import styled from '@emotion/styled'
import { FC } from 'react'
import { IsInLayoutContext } from '../context/IsInLayoutContext'

export const Width620: FC = props => (
  <IsInLayoutContext.Provider value="Width620">
    <Width620Base {...props} />
  </IsInLayoutContext.Provider>
)

const Width620Base = styled('div')`
  width: 620px;
  padding: 16px;
  padding-top: 32px;
  box-sizing: border-box;
  margin-left: calc(30% - 200px);

  @media only screen and (max-width: ${({ theme }) => theme.screenLG - 1}px) {
    margin-left: 0px;
    width: 100%;
  }
`
