import { useField } from 'formik'
import { forwardRef, useCallback, useMemo } from 'react'
import { Radio as AntdRadio } from '../../Radio'
import type {
  RadioProps as AntdRadioProps,
  RadioChangeEvent as AntdRadioChangeEvent,
  RadioRef as AntdRadioRef
} from '../../Radio'
import type { FormikFieldProps } from '../typings/FieldProps'

export type RadioChangeEvent = AntdRadioChangeEvent

export type RadioProps = FormikFieldProps & AntdRadioProps
export type RadioRef = AntdRadioRef

export const Radio = forwardRef<RadioRef, RadioProps>(
  ({ name, onChange: onChangeProp, ...restProps }, ref) => {
    const [field, , helpers] = useField(name)
    const { value } = field
    const { setValue, setTouched } = helpers

    const handleChange = useCallback(
      (event: any) => {
        setValue(event.target.value)
        onChangeProp?.(event)
      },
      [onChangeProp, setValue]
    )

    const handleBlur = useCallback(() => {
      setTouched(true)
      // restProps?.onBlur(event)
    }, [setTouched])

    const onBlurProps = useMemo(
      () => ({
        onBlur: handleBlur
      }),
      [handleBlur]
    )

    return (
      <AntdRadio
        ref={ref}
        name={name}
        value={value}
        onChange={handleChange}
        {...onBlurProps} // "onBlur" prop is missing in antd typing, so we need this workaround
        {...restProps}
      />
    )
  }
)
