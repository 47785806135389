import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { signOut } from '../actions'

export function useSignOut(): () => void {
  const dispatch = useDispatch()

  const signOutCallback = useCallback(() => {
    dispatch(signOut())
  }, [dispatch])

  return signOutCallback
}
