import { gql } from '@apollo/client'

export const userPermissionFragment = gql`
  fragment UserPermissionFragment on UserPermission {
    companyId
    companyName
    construct4dPermissions {
      id
      permissions
    }
    interim4dPermissions {
      id
      permissions
    }
    vat
  }
`
