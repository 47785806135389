import { log, LogType } from '../logging'
import { errorHandlers } from './trackError'
import { eventHandlers } from './trackEvent'
import { infoHandlers } from './trackInfo'
import { screenHandlers } from './trackScreen'
import { userHandlers, User } from './trackUser'

// TODO: use in web and native apps
// should be compatible with:
// -> native: `@bugsnag/react-native`
// -> web:    `@bugsnag/js` + (and later with google analytic ?)

export const tracking = {
  user: (user: User, language: string) => {
    log(LogType.User, user)
    try {
      userHandlers.forEach(handler => handler(user, language))
    } catch (e) {}
  },
  info: (name: string, data?: any, extraData?: any) => {
    log(LogType.Info, data, name)
    try {
      infoHandlers.forEach(handler => handler(name, data, extraData))
    } catch (e) {}
  },
  event: (key: string, name: string, data?: any) => {
    log(LogType.Event, data, name)
    try {
      eventHandlers.forEach(handler => handler(key, name, data))
    } catch (e) {}
  },
  screen: (name: string, data?: Record<string, any>) => {
    log(LogType.Screen, { name }, name)
    try {
      screenHandlers.forEach(handler => handler(name, data))
    } catch (e) {}
  },
  error: (
    error: Error | unknown,
    params?: {
      name?: string
      onBugsnagErrorCallback?: (event: unknown) => void
    }
  ) => {
    if (error instanceof Error) {
      log(LogType.Error, error, params?.name)
      try {
        errorHandlers.forEach(handler => handler(error, params))
      } catch (e) {}
    }
  }
}
