import styled from '@emotion/styled'
import { List } from '@vivaldis/antd-ui'
import { FC } from 'react'
import type { EmployeeProfileQuery_employee_educations as Education } from '../../../../typings/EmployeeProfileQuery'

export interface Props {
  education: Education
}

export const EducationListItem: FC<Props> = ({ education }) => {
  return (
    <ListItem>
      <List.Item.Meta
        title={
          <>
            {education.educationSubject.name} -{' '}
            {education.educationSubject.educationDegree.name}
          </>
        }
        description={`${education.graduationYear} - ${
          education.institute || ''
        }`}
      />
    </ListItem>
  )
}

export const ListItem = styled(List.Item)`
  background-color: ${props => props.theme.white};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 10px;
`
