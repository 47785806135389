import { css } from '@emotion/react'
import { css as classNameCss } from '@emotion/css'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { ComponentProps, ComponentType, MouseEvent } from 'react'
import { VivaldisTheme } from '@vivaldis/ui'

const Outline = css`
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    pointer-events: none;
    border: 3px solid ${VivaldisTheme.VivaldisOrange_orange6};
  }
`

const Focus = css`
  position: relative;
  outline: none;

  &:focus,
  &:focus:active {
    &:after {
      display: none;
    }
  }

  &:focus {
    ${Outline};
  }
`

const FocusClassName = classNameCss`
  ${Focus};
`

export function accessible<C extends ComponentType, P = ComponentProps<C>>(
  Component: C
): ComponentType<P> {
  const accessible = (props: any) => (
    <Component
      tabIndex={0}
      className={FocusClassName}
      {...props}
      onClick={(e: MouseEvent<any>) => {
        if (
          !(
            e.currentTarget &&
            e.currentTarget.getAttribute &&
            e.currentTarget.getAttribute('href') &&
            e.metaKey
          )
        ) {
          if (props.onClick) props.onClick(e)
        }
      }}
    />
  )

  hoistNonReactStatics(accessible, Component)
  accessible.displayName = `accessible(${
    Component.displayName || Component.name
  })`

  return accessible as ComponentType<P>
}

export const Accessibility = {
  Focus,
  Outline
}
