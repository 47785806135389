import { css } from '@emotion/react'

export interface MarginProps {
  marginBottom?: number
  marginTop?: number
  marginLeft?: number
  marginRight?: number
  marginVertical?: number
  marginHorizontal?: number
}

export function margin(props: MarginProps & { [key: string]: any }) {
  return css`
    ${props.marginBottom !== undefined
      ? `margin-bottom: ${props.marginBottom}px;`
      : null};
    ${props.marginTop !== undefined
      ? `margin-top: ${props.marginTop}px;`
      : null};
    ${props.marginLeft !== undefined
      ? `margin-left: ${props.marginLeft}px;`
      : null};
    ${props.marginRight !== undefined
      ? `margin-right: ${props.marginRight}px;`
      : null};
    ${props.marginVertical !== undefined
      ? `
      margin-bottom: ${props.marginVertical}px;
      margin-top: ${props.marginVertical}px;`
      : null};
    ${props.marginHorizontal !== undefined
      ? `
      margin-left: ${props.marginHorizontal}px;
      margin-right: ${props.marginHorizontal}px;
      `
      : null};
  `
}
