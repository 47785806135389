import { useField } from 'formik'
import { forwardRef, useCallback } from 'react'
import { Checkbox as AntdCheckbox } from '../../Checkbox'
import type {
  CheckboxProps as AntdCheckboxProps,
  CheckboxOptionType as AntdCheckboxOptionType,
  CheckboxRef as AntdCheckboxRef
} from '../../Checkbox'
import type { FormikFieldProps } from '../typings/FieldProps'

export type CheckboxOptionType = AntdCheckboxOptionType
export type CheckboxProps = FormikFieldProps & AntdCheckboxProps
export type CheckboxRef = AntdCheckboxRef

export const Checkbox = forwardRef<CheckboxRef, CheckboxProps>(
  ({ name, onChange: onChangeProp, ...restProps }, ref) => {
    const [field, , helpers] = useField(name)
    const { value } = field
    const { setValue, setTouched } = helpers

    const handleChange = useCallback(
      (event: any) => {
        setValue(event.target.checked)
        onChangeProp?.(event)
        setTimeout(() => setTouched(true), 0)
      },
      [onChangeProp, setTouched, setValue]
    )

    return (
      <AntdCheckbox
        ref={ref}
        name={name}
        checked={value}
        onChange={handleChange}
        {...restProps}
      />
    )
  }
)
