import { AlertButton, AlertOptions } from 'react-native'

type Handler = (
  type: 'confirmation' | 'mutation' | 'deletion' | 'error',
  title: string,
  message?: string,
  buttons?: AlertButton[],
  options?: AlertOptions
) => void

export class PromptHandler {
  private static handler: Handler

  public static register(handler: Handler) {
    PromptHandler.handler = handler
  }

  public static handle(
    type: 'confirmation' | 'mutation' | 'deletion' | 'error',
    title: string,
    message?: string,
    buttons?: AlertButton[],
    options?: AlertOptions
  ): void {
    const handler = PromptHandler.handler || (() => {})
    handler(type, title, message, buttons, options)
  }
}
