function createKey(keyLength = 8) {
  return Math.random().toString(36).substr(2, keyLength)
}

export function initEscapeHandler() {
  document.addEventListener('keydown', e => {
    if (e.key === 'Escape') {
      EscapeHandler.notifyListeners()
    }
  })
}

export type EventName = 'escapePress'
export type EventHandler = () => boolean

export class EscapeHandler {
  public static addEventListener(
    eventName: EventName,
    handler: EventHandler
  ): () => void {
    const key = createKey()
    EscapeHandler.listeners[eventName].push({ key, handler })

    return () => {
      EscapeHandler.listeners[eventName] = EscapeHandler.listeners[
        eventName
      ].filter(i => i.key !== key)
    }
  }

  public static removeEventListener(
    eventName: EventName,
    handler: EventHandler
  ) {
    EscapeHandler.listeners[eventName] = EscapeHandler.listeners[
      eventName
    ].filter(i => i.handler === handler)
  }

  public static notifyListeners() {
    const len = EscapeHandler.listeners.escapePress.length
    for (let i = len - 1; i >= 0; --i) {
      if (EscapeHandler.listeners.escapePress[i].handler()) {
        break
      }
    }
  }

  private static listeners: {
    escapePress: Array<{ key: string; handler: EventHandler }>
  } = {
    escapePress: []
  }
}
