import { companyFragment } from '../fragments/CompanyFragment'
import {
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
} from './__generated__/UpdateCompanyMutation'
import { ApolloClient, MutationOptions, gql } from '@apollo/client'

const mutation = gql`
  ${companyFragment}

  mutation UpdateCompanyMutation($input: UpdateCompanyInput!, $locale: String) {
    updateCompany(input: $input) {
      company {
        ...CompanyFragment
      }
    }
  }
`

export function updateCompanyMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>,
    'mutation'
  >
) {
  return apolloClient.mutate<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >({
    mutation,
    ...options
  })
}
