import { gql } from '@apollo/client'
import { getApolloClient, getReduxStore } from '@vivaldis/common'
import { userFragment } from '@vivaldis/graphql'
import { updateUser, userQuery } from '@vivaldis/session'
import { SelectCompanyInput } from '../../typings/__generated__'
import { userDashboardQuery } from '../queries/userDashboardQuery'
import {
  SelectCompanyMutation,
  SelectCompanyMutationVariables
} from './__generated__/SelectCompanyMutation'

export const mutation = gql`
  ${userFragment}

  mutation SelectCompanyMutation($input: SelectCompanyInput!, $locale: String) {
    selectCompany(input: $input) {
      user {
        ...UserFragment
      }
    }
  }
`

export const selectCompanyMutation = (input: SelectCompanyInput) => {
  const apolloClient = getApolloClient()
  return apolloClient
    .mutate<SelectCompanyMutation, SelectCompanyMutationVariables>({
      mutation,
      variables: {
        input
      },
      refetchQueries: [userQuery, userDashboardQuery],
      awaitRefetchQueries: true,
      onQueryUpdated(observableQuery) {
        if (observableQuery.queryName === 'CompanyInformationQuery') {
          return false
        }

        // Proceed with the default refetching behavior, as if onQueryUpdated
        // was not provided.
        return true
      }
    })
    .then(res => {
      const user = res?.data?.selectCompany?.user
      if (user) {
        const store = getReduxStore()
        store.dispatch(updateUser(user))
      }
      return res
    })
    .then(async res => {
      await apolloClient.resetStore()
      return res
    })
}
