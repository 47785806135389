import { useState, useEffect, FC, PropsWithChildren } from 'react'
import { useVersion } from '../hooks/useVersion'

interface Props {
  migration: (version: string, previousVersion?: string | null) => Promise<void>
}

export const Migration: FC<PropsWithChildren<Props>> = ({
  migration,
  children
}) => {
  const [ready, setReady] = useState(false)
  const { version, previousVersion } = useVersion()

  useEffect(() => {
    migration(version, previousVersion).finally(() => {
      setReady(true)
    })
  }, [migration, previousVersion, version])

  return <>{ready ? children : null}</>
}
