import { useField } from 'formik'
import { forwardRef, useCallback } from 'react'
import { Input as AntdInput, InputRef as AntdInputRef } from '../../Input'
import type { PasswordProps as AntdPasswordProps } from '../../Input'
import type { FormikFieldProps } from '../typings/FieldProps'

export type PasswordProps = FormikFieldProps & AntdPasswordProps

export const Password = forwardRef<AntdInputRef, PasswordProps>(
  ({ name, onChange: onChangeProp, onBlur: onBlurProp, ...restProps }, ref) => {
    const [field] = useField(name)
    const { value, onChange, onBlur } = field

    const handleChange = useCallback(
      (event: any) => {
        onChange(event)
        onChangeProp?.(event)
      },
      [onChange, onChangeProp]
    )

    const handleBlur = useCallback(
      (event: any) => {
        onBlur(event)
        onBlurProp?.(event)
      },
      [onBlur, onBlurProp]
    )

    return (
      <AntdInput.Password
        ref={ref}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...restProps}
      />
    )
  }
)
