const isLargerThanCharsLimit = (number: number): boolean => number > 256 // NOTE: ' ' count as chars as well for the api
const isLargerThanWordsLimit = (value: string): boolean =>
  value.split(' ').filter(word => !!word).length > 20

// the mapbox api accepts a maximum of 20 separate words and a maximum of 256 characters in a query
export const prepareInputForQueryAccordingToMapboxApiLimits = (
  input: string
): string => {
  const allTrimmedInput = input
    .trim()
    .split(' ')
    .filter(val => !!val)
    .join(' ')
  const lengthOfInput = allTrimmedInput.length
  let correctedInput = allTrimmedInput

  if (
    isLargerThanCharsLimit(lengthOfInput) &&
    !isLargerThanWordsLimit(correctedInput)
  ) {
    correctedInput = allTrimmedInput.slice(0, -(lengthOfInput - 256))
  } else if (
    !isLargerThanCharsLimit(lengthOfInput) &&
    isLargerThanWordsLimit(correctedInput)
  ) {
    correctedInput = correctedInput.split(' ').slice(0, 20).join(' ')
  } else {
    if (isLargerThanCharsLimit(lengthOfInput)) {
      correctedInput = allTrimmedInput.slice(0, -(lengthOfInput - 256))
    }
    if (isLargerThanWordsLimit(correctedInput)) {
      correctedInput = correctedInput.split(' ').slice(0, 20).join(' ')
    }
  }

  return correctedInput
}
