import { getSideSheetsNavigation } from '@vivaldis/web-ui'
import { call, takeEvery } from 'redux-saga/effects'
import { SessionActionType } from '@vivaldis/session'
import queryString from 'query-string'

// open survey from email -> https://www.vivaldispayroll.be/surveys?sidePanelAction=openSurvey&surveyId=XXXX

function* handleLinksFromEmail() {
  const parsedSearchParams = queryString.parse(window.location.search)

  // Example of "open survey from email" link:
  // http://localhost:3000/surveys?sidePanelAction=openSurvey&surveyId=Z2lkOi8vdml2YWxkaS9Db21wYW55OjpTdXJ2ZXkvOA
  if (
    parsedSearchParams.sidePanelAction === 'openSurvey' &&
    parsedSearchParams.surveyId
  ) {
    const { surveyId } = parsedSearchParams

    const sideSheetsNavigator = getSideSheetsNavigation()
    const sidePanelUrl = `/surveys/fill-survey/${surveyId}`

    // sideSheetsNavigator.push(sidePanelUrl)
    yield call(sideSheetsNavigator.push, sidePanelUrl)
  }
}

export function* emailLinks() {
  yield takeEvery(SessionActionType.InitComplete, handleLinksFromEmail)
}
