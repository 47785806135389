import { AttachmentInput, EmployeeCertificateInput } from '@vivaldis/graphql'
import { CERTIFICATE_TYPES_WITH_REQUIRED_EXPIRATION_DATE } from '@vivaldis/ui'
import {
  Formik,
  FormikContextType,
  FormikHelpers,
  useFormikContext
} from 'formik'
import { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { EmployeeCertificates } from '../types/EmployeeCertificates'
import { employeeCertificateFormValuesToEmployeeCertificateInput } from '../utils/employeeCertificateFormValuesToEmployeeCertificateInput'

export interface EmployeeCertificateFormValues {
  certificateType: string
  scannedDocumentUrlAttachment: AttachmentInput & { dataURL: string }
  scannedDocumentBackUrlAttachment: AttachmentInput & { dataURL: string }
  expiresOn: Date | null
}

export function useForm(): FormikContextType<EmployeeCertificateFormValues> {
  return useFormikContext()
}

interface Props {
  certificateTypes: string[]
  employeeCertificate?: EmployeeCertificates | null
  onSubmit: (
    employeeCertificateInput: EmployeeCertificateInput,
    formikHelpers: FormikHelpers<EmployeeCertificateFormValues>
  ) => Promise<any>
}

export const FormProvider: FC<PropsWithChildren<Props>> = ({
  certificateTypes,
  employeeCertificate,
  onSubmit,
  children
}) => {
  const [t] = useTranslation('web_ui')

  const initialValues: EmployeeCertificateFormValues = useMemo(() => {
    return {
      certificateType: employeeCertificate?.certificateType || '',
      scannedDocumentUrlAttachment: {
        fileName: '',
        base64: '',
        dataURL: employeeCertificate?.scannedDocumentUrl || ''
      },
      scannedDocumentBackUrlAttachment: {
        fileName: '',
        base64: '',
        dataURL: employeeCertificate?.scannedDocumentBackUrl || ''
      },
      expiresOn: employeeCertificate?.expiresOn
        ? new Date(employeeCertificate.expiresOn)
        : null
    }
  }, [
    employeeCertificate?.certificateType,
    employeeCertificate?.expiresOn,
    employeeCertificate?.scannedDocumentBackUrl,
    employeeCertificate?.scannedDocumentUrl
  ])

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        certificateType: Yup.string()
          .label(t('employee_certificate_form.certificate_type.label'))
          .required()
          .oneOf(certificateTypes),
        scannedDocumentUrlAttachment: Yup.object()
          .label(t('employee_certificate_form.scanned_document_url.label'))
          .shape({
            dataURL: Yup.string()
              .label(t('employee_certificate_form.scanned_document_url.label'))
              .required()
          })
          .required(),
        scannedDocumentBackUrlAttachment: Yup.object()
          .label(t('employee_certificate_form.scanned_document_back_url.label'))
          .notRequired(),
        expiresOn: Yup.date()
          .label(t('employee_certificate_form.expires_on.label'))
          .nullable()
          .when('certificateType', {
            is: (certificateType: string) =>
              CERTIFICATE_TYPES_WITH_REQUIRED_EXPIRATION_DATE.includes(
                certificateType
              ),
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          })
      }),
    [certificateTypes, t]
  )

  const handleSubmit = useCallback(
    async (
      values: EmployeeCertificateFormValues,
      formikHelpers: FormikHelpers<EmployeeCertificateFormValues>
    ) => {
      const employeeCertificateInput =
        employeeCertificateFormValuesToEmployeeCertificateInput(values)
      await onSubmit(employeeCertificateInput, formikHelpers)
      formikHelpers.setSubmitting(false)
    },
    [onSubmit]
  )

  return (
    <Formik<EmployeeCertificateFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}
