import { FC, forwardRef } from 'react'
import { Color } from '@vivaldis/ui'
import { FontWeight } from '../../../styles/font'
import { Text, TextProps } from '../../index'

export const Body: FC<TextProps> = forwardRef(
  (
    {
      color = Color.Text.Default,
      fontSize = 16,
      fontWeight = FontWeight.regular,
      letterSpacing = 0.35,
      lineHeight = 22,
      ...props
    },
    ref
  ) => (
    <Text
      ref={ref}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
      {...props}
    />
  )
)
