import { Typography } from '@vivaldis/antd-ui'
import { useTheme } from '@vivaldis/ui'
import { format } from 'date-fns'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsVcaExamDateVisible } from '../../../../../hooks/useIsVcaExamDateVisible'
import { Information } from '../../../../../Information'
import { Contract } from '../../../../typings/Contract'

interface Props {
  contract: Contract
}

export const VCAExamDateInformationField: FC<Props> = ({ contract }) => {
  const [t] = useTranslation('web_ui')

  const theme = useTheme()

  const formattedVcaExamDate = useMemo(
    () =>
      contract?.vcaExamDate
        ? format(new Date(contract?.vcaExamDate), 'yyyy-MM-dd')
        : null,
    [contract?.vcaExamDate]
  )

  const isVcaExamDateVisible = useIsVcaExamDateVisible(
    contract.position,
    contract.employee
  )

  if (!isVcaExamDateVisible) {
    return null
  }

  return (
    <>
      <Information.InformationField
        label={t('view_contract_screen_content.vcaExamDate')}
      >
        {contract.vcaExamDate ? (
          formattedVcaExamDate
        ) : (
          <Typography.Text color={theme.gray7}>
            {t('view_contract_screen_content.vca_exam_date_not_set')}
          </Typography.Text>
        )}
      </Information.InformationField>
    </>
  )
}
