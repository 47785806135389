import { useMemo } from 'react'
import { useT } from '../../../i18n/useT'

export const useGenderOptions = () => {
  const t = useT('gender')

  return useMemo(
    () => [
      {
        label: t(`options.female`),
        value: 'FEMALE'
      },
      {
        label: t(`options.male`),
        value: 'MALE'
      }
    ],
    [t]
  )
}
