import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Locale as LocaleBase } from '@vivaldis/common'

export const Locale: FC = () => {
  const {
    i18n: { language }
  } = useTranslation()

  useEffect(() => {
    LocaleBase.set(language)
  }, [language])

  return null
}
