import { useField } from 'formik'
import { forwardRef, useCallback } from 'react'
import type { TextAreaRef } from 'antd/lib/input/TextArea'
import { Input as AntdInput } from '../../Input'
import type { TextAreaProps as AntdTextAreaProps } from '../../Input'
import type { FormikFieldProps } from '../typings/FieldProps'

export type TextAreaProps = FormikFieldProps & AntdTextAreaProps

export const TextArea = forwardRef<TextAreaRef, TextAreaProps>(
  (
    {
      name,
      onChange: onChangeProp,
      onBlur: onBlurProp,
      value: _valueProp,
      ...restProps
    },
    ref
  ) => {
    const [field] = useField(name)
    const { value, onChange, onBlur } = field

    const handleChange = useCallback(
      (event: any) => {
        onChange(event)
        onChangeProp?.(event)
      },
      [onChange, onChangeProp]
    )

    const handleBlur = useCallback(
      (event: any) => {
        onBlur(event)
        onBlurProp?.(event)
      },
      [onBlur, onBlurProp]
    )

    return (
      <AntdInput.TextArea
        ref={ref}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...restProps}
      />
    )
  }
)
