import { gql } from '@apollo/client'

export const employeeCertificateFragment = gql`
  fragment EmployeeCertificateFragment on EmployeeCertificate {
    certificateType
    expiresOn
    id
    scannedDocumentBackUrl
    scannedDocumentUrl
  }
`
