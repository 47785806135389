export interface ContractStateInfo {
  isSentContract: boolean
  isContractAlreadyStarted: boolean
  label: ContractStateInfoLabel
}

export enum ContractStateInfoLabel {
  PAST,
  ONGOING,
  APPROVED,
  REQUESTED,
  CONTRACT_CANCELLATION_PENDING,
  CONTRACT_CANCELLATION_REFUSED,
  WORKDAY_CANCELLATION_PENDING,
  WORKDAY_CANCELLATION_REFUSED
}
