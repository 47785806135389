import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const DiscoverySvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.49976 12.361C3.49976 7.476 7.47476 3.5 12.3608 3.5C17.2468 3.5 21.2218 7.476 21.2218 12.361C21.2218 17.247 17.2468 21.222 12.3608 21.222C7.47476 21.222 3.49976 17.247 3.49976 12.361ZM1.99976 12.361C1.99976 18.074 6.64776 22.722 12.3608 22.722C18.0738 22.722 22.7218 18.074 22.7218 12.361C22.7218 6.648 18.0738 2 12.3608 2C6.64776 2 1.99976 6.648 1.99976 12.361ZM10.1645 14.5589L11.2115 11.2119L14.5585 10.1639L13.5105 13.5109L10.1645 14.5589ZM8.48945 16.2329C8.63245 16.3749 8.82445 16.4519 9.02045 16.4519C9.09445 16.4519 9.17045 16.4409 9.24445 16.4179L14.3335 14.8249C14.5675 14.7519 14.7525 14.5679 14.8255 14.3329L16.4175 9.24389C16.5015 8.97889 16.4295 8.68789 16.2325 8.48989C16.0345 8.29289 15.7445 8.21989 15.4775 8.30489L10.3885 9.89789C10.1545 9.97089 9.97045 10.1539 9.89745 10.3899L8.30445 15.4789C8.22045 15.7439 8.29245 16.0349 8.48945 16.2329Z"
    />
  </svg>
)

export const Discovery = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={DiscoverySvg} {...props} ref={ref} />
)
