import { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getDateFnsLocale } from './dateFnsLocales'
import { setDefaultOptions } from 'date-fns'

export const DateFnsDefaultOptionsProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const [, { language }] = useTranslation()
  const dateFnsLocale = useMemo(() => getDateFnsLocale(language), [language])

  useEffect(() => {
    setDefaultOptions({
      locale: dateFnsLocale,
      weekStartsOn: 1 // the index of the first day of the week (0 - Sunday)
    })
  }, [dateFnsLocale])

  return <>{children}</>
}
