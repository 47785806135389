import hoistNonReactStatics from 'hoist-non-react-statics'
import { List } from '../List'
import { InfiniteList as BaseInfiniteList } from './InfiniteList'
import { MobileListItem } from './MobileListItem'
export type { InfiniteListProps } from './InfiniteList'

export const InfiniteList = Object.assign(
  hoistNonReactStatics(BaseInfiniteList, List),
  {
    MobileListItem: MobileListItem
  }
)
