import { useBreakpoint } from '@vivaldis/antd-ui'
import { useEffect, useMemo, useRef, FC, PropsWithChildren } from 'react'
import { createMemoryHistory, Action, MemoryHistory } from 'history'
import { SideSheetsHistory } from '../typings/SideSheetsHistory'
import { SideSheetsHistoryContext } from '../context/SideSheetsHistoryContext'
import { SideSheetsElementsProvider } from './SideSheetsElementsProvider'

const memoryHistory = createMemoryHistory({
  initialEntries: [{ pathname: '/' }],
  initialIndex: 0
})

export const getSideSheetsNavigation = (): MemoryHistory => {
  return memoryHistory
}

export const SideSheetsProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const unSub = useRef<() => void>()
  const breakpoint = useBreakpoint()

  const history = useMemo(() => {
    const base = memoryHistory
    const history: Partial<SideSheetsHistory> = { ...base }
    history.entries = [base.location]
    history.index = 0
    unSub.current = base.listen(() => {
      history.action = base.action
      if (base.action === Action.Pop) {
        history.entries = [...history.entries!.slice(0, base.index + 1)]
      } else if (base.action === Action.Push) {
        history.entries = [...history.entries!, base.location]
      } else if (base.action === Action.Replace) {
        history.entries = [
          ...history.entries!.slice(0, base.index),
          base.location,
          ...history.entries!.slice(base.index + 1)
        ]
      }
      history.index = base.index
    })
    return history as SideSheetsHistory
  }, [])

  useEffect(
    () => () => {
      if (unSub.current) {
        unSub.current()
      }
    },
    []
  )

  useEffect(() => {
    if (!breakpoint.lg && history.entries.length) {
      // Reset when going to mobile screen size
      history.go((history.entries.length - 1) * -1)
    }
  }, [breakpoint.lg, history])

  return (
    <SideSheetsHistoryContext.Provider value={history}>
      <SideSheetsElementsProvider>{children}</SideSheetsElementsProvider>
    </SideSheetsHistoryContext.Provider>
  )
}
