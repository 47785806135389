import { Typography, TextStylePreset, Row } from '@vivaldis/antd-ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCountryName } from '@vivaldis/common'
import { Divider } from '../../../Divider'
import { EmployeeProfileQuery_employee } from '../../typings/EmployeeProfileQuery'
import { Information } from '../../../Information'
import { MissingInformationTag } from '../MissingInformationTag'
import { NotSetValue } from '../NotSetValue'

export interface ContactProps {
  employee: EmployeeProfileQuery_employee
}

export const Contact: FC<ContactProps> = ({ employee }) => {
  const [t] = useTranslation('web_ui')

  const { profile } = employee

  const buildingNumber = profile?.buildingNumber
  const street = profile?.street
  const streetNumber = profile?.streetNumber
  const city = profile?.city?.trim()
  const zip = profile?.zip

  const phone = employee.mobile
  const countryName = useCountryName(employee.profile?.country)

  const hasAddress = buildingNumber || street || streetNumber || city

  const isMissingAddressInformation = useMemo(() => {
    return !hasAddress
  }, [hasAddress])

  return (
    <>
      <Typography.Paragraph
        preset={TextStylePreset.Semibold16}
        marginBottom={16}
      >
        {t('employee_profile.contact')}
      </Typography.Paragraph>
      <Information>
        <Information.InformationField label={t('employee_profile.phone')}>
          {phone ?? <NotSetValue />}
        </Information.InformationField>

        <Information.InformationField label={t('employee_profile.email')}>
          {employee.email}
        </Information.InformationField>

        <Information.InformationField
          label={
            <>
              {t('employee_profile.address')}
              {isMissingAddressInformation ? <MissingInformationTag /> : null}
            </>
          }
        >
          {hasAddress ? null : <NotSetValue />}
          <Row justify="end">
            {street} {streetNumber} {buildingNumber}
          </Row>
          <Row justify="end">
            {zip} {city}
          </Row>
          <Row justify="end">{countryName}</Row>
        </Information.InformationField>
      </Information>
      <Divider sectionSeparator />
    </>
  )
}
