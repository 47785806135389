import { FC } from 'react'
import { Premium } from '../../../../typings/Premium'
import { EcoChequeComment } from './components/EcoChequeComment'
import { NettoPremiumComment } from './components/NettoPremiumComment'
import { PremiumItems } from './components/PremiumValues'
import { PremiumTypes } from './components/PremiumTypes'

interface Props {
  premiums: Premium[]
  showEcoChequeFields: boolean
}

export const FormContent: FC<Props> = ({ premiums, showEcoChequeFields }) => {
  return (
    <>
      <PremiumTypes premiums={premiums} />
      <PremiumItems premiums={premiums} />
      {showEcoChequeFields ? <EcoChequeComment /> : null}
      <NettoPremiumComment />
    </>
  )
}
