import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { usePosition } from '../../../providers/PositionProvider'
import { useForm } from '../providers/FormProvider'
import { ActionBar as ActionBarBase } from '../../../../ActionBar'
import { SubmitButton } from '../../../../SubmitButton'

export const ActionBar: FC = () => {
  const { t } = useTranslation('web_ui')
  const { isSubmitting } = useForm()

  const position = usePosition()

  const submitButtonLabel = useMemo(() => {
    return position
      ? t('position_form.step1.submit_button.update')
      : t('position_form.step1.submit_button.create')
  }, [position, t])

  return (
    <ActionBarBase
      rightContent={
        <SubmitButton isSubmitting={isSubmitting} label={submitButtonLabel} />
      }
    />
  )
}
