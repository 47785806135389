import styled from '@emotion/styled'
import { FC } from 'react'
import VivaldisLogoTypeAsset from './assets/vivaldis-logotype.svg'
import { LogoProps } from './typings/LogoProps'

export const Logo: FC<LogoProps> = ({ width = 92 }) => {
  return <Image src={VivaldisLogoTypeAsset} width={width} />
}

const Image = styled('img')<{ width: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ width }) => `${Math.round(width / 1.92)}px`};
`
