import { all } from 'redux-saga/effects'
import { app } from './app'
import { emailLinks } from './emailLinks'
import { preferredLanguage } from './preferredLanguage'
import { session } from './session'
import { version } from './version'

export const sagas = function* () {
  yield all([app(), emailLinks(), preferredLanguage(), session(), version()])
}
