import { FC, useCallback } from 'react'
import { useField } from 'formik'
import { DatePicker as AntdDatePicker } from '../../DatePicker'
import type { RangePickerProps as AntdRangePickerProps } from '../../DatePicker'
import type { FormikFieldProps } from '../typings/FieldProps'

export type RangePickerProps = FormikFieldProps & AntdRangePickerProps

export const RangePicker: FC<RangePickerProps> = ({
  name,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  value: _valueProp, // we don't need this value, because we are going to use value from formik
  ...restProps
}) => {
  const [field, , helpers] = useField(name)
  const { value } = field
  const { setValue, setTouched } = helpers

  const handleChange = useCallback<Required<AntdRangePickerProps>['onChange']>(
    (values, formatString) => {
      setValue(values)
      onChangeProp?.(values, formatString)
      // "onBlur" event triggers multiple times and in the wrong order, so we need to mark this input as touched manually in next "tick" to prevent showing wrong error state
      setTimeout(() => setTouched(true), 0)
    },
    [onChangeProp, setTouched, setValue]
  )

  return (
    <AntdDatePicker.RangePicker
      name={name}
      onChange={handleChange}
      value={value}
      {...restProps}
    />
  )
}
