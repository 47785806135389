import { Suspense, FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'
import { lazyOrReloadPage } from '../../utils/lazyOrReloadPage'

const AddEmployeeCertificate = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "AddEmployeeCertificate" */ './screens/AddEmployeeCertificate'
    )
)

const EditEmployeeProfile = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "EditEmployeeProfile" */ './screens/EditEmployeeProfile'
    )
)

const EditEmployeeCertificate = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "EditEmployeeCertificate" */ './screens/EditEmployeeCertificate'
    )
)
const ViewEmployeeProfile = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "ViewEmployeeProfile" */ './screens/ViewEmployeeProfile'
    )
)

export const EmployeeRoutes: FC = () => (
  <Suspense fallback={ProgressBarSuspenseFallback}>
    <Routes>
      <Route
        path=":employeeId/view-employee-profile"
        element={<ViewEmployeeProfile />}
      />
      <Route
        path=":employeeId/edit-employee-profile"
        element={<EditEmployeeProfile />}
      />
      <Route
        path=":employeeId/add-employee-certificate"
        element={<AddEmployeeCertificate />}
      />
      <Route
        path=":employeeId/edit-employee-certificate/:certificateId"
        element={<EditEmployeeCertificate />}
      />
    </Routes>
  </Suspense>
)
