import type { WorkPostSheetItemTypesEnum } from '../types'

const DATE_WPS_ITEMS = [
  'PREGNANCY_ADJUSTMENTS_DATE',
  'BREASTFEEDING_ADJUSTEMENTS_DATE',
  'ADVICE_DATE',
  'ADVICE_DATE_LABOR_DOCTOR',
  'ADVICE_DATE_INTERNAL_SERVICE'
]

export const isDateWPSItem = (type: WorkPostSheetItemTypesEnum): boolean => {
  // these items don't have checkbox in UI
  return DATE_WPS_ITEMS.includes(type)
}
