import { useField } from 'formik'
import { forwardRef, useCallback } from 'react'
import { Radio } from '../../Radio'
import type { RadioGroupProps as AntdRadioGroupProps } from '../../Radio'
import type { FormikFieldProps } from '../typings/FieldProps'

export type RadioGroupProps = FormikFieldProps & AntdRadioGroupProps

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ name, onChange: onChangeProp, ...restProps }, ref) => {
    const [field, , helpers] = useField(name)
    const { value } = field
    const { setValue, setTouched } = helpers

    const handleChange = useCallback(
      (event: any) => {
        setValue(event.target.value)
        // we don't have "onBlur" event, so we need to mark this input as touched, but in next "tick" to prevent showing error
        setTimeout(() => setTouched(true), 0)
        onChangeProp?.(event)
      },
      [onChangeProp, setTouched, setValue]
    )

    return (
      <Radio.Group
        ref={ref}
        name={name}
        value={value}
        onChange={handleChange}
        {...restProps}
      />
    )
  }
)
