import { FC } from 'react'
import { UserNavigationBar } from '@vivaldis/antd-ui'
import { AvatarAndMenu } from '../Header/components/AvatarAndMenu'
import { OnboardingNavigationSteps } from './components/OnboardingNavigationSteps'

export const NavigationBarForOnboarding: FC = () => {
  return (
    <UserNavigationBar navigationItems={<OnboardingNavigationSteps />}>
      <AvatarAndMenu />
    </UserNavigationBar>
  )
}
