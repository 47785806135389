// copied from __generated__ file

export interface AttachmentInput {
  fileName: string
  base64: string
}

export enum WorkPostSheetItemTypesEnum {
  ADVICE_BY_COMITE_PWB = 'ADVICE_BY_COMITE_PWB',
  ADVICE_BY_DIRECT_EMPLOYEE_PARTICIPATION = 'ADVICE_BY_DIRECT_EMPLOYEE_PARTICIPATION',
  ADVICE_BY_SYNDICATE_REP = 'ADVICE_BY_SYNDICATE_REP',
  ADVICE_DATE = 'ADVICE_DATE',
  ADVICE_DATE_INTERNAL_SERVICE = 'ADVICE_DATE_INTERNAL_SERVICE',
  ADVICE_DATE_LABOR_DOCTOR = 'ADVICE_DATE_LABOR_DOCTOR',
  BIOLOGICAL_AGENTS = 'BIOLOGICAL_AGENTS',
  BREASTFEEDING_ADJUSTEMENTS_DATE = 'BREASTFEEDING_ADJUSTEMENTS_DATE',
  BREASTFEEDING_ADJUSTMENTS = 'BREASTFEEDING_ADJUSTMENTS',
  CHEMICAL_AGENTS = 'CHEMICAL_AGENTS',
  COAT = 'COAT',
  DUST_JACKET = 'DUST_JACKET',
  ERGONOMIC_LOAD = 'ERGONOMIC_LOAD',
  FUNCTION_WITH_INCREASED_VIGILANCE = 'FUNCTION_WITH_INCREASED_VIGILANCE',
  GLASSES_SAFETY_SCREEN = 'GLASSES_SAFETY_SCREEN',
  GLOVES_MITTENS = 'GLOVES_MITTENS',
  HANDLING_LOADS = 'HANDLING_LOADS',
  HEARING_PROTECTION = 'HEARING_PROTECTION',
  HELMET = 'HELMET',
  HEPATITIS_B = 'HEPATITIS_B',
  MASK = 'MASK',
  NIGHT_WORK = 'NIGHT_WORK',
  NOISE = 'NOISE',
  OINTMENTS = 'OINTMENTS',
  OTHER_EQUIPMENT = 'OTHER_EQUIPMENT',
  OTHER_VACCINATIONS_TESTS = 'OTHER_VACCINATIONS_TESTS',
  PANTS_OVERALL = 'PANTS_OVERALL',
  PDF_URL = 'PDF_URL',
  PREGNANCY_ADJUSTMENTS = 'PREGNANCY_ADJUSTMENTS',
  PREGNANCY_ADJUSTMENTS_DATE = 'PREGNANCY_ADJUSTMENTS_DATE',
  SAFETY_BELT_HARNESS = 'SAFETY_BELT_HARNESS',
  SAFETY_FUNCTION = 'SAFETY_FUNCTION',
  SAFETY_SHOES = 'SAFETY_SHOES',
  SHIFT_WORK = 'SHIFT_WORK',
  TETANUS = 'TETANUS',
  TUBERCULOSE = 'TUBERCULOSE',
  YOUNGER = 'YOUNGER'
}

/**
 * Properties for creating/updating work post sheet
 */
export interface WorkPostSheetInput {
  tasksToPerform?: string | null
  requiredQualifications?: string | null
  workEquipment?: string | null
  priorInstructions?: string | null
  foreseeableTraining?: string | null
  workstationLocation?: string | null
  items?: WorkPostSheetItemInput[] | null
}

/**
 * Properties for creating/updating work post sheet item
 */
export interface WorkPostSheetItemInput {
  name: WorkPostSheetItemTypesEnum
  comment?: string | null
  commentAttachment?: AttachmentInput | null
}

// ====================================================
// GraphQL query operation: ViewWorkPostSheetQuery
// ====================================================

export interface Items {
  __typename: 'WorkPostSheetItem'
  comment: string | null
  id: string
  name: WorkPostSheetItemTypesEnum
}

export interface ViewWorkPostSheetQuery_workPostSheet {
  __typename: 'WorkPostSheet'
  id: string
  foreseeableTraining: string | null
  items: Items[] | null
  priorInstructions: string | null
  requiredQualifications: string | null
  tasksToPerform: string | null
  workEquipment: string | null
  workstationLocation: string | null
}

export interface PositionWithWPS {
  id: string
  name: string
  workPostSheet: ViewWorkPostSheetQuery_workPostSheet
}
