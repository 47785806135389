import styled from '@emotion/styled'

interface Props {
  disabled?: boolean
}

export const Base = styled('div')<Props>`
  display: grid;
  grid-template-columns: 48px 104fr 95fr 95fr 95fr 95fr;
  @media only screen and (max-width: 520px) {
    grid-template-columns:
      36px minmax(88px, 55fr) minmax(44px, 45fr) minmax(44px, 45fr) minmax(
        76px,
        55fr
      )
      minmax(70px, 65fr);
    min-width: 380px;
  }
  margin: 0 -16px;
  height: 44px;
  background: ${props =>
    props.disabled ? props.theme.gray3 : props.theme.white};
`
