import { Alert as AntdAlert } from 'antd'
import styled from '@emotion/styled'
import hoistNonReactStatics from 'hoist-non-react-statics'
export type { AlertProps } from 'antd'

const StyledAntdAlert = styled(AntdAlert)`
  // message should be semi-bold message (only when Alert has message and description)
  &.ant-alert-with-description {
    .ant-alert-message {
      font-weight: 600;
    }
  }
`

export const Alert = hoistNonReactStatics(StyledAntdAlert, AntdAlert)
