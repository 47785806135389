import { useDateFnsLocale } from '@vivaldis/common'
import { isValid, parse as parseDate, format } from 'date-fns'
import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Input, InputProps, InputRef } from '../../../Input'

const INPUT_TIME_FORMAT = 'HH:mm' // 23:45
const EMPTY_VALUE_MASK = '--:--'

type SelectedSection = 'hours' | 'minutes' | 'all'

const getSelectedPart = (
  selectionStart: number | null,
  selectionEnd: number | null
): SelectedSection => {
  if (selectionStart === 0 && selectionEnd === 5) {
    return 'all'
  }
  if (selectionStart === null || selectionStart < 3) {
    return 'hours'
  }
  return 'minutes'
}

export type HoursAndMinutesTimeInputProps = Omit<
  InputProps,
  'value' | 'onChange'
> & {
  value?: Date | null
  onChange: (value: Date | null) => void
}

// Behaviour should be similar to ths -> https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time
// User should be able to tab-complete inputs
// 9 → tab → becomes 09:00
// 09 → tab → becomes 09:00
// 09:0 → tab → becomes 09:00

export const HoursAndMinutesTimeInput: FC<HoursAndMinutesTimeInputProps> = ({
  value,
  onChange,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  ...restProps
}) => {
  const dateFnsLocale = useDateFnsLocale()

  const [inputValue, setInputValue] = useState<string>()
  const inputRef = useRef<InputRef>(null)

  const [prevSelectedPart, setPrevSelectedPart] = useState<
    'hours' | 'minutes' | null
  >(null)

  const setSelectedPartAndSelectInInput = useCallback(
    (selectedPart: SelectedSection) => {
      switch (selectedPart) {
        case 'all': {
          inputRef.current?.input?.setSelectionRange(0, 5)
          setTimeout(() => {
            inputRef.current?.input?.setSelectionRange(0, 5)
          }, 0)
          break
        }
        case 'hours': {
          inputRef.current?.input?.setSelectionRange(0, 2)
          setTimeout(() => {
            inputRef.current?.input?.setSelectionRange(0, 2)
          }, 0)
          break
        }
        case 'minutes': {
          inputRef.current?.input?.setSelectionRange(3, 5)
          setTimeout(() => {
            inputRef.current?.input?.setSelectionRange(3, 5)
          }, 0)
          break
        }
      }
    },
    []
  )

  const setValueInInputTimeFormat = useCallback(
    (newValue: string | null) => {
      if (newValue === null) {
        setInputValue(undefined)
        onChange(null)
        return
      }

      const date = parseDate(newValue, INPUT_TIME_FORMAT, new Date(), {
        locale: dateFnsLocale
      })
      if (isValid(date)) {
        setInputValue(newValue)
        onChange(date)
        return
      }

      setInputValue(newValue)
      onChange(null)
    },
    [dateFnsLocale, onChange]
  )

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    event => {
      onBlur?.(event)
      setPrevSelectedPart(null)
      // when a user clicks outside of the input, and it's not a valid time, we need to reset the input value to empty
      if (!value) {
        setInputValue(undefined)
      }

      if (value && (!inputValue || inputValue.includes('-'))) {
        onChange(null)
        setInputValue(undefined)
      }
    },
    [inputValue, onBlur, onChange, value]
  )

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      const inputValue = event.target.value ?? ''

      const date = parseDate(inputValue, 'HH:mm', new Date())
      if (isValid(date)) {
        onChange(date)
      } else {
        onChange(null)
      }

      setInputValue(inputValue)
    },
    [onChange]
  )

  const handleClick = useCallback<MouseEventHandler<HTMLInputElement>>(
    event => {
      if (!value) {
        setInputValue(EMPTY_VALUE_MASK)
      }

      const selectionStart = inputRef.current?.input?.selectionStart ?? 0
      const selectionEnd = inputRef.current?.input?.selectionEnd ?? 0

      const selectedPart = getSelectedPart(selectionStart, selectionEnd)

      setSelectedPartAndSelectInInput(selectedPart)
    },
    [setSelectedPartAndSelectInInput, value]
  )

  const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
    event => {
      if (!value) {
        setInputValue(EMPTY_VALUE_MASK)
      }

      const selectionStart = inputRef.current?.input?.selectionStart ?? 0
      const selectionEnd = inputRef.current?.input?.selectionEnd ?? 0

      const selectedPart = getSelectedPart(selectionStart, selectionEnd)

      setSelectedPartAndSelectInInput(selectedPart)
    },
    [setSelectedPartAndSelectInInput, value]
  )

  // return value in format --:--. E.x. 01:00, 23:45, 00:00, 23:--, 00:--, --:45, --:00
  const maskedValue = useMemo(() => {
    return String(inputValue || '--:--')
  }, [inputValue])

  const updateValue = useCallback(
    (char: string) => {
      const selectionStart = inputRef.current?.input?.selectionStart ?? 0
      const selectionEnd = inputRef.current?.input?.selectionEnd ?? 0

      const activePart = getSelectedPart(selectionStart, selectionEnd)

      const [formattedHours = '--', formattedMinutes = '--'] =
        maskedValue.split(':')

      const hours = (formattedHours || '00').replaceAll('-', '0')
      const minutes = (formattedMinutes || '00').replaceAll('-', '0')

      // we need update value of active part and move a cursor to the next part if needed
      if (activePart === 'hours' || activePart === 'all') {
        const lastHoursChar = hours?.[1] || '0'
        const newHours = `${lastHoursChar}${char}`

        // when prevSelectedPart === null, it means that we are in the first iteration and need ti start from 0X
        const isInitiallySetHours = prevSelectedPart !== 'hours'

        setPrevSelectedPart('hours')

        if (isInitiallySetHours) {
          // case 0 -> 01:00 -> keep selection in hours
          // case 1 -> 01:00 -> keep selection in hours
          // case 2 -> 02:00 -> keep selection in hours
          // -----
          // case 3 -> 03:00 -> move selection to minutes
          // ...
          // case 9 -> 09:00 -> move selection to minutes

          if (char === '0' || char === '1' || char === '2') {
            setValueInInputTimeFormat(`0${char}:${minutes}`)
            setSelectedPartAndSelectInInput('hours')
            return
          }

          setValueInInputTimeFormat(`0${char}:${minutes}`)
          setSelectedPartAndSelectInInput('minutes')
          return
        }

        const date = parseDate(`${newHours}:${minutes}`, 'HH:mm', new Date())
        if (isValid(date)) {
          setValueInInputTimeFormat(format(date, 'HH:mm'))
          setSelectedPartAndSelectInInput('minutes')
          return
        }
        setValueInInputTimeFormat(`0${char}:${minutes}`)
        setSelectedPartAndSelectInInput('minutes')
        return
      }

      if (activePart === 'minutes') {
        const lastMinutesChar = minutes?.[1] || '0'
        const newMinutes = `${lastMinutesChar}${char}`
        const isInitiallySetMinutes = prevSelectedPart !== 'minutes'
        setPrevSelectedPart('minutes')
        if (isInitiallySetMinutes) {
          setValueInInputTimeFormat(`${hours}:0${char}`)
          setSelectedPartAndSelectInInput('minutes')
          return
        }

        const date = parseDate(`${hours}:${newMinutes}`, 'HH:mm', new Date())
        if (isValid(date)) {
          setValueInInputTimeFormat(format(date, 'HH:mm'))
          setSelectedPartAndSelectInInput('minutes')
          return
        } else {
          setValueInInputTimeFormat(`${hours}:0${char}`)
          setSelectedPartAndSelectInInput('minutes')
          return
        }
      } else {
        setPrevSelectedPart(null)
        setSelectedPartAndSelectInInput(activePart)
      }
    },
    [
      maskedValue,
      prevSelectedPart,
      setSelectedPartAndSelectInInput,
      setValueInInputTimeFormat
    ]
  )

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    event => {
      const selectionStart = inputRef.current?.input?.selectionStart ?? 0
      const selectionEnd = inputRef.current?.input?.selectionEnd ?? 0

      const activePart = getSelectedPart(selectionStart, selectionEnd)

      // handle select all (hours and minutes)
      if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault()
        setSelectedPartAndSelectInInput('all')
        return
      }

      if (['Backspace', 'Delete'].includes(event.key)) {
        event.preventDefault()

        const activePart = getSelectedPart(selectionStart, selectionEnd)
        const [hours = '--', minutes = '--'] = maskedValue.split(':')

        if (activePart === 'all') {
          setValueInInputTimeFormat(EMPTY_VALUE_MASK)
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'hours') {
          setInputValue(`--:${minutes}`)
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'minutes') {
          setInputValue(`${hours}:--`)
          setSelectedPartAndSelectInInput('minutes')
          // when user already deleted minutes and press delete again, it indicates that user wants to remove hours as well
          if (minutes === '--' && hours !== '--') {
            setValueInInputTimeFormat(EMPTY_VALUE_MASK)
            setSelectedPartAndSelectInInput('hours')
          }
        }

        return
      }

      if (event.key === 'ArrowRight') {
        event.preventDefault()

        if (activePart === 'all') {
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'hours') {
          setSelectedPartAndSelectInInput('minutes')
        }
        if (activePart === 'minutes') {
          setSelectedPartAndSelectInInput('minutes')
        }

        return
      }

      if (event.key === 'ArrowLeft') {
        event.preventDefault()

        if (activePart === 'all') {
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'hours') {
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'minutes') {
          setSelectedPartAndSelectInInput('hours')
        }

        return
      }

      if (
        ['ArrowUp', 'ArrowDown', 'Home', 'End', 'PageUp', 'PageDown'].includes(
          event.key
        )
      ) {
        event.preventDefault()

        if (activePart === 'all') {
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'hours') {
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'minutes') {
          setSelectedPartAndSelectInInput('hours')
        }

        return
      }

      if (event.key === 'Tab') {
        // if I type 08, and I click "Tab" that then 08:00 appears
        if (maskedValue && maskedValue !== EMPTY_VALUE_MASK) {
          const time = maskedValue.replaceAll('-', '0')
          const date = parseDate(`${time}`, 'HH:mm', new Date())
          if (isValid(date)) {
            setValueInInputTimeFormat(format(date, 'HH:mm'))
          }
        }
        return
      }

      if (event.key) {
        const char = event.key || ''
        if (char.match(/\d/)) {
          updateValue(char)
        }
      }
      event.preventDefault()
    },
    [
      maskedValue,
      setSelectedPartAndSelectInInput,
      setValueInInputTimeFormat,
      updateValue
    ]
  )

  // we want to update input value when value prop was changed
  useEffect(() => {
    const isInputFocused = document?.activeElement === inputRef?.current?.input
    const inputValue = inputRef?.current?.input?.value

    // we want to update input value only when input is not focused right now
    if (!isInputFocused) {
      // setInputValue(value ? format(value, INPUT_TIME_FORMAT) : undefined)
      // console.log('useEffect: value changed', value, inputValue)
      if (value && format(value, INPUT_TIME_FORMAT) !== inputValue) {
        setInputValue(format(value, INPUT_TIME_FORMAT))
      }

      if (!value && inputValue) {
        setInputValue(undefined)
      }
    }
  }, [value])

  return (
    <Input
      ref={inputRef}
      value={inputValue}
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={handleClick}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      placeholder="00:00"
      autoComplete="off"
      inputMode="numeric"
      {...restProps}
    />
  )
}
