import { action, ActionType } from 'typesafe-actions'
import { State } from '../typings/State'

export enum AppActionType {
  ReduxPersistRehydrated = '@@App/REDUX_PERSIST_REHYDRATED',
  Init = '@App/INIT',
  InitComplete = '@App/INIT_COMPLETE',
  InitData = '@App/INIT_DATA',
  InitDataComplete = '@App/INIT_DATA_COMPLETE',
  InitUI = '@App/INIT_UI',
  InitUIComplete = '@App/INIT_UI_COMPLETE',
  InitNavigation = '@App/INIT_NAVIGATION',
  InitNavigationComplete = '@App/INIT_NAVIGATION_COMPLETE',
  Reset = '@@App/RESET',
  StateChange = '@@App/STATE_CHANGE'
}

export const reduxPersistRehydrated = () =>
  action(AppActionType.ReduxPersistRehydrated)

export const init = () => action(AppActionType.Init)

export const initComplete = () => action(AppActionType.InitComplete)

export const initData = () => action(AppActionType.InitData)

export const initDataComplete = () => action(AppActionType.InitDataComplete)

export const initUI = () => action(AppActionType.InitUI)

export const initUIComplete = () => action(AppActionType.InitUIComplete)

export const initNavigation = () => action(AppActionType.InitNavigation)

export const initNavigationComplete = () =>
  action(AppActionType.InitNavigationComplete)

export const stateChange = (state: State) =>
  action(AppActionType.StateChange, { state })

export const reset = () => action(AppActionType.Reset)

export type AppAction = ActionType<{
  reduxPersistRehydrated: typeof reduxPersistRehydrated
  init: typeof init
  initComplete: typeof initComplete
  initData: typeof initData
  initDataComplete: typeof initDataComplete
  initUI: typeof initUI
  initUIComplete: typeof initUIComplete
  initNavigation: typeof initNavigation
  initNavigationComplete: typeof initNavigationComplete
  stateChange: typeof stateChange
  reset: typeof reset
}>
