import { FC, ReactNode } from 'react'
import { DangerTriangle } from '@vivaldis/icons'
import { useTheme } from '@vivaldis/ui'
import styled from '@emotion/styled'

interface OptionWithDisabledReasonProps {
  label: ReactNode
  disabledReason?: string
}

export const SelectLabelWithDisabledReason: FC<
  OptionWithDisabledReasonProps
> = ({ label, disabledReason }) => {
  const theme = useTheme()
  return (
    <Base>
      {label}
      {disabledReason ? (
        <RightContentBase>
          <span style={{ fontSize: 10 }}>{disabledReason}</span>
          <DangerTriangle
            style={{
              marginLeft: 8,
              color: theme.VivaldisOrange_orange7,
              fontSize: 24
            }}
          />
        </RightContentBase>
      ) : null}
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RightContentBase = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
