import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import {
  DeleteContractMutation,
  DeleteContractMutationVariables
} from './__generated__/DeleteContractMutation'

const mutation = gql`
  mutation DeleteContractMutation($input: DeleteContractInput!) {
    deleteContract(input: $input) {
      status
    }
  }
`

export function deleteContractMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<DeleteContractMutation, DeleteContractMutationVariables>,
    'mutation'
  >
) {
  return apolloClient.mutate<
    DeleteContractMutation,
    DeleteContractMutationVariables
  >({
    mutation,
    ...options
  })
}
