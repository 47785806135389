import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Form } from '@vivaldis/antd-ui'
import { useIsApprovedPosition } from '../../../../../providers/PositionProvider'

export const NoVatApplicableCheckbox: FC = () => {
  const { t } = useTranslation()

  const isApprovedPosition = useIsApprovedPosition()

  return (
    <Form.Item name="noVatApplicable">
      <Checkbox name="noVatApplicable" disabled={isApprovedPosition}>
        {t('position_form.step1.no_vat_applicable.label', { ns: 'web_ui' })}
      </Checkbox>
    </Form.Item>
  )
}
