import { gql } from '@apollo/client'
import { employeeFragment } from '@vivaldis/graphql'

export const employeeQuery = gql`
  ${employeeFragment}

  query EmployeeQuery($locale: String!) {
    employee {
      ...EmployeeFragment
    }
  }
`
