import { PlusOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import {
  countrySelector,
  doesEmployeeNeedBIC,
  getSsnInfo
} from '@vivaldis/common'
import { EmployeeInput } from '@vivaldis/graphql'
import type { RcFile } from 'antd/lib/upload/interface'
import { isValid } from 'date-fns'
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  Upload
} from '@vivaldis/antd-ui'
import { EditEmployeeProfileFormProps } from '../../../../index'
import { useForm } from '../../../../provider/FormProvider'
import { CountryType } from '../../../../types/CountryType'
import { Employee } from '../../../../types/Employee'
import { fileToBase64 } from '../../../../../../utils/fileToBase64'

type ImageFormField =
  | 'idCardBackPicture'
  | 'idCardFrontPicture'
  | 'workPermitBackPicture'
  | 'workPermitFrontPicture'
  | 'residencePermitBackPicture'
  | 'residencePermitFrontPicture'

interface Props {
  countries: CountryType[]
  employee: Employee
  onPartialEmployeeUpdate: EditEmployeeProfileFormProps['onPartialEmployeeUpdate']
}

export const DocumentsInformation: FC<Props> = ({
  countries,
  employee,
  onPartialEmployeeUpdate
}) => {
  const [t] = useTranslation('web_ui')

  const [isUploadingImage, setIsUploadingImage] = useState({
    idCardBackPicture: false,
    idCardFrontPicture: false,
    workPermitBackPicture: false,
    workPermitFrontPicture: false,
    residencePermitBackPicture: false,
    residencePermitFrontPicture: false
  })
  const { values, setFieldValue, setFieldError, setFieldTouched } = useForm()

  // we want to set birthdate and gender from SSN. But we can do it only for valid Belgium SSN.
  // In some cases we have SSN for foreigners that pass validation, but it has invalid gender or birthdate. This SSN issues by Belgium government, so they are valid, but doesn't have correct information about birthdate
  const shouldSetBirthdateAndGenderFromSSN = useMemo(() => {
    // if nationality was not selected yet, we assume it is Belgium and try to get "birthdate" and "gender" from SSN
    if (!values.nationalityCountryId) {
      return true
    }

    // birthdate and gender should be set automatically from SSN only when E has Belgian nationality
    return (
      countrySelector(values.nationalityCountryId, countries)?.nameEn ===
      'Belgium'
    )
  }, [countries, values.nationalityCountryId])

  const handleSSNChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const ssn = event.target.value
      const { gender, birthdate } = getSsnInfo(ssn)
      if (shouldSetBirthdateAndGenderFromSSN) {
        if (gender) {
          setFieldValue('gender', gender)
        }
        if (birthdate) {
          setFieldValue(
            'birthdate',
            isValid(new Date(birthdate)) ? new Date(birthdate) : new Date()
          )
        }
      }
    },
    [setFieldValue, shouldSetBirthdateAndGenderFromSSN]
  )

  const showBic = useMemo(() => {
    if (values.bic) {
      return true
    }

    if (doesEmployeeNeedBIC(values.bankAccount)) {
      return true
    }

    return false
  }, [values.bankAccount, values.bic])

  // we show ID CARD when user selected `eeaMember` country or when country is not selected yet
  const showIDCardFields = useMemo(() => {
    if (!values.nationalityCountryId) {
      return true
    }

    return !!countries.find(
      country => country.id === values.nationalityCountryId
    )?.eeaMember
  }, [countries, values.nationalityCountryId])

  const showWorkPermitFields = !showIDCardFields
  const showResidencePermitFields = !showIDCardFields

  const handleImageUpload = useCallback(
    (fieldName: ImageFormField) => async (file: RcFile) => {
      try {
        setIsUploadingImage(prevState => ({
          ...prevState,
          [fieldName]: true
        }))

        const logoAttachment = {
          fileName: file.name,
          base64: await fileToBase64(file)
        }

        let employeeInput: EmployeeInput = {}
        switch (fieldName) {
          case 'idCardBackPicture':
            employeeInput = {
              profile: {
                idCardBackPictureAttachment: logoAttachment
              }
            }
            break
          case 'idCardFrontPicture':
            employeeInput = {
              profile: {
                idCardFrontPictureAttachment: logoAttachment
              }
            }
            break
          case 'workPermitBackPicture':
            employeeInput = {
              workProfile: {
                workPermitBackPictureAttachment: logoAttachment
              }
            }
            break
          case 'workPermitFrontPicture':
            employeeInput = {
              workProfile: {
                workPermitFrontPictureAttachment: logoAttachment
              }
            }
            break
          case 'residencePermitBackPicture':
            employeeInput = {
              workProfile: {
                residencePermitBackPictureAttachment: logoAttachment
              }
            }
            break
          case 'residencePermitFrontPicture':
            employeeInput = {
              workProfile: {
                residencePermitFrontPictureAttachment: logoAttachment
              }
            }
            break
          default:
            throw new Error('unhandled image type for Edit Employee profile')
        }

        await onPartialEmployeeUpdate(employeeInput)

        // clear error if any
        setFieldValue(fieldName, logoAttachment.base64)

        return logoAttachment.base64
      } catch (e) {
        setFieldError(fieldName, String(e || 'Error'))
        setFieldTouched(fieldName, true, false)
      } finally {
        setIsUploadingImage(prevState => ({
          ...prevState,
          [fieldName]: false
        }))
      }
      return ''
    },
    [onPartialEmployeeUpdate, setFieldError, setFieldTouched, setFieldValue]
  )

  const handlePictureBeforeUpload = useCallback(
    (fieldName: ImageFormField) => (file: RcFile) => {
      // file should be less than 12MB
      const maxFileSizeInMB = 12
      const fileSizeInMB = file.size / 1024 / 1024

      const isValid = fileSizeInMB < maxFileSizeInMB

      if (!isValid) {
        setFieldError(
          fieldName,
          t('edit_employee_profile_form.image_upload.errors.max_file_size', {
            count: maxFileSizeInMB
          })
        )
        setFieldTouched(fieldName, true, false)
      }

      return isValid
    },
    [setFieldError, setFieldTouched, t]
  )

  const uploadImageButton = (
    <div className={'uploadImageButton'}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        {t('edit_employee_profile_form.image_upload.upload_button')}
      </div>
    </div>
  )

  return (
    <>
      <Typography.Title level={5} marginBottom={16}>
        {t('edit_employee_profile_form.documents_section_header')}
      </Typography.Title>
      <Form.Item
        name="ssn"
        required
        label={t('edit_employee_profile_form.ssn.label')}
      >
        <Input
          name="ssn"
          placeholder={t('form.required')}
          autoComplete="off"
          onChange={handleSSNChange}
        />
      </Form.Item>
      <Form.Item
        name="bankAccount"
        required
        label={t('edit_employee_profile_form.bank_account.label')}
      >
        <Input
          name="bankAccount"
          placeholder={t('form.required')}
          autoComplete="off"
        />
      </Form.Item>
      {showBic ? (
        <Form.Item
          name="bic"
          required={doesEmployeeNeedBIC(values.bankAccount)}
          label={t('edit_employee_profile_form.bic.label')}
        >
          <Input
            name="bic"
            placeholder={t('form.required')}
            autoComplete="off"
          />
        </Form.Item>
      ) : null}

      {showIDCardFields ? (
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                name="idCardNumber"
                required
                label={t('edit_employee_profile_form.id_card_number.label')}
              >
                <Input
                  name="idCardNumber"
                  placeholder={t('form.required')}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="idCardExpiresOn"
                required
                label={t('edit_employee_profile_form.id_card_expires_on.label')}
              >
                <DatePicker
                  name="idCardExpiresOn"
                  format="dd-MM-yyyy"
                  placeholder={t('form.required')}
                  style={{ width: '100%' }}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Spin spinning={isUploadingImage.idCardFrontPicture}>
                <Form.Item
                  name="idCardFrontPicture"
                  required
                  label={t(
                    'edit_employee_profile_form.id_card_front_picture.label'
                  )}
                >
                  <DocumentUpload
                    listType="picture-card"
                    action={handleImageUpload('idCardFrontPicture')}
                    beforeUpload={handlePictureBeforeUpload(
                      'idCardFrontPicture'
                    )}
                    fileList={[]}
                    accept=".png, .jpg, .jpeg"
                  >
                    {employee?.profile?.idCardFrontPicture ? (
                      <img
                        src={employee.profile.idCardFrontPicture}
                        alt="avatar"
                        style={{ width: '100%', maxHeight: '100%' }}
                      />
                    ) : (
                      uploadImageButton
                    )}
                  </DocumentUpload>
                </Form.Item>
              </Spin>
            </Col>
            <Col span={12}>
              <Spin spinning={isUploadingImage.idCardBackPicture}>
                <Form.Item
                  name="idCardBackPicture"
                  required
                  label={t(
                    'edit_employee_profile_form.id_card_back_picture.label'
                  )}
                >
                  <DocumentUpload
                    listType="picture-card"
                    action={handleImageUpload('idCardBackPicture')}
                    beforeUpload={handlePictureBeforeUpload(
                      'idCardBackPicture'
                    )}
                    fileList={[]}
                    accept=".png, .jpg, .jpeg"
                  >
                    {employee?.profile?.idCardBackPicture ? (
                      <img
                        src={employee.profile.idCardBackPicture}
                        alt="avatar"
                        style={{ width: '100%', maxHeight: '100%' }}
                      />
                    ) : (
                      uploadImageButton
                    )}
                  </DocumentUpload>
                </Form.Item>
              </Spin>
            </Col>
          </Row>
        </>
      ) : null}

      {showWorkPermitFields ? (
        <>
          <Form.Item
            name="workPermitExpiresOn"
            required
            label={t('edit_employee_profile_form.work_permit_expires_on.label')}
          >
            <DatePicker
              name="workPermitExpiresOn"
              format="dd-MM-yyyy"
              placeholder={t('form.required')}
              style={{ width: '100%' }}
              autoComplete="off"
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Spin spinning={isUploadingImage.workPermitFrontPicture}>
                <Form.Item
                  name="workPermitFrontPicture"
                  required
                  label={t(
                    'edit_employee_profile_form.work_permit_front_picture.label'
                  )}
                >
                  <DocumentUpload
                    listType="picture-card"
                    action={handleImageUpload('workPermitFrontPicture')}
                    beforeUpload={handlePictureBeforeUpload(
                      'workPermitFrontPicture'
                    )}
                    fileList={[]}
                    accept=".png, .jpg, .jpeg"
                  >
                    {employee?.workProfile?.workPermitFrontPicture ? (
                      <img
                        src={employee.workProfile.workPermitFrontPicture}
                        alt="avatar"
                        style={{ width: '100%', maxHeight: '100%' }}
                      />
                    ) : (
                      uploadImageButton
                    )}
                  </DocumentUpload>
                </Form.Item>
              </Spin>
            </Col>
            <Col span={12}>
              <Spin spinning={isUploadingImage.workPermitBackPicture}>
                <Form.Item
                  name="workPermitBackPicture"
                  required
                  label={t(
                    'edit_employee_profile_form.work_permit_back_picture.label'
                  )}
                >
                  <DocumentUpload
                    listType="picture-card"
                    action={handleImageUpload('workPermitBackPicture')}
                    beforeUpload={handlePictureBeforeUpload(
                      'workPermitBackPicture'
                    )}
                    fileList={[]}
                    accept=".png, .jpg, .jpeg"
                  >
                    {employee?.workProfile?.workPermitBackPicture ? (
                      <img
                        src={employee.workProfile.workPermitBackPicture}
                        alt="avatar"
                        style={{ width: '100%', maxHeight: '100%' }}
                      />
                    ) : (
                      uploadImageButton
                    )}
                  </DocumentUpload>
                </Form.Item>
              </Spin>
            </Col>
          </Row>
        </>
      ) : null}

      {showResidencePermitFields ? (
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                name="residencePermitCode"
                required
                label={t(
                  'edit_employee_profile_form.residence_permit_code.label'
                )}
              >
                <Input
                  name="residencePermitCode"
                  placeholder={t('form.required')}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="residencePermitExpiresOn"
                required
                label={t(
                  'edit_employee_profile_form.residence_permit_expires_on.label'
                )}
              >
                <DatePicker
                  name="residencePermitExpiresOn"
                  format="dd-MM-yyyy"
                  placeholder={t('form.required')}
                  style={{ width: '100%' }}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Spin spinning={isUploadingImage.residencePermitFrontPicture}>
                <Form.Item
                  name="residencePermitFrontPicture"
                  required
                  label={t(
                    'edit_employee_profile_form.residence_permit_front_picture.label'
                  )}
                >
                  <DocumentUpload
                    listType="picture-card"
                    action={handleImageUpload('residencePermitFrontPicture')}
                    beforeUpload={handlePictureBeforeUpload(
                      'residencePermitFrontPicture'
                    )}
                    fileList={[]}
                    accept=".png, .jpg, .jpeg"
                  >
                    {employee?.workProfile?.residencePermitFrontPicture ? (
                      <img
                        src={employee.workProfile.residencePermitFrontPicture}
                        alt="avatar"
                        style={{ width: '100%', maxHeight: '100%' }}
                      />
                    ) : (
                      uploadImageButton
                    )}
                  </DocumentUpload>
                </Form.Item>
              </Spin>
            </Col>
            <Col span={12}>
              <Spin spinning={isUploadingImage.residencePermitBackPicture}>
                <Form.Item
                  name="residencePermitBackPicture"
                  required
                  label={t(
                    'edit_employee_profile_form.residence_permit_back_picture.label'
                  )}
                >
                  <DocumentUpload
                    listType="picture-card"
                    action={handleImageUpload('residencePermitBackPicture')}
                    beforeUpload={handlePictureBeforeUpload(
                      'residencePermitBackPicture'
                    )}
                    fileList={[]}
                    accept=".png, .jpg, .jpeg"
                  >
                    {employee?.workProfile?.residencePermitBackPicture ? (
                      <img
                        src={employee.workProfile.residencePermitBackPicture}
                        alt="avatar"
                        style={{ width: '100%', maxHeight: '100%' }}
                      />
                    ) : (
                      uploadImageButton
                    )}
                  </DocumentUpload>
                </Form.Item>
              </Spin>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  )
}

export const DocumentUpload = styled(Upload)`
  & .ant-upload {
    width: 100% !important;
    height: 160px !important;
  }
`
