import {
  FC,
  createContext,
  useContext,
  useMemo,
  PropsWithChildren
} from 'react'
import { Position } from '../typings/Position'
import { PositionApprovalStatesEnum } from '../typings/PositionApprovalStatesEnum'

export type PositionsContextValue = Position | null

export const PositionFormPositionContext =
  createContext<PositionsContextValue>(null)

export function usePosition() {
  return useContext<PositionsContextValue>(PositionFormPositionContext)
}

export function useIsApprovedPosition() {
  const position = usePosition()

  return useMemo(
    () => position?.state === PositionApprovalStatesEnum.APPROVED,
    [position?.state]
  )
}

interface PositionProviderProps {
  position: Position | null
}

export const PositionProvider: FC<PropsWithChildren<PositionProviderProps>> = ({
  children,
  position
}) => {
  const context = useMemo((): PositionsContextValue => position, [position])

  return (
    <PositionFormPositionContext.Provider value={context}>
      {children}
    </PositionFormPositionContext.Provider>
  )
}
