import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'

export type ContainerProps = HTMLAttributes<HTMLLIElement>

export const Container = styled('li')<ContainerProps>`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
`
