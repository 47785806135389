import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const TimeSquareSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 7.916C3.5 5.233 5.135 3.5 7.665 3.5H16.334C18.865 3.5 20.5 5.233 20.5 7.916V16.084C20.5 18.767 18.864 20.5 16.333 20.5H7.665C5.135 20.5 3.5 18.767 3.5 16.084V7.916ZM7.665 22H16.333C19.723 22 22 19.622 22 16.084V7.916C22 4.378 19.723 2 16.334 2H7.665C4.276 2 2 4.378 2 7.916V16.084C2 19.622 4.276 22 7.665 22ZM15.0061 14.6625C15.1271 14.7335 15.2591 14.7675 15.3901 14.7675C15.6451 14.7675 15.8941 14.6365 16.0351 14.4015C16.2471 14.0455 16.1301 13.5855 15.7751 13.3725L12.7491 11.5695V7.63354C12.7491 7.21954 12.4131 6.88354 11.9991 6.88354C11.5851 6.88354 11.2491 7.21954 11.2491 7.63354V11.9955C11.2491 12.2585 11.3891 12.5035 11.6151 12.6395L15.0061 14.6625Z"
    />
  </svg>
)

export const TimeSquare = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={TimeSquareSvg} {...props} ref={ref} />
)
