import { FC, ReactNode } from 'react'
import styled from '@emotion/styled'
import { createElement } from '@vivaldis/common'
import { css } from '@emotion/react'
import { Divider, TextStylePreset, Typography } from '@vivaldis/antd-ui'
import { ComponentType, ReactElement } from 'react'
import { useGoBackTo } from '../goBackTo/hooks/useGoBackTo'
import { useIsInSideSheet } from '../SideSheets/hooks/useIsInSideSheet'
import { ZIndex } from '../styles/zIndex'
import { ActionButton } from './components/ActionButton'
import { ActionButtons } from './components/ActionButtons'
import { MenuButton } from './components/MenuButton'
import { CloseButton } from './components/CloseButton'
import { BackButton } from './components/BackButton'

export interface NavigationBarProps {
  leftContent?: ComponentType | ReactElement | null
  rightContent?: ComponentType | ReactElement | null
  icon?: ReactNode
  title: ReactElement | string | null
}

const NavigationBarBase: FC<NavigationBarProps> = ({
  leftContent = '__DEFAULT__',
  rightContent,
  icon,
  title
}) => {
  const isInSideSheet = useIsInSideSheet()
  const goBackTo = useGoBackTo()

  if (leftContent === '__DEFAULT__') {
    if (isInSideSheet || goBackTo) {
      leftContent = BackButton
    } else {
      leftContent = MenuButton
    }
  }
  if (typeof title === 'string') {
    title = <Title preset={TextStylePreset.Semibold16}>{title}</Title>
  }
  return (
    <Container isInSideSheet={isInSideSheet}>
      <Wrapper isInSideSheet={isInSideSheet}>
        <LeftContentContainer>
          {createElement(leftContent)}
        </LeftContentContainer>
        {isInSideSheet ? null : icon ? (
          <>
            <Divider
              type="vertical"
              style={{ height: 20, top: 0, margin: '0 4.5px' }}
            />
            {icon}
          </>
        ) : null}
        <TitleContainer isInSideSheet={isInSideSheet}>{title}</TitleContainer>
        <RightContentContainer>
          {createElement(rightContent)}
        </RightContentContainer>
      </Wrapper>
    </Container>
  )
}

export const NavigationBar = Object.assign(NavigationBarBase, {
  ActionButton,
  ActionButtons,
  MenuButton,
  CloseButton,
  BackButton
})

const Container = styled('div')<{ isInSideSheet: boolean }>`
  height: 56px;
  position: relative;

  ${({ theme, isInSideSheet }) =>
    isInSideSheet
      ? css`
          background-color: ${theme.white};

          // This fixed an issue with border-radius that would show a slight white line on the corners
          &:after {
            position: absolute;
            content: '';
            position: absolute;
            top: -29px;
            left: 0;
            right: 0;
            height: 56px;
            background-color: ${theme.primaryColor};
          }
        `
      : ''}
`

const Wrapper = styled('div')<{ isInSideSheet: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  padding: 16px;
  z-index: ${ZIndex.navigationBar};
  box-sizing: border-box;
  background: ${({ theme }) => theme.white};
  border-bottom: 2px solid ${({ theme }) => theme.gray4};
  display: flex;
  align-items: center;

  ${({ isInSideSheet }) =>
    isInSideSheet
      ? css`
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        `
      : ''}
`

const LeftContentContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

const RightContentContainer = styled('div')`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const TitleContainer = styled('div')<{ isInSideSheet: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${({ isInSideSheet }) => (isInSideSheet ? 8 : 6)}px;
  overflow: hidden;
`

const Title = styled(Typography.Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

// in SideSheet   -> "main icon" -> padding(8) -> title -> rightContract
// in full screen -> "main icon" -> padding(6) -> title -> rightContent
// in full screen -> "main icon" -> divider with padding -> "custom icon" -> padding(6) -> title -> rightContent
