import { FC } from 'react'
import { Information } from '../Information'
import { Bool } from './components/Bool'
import { BoolWithText } from './components/BoolWithText'
import { Text } from './components/Text'
import type { CompanySurvey } from './types/CompanySurvey'

interface Props {
  companySurvey: CompanySurvey
}

export const ViewSurveyScreenContent: FC<Props> = ({ companySurvey }) => {
  return (
    <Information>
      {companySurvey.questions.map(question => {
        switch (question.questionType) {
          case 'bool_with_text': {
            return <BoolWithText question={question} />
          }
          case 'text': {
            return <Text question={question} />
          }
          case 'bool': {
            return <Bool question={question} />
          }
          default: {
            return (
              <Information.InformationField
                label={`THIS QUESTION TYPE IS NOT IMPLEMENTED (${question.questionType})`}
              >
                :(
              </Information.InformationField>
            )
          }
        }
      })}
    </Information>
  )
}
