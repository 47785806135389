import { createElement } from '@vivaldis/common'
import { FC } from 'react'
import { Container } from './components/Container'
import { ActionBarProps } from './typings/ActionBarProps'
import { LeftContentContainer } from './components/LeftContentContainer'
import { RightContentContainer } from './components/RightContentContainer'

const ActionBarBase: FC<ActionBarProps> = ({
  container = Container,
  leftContent,
  leftContentContainer = LeftContentContainer,
  rightContent,
  rightContentContainer = RightContentContainer
}) => {
  return createElement(
    container,
    undefined,
    <>
      {createElement(
        leftContentContainer,
        undefined,
        createElement(leftContent)
      )}
      {createElement(
        rightContentContainer,
        undefined,
        createElement(rightContent)
      )}
    </>
  )
}

export const ActionBar = Object.assign(ActionBarBase, { Container })
