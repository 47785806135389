import type { PositionFormStep3Values } from '../steps/Step3/typings/PositionFormStep3Values'
import type { Position } from '../typings/Position'

export const positionToStep3FormValues = (
  position: Position
): PositionFormStep3Values => {
  return {
    premiumIds:
      position?.positionPremiums.map(
        positionPremium => positionPremium.premium.id
      ) || [],
    premiumValuesInput:
      position?.positionPremiums.map(positionPremium => ({
        premiumId: positionPremium.premium.id,
        value: positionPremium.value,
        contributionValue: positionPremium.contributionValue,
        unit: positionPremium.unit
      })) || [],
    //
    useNettoPremiumComment: position.nettoPremiumComment ? true : false,
    nettoPremiumComment: position.nettoPremiumComment ?? null,
    //
    useEcoChequeComment: position.ecoChequeComment ? true : false,
    ecoChequeComment: position.ecoChequeComment ?? null
  }
}
