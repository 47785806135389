import { gql } from '@apollo/client'
import { committeeFragment } from './CommitteeFragment'
import { functionCodeFragment } from './FunctionCodeFragment'

export const contractDatumFragment = gql`
  ${committeeFragment}
  ${functionCodeFragment}

  fragment ContractDatumFragment on ContractDatum {
    committee {
      ...CommitteeFragment
    }
    functionCode {
      ...FunctionCodeFragment
    }
    id
    isFlexi
    noVatApplicable
    vivaldisOffice
  }
`
