export class Locale {
  private static value: string

  public static set(value: string) {
    Locale.value = value
  }

  public static get(): string {
    return Locale.value
  }
}
