import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionBar as BaseActionBar } from '../../../ActionBar'
import { SubmitButton } from '../../../SubmitButton'
import { useForm } from '../../provider/FormProvider'
import { EmployeeCertificates } from '../../types/EmployeeCertificates'

interface Props {
  employeeCertificate?: EmployeeCertificates | null
}

export const ActionBar: FC<Props> = ({ employeeCertificate }) => {
  const [t] = useTranslation('web_ui')
  const { isSubmitting } = useForm()

  const buttonText = useMemo(() => {
    if (employeeCertificate?.id) {
      return t('employee_certificate_form.update_employee_certificate_button')
    }
    return t('employee_certificate_form.create_employee_certificate_button')
  }, [employeeCertificate?.id, t])

  return (
    <BaseActionBar
      rightContent={
        <SubmitButton isSubmitting={isSubmitting} label={buttonText} />
      }
    />
  )
}
