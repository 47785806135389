import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { HTMLAttributes } from 'react'
import { accessible } from '../../../Accessibility'
import { padding, PaddingProps } from '../../../styles/padding'
import { flex, FlexProps } from '../../../styles/flex'

export type ContentContainerProps = HTMLAttributes<HTMLElement> & {
  selected?: boolean
} & PaddingProps &
  FlexProps

export const ContentContainer = styled('div')<ContentContainerProps>`
  appearance: none;
  border: none;
  flex: 1;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  ${props => padding(props)};
  margin-top: -1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 38px;
  text-decoration: none;
  position: relative;
  text-align: left;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0px;
    height: 1px;
    background-color: #ebf4f7;
    z-index: -1;
    pointer-events: none;
  }
  ${props => flex(props)}
`

export const ActionableContentContainer = accessible(
  styled(ContentContainer)`
    cursor: pointer;
    overflow: hidden;
    background-color: transparent;
    padding-left: 16px;
    ${props =>
      props.selected
        ? css`
            padding-left: 14px;
            border-left: 2px solid ${props.theme.primaryColor};
            box-sizing: border-box;
            background-color: ${props.theme.gray4};
          `
        : css``}
    &:hover {
      background-color: ${props =>
        props.selected ? props.theme.gray4 : '#F8F8FA'};
    }
  `.withComponent('button')
)
