import { Country, localizedCountryNameSelector } from '../utils/country'
import { useLocale } from '../locale/hooks/useLocale'

export const useCountryName = (country?: Country | null): string | null => {
  const locale = useLocale()
  if (!country) {
    return null
  }

  return localizedCountryNameSelector(locale, country) || null
}
