import { Navigate, Route, Routes as RoutesBase } from 'react-router-dom'
import { Suspense, FC, useMemo } from 'react'
import { useHasSession, useUser } from '@vivaldis/session'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'
import { ProtectedRoute } from '../components/ProtectedRoute'
import { useCompanyHasPayrollAccess } from '../hooks/useCompanyHasPayrollAccess'
import { useCompanyHasSelectionAccess } from '../hooks/useCompanyHasSelectionAccess'
import { AcceptTermsAndConditionsModal } from '../components/AcceptTermsAndConditionsModal'
import { useUserHasWebAccess } from '../hooks/useUserHasWebAccess'
import { lazyOrReloadPage } from '../utils/lazyOrReloadPage'
import { AuthRoutes } from './Auth/routes'
import { ContractsRoutes } from './Contracts/routes'
import { EmployeeRoutes } from './Employee/routes'
import { HoursValidationRoutes } from './HoursValidation/routes'
import { InvoicesRoutes } from './Invoices/routes'
import { PositionsRoutes } from './Positions/routes'
import { SettingsRoutes } from './Settings/routes'
import { TeamRoutes } from './Team/routes'
import { SurveysRoutes } from './Surveys/routes'

const FAQ = lazyOrReloadPage(
  () => import(/* webpackChunkName: "FAQ" */ './FAQ')
)

const NoCompanySelected = lazyOrReloadPage(
  () =>
    import(/* webpackChunkName: "NoCompanySelected" */ './NoCompanySelected')
)
const NotFound = lazyOrReloadPage(
  () => import(/* webpackChunkName: "NotFound" */ './NotFound')
)
const Onboarding = lazyOrReloadPage(
  () => import(/* webpackChunkName: "Onboarding" */ './Onboarding')
)
const VivaldisPayrollPromo = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "VivaldisPayrollPromo" */ './VivaldisPayrollPromo'
    )
)
const VivaldisSelectionPromo = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "VivaldisSelectionPromo" */ './VivaldisSelectionPromo'
    )
)

export const Routes: FC = () => {
  const user = useUser()

  const companyHasSelectionAccess = useCompanyHasSelectionAccess()
  const companyHasPayrollAccess = useCompanyHasPayrollAccess()
  const hasSession = useHasSession()

  const userHasHoursValidationAccess = useUserHasWebAccess()
  const userHasSelectionTeamAccess = useUserHasWebAccess()

  const homeUrl = useMemo(() => {
    // user is on "Lead" level
    if (!companyHasSelectionAccess && !companyHasPayrollAccess) {
      if (userHasHoursValidationAccess) {
        return '/hours-validation'
      }
      return '/vivaldis-payroll-promo'
    }

    // user has SELECTION and PAYROLL access
    if (companyHasSelectionAccess && companyHasPayrollAccess) {
      return '/positions'
    }

    if (companyHasSelectionAccess) {
      if (userHasHoursValidationAccess) {
        return '/hours-validation'
      }
      return '/invoices'
    }

    if (companyHasPayrollAccess) {
      return '/positions'
    }

    return '/faq'
  }, [
    companyHasPayrollAccess,
    companyHasSelectionAccess,
    userHasHoursValidationAccess
  ])

  // we force user to finish onboarding when company doesn't have VAT (this mean that onboarding was not passed yet)
  const showOnboarding = useMemo(
    () => !user?.company?.vat,
    [user?.company?.vat]
  )

  // we force user to select company (when he was removed from other company). It's when user doesn't have company (company === null)
  const showSelectCompany = useMemo(
    () => user?.company === null,
    [user?.company]
  )

  const showAcceptTermsAndConditions = useMemo(
    () => user?.acceptedTermsAndConditionsAt === null,
    [user?.acceptedTermsAndConditionsAt]
  )

  if (!hasSession) {
    return <AuthRoutes />
  }

  if (showSelectCompany) {
    return (
      <Suspense fallback={ProgressBarSuspenseFallback}>
        <RoutesBase>
          <Route path="/no-company-selected" element={<NoCompanySelected />} />
          <Route path="/register-additional-company" element={<Onboarding />} />
          <Route
            path="*"
            element={<Navigate to="/no-company-selected" replace />}
          />
        </RoutesBase>
      </Suspense>
    )
  }

  if (showOnboarding) {
    return (
      <Suspense fallback={ProgressBarSuspenseFallback}>
        <RoutesBase>
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="*" element={<Navigate to="/onboarding" replace />} />
        </RoutesBase>
      </Suspense>
    )
  }

  if (showAcceptTermsAndConditions) {
    return <AcceptTermsAndConditionsModal />
  }

  return (
    <Suspense fallback={ProgressBarSuspenseFallback}>
      <RoutesBase key={`company-${user?.company?.id}`}>
        {/* Redirects */}
        <Route path="/" element={<Navigate to={homeUrl} replace />} />
        <Route path="/login" element={<Navigate to="/" replace />} />
        <Route path="/registration" element={<Navigate to="/" replace />} />
        <Route path="/forgot-password" element={<Navigate to="/" replace />} />
        <Route path="/reset-password" element={<Navigate to="/" replace />} />
        <Route
          path="/set-your-password"
          element={<Navigate to="/" replace />}
        />
        <Route
          path="/staff" // from old URL in emails to a new URL
          element={<Navigate to="/team" replace />}
        />
        <Route path="/onboarding" element={<Navigate to="/" replace />} />
        <Route
          path="/no-company-selected"
          element={<Navigate to="/" replace />}
        />

        {/* Routes */}
        <Route
          path="/positions/*"
          element={
            <ProtectedRoute isAllowed={companyHasPayrollAccess}>
              <PositionsRoutes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/team/*"
          element={
            <ProtectedRoute
              isAllowed={companyHasPayrollAccess || userHasSelectionTeamAccess}
            >
              <TeamRoutes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contracts/*"
          element={
            <ProtectedRoute
              isAllowed={companyHasPayrollAccess || userHasSelectionTeamAccess}
            >
              <ContractsRoutes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/surveys/*"
          element={
            <ProtectedRoute isAllowed={companyHasPayrollAccess}>
              <SurveysRoutes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hours-validation/*"
          element={
            <ProtectedRoute isAllowed={userHasHoursValidationAccess}>
              <HoursValidationRoutes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employee/*"
          element={
            <ProtectedRoute isAllowed={companyHasPayrollAccess}>
              <EmployeeRoutes />
            </ProtectedRoute>
          }
        />
        <Route path="/invoices/*" element={<InvoicesRoutes />} />
        <Route path="/settings/*" element={<SettingsRoutes />} />
        <Route path="/register-additional-company" element={<Onboarding />} />

        <Route
          path="/vivaldis-payroll-promo"
          element={
            <ProtectedRoute isAllowed={!companyHasPayrollAccess}>
              <VivaldisPayrollPromo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vivaldis-selection-promo"
          element={
            <ProtectedRoute isAllowed={!companyHasSelectionAccess}>
              <VivaldisSelectionPromo />
            </ProtectedRoute>
          }
        />
        <Route path="/faq" element={<FAQ />} />
        {/* Not found */}
        <Route path="*" element={<NotFound />} />
      </RoutesBase>
    </Suspense>
  )
}

// We use /set-your-password?email=mail@gmain.com&token=tokenValue_X_Y_Z in email
// We use /forgot-password?=tokenValue_X_Y_Z in email
// We use /surveys?sidePanelAction=openSurvey&surveyId=XXXX in email
// We use /hours-validation in email from 4D
// We use /staff?name=EmployeeE15R in email (Kandidaat is klaar voor contracten)
