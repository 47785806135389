import { ComponentType, FC, ReactElement } from 'react'
import { createElement } from '@vivaldis/common'
import { ActionContainer } from './components/ActionContainer'
import { Caption } from './components/Caption'
import { Container as ContainerComponent } from './components/Container'
import { Image } from './components/Image'
import { SquaredImage } from './components/SquaredImage'
import { Title } from './components/Title'

export interface EmptyStateProps {
  Container?: ComponentType<any> | ReactElement | null
  title?: string | ComponentType<any> | ReactElement | null
  caption?: string | ComponentType<any> | ReactElement | null
  image?: string | ComponentType<any> | ReactElement | null
  action?: ReactElement | null
}

const EmptyStateBase: FC<EmptyStateProps> = ({
  Container = ContainerComponent,
  title,
  caption,
  image,
  action
}) => {
  return createElement(
    Container,
    undefined,
    createElement(
      typeof image === 'string' ? Image : image,
      typeof image === 'string' ? { src: image, alt: 'img' } : undefined
    ),
    createElement(
      typeof title === 'string' ? Title : title,
      undefined,
      typeof title === 'string' ? title : undefined
    ),
    createElement(
      typeof caption === 'string' ? Caption : caption,
      undefined,
      typeof caption === 'string' ? caption : undefined
    ),
    action ? createElement(ActionContainer, undefined, action) : null
  )
}

export const EmptyState = Object.assign(EmptyStateBase, {
  Image,
  SquaredImage,
  Caption,
  Container: ContainerComponent,
  Title
})
