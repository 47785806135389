// based on https://github.com/jaredreich/pell - (1kB) WYSIWYG text editor for web, with no dependencies.
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  KeyboardEvent
} from 'react'

import {
  BoldOutlined,
  ItalicOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'
import { useTheme } from '@vivaldis/ui'
import styled from '@emotion/styled'
import { Container } from '../Container'
import { Control } from '../Control'
import { ControlBar } from '../ControlBar'

const formatBlock = 'formatBlock'
const queryCommandState = (command: string) =>
  document.queryCommandState(command)
const queryCommandValue = (command: string) =>
  document.queryCommandValue(command)

const exec = (command: string, value: any = null) =>
  document.execCommand(command, false, value)

interface ControlConfig {
  icon: any
  result: () => void
  state: () => boolean
  title: string
}

export interface PellEditorProps {
  value: string
  onChange: (value: string) => any
  onBlur?: () => any
  disabled?: boolean
  placeholder?: string
  errorState?: boolean
}

const defaultParagraphSeparator = 'div'

export const PellEditor: FC<PellEditorProps> = ({
  value,
  onChange,
  onBlur,
  disabled,
  placeholder,
  errorState
}) => {
  const theme = useTheme()
  const contentRef = useRef<HTMLDivElement>(null)
  const [, forceUpdate] = useState<number>()

  useEffect(() => {
    const currentRef = contentRef.current as any
    if (currentRef && !currentRef.inited) {
      currentRef.inited = true
      currentRef.innerHTML = value
    }
  }, [value])

  const handleOnInput = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { firstChild } = target
      const innerHTML = contentRef.current && contentRef.current.innerHTML
      if (firstChild && firstChild.nodeType === 3) {
        exec(formatBlock, `<div>`)
      } else if (innerHTML === '<br>') {
        if (contentRef.current) {
          contentRef.current.innerHTML = ''
        }
        return onChange('')
      }

      onChange(innerHTML || '')
    },
    [onChange]
  )

  const handleFocus = useCallback(() => {
    contentRef?.current?.focus()
  }, [])

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (
      event.key === 'Enter' &&
      queryCommandValue(formatBlock) === 'blockquote'
    ) {
      setTimeout(() => exec(formatBlock, `<${defaultParagraphSeparator}>`), 0)
    }
  }, [])

  const controls: ControlConfig[] = [
    {
      icon: <BoldOutlined style={{ fontSize: 20 }} />,
      result: () => exec('bold'),
      state: () => queryCommandState('bold'),
      title: 'Bold'
    },
    {
      icon: <ItalicOutlined style={{ fontSize: 20 }} />,
      result: () => exec('italic'),
      state: () => queryCommandState('italic'),
      title: 'Italic'
    },
    {
      icon: <UnorderedListOutlined style={{ fontSize: 20 }} />,
      result: () => exec('insertUnorderedList'),
      state: () => queryCommandState('insertUnorderedList'),
      title: 'list'
    }
  ]

  return (
    <Container
      borderColor={errorState ? theme.red6 : theme.gray4}
      marginBottom={errorState ? 8 : 0}
      className={'ant-input'}
      disabled={disabled}
    >
      <ControlBar>
        {controls.map(c => (
          <Control
            key={c.title}
            title={c.title}
            icon={c.icon}
            selected={c.state()}
            onClick={() => {
              handleFocus()
              c.result()
              handleFocus()
              forceUpdate(Date.now())
            }}
          />
        ))}
      </ControlBar>
      <EditorInput
        contentEditable={!disabled}
        suppressContentEditableWarning
        ref={contentRef}
        onInput={handleOnInput}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        data-placeholder={placeholder}
      />
    </Container>
  )
}

const EditorInput = styled.div`
  box-sizing: border-box;
  height: 108px;
  outline: 0;
  overflow-y: auto;
  padding: 16px;
  resize: vertical;
  :empty:not(:focus) {
    ::after {
      content: attr(data-placeholder) !important;
      white-space: pre;
      cursor: text;
      font-family: OpenSans-Regular;
      font-size: 16px;
      color: #c1cbd5;
      letter-spacing: 0.31px;
    }
  }
`
