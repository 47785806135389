import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const WallerSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99763 4.5C5.51763 4.5 3.49963 6.518 3.49963 8.998V16.175C3.49963 18.655 5.51763 20.673 7.99763 20.673H16.6416C19.1216 20.673 21.1386 18.655 21.1386 16.175V15.8955H17.8407C15.9437 15.8955 14.3997 14.3525 14.3987 12.4565C14.3987 10.5585 15.9427 9.01455 17.8407 9.01355H21.1386V8.998C21.1386 6.518 19.1216 4.5 16.6416 4.5H7.99763ZM22.6387 15.1455L22.6386 15.1541V16.175C22.6386 19.482 19.9486 22.173 16.6416 22.173H7.99763C4.69063 22.173 1.99963 19.482 1.99963 16.175V8.998C1.99963 5.69 4.69063 3 7.99763 3H16.6416C19.9486 3 22.6386 5.69 22.6386 8.998V9.75501L22.6387 9.76355L22.6386 9.77209V15.137L22.6387 15.1455ZM21.1386 14.3955V10.5135H17.8407C16.7697 10.5145 15.8987 11.3855 15.8987 12.4555C15.8987 13.5245 16.7707 14.3955 17.8407 14.3955H21.1386ZM17.9863 13.1436H18.2983C18.7123 13.1436 19.0483 12.8076 19.0483 12.3936C19.0483 11.9796 18.7123 11.6436 18.2983 11.6436H17.9863C17.5723 11.6436 17.2363 11.9796 17.2363 12.3936C17.2363 12.8076 17.5723 13.1436 17.9863 13.1436ZM7.28564 9.03809H12.6846C13.0986 9.03809 13.4346 8.70209 13.4346 8.28809C13.4346 7.87409 13.0986 7.53809 12.6846 7.53809H7.28564C6.87164 7.53809 6.53564 7.87409 6.53564 8.28809C6.53564 8.70209 6.87164 9.03809 7.28564 9.03809Z"
    />
  </svg>
)

export const Waller = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={WallerSvg} {...props} ref={ref} />
)
