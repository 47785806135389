import { useField } from 'formik'
import { FC, useCallback } from 'react'
import { FormikFieldProps } from '../typings/FieldProps'
import {
  HoursAndMinutesTimeInput,
  HoursAndMinutesTimeInputProps
} from './components/HoursAndMinutesTimeInput'

export type HoursAndMinutesTimePickerProps = FormikFieldProps &
  Omit<HoursAndMinutesTimeInputProps, 'value' | 'onChange'> & {
    value?: Date | null
    onChange?: (value: Date | null) => void
  }

export const HoursAndMinutesTimePicker: FC<HoursAndMinutesTimePickerProps> = ({
  name,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  value: valueProp,
  ...restProps
}) => {
  const [field, , helpers] = useField(name)
  const { value, onBlur } = field
  const { setValue } = helpers

  const handleBlur = useCallback(
    (event: any) => {
      onBlur(event)
      onBlurProp?.(event)
    },
    [onBlur, onBlurProp]
  )

  const handleChange = useCallback(
    (value: Date | null) => {
      setValue(value)
      onChangeProp?.(value)
    },
    [onChangeProp, setValue]
  )

  return (
    <HoursAndMinutesTimeInput
      name={name}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      {...restProps}
    />
  )
}
