import { useBreakpoint } from '@vivaldis/antd-ui'
import { useNavigate } from 'react-router-dom'
import { useCallback, MouseEvent, useContext } from 'react'
import { LinkProps } from 'react-router-dom'
import { useSideSheetsNavigator } from '../SideSheets/hooks/useSideSheetsNavigator'
import { AddEntryContext } from '../goBackTo/context/AddEntryContext'

export function useNavigateOnPress({
  onPress,
  to,
  replace,
  state,
  navigate: doNavigate,
  openInSideSheet,
  goBackTo
}: {
  onPress?: (event: MouseEvent<any>) => void
  to?: LinkProps['to']
  replace?: LinkProps['replace']
  state?: LinkProps['state']
  navigate?: boolean
  openInSideSheet?: boolean
  goBackTo?: string | boolean
}): (event: MouseEvent<any>) => void {
  const breakpoint = useBreakpoint()
  const navigate = useNavigate()
  const { push, replace: replaceFunc } = useSideSheetsNavigator()
  const addGoBackToEntry = useContext(AddEntryContext)

  return useCallback(
    (event: MouseEvent<any>) => {
      const goBackToRef = `${window.location.pathname}${window.location.search}`
      if (onPress) onPress(event)
      if (!event.isDefaultPrevented()) {
        if (to && openInSideSheet && breakpoint.lg) {
          event.preventDefault()
          if (replace) replaceFunc(to, state)
          else push(to, state)
        } else if (doNavigate && to) {
          event.preventDefault()
          navigate(to, { state, replace })
          if (goBackTo) {
            const finalGoBackTo = goBackTo === true ? goBackToRef : goBackTo
            addGoBackToEntry({
              pathname: finalGoBackTo,
              key: window.history.state.key
            })
          }
        } else if (to) {
          if (goBackTo) {
            const finalGoBackTo = goBackTo === true ? goBackToRef : goBackTo
            // When navigating using react-router-dom's link, the new history location is not yet
            // created so put the call at the end of the queue
            setTimeout(() => {
              addGoBackToEntry({
                pathname: finalGoBackTo,
                key: window.history.state.key
              })
            }, 0)
          }
        }
      }
    },
    [
      onPress,
      to,
      openInSideSheet,
      breakpoint.lg,
      doNavigate,
      replace,
      replaceFunc,
      state,
      push,
      navigate,
      goBackTo,
      addGoBackToEntry
    ]
  )
}
