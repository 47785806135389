import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const ShieldDoneSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-label="shield-done"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.66866 5.899C6.28566 5.384 11.6357 3.5 12.4837 3.5C13.3317 3.5 18.6827 5.384 19.2877 5.889C19.4807 6.081 19.4877 6.399 19.4497 8.526C19.4307 9.552 19.4057 10.947 19.4057 12.879C19.4057 18.065 13.6807 20.387 12.4827 20.814C11.2837 20.389 5.56166 18.08 5.56166 12.879C5.56166 10.9789 5.53743 9.59827 5.5195 8.5769L5.51866 8.529C5.47966 6.4 5.48666 6.082 5.66866 5.899ZM12.2667 22.324C12.3377 22.346 12.4107 22.356 12.4837 22.356C12.5567 22.356 12.6297 22.346 12.7007 22.324C13.0357 22.223 20.9057 19.761 20.9057 12.879C20.9057 10.985 20.93 9.60971 20.9479 8.59561L20.9487 8.554L20.9487 8.5494C20.9947 6.01336 21.0043 5.4836 20.3487 4.828C19.5617 4.041 13.6167 2 12.4837 2C11.3497 2 5.40466 4.041 4.61966 4.828C3.96306 5.4836 3.97265 6.01335 4.01857 8.5523L4.01866 8.557L4.0194 8.59867C4.03733 9.61276 4.06166 10.988 4.06166 12.879C4.06166 19.761 11.9317 22.223 12.2667 22.324ZM11.2461 14.2995C11.3871 14.4405 11.5781 14.5195 11.7771 14.5195C11.9761 14.5195 12.1671 14.4405 12.3071 14.2995L16.2051 10.4005C16.4981 10.1075 16.4981 9.6325 16.2051 9.3395C15.9121 9.0465 15.4381 9.0465 15.1451 9.3395L11.7771 12.7075L10.4161 11.3445C10.1231 11.0515 9.64713 11.0515 9.35513 11.3445C9.06213 11.6365 9.06213 12.1125 9.35413 12.4055L11.2461 14.2995Z"
    />
  </svg>
)

export const ShieldDone = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={ShieldDoneSvg} {...props} ref={ref} />
)
