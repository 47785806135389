import {
  UpdateUserMutation,
  UpdateUserMutationVariables
} from './__generated__/UpdateUserMutation'
import { ApolloClient, MutationOptions, FetchResult, gql } from '@apollo/client'
import { userFragment } from '../fragments/UserFragment'

const mutation = gql`
  ${userFragment}

  mutation UpdateUserMutation($input: UpdateUserInput!, $locale: String) {
    updateUser(input: $input) {
      user {
        ...UserFragment
      }
    }
  }
`

export function updateUserMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<UpdateUserMutation, UpdateUserMutationVariables>,
    'mutation'
  >
): Promise<FetchResult<UpdateUserMutation>> {
  return apolloClient.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
    mutation,
    ...options
  })
}
