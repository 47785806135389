import styled from '@emotion/styled'
import { Location } from 'history'
import {
  FC,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { EscapeHandler } from '../../EscapeHandler'
import { SIDE_SHEET_DEFAULT_WIDTH } from '../../Layout/components/SideSheet'
import { useSideSheetsNavigator } from '../hooks/useSideSheetsNavigator'
import { ZIndex } from '../../styles/zIndex'
import { SideSheetRenderer } from './SideSheetRenderer'

const TRANSITION_DURATION = 600

export interface SideSheetProps {
  layer: number
  location: Location
  index: number
  onLeave: () => void
}

export const SideSheet: FC<PropsWithChildren<SideSheetProps>> = ({
  layer,
  index,
  onLeave,
  location,
  children
}) => {
  const initialRender = useRef(layer === 1)
  const navigator = useSideSheetsNavigator()

  let scale = 1
  if (layer === 2) scale = 0.98
  if (layer >= 3) scale = 0.95

  let translateX = 0
  if (initialRender.current || layer <= 0) translateX = 110
  else if (layer === 2) translateX = -4
  else if (layer >= 3) translateX = 0

  const sideSheetWidth = useMemo(() => {
    const state = location?.state as any
    return state?.sideSheetWidth || SIDE_SHEET_DEFAULT_WIDTH
  }, [location?.state])

  useEffect(() => {
    if (layer === 0) {
      const timeoutId = setTimeout(() => {
        onLeave()
      }, TRANSITION_DURATION)
      return () => clearTimeout(timeoutId)
    }
  }, [layer, onLeave])

  const [, forceUpdate] = useState(0)

  useLayoutEffect(() => {
    if (initialRender.current && layer === 1) {
      initialRender.current = false
      const timeoutId = setTimeout(() => forceUpdate(s => s + 1), 0)
      return () => clearTimeout(timeoutId)
    }
  }, [layer])

  const captureClick = useCallback((event: MouseEvent) => {
    // Do not propagate clicks on the side sheet to the overlay
    event.stopPropagation()
  }, [])

  useEffect(() => {
    const handler = () => {
      navigator.back()
      return true
    }
    EscapeHandler.addEventListener('escapePress', handler)
    return () => {
      EscapeHandler.removeEventListener('escapePress', handler)
    }
  }, [navigator])

  return (
    <ShadowContainer
      onClick={captureClick}
      sideSheetWidth={sideSheetWidth}
      style={{
        transform: `scale(${scale}) translateX(${translateX}%)`,
        transition: `transform ${TRANSITION_DURATION}ms ease`
      }}
    >
      <BackgroundContainer>
        <ContentContainer>
          <SideSheetRenderer index={index} location={location}>
            {children}
          </SideSheetRenderer>
        </ContentContainer>
      </BackgroundContainer>
    </ShadowContainer>
  )
}

const ShadowContainer = styled('div')<{ sideSheetWidth: number }>`
  position: absolute;
  z-index: ${ZIndex.SideSheet};
  top: 16px;
  right: 16px;
  bottom: 16px;
  width: ${props => props.sideSheetWidth}px;
  box-shadow:
    0px 5px 20px 0px rgba(0, 0, 0, 0.2),
    0px 5px 30px 0px rgba(107, 11, 11, 0.04);
  border-radius: 8px;
`

const BackgroundContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  overflow: hidden;
`

const ContentContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.gray3};
  z-index: ${ZIndex.SideSheet - 1};
`
