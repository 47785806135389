import { FC } from 'react'
import { Typography as AntdTypography } from 'antd'
import type { TitleProps as AntdTitleProps } from 'antd/es/typography/Title'
import { makeBaseTextComponent } from './BaseText'
import type { BaseTextProps } from './BaseText'

export interface TitleProps extends AntdTitleProps, BaseTextProps {}

export const Title: FC<TitleProps> = ({ ...props }) => {
  return <AntdTypographyTitle {...props} />
}

const AntdTypographyTitle = makeBaseTextComponent(AntdTypography.Title)
