import { Action, Location } from 'history'
import { FC, memo, ReactNode } from 'react'
import { Router } from 'react-router-dom'
import { IsInSideSheetContext } from '../context/IsInSideSheetContext'
import { SideSheetIndexContext } from '../context/SideSheetIndexContext'
import { useSideSheetsNavigator } from '../hooks/useSideSheetsNavigator'

interface Props {
  children: ReactNode
  location: Location
  index: number
}

const MemoizedSideSheetRenderer: FC<Props> = ({
  location,
  children,
  index
}) => {
  const navigator = useSideSheetsNavigator()
  return (
    <IsInSideSheetContext.Provider value={true}>
      <SideSheetIndexContext.Provider value={index}>
        <Router
          navigationType={Action.Pop}
          location={location}
          navigator={navigator}
        >
          {children}
        </Router>
      </SideSheetIndexContext.Provider>
    </IsInSideSheetContext.Provider>
  )
}

export const SideSheetRenderer = memo(MemoizedSideSheetRenderer, () => true)
