import { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

export const Container: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <ContainerBase>
      <Content>{children}</Content>
    </ContainerBase>
  )
}

const ContainerBase = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: center;
  align-items: center;
`

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  @media only screen and (max-width: ${({ theme }) => theme.screenLG - 1}px) {
    max-width: 296px;
  }
`
