import { Divider, Typography } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Contract } from '../../typings/Contract'

interface Props {
  contract: Contract
}

export const Remark: FC<Props> = ({ contract }) => {
  const { t } = useTranslation('web_ui')

  if (!contract?.remark) {
    return null
  }

  return (
    <>
      <Divider />
      <div>
        <Typography.Title level={5} marginBottom={16}>
          {t('view_contract_screen_content.remark')}
        </Typography.Title>
        <Typography.Paragraph>{contract.remark}</Typography.Paragraph>
      </div>
    </>
  )
}
