import {
  FormikContextType,
  FormikProps,
  useFormikContext,
  Formik,
  FormikHelpers
} from 'formik'
import { useMemo, FC, useCallback, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useStep } from '../../../providers/StepProvider'
import { PositionFormStep1Values } from '../typings/PositionFormStep1Values'

export function useForm(): FormikContextType<PositionFormStep1Values> {
  return useFormikContext()
}

export interface PositionFormProviderProps {
  onSubmit: (
    values: PositionFormStep1Values,
    formikHelpers: FormikHelpers<PositionFormStep1Values>
  ) => Promise<any>
  initialValues?: Partial<FormikProps<PositionFormStep1Values>['initialValues']>
  showMinimumWageInput: boolean
  showVivaldisOfficeCodeInput: boolean
}

export const FormProvider: FC<PropsWithChildren<PositionFormProviderProps>> = ({
  onSubmit,
  initialValues,
  children,
  showMinimumWageInput,
  showVivaldisOfficeCodeInput
}) => {
  const [t] = useTranslation()
  const { setStep } = useStep()

  const formInitialValues: PositionFormStep1Values = useMemo(
    () => ({
      name: '',
      functionCodeId: '',
      noVatApplicable: false,
      description: '',
      // location
      onTheMove: false,
      city: '',
      country: '',
      countryId: '',
      latitude: 0,
      longitude: 0,
      street: '',
      streetNumber: '',
      zip: '',
      // SU values for approved position
      minimumWage: undefined,
      vivaldisOfficeCode: undefined,
      ...initialValues
    }),
    [initialValues]
  )

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .label(t('position_form.step1.name.label', { ns: 'web_ui' }))
          .required(),
        functionCodeId: Yup.string()
          .label(
            t('position_form.step1.function_code_id.label', { ns: 'web_ui' })
          )
          .required(),
        noVatApplicable: Yup.boolean()
          .label(
            t('position_form.step1.no_vat_applicable.label', { ns: 'web_ui' })
          )
          .required(),
        description: Yup.string()
          .label(t('position_form.step1.description.label', { ns: 'web_ui' }))
          .min(50)
          .required(),
        // location
        onTheMove: Yup.boolean()
          .label(t('position_form.step1.on_the_move.label', { ns: 'web_ui' }))
          .required(),
        city: Yup.string().when('onTheMove', {
          is: false,
          then: schema => schema.required(t('form.location.errors.city')),
          otherwise: schema => schema.nullable().notRequired()
        }),
        country: Yup.string().when('onTheMove', {
          is: false,
          then: schema => schema.required(t('form.location.errors.country')),
          otherwise: schema => schema.nullable().notRequired()
        }),
        latitude: Yup.number().when('onTheMove', {
          is: false,
          then: schema => schema.required(t('form.location.errors.latitude')),
          otherwise: schema => schema.nullable().notRequired()
        }),
        longitude: Yup.number().when('onTheMove', {
          is: false,
          then: schema => schema.required(t('form.location.errors.longitude')),
          otherwise: schema => schema.nullable().notRequired()
        }),
        street: Yup.string().when('onTheMove', {
          is: false,
          then: schema => schema.required(t('form.location.errors.street')),
          otherwise: schema => schema.nullable().notRequired()
        }),
        streetNumber: Yup.string().when('onTheMove', {
          is: false,
          then: schema =>
            schema.required(t('form.location.errors.street_number')),
          otherwise: schema => schema.nullable().notRequired()
        }),
        zip: Yup.string().when('onTheMove', {
          is: false,
          then: schema => schema.required(t('form.location.errors.zip')),
          otherwise: schema => schema.nullable().notRequired()
        }),
        // SU values for approved position,
        minimumWage: Yup.number()
          .label(t('position_form.step1.minimum_wage.label', { ns: 'web_ui' }))
          .nullable()
          .when({
            is: () => showMinimumWageInput,
            then: schema => schema.required().positive(),
            otherwise: schema => schema.notRequired()
          }),
        vivaldisOfficeCode: Yup.string()
          .label(
            t('position_form.step1.vivaldis_office_code.label', {
              ns: 'web_ui'
            })
          )
          .nullable()
          .when({
            is: () => showVivaldisOfficeCodeInput,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          })
      }),
    [showMinimumWageInput, showVivaldisOfficeCodeInput, t]
  )

  const handleSubmit = useCallback(
    async (
      values: PositionFormStep1Values,
      formikHelpers: FormikHelpers<PositionFormStep1Values>
    ) => {
      await onSubmit(values, formikHelpers)
      formikHelpers.setSubmitting(false)
      setStep(step => step + 1)
    },
    [onSubmit, setStep]
  )

  return (
    <Formik<PositionFormStep1Values>
      enableReinitialize={true}
      initialValues={formInitialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}
