import styled from '@emotion/styled'
import { FC, useCallback } from 'react'
import { Link } from '@vivaldis/web-ui'
import { tracking } from '@vivaldis/tracking'
import { Formik } from 'formik'
import { App, Button, Form, Modal } from '@vivaldis/antd-ui'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Trans, useTranslation } from 'react-i18next'
import { useLocale } from '@vivaldis/common'
import { tBackendErrorMessage } from '@vivaldis/common'
import { acceptTermsAndConditionsMutation } from '../../graphql/mutations/acceptTermsAndConditionsMutation'

export interface FormValues {}

export const AcceptTermsAndConditionsModal: FC = () => {
  const [t] = useTranslation('components')
  const locale = useLocale()
  const { notification } = App.useApp()

  const documentUrl = () => {
    return `https://vivaldispayroll-production.s3.eu-central-1.amazonaws.com/docs/TermsAndConditions-${locale}.pdf`
  }

  const acceptTermsAndConditions = useCallback(async () => {
    try {
      await acceptTermsAndConditionsMutation()
    } catch (error) {
      tracking.error(error)
      notification.error({
        message: tBackendErrorMessage(error)
      })
    }
  }, [notification])

  return (
    <Formik<FormValues>
      initialValues={[]}
      onSubmit={acceptTermsAndConditions}
      children={({ isSubmitting, submitForm }) => (
        <Form
          name="accept-terms-and-conditions-form"
          id="accept-terms-and-conditions-form"
        >
          <Modal
            open={true}
            closable={false}
            okText={'Accept'}
            footer={[
              <Button
                key="submit"
                type="primary"
                loading={isSubmitting}
                onClick={submitForm}
              >
                {t('accept_terms_and_conditions_modal.accept')}
              </Button>
            ]}
          >
            <Container>
              <Icon>
                <ExclamationCircleOutlined />
              </Icon>
              <Content>
                <Title>{t('accept_terms_and_conditions_modal.title')}</Title>
                <Trans i18nKey="components:accept_terms_and_conditions_modal.body">
                  We have recently updated our
                  <Link to={documentUrl()} target="_blank">
                    terms and conditions
                  </Link>
                  . To continue using our services, please read and accept the
                  new terms and conditions. Your acceptance is required to
                  ensure uninterrupted service.
                </Trans>
              </Content>
            </Container>
          </Modal>
        </Form>
      )}
    />
  )
}

const Container = styled('div')`
  display: flex;
  align-items: flex-start;
`

const Icon = styled('div')`
  color: #00b287;
  font-size: 24px;
`

const Content = styled('div')`
  flex: 1;
  margin-left: 16px;
`

const Title = styled('p')`
  padding-top: 7px;
  font-size: 16px;
  font-weight: 600;
`
