import { FC, useMemo } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Alert } from '../Alert'

export interface ErrorStateProps {
  error?: Error
}

export const ErrorState: FC<ErrorStateProps> = ({ error }) => {
  const [t] = useTranslation('antd_ui')

  const message = useMemo(() => {
    if (error) {
      return t([
        `infinite_table.error_state.custom_errors.${(error as any)?.response?.data?.error}.title`,
        `infinite_table.error_state.custom_errors.${error?.message}.title`,
        'infinite_table.error_state.title'
      ])
    }
    return t('infinite_table.error_state.title')
  }, [error, t])

  const description = useMemo(() => {
    if (error) {
      return t([
        `infinite_table.error_state.custom_errors.${(error as any)?.response?.data?.error}.description`,
        `infinite_table.error_state.custom_errors.${error?.message}.description`,
        'infinite_table.error_state.description'
      ])
    }
    return t('infinite_table.error_state.description')
  }, [error, t])

  return (
    <Alert.ErrorBoundary>
      <ErrorMessage
        message={message}
        description={description}
        showIcon
        type="error"
      />
    </Alert.ErrorBoundary>
  )
}

const ErrorMessage = styled(Alert)`
  display: flex;
  text-align: start;
  margin: 16px 0;
`
