import { Typography as AntdTypography } from 'antd'

import { Typography as OriginTypography } from './Typography'
import { Text } from './Text'
import { Link } from './Link'
import { Title } from './Title'
import { Paragraph } from './Paragraph'
export type { TypographyProps } from './Typography'

export const Typography = Object.assign(OriginTypography, {
  ...AntdTypography,
  Text,
  Title,
  Link,
  Paragraph
})
