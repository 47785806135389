import { axios, type AxiosResponse } from '@vivaldis/common'
import { UserType } from '../typings/UserType'
import { Prompt } from '@vivaldis/ui'

export interface SignInRequestData {
  email: string
  password: string
  type: UserType
}

export type SignInResponse =
  | SignInResponseWithAuthenticationToken
  | SignInResponseOtpVerificationToken

export interface SignInResponseWithAuthenticationToken {
  authentication_token: string
  email: string
}

export interface SignInResponseOtpVerificationToken {
  otp_verification_required: boolean
  otp_verification_token: string
}

export const signIn = async (
  data: SignInRequestData
): Promise<AxiosResponse<SignInResponse> | undefined> => {
  let url: string
  let payload: any
  switch (data.type) {
    case UserType.Employee:
      url = '/employees/sign_in'
      payload = {
        employee: {
          email: data.email,
          password: data.password
        },
        get_token: 'true'
      }
      break
    case UserType.User:
    case UserType.SuperUser:
      url = '/users/sign_in'
      payload = {
        user: {
          email: data.email,
          password: data.password
        },
        get_token: 'true'
      }

      break
  }

  try {
    return await axios().post(url!, payload)
  } catch (error: any) {
    if (
      error &&
      error.response &&
      error.response.status.toString().match(/^5.*/)
    ) {
      setTimeout(() => Prompt.serviceUnavailablePrompt(), 0)
      return undefined
    } else if (error.response) {
      throw error
    } else if (error.request) {
      setTimeout(() => Prompt.serviceUnavailablePrompt(), 0)
      return undefined
    } else {
      setTimeout(() => Prompt.unknownErrorPrompt(), 0)
      return undefined
    }
  }
}
