import styled from '@emotion/styled'
import { Layout, Button, Typography, TextStylePreset } from '@vivaldis/antd-ui'
import { ArrowLeft } from '@vivaldis/icons'
import { useScreenTitle, ZIndex } from '@vivaldis/web-ui'
import { FC } from 'react'
import { useMatch } from 'react-router-dom'
import { animated, PickAnimated, SpringValues } from 'react-spring'
import { AvatarAndMenu } from './components/AvatarAndMenu'

interface Props {
  animatedStyles: SpringValues<PickAnimated<{ width: number }>>
}

export const Header: FC<Props> = ({ animatedStyles }) => {
  const isSettingScreens = useMatch('/settings/*')

  const { title } = useScreenTitle()

  return (
    <AnimatedHeader
      style={{
        paddingRight: animatedStyles.width,
        zIndex: ZIndex.navigationBar
      }}
    >
      <Typography.Text preset={TextStylePreset.Semibold20}>
        {isSettingScreens ? (
          <Button
            type="default"
            shape="circle"
            size="large"
            to={'/'}
            style={{
              marginRight: 16
            }}
          >
            <ArrowLeft />
          </Button>
        ) : null}
        {title}
      </Typography.Text>

      <Base>
        <AvatarAndMenu />
      </Base>
    </AnimatedHeader>
  )
}

const AnimatedHeader = styled(animated(Layout.Header))`
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid ${props => props.theme.extraBackground1};
`

const Base = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 16px;
`
