import styled from '@emotion/styled'
import { ErrorListProps } from 'antd/lib/form/ErrorList'
import { FC } from 'react'
import { Form } from '../../Form'

export const ErrorList: FC<ErrorListProps> = ({ ...props }) => {
  return <FormErrorList {...props} />
}

const FormErrorList = styled(Form.ErrorList)`
  margin-bottom: 16px;

  & div.-item-explain-error {
    color: ${props => props.theme.red5};
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
  }
`
