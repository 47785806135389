import styled from '@emotion/styled'
import { FC } from 'react'
import {
  Divider as AntdDivider,
  DividerProps as AntdDividerProps
} from '@vivaldis/antd-ui'

export interface DividerProps extends AntdDividerProps {
  sectionSeparator?: boolean
}

export const Divider: FC<DividerProps> = ({ sectionSeparator, ...props }) => {
  // in size panel some dividers should be in full size (they should ignore 16px padding of side panel)
  if (sectionSeparator) {
    return (
      <Base>
        <AntdDivider {...props} />
      </Base>
    )
  }
  return <AntdDivider {...props} />
}

const Base = styled('div')`
  margin: 0 -16px;
`
