import { Row, Typography } from '@vivaldis/antd-ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Information } from '../../../Information'
import { usePremiumTypeOptions } from '../../../PremiumType'
import { Position } from '../../typings/Position'

interface Props {
  position: Position
}

export const PremiumsInformation: FC<Props> = ({ position }) => {
  const [t] = useTranslation('web_ui')

  const premiums = usePremiumTypeOptions(
    position.positionPremiums.map(positionPremium => positionPremium.premium)
  )

  const positionHasPremiums = useMemo(
    () => position.positionPremiums.length > 0,
    [position.positionPremiums.length]
  )

  return (
    <>
      <Typography.Title level={5} marginBottom={16}>
        {t('view_position_screen_content.premiums')}
      </Typography.Title>
      <Information>
        <Information.InformationField
          label={t('view_position_screen_content.premiums')}
        >
          {positionHasPremiums
            ? premiums.map(premium => (
                <Row key={premium.label} justify="end">
                  {premium.label}
                </Row>
              ))
            : t('no', { ns: 'translation' })}
        </Information.InformationField>

        {position.positionPremiums.map(positionPremium => {
          switch (positionPremium.premium.name) {
            case 'late_shift_bonus': {
              return (
                <Information.InformationField
                  label={t(
                    'view_position_screen_content.premium_types.late_shift_bonus.label'
                  )}
                >
                  {positionPremium.value || 0}{' '}
                  {t(
                    `view_position_screen_content.premium_units.${positionPremium.unit}`
                  )}
                </Information.InformationField>
              )
            }
            case 'night_shift_bonus': {
              return (
                <Information.InformationField
                  label={t(
                    'view_position_screen_content.premium_types.night_shift_bonus.label'
                  )}
                >
                  {positionPremium.value || 0}{' '}
                  {t(
                    `view_position_screen_content.premium_units.${positionPremium.unit}`
                  )}
                </Information.InformationField>
              )
            }

            case 'morning_shift_bonus':
              return (
                <Information.InformationField
                  label={t(
                    'view_position_screen_content.premium_types.morning_shift_bonus.label'
                  )}
                >
                  {positionPremium.value || 0}{' '}
                  {t(
                    `view_position_screen_content.premium_units.${positionPremium.unit}`
                  )}
                </Information.InformationField>
              )
            case 'meal_vouchers':
              return (
                <Information.InformationField
                  label={t(
                    'view_position_screen_content.premium_types.meal_vouchers.label'
                  )}
                >
                  <>
                    {positionPremium.value || 0}{' '}
                    {t(
                      `view_position_screen_content.premium_units.${positionPremium.unit}`
                    )}
                    {' / '}
                    {positionPremium.contributionValue || 0}{' '}
                    {t(
                      `view_position_screen_content.premium_units.${positionPremium.unit}`
                    )}
                  </>
                </Information.InformationField>
              )
          }
          return null
        })}

        <Information.InformationField
          label={t('view_position_screen_content.netto_premium_comment.label')}
        >
          {position.nettoPremiumComment || t('no', { ns: 'translation' })}
        </Information.InformationField>
      </Information>
    </>
  )
}
