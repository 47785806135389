import { Axios, isAxiosError, AxiosError } from '@vivaldis/common'
import { tracking } from '@vivaldis/tracking'

Axios.config(process.env.REACT_APP_API_URL as string)

const axiosInstance = Axios.get()

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    if (config.url?.startsWith('/api/hours_validation')) {
      tracking.info(
        'Axios request to 4D api',
        {
          method: config.method,
          url: config.url,
          data: config.data,
          params: config.params
        },
        'request'
      )
    }
    // Do something before request is sent
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  error => {
    if (isAxiosError(error)) {
      // we need to track only errors for hours validation API
      if (error.config?.url?.startsWith('/api/hours_validation')) {
        tracking.info(
          'Axios response from 4D api',
          {
            //error
            error_message: error.message,
            error_code: error.code,
            // request
            request_method: error.request?.method,
            request_url: error.config?.url,
            request_data: error.config?.data,
            request_params: error.config?.params,
            // response
            response_status: error.response?.status,
            response_status_text: error.response?.statusText,
            response_data: error?.response?.data
          },
          'request'
        )

        // we want to ignore validation errors from 4D API
        // In this case we ignore validation errors for `createContractTimesheetMutationRequest` and `updateContractTimesheetMutationRequest`

        const isIgnoredError =
          // 'put' for `createContractTimesheetMutationRequest`
          // 'post' for `updateContractTimesheetMutationRequest`
          ['put', 'PUT', 'post', 'POST'].includes(
            String(error.config?.method)
          ) &&
          // URL for `createContractTimesheetMutationRequest` and `updateContractTimesheetMutationRequest` starts with `/api/hours_validation/contracts/`
          error.config?.url?.startsWith('/api/hours_validation/contracts/') &&
          // 4D API returns validation errors with status code 400 and data with `error` property
          error.response?.data?.data?.[0]

        const isNetworkError = error.code === AxiosError.ERR_NETWORK

        if (!isIgnoredError && !isNetworkError) {
          tracking.error(error, {
            onBugsnagErrorCallback: (event: any) => {
              // https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#groupinghash
              // we need to set groupingHash to avoid grouping errors with different messages to the same group
              const responseError = error?.response?.data?.error
              if (responseError && typeof responseError === 'string') {
                event.groupingHash = responseError
                // we want to use an error message from 4D response as a main error message
                if (event?.errors?.[0]?.errorMessage) {
                  event.errors[0].errorMessage = `4D API error: ${responseError}`
                }
              }
            }
          })
        }
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)
