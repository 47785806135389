import { gql } from '@apollo/client'
import { workObstructionFragment } from './WorkObstructionFragment'

export const workdayFragment = gql`
  ${workObstructionFragment}

  fragment WorkdayFragment on Workday {
    breakInMinutes
    cancellationRequestState
    endsAt
    id
    startsAt
    workObstruction {
      ...WorkObstructionFragment
    }
  }
`
