import type { CompanySurvey_questions } from '../types/CompanySurvey'

interface QuestionRequiredMetadata {
  boolValueRequired: boolean
  textValueRequired: boolean
}

export const getQuestionMetadata = (
  question: CompanySurvey_questions,
  boolValue: boolean | null | undefined
): QuestionRequiredMetadata => {
  if (question.questionType === 'text') {
    return {
      boolValueRequired: false,
      textValueRequired: question.required
    }
  }

  if (question.questionType === 'bool') {
    return {
      boolValueRequired: question.required,
      textValueRequired: false
    }
  }

  if (question.questionType === 'bool_with_text') {
    if (question.commentCondition === 'on_no' && boolValue === false) {
      return {
        boolValueRequired: question.required,
        textValueRequired: true
      }
    }

    if (question.commentCondition === 'on_yes' && boolValue === true) {
      return {
        boolValueRequired: question.required,
        textValueRequired: true
      }
    }

    return {
      boolValueRequired: question.required,
      textValueRequired: false
    }
  }

  return {
    boolValueRequired: false,
    textValueRequired: false
  }
}
