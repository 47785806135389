export interface ActionCableProposalsDecisionsMessage {
  // one of properties will be provided
  refused_proposals?: Array<{
    employee_id: string
    position_id: string
    shift_id: string
  }>
  accepted_proposals?: Array<{
    employee_id: string
    position_id: string
    shift_id: string
  }>
}

export interface ActionCableEligibleMessage {
  data: Array<{
    employee_id: string
    position_id: string
    shift_id: string
  }>
  new_state: 'eligible'
}

export interface ActionCableWorkedHoursScrapedMessage {
  company_id: string
  scrape_status: 'ok' | 'error' | 'restarted'
}

export type ActionCableUserEventMessage =
  | ActionCableProposalsDecisionsMessage
  | ActionCableEligibleMessage
  | ActionCableWorkedHoursScrapedMessage

export interface ActionCableUserEvent {
  type: ActionCableUserEventType
  message: ActionCableUserEventMessage
}

export enum ActionCableUserEventType {
  eligible = 'eligible', // see event https://sealjobs.atlassian.net/browse/SJ-343
  proposalsDecisions = 'proposalsDecisions', // see event https://sealjobs.atlassian.net/browse/SJ-343
  workedHoursScraped = 'workedHoursScraped' // after mutation `srRequestWorkedHoursScrape` when get this message with result ("ok" or "error" or "restarted")
}

export type ActionCableUserEventHandler = (event: ActionCableUserEvent) => any

export interface ActionCableUserEventListener {
  type: ActionCableUserEventType
  handler: ActionCableUserEventHandler
}

export class ActionCableUserEvents {
  public static _listeners: ActionCableUserEventListener[] = []

  public static addEventListener(
    type: ActionCableUserEventType,
    handler: ActionCableUserEventHandler
  ) {
    ActionCableUserEvents._listeners.push({ type, handler })
  }

  public static removeEventListener(
    type: ActionCableUserEventType,
    handler: ActionCableUserEventHandler
  ) {
    ActionCableUserEvents._listeners = ActionCableUserEvents._listeners.filter(
      listener => !(listener.type === type && listener.handler === handler)
    )
  }

  public static triggerEvent(event: ActionCableUserEvent) {
    ActionCableUserEvents._listeners
      .filter(({ type }) => type === event.type)
      .forEach(({ handler }) => handler(event))
  }
}
