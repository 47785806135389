import { FC, useMemo } from 'react'
import { Divider } from '@vivaldis/antd-ui'
import { Information } from '@vivaldis/web-ui'
import { useTranslation } from 'react-i18next'
import type { Contract } from '../../typings/Contract'

interface Props {
  contract: Contract
}

export const Premiums: FC<Props> = ({ contract }) => {
  const [t] = useTranslation('web_ui')

  const showPremiums = useMemo(() => {
    // this field is available only for SU
    return Object.hasOwn(contract, 'contractPremiums')
  }, [contract])

  return showPremiums ? (
    <>
      <Divider />
      <Information>
        <Information.InformationField
          label={t('view_contract_screen_content.premiums')}
        >
          {contract.contractPremiums?.length ? (
            <ul>
              {contract.contractPremiums?.map(cp => (
                <li key={cp.premium.name}>{cp.premium.name}</li>
              ))}
            </ul>
          ) : (
            '-'
          )}
        </Information.InformationField>
      </Information>
    </>
  ) : null
}
