import { FC } from 'react'
import { Form } from '@vivaldis/antd-ui'
import { PositionFormStep, useStep } from '../../providers/StepProvider'
import { CompanyFunctionCode } from '../../typings/CompanyFunctionCode'
import { CountryType } from '../../typings/CountryType'
import { VivaldisOffice } from '../../typings/VivaldiOffice'
import { FormContent } from './components/FormContent'
import { ActionBar } from './components/ActionBar'
import {
  FormProvider,
  PositionFormProviderProps
} from './providers/FormProvider'

export interface Step1Props {
  // FormProvider props
  onSubmit: PositionFormProviderProps['onSubmit']
  initialValues: PositionFormProviderProps['initialValues']
  initiallySelectedCompanyCode?: CompanyFunctionCode | undefined
  // form props
  countries: CountryType[]
  companyFunctionCodes: CompanyFunctionCode[]
  showNoVatApplicableCheckbox: boolean
  showMinimumWageInput: boolean
  vivaldisOffices: VivaldisOffice[]
  showVivaldisOfficeCodeInput: boolean
}

export const Step1: FC<Step1Props> = ({
  onSubmit,
  initialValues,
  initiallySelectedCompanyCode,
  countries,
  companyFunctionCodes,
  showNoVatApplicableCheckbox,
  showMinimumWageInput,
  vivaldisOffices,
  showVivaldisOfficeCodeInput
}) => {
  const { step } = useStep()

  if (step !== PositionFormStep.Step1GeneralInformation) {
    return null
  }

  return (
    <FormProvider
      onSubmit={onSubmit}
      initialValues={initialValues}
      showMinimumWageInput={showMinimumWageInput}
      showVivaldisOfficeCodeInput={showVivaldisOfficeCodeInput}
    >
      <Form name="position-form-step-1" id="position-form-step-1">
        <FormContent
          countries={countries}
          initiallySelectedCompanyCode={initiallySelectedCompanyCode}
          companyFunctionCodes={companyFunctionCodes}
          showNoVatApplicableCheckbox={showNoVatApplicableCheckbox}
          showMinimumWageInput={showMinimumWageInput}
          vivaldisOffices={vivaldisOffices}
          showVivaldisOfficeCodeInput={showVivaldisOfficeCodeInput}
        />
        <ActionBar />
      </Form>
    </FormProvider>
  )
}
