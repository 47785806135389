import {
  ComponentType,
  createElement as baseCreateElement,
  isValidElement,
  JSXElementConstructor,
  ReactElement,
  ReactNode
} from 'react'

export function createElement<C extends ComponentType<any> | ReactNode>(
  Component?: C,
  props?: C extends JSXElementConstructor<infer P> ? P : any,
  ...children: ReactNode[]
): JSX.Element {
  if (Component && isValidElement(Component)) {
    return Component as ReactElement<any>
  } else if (Component) {
    return baseCreateElement(
      Component as ComponentType<typeof props> | any,
      props,
      ...children
    )
  }
  return null as any
}
// https://javascript.plainenglish.io/a-cleaner-api-for-react-ts-components-47d0704a508c
// export type GetComponentProps<T> = T extends
//   | ComponentType<infer P>
//   | Component<infer P>
//   ? P
//   : never
