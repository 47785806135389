import { MetricType } from 'web-vitals'

const reportWebVitals = (onPerfEntry?: (metric: MetricType) => void) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import(/* webpackChunkName: "web-vitals" */ 'web-vitals').then(
      ({ onCLS, onFID, onFCP, onLCP, onTTFB }) => {
        onCLS(onPerfEntry)
        onFID(onPerfEntry)
        onFCP(onPerfEntry)
        onLCP(onPerfEntry)
        onTTFB(onPerfEntry)
      }
    )
  }
}

export default reportWebVitals
