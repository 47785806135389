import styled from '@emotion/styled'
import { FC } from 'react'
import { AntdConfigProvider, Steps } from '@vivaldis/antd-ui'
import { useTranslation } from 'react-i18next'
import { useStep } from '../providers/StepProvider'

export const StepsProgressHeader: FC = () => {
  const { t } = useTranslation('web_ui')

  const { step } = useStep()
  return (
    <Base>
      <AntdConfigProvider
        theme={{
          components: {
            Steps: {
              descriptionMaxWidth: 110
            }
          }
        }}
      >
        <Steps
          current={step}
          direction="horizontal"
          responsive={false}
          progressDot
          size="small"
          items={[
            {
              title: t('position_form.step1.step_name')
            },
            {
              title: t('position_form.step2.step_name')
            },
            {
              title: t('position_form.step3.step_name')
            }
          ]}
        />
      </AntdConfigProvider>
    </Base>
  )
}

export const Base = styled('div')`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 12px;
  margin: -16px -16px 16px -16px;
  background-color: ${props => props.theme.white};
`
