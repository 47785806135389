import { FC } from 'react'
import { ContractSchedule } from '../ContractSchedule'
import { ContractInformation } from './components/ContractInformation'
import { Premiums } from './components/Premiums'
import { Remark } from './components/Remark'
import type { Contract } from './typings/Contract'

export interface ViewContractScreenContentProps {
  contract: Contract
  showContractType?: boolean
  showCommittee?: boolean
  onReSendWorkObstruction?: (workObstructionId: string) => any
}

export const ViewContractScreenContent: FC<ViewContractScreenContentProps> = ({
  contract,
  showContractType = false,
  showCommittee = false,
  onReSendWorkObstruction
}) => {
  return (
    <>
      <ContractInformation
        contract={contract}
        showContractType={showContractType}
        showCommittee={showCommittee}
      />
      <ContractSchedule
        contract={contract}
        onReSendWorkObstruction={onReSendWorkObstruction}
      />
      <Premiums contract={contract} />
      <Remark contract={contract} />
    </>
  )
}
