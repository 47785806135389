import { forwardRef, isValidElement, useCallback, useMemo } from 'react'
import { useField } from 'formik'
import { Select as AntdSelect } from '../../Select'
import type {
  RefSelectProps as AntdRefSelectProps,
  SelectProps as AntdSelectProps
} from '../../Select'
import type { FormikFieldProps } from '../typings/FieldProps'
import { filterOption as selectDefaultFilterOptions } from './utils/filterOptions'
import { SelectLabelWithDisabledReason } from './components/SelectLabelWithDisabledReason'

export type RefSelectProps = AntdRefSelectProps

export interface LabeledValue {
  value: string
  label: string
  // We use `labelText` field for search (when user typing).
  // It's also useful (and required) when `label` is a custom component and a text can not be extracted from it.
  labelText?: string
  disabled?: boolean
  disabledReason?: string
}

type DefaultSelectValue = LabeledValue[]

export type SelectProps<T = DefaultSelectValue> = FormikFieldProps &
  AntdSelectProps<T> & { autoComplete?: string }

export const Select = forwardRef<RefSelectProps, SelectProps>(
  (
    {
      name,
      onChange: onChangeProp,
      onBlur: onBlurProp,
      filterOption = selectDefaultFilterOptions,
      value: _valueProp, // we don't need this value, because we are going to use value from formik
      options: optionsProp,
      ...restProps
    },
    ref
  ) => {
    const [field, , helpers] = useField(name)

    const { value, onBlur } = field
    const { setValue } = helpers

    const handleChange = useCallback(
      (value: any, option: any) => {
        setValue(value)
        onChangeProp?.(value, option)
      },
      [onChangeProp, setValue]
    )

    const handleBlur = useCallback(
      (value: any) => {
        onBlur(value)
        onBlurProp?.(value)
      },
      [onBlur, onBlurProp]
    )

    const newOptions = useMemo(() => {
      return optionsProp?.map(option => {
        let labelElement = option.label
        let labelText = option.label

        // we can use custom label component
        if (isValidElement(option.label)) {
          labelElement = option.label
        } else if (option.disabledReason) {
          // if we have `disabledReason` field, we can use custom label with this text
          labelElement = (
            <SelectLabelWithDisabledReason
              label={option.label}
              disabledReason={option.disabledReason}
            />
          )
          labelText = option.label
        }

        return {
          labelText: labelText,
          ...option,
          label: labelElement
        }
      })
    }, [optionsProp])

    return (
      <AntdSelect<DefaultSelectValue>
        ref={ref}
        onChange={handleChange}
        onBlur={handleBlur}
        // setting undefined will show the placeholder
        value={value === '' || value === null ? undefined : value}
        filterOption={filterOption}
        options={newOptions}
        {...restProps}
      />
    )
  }
)
