import styled from '@emotion/styled'
import { ComponentType, FC, PropsWithChildren, ReactNode } from 'react'
import { margin, MarginProps } from '../styles/margin'
import { createElement } from '../utils/createElement'

type ContainerProps = MarginProps & {
  columns: number
  gap: number
  columnGap?: number
  rowGap?: number
}

const Container = styled.div<ContainerProps>`
  display: grid;
  align-items: start;
  grid-gap: ${props => props.gap}px;
  ${props => (props.columnGap ? `column-gap: ${props.columnGap}px;` : '')}
  ${props => (props.rowGap ? `row-gap: ${props.rowGap}px;` : '')}
  grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
  ${props => margin(props)};
`

export type GridProps = {
  ContainerComponent?: ReactNode | ComponentType<ContainerProps>
  columns?: number
  gap?: number
  columnGap?: number
  rowGap?: number
} & MarginProps

export const Grid: FC<PropsWithChildren<GridProps>> = ({
  ContainerComponent = Container,
  columns = 2,
  gap = 16,
  children,
  ...props
}) => createElement(ContainerComponent, { ...props, columns, gap }, children)
