import styled from '@emotion/styled/base'
import { useBreakpoint } from '@vivaldis/antd-ui'
import { ComponentType, FC, isValidElement, ReactElement } from 'react'
import { createElement } from '@vivaldis/common'
import type { MasterDetailProps } from './typings/MasterDetailProps'
import { DetailProvider } from './providers/DetailProvider'

export const MasterDetail: FC<MasterDetailProps> = ({
  MasterComponent,
  DetailComponent
}) => {
  const breakpoint = useBreakpoint()
  let master: ReactElement | null = null
  if (MasterComponent && isValidElement(MasterComponent)) {
    master = MasterComponent
  } else if (MasterComponent) {
    master = createElement(MasterComponent as ComponentType<any>)
  }
  let detail: ReactElement | null = null
  if (DetailComponent && isValidElement(DetailComponent)) {
    detail = DetailComponent
  } else if (DetailComponent) {
    detail = createElement(DetailComponent as ComponentType<any>)
  }
  return (
    <>
      {breakpoint.lg ? master : null}
      <DetailProvider>
        <DetailContainer
          style={{
            marginLeft: breakpoint.lg ? 320 : 0
          }}
        >
          {detail}
        </DetailContainer>
      </DetailProvider>
    </>
  )
}

const DetailContainer = styled('div')`
  flex: 1;
`
