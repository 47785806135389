import { FC } from 'react'
import { Contract_workdays as Workday } from '../../typings/Contract'
import { Base } from '../Base'
import { Item } from '../Item'
import { EndTime } from './components/EndTime'
import { StartTime } from './components/StartTime'
import { StatusAndWorkObstructionIcon } from './components/StatusAndWorkObstructionIcon'
import { IconBase } from './components/IconBase'
import { Break } from './components/Break'
import { Day } from './components/Day'
import { TotalHours } from './components/TotalHours'

interface Props {
  workday: Workday
  onReSendWorkObstruction?: (workObstructionId: string) => any
}

const InternalScheduleItem: FC<Props> = ({
  workday,
  onReSendWorkObstruction
}) => {
  return (
    <Base>
      <IconBase>
        <StatusAndWorkObstructionIcon
          workday={workday}
          onReSendWorkObstruction={onReSendWorkObstruction}
        />
      </IconBase>
      <Item>
        <Day startsAt={workday.startsAt} />
      </Item>
      <Item>
        <StartTime startsAt={workday.startsAt} />
      </Item>
      <Item>
        <EndTime endsAt={workday.endsAt} />
      </Item>
      <Item>
        <Break breakInMinutes={workday.breakInMinutes} />
      </Item>
      <Item>
        <TotalHours
          startsAt={workday.startsAt}
          endsAt={workday.endsAt}
          breakInMinutes={workday.breakInMinutes}
        />
      </Item>
    </Base>
  )
}

export const ScheduleItem = Object.assign(InternalScheduleItem, {
  Base,
  Item,
  Break,
  Day,
  EndTime,
  StartTime,
  TotalHours
})
