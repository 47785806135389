import { useField } from 'formik'
import { FC, useCallback } from 'react'
import { InputNumber as AntdInputNumber } from '../../InputNumber'
import type { InputNumberProps as AntdInputNumberProps } from '../../InputNumber'
import type { FormikFieldProps } from '../typings/FieldProps'

export type InputNumberProps = FormikFieldProps & AntdInputNumberProps

export const InputNumber: FC<InputNumberProps> = ({
  name,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  value: _valueProp,
  ...restProps
}) => {
  const [field, , helpers] = useField(name)
  const { value, onBlur } = field
  const { setValue } = helpers

  const handleChange = useCallback(
    (value: any) => {
      setValue(value)
      onChangeProp?.(value)
    },
    [setValue, onChangeProp]
  )

  const handleBlur = useCallback(
    (event: any) => {
      onBlur(event)
      onBlurProp?.(event)
    },
    [onBlur, onBlurProp]
  )

  return (
    <AntdInputNumber
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="#"
      {...restProps}
    />
  )
}
