// list of presets for TextStyles from Vivaldis - Design System: http://joxi.ru/p27VDkVULW99Zm (11.02.2022)
export enum TextStylePreset {
  // Regular
  Regular12 = 'Regular12px | 18px',
  Regular14 = 'Regular14px | 20px',
  Regular16 = 'Regular16px | 24px',
  Regular18 = 'Regular18px | 26px',
  Regular20 = 'Regular20px | 28px',
  Regular24 = 'Regular24px | 32px',
  Regular30 = 'Regular30px | 38px',
  Regular38 = 'Regular38px | 46px',
  Regular46 = 'Regular46px | 54px',
  Regular56 = 'Regular56px | 64px',
  // Semibold
  Semibold12 = 'Semibold12px | 18px',
  Semibold14 = 'Semibold14px | 20px',
  Semibold16 = 'Semibold16px | 24px',
  Semibold18 = 'Semibold18px | 26px',
  Semibold20 = 'Semibold20px | 28px',
  Semibold24 = 'Semibold24px | 32px',
  Semibold30 = 'Semibold30px | 38px',
  Semibold38 = 'Semibold38px | 46px',
  Semibold46 = 'Semibold46px | 54px',
  Semibold56 = 'Semibold56px | 64px'
}
