import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Radio, TextStylePreset, Typography } from '@vivaldis/antd-ui'
import { useTheme } from '@vivaldis/ui'
import { usePosition } from '../../../../../providers/PositionProvider'
import { useForm } from '../../../providers/FormProvider'

export const WPSRequired: FC = () => {
  const { t } = useTranslation('web_ui')
  const theme = useTheme()

  const position = usePosition()

  const positionHasWPS = !!position?.workPostSheet

  const { values, errors } = useForm()

  // we disable this input when:
  //  - position already has WPS, and this means that WPS is required
  const disabled = positionHasWPS

  const showNoOptionHint = useMemo(() => {
    // we want to show a hint when a user selects "No" option,
    // but we don't want to show a hint and error in the same time
    return values.wpsRequired === false && !errors.wpsRequired
  }, [errors.wpsRequired, values.wpsRequired])

  return (
    <>
      <Form.Item
        name="wpsRequired"
        required
        label={t('position_form.step2.wps_required.label')}
        tooltip={t('position_form.step2.wps_required.tooltip')}
      >
        <Radio.Group name="wpsRequired" disabled={disabled}>
          <Radio name="wpsRequired" value={true}>
            {t('position_form.step2.wps_required.options.yes')}
          </Radio>
          <Radio name="wpsRequired" value={false}>
            {t('position_form.step2.wps_required.options.no')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      {showNoOptionHint ? (
        <Typography.Paragraph
          preset={TextStylePreset.Regular14}
          color={theme.gray7}
        >
          {t('position_form.step2.wps_required.no_option_hint')}
        </Typography.Paragraph>
      ) : null}
    </>
  )
}
