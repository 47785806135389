import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Select } from '@vivaldis/antd-ui'
import { Premium } from '../../../../../../typings/Premium'
import { usePremiumTypeOptions } from '../../../../../../../PremiumType'
import { useForm } from '../../../../providers/FormProvider'
import { getDefaultUnitTypeForPremium } from './utils/getDefaultUnitTypeForPremium'

interface Props {
  premiums: Premium[]
}

export const PremiumTypes: FC<Props> = ({ premiums }) => {
  const { t } = useTranslation()

  const premiumTypesOptions = usePremiumTypeOptions(premiums)

  const { values, setFieldValue } = useForm()

  const handlePremiumIdsChange = useCallback(
    (premiumIds: any) => {
      if (Array.isArray(premiumIds)) {
        const nextPremiumValuesInput = premiumIds.map(premiumId => {
          const premiumValueInput = values.premiumValuesInput.find(
            premiumValueInput => premiumValueInput.premiumId === premiumId
          )
          if (premiumValueInput) {
            return premiumValueInput
          }

          const premium = premiums.find(premium => premium.id === premiumId)

          const initialValue = premium?.name === 'meal_vouchers' ? 8 : null
          const initialContributionValue =
            premium?.name === 'meal_vouchers' ? 1.09 : null

          return {
            premiumId,
            value: initialValue,
            contributionValue: initialContributionValue,
            unit: getDefaultUnitTypeForPremium(String(premium?.name))
          }
        })
        setFieldValue('premiumValuesInput', nextPremiumValuesInput)
      }
    },
    [premiums, setFieldValue, values.premiumValuesInput]
  )

  return (
    <Form.Item
      name="premiumIds"
      label={t('position_form.step3.premium_ids.label', { ns: 'web_ui' })}
      tooltip={t('position_form.step3.premium_ids.tooltip', {
        ns: 'web_ui'
      })}
    >
      <Select
        name="premiumIds"
        mode="multiple"
        placeholder={t('form.optional')}
        options={premiumTypesOptions}
        onChange={handlePremiumIdsChange}
        showSearch
      />
    </Form.Item>
  )
}
