import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const ArrowDown2Svg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4.46967 8.71967C4.73594 8.4534 5.1526 8.4292 5.44621 8.64705L5.53033 8.71967L12 15.189L18.4697 8.71967C18.7359 8.4534 19.1526 8.4292 19.4462 8.64705L19.5303 8.71967C19.7966 8.98594 19.8208 9.4026 19.6029 9.69621L19.5303 9.78033L12.5303 16.7803C12.2641 17.0466 11.8474 17.0708 11.5538 16.8529L11.4697 16.7803L4.46967 9.78033C4.17678 9.48744 4.17678 9.01256 4.46967 8.71967Z" />
  </svg>
)

export const ArrowDown2 = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={ArrowDown2Svg} {...props} ref={ref} />
)
