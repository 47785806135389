import { createSelector } from 'reselect'
import { NotificationsState } from '../typings/State'
import { NOTIFICATIONS_STATE_KEY } from '../reducers'

interface State {
  [NOTIFICATIONS_STATE_KEY]: NotificationsState
}

export const notificationsStateSelector = (state: State): NotificationsState =>
  state[NOTIFICATIONS_STATE_KEY] || {}

export const notificationTokenSelector: (state: State) => string | undefined =
  createSelector(
    notificationsStateSelector,
    notifications => notifications.token
  )
