import { FC } from 'react'
import { ActionBar as BaseActionBar } from '../../../ActionBar'
import { SubmitButton } from '../../../SubmitButton'
import { useForm } from '../../providers/FormProvider'

interface Props {
  buttonText: string
}

export const ActionBar: FC<Props> = ({ buttonText }) => {
  const { isSubmitting } = useForm()

  return (
    <BaseActionBar
      rightContent={
        <SubmitButton isSubmitting={isSubmitting} label={buttonText} />
      }
    />
  )
}
