import { FC } from 'react'
import { Typography as AntdTypography } from 'antd'
import type { ParagraphProps as AntdParagraphProps } from 'antd/lib/typography/Paragraph'
import { makeBaseTextComponent } from './BaseText'
import type { BaseTextProps } from './BaseText'

export interface ParagraphProps extends AntdParagraphProps, BaseTextProps {}

export const Paragraph: FC<ParagraphProps> = ({ ...props }) => {
  return <AntdTypographyParagraph {...props} />
}

const AntdTypographyParagraph = makeBaseTextComponent(AntdTypography.Paragraph)
