import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const ArrowDownCircleSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 12C3.5 7.313 7.313 3.5 12 3.5C16.687 3.5 20.5 7.313 20.5 12C20.5 16.687 16.687 20.5 12 20.5C7.313 20.5 3.5 16.687 3.5 12ZM2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2C6.486 2 2 6.486 2 12ZM11.469 14.5729C11.609 14.7149 11.801 14.7939 12 14.7939C12.199 14.7939 12.391 14.7149 12.531 14.5729L16.003 11.0869C16.295 10.7929 16.294 10.3179 16.001 10.0259C15.707 9.73388 15.232 9.73388 14.9401 10.0279L12 12.9819L9.06105 10.0279C8.76905 9.73388 8.29405 9.73388 8.00005 10.0259C7.70705 10.3179 7.70505 10.7929 7.99805 11.0869L11.469 14.5729Z"
    />
  </svg>
)

export const ArrowDownCircle = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={ArrowDownCircleSvg} {...props} ref={ref} />
)
