import { Routes, Route, Navigate } from 'react-router-dom'
import { Suspense, FC, useMemo } from 'react'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'
import { ProtectedRoute } from '../../components/ProtectedRoute'
import { useUserConstructFourDCompanyId } from '../../hooks/useUserConstructFourDCompanyId'
import { useUserHasConstructionWebAccess } from '../../hooks/useUserHasConstructionWebAccess'
import { useUserHasInterimWebAccess } from '../../hooks/useUserHasInterimWebAccess'
import { useUserInterimFourDCompanyId } from '../../hooks/useUserInterimFourDCompanyId'
import { lazyOrReloadPage } from '../../utils/lazyOrReloadPage'

const DownloadTimesheets = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "DownloadTimesheets" */ './screens/DownloadTimesheets'
    )
)
const ContractAndTimesheetDetails = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "ContractAndTimesheetDetails" */ './screens/ContractAndTimesheetDetails'
    )
)
const TimesheetList = lazyOrReloadPage(
  () =>
    import(/* webpackChunkName: "TimesheetList" */ './screens/TimesheetList')
)

export const HoursValidationRoutes: FC = () => {
  const userHasConstructionWebAccess = useUserHasConstructionWebAccess()
  const userHasInterimWebAccess = useUserHasInterimWebAccess()

  const construct4dCompanyId = useUserConstructFourDCompanyId()
  const interim4dCompanyId = useUserInterimFourDCompanyId()

  const userHasConstructAndInterimAccess = useMemo(
    () => userHasConstructionWebAccess && userHasInterimWebAccess,
    [userHasConstructionWebAccess, userHasInterimWebAccess]
  )

  return (
    <Suspense fallback={ProgressBarSuspenseFallback}>
      <Routes>
        {/* When user has both construct and interim access we do redirect to interim, otherwise we use single available company*/}
        {userHasConstructAndInterimAccess ? (
          <Route
            path="/"
            element={
              <Navigate
                to={userHasInterimWebAccess ? 'interim' : 'construct'}
                replace
              />
            }
          />
        ) : (
          <Route
            path="/"
            element={
              <TimesheetList
                fourDCompanyId={construct4dCompanyId || interim4dCompanyId}
              />
            }
          />
        )}

        <Route
          path="/interim"
          element={
            <ProtectedRoute isAllowed={userHasInterimWebAccess}>
              <TimesheetList fourDCompanyId={interim4dCompanyId} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/construct"
          element={
            <ProtectedRoute isAllowed={userHasConstructionWebAccess}>
              <TimesheetList fourDCompanyId={construct4dCompanyId} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/download-timesheets/:fourDCompanyId"
          element={<DownloadTimesheets />}
        />
        <Route
          path="/contract-and-timesheet-details/:id"
          element={<ContractAndTimesheetDetails />}
        />
      </Routes>
    </Suspense>
  )
}
