import { format } from 'date-fns'
import { FC, useMemo } from 'react'
import { Contract_workdays as Workday } from '../../../typings/Contract'

interface Props {
  endsAt: Workday['endsAt']
}

export const EndTime: FC<Props> = ({ endsAt }) => {
  const endTime = useMemo(() => {
    return format(new Date(endsAt), 'HH:mm')
  }, [endsAt])

  return <>{endTime}</>
}
