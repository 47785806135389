import { Routes, Route } from 'react-router-dom'
import { Suspense, FC } from 'react'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'
import { lazyOrReloadPage } from '../../utils/lazyOrReloadPage'

const FillSurvey = lazyOrReloadPage(
  () => import(/* webpackChunkName: "FillSurvey" */ './screens/FillSurvey')
)
const SurveyList = lazyOrReloadPage(
  () => import(/* webpackChunkName: "SurveyList" */ './screens/SurveyList')
)
const ViewSurvey = lazyOrReloadPage(
  () => import(/* webpackChunkName: "ViewSurvey" */ './screens/ViewSurvey')
)

export const SurveysRoutes: FC = () => (
  <Suspense fallback={ProgressBarSuspenseFallback}>
    <Routes>
      <Route path="/" element={<SurveyList />} />
      <Route path="/fill-survey/:surveyId" element={<FillSurvey />} />
      <Route path="/view-survey/:surveyId" element={<ViewSurvey />} />
    </Routes>
  </Suspense>
)
