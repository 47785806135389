import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import {
  UpdateEmployeeMutation,
  UpdateEmployeeMutationVariables
} from './__generated__/UpdateEmployeeMutation'
import { employeeFragment } from '../fragments/EmployeeFragment'
import { EmployeeFragment } from '../fragments/__generated__/EmployeeFragment'

const mutation = gql`
  ${employeeFragment}

  mutation UpdateEmployeeMutation(
    $input: UpdateEmployeeInput!
    $locale: String!
  ) {
    updateEmployee(input: $input) {
      employee {
        ...EmployeeFragment
      }
      __typename
    }
  }
`

export function updateEmployeeMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>,
    'mutation'
  >
) {
  const input = options.variables?.input
  const fragment = apolloClient.readFragment<EmployeeFragment>({
    fragment: employeeFragment,
    variables: {
      locale: options.variables?.locale
    },
    id: `Employee:${options.variables?.input.id}`,
    fragmentName: 'EmployeeFragment'
  })

  return apolloClient.mutate<
    UpdateEmployeeMutation,
    UpdateEmployeeMutationVariables
  >({
    mutation,
    ...(fragment
      ? {
          optimisticResponse: {
            updateEmployee: {
              __typename: 'UpdateEmployeePayload',
              employee: {
                ...fragment,
                __typename: 'Employee',
                ...(input?.employee.firstName
                  ? { firstName: input?.employee.firstName }
                  : {})
                // firstName: input?.employee.firstName!,
                // country: {
                //   ...fragment?.country!,
                //   __typename: 'Country',
                //   id: input?.employee.countryId!
                // },
                // description: input?.employee.description!,
                // id: input?.id!,
                // latitude: input?.employee.latitude!,
                // longitude: input?.employee.longitude!,
                // name: input?.employee.name!,
                // street: input?.employee.street!,
                // streetNumber: input?.employee.streetNumber!,
                // zip: input?.employee.zip!
              }
            }
          }
        }
      : {}),
    ...options
  })
}
