import { NotificationOutlined } from '@ant-design/icons'
import {
  Activity,
  Paper,
  PaperUpload,
  Profile,
  TreeUser,
  TimeSquare,
  TwoUser,
  Work,
  IconSquare,
  Waller,
  PromoLabelWithWhiteBorderIcon,
  Discount
} from '@vivaldis/icons'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Menu, MenuProps, useBreakpoint } from '@vivaldis/antd-ui'
import { useLocation, useMatch } from 'react-router-dom'
import { Link, useDrawer } from '@vivaldis/web-ui'
import { useCompanyHasPayrollAccess } from '../../hooks/useCompanyHasPayrollAccess'
import { useCompanyHasSelectionAccess } from '../../hooks/useCompanyHasSelectionAccess'
import { useUserHasConstructionWebAccess } from '../../hooks/useUserHasConstructionWebAccess'
import { useUserHasInterimWebAccess } from '../../hooks/useUserHasInterimWebAccess'
import { useUserHasWebAccess } from '../../hooks/useUserHasWebAccess'
import { useUserDashboard } from '../../providers/UserDashboardQueryProvider'
import { useNotProcessedTimesheetCount } from '../../providers/TimesheetBadgesProvider'
import { getActiveMenuItemKeys } from './utils/getActiveMenuItemKeys'
import { isActiveLink } from './utils/isActiveLink'

interface Props {
  isSiderCollapsed: boolean
}

export const NavigationMenu: FC<Props> = ({ isSiderCollapsed }) => {
  const [t] = useTranslation('components')
  const breakpoint = useBreakpoint()

  const companyHasSelectionAccess = useCompanyHasSelectionAccess()
  const companyHasPayrollAccess = useCompanyHasPayrollAccess()
  const userHasInterimWebAccess = useUserHasInterimWebAccess()
  const userHasConstructionWebAccess = useUserHasConstructionWebAccess()
  const userHasInterimAndConstructWebAccess = useMemo(
    () => userHasInterimWebAccess && userHasConstructionWebAccess,
    [userHasConstructionWebAccess, userHasInterimWebAccess]
  )

  const { openedSurveysCount, unpaidInvoicesCount } = useUserDashboard()
  const {
    notProcessedConstructTimesheetCount,
    notProcessedInterimTimesheetCount
  } = useNotProcessedTimesheetCount()

  const isSettingScreens = useMatch('/settings/*')

  const showPositions = useCompanyHasPayrollAccess()
  const showPayrollTeam = useCompanyHasPayrollAccess()
  const showSelectionTeam = useMemo(() => {
    return (
      companyHasSelectionAccess &&
      (userHasInterimWebAccess || userHasConstructionWebAccess)
    )
  }, [
    companyHasSelectionAccess,
    userHasConstructionWebAccess,
    userHasInterimWebAccess
  ])
  const showPayrollContracts = useCompanyHasPayrollAccess()
  const showSelectionContracts = useMemo(() => {
    return (
      companyHasSelectionAccess &&
      (userHasInterimWebAccess || userHasConstructionWebAccess)
    )
  }, [
    companyHasSelectionAccess,
    userHasConstructionWebAccess,
    userHasInterimWebAccess
  ])
  const showSurveys = useCompanyHasPayrollAccess()
  const showInvoices = useMemo(() => {
    return companyHasSelectionAccess || companyHasPayrollAccess
  }, [companyHasPayrollAccess, companyHasSelectionAccess])
  const showTimesheets = useUserHasWebAccess()
  const showVivaldisPayrollPromo = !companyHasPayrollAccess
  const showVivaldisSelectionPromo = !companyHasSelectionAccess

  const { close } = useDrawer()
  const handleOnMenuItemClick = useCallback(() => {
    // on mobile, we close drawer after click on menu item
    if (!breakpoint.lg) {
      close()
    }
  }, [breakpoint.lg, close])

  // More about navigation menu -> https://sealjobs.atlassian.net/wiki/spaces/SJ/pages/2157936641/Navigation+menu

  //  Possible menu items:
  /*

  - positions
  ---------------TEAM---------------
  - team
     - Payroll
     - Selection
         - Interim
         - Construct
      ____OR____
  - team
     - Payroll
     - Selection (Interim only)
      ____OR____
  - team
     - Payroll
     - Selection (Construct only)
      ____OR____
  - team (Payroll only)
      ____OR____
  - team (Interim only)
      ____OR____
  - team (Construct only)
      ____OR____
  - team
     - Interim
     - Construct
  ---------------CONTRACTS----------
  - contracts
       - Payroll
       - Selection
           - Interim
           - Construct
      ____OR____
  - contracts
       - Payroll
       - Selection (Interim only)
      ____OR____
  - contracts
       - Payroll
       - Selection (Construct only)
      ____OR____
  - contracts (Payroll only)
      ____OR____
  - contracts (Interim only)
      ____OR____
  - contracts (Construct only)
      ____OR____
  - contracts
     - Interim
     - Construct
  ---------------END CONTRACTS------------
  - surveys
  - invoices
  ---------------TIMESHEETS----------
  - timesheets
     - Interim
     - Construct
      ____OR____
  - timesheets (Interim only)
      ____OR____
  - timesheets (Construct only)
  ---------------END TIMESHEETS------------
  - vivaldis-selection-promo
  - vivaldis-payroll-promo
  - faq

  */

  const positionsMenuItems: MenuProps['items'] = useMemo(() => {
    return [
      ...(showPositions
        ? [
            {
              key: '/positions',
              label: (
                <Link to="/positions" onClick={handleOnMenuItemClick}>
                  {t('navigation_menu.positions')}
                </Link>
              ),
              icon: <Work style={{ fontSize: 20 }} />
            }
          ]
        : [])
    ]
  }, [handleOnMenuItemClick, showPositions, t])

  const teamMenuItems: MenuProps['items'] = useMemo(() => {
    if (showPayrollTeam && showSelectionTeam) {
      return [
        {
          key: '/team',
          label: t('navigation_menu.team'),
          icon: <TwoUser style={{ fontSize: 20 }} />,
          children: [
            {
              key: '/team/payroll-team',
              label: (
                <Link to="/team/payroll-team" onClick={handleOnMenuItemClick}>
                  {t('navigation_menu.payroll_team')}
                </Link>
              )
            },
            ...(userHasInterimAndConstructWebAccess
              ? [
                  {
                    key: '/team/selection-team',
                    label: t('navigation_menu.selection_team'),
                    children: [
                      {
                        key: '/team/selection-team/interim',
                        label: (
                          <Link
                            to="/team/selection-team/interim"
                            onClick={handleOnMenuItemClick}
                          >
                            {t('navigation_menu.interim_team')}
                          </Link>
                        )
                      },
                      {
                        key: '/team/selection-team/construct',
                        label: (
                          <Link
                            to="/team/selection-team/construct"
                            onClick={handleOnMenuItemClick}
                          >
                            {t('navigation_menu.construct_team')}
                          </Link>
                        )
                      }
                    ]
                  }
                ]
              : [
                  {
                    key: '/team/selection-team',
                    label: (
                      <Link
                        to="/team/selection-team"
                        onClick={handleOnMenuItemClick}
                      >
                        {t('navigation_menu.selection_team')}
                      </Link>
                    )
                  }
                ])
          ]
        }
      ]
    }

    if (showPayrollTeam) {
      return [
        {
          key: '/team/payroll-team',
          label: (
            <Link to="/team/payroll-team" onClick={handleOnMenuItemClick}>
              {t('navigation_menu.team')}
            </Link>
          ),
          icon: <TwoUser style={{ fontSize: 20 }} />
        }
      ]
    }

    if (showSelectionTeam) {
      if (userHasInterimWebAccess && userHasConstructionWebAccess) {
        return [
          {
            key: '/team/selection-team',
            label: t('navigation_menu.team'),
            icon: <TwoUser style={{ fontSize: 20 }} />,
            children: [
              {
                key: '/team/selection-team/interim',
                label: (
                  <Link
                    to="/team/selection-team/interim"
                    onClick={handleOnMenuItemClick}
                  >
                    {t('navigation_menu.interim_team')}
                  </Link>
                )
              },
              {
                key: '/team/selection-team/construct',
                label: (
                  <Link
                    to="/team/selection-team/construct"
                    onClick={handleOnMenuItemClick}
                  >
                    {t('navigation_menu.construct_team')}
                  </Link>
                )
              }
            ]
          }
        ]
      }

      return [
        {
          key: '/team/selection-team',
          label: (
            <Link to="/team/selection-team" onClick={handleOnMenuItemClick}>
              {t('navigation_menu.team')}
            </Link>
          ),
          icon: <TwoUser style={{ fontSize: 20 }} />
        }
      ]
    }

    return []
  }, [
    handleOnMenuItemClick,
    showPayrollTeam,
    showSelectionTeam,
    t,
    userHasConstructionWebAccess,
    userHasInterimAndConstructWebAccess,
    userHasInterimWebAccess
  ])

  const contractsMenuItems: MenuProps['items'] = useMemo(() => {
    if (showSelectionContracts && showPayrollContracts) {
      return [
        {
          key: '/contracts',
          label: t('navigation_menu.contracts'),
          icon: <Paper style={{ fontSize: 20 }} />,
          children: [
            {
              key: '/contracts/payroll-contracts',
              label: (
                <Link
                  to="/contracts/payroll-contracts"
                  onClick={handleOnMenuItemClick}
                >
                  {t('navigation_menu.payroll_contracts')}
                </Link>
              )
            },
            ...(userHasInterimAndConstructWebAccess
              ? [
                  {
                    key: '/contracts/selection-contracts',
                    label: t('navigation_menu.selection_contracts'),
                    children: userHasInterimAndConstructWebAccess
                      ? [
                          {
                            key: '/contracts/selection-contracts/interim',
                            label: (
                              <Link
                                to="/contracts/selection-contracts/interim"
                                onClick={handleOnMenuItemClick}
                              >
                                {t('navigation_menu.interim_contracts')}
                              </Link>
                            )
                          },
                          {
                            key: '/contracts/selection-contracts/construct',
                            label: (
                              <Link
                                to="/contracts/selection-contracts/construct"
                                onClick={handleOnMenuItemClick}
                              >
                                {t('navigation_menu.construct_contracts')}
                              </Link>
                            )
                          }
                        ]
                      : undefined
                  }
                ]
              : [
                  {
                    key: '/contracts/selection-contracts',
                    label: (
                      <Link
                        to="/contracts/selection-contracts"
                        onClick={handleOnMenuItemClick}
                      >
                        {t('navigation_menu.selection_contracts')}
                      </Link>
                    )
                  }
                ])
          ]
        }
      ]
    }

    if (showSelectionContracts) {
      if (userHasInterimWebAccess && userHasConstructionWebAccess) {
        return [
          {
            key: '/contracts/selection-contracts',
            label: t('navigation_menu.contracts'),
            icon: <Paper style={{ fontSize: 20 }} />,
            children: [
              {
                key: '/contracts/selection-contracts/interim',
                label: (
                  <Link
                    to="/contracts/selection-contracts/interim"
                    onClick={handleOnMenuItemClick}
                  >
                    {t('navigation_menu.interim_contracts')}
                  </Link>
                )
              },
              {
                key: '/contracts/selection-contracts/construct',
                label: (
                  <Link
                    to="/contracts/selection-contracts/construct"
                    onClick={handleOnMenuItemClick}
                  >
                    {t('navigation_menu.construct_contracts')}
                  </Link>
                )
              }
            ]
          }
        ]
      }

      return [
        {
          key: '/contracts/selection-contracts',
          label: (
            <Link
              to="/contracts/selection-contracts"
              onClick={handleOnMenuItemClick}
            >
              {t('navigation_menu.contracts')}
            </Link>
          ),
          icon: <Paper style={{ fontSize: 20 }} />
        }
      ]
    }

    if (showPayrollContracts) {
      return [
        {
          key: '/contracts/payroll-contracts',
          label: (
            <Link
              to="/contracts/payroll-contracts"
              onClick={handleOnMenuItemClick}
            >
              {t('navigation_menu.contracts')}
            </Link>
          ),
          icon: <Paper style={{ fontSize: 20 }} />
        }
      ]
    }

    return []
  }, [
    handleOnMenuItemClick,
    showPayrollContracts,
    showSelectionContracts,
    t,
    userHasConstructionWebAccess,
    userHasInterimAndConstructWebAccess,
    userHasInterimWebAccess
  ])

  const surveysMenuItems: MenuProps['items'] = useMemo(() => {
    if (showSurveys) {
      return [
        {
          key: '/surveys',
          label: (
            <Link to="/surveys" onClick={handleOnMenuItemClick}>
              {t('navigation_menu.surveys')}
            </Link>
          ),
          icon: (
            <Badge
              count={isSiderCollapsed ? undefined : openedSurveysCount}
              dot={isSiderCollapsed ? !!openedSurveysCount : undefined}
              offset={[0, isSiderCollapsed ? 10 : 0]}
            >
              <Activity style={{ fontSize: 20 }} />
            </Badge>
          )
        }
      ]
    }

    return []
  }, [
    handleOnMenuItemClick,
    isSiderCollapsed,
    openedSurveysCount,
    showSurveys,
    t
  ])

  const invoicesMenuItems: MenuProps['items'] = useMemo(() => {
    if (showInvoices) {
      return [
        {
          key: '/invoices',
          label: (
            <Link to="/invoices" onClick={handleOnMenuItemClick}>
              {t('navigation_menu.invoices')}
            </Link>
          ),
          icon: (
            <Badge
              count={isSiderCollapsed ? undefined : unpaidInvoicesCount}
              dot={isSiderCollapsed ? !!unpaidInvoicesCount : undefined}
              offset={[0, isSiderCollapsed ? 10 : 0]}
            >
              <PaperUpload style={{ fontSize: 20 }} />
            </Badge>
          )
        }
      ]
    }

    return []
  }, [
    handleOnMenuItemClick,
    isSiderCollapsed,
    showInvoices,
    t,
    unpaidInvoicesCount
  ])

  const timesheetsMenuItems: MenuProps['items'] = useMemo(() => {
    if (showTimesheets) {
      const notProcessedTimesheetCount =
        notProcessedConstructTimesheetCount + notProcessedInterimTimesheetCount

      if (userHasInterimWebAccess && userHasConstructionWebAccess) {
        return [
          {
            key: '/hours-validation',
            label: t('navigation_menu.timesheets'),
            icon: (
              <Badge
                count={
                  isSiderCollapsed ? undefined : notProcessedTimesheetCount
                }
                dot={
                  isSiderCollapsed ? !!notProcessedTimesheetCount : undefined
                }
                offset={[0, isSiderCollapsed ? 10 : 0]}
              >
                <TimeSquare style={{ fontSize: 20 }} />
              </Badge>
            ),
            children: [
              {
                key: '/hours-validation/interim',
                label: (
                  <Link
                    to="/hours-validation/interim"
                    onClick={handleOnMenuItemClick}
                  >
                    {t('navigation_menu.interim_timesheets')}
                  </Link>
                )
              },
              {
                key: '/hours-validation/construct',
                label: (
                  <Link
                    to="/hours-validation/construct"
                    onClick={handleOnMenuItemClick}
                  >
                    {t('navigation_menu.construct_timesheets')}
                  </Link>
                )
              }
            ]
          }
        ]
      }

      return [
        {
          key: '/hours-validation',
          label: (
            <Link to="/hours-validation" onClick={handleOnMenuItemClick}>
              {t('navigation_menu.timesheets')}
            </Link>
          ),
          icon: (
            <Badge
              count={isSiderCollapsed ? undefined : notProcessedTimesheetCount}
              dot={isSiderCollapsed ? !!notProcessedTimesheetCount : undefined}
              offset={[0, isSiderCollapsed ? 10 : 0]}
            >
              <TimeSquare style={{ fontSize: 20 }} />
            </Badge>
          )
        }
      ]
    }

    return []
  }, [
    handleOnMenuItemClick,
    isSiderCollapsed,
    notProcessedConstructTimesheetCount,
    notProcessedInterimTimesheetCount,
    showTimesheets,
    t,
    userHasConstructionWebAccess,
    userHasInterimWebAccess
  ])

  const vivaldisSelectionPromoMenuItems: MenuProps['items'] = useMemo(() => {
    if (showVivaldisSelectionPromo) {
      return [
        {
          key: '/vivaldis-selection-promo',
          label: (
            <Link
              to="/vivaldis-selection-promo"
              onClick={handleOnMenuItemClick}
            >
              {t('navigation_menu.vivaldis_selection_promo')}
            </Link>
          ),
          icon: (
            <Badge count={<PromoLabelWithWhiteBorderIcon />} offset={[0, 5]}>
              <Waller style={{ fontSize: 20 }} />
            </Badge>
          )
        }
      ]
    }

    return []
  }, [handleOnMenuItemClick, showVivaldisSelectionPromo, t])

  const vivaldisPayrollPromoMenuItems: MenuProps['items'] = useMemo(() => {
    if (showVivaldisPayrollPromo) {
      return [
        {
          key: '/vivaldis-payroll-promo',
          label: (
            <Link to="/vivaldis-payroll-promo" onClick={handleOnMenuItemClick}>
              {t('navigation_menu.vivaldis_payroll_promo')}
            </Link>
          ),
          icon: (
            <Badge count={<PromoLabelWithWhiteBorderIcon />} offset={[-1, 3]}>
              <TreeUser style={{ fontSize: 20 }} />
            </Badge>
          )
        }
      ]
    }

    return []
  }, [handleOnMenuItemClick, showVivaldisPayrollPromo, t])

  const faqMenuItems: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: '/faq',
        label: (
          <Link to="/faq" onClick={handleOnMenuItemClick}>
            {t('navigation_menu.faq')}
          </Link>
        ),
        icon: <IconSquare style={{ fontSize: 20 }} />
      }
    ]
  }, [handleOnMenuItemClick, t])
  /// end

  const mainNavigationItem = useMemo(() => {
    return [
      ...positionsMenuItems,
      ...teamMenuItems,
      ...contractsMenuItems,
      ...surveysMenuItems,
      ...invoicesMenuItems,
      ...timesheetsMenuItems,
      ...vivaldisSelectionPromoMenuItems,
      ...vivaldisPayrollPromoMenuItems,
      ...faqMenuItems
    ]
  }, [
    contractsMenuItems,
    faqMenuItems,
    invoicesMenuItems,
    positionsMenuItems,
    surveysMenuItems,
    teamMenuItems,
    timesheetsMenuItems,
    vivaldisPayrollPromoMenuItems,
    vivaldisSelectionPromoMenuItems
  ])

  const menuItems: MenuProps['items'] = useMemo(() => {
    // on desktop, we show settings menu items in the main menu when user on "/settings" pages
    if (isSettingScreens && breakpoint.lg) {
      return [
        {
          type: 'group',
          label: t('navigation_menu.settings.company_group'),
          children: [
            {
              key: '/settings/company',
              label: (
                <Link to="/settings/company" onClick={handleOnMenuItemClick}>
                  {t('navigation_menu.company')}
                </Link>
              ),
              icon: <Work style={{ fontSize: 20 }} />
            },
            {
              key: '/settings/company-users',
              label: (
                <Link
                  to="/settings/company-users"
                  onClick={handleOnMenuItemClick}
                >
                  {t('navigation_menu.users')}
                </Link>
              ),
              icon: <TreeUser style={{ fontSize: 20 }} />
            },
            {
              key: '/settings/offers',
              label: (
                <Link to="/settings/offers" onClick={handleOnMenuItemClick}>
                  {t('navigation_menu.offers')}
                </Link>
              ),
              icon: <Discount style={{ fontSize: 20 }} />
            }
          ]
        },
        {
          type: 'group',
          label: t('navigation_menu.settings.personal_group'),
          children: [
            {
              key: '/settings/profile',
              label: (
                <Link to="/settings/profile" onClick={handleOnMenuItemClick}>
                  {t('navigation_menu.profile')}
                </Link>
              ),
              icon: <Profile style={{ fontSize: 20 }} />
            },
            {
              key: '/settings/email-settings',
              label: (
                <Link
                  to="/settings/email-settings"
                  onClick={handleOnMenuItemClick}
                >
                  {t('navigation_menu.email_settings')}
                </Link>
              ),
              icon: <NotificationOutlined style={{ fontSize: 20 }} />
            }
          ]
        }
      ]
    }

    return mainNavigationItem
  }, [
    breakpoint.lg,
    handleOnMenuItemClick,
    isSettingScreens,
    mainNavigationItem,
    t
  ])

  const location = useLocation()
  const defaultSelectedKeys = useMemo(() => {
    return menuItems
      .map(menuItem => getActiveMenuItemKeys(menuItem, location))
      .flat()
      .filter(Boolean)
  }, [location, menuItems])

  const defaultOpenKeys = useMemo(() => {
    return [
      // When user has selection team with both Interim and Construct access we keep sub-menu option open by default
      ...(isActiveLink({ location, to: '/team' })
        ? ['/team', '/team/selection-team']
        : []),
      // When user has selection contracts with both Interim and Construct access we keep sub-menu option open by default
      ...(isActiveLink({ location, to: '/contracts' })
        ? ['/contracts', '/contracts/selection-contracts']
        : []),
      ...(isActiveLink({ location, to: '/hours-validation' })
        ? ['/hours-validation']
        : [])
    ]
  }, [location])

  return (
    <Menu
      style={{ paddingTop: breakpoint.lg ? 16 : 0, border: 'none' }}
      selectedKeys={defaultSelectedKeys}
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      mode="inline"
      items={menuItems}
    />
  )
}
