import { Typography } from '@vivaldis/antd-ui'
import { useTheme } from '@vivaldis/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const NotSetValue: FC = () => {
  const [t] = useTranslation('web_ui')

  const theme = useTheme()
  return (
    <Typography.Text color={theme.gray7}>
      {t('employee_profile.not_set')}
    </Typography.Text>
  )
}
