import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Form,
  Input,
  Radio,
  TextStylePreset,
  Typography
} from '@vivaldis/antd-ui'
import { useForm } from '../../../../provider/FormProvider'
import type { CompanySurvey_questions } from '../../../../types/CompanySurvey'
import { getQuestionMetadata } from '../../../../utils/getQuestionMetadata'

interface Props {
  question: CompanySurvey_questions
}

export const BoolWithText: FC<Props> = ({ question }) => {
  const [t] = useTranslation('web_ui')

  const { values } = useForm()

  const boolFiledName = `${question.id}.boolValue`
  const textFiledName = `${question.id}.textValue`

  const isTextFieldRequired = useMemo(() => {
    const boolValue = values?.[question.id]?.['boolValue']
    const { textValueRequired } = getQuestionMetadata(question, boolValue)
    return textValueRequired
  }, [question, values])

  return (
    <>
      <Form.Item
        name={boolFiledName}
        required={question.required}
        label={
          <Typography.Text preset={TextStylePreset.Semibold16} marginBottom={4}>
            {question.title}
          </Typography.Text>
        }
      >
        <Radio.Group name={boolFiledName} size="large">
          <Radio name={boolFiledName} value={true}>
            {t('survey_form.bool.options.yes')}
          </Radio>
          <Radio name={boolFiledName} value={false}>
            {t('survey_form.bool.options.no')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={textFiledName} required={isTextFieldRequired}>
        <Input
          name={textFiledName}
          placeholder={
            isTextFieldRequired
              ? t('survey_form.text.required_placeholder')
              : t('survey_form.text.optional_placeholder')
          }
          size="large"
          autoComplete="off"
        />
      </Form.Item>
    </>
  )
}
