import { STUDENT_FUNCTION_CODES } from '@vivaldis/common'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

interface Committee {
  isWorker: boolean
}

interface FunctionCode {
  code: string
}

interface ContractDatum {
  committee: Committee | null
  functionCode: FunctionCode
  isFlexi: boolean | null
}

export const useStatuteLabel = (
  contractDatum: ContractDatum | null | undefined
) => {
  const [t] = useTranslation('web_ui')

  return useMemo(() => {
    if (!contractDatum || !contractDatum.committee) {
      return null
    }

    const { committee, functionCode, isFlexi } = contractDatum

    if (isFlexi) {
      return t('statute.flexi')
    }
    const isWorker = committee.isWorker
    const isStudent = STUDENT_FUNCTION_CODES.includes(functionCode.code)

    if (isStudent) {
      return isWorker ? t('statute.student_worker') : t('statute.student_clerk')
    }
    return isWorker ? t('statute.worker') : t('statute.clerk')
  }, [contractDatum, t])
}
