import { FC } from 'react'
import { Typography as AntdTypography } from 'antd'
import type { TextProps as AntdTextProps } from 'antd/lib/typography/Text'
import { makeBaseTextComponent } from './BaseText'
import type { BaseTextProps } from './BaseText'

export interface TextProps extends AntdTextProps, BaseTextProps {}

export const Text: FC<TextProps> = ({ ...props }) => {
  return <AntdTypographyText {...props} />
}

const AntdTypographyText = makeBaseTextComponent(AntdTypography.Text)
