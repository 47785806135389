import { FC } from 'react'
import { StepsProgressHeader } from './components/StepsProgressHeader'
import { PositionProvider } from './providers/PositionProvider'
import { StepProvider } from './providers/StepProvider'
import { Step1, Step1Props } from './steps/Step1'
import { Step2, Step2Props } from './steps/Step2'
import { Step3, Step3Props } from './steps/Step3'
import { Position } from './typings/Position'

interface PositionFormProps {
  position: Position | null
  step1Props: Step1Props
  step2Props: Step2Props
  step3Props: Step3Props
}

export const PositionForm: FC<PositionFormProps> = ({
  position,
  step1Props,
  step2Props,
  step3Props
}) => {
  return (
    <PositionProvider position={position}>
      <StepProvider>
        <StepsProgressHeader />
        <Step1 {...step1Props} />
        <Step2 {...step2Props} />
        <Step3 {...step3Props} />
      </StepProvider>
    </PositionProvider>
  )
}
