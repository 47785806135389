import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionBar as BaseActionBar } from '../../../ActionBar'
import { SubmitButton } from '../../../SubmitButton'
import { useForm } from '../../provider/FormProvider'

export const ActionBar: FC = () => {
  const [t] = useTranslation('web_ui')

  const { isSubmitting } = useForm()

  return (
    <BaseActionBar
      rightContent={
        <SubmitButton
          isSubmitting={isSubmitting}
          label={t('survey_form.submit_button')}
        />
      }
    />
  )
}
