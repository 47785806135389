import { ReducersMapObject } from 'redux'
import { AppActionType } from '@vivaldis/app'
import { SessionActionType } from '../actions'
import { Action } from '../typings/Action'
import { SessionData } from '../typings/SessionData'
import { SignInResponse } from '../api/signIn'
import { SignUpResponse } from '../api/signUp'
import { VerifyOTPResponse } from '../api/verifyOTP'
import { persistReducer } from 'redux-persist'
import { EmployeeFragment, UserFragment } from '@vivaldis/graphql'

export const SESSION_STATE_KEY = '@session'
export const SESSION_PERSIST_KEY = '@@redux_session'
export const SESSION_PERSIST_WHITELIST = ['data', 'source']

export interface SessionSignIn {
  submitting: boolean
  error: any
  data: SignInResponse | undefined
}

export interface SessionVerifyOTP {
  submitting: boolean
  error: any
  data: VerifyOTPResponse | undefined
}

export interface SessionSignUp {
  submitting: boolean
  error: any
  data: SignUpResponse | undefined
}

export interface SessionState {
  data: SessionData
  ready: boolean
  has_session_on_init: boolean
  processing_invalid_session: boolean
  sign_in: SessionSignIn
  sign_up: SessionSignUp
  verify_otp: SessionVerifyOTP
  employee?: EmployeeFragment
  user?: UserFragment
}

const initialState: SessionState = {
  data: {
    authentication_token: undefined,
    email: undefined,
    push_notifications_token: undefined
  },
  ready: false,
  has_session_on_init: false,
  processing_invalid_session: false,
  sign_in: {
    submitting: false,
    error: undefined,
    data: undefined
  },
  sign_up: {
    submitting: false,
    error: undefined,
    data: undefined
  },
  verify_otp: {
    submitting: false,
    error: undefined,
    data: undefined
  },
  employee: undefined,
  user: undefined
}

export function createSessionReducer(
  storage: any
): (reducers: ReducersMapObject<any, any>) => ReducersMapObject<any, any> {
  return (reducers: ReducersMapObject<any, any>) => {
    const reducer = persistReducer<SessionState, Action>(
      {
        storage,
        key: SESSION_PERSIST_KEY,
        whitelist: SESSION_PERSIST_WHITELIST
      },
      (state = initialState, action: Action) => {
        switch (action.type) {
          case AppActionType.Reset:
          case SessionActionType.SignOutComplete:
            return {
              ...initialState,
              ready: false,
              processing_invalid_session: state.processing_invalid_session
            }

          case AppActionType.Init:
            return {
              ...state,
              has_session_on_init: !!state.data.authentication_token
            }

          case SessionActionType.Init:
            return {
              ...state,
              processing_invalid_session: false,
              data: {
                ...state.data,
                ...action.payload
              }
            }

          case SessionActionType.InitComplete:
            return {
              ...state,
              ready: true,
              employee: action.payload.employee,
              user: action.payload.user
            }

          case SessionActionType.Invalidate:
            return {
              ...state,
              ready: false,
              processing_invalid_session: true
            }

          case SessionActionType.InvalidateComplete:
            return {
              ...state,
              ready: false,
              processing_invalid_session: false
            }

          case SessionActionType.Update:
            return {
              ...state,
              data: {
                ...state.data,
                ...action.payload
              }
            }

          case SessionActionType.UpdateUser:
            return {
              ...state,
              user: {
                ...state?.user,
                ...action.payload
              }
            }

          case SessionActionType.SignIn:
            return {
              ...state,
              sign_in: {
                submitting: true,
                error: undefined,
                data: undefined
              }
            }

          case SessionActionType.SignInSuccess:
            return {
              ...state,
              sign_in: {
                submitting: false,
                error: undefined,
                data: action.payload
              }
            }

          case SessionActionType.SignInError:
            return {
              ...state,
              sign_in: {
                submitting: false,
                error: action.payload.error,
                data: undefined
              }
            }

          case SessionActionType.SignUp:
            return {
              ...state,
              sign_up: {
                submitting: true,
                error: undefined,
                data: undefined
              }
            }

          case SessionActionType.SignUpSuccess:
            return {
              ...state,
              sign_up: {
                submitting: false,
                error: undefined,
                data: action.payload
              }
            }

          case SessionActionType.SignUpError:
            return {
              ...state,
              sign_up: {
                submitting: false,
                error: action.payload.error,
                data: undefined
              }
            }

          case SessionActionType.SignOut:
            return {
              ...state,
              ready: false
            }

          case SessionActionType.VerifyOTP:
            return {
              ...state,
              verify_otp: {
                submitting: true,
                error: undefined,
                data: undefined
              }
            }

          case SessionActionType.VerifyOTPSuccess:
            return {
              ...state,
              verify_otp: {
                submitting: false,
                error: undefined,
                data: action.payload
              }
            }

          case SessionActionType.VerifyOTPError:
            return {
              ...state,
              verify_otp: {
                submitting: false,
                error: action.payload.error,
                data: undefined
              }
            }

          default:
            return state
        }
      }
    )

    return {
      ...reducers,
      [SESSION_STATE_KEY]: reducer
    }
  }
}
