import type { MainNavigate } from '../typings/MainNavigate'

export type ReactRouterNavigatorEventType = 'change'

export type ReactRouterNavigatorEventHandler = (
  event: MainNavigate | undefined
) => any

export interface ReactRouterNavigatorListener {
  type: 'change'
  handler: ReactRouterNavigatorEventHandler
}

export class MainNavigateGlobalProvider {
  private static navigate: MainNavigate | undefined

  public static setNavigate(navigate: MainNavigate | undefined) {
    MainNavigateGlobalProvider.navigate = navigate
    MainNavigateGlobalProvider.triggerEvent('change', navigate)
  }

  public static async getNavigate(): Promise<MainNavigate> {
    return new Promise(resolve => {
      if (MainNavigateGlobalProvider.navigate) {
        return resolve(MainNavigateGlobalProvider.navigate)
      }

      const handleChange = () => {
        if (MainNavigateGlobalProvider.navigate) {
          MainNavigateGlobalProvider.removeEventListener('change', handleChange)
          resolve(MainNavigateGlobalProvider.navigate)
        }
      }
      MainNavigateGlobalProvider.addEventListener('change', handleChange)
    })
  }

  public static _listeners: ReactRouterNavigatorListener[] = []

  public static addEventListener(
    type: ReactRouterNavigatorEventType,
    handler: ReactRouterNavigatorEventHandler
  ) {
    MainNavigateGlobalProvider._listeners.push({ type, handler })
  }

  public static removeEventListener(
    type: ReactRouterNavigatorEventType,
    handler: ReactRouterNavigatorEventHandler
  ) {
    MainNavigateGlobalProvider._listeners =
      MainNavigateGlobalProvider._listeners.filter(
        listener => !(listener.type === type && listener.handler === handler)
      )
  }

  public static triggerEvent(
    type: ReactRouterNavigatorEventType,
    navigate: MainNavigate | undefined
  ) {
    MainNavigateGlobalProvider._listeners
      .filter(l => l.type === type)
      .forEach(({ handler }) => handler(navigate))
  }
}
