import { FC } from 'react'
import styled from '@emotion/styled'
import { Spin } from '@vivaldis/antd-ui'

export interface LoadMoreActivityIndicatorProps {
  visible?: boolean
}

export const LoadMoreActivityIndicator: FC<LoadMoreActivityIndicatorProps> = ({
  visible = true
}) => {
  return <Container>{visible ? <Spin /> : null}</Container>
}

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 32px;
`
