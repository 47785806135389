import styled from '@emotion/styled'
import { TextStylePreset, Typography } from '@vivaldis/antd-ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Contract } from '../typings/Contract'
import { getWorkingTimeFromWorkday } from '../utils/getWorkingTimeFromWorkday'

interface Props {
  contract: Contract
}

export const HoursSummary: FC<Props> = ({ contract }) => {
  const { t } = useTranslation('web_ui')

  const totalWorkingMinutes = useMemo(() => {
    const workdays = contract?.workdays || []
    return workdays.reduce((totalWorkingTimeInMinutes, workday) => {
      const { workingTimeInMinutes } = getWorkingTimeFromWorkday(workday)
      return totalWorkingTimeInMinutes + workingTimeInMinutes
    }, 0)
  }, [contract?.workdays])

  const hoursLabel = useMemo(() => {
    const hours = Math.floor(totalWorkingMinutes / 60)
    const minutes = totalWorkingMinutes % 60

    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(minutes).padStart(2, '0')
    return `${formattedHours}:${formattedMinutes}`
  }, [totalWorkingMinutes])

  return (
    <Base>
      <div>
        <HoursRow>
          <Typography.Paragraph
            preset={TextStylePreset.Regular14}
            marginBottom={0}
            marginRight={8}
          >
            {t('contract_schedule.total_hours')}
          </Typography.Paragraph>
          <Typography.Paragraph
            preset={TextStylePreset.Semibold14}
            marginBottom={0}
          >
            {hoursLabel}{' '}
            {t('contract_schedule.hours', {
              count: totalWorkingMinutes ? totalWorkingMinutes / 60 : 0
            })}
          </Typography.Paragraph>
        </HoursRow>
      </div>
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin: 0 -16px;
`

const HoursRow = styled('div')`
  display: flex;
  justify-content: space-between;
  min-width: 240px;
  padding: 14px 12px;
  background: ${props => props.theme.white};
  border: ${props => props.theme.gray4} 1px solid;
`
