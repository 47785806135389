import { UploadOutlined, EyeOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/es/upload/interface'
import type { RcFile } from 'antd/lib/upload/interface'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Button, Form, Upload } from '@vivaldis/antd-ui'
import { fileToBase64 } from '../../../../../utils/fileToBase64'
import { transformDataToBase64 } from '../../../../EmployeeCertificateForm/utils/transformDataToBase64'
import { useForm } from '../../../providers/FormProvider'

interface Props {
  disabledEditing: boolean
}

export const UploadPDFWPSFormContent: FC<Props> = ({ disabledEditing }) => {
  const [t] = useTranslation('web_ui')

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const showUploadPDFButton = !disabledEditing

  const { values, setFieldValue, isSubmitting } = useForm()

  useEffect(() => {
    if (isSubmitting) {
      // we want to simulate a progress bar. We will update the progress bar every 100ms until it reaches 95%
      const intervalId = setInterval(() => {
        setFileList(prevFileList => {
          if (prevFileList.length > 0) {
            return prevFileList.map(file => {
              const percent = (file?.percent ?? 0) + 2
              return {
                ...file,
                percent: Math.min(percent, 95),
                status: 'uploading'
              }
            })
          }
          return prevFileList
        })
      }, 100)

      return () => clearInterval(intervalId)
    }
  }, [isSubmitting])

  const handlePDFUpload = useCallback(
    async (file: RcFile) => {
      try {
        // update UI with a new file
        setFileList([
          {
            uid: file.uid,
            name: file.name
          }
        ])

        const newPdfCommentAttachment = {
          fileName: file.name,
          base64: await fileToBase64(file),
          dataURL: await transformDataToBase64(file)
        }

        setFieldValue('newPdfCommentAttachment', newPdfCommentAttachment)

        return newPdfCommentAttachment.dataURL
      } catch (e) {
        setFileList([])
      }
      return ''
    },
    [setFieldValue]
  )

  return (
    <>
      {showUploadPDFButton ? (
        <Form.Item name="newPdfCommentAttachment.base64">
          <Upload
            action={handlePDFUpload}
            fileList={fileList}
            showUploadList={{
              showDownloadIcon: false,
              showRemoveIcon: false
            }}
            accept=".pdf"
          >
            <Button icon={<UploadOutlined />} size="middle">
              {t('work_post_sheet_form.newPdfCommentAttachment.upload_button')}
            </Button>
          </Upload>
        </Form.Item>
      ) : null}

      {!showUploadPDFButton && values.existingPdfUrl ? (
        <Typography.Link href={values.existingPdfUrl} target="_blank" strong>
          <EyeOutlined style={{ marginRight: 8 }} />
          {t('work_post_sheet_form.existingPdfUrl.view_pdf')}
        </Typography.Link>
      ) : null}
    </>
  )
}
