import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const WorkSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.76922 4.58016C7.95566 3.12508 9.19947 1.99976 10.7051 1.99976H13.2851C14.7907 1.99976 16.0345 3.12508 16.2209 4.58016H18.1899C20.2973 4.58016 21.9899 6.28915 21.9899 8.39016V11.8302C21.9899 12.0965 21.8486 12.3429 21.6188 12.4774C19.2094 13.8878 16.111 14.7629 12.7451 14.8773V16.6768C12.7451 17.091 12.4093 17.4268 11.9951 17.4268C11.5809 17.4268 11.2451 17.091 11.2451 16.6768V14.8777C7.87603 14.7647 4.78253 13.889 2.37099 12.4774C2.14113 12.3429 1.99988 12.0965 1.99988 11.8302V8.38016C1.99988 6.27275 3.70887 4.58016 5.80988 4.58016H7.76922ZM9.295 4.58016H14.6952C14.5279 3.95816 13.9596 3.49976 13.2851 3.49976H10.7051C10.0306 3.49976 9.46227 3.95816 9.295 4.58016ZM12.0192 13.3901C12.0112 13.3899 12.0031 13.3898 11.9951 13.3898C11.9869 13.3898 11.9788 13.3899 11.9706 13.3901C8.7336 13.3872 5.78091 12.631 3.49988 11.3922V8.38016C3.49988 7.10756 4.53089 6.08016 5.80988 6.08016H18.1899C19.4625 6.08016 20.4899 7.11117 20.4899 8.39016V11.3921C18.2105 12.6295 15.2527 13.3856 12.0192 13.3901ZM2.71804 14.7351C3.13108 14.704 3.49114 15.0136 3.52226 15.4266L3.71124 17.9353C3.80979 19.2354 4.89304 20.24 6.19538 20.24H17.7944C19.0967 20.24 20.1799 19.2357 20.2785 17.9356C20.2785 17.9355 20.2785 17.9357 20.2785 17.9356L20.4675 15.4266C20.4986 15.0136 20.8587 14.704 21.2717 14.7351C21.6848 14.7662 21.9944 15.1262 21.9633 15.5393L21.7743 18.0483C21.6167 20.1301 19.882 21.74 17.7944 21.74H6.19538C4.1078 21.74 2.3731 20.1304 2.21552 18.0486L2.0265 15.5393C1.99538 15.1262 2.305 14.7662 2.71804 14.7351Z"
    />
  </svg>
)

export const Work = forwardRef<HTMLSpanElement, AntdIconProps>((props, ref) => (
  <Icon component={WorkSvg} {...props} ref={ref} />
))
