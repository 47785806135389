import { useUser } from '@vivaldis/session'
import { useMemo } from 'react'

export const useCompanyHasPayrollAccess = (): boolean => {
  const user = useUser()

  // More about navigation menu -> https://sealjobs.atlassian.net/wiki/spaces/SJ/pages/2157936641/Navigation+menu
  // Company has "Payroll" level when it has at least one payroll offer (with connexysId). In field offers we have only payroll offers
  return useMemo(() => {
    return user?.company?.offers?.some(offer => !!offer.connexysId) || false
  }, [user?.company?.offers])
}
