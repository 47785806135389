import { FC } from 'react'
import { Information } from '../../../Information'
import type { CompanySurvey_questions } from '../../types/CompanySurvey'
import { EmptyTextValue } from '../EmptyTextValue'

interface Props {
  question: CompanySurvey_questions
}

export const Text: FC<Props> = ({ question }) => {
  const textValue = question.answer?.textValue || ''

  return (
    <>
      <Information.InformationField label={question.title}>
        {textValue || <EmptyTextValue />}
      </Information.InformationField>
    </>
  )
}
