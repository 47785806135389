import {
  FillCompanySurveyMutation,
  FillCompanySurveyMutationVariables
} from './__generated__/FillCompanySurveyMutation'
import { ApolloClient, MutationOptions, gql } from '@apollo/client'

const mutation = gql`
  mutation FillCompanySurveyMutation($input: FillCompanySurveyInput!) {
    fillCompanySurvey(input: $input) {
      survey {
        createdAt
        employee {
          id
          firstName
          lastName
        }
        id
        participant {
          id
        }
        surveyType
      }
    }
  }
`

export function fillCompanySurveyMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<
      FillCompanySurveyMutation,
      FillCompanySurveyMutationVariables
    >,
    'mutation'
  >
) {
  return apolloClient.mutate<
    FillCompanySurveyMutation,
    FillCompanySurveyMutationVariables
  >({
    mutation,
    ...options
  })
}
