import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, PropsWithChildren } from 'react'
import { useIsInSideSheet } from '../../SideSheets/hooks/useIsInSideSheet'
import { IsInLayoutContext } from '../context/IsInLayoutContext'

export const SIDE_SHEET_DEFAULT_WIDTH = 540

export const SideSheet: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const isInSideSheet = useIsInSideSheet()

  return (
    <IsInLayoutContext.Provider value="SideSheet">
      <Container isInSideSheet={isInSideSheet}>
        <Wrapper>{children}</Wrapper>
      </Container>
    </IsInLayoutContext.Provider>
  )
}

const Container = styled('section')<{ isInSideSheet: boolean }>`
  ${({ isInSideSheet, theme }) =>
    isInSideSheet
      ? css`
          width: 100%;
          height: calc(100% - 56px);
          box-sizing: border-box;
          background-color: ${theme.extraBackground1};
        `
      : css`
          width: 100%;
          max-width: ${SIDE_SHEET_DEFAULT_WIDTH}px;
          margin-left: auto;
          margin-right: auto;
          height: auto;

          @media only screen and (max-width: ${theme.screenLG - 1}px) {
            box-sizing: border-box;
            width: 100%;
            max-width: 100%;
            height: auto;
          }
        `};
`

const Wrapper = styled('div')`
  padding: 16px;

  @media only screen and (max-width: ${({ theme }) => theme.screenLG - 1}px) {
    padding-top: 24px;
  }
`
