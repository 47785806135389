export interface OffersCommittee {
  __typename: 'Committee'
  code: string
}

interface CompanyOffer {
  __typename: 'Offer'
  committees: OffersCommittee[]
}

interface JointCommittee {
  __typename: 'JointCommittee'
  code: string
  ecoCheques: boolean
}

export const showEcoChequeFieldsOnPositionForm = (
  companyOffers: CompanyOffer[],
  jointCommittees: JointCommittee[]
): boolean => {
  const jointCommitteesWithEcoCheques = jointCommittees.filter(
    jointCommittee => jointCommittee.ecoCheques
  )

  return companyOffers.some(offer =>
    offer.committees.some(committee => {
      return jointCommitteesWithEcoCheques.some(
        jointCommittee =>
          jointCommittee.ecoCheques && jointCommittee.code === committee.code
      )
    })
  )
}
