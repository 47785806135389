import { ReducersMapObject } from 'redux'
import { NotificationsAction, NotificationsActionType } from '../actions'
import { NotificationsState } from '../typings/State'
import { persistReducer } from 'redux-persist'

export const NOTIFICATIONS_STATE_KEY = '@notifications'
export const NOTIFICATIONS_PERSIST_KEY = '@@redux_notifications'

const initialState: NotificationsState = {
  token: undefined
}

export function createNotificationsReducer(
  storage: any
): (reducers: ReducersMapObject<any, any>) => ReducersMapObject<any, any> {
  return (reducers: ReducersMapObject<any, any>) => {
    const reducer = persistReducer<NotificationsState, NotificationsAction>(
      {
        storage,
        key: NOTIFICATIONS_PERSIST_KEY
      },
      (
        state: NotificationsState = initialState,
        action: NotificationsAction
      ) => {
        switch (action.type) {
          case NotificationsActionType.EndSuccess:
          case NotificationsActionType.EndError:
            return initialState

          case NotificationsActionType.GetTokenSuccess:
            return {
              token: action.payload.token
            }

          default:
            return state
        }
      }
    )
    return {
      ...reducers,
      [NOTIFICATIONS_STATE_KEY]: reducer
    }
  }
}
