import {
  createContext,
  useRef,
  useMemo,
  useLayoutEffect,
  useState,
  memo,
  ReactNode,
  FC,
  PropsWithChildren
} from 'react'
import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  NetworkStatus
} from '@apollo/client'
import { userQuery } from '../queries/userQuery'
import {
  UserQuery as Type,
  UserQueryVariables
} from '../queries/__generated__/UserQuery'
import { UserType } from '../typings/UserType'
import { useLocale } from '@vivaldis/common'
import { useSession } from '../hooks/useSession'
import { useHasSession } from '../hooks/useHasSession'

export interface UserQueryContextValue extends QueryResult<Type> {}

export const UserQueryContext = createContext<
  UserQueryContextValue | undefined
>(undefined)

interface Props {
  children?: ReactNode
  type: UserType
}

const MemoizedUserQueryProvider: FC<PropsWithChildren<Props>> = ({
  children,
  type
}) => {
  const hasSession = useHasSession()
  const session = useSession()
  const locale = useLocale()

  const options = useMemo(
    (): QueryHookOptions<Type, UserQueryVariables> => ({
      fetchPolicy: 'cache-and-network',
      skip: !hasSession || ![UserType.User, UserType.SuperUser].includes(type), // TODO: use skipToken
      variables: {
        locale
      }
    }),
    [hasSession, type, locale]
  )

  const result = useQuery<Type>(userQuery, options)
  const refetch = useRef(result.refetch)
  refetch.current = result.refetch

  const [isValid, setIsValid] = useState(hasSession)

  const hadSession = useRef(hasSession)
  useLayoutEffect(() => {
    if (
      hasSession &&
      !hadSession.current &&
      (session.type === UserType.User || session.type === UserType.SuperUser)
    ) {
      setIsValid(false)
      refetch.current()
    }
    hadSession.current = hasSession
  }, [hasSession, session.type])

  useLayoutEffect(() => {
    if (
      hasSession &&
      (session.type === UserType.User || session.type === UserType.SuperUser) &&
      result.networkStatus === NetworkStatus.ready
    ) {
      if (!isValid) {
        setIsValid(true)
      }
    } else if (!hasSession) {
      if (isValid) {
        setIsValid(false)
      }
    }
  }, [hasSession, result.networkStatus, session.type, isValid])

  return (
    <UserQueryContext.Provider value={isValid ? result : undefined}>
      {children}
    </UserQueryContext.Provider>
  )
}

export const UserQueryProvider = memo(MemoizedUserQueryProvider)
