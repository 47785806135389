import { useApolloClient } from '@apollo/client'
import { Logout, Settings } from '@vivaldis/icons'
import { useCallback, FC, useMemo, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Typography, Dropdown } from '@vivaldis/antd-ui'
import { useTheme } from '@vivaldis/ui'
import { Link, useNavigate } from '@vivaldis/web-ui'
import { signOut, useUser } from '@vivaldis/session'
import styled from '@emotion/styled'
import { GlobalOutlined } from '@ant-design/icons'
import { updateUserMutation } from '../../../../../../graphql/mutations/updateUserMutation'
import { CompanyAvatar } from './components/CompanyAvatar'

export const AvatarAndMenu: FC = () => {
  const [t, i18n] = useTranslation('navigation_bar')
  const theme = useTheme()
  const user = useUser()

  const apolloClient = useApolloClient()
  const handleLanguageClick = useCallback(() => {
    // NL -> EN -> FR
    const language =
      i18n.language === 'nl' ? 'en' : i18n.language === 'en' ? 'fr' : 'nl'
    i18n.changeLanguage(language)
    if (user?.email && user?.firstName && user?.lastName) {
      updateUserMutation(apolloClient, {
        variables: {
          input: {
            user: {
              email: user?.email,
              firstName: user?.firstName,
              lastName: user?.lastName,
              preferredLanguage: language
            }
          }
        }
      })
    }
  }, [i18n, user?.email, user?.firstName, user?.lastName, apolloClient])

  const handleChangeLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language)
      if (user?.email && user?.firstName && user?.lastName) {
        updateUserMutation(apolloClient, {
          variables: {
            input: {
              user: {
                email: user?.email,
                firstName: user?.firstName,
                lastName: user?.lastName,
                preferredLanguage: language
              }
            }
          }
        })
      }
    },
    [apolloClient, i18n, user?.email, user?.firstName, user?.lastName]
  )

  const handleChangeLanguageToEN = useCallback(
    (e?: MouseEvent) => {
      e?.stopPropagation()
      handleChangeLanguage('en')
    },
    [handleChangeLanguage]
  )
  const handleChangeLanguageToNL = useCallback(
    (e?: MouseEvent) => {
      e?.stopPropagation()
      handleChangeLanguage('nl')
    },
    [handleChangeLanguage]
  )
  const handleChangeLanguageToFR = useCallback(
    (e?: MouseEvent) => {
      e?.stopPropagation()
      handleChangeLanguage('fr')
    },
    [handleChangeLanguage]
  )

  const dispatch = useDispatch()
  const handleLogout = useCallback(() => dispatch(signOut()), [dispatch])
  const navigate = useNavigate()

  const handleSettingClick = useCallback(() => {
    navigate({ to: '/settings' })
  }, [navigate])

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const handleMenuOpenChange = useCallback(
    (open: boolean) => setIsMenuOpen(open),
    []
  )

  const handleMenuClick = useCallback((e: any) => {
    if (e.key !== 'change_language') {
      setIsMenuOpen(false)
    }
  }, [])

  // we force user to finish onboarding when company doesn't have VAT (this mean that onboarding was not passed yet)
  const showOnboarding = useMemo(
    () => !user?.company?.vat,
    [user?.company?.vat]
  )

  return (
    <Base>
      <CompanyAvatar />
      <Dropdown
        menu={{
          style: { minWidth: 232 },
          onClick: handleMenuClick,
          items: [
            {
              key: 'change_language',
              label: (
                <LanguageBase>
                  {t('menu.change_language')}
                  <LanguageComponent>
                    <Typography.Text
                      color={
                        i18n.language === 'nl'
                          ? theme.primaryColor
                          : theme.gray6
                      }
                      onClick={handleChangeLanguageToNL}
                    >
                      NL
                    </Typography.Text>
                    <Typography.Text
                      marginLeft={8}
                      color={
                        i18n.language === 'en'
                          ? theme.primaryColor
                          : theme.gray6
                      }
                      onClick={handleChangeLanguageToEN}
                    >
                      EN
                    </Typography.Text>
                    <Typography.Text
                      marginLeft={8}
                      color={
                        i18n.language === 'fr'
                          ? theme.primaryColor
                          : theme.gray6
                      }
                      onClick={handleChangeLanguageToFR}
                    >
                      FR
                    </Typography.Text>
                  </LanguageComponent>
                </LanguageBase>
              ),
              icon: <GlobalOutlined style={{ fontSize: 18 }} />,
              onClick: handleLanguageClick
            },
            ...(showOnboarding
              ? []
              : [
                  {
                    key: 'go_to_settings',
                    label: (
                      <Link
                        to={'/settings/profile'}
                        onClick={handleSettingClick}
                      >
                        {t('menu.go_to_settings')}
                      </Link>
                    ),
                    icon: <Settings style={{ fontSize: 20 }} />
                  }
                ]),
            {
              type: 'divider'
            },
            {
              key: 'logout',
              label: t('menu.log_out'),
              icon: (
                <Logout style={{ fontSize: 20, color: theme.primaryColor }} />
              ),
              onClick: handleLogout
            }
          ]
        }}
        onOpenChange={handleMenuOpenChange}
        open={isMenuOpen}
        trigger={['click']}
      >
        <Button
          type="default"
          shape="round"
          size="large"
          style={{
            width: 40,
            padding: 0
          }}
        >
          <Settings />
        </Button>
      </Dropdown>
    </Base>
  )
}

const Base = styled.div`
  display: flex;
  align-items: center;
`

const LanguageBase = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const LanguageComponent = styled('div')`
  display: flex;
  justify-content: flex-end;
`
