import { put, take, takeEvery, call, fork } from 'redux-saga/effects'
import * as actions from '../actions'
import { AppActionType } from '../actions'
import { appStateChannel } from '../channels/appStateChannel'
import { ActionType } from 'typesafe-actions'
import { clearApolloCache } from '@vivaldis/common'
import { EventChannel } from '@redux-saga/core'

function* init(_action: ActionType<typeof actions.init>) {
  yield fork(subscribeAppStateChange)
  yield take(actions.AppActionType.InitDataComplete)
  yield put(actions.initNavigation())
  yield take(actions.AppActionType.InitNavigationComplete)
  yield take(actions.AppActionType.InitUIComplete)
  yield put(actions.initComplete())
}

function* subscribeAppStateChange() {
  const channel: EventChannel<ActionType<typeof actions.stateChange>> =
    yield call(appStateChannel)
  try {
    while (true) {
      const payload: ActionType<typeof actions.stateChange> =
        yield take(channel)
      yield put(payload)
    }
  } finally {
    /* ignore an error */
  }
}

function* reset() {
  yield call(clearApolloCache)
}

export function* appSaga() {
  yield takeEvery(AppActionType.Init, init)
  yield takeEvery(AppActionType.Reset, reset)
}
