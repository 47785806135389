import { axios, type AxiosResponse } from '@vivaldis/common'
import { UserType } from '../typings/UserType'

export interface SignOutRequestData {
  authentication_token: string
  email: string
  type: UserType
}

export interface SignOutResponse {}

export const signOut = async (
  data: SignOutRequestData
): Promise<AxiosResponse<SignOutResponse> | undefined> => {
  let url: string
  let payload: any
  switch (data.type) {
    case UserType.Employee:
      url = '/employees/sign_out'
      payload = {
        employee_email: data.email,
        employee_token: data.authentication_token
      }
      break
    case UserType.User:
    case UserType.SuperUser:
      url = '/users/sign_out'
      payload = {
        user_email: data.email,
        user_token: data.authentication_token
      }
      break
  }

  try {
    const response = await axios().delete(url!, payload)
    return response
  } catch (error) {}
}
