import { useField } from 'formik'
import { FC, useCallback, useMemo } from 'react'
import { InputNumber, InputNumberProps } from '../../InputNumber'
import type { FormikFieldProps } from '../typings/FieldProps'
import { numberValueToVpassDecimalHoursValue } from './utils/numberValueToVpassDecimalHoursValue'

// VPASS format is: '00,00 u', '02,75 u', '03,00 u', '06,00 u', '23,99 u'

export type VPASSDecimalPickerProps = FormikFieldProps & InputNumberProps

export const VPASSDecimalPicker: FC<VPASSDecimalPickerProps> = ({
  name,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  value: _valueProp,
  ...restProps
}) => {
  const [field, , helpers] = useField(name)
  const { value, onBlur } = field
  const { setValue } = helpers

  const handleBlur = useCallback(
    (event: any) => {
      onBlur(event)
      onBlurProp?.(event)
    },
    [onBlur, onBlurProp]
  )

  const handleChange = useCallback(
    (value: string | number | null) => {
      const stringValue = numberValueToVpassDecimalHoursValue(value)
      setValue(stringValue)
    },
    [setValue]
  )

  const numberValue = useMemo(() => {
    return value
      ? Number(String(value).replace(',', '.').replace('u', '').trim())
      : undefined
  }, [value])

  return (
    <InputNumber
      key={name}
      name={name}
      min={0}
      max={23.99}
      value={numberValue}
      onBlur={handleBlur}
      onChange={handleChange}
      controls={false}
      decimalSeparator=","
      inputMode="decimal"
      precision={2}
      {...restProps}
    />
  )
}
