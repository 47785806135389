import { Typography as AntdTypography } from 'antd'
import type { TypographyProps as AntdTypographyProps } from 'antd/lib/typography/Typography'
import { makeBaseTextComponent } from './BaseText'
import type { BaseTextProps } from './BaseText'

export interface TypographyProps<T extends keyof JSX.IntrinsicElements>
  extends AntdTypographyProps<T>,
    BaseTextProps {}

export const Typography = <T extends keyof JSX.IntrinsicElements>({
  ...props
}: TypographyProps<T>) => {
  return <BaseAntdTypography {...props} />
}

const BaseAntdTypography = makeBaseTextComponent(AntdTypography)
