import { Typography, TextStylePreset, Image } from '@vivaldis/antd-ui'
import { doesEmployeeNeedBIC } from '@vivaldis/common'
import { endOfDay, isBefore, isValid, parseISO, startOfDay } from 'date-fns'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../../Divider'
import { EmployeeProfileQuery_employee } from '../../typings/EmployeeProfileQuery'
import { Information } from '../../../Information'
import { ExpiredInformationTag } from '../ExpiredInformationTag'
import { MissingInformationTag } from '../MissingInformationTag'
import { NotSetImageValue } from '../NotSetImageValue'
import { NotSetValue } from '../NotSetValue'

export interface DocumentsProps {
  employee: EmployeeProfileQuery_employee
}

export const Documents: FC<DocumentsProps> = ({ employee }) => {
  const [t] = useTranslation('web_ui')

  const { profile, ssn, workProfile } = employee

  // is true when null or undefined to show a short form (only id card fields)
  const eeaMember = profile?.nationalityCountry?.eeaMember ?? true

  const bankAccount = workProfile?.bankAccount
  const bic = workProfile?.bic

  // workPermit
  const workPermitBackPicture = workProfile?.workPermitBackPicture
  const workPermitFrontPicture = workProfile?.workPermitFrontPicture
  const workPermitExpiresOn = workProfile?.workPermitExpiresOn

  const isExpiredWorkPermitExpiresInformation = useMemo(() => {
    if (!workPermitExpiresOn) {
      return false
    }

    const expiresAtDate = parseISO(workPermitExpiresOn)
    if (!isValid(expiresAtDate)) {
      return true
    }

    return isBefore(endOfDay(expiresAtDate), startOfDay(new Date()))
  }, [workPermitExpiresOn])

  // residencePermit
  const residencePermitBackPicture = workProfile?.residencePermitBackPicture
  const residencePermitFrontPicture = workProfile?.residencePermitFrontPicture
  const residencePermitExpiresOn = workProfile?.residencePermitExpiresOn
  const residencePermitCode = workProfile?.residencePermitCode

  const isExpiredResidencePermitExpiresOnInformation = useMemo(() => {
    if (!residencePermitExpiresOn) {
      return false
    }

    const expiresAtDate = parseISO(residencePermitExpiresOn)
    if (!isValid(expiresAtDate)) {
      return true
    }

    return isBefore(endOfDay(expiresAtDate), startOfDay(new Date()))
  }, [residencePermitExpiresOn])

  // idCard
  const idCardBackPicture = profile?.idCardBackPicture
  const idCardExpiresOn = profile?.idCardExpiresOn
  const idCardFrontPicture = profile?.idCardFrontPicture
  const idCardNumber = profile?.idCardNumber

  const isExpiredIdCardExpiresOnInformation = useMemo(() => {
    if (!idCardExpiresOn) {
      return false
    }

    const expiresAtDate = parseISO(idCardExpiresOn)
    if (!isValid(expiresAtDate)) {
      return true
    }

    return isBefore(endOfDay(expiresAtDate), startOfDay(new Date()))
  }, [idCardExpiresOn])

  const isMissingSSNInformation = useMemo(() => {
    return !ssn
  }, [ssn])

  const isMissingBic = useMemo(() => {
    if (bic) {
      return false
    }
    return doesEmployeeNeedBIC(bankAccount)
  }, [bankAccount, bic])

  return (
    <>
      <Typography.Paragraph
        preset={TextStylePreset.Semibold16}
        marginBottom={16}
      >
        {t('employee_profile.documents')}
      </Typography.Paragraph>
      <Information>
        <Information.InformationField
          label={
            <>
              {t('employee_profile.ssn')}
              {isMissingSSNInformation ? <MissingInformationTag /> : null}
            </>
          }
        >
          {ssn ?? <NotSetValue />}
        </Information.InformationField>
        {eeaMember ? (
          <>
            <Information.InformationField
              label={
                <>
                  {t('employee_profile.id_card_number')}
                  {!idCardNumber ? <MissingInformationTag /> : null}
                </>
              }
            >
              {idCardNumber ?? <NotSetValue />}
            </Information.InformationField>
            <Information.InformationField
              label={
                <>
                  {t('employee_profile.id_card_expires_on')}
                  {!idCardExpiresOn ? <MissingInformationTag /> : null}
                  {isExpiredIdCardExpiresOnInformation ? (
                    <ExpiredInformationTag />
                  ) : null}
                </>
              }
            >
              {idCardExpiresOn ?? <NotSetValue />}
            </Information.InformationField>
            <Information.InformationField
              label={
                <>
                  {t('employee_profile.id_card_front_picture')}
                  {idCardFrontPicture ? (
                    <Image
                      width={250}
                      height={160}
                      src={String(idCardFrontPicture)}
                    />
                  ) : (
                    <div>
                      <div>
                        <MissingInformationTag marginLeft={0} />
                      </div>
                      <NotSetImageValue />
                    </div>
                  )}
                </>
              }
            >
              <>
                {t('employee_profile.id_card_back_picture')}
                {idCardFrontPicture ? (
                  <Image
                    width={250}
                    height={160}
                    src={String(idCardBackPicture)}
                  />
                ) : (
                  <div>
                    <div>
                      <MissingInformationTag marginLeft={0} />
                    </div>
                    <NotSetImageValue />
                  </div>
                )}
              </>
            </Information.InformationField>
          </>
        ) : (
          <>
            {/* workPermit */}
            <Information.InformationField
              label={
                <>
                  {t('employee_profile.work_permit_expires_on')}
                  {!workPermitExpiresOn ? <MissingInformationTag /> : null}
                  {isExpiredWorkPermitExpiresInformation ? (
                    <ExpiredInformationTag />
                  ) : null}
                </>
              }
            >
              {workPermitExpiresOn ?? <NotSetValue />}
            </Information.InformationField>
            <Information.InformationField
              label={
                <>
                  {t('employee_profile.work_permit_front_picture')}
                  {workPermitFrontPicture ? (
                    <Image
                      width={250}
                      height={160}
                      src={String(workPermitFrontPicture)}
                    />
                  ) : (
                    <div>
                      <div>
                        <MissingInformationTag marginLeft={0} />
                      </div>
                      <NotSetImageValue />
                    </div>
                  )}
                </>
              }
            >
              <>
                {t('employee_profile.work_permit_back_picture')}
                {workPermitBackPicture ? (
                  <Image
                    width={250}
                    height={160}
                    src={String(workPermitBackPicture)}
                  />
                ) : (
                  <div>
                    <div>
                      <MissingInformationTag marginLeft={0} />
                    </div>
                    <NotSetImageValue />
                  </div>
                )}
              </>
            </Information.InformationField>
            {/* residencePermit */}
            <Information.InformationField
              label={
                <>
                  {t('employee_profile.residence_permit_code')}
                  {!residencePermitCode ? <MissingInformationTag /> : null}
                </>
              }
            >
              {residencePermitCode ?? <NotSetValue />}
            </Information.InformationField>
            <Information.InformationField
              label={
                <>
                  {t('employee_profile.residence_permit_expires_on')}
                  {!residencePermitExpiresOn ? <MissingInformationTag /> : null}
                  {isExpiredResidencePermitExpiresOnInformation ? (
                    <ExpiredInformationTag />
                  ) : null}
                </>
              }
            >
              {residencePermitExpiresOn ?? <NotSetValue />}
            </Information.InformationField>
            <Information.InformationField
              label={
                <>
                  {t('employee_profile.residence_permit_front_picture')}
                  {residencePermitFrontPicture ? (
                    <Image
                      width={250}
                      height={160}
                      src={String(residencePermitFrontPicture)}
                    />
                  ) : (
                    <div>
                      <div>
                        <MissingInformationTag marginLeft={0} />
                      </div>
                      <NotSetValue />
                    </div>
                  )}
                </>
              }
            >
              <>
                {t('employee_profile.residence_permit_back_picture')}
                {residencePermitBackPicture ? (
                  <Image
                    width={250}
                    height={160}
                    src={String(residencePermitBackPicture)}
                  />
                ) : (
                  <div>
                    <div>
                      <MissingInformationTag marginLeft={0} />
                    </div>
                    <NotSetImageValue />
                  </div>
                )}
              </>
            </Information.InformationField>
          </>
        )}
        <Information.InformationField
          label={
            <>
              {t('employee_profile.bank_account')}
              {!bankAccount ? <MissingInformationTag /> : null}
            </>
          }
        >
          {bankAccount ?? <NotSetValue />}
        </Information.InformationField>
        {bic || isMissingBic ? (
          <Information.InformationField
            label={
              <>
                {t('employee_profile.bic')}
                {isMissingBic ? <MissingInformationTag /> : null}
              </>
            }
          >
            {bic ?? <NotSetValue />}
          </Information.InformationField>
        ) : null}
      </Information>
      <Divider sectionSeparator />
    </>
  )
}
