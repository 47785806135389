import { Routes, Route } from 'react-router-dom'
import { Suspense, FC } from 'react'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'
import { lazyOrReloadPage } from '../../utils/lazyOrReloadPage'

const InvoiceList = lazyOrReloadPage(
  () => import(/* webpackChunkName: "InvoiceList" */ './screens/InvoiceList')
)

export const InvoicesRoutes: FC = () => (
  <Suspense fallback={ProgressBarSuspenseFallback}>
    <Routes>
      <Route path="/" element={<InvoiceList />} />
    </Routes>
  </Suspense>
)
