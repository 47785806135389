import styled from '@emotion/styled'
import { Image, Button, List, Space } from '@vivaldis/antd-ui'
import { Edit, Delete } from '@vivaldis/icons'
import { useCertificateTypeOptions } from '@vivaldis/ui'
import { isPast } from 'date-fns'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { EmployeeProfileQuery_employee_employeeCertificates as EmployeeCertificate } from '../../../../typings/EmployeeProfileQuery'

export interface Props {
  employeeId: string
  employeeCertificate: EmployeeCertificate
  onDeleteEmployeeCertificate?: (
    certificate: EmployeeCertificate
  ) => Promise<void>
}

export const EmployeeCertificateListItem: FC<Props> = ({
  employeeId,
  employeeCertificate,
  onDeleteEmployeeCertificate
}) => {
  const { t } = useTranslation('web_ui')

  const certificateTypesOptions = useCertificateTypeOptions([
    employeeCertificate.certificateType
  ])

  const certificateName = useMemo(() => {
    return (
      certificateTypesOptions.find(
        certificateTypesOption =>
          certificateTypesOption.value === employeeCertificate.certificateType
      )?.label || employeeCertificate.certificateType
    )
  }, [certificateTypesOptions, employeeCertificate.certificateType])

  const detail = useMemo(() => {
    if (!employeeCertificate.expiresOn) {
      return t('employee_profile.no_expiration_date')
    }

    if (isPast(new Date(employeeCertificate.expiresOn))) {
      return (
        <ExpiredText>
          {t('employee_profile.certificate_expired_on', {
            date: employeeCertificate.expiresOn
          })}
        </ExpiredText>
      )
    }

    return t('employee_profile.certificate_expires_on', {
      date: employeeCertificate.expiresOn
    })
  }, [employeeCertificate.expiresOn, t])

  const handleDeleteCertificateClick = useCallback(() => {
    onDeleteEmployeeCertificate?.(employeeCertificate)
  }, [employeeCertificate, onDeleteEmployeeCertificate])

  return (
    <ListItem
      actions={[
        <Button
          to={`/employee/${employeeId}/edit-employee-certificate/${employeeCertificate.id}`}
          openInSideSheet
          type="link"
          size="small"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          icon={<Edit />}
        >
          {t('employee_profile.edit_certificate_button')}
        </Button>,
        <Button
          onClick={handleDeleteCertificateClick}
          type="link"
          size="small"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          icon={<Delete />}
          danger
        >
          {t('employee_profile.delete_certificate_button')}
        </Button>
      ]}
    >
      <List.Item.Meta
        avatar={
          <Space style={{ paddingLeft: 12 }}>
            <Image width={40} src={employeeCertificate.scannedDocumentUrl} />
            {employeeCertificate.scannedDocumentBackUrl ? (
              <Image
                width={40}
                src={employeeCertificate.scannedDocumentBackUrl}
              />
            ) : null}
          </Space>
        }
        title={<>{certificateName}</>}
        description={detail}
      />
    </ListItem>
  )
}

const ListItem = styled(List.Item)`
  background-color: ${props => props.theme.white};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 10px;
`

const ExpiredText = styled('span')`
  color: ${props => props.theme.red6};
`
