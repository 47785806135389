import {
  SetPasswordMutation,
  SetPasswordMutationVariables
} from './__generated__/SetPasswordMutation'
import { ApolloClient, MutationOptions, FetchResult, gql } from '@apollo/client'

const mutation = gql`
  mutation SetPasswordMutation($input: SetPasswordInput!) {
    setPassword(input: $input) {
      status
    }
  }
`

export function setPasswordMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<SetPasswordMutation, SetPasswordMutationVariables>,
    'mutation'
  >
): Promise<FetchResult<SetPasswordMutation>> {
  return apolloClient.mutate<SetPasswordMutation, SetPasswordMutationVariables>(
    {
      optimisticResponse: {
        setPassword: {
          __typename: 'SetPasswordPayload',
          status: 'ok'
        }
      },
      mutation,
      ...options
    }
  )
}
