export interface PaddingProps {
  paddingBottom?: number
  paddingTop?: number
  paddingLeft?: number
  paddingRight?: number
  paddingVertical?: number
  paddingHorizontal?: number
  padding?: number
}

export function padding(props: PaddingProps & { [key: string]: any }) {
  return `
    ${props.padding !== undefined ? `padding: ${props.padding}px;` : ''}
    ${
      props.paddingVertical !== undefined
        ? `
      padding-bottom: ${props.paddingVertical}px;
      padding-top: ${props.paddingVertical}px;`
        : ''
    }
    ${
      props.paddingHorizontal !== undefined
        ? `
      padding-left: ${props.paddingHorizontal}px;
      padding-right: ${props.paddingHorizontal}px;
      `
        : ''
    }
    ${
      props.paddingBottom !== undefined
        ? `padding-bottom: ${props.paddingBottom}px;`
        : ''
    }
    ${
      props.paddingTop !== undefined
        ? `padding-top: ${props.paddingTop}px;`
        : ''
    }
    ${
      props.paddingLeft !== undefined
        ? `padding-left: ${props.paddingLeft}px;`
        : ''
    }
    ${
      props.paddingRight !== undefined
        ? `padding-right: ${props.paddingRight}px;`
        : ''
    }
  `
}
