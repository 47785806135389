import { compareAsc, format, parseISO, startOfISOWeek } from 'date-fns'
import { FC, useMemo } from 'react'
import { Title } from './components/Title'
import { TableHeader } from './components/TableHeader'
import { Contract } from './typings/Contract'
import { HoursSummary } from './components/HoursSummary'
import { ScheduleItem } from './components/ScheduleItem'

interface ContractScheduleProps {
  contract: Contract
  onReSendWorkObstruction?: (workObstructionId: string) => any
}

const InternalContractSchedule: FC<ContractScheduleProps> = ({
  contract,
  onReSendWorkObstruction
}) => {
  const weekNumber = useMemo(() => {
    if (!contract) {
      return null
    }
    const firstDay = contract.workdays?.[0]?.startsAt
      ? new Date(contract.workdays?.[0]?.startsAt)
      : new Date()
    return format(startOfISOWeek(firstDay), 'I')
  }, [contract])

  const workdays = useMemo(() => {
    return [...contract.workdays].sort((a, b) =>
      compareAsc(parseISO(a.startsAt), parseISO(b.startsAt))
    )
  }, [contract.workdays])

  return (
    <>
      <Title />
      <TableHeader weekNumber={weekNumber} />
      {workdays.map(workday => (
        <ScheduleItem
          key={workday.startsAt}
          workday={workday}
          onReSendWorkObstruction={onReSendWorkObstruction}
        />
      ))}
      <HoursSummary contract={contract} />
    </>
  )
}

export const ContractSchedule = Object.assign(InternalContractSchedule, {
  ScheduleItem,
  TableHeader,
  Title
})
