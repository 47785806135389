import { useUser } from '@vivaldis/session'
import { useMemo } from 'react'

export const useUserHasConstructionWebAccess = (): boolean => {
  const user = useUser()
  return useMemo(() => {
    const permission = user?.permissions.find(
      permission => permission.companyId === user?.company?.id
    )

    const userHasHoursValidationPermissionForConstruct =
      permission?.construct4dPermissions?.permissions?.some(
        permission => permission === 'hours_validation'
      ) || false

    return userHasHoursValidationPermissionForConstruct
  }, [user?.company?.id, user?.permissions])
}
