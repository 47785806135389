import type { CompanyStatus } from './typings/CompanyStatus'

// see description: https://sealjobs.atlassian.net/wiki/spaces/SJ/pages/2100035589/Credit+related+warning+messages

const BLOCKED_DNB_STATUS = 'GEBLOKKEERD'

export type CompanyStatusInfo =
  | 'hasMoreThan21UnpaidInvoiceDays'
  | 'outOfVivaldisCreditLimit'
  | 'outOfDNBCreditLimit'
  | 'contractCreationBlocked'
  | null

export const getCompanyStatusInfo = (
  companyStatus: CompanyStatus | null
): CompanyStatusInfo => {
  if (!companyStatus) {
    return null
  }

  const {
    amountDue, // FACT_OPENSTAAND
    dnbCreditLimit, // DNB_KREDIETLIMIET
    dnbStatus, // DNB_STATUS
    unpaidInvoiceDays, // FACT_VERVALLEN_DAGEN
    vivCreditLimit // VIV_KREDIETLIMIET
  } = companyStatus

  // As a User I want to see a message when I am compeltely blocked from creating contracts.
  //   This is indicated by DNB_STATUS: GEBLOKKEERD
  if (dnbStatus.toLowerCase() === BLOCKED_DNB_STATUS.toLowerCase()) {
    return 'contractCreationBlocked'
  }

  // They have an unpaid invoice that is more than 21 days overtime
  //   FACT_VERVALLEN_DAGEN > 21 days
  const hasMoreThan21UnpaidInvoiceDays = unpaidInvoiceDays > 21

  if (hasMoreThan21UnpaidInvoiceDays) {
    return 'hasMoreThan21UnpaidInvoiceDays'
  }

  // < 1000 EUR from their credit limit
  //   (VIV_KREDIETLIMIET or DNB_KREDIETLIMIET) - FACT_OPENSTAAND < 1000
  if (vivCreditLimit) {
    if (vivCreditLimit - amountDue < 1000) {
      // out of Vivaldis credit limit
      return 'outOfVivaldisCreditLimit'
    }
  } else if (dnbCreditLimit - amountDue < 1000) {
    // out of DND credit limit
    return 'outOfDNBCreditLimit'
  }

  return null
}
