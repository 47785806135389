import styled from '@emotion/styled'
import {
  ChangeEvent,
  Children,
  ComponentType,
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react'
import { createElement } from '../../utils/createElement'
import { AccordionDetails } from '../AccordionDetails'
import { AccordionSummary } from '../AccordionSummary'
import { AccordionContext } from '../AccordionContext'
import { MarginProps, margin } from '../../styles/margin'
import { Collapse } from '../Collapse'

export interface AccordionComponentProps extends MarginProps {
  BaseComponent?: ReactNode | ComponentType<BaseProps>
  defaultExpanded?: boolean
  disabled?: boolean
  /**
   * If `true`, expands the accordion, otherwise collapse it.
   * Setting this prop enables control over the accordion.
   */
  expanded?: boolean
  /**
   * Callback fired when the expand/collapse state is changed.
   *
   * @param {object} event The event source of the callback.
   * @param {boolean} expanded The `expanded` state of the accordion.
   */
  onChange?: (event: ChangeEvent<unknown>, expanded: boolean) => void
  /**
   * If `true`, remove AccordionSummary content from screen when accordion is collapsed
   */
  removeCollapsedSummaryContent?: boolean
}

const AccordionComponent: FC<PropsWithChildren<AccordionComponentProps>> = ({
  BaseComponent = Base,
  children: childrenProp,
  defaultExpanded = false,
  disabled = false,
  expanded: expandedProp,
  onChange,
  removeCollapsedSummaryContent,
  ...restProps
}) => {
  const [summary, ...children] = Children.toArray(childrenProp)

  // internal state for `expanded`. Is used when no `expanded` prop was passed to component (uncontrolled component)
  const [expandedState, setExpandedState] = useState<boolean>(defaultExpanded)

  const expanded = expandedProp !== undefined ? expandedProp : expandedState

  const handleChange = useCallback(
    (event: any) => {
      setExpandedState(!expanded)

      if (onChange) {
        onChange(event, !expanded)
      }
    },
    [expanded, onChange, setExpandedState]
  )

  const contextValue = useMemo(
    () => ({ expanded, disabled, toggle: handleChange }),
    [expanded, disabled, handleChange]
  )

  return createElement(
    BaseComponent,
    {
      ...restProps
    },
    <>
      <AccordionContext.Provider value={contextValue}>
        {summary}
      </AccordionContext.Provider>
      {removeCollapsedSummaryContent && !expanded ? null : (
        <Collapse isOpen={expanded}>{children}</Collapse>
      )}
    </>
  )
}

interface BaseProps extends MarginProps {}

const Base = styled.div<BaseProps>`
  position: relative;
  border-radius: 6px;
  ${props => margin(props)};
  background-color: white;
`

export const Accordion = Object.assign(AccordionComponent, {
  AccordionDetails: AccordionDetails,
  AccordionSummary: AccordionSummary,
  Collapse: Collapse
})
