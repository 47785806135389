import { useEffect, useMemo } from 'react'
import { useProgressBar } from './useProgressBar'

export function useUpdateProgressBar(...steps: boolean[]) {
  const progressBar = useProgressBar()

  // if any of steps is loading -> we consider it as loading
  const isLoading = useMemo(() => {
    return steps.some(step => step)
  }, [steps])

  useEffect(() => {
    if (isLoading) {
      progressBar.addLoadingState()
      return () => {
        progressBar.removeLoadingState()
      }
    }
  }, [isLoading, progressBar])
}
