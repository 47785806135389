import { MapboxPlace } from '../api/mapbox'

export const getLocationLabel = (place: Partial<MapboxPlace>): string => {
  if (
    place.street ||
    place.streetNumber ||
    place.zip ||
    place.city ||
    place.country
  )
    return `${place.street}${
      place.streetNumber ? ` ${place.streetNumber}` : ''
    }, ${place.zip} ${place.city} ${place.country}`
  return ''
}
