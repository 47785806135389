import {
  RemoveFromTeamMutation,
  RemoveFromTeamMutationVariables
} from './__generated__/RemoveFromTeamMutation'
import { ApolloClient, MutationOptions, gql } from '@apollo/client'

const mutation = gql`
  mutation RemoveFromTeamMutation($input: RemoveFromTeamInput!) {
    removeFromTeam(input: $input) {
      status
    }
  }
`

export function removeFromTeamMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<RemoveFromTeamMutation, RemoveFromTeamMutationVariables>,
    'mutation'
  >
) {
  return apolloClient.mutate<
    RemoveFromTeamMutation,
    RemoveFromTeamMutationVariables
  >({
    mutation,
    ...options
  })
}
