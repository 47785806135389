import AxiosBase, { AxiosInstance, AxiosRequestConfig } from 'axios'

export type { AxiosResponse } from 'axios'
export { isAxiosError, AxiosError } from 'axios'

export class Axios {
  static authenticateInstance = Axios.createNewInstance()

  static config(baseURL: string) {
    AxiosBase.defaults.headers.common.Accept = 'application/json'
    Axios.authenticateInstance = Axios.createNewInstance({ baseURL })
  }

  static get(): AxiosInstance {
    return Axios.authenticateInstance
  }

  static createNewInstance(config?: AxiosRequestConfig): AxiosInstance {
    return AxiosBase.create(config)
  }
}

/**
 * Return authenticate axios instance that let perform authorized queries to seal server
 */
export function axios(): AxiosInstance {
  return Axios.get()
}
