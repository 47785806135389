import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const PaperSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7321 7.48424L14.7255 4.35244V5.65924C14.7255 6.66324 15.5425 7.48124 16.5455 7.48424H17.7321ZM13.8441 2.01172H7.58835H7.57135C5.10835 2.01172 3.05935 4.00972 3.00135 6.49072V17.1877C2.94635 19.7107 4.95435 21.8087 7.47635 21.8657H7.58535L15.6043 21.8647C18.1173 21.7627 20.0713 19.7127 20.0533 17.2037V8.03772C20.0533 7.84472 19.9783 7.65772 19.8443 7.51872L14.7793 2.24172C14.6373 2.09472 14.4423 2.01172 14.2383 2.01172H14.1068C14.0641 2.00418 14.0203 2.00024 13.9755 2.00024C13.9307 2.00024 13.8868 2.00418 13.8441 2.01172ZM13.2255 3.51172V5.65924C13.2255 7.48724 14.7135 8.97924 16.5435 8.98424H18.5533V17.2097C18.5653 18.9097 17.2433 20.2967 15.5743 20.3657H7.51035C5.81435 20.3277 4.46435 18.9167 4.50135 17.2037V6.50872C4.54035 4.85372 5.91635 3.51172 7.57335 3.51172H7.58535H13.2255ZM13.7887 16.1084H8.38867C7.97467 16.1084 7.63867 15.7724 7.63867 15.3584C7.63867 14.9444 7.97467 14.6084 8.38867 14.6084H13.7887C14.2027 14.6084 14.5387 14.9444 14.5387 15.3584C14.5387 15.7724 14.2027 16.1084 13.7887 16.1084ZM8.38782 12.3564H11.7438C12.1578 12.3564 12.4938 12.0204 12.4938 11.6064C12.4938 11.1924 12.1578 10.8564 11.7438 10.8564H8.38782C7.97382 10.8564 7.63782 11.1924 7.63782 11.6064C7.63782 12.0204 7.97382 12.3564 8.38782 12.3564Z"
    />
  </svg>
)

export const Paper = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={PaperSvg} {...props} ref={ref} />
)
