import { isValid, parse as parseDate, format } from 'date-fns'
import { useField } from 'formik'
import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import { Input, InputProps, InputRef } from '../../Input'
import type { FormikFieldProps } from '../typings/FieldProps'

// value from 4D is in format "## u ##" (e.g. "08 u 30", "12 u 00", "16 u 15", "20 u 45")
// we want to show value in format "##:##" (e.g. "08:30", "12:00", "16:15", "20:45"), but we want to keep value in format "## u ##" in formik. So we need to convert value from "##:##" to "## u ##" and vice versa
const INPUT_TIME_FORMAT = 'HH:mm'
const VPASS_TIME_FORMAT = "HH 'u' mm"

type SelectedSection = 'hours' | 'minutes' | 'all'

const getSelectedPart = (
  selectionStart: number | null,
  selectionEnd: number | null
): SelectedSection => {
  if (selectionStart === 0 && selectionEnd === 5) {
    return 'all'
  }
  if (selectionStart === null || selectionStart < 3) {
    return 'hours'
  }
  return 'minutes'
}

export type VPASSHoursPickerProps = FormikFieldProps & InputProps

// Behaviour should be similar to ths -> https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time
// User should be able to tab-complete inputs
// 9 → tab → becomes 09:00
// 09 → tab → becomes 09:00
// 09:0 → tab → becomes 09:00

export const VPASSHoursPicker: FC<VPASSHoursPickerProps> = ({
  name,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  value: _valueProp,
  ...restProps
}) => {
  const [field, , helpers] = useField(name)
  const { value, onBlur } = field
  const { setValue } = helpers

  const inputRef = useRef<InputRef>(null)

  const [prevSelectedPart, setPrevSelectedPart] = useState<
    'hours' | 'minutes' | null
  >(null)

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    event => {
      onBlur(event)
      onBlurProp?.(event)
      setPrevSelectedPart(null)
    },
    [onBlur, onBlurProp]
  )

  const setSelectedPartAndSelectInInput = useCallback(
    (selectedPart: SelectedSection) => {
      switch (selectedPart) {
        case 'all': {
          setTimeout(() => {
            inputRef.current?.input?.setSelectionRange(0, 5)
          }, 0)
          break
        }
        case 'hours': {
          inputRef.current?.input?.setSelectionRange(0, 2)
          setTimeout(() => {
            inputRef.current?.input?.setSelectionRange(0, 2)
          }, 0)
          break
        }
        case 'minutes': {
          inputRef.current?.input?.setSelectionRange(3, 5)
          setTimeout(() => {
            inputRef.current?.input?.setSelectionRange(3, 5)
          }, 0)
          break
        }
      }
    },
    []
  )

  const handleOnClick = useCallback<MouseEventHandler<HTMLInputElement>>(
    event => {
      const selectionStart = inputRef.current?.input?.selectionStart ?? 0
      const selectionEnd = inputRef.current?.input?.selectionEnd ?? 0

      const selectedPart = getSelectedPart(selectionStart, selectionEnd)

      setSelectedPartAndSelectInInput(selectedPart)
    },
    [setSelectedPartAndSelectInInput]
  )

  const formattedValue = useMemo(() => {
    // transform "## u ##" to 'HH:mm' format
    return String(value || '').replace(' u ', ':')
  }, [value])

  const setValueInInputTimeFormat = useCallback(
    (newValue: string) => {
      const date = parseDate(newValue, INPUT_TIME_FORMAT, new Date())
      if (isValid(date)) {
        return setValue(format(date, VPASS_TIME_FORMAT))
      }

      setValue('00 u 00')
    },
    [setValue]
  )

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      const inputValue = event.target.value ?? ''

      const date = parseDate(inputValue, 'HH:mm', new Date())
      if (isValid(date)) {
        setValueInInputTimeFormat(format(date, 'HH:mm'))
      } else {
        setValueInInputTimeFormat('00:00')
      }

      onChangeProp?.(event)
    },
    [onChangeProp, setValueInInputTimeFormat]
  )

  const updateValue = useCallback(
    (char: string) => {
      const selectionStart = inputRef.current?.input?.selectionStart ?? 0
      const selectionEnd = inputRef.current?.input?.selectionEnd ?? 0

      const activePart = getSelectedPart(selectionStart, selectionEnd)

      const [hours = '00', minutes = '00'] = formattedValue.split(':')

      // we need update value of active part + move cursor to the next part if needed

      if (activePart === 'hours' || activePart === 'all') {
        const lastHoursChar = hours?.[1] || '0'
        const newHours = `${lastHoursChar}${char}`

        // when prevSelectedPart === null it means that we are in the first iteration and need ti start from 0X
        const isInitiallySetHours = prevSelectedPart !== 'hours'

        setPrevSelectedPart('hours')

        if (isInitiallySetHours) {
          // case 1 -> 01:00 -> keep selection in hours
          // case 2 -> 02:00 -> keep selection in hours
          // -----
          // case 3 -> 03:00 -> move selection to minutes
          // ...
          // case 9 -> 09:00 -> move selection to minutes

          if (char === '0' || char === '1' || char === '2') {
            setValueInInputTimeFormat(`0${char}:${minutes}`)
            setSelectedPartAndSelectInInput('hours')
            return
          }

          setValueInInputTimeFormat(`0${char}:${minutes}`)
          setSelectedPartAndSelectInInput('minutes')
          return
        }

        const date = parseDate(`${newHours}:${minutes}`, 'HH:mm', new Date())
        if (isValid(date)) {
          setValueInInputTimeFormat(format(date, 'HH:mm'))
          setSelectedPartAndSelectInInput('minutes')
          return
        }
        setValueInInputTimeFormat(`0${char}:${minutes}`)
        setSelectedPartAndSelectInInput('minutes')
        return
      } else if (activePart === 'minutes') {
        const lastMinutesChar = minutes?.[1] || '0'
        const newMinutes = `${lastMinutesChar}${char}`
        const isInitiallySetMinutes = prevSelectedPart !== 'minutes'
        setPrevSelectedPart('minutes')
        if (isInitiallySetMinutes) {
          setValueInInputTimeFormat(`${hours}:0${char}`)
          setSelectedPartAndSelectInInput('minutes')
          return
        }

        const date = parseDate(`${hours}:${newMinutes}`, 'HH:mm', new Date())
        if (isValid(date)) {
          setValueInInputTimeFormat(format(date, 'HH:mm'))
          setSelectedPartAndSelectInInput('minutes')
          return
        } else {
          setValueInInputTimeFormat(`${hours}:0${char}`)
          setSelectedPartAndSelectInInput('minutes')
          return
        }
      } else {
        setPrevSelectedPart(null)
        setSelectedPartAndSelectInInput(activePart)
      }
    },
    [
      formattedValue,
      prevSelectedPart,
      setSelectedPartAndSelectInInput,
      setValueInInputTimeFormat
    ]
  )

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    event => {
      const selectionStart = inputRef.current?.input?.selectionStart ?? 0
      const selectionEnd = inputRef.current?.input?.selectionEnd ?? 0

      const activePart = getSelectedPart(selectionStart, selectionEnd)

      if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
        // prevent default to make sure that the next line "select all" while updating
        // the internal state at the same time.
        event.preventDefault()
        setSelectedPartAndSelectInInput('all')
        return
      }

      if (['Backspace', 'Delete'].includes(event.key)) {
        event.preventDefault()

        const activePart = getSelectedPart(selectionStart, selectionEnd)

        if (activePart === 'all') {
          setValueInInputTimeFormat('00:00')
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'hours') {
          setValueInInputTimeFormat(`00:${formattedValue.split(':')[1]}`)
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'minutes') {
          setValueInInputTimeFormat(`${formattedValue.split(':')[0]}:00`)
          setSelectedPartAndSelectInInput('minutes')
        }

        return
      }

      if (event.key === 'ArrowRight') {
        event.preventDefault()

        if (activePart === 'all') {
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'hours') {
          setSelectedPartAndSelectInInput('minutes')
        }
        if (activePart === 'minutes') {
          setSelectedPartAndSelectInInput('minutes')
        }

        return
      }

      if (event.key === 'ArrowLeft') {
        event.preventDefault()

        if (activePart === 'all') {
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'hours') {
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'minutes') {
          setSelectedPartAndSelectInInput('hours')
        }

        return
      }

      if (
        ['ArrowUp', 'ArrowDown', 'Home', 'End', 'PageUp', 'PageDown'].includes(
          event.key
        )
      ) {
        event.preventDefault()

        if (activePart === 'all') {
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'hours') {
          setSelectedPartAndSelectInInput('hours')
        }
        if (activePart === 'minutes') {
          setSelectedPartAndSelectInInput('hours')
        }

        return
      }

      if (event.key === 'Tab') {
        return
      }

      if (event.key) {
        const char = event.key || ''
        if (char.match(/\d/)) {
          updateValue(char)
        }
      }
      event.preventDefault()
    },
    [
      formattedValue,
      setSelectedPartAndSelectInInput,
      setValueInInputTimeFormat,
      updateValue
    ]
  )

  return (
    <Input
      ref={inputRef}
      name={name}
      value={formattedValue}
      onBlur={handleBlur}
      onClick={handleOnClick}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder="00:00"
      autoComplete="off"
      inputMode="numeric"
      {...restProps}
    />
  )
}
