import { Typography, TextStylePreset } from '@vivaldis/antd-ui'
import { useMaritalStateOptions, useTaxCodeOptions } from '@vivaldis/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../../Divider'
import { EmployeeProfileQuery_employee } from '../../typings/EmployeeProfileQuery'
import { Information } from '../../../Information'
import { NotSetValue } from '../NotSetValue'

export interface EmployeeProfileProps {
  employee: EmployeeProfileQuery_employee
}

export const WageProfile: FC<EmployeeProfileProps> = ({ employee }) => {
  const [t] = useTranslation('web_ui')

  const { workProfile } = employee

  const taxCode = workProfile?.taxCode
  const childrenNumber = workProfile?.childrenNumber
  const childrenWithDisabilitiesNumber =
    workProfile?.childrenWithDisabilitiesNumber
  const dependentPersonsNumber = workProfile?.dependentPersonsNumber
  const dependentPersonsWithDisabilitiesNumber =
    workProfile?.dependentPersonsWithDisabilitiesNumber
  const maritalDate = workProfile?.maritalDate
  const maritalState = workProfile?.maritalState
  const involuntaryPartTime = workProfile?.involuntaryPartTime

  const taxCodeOptions = useTaxCodeOptions([taxCode || ''])

  const maritalStateOptions = useMaritalStateOptions({
    maritalState: maritalState
  })

  return (
    <>
      <Typography.Paragraph
        preset={TextStylePreset.Semibold16}
        marginBottom={16}
      >
        {t('employee_profile.wage_profile')}
      </Typography.Paragraph>
      <Information>
        <Information.InformationField label={t('employee_profile.tax_code')}>
          {taxCodeOptions?.[0]?.label || <NotSetValue />}
        </Information.InformationField>
        <Information.InformationField
          label={t('employee_profile.children_number')}
        >
          {childrenNumber ?? <NotSetValue />}
        </Information.InformationField>
        <Information.InformationField
          label={t('employee_profile.children_with_disabilities_number')}
        >
          {childrenWithDisabilitiesNumber ?? <NotSetValue />}
        </Information.InformationField>
        <Information.InformationField
          label={t('employee_profile.dependent_persons_number')}
        >
          {dependentPersonsNumber ?? <NotSetValue />}
        </Information.InformationField>
        <Information.InformationField
          label={t(
            'employee_profile.dependent_persons_with_disabilities_number'
          )}
        >
          {dependentPersonsWithDisabilitiesNumber ?? <NotSetValue />}
        </Information.InformationField>
        <Information.InformationField
          label={t('employee_profile.marital_date')}
        >
          {maritalDate ?? <NotSetValue />}
        </Information.InformationField>
        <Information.InformationField
          label={t('employee_profile.marital_state')}
        >
          {maritalState ? maritalStateOptions?.[0]?.label : <NotSetValue />}
        </Information.InformationField>
        <Information.InformationField
          label={t('employee_profile.involuntary_part_time')}
        >
          {involuntaryPartTime === undefined ? (
            <NotSetValue />
          ) : involuntaryPartTime ? (
            t('yes', { ns: 'translation' })
          ) : (
            t('no', { ns: 'translation' })
          )}
        </Information.InformationField>
      </Information>
      <Divider sectionSeparator />
    </>
  )
}
