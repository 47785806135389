import { Typography, TextStylePreset, Row } from '@vivaldis/antd-ui'
import { useGenderOptions, useTheme } from '@vivaldis/ui'
import {
  differenceInYears,
  formatDistance,
  isFuture,
  isValid,
  parseISO
} from 'date-fns'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCountryName, useDateFormat } from '@vivaldis/common'
import { Divider } from '../../../Divider'
import { EmployeeProfileQuery_employee } from '../../typings/EmployeeProfileQuery'
import { Information } from '../../../Information'
import { MissingInformationTag } from '../MissingInformationTag'
import { NotSetValue } from '../NotSetValue'
import { RemainingWorkingHours } from './components/RemainingWorkingHours'

export interface EmployeeProfileProps {
  employee: EmployeeProfileQuery_employee
  onRefreshRemainingHours?: () => Promise<void>
}

export const GeneralInformation: FC<EmployeeProfileProps> = ({
  employee,
  onRefreshRemainingHours
}) => {
  const [t] = useTranslation('web_ui')

  const theme = useTheme()

  const { gender, preferredLanguage } = employee

  const employeeName = useMemo(
    () => `${employee.firstName} ${employee.lastName}`,
    [employee.firstName, employee.lastName]
  )

  const genderOptions = useGenderOptions()

  const genderText = useMemo(() => {
    return genderOptions.find(genderOption => genderOption.value === gender)
      ?.label
  }, [gender, genderOptions])

  const nationalityName = employee.profile?.nationalityCountry?.nationality
  const countryOfBirthName = useCountryName(
    employee.profile?.countryOfBirth || undefined
  )
  const placeOfBirth = employee.profile?.placeOfBirth
  const birthdate = employee.profile?.birthdate

  const format = useDateFormat('d MMMM yyyy')

  const dateOfBirth = useMemo(() => {
    return birthdate ? format(new Date(birthdate)) : ''
  }, [birthdate, format])

  const age = useMemo(() => {
    return birthdate ? differenceInYears(new Date(), new Date(birthdate)) : ''
  }, [birthdate])

  const lastSeenAt = useMemo(() => {
    if (!employee.lastSeenAt) {
      return '-'
    }
    const lastSeenAtParsed = parseISO(String(employee.lastSeenAt))

    return isValid(lastSeenAtParsed)
      ? formatDistance(lastSeenAtParsed, new Date(), {
          addSuffix: true
        })
      : '-'
  }, [employee.lastSeenAt])

  const isFlexiForbidden = useMemo(() => {
    if (!employee.workProfile?.flexiNotAllowedUntil) {
      return false
    }

    const flexiNotAllowedUntilDate = parseISO(
      employee?.workProfile?.flexiNotAllowedUntil
    )
    if (!isValid(flexiNotAllowedUntilDate)) {
      return false
    }

    // if future = true -> return true (means flexi is not allowed)
    // if future = false -> return false (means flexi is allowed)

    return isFuture(flexiNotAllowedUntilDate)
  }, [employee.workProfile?.flexiNotAllowedUntil])

  const flexiForbiddenUntil = useMemo(() => {
    if (!employee.workProfile?.flexiNotAllowedUntil) {
      return false
    }

    const flexiNotAllowedUntilDate = parseISO(
      employee?.workProfile?.flexiNotAllowedUntil
    )
    if (!isValid(flexiNotAllowedUntilDate)) {
      return false
    }

    return format(flexiNotAllowedUntilDate)
  }, [employee.workProfile?.flexiNotAllowedUntil, format])

  return (
    <>
      <Information>
        <Information.InformationField label={t('employee_profile.name')}>
          {employeeName}
        </Information.InformationField>
        <Information.InformationField label={t('employee_profile.gender')}>
          {genderText ?? <MissingInformationTag />}
        </Information.InformationField>

        <Information.InformationField
          label={
            <>
              {t('employee_profile.nationality')}
              {nationalityName ? null : <MissingInformationTag />}
            </>
          }
        >
          {nationalityName ?? <NotSetValue />}
        </Information.InformationField>

        <Information.InformationField
          label={
            <>
              {t('employee_profile.country_of_birth')}
              {countryOfBirthName ? null : <MissingInformationTag />}
            </>
          }
        >
          {countryOfBirthName ?? <NotSetValue />}
        </Information.InformationField>

        <Information.InformationField label={t('employee_profile.birthplace')}>
          {placeOfBirth ?? <NotSetValue />}
        </Information.InformationField>

        <Information.InformationField label={t('employee_profile.birthdate')}>
          {birthdate ? (
            <>
              {dateOfBirth}{' '}
              <Typography.Text
                preset={TextStylePreset.Regular14}
                color={theme.gray7}
              >
                ({age})
              </Typography.Text>
            </>
          ) : (
            <MissingInformationTag />
          )}
        </Information.InformationField>

        {isFlexiForbidden ? (
          <Information.InformationField
            label={t('employee_profile.flexi_work.label')}
          >
            <Row justify="end">
              {t('employee_profile.flexi_work.forbidden')}
            </Row>

            <Row justify="end">
              {t('employee_profile.flexi_work.forbidden_until', {
                date: flexiForbiddenUntil
              })}
            </Row>
          </Information.InformationField>
        ) : null}

        <Information.InformationField label={t('employee_profile.student')}>
          {employee.isStudent
            ? t('yes', { ns: 'translation' })
            : t('no', { ns: 'translation' })}
        </Information.InformationField>

        <RemainingWorkingHours
          employee={employee}
          onRefreshRemainingHours={onRefreshRemainingHours}
        />

        <Information.InformationField
          label={t('employee_profile.preferred_language')}
        >
          {preferredLanguage ?? <NotSetValue />}
        </Information.InformationField>

        <Information.InformationField
          label={t('employee_profile.last_seen_at')}
        >
          {lastSeenAt ?? <NotSetValue />}
        </Information.InformationField>
      </Information>
      <Divider sectionSeparator />
    </>
  )
}
