import {
  createContext,
  useRef,
  useMemo,
  useLayoutEffect,
  useState,
  memo,
  ReactNode,
  FC,
  PropsWithChildren
} from 'react'
import {
  useQuery,
  QueryHookOptions,
  QueryResult,
  NetworkStatus
} from '@apollo/client'
import { employeeQuery } from '../queries/employeeQuery'
import {
  EmployeeQuery,
  EmployeeQueryVariables
} from '../queries/__generated__/EmployeeQuery'
import { UserType } from '../typings/UserType'
import { useLocale } from '@vivaldis/common'
import { useSession } from '../hooks/useSession'
import { useHasSession } from '../hooks/useHasSession'

export interface EmployeeQueryContextValue
  extends QueryResult<EmployeeQuery, EmployeeQueryVariables> {}

export const EmployeeQueryContext = createContext<
  EmployeeQueryContextValue | undefined
>(undefined)

interface Props {
  children?: ReactNode
  type: UserType
}

const MemoizedEmployeeQueryProvider: FC<PropsWithChildren<Props>> = ({
  children,
  type
}) => {
  const hasSession = useHasSession()
  const session = useSession()
  const locale = useLocale()

  const options = useMemo(
    (): QueryHookOptions<EmployeeQuery, EmployeeQueryVariables> => ({
      fetchPolicy: 'cache-and-network',
      skip: !hasSession || type !== UserType.Employee, // TODO: use skipToken
      variables: {
        locale
      }
    }),
    [hasSession, type, locale]
  )

  const result = useQuery<EmployeeQuery, EmployeeQueryVariables>(
    employeeQuery,
    options
  )
  const refetch = useRef(result.refetch)
  refetch.current = result.refetch

  const [isValid, setIsValid] = useState(hasSession)

  const hadSession = useRef(hasSession)
  useLayoutEffect(() => {
    if (
      hasSession &&
      !hadSession.current &&
      session.type === UserType.Employee
    ) {
      setIsValid(false)
      refetch.current()
    }
    hadSession.current = hasSession
  }, [hasSession, session.type])

  useLayoutEffect(() => {
    if (
      hasSession &&
      session.type === UserType.Employee &&
      result.networkStatus === NetworkStatus.ready
    ) {
      if (!isValid) {
        setIsValid(true)
      }
    } else if (!hasSession) {
      if (isValid) {
        setIsValid(false)
      }
    }
  }, [hasSession, result.networkStatus, session.type, isValid])

  return (
    <EmployeeQueryContext.Provider value={isValid ? result : undefined}>
      {children}
    </EmployeeQueryContext.Provider>
  )
}

export const EmployeeQueryProvider = memo(MemoizedEmployeeQueryProvider)
