import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const ActivitySvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9673 3.5C19.3213 3.5 18.7953 4.025 18.7953 4.672C18.7953 5.318 19.3213 5.845 19.9673 5.845C20.6133 5.845 21.1393 5.318 21.1393 4.672C21.1393 4.025 20.6133 3.5 19.9673 3.5ZM19.9673 7.345C18.4943 7.345 17.2953 6.146 17.2953 4.672C17.2953 3.198 18.4943 2 19.9673 2C21.4413 2 22.6393 3.198 22.6393 4.672C22.6393 6.146 21.4413 7.345 19.9673 7.345ZM7.21629 16.0029C7.05629 16.0029 6.89529 15.9519 6.75929 15.8479C6.43129 15.5949 6.36929 15.1239 6.62229 14.7959L9.61529 10.9059C9.73729 10.7469 9.91829 10.6439 10.1163 10.6189C10.3183 10.5929 10.5163 10.6489 10.6733 10.7739L13.4933 12.9889L15.9603 9.80587C16.2143 9.47687 16.6843 9.41587 17.0123 9.67187C17.3403 9.92587 17.4003 10.3969 17.1463 10.7239L14.2163 14.5039C14.0943 14.6619 13.9143 14.7649 13.7163 14.7889C13.5163 14.8159 13.3183 14.7579 13.1603 14.6349L10.3423 12.4209L7.81129 15.7099C7.66329 15.9019 7.44129 16.0029 7.21629 16.0029ZM7.62888 22.703H16.2329C19.5999 22.703 21.8619 20.338 21.8619 16.818V9.77904C21.8619 9.36504 21.5259 9.02904 21.1119 9.02904C20.6979 9.02904 20.3619 9.36504 20.3619 9.77904V16.818C20.3619 19.482 18.7409 21.203 16.2329 21.203H7.62888C5.08188 21.203 3.49988 19.523 3.49988 16.818V8.73604C3.49988 6.06604 5.12088 4.34204 7.62888 4.34204H14.8969C15.3109 4.34204 15.6469 4.00604 15.6469 3.59204C15.6469 3.17804 15.3109 2.84204 14.8969 2.84204H7.62888C4.26188 2.84204 1.99988 5.21104 1.99988 8.73604V16.818C1.99988 20.338 4.26188 22.703 7.62888 22.703Z"
    />
  </svg>
)

export const Activity = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={ActivitySvg} {...props} ref={ref} />
)
