interface Address {
  street: string | null
  streetNumber: string | null
  zip: string | null
  cityName: string | null
  countryName: string | null
}

export function constructAddressLine(employee: Address) {
  const { street, streetNumber, zip, cityName, countryName } = employee

  let address = ''
  if (street) {
    address = street
  }
  if (street && streetNumber) {
    address += ' '
  }
  if (streetNumber) {
    address += streetNumber
  }
  if (zip || cityName) {
    address += ','
  }
  if (zip) {
    address += ` ${zip}`
  }
  if (cityName) {
    address += ` ${cityName}`
  }
  if (countryName) {
    address += `, ${countryName}`
  }

  return address
}
