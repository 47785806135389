import {
  getStoreKeyName,
  storeKeyNameFromField,
  valueToObjectRepresentation
} from '@apollo/client/utilities'
import type { FieldPolicy, Reference } from '@apollo/client'
import type {
  TRelayEdge,
  TRelayPageInfo
} from '@apollo/client/utilities/policies/pagination'
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common'
import { uniqBy } from 'lodash'

type RelayConnection<TNode> = Readonly<{
  edges: TRelayEdge<TNode>[]
  pageInfo: TRelayPageInfo
}>

interface RelayStylePaginationConfigurationOptions {
  excludeKeyArgs?: string[]
  uniqByValueIteratee?: (
    edge: TRelayEdge<any>,
    readField: ReadFieldFunction
  ) => unknown
}

export const relayStylePagination = <TNode = Reference>({
  excludeKeyArgs = ['after', 'before', 'first', 'last'], // exclude "paginated" variables
  uniqByValueIteratee = (edge, readField) => {
    // 'node.id'
    return readField('id', readField('node', edge))
  }
}: RelayStylePaginationConfigurationOptions): FieldPolicy<
  RelayConnection<TNode>
> => {
  return {
    // in this function we exclude "paginated" variables (`after`, `before`, `first`, `last`) from cache key, but preserve all other used variables
    keyArgs: (args, context) => {
      if (context.field) {
        const hasDirectives = context.field.directives?.length
        const hasExcludedVariables = excludeKeyArgs.some(
          excludeKeyArg => args && Object.hasOwn(args, excludeKeyArg)
        )
        //  - when query use directive (like @connection) we can use default apollo cache key
        //  - when query doesn't have "excluded" variables we can use default apollo cache key
        if (hasDirectives || !hasExcludedVariables) {
          return storeKeyNameFromField(context.field, context.variables)
        }

        const argObj: Record<string, unknown> = {}
        context.field.arguments?.forEach(({ name, value }) => {
          return valueToObjectRepresentation(
            argObj,
            name,
            value,
            context.variables
          )
        })

        const newArgObj: Record<string, unknown> = {}
        Object.keys(argObj).map(key => {
          if (!excludeKeyArgs.includes(key)) {
            newArgObj[key] = argObj[key]
          }
        })
        return getStoreKeyName(context.field.name.value, newArgObj)
      }

      return getStoreKeyName(context.fieldName, args || context.variables)
    },

    merge(existing, incoming, { args, readField }) {
      // when no previous cashed data exist
      if (!existing) {
        return incoming
      }

      // when we fetch first page we only need new data
      if (!args?.after) {
        return incoming
      }

      const existingEdges = existing.edges || []
      const incomingEdges = incoming.edges || []

      const uniqEdges = uniqBy([...existingEdges, ...incomingEdges], edge =>
        uniqByValueIteratee(edge, readField)
      )

      return {
        ...existing,
        ...incoming,
        // in some cases server return the same items (with the same id) on different pages, so we need filter-out duplicates
        edges: uniqEdges
      }
    }
  }
}
