import { gql } from '@apollo/client'
import { countryFragment } from './CountryFragment'

export const employeeProfileFragment = gql`
  ${countryFragment}

  fragment EmployeeProfileFragment on EmployeeProfile {
    birthdate
    buildingNumber
    city
    country {
      ...CountryFragment
    }
    countryOfBirth {
      ...CountryFragment
    }
    idCardBackPicture
    idCardExpiresOn
    idCardFrontPicture
    idCardNumber
    nationalityCountry {
      ...CountryFragment
    }
    picture
    placeOfBirth
    street
    streetNumber
    zip
  }
`
