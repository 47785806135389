import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import {
  ConfirmAgreementsMutation,
  ConfirmAgreementsMutationVariables
} from './__generated__/ConfirmAgreementsMutation'

const mutation = gql`
  mutation ConfirmAgreementsMutation($input: ConfirmAgreementsInput!) {
    confirmAgreements(input: $input) {
      status
    }
  }
`

export function confirmAgreementsMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<
      ConfirmAgreementsMutation,
      ConfirmAgreementsMutationVariables
    >,
    'mutation'
  >
) {
  return apolloClient.mutate<
    ConfirmAgreementsMutation,
    ConfirmAgreementsMutationVariables
  >({
    mutation,
    ...options
  })
}
