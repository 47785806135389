import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import {
  CreateWorkPostSheetMutation,
  CreateWorkPostSheetMutationVariables
} from './__generated__/CreateWorkPostSheetMutation'
import { workPostSheetFragment } from '../fragments/WorkPostSheetFragment'

const mutation = gql`
  ${workPostSheetFragment}

  mutation CreateWorkPostSheetMutation($input: CreateWorkPostSheetInput!) {
    createWorkPostSheet(input: $input) {
      workPostSheet {
        ...WorkPostSheetFragment
        position {
          id
          workPostSheet {
            ...WorkPostSheetFragment
          }
        }
      }
    }
  }
`

export function createWorkPostSheetMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<
      CreateWorkPostSheetMutation,
      CreateWorkPostSheetMutationVariables
    >,
    'mutation'
  >
) {
  return apolloClient.mutate<
    CreateWorkPostSheetMutation,
    CreateWorkPostSheetMutationVariables
  >({
    mutation,
    ...options
  })
}
