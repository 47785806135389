import i18next from 'i18next'
import { call, select, takeEvery } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import {
  initComplete as initCompleteSessionAction,
  SessionActionType,
  SessionData,
  sessionSelector
} from '@vivaldis/session'
import { getApolloClient } from '@vivaldis/common'
import { tracking } from '@vivaldis/tracking'
import { updateUserMutation } from '../graphql/mutations/updateUserMutation'

function* updatePreferredLanguageOnSessionInitComplete(
  action: ActionType<typeof initCompleteSessionAction>
) {
  const locale = i18next.language
  const session: SessionData = yield select(sessionSelector)
  const user = action.payload.user

  if (
    session.authentication_token &&
    session.email &&
    user &&
    user.firstName &&
    user.lastName &&
    user.preferredLanguage !== locale
  ) {
    try {
      const apolloClient = getApolloClient()
      yield call(updateUserMutation, apolloClient, {
        variables: {
          input: {
            user: {
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              preferredLanguage: locale
            }
          }
        }
      })
    } catch (e) {
      yield call(tracking.error, e)
    }
  }
}

export function* preferredLanguage() {
  yield takeEvery(
    SessionActionType.InitComplete,
    updatePreferredLanguageOnSessionInitComplete
  )
}
