import { css } from '@emotion/react'

export const Shadows = {
  Subtle: css`
    box-shadow: 0 2px 15px 0px rgba(0, 0, 0, 0.1);
  `,
  SubtleBottom: css`
    box-shadow: 0 2px 15px -2px rgba(0, 0, 0, 0.1);
  `,
  Medium: css`
    box-shadow: 0 4px 15px 0px rgba(0, 0, 0, 0.15);
  `,
  Hard: css`
    box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.2);
  `
}
