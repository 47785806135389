import { FC } from 'react'
import { Form } from '@vivaldis/antd-ui'
import { PositionFormStep, useStep } from '../../providers/StepProvider'
import { CompanyFunctionCode } from '../../typings/CompanyFunctionCode'
import { Offer } from '../../typings/Offer'
import { FormContent } from './components/FormContent'
import { ActionBar } from './components/ActionBar'
import {
  FormProvider,
  PositionFormProviderProps
} from './providers/FormProvider'
import type { PositionFormStep2Values } from './typings/PositionFormStep2Values'

export interface Step2Props {
  // FormProvider props
  onSubmit: PositionFormProviderProps['onSubmit']
  onPrevStep?: (values: PositionFormStep2Values) => void
  initialValues: PositionFormProviderProps['initialValues']
  // form props
  certificateTypes: string[]
  companyFunctionCodes: CompanyFunctionCode[]
  companyOffers: Offer[]
  functionCodeId?: string
  viewWPSUrl: string
  addWPSUrl: string
  editWPSUrl: string
}

export const Step2: FC<Step2Props> = ({
  onSubmit,
  onPrevStep,
  initialValues,
  certificateTypes,
  companyOffers,
  companyFunctionCodes,
  functionCodeId,
  viewWPSUrl,
  addWPSUrl,
  editWPSUrl
}) => {
  const { step } = useStep()

  if (step !== PositionFormStep.Step2Safety) {
    return null
  }

  return (
    <FormProvider
      onSubmit={onSubmit}
      initialValues={initialValues}
      companyFunctionCodes={companyFunctionCodes}
      companyOffers={companyOffers}
      functionCodeId={functionCodeId}
    >
      <Form name="position-form-step-2" id="position-form-step-2">
        <FormContent
          certificateTypes={certificateTypes}
          viewWPSUrl={viewWPSUrl}
          addWPSUrl={addWPSUrl}
          editWPSUrl={editWPSUrl}
        />
        <ActionBar onPrevStep={onPrevStep} />
      </Form>
    </FormProvider>
  )
}
