import { gql } from '@apollo/client'
import { getApolloClient } from '@vivaldis/common'
import { userQuery } from '@vivaldis/session'
import {
  AcceptTermsAndConditionsMutation,
  AcceptTermsAndConditionsMutationVariables
} from './__generated__/AcceptTermsAndConditionsMutation'

export const mutation = gql`
  mutation AcceptTermsAndConditionsMutation(
    $input: AcceptTermsAndConditionsInput!
  ) {
    acceptTermsAndConditions(input: $input) {
      status
    }
  }
`

export const acceptTermsAndConditionsMutation = () => {
  const apolloClient = getApolloClient()
  return apolloClient.mutate<
    AcceptTermsAndConditionsMutation,
    AcceptTermsAndConditionsMutationVariables
  >({
    mutation,
    variables: { input: {} },
    refetchQueries: [userQuery],
    awaitRefetchQueries: true
  })
}
