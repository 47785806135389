import styled from '@emotion/styled'
import React, { FC, PropsWithChildren } from 'react'

export interface InformationProps {}

export const Information: FC<PropsWithChildren<InformationProps>> = ({
  children
}) => {
  children = React.Children.toArray(children) // remove null from the list for correct count
  const childrenWithShowDividerProps = React.Children.map(
    children,
    (child, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          showDivider: React.Children.count(children) > index + 1
        } as any)
      }
      return child
    }
  )
  return <Base>{childrenWithShowDividerProps}</Base>
}

const Base = styled('div')`
  display: flex;
  flex-direction: column;
`
