import { ApolloClient, gql, MutationOptions } from '@apollo/client'
import { employeeCertificateFragment } from '../fragments/EmployeeCertificateFragment'
import {
  UpdateEmployeeCertificateMutation,
  UpdateEmployeeCertificateMutationVariables
} from './__generated__/UpdateEmployeeCertificateMutation'

const mutation = gql`
  ${employeeCertificateFragment}

  mutation UpdateEmployeeCertificateMutation(
    $input: UpdateEmployeeCertificateInput!
  ) {
    updateEmployeeCertificate(input: $input) {
      certificate {
        ...EmployeeCertificateFragment
      }
    }
  }
`

export function updateEmployeeCertificateMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<
      UpdateEmployeeCertificateMutation,
      UpdateEmployeeCertificateMutationVariables
    >,
    'mutation'
  >
) {
  return apolloClient.mutate<
    UpdateEmployeeCertificateMutation,
    UpdateEmployeeCertificateMutationVariables
  >({
    mutation,
    ...options
  })
}
