import { useRef, useLayoutEffect, FC, PropsWithChildren } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sessionSelector, hasSessionSelector } from '../selectors'
import { UserType } from '../typings/UserType'
import { init, initComplete } from '../actions'
import { useEmployee } from '../hooks/useEmployee'
import { useUser } from '../hooks/useUser'

interface Props {
  actionCableURL: string
}

export const ReduxProvider: FC<PropsWithChildren<Props>> = ({
  children,
  actionCableURL
}) => {
  const hasSession = useSelector(hasSessionSelector)
  const session = useSelector(sessionSelector)
  const dispatch = useDispatch()
  const employee = useEmployee()
  const user = useUser()

  const privateInitDispatched = useRef(false)
  useLayoutEffect(() => {
    if (hasSession && !privateInitDispatched.current) {
      privateInitDispatched.current = true
      dispatch(init())
    }
  }, [actionCableURL, dispatch, hasSession])

  const initDispatched = useRef(false)
  useLayoutEffect(() => {
    if (hasSession && !initDispatched.current) {
      if (session.type === UserType.Employee && employee) {
        initDispatched.current = true
        dispatch(initComplete({ employee }))
      } else if (session.type === UserType.User && user) {
        initDispatched.current = true
        dispatch(
          initComplete({
            user: user
          })
        )
      } else if (session.type === UserType.SuperUser && user) {
        initDispatched.current = true
        dispatch(
          initComplete({
            user: user
          })
        )
      }
    } else if (!hasSession) {
      initDispatched.current = false
    }
  }, [actionCableURL, dispatch, employee, hasSession, user, session.type])

  return <>{children}</>
}
