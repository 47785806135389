import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const PromoLabelWithWhiteBorderIconSvg: FC<
  AntdCustomIconComponentProps
> = props => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50613 2.72071C8.99698 2.50359 9.56114 2.43384 10.0771 2.5751C10.1698 2.60046 10.261 2.63074 10.3505 2.66579C10.8486 2.86105 11.2591 3.2542 12.3244 4.31951L13.4468 5.44194C14.4384 6.43353 15.1421 7.13864 15.6175 7.74318C16.0841 8.33658 16.2776 8.769 16.3166 9.2015C16.3311 9.36316 16.3311 9.52575 16.3166 9.68741C16.2776 10.1199 16.0841 10.5523 15.6175 11.1457C15.1421 11.7502 14.4384 12.4553 13.4468 13.4469C12.4552 14.4385 11.7501 15.1422 11.1456 15.6176C10.5522 16.0842 10.1198 16.2777 9.68731 16.3167C9.52564 16.3312 9.36298 16.3312 9.20131 16.3167C8.76881 16.2777 8.33648 16.0842 7.74307 15.6176C7.13852 15.1422 6.43341 14.4385 5.44182 13.4469L4.31939 12.3245C3.25408 11.2592 2.86093 10.8487 2.66567 10.3506C2.63062 10.2612 2.60034 10.1699 2.57498 10.0772C2.43372 9.56125 2.50347 8.99708 2.72059 7.50625C2.87492 6.44662 2.98459 5.69895 3.13046 5.12213C3.27303 4.5584 3.43723 4.21542 3.66775 3.94835C3.75426 3.84813 3.84801 3.75439 3.94823 3.66788C4.21529 3.43735 4.55827 3.27315 5.122 3.13059C5.69883 2.98471 6.44649 2.87504 7.50613 2.72071Z"
      fill="white"
    />
    <path
      d="M9.16648 9.16668C9.78013 8.55303 9.78013 7.55811 9.16648 6.94446C8.55283 6.33081 7.55791 6.33081 6.94426 6.94446C6.33061 7.55811 6.33061 8.55303 6.94426 9.16668C7.55791 9.78033 8.55283 9.78033 9.16648 9.16668Z"
      fill="white"
    />
    <path
      d="M16.3166 9.68741C16.3311 9.52575 16.3311 9.36316 16.3166 9.2015C16.2776 8.769 16.0841 8.33658 15.6175 7.74318C15.1421 7.13864 14.4384 6.43353 13.4468 5.44194L12.3244 4.31951C11.2591 3.2542 10.8486 2.86105 10.3505 2.66579C10.261 2.63074 10.1698 2.60046 10.0771 2.5751C9.56114 2.43384 8.99698 2.50359 7.50613 2.72071C6.44649 2.87504 5.69883 2.98471 5.122 3.13059C4.55827 3.27315 4.21529 3.43735 3.94823 3.66788C3.84801 3.75439 3.75426 3.84813 3.66775 3.94835C3.43723 4.21542 3.27303 4.5584 3.13046 5.12213C2.98459 5.69895 2.87492 6.44662 2.72059 7.50625C2.50347 8.99708 2.43372 9.56125 2.57498 10.0772C2.60034 10.1699 2.63062 10.2612 2.66567 10.3506C2.86093 10.8487 3.25408 11.2592 4.31939 12.3245L5.44182 13.4469C6.43341 14.4385 7.13852 15.1422 7.74307 15.6176C8.33648 16.0842 8.76881 16.2777 9.20131 16.3167C9.36298 16.3312 9.52564 16.3312 9.68731 16.3167M16.3166 9.68741L16.9391 9.7435M16.3166 9.68741C16.2776 10.1199 16.0841 10.5523 15.6175 11.1457C15.1421 11.7502 14.4384 12.4553 13.4468 13.4469C12.4552 14.4385 11.7501 15.1422 11.1456 15.6176C10.5522 16.0842 10.1198 16.2777 9.68731 16.3167M9.68731 16.3167L9.74339 16.9392M9.16648 6.94446C9.78013 7.55811 9.78013 8.55303 9.16648 9.16668C8.55283 9.78033 7.55791 9.78033 6.94426 9.16668C6.33061 8.55303 6.33061 7.55811 6.94426 6.94446C7.55791 6.33081 8.55283 6.33081 9.16648 6.94446Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M7.92153 3.93395C9.1639 3.75301 9.63404 3.69488 10.064 3.81261C10.1413 3.83374 10.2172 3.85897 10.2918 3.88818C10.7069 4.05089 11.049 4.37852 11.9367 5.26628L12.8721 6.20164C13.6984 7.02796 14.2848 7.61555 14.681 8.11934C15.0698 8.61384 15.2311 8.97418 15.2636 9.3346C15.2757 9.46932 15.2757 9.60481 15.2636 9.73953C15.2311 10.0999 15.0698 10.4603 14.681 10.9547C14.2848 11.4586 13.6984 12.0461 12.8721 12.8724C12.0458 13.6988 11.4582 14.2852 10.9544 14.6813C10.4599 15.0702 10.0996 15.2315 9.73918 15.2639C9.60446 15.2761 9.4689 15.2761 9.33418 15.2639C8.97376 15.2315 8.61349 15.0702 8.11898 14.6813C7.61519 14.2852 7.0276 13.6988 6.20127 12.8724L5.26592 11.9371C4.37815 11.0493 4.05053 10.7072 3.88781 10.2922C3.8586 10.2177 3.83337 10.1416 3.81224 10.0644C3.69452 9.63439 3.75265 9.16425 3.93358 7.92189C4.06219 7.03887 4.15358 6.41582 4.27514 5.93513C4.39394 5.46535 4.53078 5.17954 4.72288 4.95698C4.79497 4.87346 4.8731 4.79534 4.95661 4.72325C5.17917 4.53114 5.46498 4.39431 5.93476 4.27551C6.41544 4.15395 7.0385 4.06255 7.92153 3.93395Z"
      fill="#CCF0E7"
    />
    <path
      d="M9.30516 9.30559C9.81653 8.79421 9.81653 7.96511 9.30516 7.45374C8.79378 6.94236 7.96468 6.94236 7.4533 7.45374C6.94193 7.96511 6.94193 8.79421 7.4533 9.30559C7.96468 9.81696 8.79378 9.81697 9.30516 9.30559Z"
      fill="#CCF0E7"
    />
    <path
      d="M15.2636 9.73953C15.2757 9.60481 15.2757 9.46932 15.2636 9.3346C15.2311 8.97418 15.0698 8.61384 14.681 8.11934C14.2848 7.61555 13.6984 7.02796 12.8721 6.20164L11.9367 5.26628C11.049 4.37852 10.7069 4.05089 10.2918 3.88818C10.2172 3.85897 10.1413 3.83374 10.064 3.81261C9.63404 3.69488 9.1639 3.75301 7.92153 3.93395C7.0385 4.06255 6.41544 4.15395 5.93476 4.27551C5.46498 4.39431 5.17917 4.53114 4.95661 4.72325C4.8731 4.79534 4.79497 4.87346 4.72288 4.95698C4.53078 5.17954 4.39394 5.46535 4.27514 5.93513C4.15358 6.41582 4.06219 7.03887 3.93358 7.92189C3.75265 9.16425 3.69452 9.63439 3.81224 10.0644C3.83337 10.1416 3.8586 10.2177 3.88781 10.2922C4.05053 10.7072 4.37815 11.0493 5.26592 11.9371L6.20127 12.8724C7.0276 13.6988 7.61519 14.2852 8.11898 14.6813C8.61349 15.0702 8.97376 15.2315 9.33418 15.2639C9.4689 15.2761 9.60446 15.2761 9.73918 15.2639M15.2636 9.73953L15.7823 9.78627M15.2636 9.73953C15.2311 10.0999 15.0698 10.4603 14.681 10.9547C14.2848 11.4586 13.6984 12.0461 12.8721 12.8724C12.0458 13.6988 11.4582 14.2852 10.9544 14.6813C10.4599 15.0702 10.0996 15.2315 9.73918 15.2639M9.73918 15.2639L9.78592 15.7827M9.30516 7.45374C9.81653 7.96511 9.81653 8.79421 9.30516 9.30559C8.79378 9.81697 7.96468 9.81696 7.4533 9.30559C6.94193 8.79421 6.94193 7.96511 7.4533 7.45374C7.96468 6.94236 8.79378 6.94236 9.30516 7.45374Z"
      stroke="#00B287"
      strokeWidth="1.5"
    />
  </svg>
)

export const PromoLabelWithWhiteBorderIcon = forwardRef<
  HTMLSpanElement,
  AntdIconProps
>((props, ref) => (
  <Icon component={PromoLabelWithWhiteBorderIconSvg} {...props} ref={ref} />
))
