import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Caption, Body } from '../Typography'
import { FontWeight } from '../styles/font'
import { Container } from './components/Container'

// Note: This component cannot use any contexts because it's a root level component

export const UnknownErrorPage: FC = () => {
  const { t } = useTranslation('web_ui')
  return (
    <Container>
      <Text
        color="white"
        fontSize={40}
        fontWeight={FontWeight.bold}
        letterSpacing={1.8}
        lineHeight={51}
        children={t('unknown_error_page.title')}
      />
      <Text
        color="white"
        fontSize={32}
        fontWeight={FontWeight.regular}
        letterSpacing={0.8}
        lineHeight={41}
        children={t('unknown_error_page.description')}
      />
      <Caption
        color="white"
        children={t('unknown_error_page.more_info')}
        marginBottom={12}
      />
      <a
        style={{
          minWidth: 140,
          minHeight: 60,
          background: 'white',
          borderRadius: 12,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        href={'/'}
        onClick={() => {
          window.location.replace('/')
        }}
      >
        <Body color="#00B287" fontSize={16}>
          {t('unknown_error_page.action_button')}
        </Body>
      </a>
    </Container>
  )
}
