import styled from '@emotion/styled'
import { margin, MarginProps } from '../../../styles/margin'

export interface ContainerProps extends MarginProps {}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  ${props => margin(props)};
`
