import { useUser } from '@vivaldis/session'
import { useMemo } from 'react'
import { useUserHasConstructionWebAccess } from './useUserHasConstructionWebAccess'

export const useUserHasConstructionExtendContractAccess = (): boolean => {
  const user = useUser()
  const userHasConstructionWebAccess = useUserHasConstructionWebAccess()
  return useMemo(() => {
    if (!userHasConstructionWebAccess) return false

    const permission = user?.permissions.find(
      permission => permission.companyId === user?.company?.id
    )

    const userHasHoursValidationPermissionForConstruct =
      permission?.construct4dPermissions?.permissions?.some(
        permission => permission === 'extending_contracts'
      ) || false

    return userHasHoursValidationPermissionForConstruct
  }, [user?.company?.id, user?.permissions, userHasConstructionWebAccess])
}
