import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'

const routerLinkProps = ['to', 'replace', 'state']

export type LinkProps = RouterLinkProps

export const Link = styled(RouterLink, {
  shouldForwardProp: prop =>
    isPropValid(prop) || routerLinkProps?.includes(String(prop))
})``
