import { FC, useEffect } from 'react'
import { useNavigate, useInRouterContext } from 'react-router-dom'
import { useSetMainNavigate } from '../hooks/useSetMainNavigate'
import { MainNavigateGlobalProvider } from '../utils/MainNavigateGlobalProvider'

export const CaptureMainNavigate: FC = () => {
  const inRouterContext = useInRouterContext()
  return inRouterContext ? <CaptureMainNavigateComponent /> : null
}

const CaptureMainNavigateComponent: FC = () => {
  const navigate = useNavigate()
  const setMainNavigate = useSetMainNavigate()
  useEffect(() => {
    setMainNavigate(navigate)
    MainNavigateGlobalProvider.setNavigate(navigate)
    return () => {
      // setMainNavigate(undefined)
      MainNavigateGlobalProvider.setNavigate(undefined)
    }
  }, [navigate, setMainNavigate])

  return null
}
