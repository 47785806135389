import certificate_typeTranslationsEN from '../components/CertificateType/i18n/en.json'
import certificate_typeTranslationsFR from '../components/CertificateType/i18n/fr.json'
import certificate_typeTranslationsNL from '../components/CertificateType/i18n/nl.json'

import genderTranslationsEN from '../components/Gender/i18n/en.json'
import genderTranslationsFR from '../components/Gender/i18n/fr.json'
import genderTranslationsNL from '../components/Gender/i18n/nl.json'

import insurance_providerTranslationsEN from '../components/InsuranceProvider/i18n/en.json'
import insurance_providerTranslationsFR from '../components/InsuranceProvider/i18n/fr.json'
import insurance_providerTranslationsNL from '../components/InsuranceProvider/i18n/nl.json'

import licence_typeTranslationsEN from '../components/LicenceType/i18n/en.json'
import licence_typeTranslationsFR from '../components/LicenceType/i18n/fr.json'
import licence_typeTranslationsNL from '../components/LicenceType/i18n/nl.json'

import marital_stateTranslationsEN from '../components/MaritalState/i18n/en.json'
import marital_stateTranslationsFR from '../components/MaritalState/i18n/fr.json'
import marital_stateTranslationsNL from '../components/MaritalState/i18n/nl.json'

import tax_codeTranslationsEN from '../components/TaxCode/i18n/en.json'
import tax_codeTranslationsFR from '../components/TaxCode/i18n/fr.json'
import tax_codeTranslationsNL from '../components/TaxCode/i18n/nl.json'

import promptTranslationsEN from '../prompt/i18n/en.json'
import promptTranslationsFR from '../prompt/i18n/fr.json'
import promptTranslationsNL from '../prompt/i18n/nl.json'

export const resources = {
  en: {
    certificate_type: certificate_typeTranslationsEN,
    gender: genderTranslationsEN,
    insurance_provider: insurance_providerTranslationsEN,
    licence_type: licence_typeTranslationsEN,
    marital_state: marital_stateTranslationsEN,
    tax_code: tax_codeTranslationsEN,
    prompt: promptTranslationsEN
  },
  fr: {
    certificate_type: certificate_typeTranslationsFR,
    gender: genderTranslationsFR,
    insurance_provider: insurance_providerTranslationsFR,
    licence_type: licence_typeTranslationsFR,
    marital_state: marital_stateTranslationsFR,
    tax_code: tax_codeTranslationsFR,
    prompt: promptTranslationsFR
  },
  nl: {
    certificate_type: certificate_typeTranslationsNL,
    gender: genderTranslationsNL,
    insurance_provider: insurance_providerTranslationsNL,
    licence_type: licence_typeTranslationsNL,
    marital_state: marital_stateTranslationsNL,
    tax_code: tax_codeTranslationsNL,
    prompt: promptTranslationsNL
  }
} as const
