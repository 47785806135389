import { Formik, FormikContextType, useFormikContext } from 'formik'
import { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  AttachmentInput,
  ViewWorkPostSheetQuery_workPostSheet,
  WorkPostSheetItemTypesEnum
} from '../types'
import { wpsToWPSFormValues } from '../utils/wpsToWPSFormValues'

interface WorkPostSheetFormItem {
  checked: boolean
  name: WorkPostSheetItemTypesEnum
  comment?: string | null | Date
}

export interface WorkPostSheetFormValues {
  uploadOwnWPS: boolean
  newPdfCommentAttachment: AttachmentInput | undefined
  existingPdfUrl: string | undefined
  tasksToPerform: string
  requiredQualifications: string
  workEquipment: string
  priorInstructions: string
  foreseeableTraining: string
  workstationLocation: string
  maternityProtectionMeasuresRequired: boolean
  items: WorkPostSheetFormItem[]
}

export function useForm(): FormikContextType<WorkPostSheetFormValues> {
  return useFormikContext()
}

export interface EmployeeFormProviderProps {
  onSubmit: (values: WorkPostSheetFormValues) => Promise<any>
  workPostSheet?: ViewWorkPostSheetQuery_workPostSheet | null
}

export const FormProvider: FC<PropsWithChildren<EmployeeFormProviderProps>> = ({
  onSubmit,
  workPostSheet,
  children
}) => {
  const [t] = useTranslation('web_ui')

  const formInitialValues: WorkPostSheetFormValues = useMemo(
    () => ({
      uploadOwnWPS: false,
      newPdfCommentAttachment: {
        fileName: '',
        base64: ''
      },
      existingPdfUrl: undefined,
      tasksToPerform: '',
      requiredQualifications: '',
      workEquipment: '',
      priorInstructions: '',
      foreseeableTraining: '',
      workstationLocation: '',
      maternityProtectionMeasuresRequired: false,
      items: Object.values(WorkPostSheetItemTypesEnum).map(name => ({
        name,
        comment: '',
        checked: false
      })),
      ...(workPostSheet ? wpsToWPSFormValues(workPostSheet) : {})
    }),
    [workPostSheet]
  )

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        uploadOwnWPS: Yup.boolean()
          .label(t('work_post_sheet_form.uploadOwnWPS.label'))
          .required(),
        newPdfCommentAttachment: Yup.object()
          .label(t('work_post_sheet_form.newPdfCommentAttachment.label'))
          .when('uploadOwnWPS', {
            is: (uploadOwnWPS?: boolean | null) => !!uploadOwnWPS,
            then: schema =>
              schema.shape({
                fileName: Yup.string()
                  .label(
                    t('work_post_sheet_form.newPdfCommentAttachment.label')
                  )
                  .required(),
                base64: Yup.string()
                  .label(
                    t('work_post_sheet_form.newPdfCommentAttachment.label')
                  )
                  .required()
              }),
            otherwise: schema => schema.nullable().notRequired()
          }),
        tasksToPerform: Yup.string()
          .label(t('work_post_sheet_form.tasksToPerform.label'))
          .when('uploadOwnWPS', {
            is: (uploadOwnWPS?: boolean | null) => !uploadOwnWPS,
            then: schema => schema.required(),
            otherwise: schema => schema.nullable().notRequired()
          }),
        requiredQualifications: Yup.string()
          .label(t('work_post_sheet_form.requiredQualifications.label'))
          .when('uploadOwnWPS', {
            is: (uploadOwnWPS?: boolean | null) => !uploadOwnWPS,
            then: schema => schema.required(),
            otherwise: schema => schema.nullable().notRequired()
          }),
        workEquipment: Yup.string()
          .label(t('work_post_sheet_form.workEquipment.label'))
          .when('uploadOwnWPS', {
            is: (uploadOwnWPS?: boolean | null) => !uploadOwnWPS,
            then: schema => schema.required(),
            otherwise: schema => schema.nullable().notRequired()
          }),
        priorInstructions: Yup.string()
          .label(t('work_post_sheet_form.priorInstructions.label'))
          .when('uploadOwnWPS', {
            is: (uploadOwnWPS?: boolean | null) => !uploadOwnWPS,
            then: schema => schema.required(),
            otherwise: schema => schema.nullable().notRequired()
          }),
        foreseeableTraining: Yup.string()
          .label(t('work_post_sheet_form.foreseeableTraining.label'))
          .when('uploadOwnWPS', {
            is: (uploadOwnWPS?: boolean | null) => !uploadOwnWPS,
            then: schema => schema.required(),
            otherwise: schema => schema.nullable().notRequired()
          }),
        workstationLocation: Yup.string()
          .label(t('work_post_sheet_form.workstationLocation.label'))
          .when('uploadOwnWPS', {
            is: (uploadOwnWPS?: boolean | null) => !uploadOwnWPS,
            then: schema => schema.required(),
            otherwise: schema => schema.nullable().notRequired()
          })
      }),
    [t]
  )

  const handleSubmit = useCallback(
    async (values: WorkPostSheetFormValues) => {
      await onSubmit(values)
    },
    [onSubmit]
  )

  return (
    <Formik<WorkPostSheetFormValues>
      initialValues={formInitialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}
