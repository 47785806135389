import { useLocale } from '@vivaldis/common'
import type { PickerFocusEventHandler } from 'rc-picker/lib/interface'
import { forwardRef, KeyboardEventHandler, useCallback } from 'react'
import { useField } from 'formik'
import { DatePicker as AntdDatePicker, DatePickerRef } from '../../DatePicker'
import type { DatePickerProps as AntdDatePickerProps } from '../../DatePicker'
import generateConfig from '../../DatePicker/config/dateFnsGenerateConfig'
import type { FormikFieldProps } from '../typings/FieldProps'

export type DatePickerProps = FormikFieldProps & AntdDatePickerProps

export const DatePicker = forwardRef<DatePickerRef, DatePickerProps>(
  (
    {
      name,
      onChange: onChangeProp,
      onBlur: onBlurProp,
      value: _valueProp, // we don't need this value, because we are going to use value from formik
      format = 'dd-MM-yyyy',
      ...restProps
    },
    ref
  ) => {
    const locale = useLocale()
    const [field, , helpers] = useField(name)
    const { value } = field
    const { setValue, setTouched } = helpers

    const handleChange = useCallback(
      (value: Date | null, dateString: string | string[]) => {
        setValue(value)
        onChangeProp?.(value, dateString)
      },
      [onChangeProp, setValue]
    )

    const handleBlur = useCallback<PickerFocusEventHandler>(
      (event, info) => {
        setTouched(true)
        onBlurProp?.(event, info)
      },
      [onBlurProp, setTouched]
    )

    // we want to select value when user types in manually, without extra click on calendar to confirm date
    const handleOnKeyDown = useCallback<KeyboardEventHandler<HTMLElement>>(
      event => {
        const dateString = (event as any)?.target?.value
        // when it's a valid value we need to call handleChange
        if (dateString && typeof format === 'string') {
          const dateValue = generateConfig.locale.parse(locale, dateString, [
            format
          ])
          if (dateValue) {
            handleChange(dateValue, dateString)
          }
        }
      },
      [format, handleChange, locale]
    )

    return (
      <AntdDatePicker
        ref={ref}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        format={format}
        onKeyDown={handleOnKeyDown}
        {...restProps}
      />
    )
  }
)
