import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const ArrowRightSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1687 5.17211C12.8765 5.46563 12.8775 5.9405 13.171 6.23276L17.9345 10.9758H4.75L4.64823 10.9826C4.28215 11.0323 4 11.3461 4 11.7258C4 12.14 4.33579 12.4758 4.75 12.4758H17.9332L13.171 17.2189L13.0982 17.3028C12.8797 17.596 12.9031 18.0127 13.1688 18.2795C13.4611 18.573 13.9359 18.574 14.2294 18.2817L20.2671 12.269C20.3146 12.2238 20.3562 12.1723 20.3906 12.116C20.4305 12.0507 20.4595 11.9806 20.4776 11.9084C20.5397 11.6606 20.4737 11.3873 20.2794 11.1938L14.2294 5.16982L14.1451 5.09739C13.851 4.88016 13.4344 4.90527 13.1687 5.17211Z"
    />
  </svg>
)

export const ArrowRight = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={ArrowRightSvg} {...props} ref={ref} />
)
