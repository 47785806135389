import { FC } from 'react'
import { Image as AntdImage } from 'antd'
import type { ImageProps as AntdImageProps } from 'antd'

import NoImageAvailableFallback from './assets/no-image-available.svg'

export interface ImageProps extends AntdImageProps {}

export const Image: FC<ImageProps> = props => {
  return <AntdImage fallback={NoImageAvailableFallback} {...props} />
}
