import { constructAddressLine, Country } from '@vivaldis/common'
import { Col, Form, FormItemProps, Row } from 'antd'
import { debounce } from 'lodash'
import {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Empty } from '../Empty'
import { Input } from '../Input'
import { Select } from '../Select'
import { findPlaces, MapboxPlace } from './api/mapbox'
import { SelectSuffixIcon } from './components/SelectSuffixIcon'
import { getLocationLabel } from './utils/getLocationLabel'

import { prepareInputForQueryAccordingToMapboxApiLimits } from './utils/prepareInputForQueryAccordingToMapboxApiLimits'

const findCountryIdByCountryName = (name: string, countries: Country[]) => {
  const country = countries.find(country => {
    return (
      country.nameNl === name ||
      country.nameEn === name ||
      country.nameFr === name
    )
  })

  return country ? country.id : ''
}

const formatMapboxOptions = (places: MapboxPlace[]): LocationOptionType[] => {
  return places.map(place => ({
    label: getLocationLabel(place),
    value: place
  }))
}

export interface LocationOptionType {
  label: string
  value: MapboxPlace
}

export interface LocationPickerValue {
  city: string
  country: string
  countryId: string
  street: string
  streetNumber: string
  zip: string
  latitude: number
  longitude: number
}

export interface LocationPickerProps extends FormItemProps {
  value: LocationPickerValue
  onChange: (value: LocationPickerValue) => void
  countries: Country[]
  placeholder?: string | ReactNode
  error?: string | ReactNode
  onBlur?: () => void
  onFocus?: () => void
  disabled?: boolean
}

export const LocationPicker: FC<LocationPickerProps> = ({
  countries,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  error,
  disabled,
  ...restProps
}) => {
  const [t] = useTranslation('antd_ui')

  const address = useMemo(
    () =>
      constructAddressLine({
        street: value.street || null,
        streetNumber: value.streetNumber || null,
        zip: value.zip || null,
        cityName: value.city || null,
        countryName: value.country || null
      }),
    [value.city, value.country, value.street, value.streetNumber, value.zip]
  )

  const [inputValue, setInputValue] = useState(address)
  const [searchInputValue, setSearchInputValue] = useState('')
  const [isLoadingSearchOptions, setIsLoadingSearchOptions] = useState(false)
  const [searchOptions, setSearchOptions] = useState<LocationOptionType[]>([])

  // update input to address value
  useEffect(() => {
    setInputValue(address)
  }, [address])

  const fetchMapboxOptions = useCallback(
    async (inputValue: string) => {
      setIsLoadingSearchOptions(true)
      const mapboxOptions = await findPlaces(
        prepareInputForQueryAccordingToMapboxApiLimits(inputValue || address)
      ).then(formatMapboxOptions)
      setIsLoadingSearchOptions(false)
      return mapboxOptions
    },
    [address]
  )

  const triggerOnChange = useCallback(
    (mapboxValue: MapboxPlace) => {
      const countryId =
        findCountryIdByCountryName(mapboxValue.country, countries) ||
        value.countryId

      const newValue = {
        ...mapboxValue,
        countryId
      }

      onChange(newValue)
    },
    [countries, value.countryId, onChange]
  )

  // set initial latitude and longitude if they are not set, but address is set
  useEffect(() => {
    const { latitude, longitude, streetNumber, street, city } = value
    if (!latitude && !longitude && city && street && streetNumber) {
      fetchMapboxOptions(inputValue).then(places => {
        if (places && places.length) {
          triggerOnChange(places[0].value)
        }
        setSearchOptions(places)
      })
    }
  }, [fetchMapboxOptions, inputValue, triggerOnChange, value])

  const debouncedHandleNewStreetNumber = debounce(async newStreetNumber => {
    if (newStreetNumber) {
      if (value && value.street) {
        // create a new location name with previous value and new streetNumber for search purposes
        const newLocationName = getLocationLabel({
          ...value,
          streetNumber: newStreetNumber
        })
        // and find the matching mapbox place(s)
        const foundPlaces = await findPlaces(
          prepareInputForQueryAccordingToMapboxApiLimits(newLocationName)
        )
        // note (1/2): even when the streetnumber has no matching places, the location label should and will still result in valid locations, albeit locations without streetNumber values
        // if we find any matching mapbox place(s)
        if (foundPlaces && foundPlaces.length > 0) {
          // format the closest match to a proper location object
          const closestMatch = formatMapboxOptions([foundPlaces[0]])[0]
          // propagate the found location to parent
          // note (2/2): if it's a location without a streetNumber/different streetNumber
          // then we know the streetNumber input should be parsed separately to the parent.
          // it might be invalid, it might be valid and just not existing on the mapbox api, it might even be half valid/half invalid (eg '<valid housenumber> <invalid words>') we can't know for sure
          // so we have to parse it and deal with it in the parent
          triggerOnChange({
            ...closestMatch.value,
            streetNumber: newStreetNumber
          })
        }
      }
    }
  }, 300)

  const handleStreetNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newStreetNumber = event.target.value.trim()
      triggerOnChange({
        id: value.countryId,
        name: address,
        city: value.city,
        country: value.country,
        street: value.street,
        streetNumber: newStreetNumber,
        zip: value.zip,
        latitude: value.latitude,
        longitude: value.longitude
      })
      if (newStreetNumber) {
        debouncedHandleNewStreetNumber(newStreetNumber)
      }
    },
    [
      triggerOnChange,
      value.countryId,
      value.city,
      value.country,
      value.street,
      value.zip,
      value.latitude,
      value.longitude,
      address,
      debouncedHandleNewStreetNumber
    ]
  )

  const handleStreetNumberBlur = useCallback(() => {
    onBlur?.()
  }, [onBlur])

  const handleStreetNumberFocus = useCallback(() => {
    onFocus?.()
  }, [onFocus])

  const handleSearch = useCallback(
    async (searchText: string) => {
      setSearchInputValue(searchText)
      const foundSearchOptions = await fetchMapboxOptions(searchText)
      setSearchOptions(foundSearchOptions || [])
    },
    [fetchMapboxOptions]
  )

  const handleSelectFocus = useCallback(async () => {
    setSearchInputValue(inputValue)
    const foundSearchOptions = await fetchMapboxOptions(inputValue)
    setSearchOptions(foundSearchOptions || [])
    onFocus?.()
  }, [fetchMapboxOptions, inputValue, onFocus])

  const handleSelectBlur = useCallback(() => {
    onBlur?.()
  }, [onBlur])

  const handleSelect = useCallback(
    (value: any, option: any) => {
      triggerOnChange(option.mapboxValue)
    },
    [triggerOnChange]
  )

  const handleNoOptionsMessage = useMemo(() => {
    if (!inputValue) {
      return t('location_picker.start_type')
    }
    if (isLoadingSearchOptions && !searchOptions.length) {
      return t('location_picker.loading')
    }
    return t('location_picker.no_options')
  }, [inputValue, isLoadingSearchOptions, searchOptions.length, t])

  const notFoundContent = useMemo(() => {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={handleNoOptionsMessage}
      />
    )
  }, [handleNoOptionsMessage])

  const selectOptions = useMemo(() => {
    return searchOptions.map(searchOption => ({
      label: searchOption.label,
      value: searchOption.label,
      mapboxValue: searchOption.value
    }))
  }, [searchOptions])

  return (
    <Form.Item
      validateStatus={error ? 'error' : ''}
      help={error}
      {...restProps}
    >
      <Row gutter={8}>
        <Col span={20}>
          <Select
            showSearch
            value={inputValue}
            searchValue={searchInputValue}
            placeholder={placeholder}
            defaultActiveFirstOption={false}
            // showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onFocus={handleSelectFocus}
            onBlur={handleSelectBlur}
            notFoundContent={notFoundContent}
            suffixIcon={<SelectSuffixIcon loading={isLoadingSearchOptions} />}
            loading={isLoadingSearchOptions}
            style={{
              minWidth: '100%'
            }}
            options={selectOptions}
            disabled={disabled}
          />
        </Col>
        <Col span={4}>
          <Input
            value={value.streetNumber}
            onChange={handleStreetNumberChange}
            onBlur={handleStreetNumberBlur}
            onFocus={handleStreetNumberFocus}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Form.Item>
  )
}
