import { call, put, takeEvery, select, spawn } from 'redux-saga/effects'
import * as actions from '../actions'
import { SessionActionType } from '../actions'
import { ActionType } from 'typesafe-actions'
import { axios, type AxiosResponse } from '@vivaldis/common'
import { signIn as signInRequest, SignInResponse } from '../api/signIn'
import { signUp as signUpRequest, SignUpResponse } from '../api/signUp'
import { signOut as signOutRequest } from '../api/signOut'
import {
  verifyOTP as verifyOTPRequest,
  VerifyOTPResponse
} from '../api/verifyOTP'
import { UserType } from '../typings/UserType'
import { SessionData } from '../typings/SessionData'
import {
  sessionSelector,
  isProcessingInvalidSessionSelector,
  hasSessionSelector
} from '../selectors'
import { actionCableActions, ActionCableChannel } from '@vivaldis/action-cable'
import { ActionCable } from '../config/ActionCable'
import { Platform } from 'react-native'
import { tracking } from '@vivaldis/tracking'
import * as app from '@vivaldis/app'
import { Locale } from '../config/Locale'
import { setBadgeCount } from '@vivaldis/notifications'

function* appInit(_action: ActionType<typeof app.init>) {
  const session: SessionData = yield select(sessionSelector)
  if (session && session.authentication_token) {
    switch (session.type) {
      case UserType.Employee:
        if (session.authentication_token) {
          axios().defaults.headers.common['X-Employee-Token'] =
            session.authentication_token
        }
        if (session.email) {
          axios().defaults.headers.common['X-Employee-Email'] = session.email
        }
        break
      case UserType.User:
      case UserType.SuperUser:
        if (session.authentication_token) {
          axios().defaults.headers.common['X-User-Token'] =
            session.authentication_token
        }
        if (session.email) {
          axios().defaults.headers.common['X-User-Email'] = session.email
        }
        break
    }
  } else {
    yield call(() => setBadgeCount(0))
  }
}

function* init(_action: ActionType<typeof actions.init>) {
  const session: SessionData = yield select(sessionSelector)

  if (session.authentication_token && session.email) {
    yield put(
      actionCableActions.init(
        ActionCable.URL,
        session.type === UserType.Employee
          ? {
              employee_token: session.authentication_token!,
              employee_email: session.email!
            }
          : {
              user_token: session.authentication_token,
              user_email: session.email
            }
      )
    )
    yield put(
      actionCableActions.subscribeToChannel(ActionCableChannel.Dashboard)
    )
  }
}

function* initComplete(action: ActionType<typeof actions.initComplete>) {
  const session: SessionData = yield select(sessionSelector)
  if (action.payload.employee) {
    const employee = action.payload.employee
    yield spawn(
      tracking.user,
      {
        id: employee.id,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        birthdate: employee.profile?.birthdate,
        city: employee.profile?.city,
        country: employee.profile?.country
          ? employee.profile?.country.nameEn
          : null
      },
      Locale.locale
    )
  } else if (action.payload.user) {
    const user = action.payload.user
    yield spawn(
      tracking.user,
      {
        id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        companyId: user?.company?.id,
        companyName: user?.company?.name
      },
      Locale.locale
    )
  }

  yield spawn(tracking.info, 'Session initialized', session)
}

// eslint-disable-next-line require-yield
function* update(action: ActionType<typeof actions.update>) {
  switch (action.payload.type) {
    case UserType.Employee:
      if (action.payload.authentication_token) {
        axios().defaults.headers.common['X-Employee-Token'] =
          action.payload.authentication_token
      }
      if (action.payload.email) {
        axios().defaults.headers.common['X-Employee-Email'] =
          action.payload.email
      }
      break
    case UserType.User:
    case UserType.SuperUser:
      if (action.payload.authentication_token) {
        axios().defaults.headers.common['X-User-Token'] =
          action.payload.authentication_token
      }
      if (action.payload.email) {
        axios().defaults.headers.common['X-User-Email'] = action.payload.email
      }
      break
  }
}

function* signIn(action: ActionType<typeof actions.signIn>) {
  try {
    const response: AxiosResponse<SignInResponse> | undefined = yield call(
      signInRequest,
      action.payload
    )
    if (response) {
      if ('authentication_token' in response.data) {
        yield put(
          actions.update({
            authentication_token: response.data.authentication_token,
            email: response.data.email,
            type: action.payload.type
          })
        )
      }
      yield put(actions.signInSuccess(response.data))
      if (action.meta?.onSuccessSignIn) {
        yield call(action.meta.onSuccessSignIn)
      }
    } else {
      yield put(actions.signInError(response))
      if (action.meta?.onFailedSignIn) {
        yield call(action.meta.onFailedSignIn, response)
      }
    }
  } catch (error) {
    yield put(actions.signInError(error))
    if (action.meta?.onFailedSignIn) {
      yield call(action.meta.onFailedSignIn, error)
    }
  }
}

function* signInSuccess(action: ActionType<typeof actions.signInSuccess>) {
  if ('authentication_token' in action.payload) {
    yield put(
      actions.update({
        authentication_token: action.payload.authentication_token,
        email: action.payload.email
      })
    )
  }
  yield put(actions.init())
}

function* signUp(action: ActionType<typeof actions.signUp>) {
  try {
    const response: AxiosResponse<SignUpResponse> | undefined = yield call(
      signUpRequest,
      action.payload
    )
    if (response) {
      yield put(
        actions.update({
          ...response.data,
          type: action.payload.type
        })
      )
      yield put(actions.signUpSuccess(response.data))
    } else {
      yield put(actions.signUpError(response))
    }
  } catch (error) {
    yield put(actions.signUpError(error))
  }
}

function* signUpSuccess(action: ActionType<typeof actions.signUpSuccess>) {
  const { authentication_token, email } = action.payload
  yield put(actions.update({ authentication_token, email }))
  yield put(actions.init())
}

function* signOut(_action: ActionType<typeof actions.signOut>) {
  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    yield call(() => setBadgeCount(0))
  }
  yield put(actions.signOutCredentials())
  yield put(app.reset())
}

function* signOutCredentials(_action: ActionType<typeof actions.signOut>) {
  const session: Required<SessionData> = yield select(sessionSelector)
  yield call(signOutRequest, session)
  yield put(actions.signOutComplete())
}

// eslint-disable-next-line require-yield
function* signOutComplete(_action: ActionType<typeof actions.signOut>) {
  delete axios().defaults.headers.common['X-Employee-Token']
  delete axios().defaults.headers.common['X-Employee-Email']
  delete axios().defaults.headers.common['X-User-Token']
  delete axios().defaults.headers.common['X-User-Email']
}

function* invalidate(_action: ActionType<typeof actions.invalidate>) {
  const isProcessingInvalidSession: boolean = yield select(
    isProcessingInvalidSessionSelector
  )
  const hasSession: boolean = yield select(hasSessionSelector)
  if (hasSession && isProcessingInvalidSession) {
    yield put(actions.signOut())
    yield put(actions.invalidateComplete())
  }
}

function* verifyOTP(action: ActionType<typeof actions.verifyOTP>) {
  try {
    const response: AxiosResponse<VerifyOTPResponse> | undefined = yield call(
      verifyOTPRequest,
      action.payload
    )
    if (response) {
      yield put(
        actions.update({
          authentication_token: response.data.authentication_token,
          email: action.payload.email,
          type: action.payload.type
        })
      )
      yield put(actions.verifyOTPSuccess(response.data))
      if (action.meta?.onSuccessVerifyOTP) {
        yield call(action.meta.onSuccessVerifyOTP)
      }
    } else {
      yield put(actions.verifyOTPError(response))
      if (action.meta?.onFailedVerifyOTP) {
        yield call(action.meta.onFailedVerifyOTP, response)
      }
    }
  } catch (error) {
    yield put(actions.verifyOTPError(error))
    if (action.meta?.onFailedVerifyOTP) {
      yield call(action.meta.onFailedVerifyOTP, error)
    }
  }
}

export function* sessionSaga() {
  yield takeEvery(app.AppActionType.Init, appInit)
  yield takeEvery(SessionActionType.Init, init)
  yield takeEvery(SessionActionType.InitComplete, initComplete)
  yield takeEvery(SessionActionType.Update, update)
  yield takeEvery(SessionActionType.SignIn, signIn)
  yield takeEvery(SessionActionType.SignInSuccess, signInSuccess)
  yield takeEvery(SessionActionType.SignUp, signUp)
  yield takeEvery(SessionActionType.SignUpSuccess, signUpSuccess)
  yield takeEvery(SessionActionType.SignOut, signOut)
  yield takeEvery(SessionActionType.SignOutCredentials, signOutCredentials)
  yield takeEvery(SessionActionType.SignOutComplete, signOutComplete)
  yield takeEvery(SessionActionType.Invalidate, invalidate)
  yield takeEvery(SessionActionType.VerifyOTP, verifyOTP)
}
