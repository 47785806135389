import styled from '@emotion/styled'
import { FC, RefObject, useEffect, useRef } from 'react'
import { GlyphProps, useTheme } from '@vivaldis/ui'
import { margin, MarginProps } from '../styles/margin'
import { startAnimation, stopAnimation } from './animation'

export type ProgressIndicatorProps = {
  className?: string
} & GlyphProps &
  MarginProps

const SVG = styled('svg')`
  ${props => margin(props)};
`

export const ProgressIndicator: FC<ProgressIndicatorProps> = ({
  color,
  size = 26,
  className,
  ...props
}) => {
  const theme = useTheme()

  const c1: RefObject<SVGCircleElement> = useRef<any>()
  const c2: RefObject<SVGCircleElement> = useRef<any>()
  const c3: RefObject<SVGCircleElement> = useRef<any>()
  const c4: RefObject<SVGCircleElement> = useRef<any>()
  const c5: RefObject<SVGCircleElement> = useRef<any>()
  const c6: RefObject<SVGCircleElement> = useRef<any>()
  const c7: RefObject<SVGCircleElement> = useRef<any>()
  const c8: RefObject<SVGCircleElement> = useRef<any>()

  useEffect(() => {
    if (
      c1.current &&
      c2.current &&
      c3.current &&
      c4.current &&
      c5.current &&
      c6.current &&
      c7.current &&
      c8.current
    ) {
      const id = startAnimation(
        c1.current,
        c2.current,
        c3.current,
        c4.current,
        c5.current,
        c6.current,
        c7.current,
        c8.current
      )
      return () => {
        stopAnimation(id)
      }
    }
  }, [c1, c2, c3, c4, c5, c6, c7, c8])

  return (
    <SVG
      className={className}
      width={size}
      height={size}
      x="0px"
      y="0px"
      viewBox="0 0 40 40"
      style={props.style as any}
      {...props}
    >
      <g fill={color ?? theme.primaryColor} fillRule="evenodd">
        <circle ref={c1} cx="3" cy="20.5" r="1" fillOpacity="0" />
        <circle ref={c2} cx="5.25" cy="12" r="1.5" fillOpacity="0" />
        <circle ref={c3} cx="11.5" cy="5.75" r="2.5" fillOpacity="0" />
        <circle ref={c4} cx="20" cy="3.5" r="3" fillOpacity="0" />
        <circle ref={c5} cx="28.5" cy="5.75" r="3" fillOpacity="0" />
        <circle ref={c6} cx="34.75" cy="12" r="2.5" fillOpacity="0" />
        <circle ref={c7} cx="37" cy="20.5" r="1.5" fillOpacity="0" />
        <circle ref={c8} cx="35" cy="29" r="1" fillOpacity="0" />
      </g>
    </SVG>
  )
}
