import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InputNumber } from '../InputNumber'
import type { InputNumberProps } from '../InputNumber'

export type WageInputProps = InputNumberProps

export const WageInput: FC<WageInputProps> = ({ ...restProps }) => {
  const [t] = useTranslation('antd_ui')
  return (
    <InputNumber
      type="number"
      placeholder="#"
      addonAfter={t('wage_input.currency')}
      style={{ width: '100%' }}
      {...restProps}
    />
  )
}
