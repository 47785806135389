type ErrorHandler = (
  error: Error,
  params?: { onBugsnagErrorCallback?: (event: unknown) => void }
) => void

export const errorHandlers: ErrorHandler[] = []

export const addOnErrorHandler = (handler: ErrorHandler) => {
  errorHandlers.push(handler)
}
