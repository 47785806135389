import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Radio } from '@vivaldis/antd-ui'
import { useForm } from '../../../providers/FormProvider'

export const WorkingAndProtectiveEquipment: FC = () => {
  const { t } = useTranslation('web_ui')

  const { values } = useForm()

  return (
    <>
      <Form.Item
        name="workEquipmentRequired"
        required
        label={t('position_form.step2.work_equipment_required.label')}
        tooltip={t('position_form.step2.work_equipment_required.tooltip')}
      >
        <Radio.Group name="workEquipmentRequired">
          <Radio name="workEquipmentRequired" value={true}>
            {t('position_form.step2.work_equipment_required.options.yes')}
          </Radio>
          <Radio name="workEquipmentRequired" value={false}>
            {t('position_form.step2.work_equipment_required.options.no')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      {values.workEquipmentRequired ? (
        <Form.Item
          name="workEquipmentRequiredConfirmation"
          required
          label={t(
            'position_form.step2.work_equipment_required_confirmation.label'
          )}
          tooltip={t(
            'position_form.step2.work_equipment_required_confirmation.tooltip'
          )}
        >
          <Radio.Group name="workEquipmentRequiredConfirmation">
            <Radio name="workEquipmentRequired" value={true}>
              {t(
                'position_form.step2.work_equipment_required_confirmation.options.yes'
              )}
            </Radio>
            <Radio name="workEquipmentRequiredConfirmation" value={false}>
              {t(
                'position_form.step2.work_equipment_required_confirmation.options.no'
              )}
            </Radio>
          </Radio.Group>
        </Form.Item>
      ) : null}
    </>
  )
}
