import { STUDENT_FUNCTION_CODES } from '@vivaldis/common'
import {
  FormikContextType,
  FormikProps,
  useFormikContext,
  Formik,
  FormikHelpers
} from 'formik'
import { useMemo, FC, useCallback, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  useIsApprovedPosition,
  usePosition
} from '../../../providers/PositionProvider'
import { useStep } from '../../../providers/StepProvider'
import { CompanyFunctionCode } from '../../../typings/CompanyFunctionCode'
import { Offer } from '../../../typings/Offer'
import { PositionFormStep2Values } from '../typings/PositionFormStep2Values'

const STUDENT_FUNCTION_CODES_WITH_WPS_REQUIRED = ['4350', '9060']

export function useForm(): FormikContextType<PositionFormStep2Values> {
  return useFormikContext()
}

export interface PositionFormProviderProps {
  onSubmit: (
    values: PositionFormStep2Values,
    formikHelpers: FormikHelpers<PositionFormStep2Values>
  ) => Promise<any>
  initialValues?: Partial<FormikProps<PositionFormStep2Values>['initialValues']>
  companyFunctionCodes: CompanyFunctionCode[]
  companyOffers: Offer[]
  functionCodeId?: string
}

export const FormProvider: FC<PropsWithChildren<PositionFormProviderProps>> = ({
  onSubmit,
  initialValues,
  companyFunctionCodes,
  companyOffers,
  functionCodeId,
  children
}) => {
  const [t] = useTranslation('web_ui')
  const { setStep } = useStep()

  const position = usePosition()
  const isApprovedPosition = useIsApprovedPosition()
  const positionHasWPS = !!position?.workPostSheet

  const formInitialValues: PositionFormStep2Values = useMemo(
    () => ({
      // by default, this radio should not be selected
      studentsAllowed: null as any,
      vcaCertificationRequired: null as any,
      vcaCertificationRequiredConfirmation: null as any,
      workEquipmentRequired: null as any,
      workEquipmentRequiredConfirmation: null as any,
      wpsRequired: null as any,
      certificateTypes: [],
      ...initialValues
    }),
    [initialValues]
  )

  const isStudentFunctionCode = useMemo(() => {
    return STUDENT_FUNCTION_CODES.includes(
      String(
        companyFunctionCodes.find(
          companyFunctionCode => companyFunctionCode.id === functionCodeId
        )?.code
      )
    )
  }, [companyFunctionCodes, functionCodeId])

  const isWPSRequiredForSelectedFunctionCode = useMemo(() => {
    return STUDENT_FUNCTION_CODES_WITH_WPS_REQUIRED.includes(
      String(
        companyFunctionCodes.find(
          companyFunctionCode => companyFunctionCode.id === functionCodeId
        )?.code
      )
    )
  }, [companyFunctionCodes, functionCodeId])

  const isAllCommitteesAreForWorker = useMemo(() => {
    return companyOffers.every(offer =>
      offer.committees.every(committee => committee.isWorker)
    )
  }, [companyOffers])

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        studentsAllowed: Yup.boolean()
          .typeError(
            t('position_form.step2.students_allowed.errors.must_be_checked')
          )
          .required()
          .when({
            // when user selected function code for student -> then student should be allowed
            is: () => isStudentFunctionCode,
            then: schema =>
              schema.oneOf(
                [true],
                t(
                  'position_form.step2.students_allowed.errors.students_allowed_required_for_student_function_code'
                )
              )
          }),
        wpsRequired: Yup.boolean()
          .typeError(
            t('position_form.step2.wps_required.errors.must_be_checked')
          )
          .when('vcaCertificationRequired', {
            is: true,
            then: schema =>
              schema.oneOf(
                [true],
                t(
                  'position_form.step2.wps_required.errors.wps_required_when_vca_required'
                )
              )
          })
          // when position is already approved and 'wpsRequired' is true, but position doesn't have actual WPS
          .when({
            is: (wpsRequired: boolean | unknown) =>
              isApprovedPosition && !!wpsRequired,
            then: schema =>
              schema.test({
                test: () => positionHasWPS,
                message: t(
                  'position_form.step2.wps_required.errors.actual_wps_required'
                )
              })
          })
          // when user selected function code for student (only function codes: '4350' and '9060') -> then work post sheet is required
          .when({
            is: () => isWPSRequiredForSelectedFunctionCode,
            then: schema =>
              schema.oneOf(
                [true],
                t(
                  'position_form.step2.wps_required.errors.wps_required_for_student_function_code'
                )
              )
          })
          // when a company has worker (arbeider) only committees -> then a work post sheet is required
          .when({
            is: () => isAllCommitteesAreForWorker,
            then: schema =>
              schema.oneOf(
                [true],
                t(
                  'position_form.step2.wps_required.errors.wps_required_for_worker_committee'
                )
              )
          })
          .required(),
        workEquipmentRequired: Yup.boolean()
          .typeError(
            t(
              'position_form.step2.work_equipment_required.errors.must_be_checked'
            )
          )
          .required(),
        workEquipmentRequiredConfirmation: Yup.boolean().when(
          'workEquipmentRequired',
          {
            is: true,
            then: schema =>
              schema
                .typeError(
                  t(
                    'position_form.step2.work_equipment_required_confirmation.errors.must_be_checked'
                  )
                )
                .required()
                .oneOf(
                  [true],
                  t(
                    'position_form.step2.work_equipment_required_confirmation.errors.work_equipment_must_be_provided'
                  )
                ),
            otherwise: schema => schema.nullable().notRequired()
          }
        ),
        vcaCertificationRequired: Yup.boolean()
          .typeError(
            t(
              'position_form.step2.vca_certification_required.errors.must_be_checked'
            )
          )
          .required(),
        vcaCertificationRequiredConfirmation: Yup.boolean().when(
          'vcaCertificationRequired',
          {
            is: true,
            then: schema =>
              schema
                .typeError(
                  t(
                    'position_form.step2.vca_certification_required_confirmation.errors.must_be_checked'
                  )
                )
                .required()
                .oneOf(
                  [true],
                  t(
                    'position_form.step2.vca_certification_required_confirmation.errors.vca_certification_must_be_provided'
                  )
                ),
            otherwise: schema => schema.nullable().notRequired()
          }
        ),
        //
        certificateTypes: Yup.array().required()
      }),
    [
      isAllCommitteesAreForWorker,
      isApprovedPosition,
      isStudentFunctionCode,
      isWPSRequiredForSelectedFunctionCode,
      positionHasWPS,
      t
    ]
  )

  const handleSubmit = useCallback(
    async (
      values: PositionFormStep2Values,
      formikHelpers: FormikHelpers<PositionFormStep2Values>
    ) => {
      await onSubmit(values, formikHelpers)
      formikHelpers.setSubmitting(false)
      setStep(step => step + 1)
    },
    [onSubmit, setStep]
  )

  return (
    <Formik<PositionFormStep2Values>
      enableReinitialize={true}
      initialValues={formInitialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}
