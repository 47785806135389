import type { DropDownProps } from 'antd'
import { Dropdown as AntdDropdown } from 'antd'
import { FC } from 'react'
import { DropdownButton } from './DropdownButton'

export type { DropDownProps } from 'antd'

const DropdownComponent: FC<DropDownProps> = props => (
  <AntdDropdown {...props} />
)

export const Dropdown = Object.assign(DropdownComponent, {
  ...AntdDropdown,
  Button: DropdownButton
})
