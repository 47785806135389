import { Alert } from '@vivaldis/antd-ui'
import { useCertificateTypeOptions } from '@vivaldis/ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Contract } from '../../../../typings/Contract'

interface Props {
  contract: Contract
}

export const Certificates: FC<Props> = ({ contract }) => {
  const [t] = useTranslation('web_ui')

  const missingEmployeeCertificates = useMemo(() => {
    if (!contract) {
      return []
    }

    if (!contract.position.certificateTypes) {
      return []
    }

    if (!contract.employee.employeeCertificates) {
      return []
    }

    return contract.position.certificateTypes.filter(
      positionCertificateType => {
        return !contract.employee.employeeCertificates.some(
          employeeCertificate =>
            employeeCertificate.certificateType === positionCertificateType
        )
      }
    )
  }, [contract])

  const certificateTypesOptions = useCertificateTypeOptions(
    contract.position.certificateTypes || []
  )

  return (
    <>
      {certificateTypesOptions.length ? (
        <ul>
          {certificateTypesOptions.map(option => (
            <li key={option.label}>{option.label}</li>
          ))}
        </ul>
      ) : (
        '-'
      )}
      {missingEmployeeCertificates.length ? (
        <Alert
          message={t(
            'view_contract_screen_content.missing_position_certificates_alert.message'
          )}
          description={t(
            'view_contract_screen_content.missing_position_certificates_alert.description'
          )}
          type="warning"
          showIcon
        />
      ) : null}
    </>
  )
}
