import {
  InviteToTeamMutation,
  InviteToTeamMutationVariables
} from './__generated__/InviteToTeamMutation'
import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import { employeeFragment } from '../fragments/EmployeeFragment'

const mutation = gql`
  ${employeeFragment}

  mutation InviteToTeamMutation($input: InviteToTeamInput!, $locale: String) {
    inviteToTeam(input: $input) {
      member {
        employee {
          id
          ...EmployeeFragment
        }
      }
    }
  }
`

export function inviteToTeamMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<InviteToTeamMutation, InviteToTeamMutationVariables>,
    'mutation'
  >
) {
  return apolloClient.mutate<
    InviteToTeamMutation,
    InviteToTeamMutationVariables
  >({
    mutation,
    ...options
  })
}
