import { useTheme } from '@vivaldis/ui'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, Radio, Space, Tag } from '@vivaldis/antd-ui'
import { useIsApprovedPosition } from '../../../../../../providers/PositionProvider'
import { useForm } from '../../../../providers/FormProvider'

export const EcoChequeComment: FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const isApprovedPosition = useIsApprovedPosition()

  const { values, setFieldValue } = useForm()

  const handleSetNotUseEcoChequeComment = useCallback(() => {
    setFieldValue('ecoChequeComment', null)
  }, [setFieldValue])

  return (
    <>
      <Space
        direction="horizontal"
        style={{ width: '100%', justifyContent: 'space-between' }}
      >
        <Form.Item
          name="useEcoChequeComment"
          required
          label={t('position_form.step3.use_eco_cheque_comment.label', {
            ns: 'web_ui'
          })}
          style={
            values.useEcoChequeComment
              ? { marginBottom: 8 }
              : { marginBottom: 6 }
          }
        >
          <Radio.Group name="useEcoChequeComment" disabled={isApprovedPosition}>
            <Space>
              <Radio value={false} name="useEcoChequeComment">
                {t('position_form.step3.use_eco_cheque_comment.options.no', {
                  ns: 'web_ui'
                })}
              </Radio>
              <Radio
                value={true}
                name="useEcoChequeComment"
                onChange={handleSetNotUseEcoChequeComment}
              >
                {t('position_form.step3.use_eco_cheque_comment.options.yes', {
                  ns: 'web_ui'
                })}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Space>

      {values.useEcoChequeComment === false ? (
        <Tag
          style={{
            background: theme.StatusGreen_green1,
            borderColor: theme.StatusGreen_green4,
            marginBottom: 16
          }}
        >
          {t('position_form.step3.use_eco_cheque_comment.yes_description', {
            ns: 'web_ui'
          })}
        </Tag>
      ) : null}

      {values.useEcoChequeComment ? (
        <Form.Item required name="ecoChequeComment">
          <Input.TextArea
            name="ecoChequeComment"
            placeholder={t(
              'position_form.step3.eco_cheque_comment.placeholder',
              {
                ns: 'web_ui'
              }
            )}
            rows={2}
            disabled={isApprovedPosition}
          />
        </Form.Item>
      ) : null}
    </>
  )
}
