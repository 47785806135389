import { gql } from '@apollo/client'
import { countryFragment } from './CountryFragment'
import { offerFragment } from './OfferFragment'

export const companyFragment = gql`
  ${countryFragment}
  ${offerFragment}

  fragment CompanyFragment on Company {
    buildingNumber
    city
    country {
      ...CountryFragment
    }
    createdAt
    hasNonPayrollOffers
    id
    logo
    name
    offers {
      ...OfferFragment
    }
    officialName
    ownerId
    referralCode
    street
    streetNumber
    vat
    website
    zip
  }
`
