import { FC, Suspense } from 'react'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'
import { Routes, Route } from 'react-router-dom'
import { lazyOrReloadPage } from '../../utils/lazyOrReloadPage'

const AddPosition = lazyOrReloadPage(
  () => import(/* webpackChunkName: "AddPosition" */ './screens/AddPosition')
)
const AddWorkPostSheet = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "AddWorkPostSheet" */ './screens/AddWorkPostSheet'
    )
)
const EditWorkPostSheet = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "EditWorkPostSheet" */ './screens/EditWorkPostSheet'
    )
)
const EditPosition = lazyOrReloadPage(
  () => import(/* webpackChunkName: "EditPosition" */ './screens/EditPosition')
)
const PositionList = lazyOrReloadPage(
  () => import(/* webpackChunkName: "PositionList" */ './screens/PositionList')
)
const ViewPosition = lazyOrReloadPage(
  () => import(/* webpackChunkName: "ViewPosition" */ './screens/ViewPosition')
)
const ViewWorkPostSheet = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "ViewWorkPostSheet" */ './screens/ViewWorkPostSheet'
    )
)

export const PositionsRoutes: FC = () => {
  return (
    <Suspense fallback={ProgressBarSuspenseFallback}>
      <Routes>
        <Route path="/" element={<PositionList />} />
        <Route path="add-position" element={<AddPosition />} />
        <Route path="edit-position/:positionId" element={<EditPosition />} />
        <Route
          path="add-work-post-sheet/:positionId"
          element={<AddWorkPostSheet />}
        />
        <Route
          path="edit-work-post-sheet/:positionId"
          element={<EditWorkPostSheet />}
        />
        <Route path="view-position/:positionId" element={<ViewPosition />} />
        <Route
          path="view-work-post-sheet/:positionId"
          element={<ViewWorkPostSheet />}
        />
      </Routes>
    </Suspense>
  )
}
