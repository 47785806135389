import {
  useState,
  useLayoutEffect,
  createElement,
  Fragment,
  ReactNode,
  ReactElement,
  FC,
  PropsWithChildren
} from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate as ReduxPersistGate } from 'redux-persist/integration/react'
import { Platform } from 'react-native'
import { ApolloClient, ApolloProvider } from '@apollo/client'
import { DateFnsDefaultOptionsProvider } from '@vivaldis/common'
import type { CachePersistor } from 'apollo3-cache-persist'
import { Store } from 'redux'
import { Persistor as ReduxPersistor } from 'redux-persist'
import { isAppNavigationReadySelector } from '../selectors'
import { useDispatch } from 'react-redux'
import { init, initData, initUI } from '../actions'
import { NotificationsProvider } from '@vivaldis/notifications'
import { SessionProvider, UserType } from '@vivaldis/session'
import { I18nProvider } from '@vivaldis/ui'
import {
  GoBackToEntriesProvider,
  SideSheetsProvider,
  MainNavigateProvider,
  CaptureMainNavigate
} from '@vivaldis/web-ui'
import { BrowserRouter } from 'react-router-dom'
import { ScrollToTop } from './SrollToTop'

interface Props {
  Providers: (children: ReactNode) => ReactElement | null
  apolloClient: ApolloClient<any>
  apolloCachePersistor: CachePersistor<any>
  reduxStore: Store
  reduxPersistor: ReduxPersistor
  userType: UserType
  actionCableURL: string
  sideSheets?: ReactNode
}

interface State {
  navigationReady: boolean
  apolloCacheReady: boolean
}

export const Data: FC<PropsWithChildren<Props>> = ({
  Providers,
  children,
  reduxPersistor,
  reduxStore,
  apolloClient,
  apolloCachePersistor,
  userType,
  actionCableURL,
  sideSheets
}) => {
  const [state, setState] = useState<State>({
    navigationReady: false,
    apolloCacheReady: false
  })

  useLayoutEffect(() => {
    reduxStore.dispatch(init())
  }, [reduxStore])

  useLayoutEffect(() => {
    let subscribed = true
    const unsubscribe = reduxStore.subscribe(() => {
      const isReady = isAppNavigationReadySelector(reduxStore.getState())
      if (isReady && subscribed) {
        subscribed = false
        unsubscribe()
      }
      if (isReady) {
        setState(s => ({ ...s, navigationReady: true }))
      }
    })
    return () => {
      if (subscribed) {
        subscribed = false
        unsubscribe()
      }
    }
  }, [reduxStore])

  useLayoutEffect(() => {
    apolloCachePersistor.restore().finally(() => {
      setState(s => ({ ...s, apolloCacheReady: true }))
    })
  }, [apolloCachePersistor])

  return (
    <ReduxProvider store={reduxStore}>
      <ReduxPersistGate persistor={reduxPersistor}>
        <SideSheetsProvider>
          <ApolloProvider client={apolloClient}>
            {state.apolloCacheReady ? (
              <SessionProvider type={userType} actionCableURL={actionCableURL}>
                {Providers(
                  <NotificationsProvider>
                    <DispatchInitData>
                      <I18nProvider>
                        <DateFnsDefaultOptionsProvider>
                          <GoBackToEntriesProvider>
                            <MainNavigateProvider>
                              {createElement(
                                Platform.select<any>({
                                  web: BrowserRouter,
                                  default: Fragment
                                }),
                                undefined,
                                <DispatchInitUI>
                                  {children}
                                  <CaptureMainNavigate />
                                  {Platform.select<any>({
                                    web: <ScrollToTop />,
                                    default: null
                                  })}
                                </DispatchInitUI>
                              )}
                              {sideSheets}
                            </MainNavigateProvider>
                          </GoBackToEntriesProvider>
                        </DateFnsDefaultOptionsProvider>
                      </I18nProvider>
                    </DispatchInitData>
                  </NotificationsProvider>
                )}
              </SessionProvider>
            ) : null}
          </ApolloProvider>
        </SideSheetsProvider>
      </ReduxPersistGate>
    </ReduxProvider>
  )
}

const DispatchInitData: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useDispatch()
  useLayoutEffect(() => {
    dispatch(initData())
  }, [dispatch])
  return <>{children}</>
}

const DispatchInitUI: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useDispatch()
  useLayoutEffect(() => {
    dispatch(initUI())
  }, [dispatch])
  return <>{children}</>
}
