import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const ChatSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.81444 20.1282C7.23544 19.8952 6.68844 19.6752 6.10844 19.6752C5.53647 19.6792 4.95963 19.8764 4.44924 20.0508L4.43044 20.0572L4.40294 20.0667C3.97418 20.2137 3.3485 20.4282 3.21244 20.2872C3.07091 20.1457 3.28998 19.5035 3.43599 19.0755L3.43744 19.0712L3.4388 19.0672C3.61547 18.5482 3.81544 17.9607 3.81544 17.3772C3.81544 16.7912 3.58944 16.2272 3.35544 15.6482C1.73044 12.1352 2.47444 7.94024 5.20844 5.20824C6.95344 3.46224 9.27644 2.50024 11.7494 2.50024C14.2214 2.50024 16.5434 3.46124 18.2894 5.20724C21.8954 8.81324 21.8954 14.6822 18.2894 18.2902C15.5564 21.0222 11.3614 21.7682 7.81444 20.1282ZM7.21944 21.5052C8.65844 22.1712 10.1964 22.4942 11.7244 22.4942C14.5274 22.4942 17.2944 21.4062 19.3494 19.3502C23.5414 15.1582 23.5414 8.33824 19.3494 4.14624C17.3214 2.11724 14.6214 1.00024 11.7484 1.00024C8.87544 1.00024 6.17644 2.11824 4.14844 4.14724C0.971437 7.32224 0.105437 12.1972 1.97844 16.2422C2.18244 16.7492 2.31544 17.1012 2.31544 17.3772C2.31544 17.7132 2.16444 18.1572 2.01744 18.5872C1.71944 19.4602 1.34944 20.5452 2.15144 21.3482C2.95603 22.1498 4.04334 21.7766 4.91711 21.4767L4.91844 21.4762C5.34444 21.3292 5.78544 21.1772 6.11344 21.1752C6.39641 21.1752 6.78979 21.333 7.21038 21.5016L7.21944 21.5052ZM14.6923 12.163C14.6923 12.716 15.1443 13.163 15.6963 13.163C16.2483 13.163 16.6963 12.716 16.6963 12.163C16.6963 11.61 16.2483 11.163 15.6963 11.163H15.6873C15.1353 11.163 14.6923 11.61 14.6923 12.163ZM11.6875 13.163C11.1355 13.163 10.6835 12.716 10.6835 12.163C10.6835 11.61 11.1255 11.163 11.6785 11.163H11.6875C12.2395 11.163 12.6875 11.61 12.6875 12.163C12.6875 12.716 12.2395 13.163 11.6875 13.163ZM6.67432 12.163C6.67432 12.716 7.12632 13.163 7.67832 13.163C8.23032 13.163 8.67832 12.716 8.67832 12.163C8.67832 11.61 8.23032 11.163 7.67832 11.163H7.66932C7.11732 11.163 6.67432 11.61 6.67432 12.163Z"
    />
  </svg>
)

export const Chat = forwardRef<HTMLSpanElement, AntdIconProps>((props, ref) => (
  <Icon component={ChatSvg} {...props} ref={ref} />
))
