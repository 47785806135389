import { useLocation, LinkProps, resolvePath } from 'react-router-dom'

type Location = ReturnType<typeof useLocation>

export const isActiveLink = ({
  location,
  to,
  caseSensitive = false,
  end = false
}: {
  location: Location
  to: LinkProps['to']
  caseSensitive?: boolean
  end?: boolean
}): boolean => {
  const path = resolvePath(to)

  let locationPathname = location.pathname
  let toPathname = path.pathname
  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase()
    toPathname = toPathname.toLowerCase()
  }

  const isActive =
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === '/')

  return isActive
}
