import { useBreakpoint } from '@vivaldis/antd-ui'
import { FC, PropsWithChildren } from 'react'
import { createElement } from '@vivaldis/common'
import styled from '@emotion/styled'
import { useIsInsideDetail } from '../../MasterDetail/MasterDetail/providers/DetailProvider'
import { Screen } from '../index'
import { ScreenEmptyStateProps } from '../typings/ScreenEmptyStateProps'

export const EmptyState: FC<ScreenEmptyStateProps> = ({
  EmptyStateComponent,
  navigationBar,
  title
}) => {
  return (
    <>
      <Screen navigationBar={navigationBar} title={title} Component={Container}>
        {createElement(EmptyStateComponent)}
      </Screen>
    </>
  )
}

export const Container: FC<PropsWithChildren<{ offsetY: number }>> = ({
  children,
  offsetY
}) => {
  const isInsideDetail = useIsInsideDetail()
  const breakpoint = useBreakpoint()
  return (
    <ContainerBase masterOffset={isInsideDetail && breakpoint.lg}>
      <Wrapper offsetY={offsetY}>{children}</Wrapper>
    </ContainerBase>
  )
}

const ContainerBase = styled('div')<{ masterOffset: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  ${({ masterOffset }) => (masterOffset ? `left: 336px;` : '')};
`

const Wrapper = styled('div')<{ offsetY: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  ${({ offsetY }) =>
    typeof offsetY === 'number' ? `transform: translateY(${offsetY}px)` : ''};
`
