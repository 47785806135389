/**
 * https://nl.wikipedia.org/wiki/Rijksregisternummer
 */

export const VALID_SSN_LENGTH = 11

const mod97 = (input: string): string =>
  String(97 - (Number(input) % 97)).padStart(2, '0')

export const isValidSSN = (ssn: string) => {
  // YYMMDD-nnn-cc   120601-842-04  821221 366 34
  const yymmddnnncc = normalize(ssn)
  if (yymmddnnncc.length !== VALID_SSN_LENGTH) {
    return false
  }

  const yymmddnnn = yymmddnnncc.slice(0, 9)
  const cc = yymmddnnncc.slice(9, 11)

  const checksum19 = mod97(`${yymmddnnn}`)
  const checksum20 = mod97(`2${yymmddnnn}`)

  return checksum19 === cc || checksum20 === cc
}

const getBirthDate = (ssn: string) => {
  const yymmddnnncc = normalize(ssn)

  const yymmddnnn = yymmddnnncc.slice(0, 9)

  const yy = yymmddnnncc.slice(0, 2)
  const mm = yymmddnnncc.slice(2, 4)
  const dd = yymmddnnncc.slice(4, 6)
  const cc = yymmddnnncc.slice(9, 11)

  const checksum19 = mod97(`${yymmddnnn}`)
  const checksum20 = mod97(`2${yymmddnnn}`)

  let year = ``

  if (checksum19 === cc) {
    year = `19${yy}`
  } else if (checksum20 === cc) {
    year = `20${yy}`
  } else {
    return null
  }

  return `${year}-${mm}-${dd}`
}

export enum GendersEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

const getGender = (ssn: string) => {
  const yymmddnnncc = normalize(ssn)

  const nnn = parseInt(yymmddnnncc.slice(6, 9), 10)

  if (nnn % 2 === 0) {
    return GendersEnum.FEMALE
  }
  if (nnn % 2 === 1) {
    return GendersEnum.MALE
  }

  return null
}

export interface SSNInfo {
  birthdate: string | null // YYYY-MM-DD (1991-08-26)
  gender: GendersEnum | null
  isValidSSN: boolean
}

// 1. Function to generate test data: https://www.cagy.org/test-data-generator/?page=function-reference&id=15#
// 2. Here you can actually generate test date: https://www.cagy.org/test-data-generator/?
// 3. Validate SSN -> https://z01.be/hst8_php/rijksregisternummer_uitgebreideOpl.php
// 4. Validate SSN 2 -> http://rsolution.be/rijksregister-nummer-generator.RSolution?year=1916&month=05&day=11&teller=357&man=1
// 5. Belgium SSN explanation: https://nl.wikipedia.org/wiki/Rijksregisternummer
// 6. JavaScript package for Belgium SSN -> https://www.npmjs.com/package/be-nrn   <--------------------------------------------------------

export const getSsnInfo = (ssn: string): SSNInfo => {
  if (!isValidSSN(ssn)) {
    return {
      birthdate: null,
      gender: null,
      isValidSSN: false
    }
  }

  return {
    birthdate: getBirthDate(ssn),
    gender: getGender(ssn),
    isValidSSN: true
  }
}

function normalize(ssn: string): string {
  return ssn.replace(/[^0-9]/g, '')
}
