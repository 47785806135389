import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonProps } from '@vivaldis/antd-ui'
import { Container, ContainerProps } from './components/Container'

export interface SubmitButtonProps extends ButtonProps, ContainerProps {
  isSubmitting: boolean
  label?: string
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  isSubmitting,
  marginTop,
  marginBottom,
  marginVertical,
  marginHorizontal,
  marginLeft,
  marginRight,
  children,
  label,
  ...rest
}) => {
  const [t] = useTranslation()
  return (
    <Container
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginVertical={marginVertical}
      marginHorizontal={marginHorizontal}
      marginLeft={marginLeft}
      marginRight={marginRight}
    >
      <Button
        type="primary"
        shape="round"
        size="large"
        disabled={isSubmitting}
        loading={isSubmitting}
        htmlType={isSubmitting ? 'button' : 'submit'}
        {...rest}
      >
        {children || label || t('form.save')}
      </Button>
    </Container>
  )
}
