import { ApolloClient, gql, MutationOptions } from '@apollo/client'
import {
  DeleteEmployeeCertificateMutation,
  DeleteEmployeeCertificateMutationVariables
} from './__generated__/DeleteEmployeeCertificateMutation'

const mutation = gql`
  mutation DeleteEmployeeCertificateMutation(
    $input: DeleteEmployeeCertificateInput!
  ) {
    deleteEmployeeCertificate(input: $input) {
      status
    }
  }
`

export function deleteEmployeeCertificateMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<
      DeleteEmployeeCertificateMutation,
      DeleteEmployeeCertificateMutationVariables
    >,
    'mutation'
  >
) {
  return apolloClient.mutate<
    DeleteEmployeeCertificateMutation,
    DeleteEmployeeCertificateMutationVariables
  >({
    mutation,
    ...options
  })
}
