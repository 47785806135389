import { Button, Row, Typography } from '@vivaldis/antd-ui'
import { Show } from '@vivaldis/icons'
import { useCertificateTypeOptions, VCA_CERTIFICATE_TYPE } from '@vivaldis/ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Information } from '../../../Information'
import { Position } from '../../typings/Position'
import { SafetyQuestion } from './components/SafetyQuestion'

interface Props {
  position: Position
  suView: boolean
  viewWPSUrl: string
}

export const SafetyInformation: FC<Props> = ({
  position,
  viewWPSUrl,
  suView
}) => {
  const [t] = useTranslation('web_ui')

  const certificateTypesOptions = useCertificateTypeOptions(
    position.certificateTypes
  )

  const hasWps = !!position.workPostSheet

  const vcaCertificationRequired = useMemo(
    () => position.certificateTypes.includes(VCA_CERTIFICATE_TYPE),
    [position.certificateTypes]
  )

  return (
    <>
      <Typography.Title level={5} marginBottom={16}>
        {t('view_position_screen_content.safety')}
      </Typography.Title>
      <Information>
        <Information.InformationField
          label={t('view_position_screen_content.students_allowed')}
        >
          {position.studentsAllowed
            ? t('yes', { ns: 'translation' })
            : t('no', { ns: 'translation' })}
        </Information.InformationField>

        <Information.InformationField
          label={t('view_position_screen_content.work_equipment_required')}
        >
          {position.workEquipmentRequired
            ? t('yes', { ns: 'translation' })
            : t('no', { ns: 'translation' })}
        </Information.InformationField>

        <Information.InformationField
          label={t('view_position_screen_content.vca_certification_required')}
        >
          {vcaCertificationRequired
            ? t('yes', { ns: 'translation' })
            : t('no', { ns: 'translation' })}
        </Information.InformationField>

        <Information.InformationField
          label={t('view_position_screen_content.wps_required')}
        >
          {position.wpsRequired
            ? t('yes', { ns: 'translation' })
            : t('no', { ns: 'translation' })}
          {hasWps ? (
            <Button
              shape="circle"
              size="large"
              to={viewWPSUrl}
              openInSideSheet
              goBackTo
              style={{ marginLeft: 6 }}
              icon={<Show />}
            />
          ) : null}
        </Information.InformationField>

        <Information.InformationField
          label={t('view_position_screen_content.certificate_types')}
        >
          {certificateTypesOptions.length
            ? certificateTypesOptions.map(certificateTypesOption => (
                <Row key={certificateTypesOption.value} justify="end">
                  {certificateTypesOption.label}
                </Row>
              ))
            : t('no', { ns: 'translation' })}
        </Information.InformationField>

        {suView ? (
          <Information.InformationField
            label={t('view_position_screen_content.safety_question')}
          >
            {position?.contractDatum?.safetyQuestion ? (
              <SafetyQuestion
                question={position.contractDatum.safetyQuestion}
              />
            ) : (
              t('no', { ns: 'translation' })
            )}
          </Information.InformationField>
        ) : null}
      </Information>
    </>
  )
}
