import {
  FormikContextType,
  FormikProps,
  useFormikContext,
  Formik,
  FormikHelpers
} from 'formik'
import { useMemo, FC, useCallback, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Premium } from '../../../typings/Premium'
import { PositionFormStep3Values } from '../typings/PositionFormStep3Values'

export function useForm(): FormikContextType<PositionFormStep3Values> {
  return useFormikContext()
}

export interface PositionFormProviderProps {
  onSubmit: (
    values: PositionFormStep3Values,
    formikHelpers: FormikHelpers<PositionFormStep3Values>
  ) => Promise<any>
  initialValues?: Partial<FormikProps<PositionFormStep3Values>['initialValues']>
  premiums: Premium[]
  showEcoChequeFields: boolean
}

export const FormProvider: FC<PropsWithChildren<PositionFormProviderProps>> = ({
  onSubmit,
  initialValues,
  premiums,
  showEcoChequeFields,
  children
}) => {
  const [t] = useTranslation()

  const formInitialValues: PositionFormStep3Values = useMemo(
    () => ({
      useNettoPremiumComment: false,
      nettoPremiumComment: null,
      premiumIds: [],
      premiumValuesInput: [],
      //
      useEcoChequeComment: null,
      ecoChequeComment: null,
      //
      ...initialValues
    }),
    [initialValues]
  )

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        premiumIds: Yup.array()
          .label(
            t('position_form.step3.premium_ids.label', {
              ns: 'web_ui'
            })
          )
          .required(),
        premiumValuesInput: Yup.array().of(
          Yup.object().shape({
            premiumId: Yup.string().label(t('form.this')).required(),
            value: Yup.number()
              .label(t('form.this'))
              .nullable()
              .notRequired()
              .when('premiumId', ([premiumId], schema) => {
                const premiumName = premiums.find(
                  premium => premium.id === premiumId
                )?.name

                switch (premiumName) {
                  case 'late_shift_bonus':
                    return schema.min(0).required()
                  case 'night_shift_bonus':
                    return schema.min(0).required()
                  case 'morning_shift_bonus':
                    return schema.min(0).required()
                  case 'meal_vouchers':
                    // Value of BEDRAG needs to be between 2 and 8 eur.
                    return schema.min(2).max(8).required()
                  case 'bike_bonus':
                    // NOTE: on position level this field should be empty (it's only required on contract level)
                    return schema.notRequired()
                  case 'social_kilometer_compensation':
                    // NOTE: on position level this field should be empty (it's only required on contract level)
                    return schema.notRequired()
                  default:
                    return schema
                }
              }),
            contributionValue: Yup.number()
              .label(t('form.this'))
              .nullable()
              .notRequired(),
            unit: Yup.string().label(t('form.this')).required()
          })
        ),
        // nettoPremiumComment
        useNettoPremiumComment: Yup.boolean()
          .label(
            t('position_form.step3.netto_premium_comment.label', {
              ns: 'web_ui'
            })
          )
          .required(),
        nettoPremiumComment: Yup.string()
          .label(
            t('position_form.step3.netto_premium_comment.label', {
              ns: 'web_ui'
            })
          )
          .nullable()
          .max(255)
          .when('useNettoPremiumComment', {
            is: true,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        // ecoChequeComment fields
        useEcoChequeComment: Yup.boolean()
          .label(t('form.this'))
          .nullable()
          // this question is required, only when User should see this fields
          .when({
            is: () => showEcoChequeFields,
            then: schema => schema.required()
          }),
        ecoChequeComment: Yup.string()
          .label(t('form.this'))
          .nullable()
          .max(255)
          .when('useEcoChequeComment', {
            is: true,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          })
      }),
    [premiums, showEcoChequeFields, t]
  )

  const handleSubmit = useCallback(
    async (
      values: PositionFormStep3Values,
      formikHelpers: FormikHelpers<PositionFormStep3Values>
    ) => {
      await onSubmit(values, formikHelpers)
      formikHelpers.setSubmitting(false)
    },
    [onSubmit]
  )

  return (
    <Formik<PositionFormStep3Values>
      enableReinitialize={true}
      initialValues={formInitialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}
