import { Typography } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../../../../Divider'
import { Information } from '../../../../../Information'
import { Offer } from '../../../../typings/Offer'
import { OvertimePayingStatusLabel } from './components/OvertimePayingStatusLabel'

interface Props {
  offer: Offer
}

export const Committees: FC<Props> = ({ offer }) => {
  const { t } = useTranslation('web_ui')

  return (
    <div style={{ flex: 1 }}>
      <Typography.Title level={5} style={{ marginBottom: 24 }}>
        {t('offers_table_view.committees.title')}
      </Typography.Title>
      {offer.committees.map(commetee => (
        <div
          key={`${commetee.id} - ${commetee.code}`}
          style={{ marginBottom: 32 }}
        >
          <Information>
            <Information.InformationField
              label={t(
                'offers_table_view.committees.fields.code_and_name.label'
              )}
              smallDivider
            >
              {commetee.code} - {commetee.name}
            </Information.InformationField>
            <Information.InformationField
              label={t(
                'offers_table_view.committees.fields.average_weekly_hours.label'
              )}
              smallDivider
            >
              {commetee.averageWeeklyHours}
            </Information.InformationField>
            <Information.InformationField
              label={t(
                'offers_table_view.committees.fields.average_weekly_hours_by_year.label'
              )}
              smallDivider
            >
              {commetee.averageWeeklyHoursByYear}
            </Information.InformationField>

            <Information.InformationField
              label={t(
                'offers_table_view.committees.fields.is_flexi_allowed.label'
              )}
              smallDivider
            >
              {commetee.isFlexiAllowed
                ? t('yes', { ns: 'translation' })
                : t('no', { ns: 'translation' })}
            </Information.InformationField>
            <Information.InformationField
              label={t('offers_table_view.committees.fields.statute.label')}
              smallDivider
            >
              {commetee.isWorker
                ? t(
                    'offers_table_view.committees.fields.statute.options.worker'
                  )
                : t(
                    'offers_table_view.committees.fields.statute.options.clerk'
                  )}
            </Information.InformationField>
            <Information.InformationField
              label={t(
                'offers_table_view.committees.fields.overtime_paying_status.label'
              )}
              smallDivider
            >
              <OvertimePayingStatusLabel
                overtimePayingStatus={commetee.overtimePayingStatus}
              />
            </Information.InformationField>
          </Information>
          <Divider sectionSeparator />
        </div>
      ))}
    </div>
  )
}
