import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, PropsWithChildren } from 'react'
import { SIDE_SHEET_DEFAULT_WIDTH } from '../../Layout/components/SideSheet'
import { useIsInLayout } from '../../Layout/hooks/useIsInLayout'
import { useIsInSideSheet } from '../../SideSheets/hooks/useIsInSideSheet'

export const Container: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const isInSideSheet = useIsInSideSheet()
  const isInSideSheetLayout = useIsInLayout('SideSheet')
  return (
    <Wrapper
      isInSideSheet={isInSideSheet}
      isInSideSheetLayoutOutsideSideSheet={
        !isInSideSheet && isInSideSheetLayout
      }
    >
      <BaseContainer
        isInSideSheet={isInSideSheet}
        isInSideSheetLayoutOutsideSideSheet={
          !isInSideSheet && isInSideSheetLayout
        }
      >
        <InnerContainer
          isInSideSheet={isInSideSheet}
          isInSideSheetLayoutOutsideSideSheet={
            !isInSideSheet && isInSideSheetLayout
          }
        >
          {children}
        </InnerContainer>
      </BaseContainer>
    </Wrapper>
  )
}

interface BaseContainerProps {
  isInSideSheet: boolean
  isInSideSheetLayoutOutsideSideSheet: boolean
}

const Wrapper = styled('div')<BaseContainerProps>`
  height: 60px;

  ${props =>
    props.isInSideSheetLayoutOutsideSideSheet ? 'margin-top: 40px' : ''};
`

const BaseContainer = styled('div')<BaseContainerProps>`
  border-top: 2px solid ${({ theme }) => theme.gray4};
  padding: 0px 16px;
  padding: ${props =>
    props.isInSideSheetLayoutOutsideSideSheet ? '0px 0px' : '0px 16px'};

  ${props =>
    props.isInSideSheetLayoutOutsideSideSheet
      ? css`
          @media only screen and (max-width: ${props.theme.screenLG - 1}px) {
            padding: 0px 16px;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            height: 60px;
            background-color: ${props.theme.white};
            z-index: 2;
          }
        `
      : css`
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          height: 60px;
          background-color: ${props.theme.white};

          ${props.isInSideSheet
            ? css`
                border-bottom-left-radius: 9px;
                border-bottom-right-radius: 9px;
              `
            : ''}
        `}
`

const InnerContainer = styled('div')<BaseContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;

  ${props =>
    props.isInSideSheetLayoutOutsideSideSheet
      ? css`
          width: 100%;
          max-width: ${SIDE_SHEET_DEFAULT_WIDTH}px;
          margin: 0 auto;
          @media only screen and (max-width: ${props.theme.screenLG - 1}px) {
            max-width: 100%;
          }
        `
      : null}
`
