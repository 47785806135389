import { VCA_CERTIFICATE_TYPE } from '@vivaldis/ui'
import type { PositionFormStep2Values } from '../steps/Step2/typings/PositionFormStep2Values'
import type { Position } from '../typings/Position'

export const positionToStep2FormValues = (
  position: Position
): PositionFormStep2Values => {
  // wpsRequired should be changed to true if position already has workPostSheet
  const wpsRequired = !!position.wpsRequired || !!position.workPostSheet?.id

  const vcaCertificationRequired =
    position.certificateTypes.includes(VCA_CERTIFICATE_TYPE)

  return {
    studentsAllowed: position.studentsAllowed,
    vcaCertificationRequired: vcaCertificationRequired,
    vcaCertificationRequiredConfirmation: vcaCertificationRequired,
    workEquipmentRequired: !!position.workEquipmentRequired,
    workEquipmentRequiredConfirmation: !!position.workEquipmentRequired,
    wpsRequired: wpsRequired,
    certificateTypes: position.certificateTypes
  }
}
