import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Select } from '@vivaldis/antd-ui'
import { useIsApprovedPosition } from '../../../../../providers/PositionProvider'
import { CompanyFunctionCode } from '../../../../../typings/CompanyFunctionCode'
import { useForm } from '../../../providers/FormProvider'

interface Props {
  initiallySelectedCompanyCode?: CompanyFunctionCode | undefined
  companyFunctionCodes: CompanyFunctionCode[]
}

export const FunctionCode: FC<Props> = ({
  initiallySelectedCompanyCode,
  companyFunctionCodes
}) => {
  const { t } = useTranslation()
  const { values } = useForm()

  const isApprovedPosition = useIsApprovedPosition()

  const companyFunctionCodesOptions = useMemo(() => {
    const options = companyFunctionCodes.map(companyFunctionCode => ({
      label: `${companyFunctionCode.name || companyFunctionCode.code}`,
      value: companyFunctionCode.id
    }))

    const isSelectedFunctionCodeIdExistInOptions = options.some(
      option => option.value === values.functionCodeId
    )

    // in case when position has "functionCodeId" value
    // that is not in the list of "companyFunctionCodes"
    // (it can be when we are an editing old position where available function codes were changed/deleted)
    // we need to add it to the list to be able to show the proper label in the select
    if (
      !isSelectedFunctionCodeIdExistInOptions &&
      initiallySelectedCompanyCode
    ) {
      return [
        ...options,
        {
          label: `${
            initiallySelectedCompanyCode.name ||
            initiallySelectedCompanyCode.code
          }`,
          value: initiallySelectedCompanyCode.id
        }
      ]
    }

    return options
  }, [
    companyFunctionCodes,
    initiallySelectedCompanyCode,
    values.functionCodeId
  ])

  return (
    <Form.Item
      name="functionCodeId"
      label={t('position_form.step1.function_code_id.label', {
        ns: 'web_ui'
      })}
      required
    >
      <Select
        name="functionCodeId"
        placeholder={t('form.required')}
        options={companyFunctionCodesOptions}
        disabled={isApprovedPosition}
        showSearch
      />
    </Form.Item>
  )
}
