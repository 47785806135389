import { forwardRef } from 'react'
import { Spin } from 'antd'
import styled from '@emotion/styled'

interface Props {}

export const FooterLoadingMoreIndicator = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    return (
      <Spin size="large">
        <Base ref={ref} />
      </Spin>
    )
  }
)

const Base = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;
`
