import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Select } from '@vivaldis/antd-ui'
import { Divider } from '../../../../../../Divider'
import { VivaldisOffice } from '../../../../../typings/VivaldiOffice'

interface Props {
  vivaldisOffices: VivaldisOffice[]
}

export const VivaldisOfficeCode: FC<Props> = ({ vivaldisOffices }) => {
  const { t } = useTranslation()

  const vivaldisOfficesOptions = useMemo(
    () =>
      vivaldisOffices.map(vivaldisOffice => ({
        label: `${vivaldisOffice.code} - ${vivaldisOffice.name}`,
        value: vivaldisOffice.code
      })),
    [vivaldisOffices]
  )

  return (
    <>
      <Divider sectionSeparator />
      <Form.Item
        name="vivaldisOfficeCode"
        required
        label={t('position_form.step1.vivaldis_office_code.label', {
          ns: 'web_ui'
        })}
      >
        <Select
          name="vivaldisOfficeCode"
          placeholder={t('form.required')}
          options={vivaldisOfficesOptions}
          showSearch
        />
      </Form.Item>
    </>
  )
}
