import { memo, ReactNode, useRef, createContext, FC } from 'react'
import { EmployeeQueryProvider } from './EmployeeQueryProvider'
import { UserQueryProvider } from './UserQueryProvider'
import { ReduxProvider } from './ReduxProvider'
import { useLocale } from '@vivaldis/common'
import { Locale } from '../config/Locale'
import { ActionCable } from '../config/ActionCable'
import { UserType } from '../typings/UserType'
import { isEqual } from 'lodash'
import { SessionData } from '../typings/SessionData'
import { useSelector } from 'react-redux'
import { sessionSelector } from '../selectors'

export const SessionContext = createContext<SessionData | undefined>(undefined)

export interface SessionProps {
  children?: ReactNode
  type: UserType
  actionCableURL: string
}

const MemoizedSessionProvider: FC<SessionProps> = ({
  children,
  type,
  actionCableURL
}) => {
  const locale = useLocale()
  const session = useSelector(sessionSelector)
  const sessionRef = useRef(session)
  if (session !== sessionRef.current && !isEqual(session, sessionRef.current)) {
    sessionRef.current = session
  }

  Locale.locale = locale
  ActionCable.URL = actionCableURL

  return (
    <SessionContext.Provider value={sessionRef.current}>
      <EmployeeQueryProvider type={type}>
        <UserQueryProvider type={type}>
          <ReduxProvider actionCableURL={actionCableURL}>
            {children}
          </ReduxProvider>
        </UserQueryProvider>
      </EmployeeQueryProvider>
    </SessionContext.Provider>
  )
}

export const SessionProvider = memo(MemoizedSessionProvider)
