import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Information } from '../../../Information'
import type { CompanySurvey_questions } from '../../types/CompanySurvey'

interface Props {
  question: CompanySurvey_questions
}

export const Bool: FC<Props> = ({ question }) => {
  const [t] = useTranslation('web_ui')

  const boolValue = question.answer?.boolValue || false

  return (
    <Information.InformationField label={question.title}>
      {boolValue
        ? t('yes', { ns: 'translation' })
        : t('no', { ns: 'translation' })}
    </Information.InformationField>
  )
}
