import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Radio } from '@vivaldis/antd-ui'
import { useForm } from '../../providers/FormProvider'
import { PositionWithWPS } from '../../types'
import { CreateWPSFormContent } from './CreateWPSFormContent'
import { UploadPDFWPSFormContent } from './UploadPDFWPSFormContent'

// Example of this form on official web-site: https://www.workstationsheet.be/your-workstation-sheet/online-form

interface Props {
  disabledEditing: boolean
  positionsWithWPS?: PositionWithWPS[]
}

export const FormContent: FC<Props> = ({
  disabledEditing,
  positionsWithWPS
}) => {
  const [t] = useTranslation('web_ui')

  const { values } = useForm()

  const { uploadOwnWPS } = values

  return (
    <>
      <Form.Item
        name="uploadOwnWPS"
        required
        label={t('work_post_sheet_form.uploadOwnWPS.label')}
        tooltip={t('work_post_sheet_form.uploadOwnWPS.tooltip')}
      >
        <Radio.Group name="uploadOwnWPS" disabled={disabledEditing}>
          <Radio name="uploadOwnWPS" value={false}>
            {t('work_post_sheet_form.uploadOwnWPS.options.no')}
          </Radio>
          <Radio name="uploadOwnWPS" value={true}>
            {t('work_post_sheet_form.uploadOwnWPS.options.yes')}
          </Radio>
        </Radio.Group>
      </Form.Item>

      <div>
        {uploadOwnWPS ? (
          <UploadPDFWPSFormContent disabledEditing={disabledEditing} />
        ) : (
          <CreateWPSFormContent
            disabledEditing={disabledEditing}
            positionsWithWPS={positionsWithWPS}
          />
        )}
      </div>
    </>
  )
}
