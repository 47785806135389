import type { Contract } from '../typings/Contract'
import type { ContractStateInfo } from '../typings/ContractStateInfo'
import { getContractStateInfo } from '../utils/getContractStateInfo'

// description is here: https://sealjobs.atlassian.net/wiki/spaces/SJ/pages/2094104581/Contract+states

export const useContractStateInfo = (contract: Contract): ContractStateInfo => {
  const { isSentContract, isContractAlreadyStarted, label } =
    getContractStateInfo(contract)

  return {
    isSentContract,
    isContractAlreadyStarted,
    label
  }
}
