import { Menu } from '@vivaldis/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDrawer } from '../../Drawer/providers/DrawerProvider'
import { ActionButton } from './ActionButton'

export const MenuButton: FC = () => {
  const { open } = useDrawer()
  const { t } = useTranslation('web_ui')
  return (
    <ActionButton
      title={t('navigation_bar.menu')}
      onClick={open}
      icon={<Menu style={{ fontSize: 24 }} />}
    />
  )
}
