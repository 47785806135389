import { FC, useState, useCallback, PropsWithChildren } from 'react'
import { AddEntryContext } from '../context/AddEntryContext'
import { GoBackToEntriesContext } from '../context/GoBackToEntriesContext'
import { GoBackToEntry } from '../typings/GoBackToEntry'

export const GoBackToEntriesProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const [entries, setEntries] = useState<GoBackToEntry[]>([])
  const addEntry = useCallback((entry: GoBackToEntry) => {
    setEntries(entries => [...entries, entry])
  }, [])
  return (
    <GoBackToEntriesContext.Provider value={[entries, setEntries]}>
      <AddEntryContext.Provider value={addEntry}>
        {children}
      </AddEntryContext.Provider>
    </GoBackToEntriesContext.Provider>
  )
}
