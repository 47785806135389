import { axios } from '@vivaldis/common'
import { call, delay, fork } from 'redux-saga/effects'

let prevManifestHash: string | null = null
// we want to keep FE up to date with latest deployed version
function* handleCheckNewVersionAndReloadScreen() {
  // we want to have this check only in production
  while (process.env.NODE_ENV === 'production') {
    try {
      const response: { data: { version: string } } = yield call(
        axios().get,
        '/asset-manifest.json',
        {
          baseURL: ''
        }
      )

      const manifestHash = JSON.stringify(response.data)
      if (prevManifestHash && prevManifestHash !== manifestHash) {
        yield call(() => window.location.reload())
      }
      prevManifestHash = manifestHash
    } catch (e) {}
    // check version every 60 seconds
    yield delay(60 * 1000)
  }
}

export function* version() {
  yield fork(handleCheckNewVersionAndReloadScreen)
}
