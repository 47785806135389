import { Button, Row } from '@vivaldis/antd-ui'
import { ReloadOutlined } from '@ant-design/icons'
import { formatDistance, isValid, parseISO } from 'date-fns'
import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmployeeProfileQuery_employee } from '../../../../typings/EmployeeProfileQuery'
import { Information } from '../../../../../Information'
import { NotSetValue } from '../../../NotSetValue'

export interface EmployeeProfileProps {
  employee: EmployeeProfileQuery_employee
  onRefreshRemainingHours?: () => Promise<void>
}

export const RemainingWorkingHours: FC<EmployeeProfileProps> = ({
  employee,
  onRefreshRemainingHours
}) => {
  const [t] = useTranslation('web_ui')

  const isRemainingHoursHasNumberValue = useMemo(() => {
    return typeof employee.workProfile?.remainingHours === 'number'
  }, [employee.workProfile?.remainingHours])

  const remainingHoursScrapedAt = useMemo(() => {
    if (!employee.workProfile?.remainingHoursScrapedAt) {
      return <NotSetValue />
    }

    const remainingHoursScrapedAtParsed = parseISO(
      String(employee.workProfile?.remainingHoursScrapedAt)
    )

    return isValid(remainingHoursScrapedAtParsed)
      ? formatDistance(remainingHoursScrapedAtParsed, new Date(), {
          addSuffix: true
        })
      : '-'
  }, [employee.workProfile?.remainingHoursScrapedAt])

  const [isRefreshing, setIsRefreshing] = useState(false)
  const handleOnRefreshClick = useCallback(async () => {
    try {
      setIsRefreshing(true)
      await onRefreshRemainingHours?.()
    } catch {
    } finally {
      setIsRefreshing(false)
    }
  }, [onRefreshRemainingHours])

  // we don't show this functionality for U. So only when `onRefreshRemainingHours` is provided we show this block
  if (!onRefreshRemainingHours || !employee?.isStudent) {
    return null
  }

  return (
    <Information.InformationField
      label={t('employee_profile.remaining_hours.label')}
    >
      <Button
        type="link"
        icon={<ReloadOutlined />}
        loading={isRefreshing}
        onClick={handleOnRefreshClick}
      >
        {t('employee_profile.remaining_hours.refresh_button')}
      </Button>
      <>
        {isRemainingHoursHasNumberValue ? (
          t('employee_profile.remaining_hours.value', {
            count: employee.workProfile?.remainingHours || 0
          })
        ) : (
          <NotSetValue />
        )}
      </>
      <Row justify="end">
        <span style={{ marginRight: 4 }}>
          {t('employee_profile.remaining_hours.updated')}:{'  '}
        </span>
        {remainingHoursScrapedAt}
      </Row>
    </Information.InformationField>
  )
}
