import { useBreakpoint } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { ProgressBar } from '@vivaldis/web-ui'
import { hasSessionSelector, useUser } from '@vivaldis/session'
import { useTheme } from '@vivaldis/ui'
import { Global, css } from '@emotion/react'
import { ClarityTrackingConfig } from './ClarityTrackingConfig'
import { MobileLayout } from './MobileLayout'
import { IntercomMessenger } from './IntercomMessenger'
import DevTools from './Dev/DevTools'
import { Locale } from './Locale'
import { DesktopLayout } from './DesktopLayout'
import { NoUserLoadingState } from './NoUserLoadingState'

export const App: FC = () => {
  const hasSession = useSelector(hasSessionSelector)
  const user = useUser()
  const breakpoint = useBreakpoint()
  const theme = useTheme()

  if (hasSession && !user) {
    return <NoUserLoadingState />
  }

  return (
    <>
      {breakpoint.lg ? <DesktopLayout /> : <MobileLayout />}
      {hasSession ? <IntercomMessenger /> : null}
      {process.env.NODE_ENV !== 'production' ? <DevTools /> : null}
      <ProgressBar />
      {hasSession ? (
        <Global
          styles={
            // We set the background-color of the app here when the app is loaded to not override the splash screen background-color
            css`
              html,
              body {
                background-color: ${theme.extraBackground1} !important;
              }
            `
          }
        />
      ) : null}
      <Locale />
      {hasSession ? <ClarityTrackingConfig /> : null}
    </>
  )
}
