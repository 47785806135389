import styled from '@emotion/styled'
import { tracking } from '@vivaldis/tracking'
import { useTheme } from '@vivaldis/ui'
import { FC, useCallback, useMemo } from 'react'
import {
  Avatar,
  Dropdown,
  Menu,
  TextStylePreset,
  Typography
} from '@vivaldis/antd-ui'
import { ArrowDown2 } from '@vivaldis/icons'
import { UserOutlined } from '@ant-design/icons'
import { useUser } from '@vivaldis/session'
import { PickAnimated, SpringValues } from 'react-spring'
import { selectCompanyMutation } from '../../../../graphql/mutations/selectCompanyMutation'

interface Props {
  isSiderCollapsed: boolean
  animatedStyles: SpringValues<PickAnimated<{ width: number }>>
}

export const CompaniesDropdown: FC<Props> = ({
  isSiderCollapsed,
  animatedStyles
}) => {
  const theme = useTheme()
  const user = useUser()

  // we show this only when user has multiple companies

  const permissions = useMemo(
    () => user?.permissions || [],
    [user?.permissions]
  )

  const handleMenuItemClick = useCallback(
    async (item: any) => {
      const { key } = item

      if (key !== user?.company?.id) {
        try {
          await selectCompanyMutation({
            companyId: String(key)
          })
        } catch (error) {
          tracking.error(error)
        }
      }
    },
    [user?.company?.id]
  )

  const menuItems = useMemo(
    () =>
      permissions.map(permission => ({
        key: permission.companyId,
        label: permission.companyName
      })),
    [permissions]
  )

  if (permissions.length < 2) {
    return (
      <Base>
        <div>
          <Avatar
            size={32}
            icon={<UserOutlined />}
            src={user?.company?.logo}
            style={{ marginRight: 12 }}
          />
        </div>
        <div style={{ overflow: 'hidden' }}>
          <Typography.Text preset={TextStylePreset.Semibold16} ellipsis>
            {user?.company?.name}
          </Typography.Text>
        </div>
      </Base>
    )
  }

  if (isSiderCollapsed) {
    return (
      <MenuBase>
        <Menu
          onClick={handleMenuItemClick}
          mode="vertical"
          items={[
            {
              key: 'selected-company',
              title: user?.company?.name || '',
              label: (
                <SelectedCompanyAvatarBase>
                  <Avatar
                    size={32}
                    icon={<UserOutlined />}
                    src={user?.company?.logo}
                  />
                </SelectedCompanyAvatarBase>
              ),
              children: menuItems
            }
          ]}
        />
      </MenuBase>
    )
  }

  return (
    <Dropdown
      menu={{
        onClick: handleMenuItemClick,
        items: menuItems
      }}
    >
      <Base>
        <div>
          <Avatar
            size={32}
            icon={<UserOutlined />}
            src={user?.company?.logo}
            style={{ marginRight: 12 }}
          />
        </div>
        <div style={{ overflow: 'hidden', width: '100%' }}>
          <Typography.Text preset={TextStylePreset.Semibold16} ellipsis>
            {user?.company?.name}
          </Typography.Text>
        </div>
        <ArrowDown2 style={{ color: theme.primaryColor, fontSize: 24 }} />
      </Base>
    </Dropdown>
  )
}

const Base = styled('div')`
  height: 65px;
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${props => props.theme.extraBackground1};
`

const MenuBase = styled('div')`
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.extraBackground1};

  &
    > .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title {
    padding-left: 0;
  }

  & > .ant-menu-vertical .ant-menu-submenu-title {
    padding-left: 0;
  }
`

const SelectedCompanyAvatarBase = styled('div')`
  display: flex;
  width: 50px;
  height: 100%;
  justify-content: center;
  align-items: center;
`
