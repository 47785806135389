import { FC } from 'react'
import type { CompanySurvey } from '../../types/CompanySurvey'
import { Bool } from './components/Bool'
import { BoolWithText } from './components/BoolWithText'
import { Text } from './components/Text'

interface Props {
  companySurvey: CompanySurvey
}

export const FormContent: FC<Props> = ({ companySurvey }) => {
  return (
    <>
      {companySurvey.questions.map(question => {
        switch (question.questionType) {
          case 'bool_with_text': {
            return <BoolWithText key={question.id} question={question} />
          }
          case 'text': {
            return <Text key={question.id} question={question} />
          }
          case 'bool': {
            return <Bool key={question.id} question={question} />
          }
          default: {
            return `THIS QUESTION TYPE IS NOT IMPLEMENTED (${question.questionType})`
          }
        }
      })}
    </>
  )
}
