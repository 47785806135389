import { compareAsc, isFuture, isPast, parseISO } from 'date-fns'
import type { Contract, Workday } from '../typings/Contract'
import { ContractStateInfoLabel } from '../typings/ContractStateInfo'
import type { ContractStateInfo } from '../typings/ContractStateInfo'

// description is here: https://sealjobs.atlassian.net/wiki/spaces/SJ/pages/2094104581/Contract+states

export const getContractStateInfo = (contract: Contract): ContractStateInfo => {
  const isSentContract = getIsSentContract(contract)

  const workdays = contract.workdays
    .map(workday => ({ ...workday }))
    .sort((a, b) => compareAsc(parseISO(a.startsAt), parseISO(b.startsAt)))

  const firstContractDay = workdays[0]

  const label = getContractStateInfoLabel(contract)

  const isContractAlreadyStarted = isPast(parseISO(firstContractDay.startsAt))

  return {
    isSentContract,
    isContractAlreadyStarted,
    label
  }
}

const getIsSentContract = (contract: Contract): boolean => {
  return !!contract.backOfficeId
}

const getContractTime = (
  lastContractDay: Workday
): 'past' | 'present' | 'future' => {
  const startsTime = parseISO(lastContractDay.startsAt)

  if (isFuture(startsTime)) {
    return 'future'
  }

  if (isPast(startsTime)) {
    return 'past'
  }

  return 'present'
}

const getContractStateInfoLabel = (
  contract: Contract
): ContractStateInfoLabel => {
  const isSentContract = getIsSentContract(contract)

  if (!isSentContract) {
    return ContractStateInfoLabel.REQUESTED
  }

  const hasPendingWorkdayCancellationRequest = contract.workdays.some(
    workday => workday.cancellationRequestState === 'pending'
  )

  const hasRefusedWorkdayCancellationRequest = contract.workdays.some(
    workday => workday.cancellationRequestState === 'denided'
  )

  if (hasPendingWorkdayCancellationRequest) {
    return ContractStateInfoLabel.WORKDAY_CANCELLATION_PENDING
  }

  if (hasRefusedWorkdayCancellationRequest) {
    return ContractStateInfoLabel.WORKDAY_CANCELLATION_REFUSED
  }

  // when contract has cancellation request than this contact in one of 2 cancellation states
  if (contract.cancellationRequest) {
    switch (contract.cancellationRequest.isApproved) {
      case false:
        return ContractStateInfoLabel.CONTRACT_CANCELLATION_REFUSED
      case null:
        return ContractStateInfoLabel.CONTRACT_CANCELLATION_PENDING
    }
  }

  const workdays = contract.workdays
    .map(workday => ({ ...workday }))
    .sort((a, b) => compareAsc(parseISO(a.startsAt), parseISO(b.startsAt)))

  const lastContractDay = workdays[workdays.length - 1]

  // "Time" is it based on the end hour of the last day in that contract
  const time = getContractTime(lastContractDay)

  switch (time) {
    case 'past':
      return ContractStateInfoLabel.PAST
    case 'present':
      return ContractStateInfoLabel.ONGOING
    case 'future':
      return ContractStateInfoLabel.APPROVED
    default:
      return ContractStateInfoLabel.REQUESTED
  }
}
