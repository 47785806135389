import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BaseForm,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select
} from '@vivaldis/antd-ui'
import { useTheme } from '@vivaldis/ui'
import { Divider } from '../../../../Divider'
import { useForm } from '../../../providers/FormProvider'
import {
  PositionWithWPS,
  WorkPostSheetItemTypesEnum as Type
} from '../../../types'
import { Grid } from '../../../../Grid'
import { BodyAlt } from '../../../../Typography'
import { FontWeight } from '../../../../styles/font'
import { wpsToWPSFormValues } from '../../../utils/wpsToWPSFormValues'

// Example of this form on official web-site: https://www.workstationsheet.be/your-workstation-sheet/online-form

interface Props {
  disabledEditing: boolean
  positionsWithWPS?: PositionWithWPS[]
}

interface CheckboxOption {
  label: string
  value: string
}

export const CreateWPSFormContent: FC<Props> = ({
  disabledEditing,
  positionsWithWPS
}) => {
  const [t, i18n] = useTranslation('web_ui')
  const theme = useTheme()

  const { values, setValues, resetForm } = useForm()

  const workClothesAndProtectiveEquipmentOptions: CheckboxOption[] = useMemo(
    () => [
      {
        label: t('work_post_sheet_form.glasses_safety_screen.label'),
        value: Type.GLASSES_SAFETY_SCREEN
      },
      {
        label: t('work_post_sheet_form.mask.label'),
        value: Type.MASK
      },
      {
        label: t('work_post_sheet_form.pants_overall.label'),
        value: Type.PANTS_OVERALL
      },
      {
        label: t('work_post_sheet_form.dust_jacket.label'),
        value: Type.DUST_JACKET
      },
      {
        label: t('work_post_sheet_form.hearing_protection.label'),
        value: Type.HEARING_PROTECTION
      },
      {
        label: t('work_post_sheet_form.safety_belt_harness.label'),
        value: Type.SAFETY_BELT_HARNESS
      },
      {
        label: t('work_post_sheet_form.gloves_mittens.label'),
        value: Type.GLOVES_MITTENS
      },
      {
        label: t('work_post_sheet_form.safety_shoes.label'),
        value: Type.SAFETY_SHOES
      },
      {
        label: t('work_post_sheet_form.helmet.label'),
        value: Type.HELMET
      },
      {
        label: t('work_post_sheet_form.ointments.label'),
        value: Type.OINTMENTS
      },
      {
        label: t('work_post_sheet_form.coat.label'),
        value: Type.COAT
      },
      {
        label: t('work_post_sheet_form.other_equipment.label'),
        value: Type.OTHER_EQUIPMENT
      }
    ],
    [t]
  )

  const requiredVaccinationsAndTestsOptions: CheckboxOption[] = useMemo(
    () => [
      {
        label: t('work_post_sheet_form.tetanus.label'),
        value: Type.TETANUS
      },
      {
        label: t('work_post_sheet_form.tuberculose.label'),
        value: Type.TUBERCULOSE
      },
      {
        label: t('work_post_sheet_form.hepatitis_b.label'),
        value: Type.HEPATITIS_B
      },
      {
        label: t('work_post_sheet_form.other_vaccinations_tests.label'),
        value: Type.OTHER_VACCINATIONS_TESTS
      }
    ],
    [t]
  )

  const healthRisksOptions: CheckboxOption[] = useMemo(
    () => [
      {
        label: t('work_post_sheet_form.safety_function.label'),
        value: Type.SAFETY_FUNCTION
      },
      {
        label: t(
          'work_post_sheet_form.function_with_increased_vigilance.label'
        ),
        value: Type.FUNCTION_WITH_INCREASED_VIGILANCE
      },
      {
        label: t('work_post_sheet_form.night_work.label'),
        value: Type.NIGHT_WORK
      },
      {
        label: t('work_post_sheet_form.handling_loads.label'),
        value: Type.HANDLING_LOADS
      },
      {
        label: t('work_post_sheet_form.shift_work.label'),
        value: Type.SHIFT_WORK
      },
      {
        label: t('work_post_sheet_form.chemical_agents.label'),
        value: Type.CHEMICAL_AGENTS
      },
      {
        label: t('work_post_sheet_form.biological_agents.label'),
        value: Type.BIOLOGICAL_AGENTS
      },
      {
        label: t('work_post_sheet_form.noise.label'),
        value: Type.NOISE
      },
      {
        label: t('work_post_sheet_form.ergonomic_load.label'),
        value: Type.ERGONOMIC_LOAD
      },
      {
        label: t('work_post_sheet_form.younger.label'),
        value: Type.YOUNGER
      }
    ],
    [t]
  )

  const getItemIndexByName = useCallback(
    (name: any) => {
      return values.items.findIndex(option => option.name === name)
    },
    [values.items]
  )

  const positionsWithWPSOptions = useMemo(
    () =>
      (positionsWithWPS || []).map(positionsWithWPS => ({
        label: positionsWithWPS.name,
        value: positionsWithWPS.id
      })),
    [positionsWithWPS]
  )

  const showCopyFromPositionButton = useMemo(
    () => positionsWithWPSOptions.length > 0,
    [positionsWithWPSOptions.length]
  )

  const handlePositionWithWPSChange = useCallback(
    (positionId: unknown | undefined) => {
      if (!positionId) {
        return resetForm()
      }

      const positionWithWPS = positionsWithWPS?.find(
        position => position.id === positionId
      )

      if (positionWithWPS) {
        setValues(prevValues => {
          return {
            ...prevValues,
            copyFromPositionId: positionId,
            ...wpsToWPSFormValues(positionWithWPS.workPostSheet)
          }
        })
      }
    },
    [positionsWithWPS, resetForm, setValues]
  )

  return (
    <>
      {showCopyFromPositionButton ? (
        <Form.Item
          name="copyFromPositionId"
          label={t('work_post_sheet_form.copyFromPositionId.label')}
        >
          <Select
            name="copyFromPositionId"
            placeholder={t('form.optional')}
            options={positionsWithWPSOptions}
            onChange={handlePositionWithWPSChange}
            showSearch
            allowClear
          />
        </Form.Item>
      ) : null}

      <Form.Item
        name="tasksToPerform"
        required
        label={t('work_post_sheet_form.tasksToPerform.label')}
        tooltip={t('work_post_sheet_form.tasksToPerform.hint')}
      >
        <Input
          name="tasksToPerform"
          placeholder={t('form.required')}
          disabled={disabledEditing}
        />
      </Form.Item>
      <Form.Item
        name="requiredQualifications"
        required
        label={t('work_post_sheet_form.requiredQualifications.label')}
        tooltip={t('work_post_sheet_form.requiredQualifications.hint')}
      >
        <Input
          name="requiredQualifications"
          placeholder={t('form.required')}
          disabled={disabledEditing}
        />
      </Form.Item>
      <Form.Item
        name="workEquipment"
        required
        label={t('work_post_sheet_form.workEquipment.label')}
        tooltip={t('work_post_sheet_form.workEquipment.hint')}
      >
        <Input
          name="workEquipment"
          placeholder={t('form.required')}
          disabled={disabledEditing}
        />
      </Form.Item>
      <Form.Item
        name="priorInstructions"
        required
        label={t('work_post_sheet_form.priorInstructions.label')}
        tooltip={t('work_post_sheet_form.priorInstructions.hint')}
      >
        <Input
          name="priorInstructions"
          placeholder={t('form.required')}
          disabled={disabledEditing}
        />
      </Form.Item>
      <Form.Item
        name="foreseeableTraining"
        required
        label={t('work_post_sheet_form.foreseeableTraining.label')}
        tooltip={t('work_post_sheet_form.foreseeableTraining.hint')}
      >
        <Input
          name="foreseeableTraining"
          placeholder={t('form.required')}
          disabled={disabledEditing}
        />
      </Form.Item>
      <Form.Item
        name="workstationLocation"
        required
        label={t('work_post_sheet_form.workstationLocation.label')}
        tooltip={t('work_post_sheet_form.workstationLocation.hint')}
      >
        <Input
          name="workstationLocation"
          placeholder={t('form.required')}
          disabled={disabledEditing}
        />
      </Form.Item>
      <Divider sectionSeparator />
      <BaseForm.Item
        label={t('work_post_sheet_form.group_1.label')}
        tooltip={t('work_post_sheet_form.group_1.hint')}
      >
        <Grid columns={2} gap={0} columnGap={16} marginTop={6}>
          {workClothesAndProtectiveEquipmentOptions.map(option => (
            <div key={getItemIndexByName(option.value)}>
              <Checkbox
                name={`items[${getItemIndexByName(option.value)}].checked`}
                disabled={disabledEditing}
                style={{ marginBottom: 8 }}
              >
                {option.label}
              </Checkbox>
              {values.items[getItemIndexByName(option.value)]?.checked && (
                <Input
                  name={`items[${getItemIndexByName(option.value)}].comment`}
                  disabled={disabledEditing}
                  style={{ marginBottom: 8 }}
                />
              )}
            </div>
          ))}
        </Grid>
      </BaseForm.Item>
      <BaseForm.Item label={t('work_post_sheet_form.group_2.label')}>
        <Grid columns={1} gap={0} columnGap={16} marginTop={6}>
          {requiredVaccinationsAndTestsOptions.map(option => (
            <div key={getItemIndexByName(option.value)}>
              <Checkbox
                name={`items[${getItemIndexByName(option.value)}].checked`}
                disabled={disabledEditing}
                style={{ marginBottom: 8 }}
              >
                {option.label}
              </Checkbox>
              {values.items[getItemIndexByName(option.value)]?.checked && (
                <Input
                  name={`items[${getItemIndexByName(option.value)}].comment`}
                  disabled={disabledEditing}
                  style={{ marginBottom: 8 }}
                />
              )}
            </div>
          ))}
        </Grid>
      </BaseForm.Item>
      <BaseForm.Item label={t('work_post_sheet_form.group_3.label')}>
        <Grid columns={1} gap={0} columnGap={16} marginTop={6}>
          {healthRisksOptions.map(option => (
            <div key={getItemIndexByName(option.value)}>
              <Checkbox
                name={`items[${getItemIndexByName(option.value)}].checked`}
                disabled={disabledEditing}
                style={{ marginBottom: 8 }}
              >
                {option.label}
                {i18n.exists(
                  `work_post_sheet_form.${option.value.toLocaleLowerCase()}.code`,
                  { ns: 'web_ui' }
                ) ? (
                  <BodyAlt
                    marginLeft={8}
                    color={theme.gray7}
                    letterSpacing={0}
                    fontWeight={FontWeight.italic}
                  >
                    —{' '}
                    {t(
                      `work_post_sheet_form.${option.value.toLocaleLowerCase()}.code`
                    )}
                  </BodyAlt>
                ) : null}
              </Checkbox>
              {values.items[getItemIndexByName(option.value)]?.checked && (
                <Input
                  name={`items[${getItemIndexByName(option.value)}].comment`}
                  disabled={disabledEditing}
                  style={{ marginBottom: 8 }}
                />
              )}
            </div>
          ))}
        </Grid>
      </BaseForm.Item>

      <Form.Item
        name="maternityProtectionMeasuresRequired"
        required
        label={t('work_post_sheet_form.group_4.label')}
        tooltip={t('work_post_sheet_form.group_4.tooltip')}
      >
        <Radio.Group
          name="maternityProtectionMeasuresRequired"
          disabled={disabledEditing}
        >
          <Radio name="maternityProtectionMeasuresRequired" value={true}>
            {t('work_post_sheet_form.group_4.options.yes')}
          </Radio>
          <Radio name="maternityProtectionMeasuresRequired" value={false}>
            {t('work_post_sheet_form.group_4.options.no')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      {values.maternityProtectionMeasuresRequired ? (
        <Grid columns={1} gap={0} columnGap={16} marginTop={6}>
          <Form.Item
            name={`items[${getItemIndexByName(
              Type.PREGNANCY_ADJUSTMENTS
            )}].comment`}
            label={t('work_post_sheet_form.pregnancy_adjustments.label')}
          >
            <Input
              name={`items[${getItemIndexByName(
                Type.PREGNANCY_ADJUSTMENTS
              )}].comment`}
              disabled={disabledEditing}
              allowClear={true}
            />
          </Form.Item>
          <Form.Item
            name={`items[${getItemIndexByName(
              Type.PREGNANCY_ADJUSTMENTS_DATE
            )}].comment`}
            label={t('work_post_sheet_form.pregnancy_adjustments_date.label')}
          >
            <DatePicker
              name={`items[${getItemIndexByName(
                Type.PREGNANCY_ADJUSTMENTS_DATE
              )}].comment`}
              disabled={disabledEditing}
              allowClear={true}
            />
          </Form.Item>
          <Form.Item
            name={`items[${getItemIndexByName(
              Type.BREASTFEEDING_ADJUSTMENTS
            )}].comment`}
            label={t('work_post_sheet_form.breastfeeding_adjustments.label')}
          >
            <Input
              name={`items[${getItemIndexByName(
                Type.BREASTFEEDING_ADJUSTMENTS
              )}].comment`}
              disabled={disabledEditing}
            />
          </Form.Item>
          <Form.Item
            name={`items[${getItemIndexByName(
              Type.BREASTFEEDING_ADJUSTEMENTS_DATE
            )}].comment`}
            label={t(
              'work_post_sheet_form.breastfeeding_adjustements_date.label'
            )}
          >
            <DatePicker
              name={`items[${getItemIndexByName(
                Type.BREASTFEEDING_ADJUSTEMENTS_DATE
              )}].comment`}
              disabled={disabledEditing}
            />
          </Form.Item>
        </Grid>
      ) : null}

      <BaseForm.Item
        label={t('work_post_sheet_form.group_5.label')}
        tooltip={t('work_post_sheet_form.group_5.tooltip')}
      >
        <Grid columns={1} gap={0} columnGap={16} marginTop={6}>
          <Form.Item
            name={`items[${getItemIndexByName(Type.ADVICE_DATE)}].comment`}
            label={t('work_post_sheet_form.advice_date.label')}
          >
            <DatePicker
              name={`items[${getItemIndexByName(Type.ADVICE_DATE)}].comment`}
              disabled={disabledEditing}
              allowClear={true}
            />
          </Form.Item>
          <div key={getItemIndexByName(Type.ADVICE_BY_COMITE_PWB)}>
            <Checkbox
              name={`items[${getItemIndexByName(
                Type.ADVICE_BY_COMITE_PWB
              )}].checked`}
              disabled={disabledEditing}
              style={{ marginBottom: 8 }}
            >
              {t('work_post_sheet_form.advice_by_comite_pwb.label')}
              {i18n.exists(
                `work_post_sheet_form.${Type.ADVICE_BY_COMITE_PWB.toLocaleLowerCase()}.code`,
                { ns: 'web_ui' }
              ) ? (
                <BodyAlt
                  marginLeft={8}
                  color={theme.gray7}
                  letterSpacing={0}
                  fontWeight={FontWeight.italic}
                >
                  —{' '}
                  {t(
                    `work_post_sheet_form.${Type.ADVICE_BY_COMITE_PWB.toLocaleLowerCase()}.code`
                  )}
                </BodyAlt>
              ) : null}
            </Checkbox>
          </div>
          <div key={getItemIndexByName(Type.ADVICE_BY_SYNDICATE_REP)}>
            <Checkbox
              name={`items[${getItemIndexByName(
                Type.ADVICE_BY_SYNDICATE_REP
              )}].checked`}
              disabled={disabledEditing}
              style={{ marginBottom: 8 }}
            >
              {t('work_post_sheet_form.advice_by_syndicate_rep.label')}
              {i18n.exists(
                `work_post_sheet_form.${Type.ADVICE_BY_SYNDICATE_REP.toLocaleLowerCase()}.code`,
                { ns: 'web_ui' }
              ) ? (
                <BodyAlt
                  marginLeft={8}
                  color={theme.gray7}
                  letterSpacing={0}
                  fontWeight={FontWeight.italic}
                >
                  —{' '}
                  {t(
                    `work_post_sheet_form.${Type.ADVICE_BY_SYNDICATE_REP.toLocaleLowerCase()}.code`
                  )}
                </BodyAlt>
              ) : null}
            </Checkbox>
          </div>
          <div
            key={getItemIndexByName(
              Type.ADVICE_BY_DIRECT_EMPLOYEE_PARTICIPATION
            )}
          >
            <Checkbox
              name={`items[${getItemIndexByName(
                Type.ADVICE_BY_DIRECT_EMPLOYEE_PARTICIPATION
              )}].checked`}
              disabled={disabledEditing}
              style={{ marginBottom: 8 }}
            >
              {t(
                'work_post_sheet_form.advice_by_direct_employee_participation.label'
              )}
              {i18n.exists(
                `work_post_sheet_form.${Type.ADVICE_BY_DIRECT_EMPLOYEE_PARTICIPATION.toLocaleLowerCase()}.code`,
                { ns: 'web_ui' }
              ) ? (
                <BodyAlt
                  marginLeft={8}
                  color={theme.gray7}
                  letterSpacing={0}
                  fontWeight={FontWeight.italic}
                >
                  —{' '}
                  {t(
                    `work_post_sheet_form.${Type.ADVICE_BY_DIRECT_EMPLOYEE_PARTICIPATION.toLocaleLowerCase()}.code`
                  )}
                </BodyAlt>
              ) : null}
            </Checkbox>
          </div>
          <div>
            <Form.Item
              name={`items[${getItemIndexByName(
                Type.ADVICE_DATE_LABOR_DOCTOR
              )}].comment`}
              label={t('work_post_sheet_form.advice_date_labor_doctor.label')}
            >
              <DatePicker
                name={`items[${getItemIndexByName(
                  Type.ADVICE_DATE_LABOR_DOCTOR
                )}].comment`}
                disabled={disabledEditing}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item
              name={`items[${getItemIndexByName(
                Type.ADVICE_DATE_INTERNAL_SERVICE
              )}].comment`}
              label={t(
                'work_post_sheet_form.advice_date_internal_service.label'
              )}
            >
              <DatePicker
                name={`items[${getItemIndexByName(
                  Type.ADVICE_DATE_INTERNAL_SERVICE
                )}].comment`}
                disabled={disabledEditing}
              />
            </Form.Item>
          </div>
        </Grid>
      </BaseForm.Item>
    </>
  )
}
