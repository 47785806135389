import { Routes, Route, Navigate } from 'react-router-dom'
import { Suspense, FC, useMemo } from 'react'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'
import { ProtectedRoute } from '../../components/ProtectedRoute'
import { useCompanyHasPayrollAccess } from '../../hooks/useCompanyHasPayrollAccess'
import { useCompanyHasSelectionAccess } from '../../hooks/useCompanyHasSelectionAccess'
import { useUserConstructFourDCompanyId } from '../../hooks/useUserConstructFourDCompanyId'
import { useUserHasConstructionWebAccess } from '../../hooks/useUserHasConstructionWebAccess'
import { useUserHasInterimWebAccess } from '../../hooks/useUserHasInterimWebAccess'
import { useUserHasWebAccess } from '../../hooks/useUserHasWebAccess'
import { useUserInterimFourDCompanyId } from '../../hooks/useUserInterimFourDCompanyId'
import { lazyOrReloadPage } from '../../utils/lazyOrReloadPage'

const InviteToPayrollTeam = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "InviteToPayrollTeam" */ './screens/InviteToPayrollTeam'
    )
)
const PayrollTeamList = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "PayrollTeamList" */ './screens/PayrollTeamList'
    )
)
const SelectionTeamList = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "SelectionTeamList" */ './screens/SelectionTeamList'
    )
)

export const TeamRoutes: FC = () => {
  const companyHasPayrollAccess = useCompanyHasPayrollAccess()
  const companyHasSelectionAccess = useCompanyHasSelectionAccess()
  const userHasWebAccess = useUserHasWebAccess()
  const userHasConstructionWebAccess = useUserHasConstructionWebAccess()
  const userHasInterimWebAccess = useUserHasInterimWebAccess()

  const userHasSelectionTeamAccess = useMemo(() => {
    return companyHasSelectionAccess && userHasWebAccess
  }, [companyHasSelectionAccess, userHasWebAccess])

  const construct4dCompanyId = useUserConstructFourDCompanyId()
  const interim4dCompanyId = useUserInterimFourDCompanyId()

  const userHasConstructAndInterimAccess = useMemo(
    () => userHasConstructionWebAccess && userHasInterimWebAccess,
    [userHasConstructionWebAccess, userHasInterimWebAccess]
  )

  return (
    <Suspense fallback={ProgressBarSuspenseFallback}>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={companyHasPayrollAccess ? 'payroll-team' : 'selection-team'}
              replace
            />
          }
        />

        <Route
          path="payroll-team"
          element={
            <ProtectedRoute isAllowed={companyHasPayrollAccess}>
              <PayrollTeamList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/selection-team/*"
          element={
            <ProtectedRoute isAllowed={userHasSelectionTeamAccess}>
              <Routes>
                {userHasConstructAndInterimAccess ? (
                  <Route
                    path="/"
                    element={
                      <Navigate
                        to={userHasInterimWebAccess ? 'interim' : 'construct'}
                        replace
                      />
                    }
                  />
                ) : (
                  <Route
                    path="/"
                    element={
                      <SelectionTeamList
                        fourDCompanyId={
                          construct4dCompanyId || interim4dCompanyId
                        }
                      />
                    }
                  />
                )}

                <Route
                  path="/interim"
                  element={
                    <ProtectedRoute isAllowed={userHasInterimWebAccess}>
                      <SelectionTeamList fourDCompanyId={interim4dCompanyId} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/construct"
                  element={
                    <ProtectedRoute isAllowed={userHasConstructionWebAccess}>
                      <SelectionTeamList
                        fourDCompanyId={construct4dCompanyId}
                      />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </ProtectedRoute>
          }
        />

        <Route
          path="invite-to-payroll-team"
          element={<InviteToPayrollTeam />}
        />
      </Routes>
    </Suspense>
  )
}
