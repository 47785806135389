import { useField } from 'formik'
import { FC, useCallback, useMemo } from 'react'
import { InputNumber, InputNumberProps } from '../../InputNumber'
import type { FormikFieldProps } from '../typings/FieldProps'
import { numberValueToVpassKMValue } from './utils/numberValueToVpassKMValue'

// VPASS format is: '0 km', '99 km', '9999 km'

export type VPASSKilometersInputProps = FormikFieldProps & InputNumberProps

export const VPASSKilometersInput: FC<VPASSKilometersInputProps> = ({
  min = 0,
  max = 9999,
  name,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  value: _valueProp,
  ...restProps
}) => {
  const [field, , helpers] = useField(name)
  const { value, onBlur } = field
  const { setValue } = helpers

  const handleBlur = useCallback(
    (event: any) => {
      onBlur(event)
      onBlurProp?.(event)
    },
    [onBlur, onBlurProp]
  )
  const handleChange = useCallback(
    (value: string | number | null) => {
      const stringValue = numberValueToVpassKMValue(value)
      setValue(stringValue)
    },
    [setValue]
  )

  const numberValue = useMemo(() => {
    return value ? Number(String(value).replace(' km', '').trim()) : undefined
  }, [value])

  return (
    <InputNumber
      key={name}
      name={name}
      value={numberValue}
      min={0}
      max={9999}
      step={1}
      onBlur={handleBlur}
      onChange={handleChange}
      controls={false}
      inputMode="numeric"
      type="number"
      precision={0}
      addonAfter="km"
      {...restProps}
    />
  )
}
