export const formikErrorsToArray = (errors: unknown): string[] => {
  if (typeof errors === 'string') {
    return [errors]
  }

  if (Array.isArray(errors)) {
    return errors
      .filter(error => !!error)
      .map(error => formikErrorsToArray(error))
      .flat()
  }

  if (errors && typeof errors === 'object') {
    return Object.entries(errors)
      .map(([_, value]) => {
        return formikErrorsToArray(value)
      })
      .flat()
  }

  return []
}
