import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

interface PreventionServicesEnum {
  [key: string]: string
}

export const usePreventionServiceOptions = (
  PreventionServicesEnum: PreventionServicesEnum
) => {
  const [t] = useTranslation('web_ui')

  return useMemo(
    () =>
      Object.values(PreventionServicesEnum)
        .sort(value => {
          // INTERNAL_ADVISOR should be the first option
          if (value === 'INTERNAL_ADVISOR') {
            return -1
          }
          // REQUESTED should be the first option for SR (this option is available only for SR)
          if (value === 'REQUESTED') {
            return -1
          }
          return 0
        })
        .map(value => ({
          label: t(`prevention_services.options.${value}`, `${value}`),
          value: value
        })),
    [PreventionServicesEnum, t]
  )
}
