import { gql } from '@apollo/client'

export const sessionSagaUserQuery = gql`
  query SessionSagaUserQuery {
    user {
      id
      company {
        id
        logo
      }
    }
  }
`
