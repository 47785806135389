import { ApolloLink } from '@apollo/client'
import apolloLogger from 'apollo-link-logger'
import { isApolloLoggerEnabled } from '../debug/logger'

export const loggerLink = new ApolloLink((operation, forward) => {
  if (!isApolloLoggerEnabled()) {
    return forward ? forward(operation) : null
  }
  return apolloLogger.request(operation, forward)
})
