import type { EmployeeCertificateInput } from '@vivaldis/graphql'
import { FC } from 'react'
import { Form } from '@vivaldis/antd-ui'
import { ActionBar } from './components/ActionBar'
import { FormContent } from './components/FormContent'
import { FormProvider } from './provider/FormProvider'
import { EmployeeCertificates } from './types/EmployeeCertificates'

interface EmployeeCertificateFormProps {
  certificateTypes: string[]
  employeeCertificate?: EmployeeCertificates | null
  onSubmit: (employeeCertificateInput: EmployeeCertificateInput) => Promise<any>
}

export const EmployeeCertificateForm: FC<EmployeeCertificateFormProps> = ({
  certificateTypes,
  employeeCertificate,
  onSubmit
}) => {
  return (
    <FormProvider
      certificateTypes={certificateTypes}
      employeeCertificate={employeeCertificate}
      onSubmit={onSubmit}
    >
      <Form name="employee-certificate-form">
        <FormContent
          certificateTypes={certificateTypes}
          employeeCertificate={employeeCertificate}
        />
        <ActionBar employeeCertificate={employeeCertificate} />
      </Form>
    </FormProvider>
  )
}
