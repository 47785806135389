import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

// See translations here: https://sealjobs.atlassian.net/wiki/spaces/SJ/pages/2069430287/Company+profile+-+Premiums

interface PremiumType {
  id: string
  name: string
}

export const usePremiumTypeOptions = (premiumTypes: PremiumType[]) => {
  const [t] = useTranslation('web_ui')

  return useMemo(
    () =>
      premiumTypes.map(premiumType => ({
        label: t(
          `premium_type.options.${premiumType.name}`,
          `${premiumType.name}`
        ),
        value: premiumType.id
      })),
    [premiumTypes, t]
  )
}
