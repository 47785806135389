import {
  FC,
  createContext,
  useContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  PropsWithChildren
} from 'react'

export enum OnboardingStep {
  Company,
  WorkerContractType,
  ClerkContractType,
  Overview
}

export interface StepsContextValue {
  currentStep: OnboardingStep
  setCurrentStep: Dispatch<SetStateAction<OnboardingStep>>
}

export const OnboardingStepContext = createContext<StepsContextValue>({
  currentStep: OnboardingStep.Company,
  setCurrentStep: () => {}
})

export function useOnboardingStep() {
  return useContext(OnboardingStepContext)
}

export const OnboardingStepProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const [currentStep, setCurrentStep] = useState<OnboardingStep>(
    OnboardingStep.Company
  )

  const context = useMemo(
    (): StepsContextValue => ({ currentStep, setCurrentStep }),
    [currentStep]
  )

  return (
    <OnboardingStepContext.Provider value={context}>
      {children}
    </OnboardingStepContext.Provider>
  )
}
