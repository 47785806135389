import styled from '@emotion/styled/base'
import { FC, memo, useEffect, useState } from 'react'
import { animated, config, useSpring } from 'react-spring'
import { ZIndex } from '../styles/zIndex'
import { useProgressBar } from './useProgressBar'

export const ProgressBar: FC = memo(
  () => {
    const progressBar = useProgressBar()

    const [showProgressBar, setShowProgressBar] = useState(false)

    useEffect(() => {
      return progressBar.listen(progressBar => {
        const isLoading = progressBar.loadingStatesCount > 0
        setShowProgressBar(isLoading)
      })
    }, [progressBar])

    const [animatedStyles] = useSpring(
      () => ({
        opacity: showProgressBar ? 1 : 0,
        x: showProgressBar ? 0.9 : 0, // 90% should be max value
        config: config.slow
      }),
      [showProgressBar]
    )

    return (
      <ProgressBarIndicator
        style={{
          opacity: animatedStyles.opacity.to({
            range: [0, 0.1, 0.2, 1],
            output: [0, 0.5, 0.9, 1]
          }),
          width: animatedStyles.x.to(x => `${x * 100}%`)
        }}
      />
    )
  },
  () => true
)

const ProgressBarIndicator = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.VivaldisYellow_yellow6};
  box-shadow:
    0 0 20px ${({ theme }) => theme.VivaldisYellow_yellow3},
    0 0 4px ${({ theme }) => theme.VivaldisYellow_yellow4};
  z-index: ${ZIndex.ProgressBar};
`
