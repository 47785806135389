import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const DangerCircleSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.5C7.313 3.5 3.5 7.313 3.5 12C3.5 16.687 7.313 20.5 12 20.5C16.687 20.5 20.5 16.687 20.5 12C20.5 7.313 16.687 3.5 12 3.5ZM12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22ZM11.9941 13.373C11.5801 13.373 11.2441 13.037 11.2441 12.623V8.20398C11.2441 7.78998 11.5801 7.45398 11.9941 7.45398C12.4081 7.45398 12.7441 7.78998 12.7441 8.20398V12.623C12.7441 13.037 12.4081 13.373 11.9941 13.373ZM10.9989 15.7959C10.9989 16.3489 11.4509 16.7959 12.0039 16.7959C12.5569 16.7959 13.0039 16.3489 13.0039 15.7959C13.0039 15.2429 12.5569 14.7959 12.0039 14.7959H11.9939C11.4419 14.7959 10.9989 15.2429 10.9989 15.7959Z"
    />
  </svg>
)

export const DangerCircle = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={DangerCircleSvg} {...props} ref={ref} />
)
