import styled from '@emotion/styled'
import { useBreakpoint } from '@vivaldis/antd-ui'
import type { Insets } from '@vivaldis/ui'
import { createElement } from '@vivaldis/common'
import { forwardRef, PropsWithChildren, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useIsInSideSheet } from '../SideSheets/hooks/useIsInSideSheet'
import { useScreenTitle } from './providers/ScreenTitleProvider'
import { ScreenProps } from './typings/ScreenProps'
import { LoadingState } from './components/LoadingState'
import { EmptyState } from './components/EmptyState'
import { ErrorState } from './components/ErrorState'

function RefForwardingScreenBase(
  {
    Component = ContentContainer,
    navigationBar,
    children,
    title,
    contentInset = '__DEFAULT__',
    ...props
  }: PropsWithChildren<ScreenProps>,
  ref: any
) {
  const { i18n } = useTranslation()
  const breakpoint = useBreakpoint()
  const isInSideSheet = useIsInSideSheet()

  const { setTitle } = useScreenTitle()

  const appName = 'VivaConnect'

  if (contentInset === '__DEFAULT__') {
    contentInset = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  }

  useEffect(() => {
    if (!isInSideSheet) {
      setTitle(title || null)
      return () => setTitle(null)
    }
  }, [isInSideSheet, setTitle, title])

  return (
    <>
      <Helmet titleTemplate={`%s - ${appName}`} defaultTitle={appName}>
        <html lang={i18n.language} />
        <title>{title}</title>
      </Helmet>
      {!breakpoint.lg || isInSideSheet ? createElement(navigationBar) : null}
      {createElement(
        Component,
        {
          ...props,
          insets: contentInset,
          ref
        },
        children
      )}
    </>
  )
}

export const Screen = Object.assign(forwardRef(RefForwardingScreenBase), {
  LoadingState,
  EmptyState,
  ErrorState
})

const ContentContainer = styled('section')<{ insets: Insets }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: ${({ insets: { top } }) =>
    typeof top === 'number' ? `${top}px` : '0px'};
  padding-bottom: ${({ insets: { bottom } }) =>
    typeof bottom === 'number' ? `${bottom}px` : '0px'};
  padding-left: ${({ insets: { left } }) =>
    typeof left === 'number' ? `${left}px` : '0px'};
  padding-right: ${({ insets: { right } }) =>
    typeof right === 'number' ? `${right}px` : '0px'};
`
