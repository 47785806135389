import { gql } from '@apollo/client'
import { companyFragment } from './CompanyFragment'
import { userPermissionFragment } from './UserPermissionFragment'

export const userFragment = gql`
  ${companyFragment}
  ${userPermissionFragment}

  fragment UserFragment on User {
    company {
      ...CompanyFragment
    }
    createdAt
    email
    firstName
    id
    lastName
    lastSeenAt
    mobile
    permissions {
      ...UserPermissionFragment
    }
    preferredLanguage
    telephone
    acceptedTermsAndConditionsAt
  }
`
