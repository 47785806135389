import { gql } from '@apollo/client'
import { employeeCertificateFragment } from './EmployeeCertificateFragment'
import { employeeEducationFragment } from './EmployeeEducationFragment'
import { employeeProfileFragment } from './EmployeeProfileFragment'
import { employeeWorkProfileFragment } from './EmployeeWorkProfileFragment'

export const employeeFragment = gql`
  ${employeeCertificateFragment}
  ${employeeEducationFragment}
  ${employeeProfileFragment}
  ${employeeWorkProfileFragment}

  fragment EmployeeFragment on Employee {
    createdAt
    educations {
      ...EmployeeEducationFragment
    }
    email
    employeeCertificates {
      ...EmployeeCertificateFragment
    }
    firstName
    gender
    id
    intentAgreementUrl
    isStudent
    lastName
    lastSeenAt
    mobile
    preferredLanguage
    profile {
      ...EmployeeProfileFragment
    }
    ssn
    studentAgreementUrl
    updatedAt
    workProfile {
      ...EmployeeWorkProfileFragment
    }
  }
`
