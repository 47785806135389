import styled from '@emotion/styled'
import { Table as AntdTable } from 'antd'
import hoistNonReactStatics from 'hoist-non-react-statics'

export type {
  TableProps,
  TablePaginationConfig,
  TableColumnGroupType,
  TableColumnType,
  TableColumnProps,
  TableColumnsType
} from 'antd'

export const StyledAntdTable = styled(AntdTable)`
  & .ant-table:not(.ant-table-bordered) {
    // all tables should have 2px width white border
    border: 2px solid #fff;

    // ============================ Header ============================
    & .ant-table-thead {
      > tr {
        > th {
          // for table header we use 600 instead of 500 (our custom property)
          font-weight: 600;
        }

        // for borderless table we don't want to have en extra header-cell divider in our design - https://joxi.ru/xAeVxdVUMgynKr
        > th:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
          ):not([colspan])::before,
        :where(.css-dev-only-do-not-override-g3rt44).ant-table-wrapper
          .ant-table-thead
          > tr
          > td:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
          ):not([colspan])::before {
          background-color: transparent;
        }
      }
    }

    // ============================= Body =============================
    & .ant-table-tbody {
      // CUSTOM STYLES: we don't need border bottom last table body row item
      > tr:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }
` as unknown as typeof AntdTable
export const Table = hoistNonReactStatics(StyledAntdTable, AntdTable)
