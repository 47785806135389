import { axios, type AxiosResponse } from '@vivaldis/common'
import { Prompt } from '@vivaldis/ui'
import { UserType } from '../typings/UserType'

export interface SignUpRequestUserData {
  first_name: string
  last_name: string
  email: string
  password: string
  tel?: string
  function?: string
  referral_code?: string
  type: UserType.User
}

export interface SignUpRequestEmployeeData {
  first_name: string
  last_name: string
  email: string
  password: string
  password_confirmation: string
  accepted_terms: boolean
  type: UserType.Employee
}

export type SignUpRequestData =
  | SignUpRequestEmployeeData
  | SignUpRequestUserData

export interface SignUpResponse {
  authentication_token: string
  email: string
}

export const signUp = async (
  data: SignUpRequestData
): Promise<AxiosResponse<SignUpResponse> | undefined> => {
  let url: string
  let payload: any

  switch (data.type) {
    case UserType.Employee:
      url = '/employees'
      payload = {
        employee: data,
        get_token: 'true'
      }
      break
    case UserType.User:
      url = '/users'
      payload = {
        user: data,
        get_token: 'true'
      }

      break
  }

  try {
    return await axios().post(url!, payload)
  } catch (error: any) {
    if (
      error &&
      error.response &&
      error.response.status.toString().match(/^5.*/)
    ) {
      setTimeout(() => Prompt.serviceUnavailablePrompt(), 0)
      return undefined
    } else if (error.response) {
      throw error
    } else if (error.request) {
      setTimeout(() => Prompt.serviceUnavailablePrompt(), 0)
      return undefined
    } else {
      setTimeout(() => Prompt.unknownErrorPrompt(), 0)
      return undefined
    }
  }
}
