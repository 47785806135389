import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const PaperDownloadSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7312 7.48424L14.726 4.35317V5.65924C14.726 6.66324 15.542 7.48124 16.546 7.48424H17.7312ZM13.8446 2.01172H7.58831H7.57031C5.10831 2.01172 3.06031 4.00972 3.00131 6.49072V17.2117C2.94731 19.7237 4.94731 21.8117 7.45931 21.8657H7.58531H15.5993C18.1123 21.7757 20.0683 19.7367 20.0523 17.2277V8.03772C20.0523 7.84472 19.9773 7.65772 19.8443 7.51872L14.7783 2.24172C14.6363 2.09472 14.4413 2.01172 14.2373 2.01172H14.1073C14.0646 2.00418 14.0207 2.00024 13.976 2.00024C13.9312 2.00024 13.8873 2.00418 13.8446 2.01172ZM13.226 3.51172V5.65924C13.226 7.48724 14.713 8.97924 16.544 8.98424H18.5523V17.2327C18.5633 18.9287 17.2423 20.3057 15.5733 20.3657H7.49031C5.80531 20.3287 4.46531 18.9287 4.50131 17.2277V6.50872C4.54031 4.85372 5.91631 3.51172 7.57331 3.51172H7.58531H13.226ZM10.3927 14.1345L9.32874 13.0652C9.03674 12.7712 8.56174 12.7712 8.26774 13.0632C7.97474 13.3552 7.97374 13.8302 8.26574 14.1242L10.5957 16.4631C10.7325 16.609 10.927 16.7002 11.1427 16.7002C11.3684 16.7002 11.571 16.6003 11.7085 16.4423L14.0177 14.1242C14.3097 13.8302 14.3087 13.3552 14.0157 13.0632C13.7217 12.7712 13.2467 12.7712 12.9547 13.0652L11.8927 14.1325V9.90918C11.8927 9.49518 11.5567 9.15918 11.1427 9.15918C10.7287 9.15918 10.3927 9.49518 10.3927 9.90918V14.1345Z"
    />
  </svg>
)

export const PaperDownload = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={PaperDownloadSvg} {...props} ref={ref} />
)
