// VPASS format is: '100,00 %', '0,12 %', '0,00 %', '299,99 %', '040,00 %'
export const numberValueToVpassPercentageValue = (
  value: string | number | null
) => {
  if (value === null) {
    return '00,00 %'
  }
  if (typeof value === 'number') {
    return value.toFixed(2).toString().replace('.', ',').concat(' ', '%')
  }
  return '00,00 %'
}
