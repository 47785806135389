import { VCA_CERTIFICATE_TYPE } from '@vivaldis/ui'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Radio } from '@vivaldis/antd-ui'
import { useForm } from '../../../providers/FormProvider'

export const VCACertificationRequired: FC = () => {
  const { t } = useTranslation('web_ui')

  const { values, setFieldValue } = useForm()

  const handleVcaCertificationRequiredChange = useCallback(
    (e: RadioChangeEvent) => {
      const vcaCertificationRequired = e.target.value

      // add VCA_CERTIFICATE_TYPE when user select vcaCertificationRequired = true
      if (
        vcaCertificationRequired &&
        !values.certificateTypes.includes(VCA_CERTIFICATE_TYPE)
      ) {
        setFieldValue('certificateTypes', [
          ...values.certificateTypes,
          VCA_CERTIFICATE_TYPE
        ])
      }

      // remove VCA_CERTIFICATE_TYPE when user select vcaCertificationRequired = false
      if (
        !vcaCertificationRequired &&
        values.certificateTypes.includes(VCA_CERTIFICATE_TYPE)
      ) {
        setFieldValue('certificateTypes', [
          ...values.certificateTypes.filter(
            certificateType => certificateType !== VCA_CERTIFICATE_TYPE
          )
        ])
      }

      // Note: we don't need to update "vcaCertificationRequired" field because Radio.Group do it automatically
    },
    [setFieldValue, values.certificateTypes]
  )

  return (
    <>
      <Form.Item
        name="vcaCertificationRequired"
        required
        label={t('position_form.step2.vca_certification_required.label')}
        tooltip={t('position_form.step2.vca_certification_required.tooltip')}
      >
        <Radio.Group
          name="vcaCertificationRequired"
          onChange={handleVcaCertificationRequiredChange}
        >
          <Radio name="vcaCertificationRequired" value={true}>
            {t('position_form.step2.vca_certification_required.options.yes')}
          </Radio>
          <Radio name="vcaCertificationRequired" value={false}>
            {t('position_form.step2.vca_certification_required.options.no')}
          </Radio>
        </Radio.Group>
      </Form.Item>
      {values.vcaCertificationRequired ? (
        <Form.Item
          name="vcaCertificationRequiredConfirmation"
          required
          label={t(
            'position_form.step2.vca_certification_required_confirmation.label'
          )}
          tooltip={t(
            'position_form.step2.vca_certification_required_confirmation.tooltip'
          )}
        >
          <Radio.Group name="vcaCertificationRequiredConfirmation">
            <Radio name="vcaCertificationRequiredConfirmation" value={true}>
              {t(
                'position_form.step2.vca_certification_required_confirmation.options.yes'
              )}
            </Radio>
            <Radio name="vcaCertificationRequiredConfirmation" value={false}>
              {t(
                'position_form.step2.vca_certification_required_confirmation.options.no'
              )}
            </Radio>
          </Radio.Group>
        </Form.Item>
      ) : null}
    </>
  )
}
