import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const ArrowLeftSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8312 18.8279C11.1235 18.5344 11.1225 18.0595 10.8289 17.7673L6.06543 13.0243H19.25L19.3517 13.0174C19.7178 12.9678 20 12.654 20 12.2743C20 11.8601 19.6642 11.5243 19.25 11.5243H6.06675L10.829 6.78118L10.9018 6.69722C11.1202 6.40406 11.0969 5.98734 10.8312 5.72053C10.5389 5.42703 10.064 5.42604 9.77052 5.71833L3.73292 11.731C3.68164 11.7798 3.63727 11.8358 3.60142 11.8973C3.43376 12.1849 3.47348 12.5602 3.72057 12.8062L9.77057 18.8302L9.85484 18.9027C10.1489 19.1199 10.5655 19.0948 10.8312 18.8279Z"
    />
  </svg>
)

export const ArrowLeft = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={ArrowLeftSvg} {...props} ref={ref} />
)
