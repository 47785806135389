import { action, ActionType } from 'typesafe-actions'
import { VerifyOTPResponse } from '../api/verifyOTP'
import { SessionData } from '../typings/SessionData'
import { UserType } from '../typings/UserType'
import { SignInResponse } from '../api/signIn'
import { SignUpRequestData, SignUpResponse } from '../api/signUp'
import { EmployeeFragment, UserFragment } from '@vivaldis/graphql'

export enum SessionActionType {
  Init = '@@Session/INIT',
  InitComplete = '@@Session/INIT_COMPLETE',
  Update = '@@Session/UPDATE',
  UpdateUser = '@@Session/UPDATE_USER',
  SignIn = '@@Session/SIGN_IN',
  SignInError = '@@Session/SIGN_IN_ERROR',
  SignInSuccess = '@@Session/SIGN_IN_SUCCESS',
  SignUp = '@@Session/SIGN_UP',
  SignUpError = '@@Session/SIGN_UP_ERROR',
  SignUpSuccess = '@@Session/SIGN_UP_SUCCESS',
  SignOut = '@@Session/SIGN_OUT',
  SignOutCredentials = '@@Session/SIGN_OUT_CREDENTIALS',
  SignOutComplete = '@@Session/SIGN_OUT_COMPLETE',
  Invalidate = '@@Session/INVALIDATE',
  InvalidateComplete = '@@Session/INVALIDATE_COMPLETE',
  VerifyOTP = '@@Session/VERIFY_OTP',
  VerifyOTPError = '@@Session/VERIFY_OTP_ERROR',
  VerifyOTPSuccess = '@@Session/VERIFY_OTP_SUCCESS'
}

export const init = (payload?: Partial<SessionData>) =>
  action(SessionActionType.Init, payload)

export const initComplete = (payload: {
  employee?: EmployeeFragment
  user?: UserFragment
}) => action(SessionActionType.InitComplete, payload)

export const update = (payload: Partial<SessionData>) =>
  action(SessionActionType.Update, payload)

export const updateUser = (payload: UserFragment) =>
  action(SessionActionType.UpdateUser, payload)

export const signIn = (
  payload: {
    email: string
    password: string
    type: UserType
  },
  meta?: {
    onSuccessSignIn?: () => void
    onFailedSignIn?: (error: unknown) => void
  }
) => action(SessionActionType.SignIn, payload, meta)

export const signInSuccess = (payload: SignInResponse) =>
  action(SessionActionType.SignInSuccess, payload)

export const signInError = (error?: any) =>
  action(SessionActionType.SignInError, { error })

export const signUp = (payload: SignUpRequestData) =>
  action(SessionActionType.SignUp, payload)

export const signUpSuccess = (payload: SignUpResponse) =>
  action(SessionActionType.SignUpSuccess, payload)

export const signUpError = (error: any) =>
  action(SessionActionType.SignUpError, { error })

export const signOut = () => action(SessionActionType.SignOut)

export const signOutCredentials = () =>
  action(SessionActionType.SignOutCredentials)

export const signOutComplete = () => action(SessionActionType.SignOutComplete)

export const invalidate = () => action(SessionActionType.Invalidate)

export const invalidateComplete = () =>
  action(SessionActionType.InvalidateComplete)

export const verifyOTP = (
  payload: {
    otp_verification_code: string
    otp_verification_token: string
    email: string
    type: UserType
  },
  meta?: {
    onSuccessVerifyOTP?: () => void
    onFailedVerifyOTP?: (error: unknown) => void
  }
) => action(SessionActionType.VerifyOTP, payload, meta)

export const verifyOTPSuccess = (payload: VerifyOTPResponse) =>
  action(SessionActionType.VerifyOTPSuccess, payload)

export const verifyOTPError = (error?: any) =>
  action(SessionActionType.VerifyOTPError, { error })

export type SessionAction = ActionType<{
  init: typeof init
  initComplete: typeof initComplete
  update: typeof update
  updateUser: typeof updateUser
  signIn: typeof signIn
  signInSuccess: typeof signInSuccess
  signInError: typeof signInError
  signUp: typeof signUp
  signUpSuccess: typeof signUpSuccess
  signUpError: typeof signUpError
  signOut: typeof signOut
  signOutCredentials: typeof signOutCredentials
  signOutComplete: typeof signOutComplete
  invalidate: typeof invalidate
  invalidateComplete: typeof invalidateComplete
  verifyOTP: typeof verifyOTP
  verifyOTPSuccess: typeof verifyOTPSuccess
  verifyOTPError: typeof verifyOTPError
}>
