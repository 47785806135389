export interface Country {
  id: string
  nameEn: string
  nameNl: string
  nameFr: string
  eeaMember?: boolean
}

export const countrySelector = (
  id: string,
  countries?: Country[]
): Country | undefined => {
  if (countries && countries.length) {
    const country = countries.find(c => c.id === id)
    if (country) return country
  }
  return undefined
}

export const localizedCountryNameSelector = (
  locale: string,
  country?: Country
): string => {
  if (country) {
    const fallbackLocale =
      country?.nameNl || country?.nameEn || country?.nameFr || ''

    if (country.nameEn && locale === 'en') return country.nameEn
    else if (country.nameNl && locale === 'nl') return country.nameNl
    else if (country.nameFr && locale === 'fr') return country.nameFr
    return fallbackLocale
  }
  return ''
}
