import { useBreakpoint } from '@vivaldis/antd-ui'
import { useNavigate as useNavigateBase } from 'react-router-dom'
import { useCallback, useContext } from 'react'
import { LinkProps } from 'react-router-dom'
import { useSideSheetsNavigator } from '../SideSheets/hooks/useSideSheetsNavigator'
import { AddEntryContext } from '../goBackTo/context/AddEntryContext'

export function useNavigate(): ({
  to,
  replace,
  state,
  navigate,
  openInSideSheet,
  goBackTo
}: {
  to?: LinkProps['to']
  replace?: LinkProps['replace']
  state?: LinkProps['state']
  navigate?: boolean
  openInSideSheet?: boolean
  goBackTo?: string | boolean
}) => void {
  const breakpoint = useBreakpoint()
  const navigate = useNavigateBase()
  const { push, replace: replaceFunc } = useSideSheetsNavigator()
  const addGoBackToEntry = useContext(AddEntryContext)

  return useCallback(
    ({
      to,
      replace,
      state,
      openInSideSheet,
      goBackTo
    }: {
      to?: LinkProps['to']
      replace?: LinkProps['replace']
      state?: LinkProps['state']
      openInSideSheet?: boolean
      goBackTo?: string | boolean
    }) => {
      const goBackToRef = `${window.location.pathname}${window.location.search}`
      if (to && openInSideSheet && breakpoint.lg) {
        if (replace) replaceFunc(to, state)
        else push(to, state)
      } else if (to) {
        navigate(to, { state, replace })
        if (goBackTo) {
          const finalGoBackTo = goBackTo === true ? goBackToRef : goBackTo
          addGoBackToEntry({
            pathname: finalGoBackTo,
            key: window.history.state.key
          })
        }
      }
    },
    [addGoBackToEntry, breakpoint.lg, navigate, push, replaceFunc]
  )
}
