import { ConfigProvider as AntdConfigProvider } from 'antd'
import type { ConfigProviderProps } from 'antd/es/config-provider'
import type { ThemeConfig } from 'antd/es/config-provider/context'
import enUS from 'antd/locale/en_US'
import frBE from 'antd/locale/fr_BE'
import nlBE from 'antd/locale/nl_BE'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { VivaldisTheme } from '@vivaldis/ui'

export const ConfigProvider: FC<ConfigProviderProps> = ({ ...props }) => {
  const [, { language }] = useTranslation()

  const locale = useMemo(() => {
    if (language === 'nl') {
      return nlBE
    }
    if (language === 'fr') {
      return frBE
    }
    return enUS
  }, [language])

  const theme: ThemeConfig = useMemo(
    () => ({
      cssVar: true,
      token: {
        colorPrimary: VivaldisTheme.primaryColor,
        colorLink: VivaldisTheme.primaryColor,
        colorLinkHover: '#1fbf94',
        colorLinkActive: '#008c70',
        colorBgLayout: VivaldisTheme.extraBackground1,
        fontFamily: "'Open Sans', sans-serif", //  default is: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
        colorTextBase: VivaldisTheme.gray9,
        colorText: VivaldisTheme.gray9,
        borderRadius: 8
      },
      components: {
        Avatar: {
          lineWidth: 0 // border width = 0
        },
        Badge: {},
        Button: {
          colorText: VivaldisTheme.primaryColor
        },
        Checkbox: {
          borderRadiusSM: 2,
          lineWidth: 2
        },
        Divider: {
          colorText: VivaldisTheme.gray5,
          marginLG: 16,
          colorSplit: VivaldisTheme.gray5
        },
        Form: {
          colorTextHeading: VivaldisTheme.gray7,
          paddingXS: 4
        },
        Layout: {
          headerBg: VivaldisTheme.gray1, // it's "white" color
          controlHeight: 72 / 2,
          controlHeightLG: 24 / 1.25
        },
        Menu: {
          fontSize: 16,
          controlHeightLG: 48,
          marginXXS: 8
        },
        Table: {
          borderRadiusLG: 6
        }
      }
    }),
    []
  )

  return <AntdConfigProvider locale={locale} theme={theme} {...props} />
}
