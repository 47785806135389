import styled from '@emotion/styled'
import { Steps as AntdSteps } from 'antd'
export type { StepProps, StepsProps } from 'antd'

const StyledAntdSteps = styled(AntdSteps)`
  // headers should be semi-bold according to our design
  & .ant-steps-item {
    font-weight: 600;
  }
`

// should we do it with hoist-non-react-statics ?
export const Steps = Object.assign({ ...AntdSteps }, StyledAntdSteps)
