import { useGenderOptions } from '@vivaldis/ui'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, Form, Input, Select, Typography } from '@vivaldis/antd-ui'
import {
  countrySelector,
  localizedCountryNameSelector,
  NONE_STUDENT_DEFAULT_TAX_CODE,
  STUDENT_DEFAULT_TAX_CODE,
  useLocale
} from '@vivaldis/common'
import { Grid } from '../../../../../Grid'
import { useForm } from '../../../../provider/FormProvider'
import { CountryType } from '../../../../types/CountryType'

interface Props {
  countries: CountryType[]
}

export const GeneralInformation: FC<Props> = ({ countries }) => {
  const [t] = useTranslation('web_ui')

  const { values, setFieldValue } = useForm()

  const locale = useLocale()

  const genderOptions = useGenderOptions()

  const studentOptions: any[] = useMemo(
    () => [
      {
        label: t('edit_employee_profile_form.is_student.options.yes'),
        value: true
      },
      {
        label: t('edit_employee_profile_form.is_student.options.no'),
        value: false
      }
    ],
    [t]
  )

  const countryOptions = useMemo(
    () =>
      countries
        .map(country => ({
          label: localizedCountryNameSelector(locale, country),
          value: country.id
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [countries, locale]
  )

  const nationalityOptions = useMemo(
    () =>
      countries
        .map(country => ({
          label: country.nationality,
          value: country.id
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [countries]
  )

  const handleIsStudentChange = useCallback(
    (isStudent: unknown) => {
      setFieldValue(
        'taxCode',
        isStudent ? STUDENT_DEFAULT_TAX_CODE : NONE_STUDENT_DEFAULT_TAX_CODE
      )
    },
    [setFieldValue]
  )

  const showBirthDateAndGenderFields = useMemo(() => {
    if (!values.nationalityCountryId) {
      return false
    }

    const isBelgium =
      countrySelector(values.nationalityCountryId, countries)?.nameEn ===
      'Belgium'

    return !isBelgium
  }, [countries, values.nationalityCountryId])

  return (
    <>
      <Typography.Title level={5} marginBottom={16}>
        {t('edit_employee_profile_form.general_section_header')}
      </Typography.Title>

      <Grid columns={2} gap={0} columnGap={16} marginTop={6}>
        <Form.Item
          name="firstName"
          required
          label={t('edit_employee_profile_form.first_name.label')}
        >
          <Input
            name="firstName"
            placeholder={t('form.required')}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          required
          label={t('edit_employee_profile_form.last_name.label')}
        >
          <Input
            name="lastName"
            placeholder={t('form.required')}
            autoComplete="off"
          />
        </Form.Item>
      </Grid>

      <Grid columns={2} gap={0} columnGap={16} marginTop={6}>
        <Form.Item
          name="isStudent"
          required
          label={t('edit_employee_profile_form.is_student.label')}
        >
          <Select
            name="isStudent"
            placeholder={t('form.required')}
            options={studentOptions}
            onChange={handleIsStudentChange}
            showSearch={false}
          />
        </Form.Item>
        <Form.Item
          name="nationalityCountryId"
          required
          label={t('edit_employee_profile_form.nationality_country_id.label')}
        >
          <Select
            name="nationalityCountryId"
            placeholder={t('form.required')}
            options={nationalityOptions}
            showSearch
            autoComplete="chrome-off-employee-nationality"
          />
        </Form.Item>
      </Grid>

      <Grid columns={2} gap={0} columnGap={16} marginTop={6}>
        <Form.Item
          name="placeOfBirth"
          required
          label={t('edit_employee_profile_form.place_of_birth.label')}
        >
          <Input
            name="placeOfBirth"
            placeholder={t('form.required')}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="countryOfBirthId"
          required
          label={t('edit_employee_profile_form.country_of_birth_id.label')}
        >
          <Select
            name="countryOfBirthId"
            placeholder={t('form.required')}
            options={countryOptions}
            showSearch
            autoComplete="chrome-off-employee-country"
          />
        </Form.Item>
      </Grid>
      {showBirthDateAndGenderFields ? (
        <Grid columns={2} gap={0} columnGap={16} marginTop={6}>
          <Form.Item
            name="birthdate"
            required
            label={t('edit_employee_profile_form.birthdate.label')}
          >
            <DatePicker
              name="birthdate"
              format="dd-MM-yyyy"
              placeholder={t('form.required')}
              style={{ width: '100%' }}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="gender"
            required
            label={t('edit_employee_profile_form.gender.label')}
          >
            <Select
              name="gender"
              placeholder={t('form.required')}
              options={genderOptions}
              showSearch={false}
            />
          </Form.Item>
        </Grid>
      ) : null}
    </>
  )
}
