interface Country {
  eeaMember: boolean
}

interface Data {
  nationalityCountry: Country | null
}

export function doesEmployeeNeedWorkPermit(data: Data): boolean {
  return !(data.nationalityCountry && data.nationalityCountry.eeaMember)
}
