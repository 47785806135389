import { useBreakpoint } from '@vivaldis/antd-ui'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Suspense, FC } from 'react'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'
import { lazyOrReloadPage } from '../../utils/lazyOrReloadPage'

const Profile = lazyOrReloadPage(
  () => import(/* webpackChunkName: "Profile" */ './screens/Profile')
)
const Company = lazyOrReloadPage(
  () => import(/* webpackChunkName: "Company" */ './screens/Company')
)
const CompanyUsers = lazyOrReloadPage(
  () => import(/* webpackChunkName: "CompanyUsers" */ './screens/CompanyUsers')
)
const EmailSettings = lazyOrReloadPage(
  () =>
    import(/* webpackChunkName: "EmailSettings" */ './screens/EmailSettings')
)
const Offers = lazyOrReloadPage(
  () => import(/* webpackChunkName: "Offers" */ './screens/Offers')
)
const AddCompanyUser = lazyOrReloadPage(
  () =>
    import(/* webpackChunkName: "AddCompanyUser" */ './screens/AddCompanyUser')
)
const Settings = lazyOrReloadPage(
  () => import(/* webpackChunkName: "Settings" */ './screens/Settings')
)
const ViewCompanyUser = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "ViewCompanyUser" */ './screens/ViewCompanyUser'
    )
)

export const SettingsRoutes: FC = () => {
  const breakpoint = useBreakpoint()

  return (
    <Suspense fallback={ProgressBarSuspenseFallback}>
      <Routes>
        <Route path="/" element={breakpoint.lg ? <Profile /> : <Settings />} />
        <Route path="profile" element={<Profile />} />
        <Route path="company" element={<Company />} />
        <Route path="company-users" element={<CompanyUsers />} />
        <Route path="email-settings" element={<EmailSettings />} />
        {/* Redirect: In old mails we use "/settings/mail-management" */}
        <Route
          path="mail-management"
          element={<Navigate to="/settings/email-settings" replace />}
        />
        <Route path="offers" element={<Offers />} />
        <Route
          path="company-users/add-company-user"
          element={<AddCompanyUser />}
        />
        <Route path="company-users/:id" element={<ViewCompanyUser />} />
      </Routes>
    </Suspense>
  )
}
