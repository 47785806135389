import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'
import { MarginProps, margin } from '../../styles/margin'

export type ListViewProps = HTMLAttributes<HTMLUListElement> & MarginProps

export const ListView = styled('ul')<ListViewProps>`
  margin: 0;
  padding: 0;
  min-height: min-content;
  ${props => margin(props)}
`
