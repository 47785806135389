import { InputNumber as AntdInputNumber } from 'antd'
import type { InputNumberProps as AntdInputNumberProps } from 'antd'
import { forwardRef } from 'react'

export interface InputNumberProps extends AntdInputNumberProps {}

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
  (props, ref) => {
    return <AntdInputNumber ref={ref} {...props} />
  }
)
