import { update as updateSession } from '@vivaldis/session'
import { getReduxStore, UserType } from '@vivaldis/common'
import {
  updateUserMutation as updateUserMutationBase,
  UpdateUserMutation,
  UpdateUserMutationVariables
} from '@vivaldis/graphql'
import { ApolloClient, MutationOptions } from '@apollo/client'

export async function updateUserMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<UpdateUserMutation, UpdateUserMutationVariables>,
    'mutation'
  >
) {
  return updateUserMutationBase(apolloClient, options).then(res => {
    const email = options.variables?.input.user.email
    if (email) {
      const store = getReduxStore()
      store.dispatch(updateSession({ email, type: UserType.User }))
    }
    return res
  })
}
