import { FC, useEffect, useState } from 'react'
import { useUser } from '@vivaldis/session'

declare const window: any

export const ClarityTrackingConfig: FC = () => {
  const [isClarityScriptReady, setIsClarityScriptReady] =
    useState<boolean>(false)

  const userId = useUser()?.id

  // check if Clarity script is ready
  useEffect(() => {
    if (!isClarityScriptReady) {
      const intervalId = setInterval(() => {
        // we can check if .v available (version) to be sure that reals Clarity script is ready. See https://github.com/microsoft/clarity/blob/9866e4ba9450f7d3681ff10d20ea9e611834e00d/packages/clarity-js/src/queue.ts#L27
        if (window?.clarity && window.clarity?.v) {
          setIsClarityScriptReady(true)
        }
      }, 500)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [isClarityScriptReady])

  // set custom user ID in Clarity
  useEffect(() => {
    if (isClarityScriptReady && userId) {
      const claritySessionId = null
      const clarityPageId = null
      const clarityUserHint = userId
      window.clarity(
        'identify',
        userId,
        claritySessionId,
        clarityPageId,
        clarityUserHint
      )
    }
  }, [isClarityScriptReady, userId])

  return null
}
