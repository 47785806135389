import {
  countrySelector,
  doesEmployeeNeedBIC,
  NONE_STUDENT_DEFAULT_TAX_CODE,
  STUDENT_DEFAULT_TAX_CODE,
  validators
} from '@vivaldis/common'
import { EmployeeInput } from '@vivaldis/graphql'
import { GendersEnum, MaritalStatesEnum } from '@vivaldis/session'
import { endOfDay, isBefore, startOfDay } from 'date-fns'
import {
  Formik,
  FormikContextType,
  FormikErrors,
  FormikProps,
  FormikHelpers,
  useFormikContext
} from 'formik'
import { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { CountryType } from '../types/CountryType'
import { employeeFormValuesToEmployeeInput } from '../utils/employeeFormValuesToEmployeeInput'

export interface EditEmployeeProfileFormValues {
  email: string
  firstName: string
  lastName: string
  ssn: string
  gender: GendersEnum | null
  mobile: string | null
  isStudent: boolean

  // -> profile fields
  birthdate: Date | null
  placeOfBirth: string | null
  street: string | null
  streetNumber: string | null
  buildingNumber: string | null
  zip: string | null
  city: string | null
  countryId: string | null
  countryOfBirthId: string | null
  nationalityCountryId: string | null
  idCardNumber: string | null
  idCardExpiresOn: Date | null
  idCardFrontPicture: string | null
  idCardBackPicture: string | null

  // -> workProfile fields
  bankAccount: string | null
  bic: string | null
  workPermitExpiresOn: Date | null
  residencePermitExpiresOn: Date | null
  residencePermitCode: string | null
  taxCode: string | null
  maritalState: MaritalStatesEnum | null
  maritalDate: Date | null
  childrenNumber: number | null
  childrenWithDisabilitiesNumber: number | null
  dependentPersonsNumber: number | null
  dependentPersonsWithDisabilitiesNumber: number | null
  involuntaryPartTime?: boolean | null
  workPermitFrontPicture: string | null
  workPermitBackPicture: string | null
  residencePermitFrontPicture: string | null
  residencePermitBackPicture: string | null
}

export function useForm(): FormikContextType<EditEmployeeProfileFormValues> {
  return useFormikContext()
}

export interface EmployeeFormProviderProps {
  countries: CountryType[]
  // We allow saving an empty fields when an employee is not contract ready. In most cases, it's when U initially fill-in information instead of E. But we don't want to allow U to remove valid information
  isEmployeeContractReady: boolean
  onSubmit: (
    employeeInput: EmployeeInput,
    formikHelpers: FormikHelpers<EditEmployeeProfileFormValues>
  ) => Promise<any>
  initialValues?: Partial<
    FormikProps<EditEmployeeProfileFormValues>['initialValues']
  >
}

export const FormProvider: FC<PropsWithChildren<EmployeeFormProviderProps>> = ({
  countries,
  isEmployeeContractReady,
  onSubmit,
  initialValues,
  children
}) => {
  const [t] = useTranslation('web_ui')

  const defaultTaxCode = useMemo(() => {
    const isStudent = initialValues?.isStudent || false
    // The tax should be default (and mandatory) 11,11% for students
    // The tax should be default 18.8% for non-students
    return initialValues?.taxCode
      ? initialValues?.taxCode
      : isStudent
        ? STUDENT_DEFAULT_TAX_CODE
        : NONE_STUDENT_DEFAULT_TAX_CODE
  }, [initialValues?.isStudent, initialValues?.taxCode])

  const formInitialValues: EditEmployeeProfileFormValues = useMemo(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
      ssn: '',
      gender: null,
      mobile: null,
      isStudent: false,
      // -> profile fields
      birthdate: null,
      placeOfBirth: null,
      street: null,
      streetNumber: null,
      buildingNumber: null,
      zip: null,
      city: null,
      countryId: null,
      countryOfBirthId: null,
      nationalityCountryId: null,
      idCardNumber: null,
      idCardExpiresOn: null,
      idCardFrontPicture: null,
      idCardBackPicture: null,
      // -> workProfile fields
      bankAccount: null,
      bic: null,
      workPermitExpiresOn: null,
      residencePermitExpiresOn: null,
      residencePermitCode: null,
      // taxCode: null,
      maritalState: null,
      maritalDate: null,
      childrenNumber: null,
      childrenWithDisabilitiesNumber: null,
      dependentPersonsNumber: null,
      dependentPersonsWithDisabilitiesNumber: null,
      // involuntaryPartTime: null,
      workPermitFrontPicture: null,
      workPermitBackPicture: null,
      residencePermitFrontPicture: null,
      residencePermitBackPicture: null,
      ...initialValues,
      // involuntaryPartTime should be "false" by default
      involuntaryPartTime: initialValues?.involuntaryPartTime || false,
      taxCode: defaultTaxCode
    }),
    [defaultTaxCode, initialValues]
  )

  const isEeeMemberCountry = useCallback(
    (countryId: string): boolean =>
      !!countries.find(country => country.id === countryId)?.eeaMember,
    [countries]
  )

  const formInitialErrors: FormikErrors<EditEmployeeProfileFormValues> =
    useMemo(() => {
      const isEeaMember = initialValues?.nationalityCountryId
        ? isEeeMemberCountry(initialValues?.nationalityCountryId)
        : true

      const isExpiredIdCardExpiresOnInformation = initialValues?.idCardExpiresOn
        ? isBefore(
            endOfDay(initialValues?.idCardExpiresOn),
            startOfDay(new Date())
          )
        : false

      const isExpiredResidencePermitExpiresOnInformation =
        initialValues?.residencePermitExpiresOn
          ? isBefore(
              endOfDay(initialValues?.residencePermitExpiresOn),
              startOfDay(new Date())
            )
          : false

      const isExpiredWorkPermitExpiresInformation =
        initialValues?.workPermitExpiresOn
          ? isBefore(
              endOfDay(initialValues?.workPermitExpiresOn),
              startOfDay(new Date())
            )
          : false

      return {
        ...(!initialValues?.birthdate ? { birthdate: '' } : {}),
        ...(!initialValues?.ssn ? { ssn: '' } : {}),
        ...(!initialValues?.gender ? { gender: '' } : {}),
        ...(!initialValues?.countryOfBirthId ? { countryOfBirthId: '' } : {}),
        ...(!initialValues?.nationalityCountryId
          ? { nationalityCountryId: '' }
          : {}),
        ...(!initialValues?.street ? { street: '' } : {}),
        ...(!initialValues?.streetNumber ? { streetNumber: '' } : {}),
        ...(!initialValues?.zip ? { zip: '' } : {}),
        ...(!initialValues?.city ? { city: '' } : {}),
        ...(!initialValues?.countryId ? { countryId: '' } : {}),
        ...(!initialValues?.bankAccount ? { bankAccount: '' } : {}),
        ...(!initialValues?.bic ? { bic: '' } : {}),
        //
        ...(isEeaMember
          ? {
              // idCard
              ...(!initialValues?.idCardNumber ? { idCardNumber: '' } : {}),
              ...(!initialValues?.idCardBackPicture
                ? { idCardBackPicture: '' }
                : {}),
              ...(!initialValues?.idCardFrontPicture
                ? { idCardFrontPicture: '' }
                : {}),
              ...(!initialValues?.idCardExpiresOn
                ? { idCardExpiresOn: '' }
                : {
                    ...(isExpiredIdCardExpiresOnInformation
                      ? { idCardExpiresOn: '' }
                      : {})
                  })
            }
          : {
              // residencePermit
              ...(!initialValues?.residencePermitCode
                ? { residencePermitCode: '' }
                : {}),
              ...(!initialValues?.residencePermitBackPicture
                ? { residencePermitBackPicture: '' }
                : {}),
              ...(!initialValues?.residencePermitFrontPicture
                ? { residencePermitFrontPicture: '' }
                : {}),
              ...(!initialValues?.residencePermitExpiresOn
                ? { residencePermitExpiresOn: '' }
                : {
                    ...(isExpiredResidencePermitExpiresOnInformation
                      ? { residencePermitExpiresOn: '' }
                      : {})
                  }),
              // workPermit
              ...(!initialValues?.workPermitBackPicture
                ? { workPermitBackPicture: '' }
                : {}),
              ...(!initialValues?.workPermitFrontPicture
                ? { workPermitBackPicture: '' }
                : {}),
              ...(!initialValues?.workPermitExpiresOn
                ? { workPermitExpiresOn: '' }
                : {
                    ...(isExpiredWorkPermitExpiresInformation
                      ? { workPermitExpiresOn: '' }
                      : {})
                  })
            })
      }
    }, [
      initialValues?.nationalityCountryId,
      initialValues?.idCardExpiresOn,
      initialValues?.residencePermitExpiresOn,
      initialValues?.workPermitExpiresOn,
      initialValues?.birthdate,
      initialValues?.ssn,
      initialValues?.gender,
      initialValues?.countryOfBirthId,
      initialValues?.street,
      initialValues?.streetNumber,
      initialValues?.zip,
      initialValues?.city,
      initialValues?.countryId,
      initialValues?.bankAccount,
      initialValues?.bic,
      initialValues?.idCardNumber,
      initialValues?.idCardBackPicture,
      initialValues?.idCardFrontPicture,
      initialValues?.residencePermitCode,
      initialValues?.residencePermitBackPicture,
      initialValues?.residencePermitFrontPicture,
      initialValues?.workPermitBackPicture,
      initialValues?.workPermitFrontPicture,
      isEeeMemberCountry
    ])

  // TODO: merge "update employee" mutation in session and graphql packages
  // TODO: fix export and import (unused and direct import from other packages)

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        // --------- GeneralInformation
        firstName: Yup.string()
          .label(t('edit_employee_profile_form.first_name.label'))
          .required(),
        lastName: Yup.string()
          .label(t('edit_employee_profile_form.last_name.label'))
          .required(),
        gender: Yup.string()
          .label(t('edit_employee_profile_form.gender.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        nationalityCountryId: Yup.string()
          .label(t('edit_employee_profile_form.nationality_country_id.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        placeOfBirth: Yup.string()
          .label(t('edit_employee_profile_form.place_of_birth.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        countryOfBirthId: Yup.string()
          .label(t('edit_employee_profile_form.country_of_birth_id.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        birthdate: Yup.date()
          .label(t('edit_employee_profile_form.birthdate.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        isStudent: Yup.boolean()
          .label(t('edit_employee_profile_form.is_student.label'))
          .required(),
        // --------- ContactInformation
        mobile: Yup.string()
          .label(t('edit_employee_profile_form.mobile.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        email: Yup.string()
          .label(t('edit_employee_profile_form.email.label'))
          .nullable()
          .email()
          .required(),
        street: Yup.string()
          .label(t('edit_employee_profile_form.street.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        streetNumber: Yup.string()
          .label(t('edit_employee_profile_form.street_number.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        buildingNumber: Yup.string()
          .label(t('edit_employee_profile_form.building_number.label'))
          .nullable()
          .notRequired(),
        zip: Yup.string()
          .label(t('edit_employee_profile_form.zip.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        city: Yup.string()
          .label(t('edit_employee_profile_form.city.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        countryId: Yup.string()
          .label(t('edit_employee_profile_form.country_id.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        // --------- DocumentsInformation
        ssn: Yup.string()
          .label(t('edit_employee_profile_form.ssn.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          })
          .test(
            'isValidSSN',
            t('edit_employee_profile_form.ssn.errors.invalid'),
            ssn => (ssn ? validators.isValidSSN(ssn) : true)
          ),
        bankAccount: Yup.string()
          .label(t('edit_employee_profile_form.bank_account.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          })
          .test(
            'isValidBankAccount',
            t('edit_employee_profile_form.bank_account.errors.invalid'),
            bankAccount =>
              bankAccount ? validators.isValidIBANNumber(bankAccount) : true
          ),
        bic: Yup.string()
          .label(t('edit_employee_profile_form.bank_account.label'))
          .nullable()
          .when({
            is: () => isEmployeeContractReady,
            then: schema =>
              schema.when('bankAccount', ([bankAccount], schema) => {
                if (doesEmployeeNeedBIC(bankAccount)) {
                  return schema.required()
                }
                return schema.notRequired()
              }),
            otherwise: schema => schema.notRequired()
          }),
        // ID card
        idCardNumber: Yup.string()
          .label(t('edit_employee_profile_form.id_card_number.label'))
          .nullable()
          .when({
            is: (idCardNumber: string | null) =>
              isEmployeeContractReady || !!idCardNumber,
            then: schema =>
              schema
                .required()
                .when(
                  'nationalityCountryId',
                  ([nationalityCountryId], schema) => {
                    return !nationalityCountryId ||
                      isEeeMemberCountry(nationalityCountryId)
                      ? schema
                          .required()
                          .test(
                            'isValidIdCard',
                            t(
                              'edit_employee_profile_form.id_card_number.errors.invalid'
                            ),
                            (idCardNumber: string) => {
                              const isBelgium =
                                countrySelector(nationalityCountryId, countries)
                                  ?.nameEn === 'Belgium'
                              if (isBelgium) {
                                return !!(
                                  idCardNumber &&
                                  validators.isValidBelgiumIdCardNumber(
                                    idCardNumber
                                  )
                                )
                              }
                              return true
                            }
                          )
                      : schema.notRequired()
                  }
                )
                .when('nationalityCountryId', {
                  is: (nationalityCountryId?: unknown) =>
                    isEeeMemberCountry(String(nationalityCountryId)),
                  then: schema => schema.required()
                }),
            otherwise: schema => schema.notRequired()
          }),
        idCardExpiresOn: Yup.date()
          .label(t('edit_employee_profile_form.id_card_expires_on.label'))
          .nullable()
          .when({
            is: (idCardExpiresOn: string | null) =>
              isEmployeeContractReady || !!idCardExpiresOn,
            then: schema =>
              schema
                .required()
                .when(
                  'nationalityCountryId',
                  ([nationalityCountryId], schema) => {
                    return !nationalityCountryId ||
                      isEeeMemberCountry(nationalityCountryId)
                      ? schema
                          .required()
                          .test(
                            'isIdCardNotExpired',
                            t(
                              'edit_employee_profile_form.id_card_expires_on.errors.expired'
                            ),
                            (idCardExpiresOn: Date | null) => {
                              if (!idCardExpiresOn) {
                                return false
                              }
                              return validators.isDateInTheFuture(
                                idCardExpiresOn
                              )
                            }
                          )
                      : schema.notRequired()
                  }
                ),
            otherwise: schema => schema.notRequired()
          }),
        idCardFrontPicture: Yup.string()
          .label(t('edit_employee_profile_form.id_card_front_picture.label'))
          .nullable()
          .when({
            is: (idCardFrontPicture: string | null) =>
              isEmployeeContractReady || !!idCardFrontPicture,
            then: schema =>
              schema
                .required()
                .when(
                  'nationalityCountryId',
                  ([nationalityCountryId], schema) => {
                    return !nationalityCountryId ||
                      isEeeMemberCountry(nationalityCountryId)
                      ? schema.required()
                      : schema.notRequired()
                  }
                ),
            otherwise: schema => schema.notRequired()
          }),
        idCardBackPicture: Yup.string()
          .label(t('edit_employee_profile_form.id_card_back_picture.label'))
          .nullable()
          .when({
            is: (idCardBackPicture: string | null) =>
              isEmployeeContractReady || !!idCardBackPicture,
            then: schema =>
              schema
                .required()
                .when(
                  'nationalityCountryId',
                  ([nationalityCountryId], schema) => {
                    return !nationalityCountryId ||
                      isEeeMemberCountry(nationalityCountryId)
                      ? schema.required()
                      : schema.notRequired()
                  }
                ),
            otherwise: schema => schema.notRequired()
          }),
        // residencePermit
        residencePermitCode: Yup.string()
          .label(t('edit_employee_profile_form.residence_permit_code.label'))
          .nullable()
          .when({
            is: (residencePermitCode: string | null) =>
              isEmployeeContractReady || !!residencePermitCode,
            then: schema =>
              schema
                .required()
                .when(
                  'nationalityCountryId',
                  ([nationalityCountryId], schema) => {
                    return !isEeeMemberCountry(nationalityCountryId)
                      ? schema.required()
                      : schema.notRequired()
                  }
                ),
            otherwise: schema => schema.notRequired()
          }),
        residencePermitExpiresOn: Yup.date()
          .label(
            t('edit_employee_profile_form.residence_permit_expires_on.label')
          )
          .nullable()
          .when({
            is: (residencePermitExpiresOn: string | null) =>
              isEmployeeContractReady || !!residencePermitExpiresOn,
            then: schema =>
              schema
                .required()
                .when(
                  'nationalityCountryId',
                  ([nationalityCountryId], schema) => {
                    return !isEeeMemberCountry(nationalityCountryId)
                      ? schema
                          .required()
                          .test(
                            'isResidencePermitNotExpired',
                            t(
                              'edit_employee_profile_form.residence_permit_expires_on.errors.expired'
                            ),
                            (residencePermitExpiresOn: Date | null) => {
                              if (!residencePermitExpiresOn) {
                                return false
                              }
                              return validators.isDateInTheFuture(
                                residencePermitExpiresOn
                              )
                            }
                          )
                      : schema.notRequired()
                  }
                ),
            otherwise: schema => schema.notRequired()
          }),
        residencePermitFrontPicture: Yup.string()
          .label(
            t('edit_employee_profile_form.residence_permit_front_picture.label')
          )
          .nullable()
          .when({
            is: (residencePermitFrontPicture: string | null) =>
              isEmployeeContractReady || !!residencePermitFrontPicture,
            then: schema =>
              schema
                .required()
                .when(
                  'nationalityCountryId',
                  ([nationalityCountryId], schema) => {
                    return !isEeeMemberCountry(nationalityCountryId)
                      ? schema.required()
                      : schema.notRequired()
                  }
                ),
            otherwise: schema => schema.notRequired()
          }),
        residencePermitBackPicture: Yup.string()
          .label(
            t('edit_employee_profile_form.residence_permit_back_picture.label')
          )
          .nullable()
          .when({
            is: (residencePermitBackPicture: string | null) =>
              isEmployeeContractReady || !!residencePermitBackPicture,
            then: schema =>
              schema
                .required()
                .when(
                  'nationalityCountryId',
                  ([nationalityCountryId], schema) => {
                    return !isEeeMemberCountry(nationalityCountryId)
                      ? schema.required()
                      : schema.notRequired()
                  }
                ),
            otherwise: schema => schema.notRequired()
          }),
        // workPermit
        workPermitExpiresOn: Yup.date()
          .label(t('edit_employee_profile_form.work_permit_expires_on.label'))
          .nullable()
          .when({
            is: (workPermitExpiresOn: string | null) =>
              isEmployeeContractReady || !!workPermitExpiresOn,
            then: schema =>
              schema
                .required()
                .when(
                  'nationalityCountryId',
                  ([nationalityCountryId], schema) => {
                    return !isEeeMemberCountry(nationalityCountryId)
                      ? schema
                          .required()
                          .test(
                            'isWorkPermitNotExpired',
                            t(
                              'edit_employee_profile_form.work_permit_expires_on.errors.expired'
                            ),
                            (workPermitExpiresOn: Date | null) => {
                              if (!workPermitExpiresOn) {
                                return false
                              }
                              return validators.isDateInTheFuture(
                                workPermitExpiresOn
                              )
                            }
                          )
                      : schema.notRequired()
                  }
                ),
            otherwise: schema => schema.notRequired()
          }),
        workPermitFrontPicture: Yup.string()
          .label(
            t('edit_employee_profile_form.work_permit_front_picture.label')
          )
          .nullable()
          .when({
            is: (workPermitFrontPicture: string | null) =>
              isEmployeeContractReady || !!workPermitFrontPicture,
            then: schema =>
              schema
                .required()
                .when(
                  'nationalityCountryId',
                  ([nationalityCountryId], schema) => {
                    return !isEeeMemberCountry(nationalityCountryId)
                      ? schema.required()
                      : schema.notRequired()
                  }
                ),
            otherwise: schema => schema.notRequired()
          }),
        workPermitBackPicture: Yup.string()
          .label(t('edit_employee_profile_form.work_permit_back_picture.label'))
          .nullable()
          .when({
            is: (workPermitBackPicture: string | null) =>
              isEmployeeContractReady || !!workPermitBackPicture,
            then: schema =>
              schema
                .required()
                .when(
                  'nationalityCountryId',
                  ([nationalityCountryId], schema) => {
                    return !isEeeMemberCountry(nationalityCountryId)
                      ? schema.required()
                      : schema.notRequired()
                  }
                ),
            otherwise: schema => schema.notRequired()
          }),
        // --------- WorkProfileInformation
        maritalState: Yup.string()
          .label(t('edit_employee_profile_form.marital_state.label'))
          .nullable(),
        maritalDate: Yup.date()
          .label(t('edit_employee_profile_form.marital_date.label'))
          .nullable(),
        childrenNumber: Yup.number()
          .label(t('edit_employee_profile_form.children_number.label'))
          .nullable(),
        childrenWithDisabilitiesNumber: Yup.number()
          .label(
            t(
              'edit_employee_profile_form.children_with_disabilities_number.label'
            )
          )
          .nullable(),
        dependentPersonsNumber: Yup.number()
          .label(t('edit_employee_profile_form.dependent_persons_number.label'))
          .nullable(),
        dependentPersonsWithDisabilitiesNumber: Yup.number()
          .label(
            t(
              'edit_employee_profile_form.dependent_persons_with_disabilities_number.label'
            )
          )
          .nullable(),
        taxCode: Yup.string()
          .label(t('edit_employee_profile_form.tax_code.label'))
          .nullable(),
        involuntaryPartTime: Yup.boolean()
          .label(t('edit_employee_profile_form.involuntary_part_time.label'))
          .nullable()
      }),
    [countries, isEeeMemberCountry, isEmployeeContractReady, t]
  )

  const handleSubmit = useCallback(
    async (
      values: EditEmployeeProfileFormValues,
      formikHelpers: FormikHelpers<EditEmployeeProfileFormValues>
    ) => {
      const employeeInput = employeeFormValuesToEmployeeInput(values)
      await onSubmit(employeeInput, formikHelpers)
      formikHelpers.setSubmitting(false)
    },
    [onSubmit]
  )

  return (
    <Formik<EditEmployeeProfileFormValues>
      initialValues={formInitialValues}
      initialErrors={formInitialErrors}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}
