import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Information,
  InformationFieldProps
} from '../../../../../../../Information'
import { Offer_functionCodes } from '../../../../../../typings/Offer'

interface Props extends Partial<InformationFieldProps> {
  functionCode: Offer_functionCodes
}

export const PayrollCoefficient: FC<Props> = ({ functionCode, ...props }) => {
  const { t } = useTranslation('web_ui')

  return (
    <Information.InformationField
      label={t(
        'offers_table_view.function_codes.fields.payroll_coefficient.label'
      )}
      smallDivider
      {...props}
    >
      {functionCode.payrollCoefficient !== null
        ? `${functionCode.payrollCoefficient}`
        : t('no', { ns: 'translation' })}
    </Information.InformationField>
  )
}
