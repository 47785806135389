import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, WageInput } from '@vivaldis/antd-ui'
import { Divider } from '../../../../../../Divider'

export const MinimumWage: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Divider sectionSeparator />
      <Form.Item
        name="minimumWage"
        required
        label={t('position_form.step1.minimum_wage.label', { ns: 'web_ui' })}
      >
        <WageInput name="minimumWage" placeholder={t('form.required')} />
      </Form.Item>
    </>
  )
}
