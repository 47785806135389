import { FC, PropsWithChildren, useCallback, useState } from 'react'
import {
  MainNavigateContext,
  SetMainNavigateContext
} from '../context/MainNavigateContext'
import type { MainNavigate } from '../typings/MainNavigate'

export const MainNavigateProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const [mainNavigate, setMainNavigate] = useState<MainNavigate | undefined>(
    undefined
  )

  const handleSetMainNavigate = useCallback((mainNavigate: any) => {
    setMainNavigate(() => mainNavigate)
  }, [])

  return (
    <MainNavigateContext.Provider value={mainNavigate}>
      <SetMainNavigateContext.Provider value={handleSetMainNavigate}>
        <>{children}</>
      </SetMainNavigateContext.Provider>
    </MainNavigateContext.Provider>
  )
}
