import { PlusOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { useCertificateTypeOptions } from '@vivaldis/ui'
import { RcFile } from 'antd/lib/upload/interface'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, DatePicker, Form, Row, Select, Upload } from '@vivaldis/antd-ui'
import { fileToBase64 } from '../../../../utils/fileToBase64'
import { useForm } from '../../provider/FormProvider'
import { EmployeeCertificates } from '../../types/EmployeeCertificates'
import { transformDataToBase64 } from '../../utils/transformDataToBase64'

interface Props {
  certificateTypes: string[]
  employeeCertificate?: EmployeeCertificates | null
}

export const FormContent: FC<Props> = ({
  certificateTypes,
  employeeCertificate
}) => {
  const [t] = useTranslation('web_ui')

  const { values, setFieldError, setFieldTouched, setFieldValue } = useForm()

  const maritalStateOptions = useCertificateTypeOptions(certificateTypes)

  const disableCertificateType = useMemo(
    () => !!employeeCertificate?.id,
    [employeeCertificate?.id]
  )

  const handleFrontPictureUpload = useCallback(
    async (file: RcFile) => {
      try {
        const scannedDocumentUrlAttachment = {
          fileName: file.name,
          base64: await fileToBase64(file),
          dataURL: await transformDataToBase64(file)
        }

        setFieldValue(
          'scannedDocumentUrlAttachment',
          scannedDocumentUrlAttachment,
          true
        )

        return scannedDocumentUrlAttachment.dataURL
      } catch (e) {}
      return ''
    },
    [setFieldValue]
  )

  const handleBackPictureUpload = useCallback(
    async (file: RcFile) => {
      try {
        const scannedDocumentBackUrlAttachment = {
          fileName: file.name,
          base64: await fileToBase64(file),
          dataURL: await transformDataToBase64(file)
        }

        setFieldValue(
          'scannedDocumentBackUrlAttachment',
          scannedDocumentBackUrlAttachment,
          true
        )

        return scannedDocumentBackUrlAttachment.dataURL
      } catch (e) {}
      return ''
    },
    [setFieldValue]
  )

  const handleFrontPictureBeforeUpload = useCallback(
    (file: RcFile) => {
      // file should be less than 12MB
      const maxFileSizeInMB = 12
      const fileSizeInMB = file.size / 1024 / 1024

      const isValid = fileSizeInMB < maxFileSizeInMB

      if (!isValid) {
        setFieldError(
          'scannedDocumentUrlAttachment.dataURL',
          t(
            'employee_certificate_form.scanned_document_url.errors.max_file_size',
            { count: maxFileSizeInMB }
          )
        )
        setFieldTouched('scannedDocumentUrlAttachment.dataURL', true, false)
      }

      return isValid
    },
    [setFieldError, setFieldTouched, t]
  )

  const handleBackPictureBeforeUpload = useCallback(
    (file: RcFile) => {
      // file should be less than 12MB
      const maxFileSizeInMB = 12
      const fileSizeInMB = file.size / 1024 / 1024

      const isValid = fileSizeInMB < maxFileSizeInMB

      if (!isValid) {
        setFieldError(
          'scannedDocumentBackUrlAttachment.dataURL',
          t(
            'employee_certificate_form.scanned_document_back_url.errors.max_file_size',
            { count: maxFileSizeInMB }
          )
        )
        setFieldTouched('scannedDocumentBackUrlAttachment.dataURL', true, false)
      }

      return isValid
    },
    [setFieldError, setFieldTouched, t]
  )

  return (
    <>
      <Form.Item
        name="certificateType"
        required
        label={t('employee_certificate_form.certificate_type.label')}
        hasFeedback={false}
      >
        <Select
          name="certificateType"
          placeholder={t('form.required')}
          options={maritalStateOptions}
          disabled={disableCertificateType}
          showSearch
        />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t('employee_certificate_form.scanned_document_url.label')}
            name="scannedDocumentUrlAttachment.dataURL"
            hasFeedback={false}
          >
            <DocumentUpload
              listType="picture-card"
              action={handleFrontPictureUpload}
              beforeUpload={handleFrontPictureBeforeUpload}
              fileList={[]}
              accept=".png, .jpg, .jpeg"
            >
              {values.scannedDocumentUrlAttachment.dataURL ? (
                <img
                  src={values.scannedDocumentUrlAttachment.dataURL}
                  alt="avatar"
                  style={{ width: '100%' }}
                />
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>
                    {t('edit_employee_profile_form.image_upload.upload_button')}
                  </div>
                </div>
              )}
            </DocumentUpload>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t(
              'employee_certificate_form.scanned_document_back_url.label'
            )}
            name="scannedDocumentBackUrlAttachment.dataURL"
            hasFeedback={false}
          >
            <DocumentUpload
              listType="picture-card"
              action={handleBackPictureUpload}
              beforeUpload={handleBackPictureBeforeUpload}
              fileList={[]}
              accept=".png, .jpg, .jpeg"
            >
              {values.scannedDocumentBackUrlAttachment.dataURL ? (
                <img
                  src={values.scannedDocumentBackUrlAttachment.dataURL}
                  alt="avatar"
                  style={{ width: '100%' }}
                />
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>
                    {t('edit_employee_profile_form.image_upload.upload_button')}
                  </div>
                </div>
              )}
            </DocumentUpload>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="expiresOn"
        label={t('employee_certificate_form.expires_on.label')}
        hasFeedback={false}
      >
        <DatePicker
          name="expiresOn"
          format="dd-MM-yyyy"
          allowClear={true}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </>
  )
}

const DocumentUpload = styled(Upload)`
  & .ant-upload {
    width: 100% !important;
    height: 260px !important;
    overflow: hidden;
  }
`
