import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const PaperPlusSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7293 7.48437L14.7245 4.35242V5.65937C14.7245 6.66337 15.5415 7.48137 16.5455 7.48437H17.7293ZM13.8439 2.01172H7.5879H7.5699C5.1069 2.01172 3.0579 4.00972 3.0009 6.49072V17.3267C2.9589 19.7867 4.9269 21.8237 7.3999 21.8667L15.5809 21.8657C18.0499 21.8397 20.0559 19.8087 20.0519 17.3397V8.03972C20.0519 7.84672 19.9769 7.65972 19.8439 7.52072L14.7779 2.24172C14.6359 2.09472 14.4409 2.01172 14.2369 2.01172H14.1051C14.0627 2.00426 14.019 2.00037 13.9745 2.00037C13.9299 2.00037 13.8863 2.00426 13.8439 2.01172ZM13.2245 3.51172V5.65937C13.2245 7.48737 14.7125 8.97937 16.5435 8.98437H18.5519V17.3407C18.5539 18.9907 17.2149 20.3477 15.5729 20.3657H7.5609L7.4139 20.3667C5.7789 20.3387 4.4719 18.9867 4.5009 17.3397V6.50872C4.5399 4.85372 5.9149 3.51172 7.5729 3.51172H7.5849H13.2245ZM10.5944 13.6641H8.89465C8.48065 13.6641 8.14465 13.3281 8.14465 12.9141C8.14465 12.5001 8.48065 12.1641 8.89465 12.1641H10.5944V10.4644C10.5944 10.0504 10.9304 9.71436 11.3444 9.71436C11.7584 9.71436 12.0944 10.0504 12.0944 10.4644V12.1641H13.7937C14.2077 12.1641 14.5437 12.5001 14.5437 12.9141C14.5437 13.3281 14.2077 13.6641 13.7937 13.6641H12.0944V15.3644C12.0944 15.7784 11.7584 16.1144 11.3444 16.1144C10.9304 16.1144 10.5944 15.7784 10.5944 15.3644V13.6641Z"
    />
  </svg>
)

export const PaperPlus = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={PaperPlusSvg} {...props} ref={ref} />
)
