import styled from '@emotion/styled'
import { useField } from 'formik'
import { FC, ReactNode } from 'react'
import { Form } from '../../Form'
import { FormItemProps as AntdFormItemProps } from '../../Form'

export type FormItemProps = {
  showValidateSuccess?: boolean
  showInitialErrorAfterTouched?: boolean
  children: ReactNode
} & { name: string } & AntdFormItemProps

export const FormItem: FC<FormItemProps> = ({
  name,
  showValidateSuccess,
  showInitialErrorAfterTouched = false,
  children,
  ...restProps
}) => {
  const [, meta] = useField(name)

  const { error, initialError } = meta
  const isTouched = meta.touched

  const hasError = error !== undefined && isTouched
  const hasInitialError = initialError !== undefined
  const isValid = !error && isTouched
  const showHelp =
    hasError ||
    (hasInitialError && (!isTouched || showInitialErrorAfterTouched))

  return (
    <AntdFormItem
      name={name}
      validateStatus={
        hasError || (hasInitialError && !isTouched)
          ? 'error'
          : isValid && showValidateSuccess
            ? 'success'
            : undefined
      }
      hasFeedback={isValid}
      help={
        showHelp ? (
          <>
            {hasError && <li>{error}</li>}
            {hasInitialError &&
              (!isTouched || showInitialErrorAfterTouched) && (
                <li>{initialError}</li>
              )}
          </>
        ) : undefined
      }
      {...restProps}
    >
      {children}
    </AntdFormItem>
  )
}

const AntdFormItem = styled(Form.Item)`
  form.ant-form & .ant-form-item-label label {
    font-weight: 600;
    font-size: 12px;
  }

  form.ant-form-large & .ant-form-item-label > label {
    height: auto;
    min-height: 20px;
  }

  form.ant-form-large & .ant-form-item-control > .ant-form-item-control-input {
    height: auto;
    min-height: 20px;
  }

  form.ant-form
    &
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    color: ${props => props.theme.orange6};
  }
`
