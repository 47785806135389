import { useBreakpoint } from '@vivaldis/antd-ui'
import { useContext } from 'react'
import { SideSheetsHistoryContext } from '../context/SideSheetsHistoryContext'
import { SideSheetsElementsContext } from '../providers/SideSheetsElementsProvider'

export function useHasSideSheets(): boolean {
  const breakpoint = useBreakpoint()
  const sideSheets = useContext(SideSheetsElementsContext)!
  const { index } = useContext(SideSheetsHistoryContext)!
  if (!breakpoint.lg) return false
  return sideSheets.length > 1 || index > 0
}
