import { format as formatFns } from 'date-fns'
import { useDateFnsLocale } from './useDateFnsLocale'
import { useCallback } from 'react'

export const useDateFormat = (format: string) => {
  const locale = useDateFnsLocale()

  return useCallback(
    (date: Date | number): string => {
      return formatFns(date, format, {
        locale
      })
    },
    [format, locale]
  )
}
