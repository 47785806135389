import { TextStylePreset, Typography, TypographyProps } from '@vivaldis/antd-ui'

export const Caption = ({
  textAlign = 'center',
  ...props
}: TypographyProps<'span'>) => {
  return (
    <Typography.Text
      preset={TextStylePreset.Regular14}
      textAlign={textAlign}
      style={{ whiteSpace: 'pre-line' }}
      {...props}
    />
  )
}
