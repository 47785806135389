import { css } from '@emotion/react'

export interface PaddingProps {
  paddingBottom?: number
  paddingTop?: number
  paddingLeft?: number
  paddingRight?: number
  paddingVertical?: number
  paddingHorizontal?: number
  padding?: number
}

export function padding(props: PaddingProps & { [key: string]: any }) {
  return css`
    ${props.padding !== undefined ? `padding: ${props.padding}px` : null}
    ${props.paddingVertical !== undefined
      ? `
      padding-bottom: ${props.paddingVertical}px;
      padding-top: ${props.paddingVertical}px;`
      : null};
    ${props.paddingHorizontal !== undefined
      ? `
      padding-left: ${props.paddingHorizontal}px;
      padding-right: ${props.paddingHorizontal}px;
      `
      : null};
    ${props.paddingBottom !== undefined
      ? `padding-bottom: ${props.paddingBottom}px;`
      : null};
    ${props.paddingTop !== undefined
      ? `padding-top: ${props.paddingTop}px;`
      : null};
    ${props.paddingLeft !== undefined
      ? `padding-left: ${props.paddingLeft}px;`
      : null};
    ${props.paddingRight !== undefined
      ? `padding-right: ${props.paddingRight}px;`
      : null};
  `
}
