import styled from '@emotion/styled'
import { FC, useMemo } from 'react'
import { Drawer as DrawerBase } from '@vivaldis/web-ui'
import { useUser, useSignOut } from '@vivaldis/session'
import { NavigationMenu } from '../../../../components/NavigationMenu'
import { DrawerFooterWithCompanySelection } from './components/DrawerFooterWithCompanySelection'
import { LanguagePicker } from './components/LanguagePicker'

interface Props {}

export const Drawer: FC<Props> = () => {
  const user = useUser()
  const signOut = useSignOut()

  // we show this only when user has multiple companies
  const permissions = useMemo(
    () => user?.permissions || [],
    [user?.permissions]
  )

  return (
    <DrawerBase
      footer={
        <DrawerBase.FooterBar onLogoutPress={signOut}>
          {permissions.length < 2 ? (
            <DrawerBase.FooterBar.Company
              name={String(user?.company?.name)}
              source={user?.company?.logo}
            />
          ) : (
            <DrawerFooterWithCompanySelection />
          )}
        </DrawerBase.FooterBar>
      }
    >
      <ContentBase>
        <NavigationMenu isSiderCollapsed={false} />
        <LanguagePicker />
      </ContentBase>
    </DrawerBase>
  )
}

const ContentBase = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
