import { FC, forwardRef, PropsWithChildren } from 'react'
import { Text } from '../platform/Text'
import { TextProps } from '../typings/TextProps'
import { FontWeight } from '../../font/typings/FontWeight'
import { Color } from '../../colors/Color'

export const Header: FC<PropsWithChildren<TextProps>> = forwardRef(
  (
    {
      color = Color.Text.Default,
      fontSize = 18,
      fontWeight = FontWeight.Bold,
      letterSpacing = 0,
      lineHeight = 24,
      ...props
    },
    ref
  ) => (
    <Text
      ref={ref}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
      {...props}
    />
  )
)
