import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

if (!process.env.REACT_APP_BUGSNAG_KEY) {
  console.log('Please add "REACT_APP_BUGSNAG_KEY" to .env')
}

export const bugsnagClient = Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY || '',
  plugins: [new BugsnagPluginReact()],
  // version from package.json
  appVersion: process.env.REACT_APP_VERSION,
  // By default, we will notify Bugsnag of exceptions that happen in any releaseStage. If you would like to change which release stages notify Bugsnag of errors you can set notifyReleaseStages.
  enabledReleaseStages: [
    'production',
    'staging'
    // 'development' // uncomment this line to see logs in development (local)
  ],
  // If you would like to distinguish between errors that happen in different stages of the application release process (development, staging, production, etc) you can set the releaseStage that is reported to Bugsnag.
  releaseStage: process.env.REACT_APP_RELEASE_STAGE,

  enabledBreadcrumbTypes: [
    'navigation',
    // We disable type "request" because it doesn't capture request/response body. Instead we use custom logging for queries/mutations
    // Preview in UI:  http://joxi.net/MAjj8Kjcx0RvGA
    // https://docs.bugsnag.com/platforms/javascript/react/configuration-options/#networkbreadcrumbsenabled
    // 'request',
    'process',
    'log',
    'user',
    'state',
    'error',
    'manual'
  ],
  logger: null // comment this line if you want to see annoying debug information in console like "[bugsnag] Session not sent due to releaseStage/notifyReleaseStages configuration"
})

export default bugsnagClient
