import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const CloseSquareSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 7.916C3.5 5.233 5.135 3.5 7.665 3.5H16.334C18.864 3.5 20.5 5.233 20.5 7.916V16.084C20.5 18.767 18.864 20.5 16.333 20.5H7.665C5.135 20.5 3.5 18.767 3.5 16.084V7.916ZM7.665 22H16.333C19.723 22 22 19.622 22 16.084V7.916C22 4.378 19.723 2 16.334 2H7.665C4.276 2 2 4.378 2 7.916V16.084C2 19.622 4.276 22 7.665 22ZM14.3966 15.1396C14.2046 15.1396 14.0126 15.0666 13.8666 14.9196L11.9983 13.051L10.1325 14.9167C9.98653 15.0637 9.79453 15.1367 9.60253 15.1367C9.41053 15.1367 9.21853 15.0637 9.07253 14.9167C8.77953 14.6237 8.77953 14.1497 9.07253 13.8567L10.9384 11.9909L9.07058 10.1226C8.77758 9.82965 8.77758 9.35565 9.07058 9.06265C9.36458 8.76965 9.83858 8.76965 10.1306 9.06265L11.9984 10.9309L13.8645 9.06472C14.1575 8.77172 14.6315 8.77172 14.9245 9.06472C15.2175 9.35772 15.2175 9.83172 14.9245 10.1247L13.0583 11.991L14.9266 13.8596C15.2196 14.1526 15.2196 14.6266 14.9266 14.9196C14.7806 15.0666 14.5876 15.1396 14.3966 15.1396Z"
    />
  </svg>
)

export const CloseSquare = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={CloseSquareSvg} {...props} ref={ref} />
)
