import { axios, type AxiosError } from '@vivaldis/common'
import {
  useQuery as useReactQuery,
  type UseQueryResult
} from '@tanstack/react-query'
import {
  endOfISOWeek,
  format,
  isPast,
  startOfISOWeek,
  subMonths
} from 'date-fns'
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'
import { useUserConstructFourDCompanyId } from '../../hooks/useUserConstructFourDCompanyId'
import { useUserHasConstructionWebAccess } from '../../hooks/useUserHasConstructionWebAccess'
import { useUserHasInterimWebAccess } from '../../hooks/useUserHasInterimWebAccess'
import { useUserInterimFourDCompanyId } from '../../hooks/useUserInterimFourDCompanyId'
import { HoursValidationApiResponse } from '../../hoursValidationApi/HoursValidationApiResponse'

// API documentation -https://github.com/VeniceNV/vivaldis/blob/master/doc/hours_validation_api.md#company-contracts
interface QueryVariables {
  companyId: string
  // statusTimesheet?: string | 'handled' | 'empty' | 'final' | 'draft'
  // year?: string // four digit year
  // week?: string // 1 or 2 digit week number
  startDate?: string //YYYY-MM-DD
  endDate?: string //YYYY-MM-DD
}

interface Contract {
  id: string // 'IN24FF78CD129F'
  startDate: string // '2021-12-06'
  endDate: string // '2021-12-06'
  status: string | 'handled' | 'empty' | 'final' | 'draft'
}

export interface QueryContextValue {
  constructQueryResult: UseQueryResult<Contract[], AxiosError>
  interimQueryResult: UseQueryResult<Contract[], AxiosError>
}

export const QueryContext = createContext<QueryContextValue | undefined>(
  undefined
)

export function useQuery(): QueryContextValue {
  return useContext(QueryContext)!
}
export function useNotProcessedTimesheetCount(): {
  notProcessedInterimTimesheetCount: number
  notProcessedConstructTimesheetCount: number
} {
  const { constructQueryResult, interimQueryResult } = useQuery()

  return useMemo(() => {
    const interimContracts = interimQueryResult?.data || []
    const constructContracts = constructQueryResult?.data || []

    const interimCount = interimContracts.filter(contract => {
      return (
        ['empty', 'draft'].includes(String(contract.status)) &&
        isPast(new Date(contract.endDate))
      )
    }).length

    const constructCount = constructContracts.filter(contract => {
      return (
        ['empty', 'draft'].includes(String(contract.status)) &&
        isPast(new Date(contract.endDate))
      )
    }).length

    return {
      notProcessedInterimTimesheetCount: interimCount,
      notProcessedConstructTimesheetCount: constructCount
    }
  }, [constructQueryResult?.data, interimQueryResult?.data])
}

const fetchContracts = async ({
  companyId,
  startDate,
  endDate
}: QueryVariables) => {
  const response = await axios().get<HoursValidationApiResponse<Contract[]>>(
    `/api/hours_validation/companies/${companyId}/contracts`,
    {
      params: {
        ...(startDate ? { startDate } : {}),
        ...(endDate ? { endDate } : {})
      },
      data: {}
    }
  )

  return response.data.data
}

export const TimesheetBadgesProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const userHasInterimWebAccess = useUserHasInterimWebAccess()
  const userHasConstructionWebAccess = useUserHasConstructionWebAccess()

  const constructFourDCompanyId = useUserConstructFourDCompanyId()
  const interimFourDCompanyId = useUserInterimFourDCompanyId()

  const startDate = format(
    startOfISOWeek(subMonths(new Date(), 1)),
    'yyyy-MM-dd'
  )
  const endDate = format(endOfISOWeek(new Date()), 'yyyy-MM-dd')

  const constructQueryVariables: QueryVariables = useMemo(() => {
    return {
      startDate,
      endDate,
      companyId: String(constructFourDCompanyId)
    }
  }, [constructFourDCompanyId, endDate, startDate])

  const constructQueryResult = useReactQuery<Contract[], AxiosError>({
    queryKey: ['contracts', constructQueryVariables],
    queryFn: () => fetchContracts(constructQueryVariables),
    enabled: !!constructFourDCompanyId && userHasConstructionWebAccess
  })

  const interimQueryVariables: QueryVariables = useMemo(() => {
    return {
      startDate,
      endDate,
      companyId: String(interimFourDCompanyId)
    }
  }, [endDate, interimFourDCompanyId, startDate])

  const interimQueryResult = useReactQuery<Contract[], AxiosError>({
    queryKey: ['contracts', interimQueryVariables],
    queryFn: () => fetchContracts(interimQueryVariables),
    enabled: !!interimFourDCompanyId && userHasInterimWebAccess
  })

  const contextValue = useMemo(
    () => ({
      constructQueryResult,
      interimQueryResult
    }),
    [constructQueryResult, interimQueryResult]
  )

  return (
    <QueryContext.Provider value={contextValue}>
      {children}
    </QueryContext.Provider>
  )
}
