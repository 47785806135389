import { Row, Tag } from '@vivaldis/antd-ui'
import { ON_THE_MOVE, useCountryName } from '@vivaldis/common'
import { Clock, ShieldDone } from '@vivaldis/icons'
import { useTheme } from '@vivaldis/ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useContractTypeLabel } from '../../../ContractType'
import { Information } from '../../../Information'
import { useStatuteLabel } from '../../../Statute'
import { PositionApprovalStatesEnum } from '../../typings/PositionApprovalStatesEnum'
import { SuVivaldisOffices } from '../../typings/SuVivaldisOffices'
import { Position } from '../../typings/Position'
import { Description } from './components/Description'

interface Props {
  position: Position
  vivaldisOffices: SuVivaldisOffices[]
  suView: boolean
}

export const ContractInformation: FC<Props> = ({
  position,
  vivaldisOffices,
  suView
}) => {
  const [t] = useTranslation('web_ui')
  const theme = useTheme()

  const countryName = useCountryName(position.country)

  const statuteLabel = useStatuteLabel(position.contractDatum)
  const contractTypeLabel = useContractTypeLabel(position.contractDatum)

  const vivaldisOffice = useMemo(() => {
    const suVivaldisOffice = vivaldisOffices.find(
      suVivaldisOffice =>
        suVivaldisOffice.code === position.contractDatum?.vivaldisOffice
    )

    return `${suVivaldisOffice?.code} - ${suVivaldisOffice?.name}`
  }, [position.contractDatum?.vivaldisOffice, vivaldisOffices])

  return (
    <Information>
      <Information.InformationField
        label={t('view_position_screen_content.position_name')}
      >
        {position.name}
      </Information.InformationField>
      <Information.InformationField
        label={t('view_position_screen_content.position_description')}
      >
        <Description description={position.description} />
      </Information.InformationField>

      {suView ? (
        <Information.InformationField
          label={t('view_position_screen_content.company')}
        >
          {position?.company?.name}
        </Information.InformationField>
      ) : null}

      <Information.InformationField
        label={t('view_position_screen_content.location.label')}
      >
        {position.street === ON_THE_MOVE ? (
          <>{t('view_position_screen_content.location.on_the_move')}</>
        ) : (
          <>
            <Row justify="end">{`${position.street} ${position.streetNumber}`}</Row>
            <Row justify="end">
              {position.zip}, {position.city}
            </Row>
            <Row justify="end">{countryName}</Row>
          </>
        )}
      </Information.InformationField>

      <Information.InformationField
        label={t('view_position_screen_content.statute')}
      >
        {statuteLabel}
      </Information.InformationField>

      {suView ? (
        <Information.InformationField
          label={t('view_position_screen_content.contract_type')}
        >
          {contractTypeLabel}
        </Information.InformationField>
      ) : null}

      <Information.InformationField
        label={t('view_position_screen_content.status.label')}
      >
        {position.state === PositionApprovalStatesEnum.APPROVED ? (
          <Tag
            icon={<ShieldDone />}
            style={{
              background: theme.StatusGreen_green1,
              borderColor: theme.StatusGreen_green4
            }}
          >
            {t('view_position_screen_content.status.statuses.approved')}
          </Tag>
        ) : null}
        {position.state === PositionApprovalStatesEnum.WAITING_APPROVAL ? (
          <>
            <Tag
              icon={<Clock />}
              style={{
                background: theme.VivaldisYellow_yellow1,
                borderColor: theme.VivaldisYellow_yellow4
              }}
            >
              {t(
                'view_position_screen_content.status.statuses.waiting_approval'
              )}
            </Tag>
          </>
        ) : null}
      </Information.InformationField>

      {suView ? (
        <Information.InformationField
          label={t('view_position_screen_content.committee.label')}
        >
          {`${position.contractDatum?.committee?.code} (${
            position.contractDatum?.committee?.isWorker
              ? t('view_position_screen_content.committee.options.worker')
              : t('view_position_screen_content.committee.options.clerk')
          })   - ${position.contractDatum?.committee?.name}`}
        </Information.InformationField>
      ) : null}

      {suView ? (
        <Information.InformationField
          label={t('view_position_screen_content.vivaldis_office_code')}
        >
          {vivaldisOffice}
        </Information.InformationField>
      ) : null}

      {suView ? (
        <Information.InformationField
          label={t('view_position_screen_content.is_flexi')}
        >
          {position.contractDatum?.isFlexi
            ? t('yes', { ns: 'translation' })
            : t('no', { ns: 'translation' })}
        </Information.InformationField>
      ) : null}

      <Information.InformationField
        label={t('view_position_screen_content.minimum_wage')}
      >
        {t('view_position_screen_content.wage_value', {
          count: position.minimumWage || 0
        })}
      </Information.InformationField>
    </Information>
  )
}
