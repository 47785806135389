import queryString from 'query-string'

export enum ActionCableEventType {
  Connect = 'connect',
  MessageError = 'messageError'
}

export type ActionCableEvent = any
export type ActionCableListener = (event?: ActionCableEvent) => void
export type ActionCableUnsubscribeCallback = () => void

export interface ActionCableUserCredentials {
  user_token: string
  user_email: string
}

export interface ActionCableEmployeeCredentials {
  employee_token: string
  employee_email: string
}

export type ActionCableCredentials =
  | ActionCableUserCredentials
  | ActionCableEmployeeCredentials

export interface ActionCableSubscribeFunction {
  (listener: ActionCableListener): ActionCableUnsubscribeCallback
  (
    type: ActionCableEventType,
    listener: ActionCableListener
  ): ActionCableUnsubscribeCallback
}

export interface ActionCable {
  connection?: any | undefined
  connect: (url: string, credentials: ActionCableCredentials) => void
  subscribe: ActionCableSubscribeFunction
  trigger: (type: ActionCableEventType, event: ActionCableEvent) => void
  readonly url?: string
  readonly credentials?: ActionCableCredentials
}

const actionCable: ActionCable = createActionCable()

export function createActionCable(): ActionCable {
  function connect(url: string, credentials: ActionCableCredentials) {
    // For employee it should be `employee_token` and `employee_email`
    // For user and SR it should be `user_token` and `user_email`
    const queryParams = queryString.stringify(credentials)

    actionCable.connection = new WebSocket(`${url}?${queryParams}`)
    ;(actionCable as any).url = url
    ;(actionCable as any).credentials = { ...credentials }
    trigger(ActionCableEventType.Connect)
  }

  const listeners: {
    [key in ActionCableEventType | 'all']: ActionCableListener[]
  } = {
    all: [],
    connect: [],
    messageError: []
  }

  function subscribe(
    type: ActionCableEventType | ActionCableListener,
    listener?: ActionCableListener
  ): ActionCableUnsubscribeCallback {
    let isSubscribed = true

    if (type && listener) {
      listeners[type as ActionCableEventType].push(listener)
    } else if (listener) {
      listeners.all.push(listener)
    }

    return () => {
      if (!isSubscribed) {
        return
      }
      isSubscribed = false

      if (type && listener) {
        const index = listeners[type as ActionCableEventType].indexOf(listener)
        listeners[type as ActionCableEventType].splice(index, 1)
      } else if (listener) {
        const index = listeners.all.indexOf(listener)
        listeners.all.splice(index, 1)
      }
    }
  }

  function trigger(event: ActionCableEventType) {
    const len = listeners[event].length
    for (let i = 0; i < len; ++i) {
      listeners[event][i]()
    }
    const len2 = listeners.all.length
    for (let i = 0; i < len2; ++i) {
      listeners.all[i]()
    }
  }

  const actionCable: ActionCable = {
    connect,
    connection: undefined,
    subscribe,
    trigger
  }

  return actionCable
}

export function getActionCable(): ActionCable {
  return actionCable
}
