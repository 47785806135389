import styled from '@emotion/styled'
import { useCallback, FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Form,
  LocationPicker,
  LocationPickerValue,
  Typography,
  Radio
} from '@vivaldis/antd-ui'
import { CountryType } from '../../../../../typings/CountryType'
import { useForm } from '../../../providers/FormProvider'

interface Props {
  countries: CountryType[]
}

export const Location: FC<Props> = ({ countries }) => {
  const { t } = useTranslation()

  const { values, touched, errors, setFieldValue, setFieldTouched } = useForm()

  const handleLocationChange = useCallback(
    (location: LocationPickerValue) => {
      const {
        street,
        streetNumber,
        zip,
        city,
        country,
        countryId,
        latitude,
        longitude
      } = location

      setFieldValue('city', city)
      setFieldValue('country', country)
      setFieldValue('countryId', countryId)
      setFieldValue('street', street)
      setFieldValue('streetNumber', streetNumber)
      setFieldValue('zip', zip)
      setFieldValue('latitude', latitude)
      setFieldValue('longitude', longitude)
    },
    [setFieldValue]
  )

  const handleLocationBlur = useCallback(() => {
    setFieldTouched('streetNumber')
  }, [setFieldTouched])

  const handleLocationFocus = useCallback(() => {
    setFieldValue('onTheMove', false)
  }, [setFieldValue])

  const {
    city,
    country,
    countryId,
    street,
    streetNumber,
    zip,
    latitude,
    longitude
  } = values

  const locationPickerValue = useMemo(
    () => ({
      city: city,
      country: country,
      countryId: countryId,
      street: street,
      streetNumber: streetNumber,
      zip: zip,
      latitude: latitude,
      longitude: longitude
    }),
    [city, country, countryId, latitude, longitude, street, streetNumber, zip]
  )

  const locationPickerError = useMemo(() => {
    return (
      (touched.city && errors.city) ||
      (touched.country && errors.country) ||
      (touched.countryId && errors.countryId) ||
      (touched.street && errors.street) ||
      (touched.streetNumber && errors.streetNumber) ||
      (touched.zip && errors.zip) ||
      (touched.latitude && errors.latitude) ||
      (touched.longitude && errors.longitude)
    )
  }, [
    errors.city,
    errors.country,
    errors.countryId,
    errors.latitude,
    errors.longitude,
    errors.street,
    errors.streetNumber,
    errors.zip,
    touched.city,
    touched.country,
    touched.countryId,
    touched.latitude,
    touched.longitude,
    touched.street,
    touched.streetNumber,
    touched.zip
  ])

  return (
    <>
      <Typography.Title level={5} marginBottom={16}>
        {t('position_form.step1.location.header', { ns: 'web_ui' })}
      </Typography.Title>
      <Form.Item name="onTheMove">
        <Radio.Group name="onTheMove">
          <RadioForLocationPicker value={false} name="onTheMove">
            <label style={{ minWidth: '100%' }}>
              <LocationPicker
                placeholder={t('form.required')}
                onChange={handleLocationChange}
                value={locationPickerValue}
                onBlur={handleLocationBlur}
                onFocus={handleLocationFocus}
                error={locationPickerError}
                countries={countries}
                style={{ marginBottom: 0 }}
              />
            </label>
          </RadioForLocationPicker>
          <Radio value={true} name="onTheMove">
            {t('position_form.step1.on_the_move.label', { ns: 'web_ui' })}
          </Radio>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

// location picker should take full width of the screen (side panel) in empty and not-empty state
const RadioForLocationPicker = styled(Radio)`
  min-width: 100%;
  & > span:nth-of-type(2) {
    min-width: 100%;
  }
  margin-bottom: 16px;
`
