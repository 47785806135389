import { FC, ReactNode } from 'react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import type { NavLinkProps } from 'react-router-dom'
import { text } from '../../utils/font'
import { FontWeight } from '../../utils/font/typings/FontWeight'

const NAVIGATION_BAR_ITEM_CLASS_PREFIX = 'user-navigation-bar'

export const NAVIGATION_BAR_ITEM_LINK_CLASS = `${NAVIGATION_BAR_ITEM_CLASS_PREFIX}-link`
export const NAVIGATION_BAR_ITEM_ACTIVE_LINK_CLASS = `${NAVIGATION_BAR_ITEM_CLASS_PREFIX}-link-active`

export const NAVIGATION_BAR_ITEM_LINK_BOTTOM_INDICATOR_CLASS = `${NAVIGATION_BAR_ITEM_CLASS_PREFIX}-link-bottom-indicator`

interface NavigationBarItemProps extends NavLinkProps {
  label: string
  to: string
  id: string
  icon: ReactNode
  badge?: boolean | number | string | null
}

export const NavigationBarItem: FC<NavigationBarItemProps> = ({
  to,
  label,
  id,
  badge,
  icon,
  className,
  style,
  ...navLinkProps
}) => {
  // handle external links
  if (to.startsWith('https://')) {
    return (
      <a
        href={to}
        id={id}
        className={`${NAVIGATION_BAR_ITEM_LINK_CLASS}`}
        {...navLinkProps}
      >
        <GlyphWrapper>
          {icon}
          {badge ? <Badge>{badge}</Badge> : null}
        </GlyphWrapper>
        <Label>{label}</Label>
        <div className={NAVIGATION_BAR_ITEM_LINK_BOTTOM_INDICATOR_CLASS} />
      </a>
    )
  }

  return (
    <NavLink
      to={to}
      id={id}
      className={({ isActive }) =>
        isActive
          ? `${NAVIGATION_BAR_ITEM_LINK_CLASS} ${NAVIGATION_BAR_ITEM_ACTIVE_LINK_CLASS}`
          : `${NAVIGATION_BAR_ITEM_LINK_CLASS}`
      }
      {...navLinkProps}
    >
      <GlyphWrapper>
        {icon}
        {badge ? <Badge>{badge}</Badge> : null}
      </GlyphWrapper>
      <Label>{label}</Label>
      <div className={NAVIGATION_BAR_ITEM_LINK_BOTTOM_INDICATOR_CLASS} />
    </NavLink>
  )
}

const GlyphWrapper = styled('span')`
  position: relative;
`

const Label = styled('div')`
  padding-left: 10px;
`

const Badge = styled('div')`
  position: absolute;
  top: -6px;
  left: 12px;
  height: 20px;
  min-width: 20px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.primaryColor};
  background-color: ${({ theme }) => theme.red7};
  color: white;
  ${text({
    fontSize: 12,
    fontWeight: FontWeight.Semibold,
    letterSpacing: 0.4,
    lineHeight: 17
  })};

  a:hover & {
    border-color: ${({ theme }) => theme.StatusGreen_green7};
    @media (hover: none) and (pointer: coarse) {
      border-color: ${({ theme }) => theme.primaryColor};
    }
  }

  a:active & {
    border-color: ${({ theme }) => theme.StatusGreen_green9};
  }
`
