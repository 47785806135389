import { useQuery, OperationVariables } from '@apollo/client'
import { useCallback, useState } from 'react'
import { DocumentNode } from 'graphql'
import { getPageInfo } from '../utils/getPageInfo'
import { DataWithPropertyData, PaginatedQueryVariables } from '../typings'
import { PaginatedQueryOptions, PaginatedQueryResult } from '.'

export const usePaginatedQuery = <
  TProperty extends string = string,
  TData extends DataWithPropertyData<TProperty> = any,
  TVariables extends PaginatedQueryVariables = OperationVariables
>(
  query: DocumentNode,
  options: PaginatedQueryOptions<TProperty, TData, TVariables>
): PaginatedQueryResult<TData, TVariables> => {
  const { property, ...queryOptions } = options
  const queryResult = useQuery<TData, TVariables>(query, queryOptions)
  const { data, fetchMore, loading } = queryResult
  const { hasNextPage, endCursor } = getPageInfo(data, property)

  // when use refetch need reset isLoadingMore
  // when change variables need reset isLoadingMore
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const loadMore = useCallback(async () => {
    if (isLoadingMore || loading || !hasNextPage) {
      return
    }
    setIsLoadingMore(true)

    return fetchMore({
      variables: {
        cursor: endCursor
      }
    }).finally(() => setIsLoadingMore(false))
  }, [isLoadingMore, loading, hasNextPage, fetchMore, endCursor])

  return {
    ...queryResult,
    loadMore,
    isLoadingMore,
    hasNextPage
  }
}
