import {
  AddUserToCompanyMutation,
  AddUserToCompanyMutationVariables
} from './__generated__/AddUserToCompanyMutation'
import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import { userFragment } from '../fragments/UserFragment'

const mutation = gql`
  ${userFragment}

  mutation AddUserToCompanyMutation(
    $input: AddUserToCompanyInput!
    $locale: String
  ) {
    addUserToCompany(input: $input) {
      user {
        ...UserFragment
      }
    }
  }
`

export function addUserToCompanyMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<
      AddUserToCompanyMutation,
      AddUserToCompanyMutationVariables
    >,
    'mutation'
  >
) {
  return apolloClient.mutate<
    AddUserToCompanyMutation,
    AddUserToCompanyMutationVariables
  >({
    mutation,
    ...options
  })
}
