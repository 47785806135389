import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { resources } from './resources'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    // debug: true,
    // saveMissing: true,
    fallbackLng: 'nl',
    load: 'currentOnly', // to have to avoid languages like en-US
    resources: resources,
    interpolation: {
      escapeValue: false
    },
    supportedLngs: ['en', 'nl', 'fr'], // to have to avoid languages like en-US
    returnNull: false
  })
  .catch()

export default i18n
