import styled from '@emotion/styled'
import { memo, forwardRef, CSSProperties, ComponentType, FC } from 'react'
import font, { FontWeight } from '../styles/font'
import { margin, MarginProps } from '../styles/margin'
import { padding, PaddingProps } from '../styles/padding'

export interface TypographyProps {
  color?: string
  fontSize?: number
  fontWeight?: FontWeight
  letterSpacing?: number
  lineHeight?: number
  textAlign?: 'center' | 'left' | 'right'
  textClip?: 'initial' | 'clip' | 'ellipsis' | 'none'
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none'
  textDecoration?: CSSProperties['textDecoration']
  whiteSpace?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | '-moz-pre-wrap'
    | 'break-spaces'
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-line'
    | 'pre-wrap'
}

export type TextProps = TypographyProps &
  MarginProps &
  PaddingProps & {
    Component?: keyof JSX.IntrinsicElements | ComponentType<any>
    className?: string
    [key: string]: any
  }

type TextComponentProps = TypographyProps &
  MarginProps &
  PaddingProps & { as?: any }

const TextComponent = styled('span')<TextComponentProps>`
  ${({ fontSize, fontWeight, lineHeight, letterSpacing, textTransform }) =>
    font(fontSize, fontWeight, lineHeight, letterSpacing, textTransform)};
  ${({ color }) => (color ? `color: ${color}` : null)};
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : null)};
  ${({ whiteSpace }) => (whiteSpace ? `white-space: ${whiteSpace};` : null)};
  ${({ textDecoration }) =>
    textDecoration ? `text-decoration: ${textDecoration};` : null};
  ${props => margin(props)};
  ${props => padding(props)};
  ${props =>
    props.textClip !== 'none'
      ? `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ${props.textClip};
    `
      : null};
`

export const Text: FC<TextProps> = memo(
  forwardRef<any, TextProps>(
    ({ Component = 'span', textClip = 'none', ...props }, ref) => (
      <TextComponent ref={ref} as={Component} textClip={textClip} {...props} />
    )
  )
)

export { Body } from './components/Body'
export { BodyAlt } from './components/BodyAlt'
export { Caption } from './components/Caption'
export { CaptionAlt } from './components/CaptionAlt'
