import { Typography } from '@vivaldis/antd-ui'
import { useTheme } from '@vivaldis/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const EmptyTextValue: FC = () => {
  const [t] = useTranslation('web_ui')

  const theme = useTheme()
  return (
    <Typography.Text color={theme.gray7}>
      {t('view_survey_screen_content.empty_text_value')}
    </Typography.Text>
  )
}
