import { FC, PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { i18n } from '../i18n'
import { setYupLocale } from './yupLocales'

export const I18nProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { i18n: i18nParent } = useTranslation()

  useEffect(() => {
    const handler = (lng: string) => {
      i18n.changeLanguage(lng)
    }

    i18nParent.on('languageChanged', handler)

    return () => {
      i18nParent.off('languageChanged', handler)
    }
  }, [i18nParent])

  // sync i18nParent.language with i18n.language
  useEffect(() => {
    if (i18nParent.language !== i18n.language) {
      i18n.changeLanguage(i18nParent.language).catch(() => {})
    }
  }, [i18nParent.language])

  useEffect(() => {
    // sync yup locale with selected language because translations for Yup errors work not in i18next
    setYupLocale(i18nParent.language)
  }, [i18nParent.language])

  return <>{children}</>
}
