import { Button } from '@vivaldis/antd-ui'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { usePosition } from '../../../providers/PositionProvider'
import { useStep } from '../../../providers/StepProvider'
import { useForm } from '../providers/FormProvider'
import { ActionBar as ActionBarBase } from '../../../../ActionBar'
import { SubmitButton } from '../../../../SubmitButton'
import { PositionFormStep3Values } from '../typings/PositionFormStep3Values'

interface Props {
  customSubmitButtonLabel?: string
  onPrevStep?: (values: PositionFormStep3Values) => void
}

export const ActionBar: FC<Props> = ({
  customSubmitButtonLabel,
  onPrevStep
}) => {
  const { t } = useTranslation('web_ui')
  const { isSubmitting, values } = useForm()
  const { setStep } = useStep()

  const position = usePosition()

  const handleGoToPreviousStep = useCallback(() => {
    onPrevStep?.(values)
    setStep(step => step - 1)
  }, [onPrevStep, setStep, values])

  const submitButtonLabel = useMemo(() => {
    if (customSubmitButtonLabel) {
      return customSubmitButtonLabel
    }

    return position
      ? t('position_form.step3.submit_button.update')
      : t('position_form.step3.submit_button.create')
  }, [customSubmitButtonLabel, position, t])

  return (
    <ActionBarBase
      leftContent={
        <Button
          type="default"
          shape="round"
          size="large"
          disabled={isSubmitting}
          onClick={handleGoToPreviousStep}
        >
          {t('position_form.step3.previous_step_button')}
        </Button>
      }
      rightContent={
        <SubmitButton isSubmitting={isSubmitting} label={submitButtonLabel} />
      }
    />
  )
}
