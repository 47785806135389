import { ArrowLeftSquare, CloseSquare } from '@vivaldis/icons'
import { FC, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SideSheetsHistoryContext } from '../../SideSheets/context/SideSheetsHistoryContext'
import { useGoBackTo } from '../../goBackTo/hooks/useGoBackTo'
import { useIsInSideSheet } from '../../SideSheets/hooks/useIsInSideSheet'
import { useSideSheetIndex } from '../../SideSheets/hooks/useSideSheetIndex'
import { ActionButton } from './ActionButton'

export const BackButton: FC = () => {
  const { t } = useTranslation('web_ui')
  const isInSideSheet = useIsInSideSheet()
  const sideSheetIndex = useSideSheetIndex()
  const history = useContext(SideSheetsHistoryContext)

  const navigate = useNavigate()
  const goBackTo = useGoBackTo()

  const onClick = useCallback(() => {
    if (isInSideSheet && history) {
      history.back()
    } else if (goBackTo) {
      navigate(goBackTo)
    }
  }, [isInSideSheet, history, navigate, goBackTo])
  return (
    <ActionButton
      title={t(
        isInSideSheet && sideSheetIndex === 0
          ? 'navigation_bar.close'
          : 'navigation_bar.back'
      )}
      onClick={onClick}
      icon={
        isInSideSheet && sideSheetIndex === 0 ? (
          <CloseSquare />
        ) : (
          <ArrowLeftSquare />
        )
      }
    />
  )
}
