import { FC } from 'react'
import { Avatar } from '@vivaldis/antd-ui'
import { UserOutlined } from '@ant-design/icons'
import { useUser } from '@vivaldis/session'

export const CompanyAvatar: FC = () => {
  const user = useUser()
  return (
    <Avatar
      size="large"
      icon={<UserOutlined />}
      src={user?.company?.logo}
      style={{ marginRight: 8 }}
    />
  )
}
