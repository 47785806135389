import { FC } from 'react'
import { Divider } from '../../../../../Divider'
import { usePosition } from '../../../../providers/PositionProvider'
import { CertificateTypes } from './components/CertificateTypes'
import { StudentsAllowed } from './components/StudentsAllowed'
import { VCACertificationRequired } from './components/VCACertificationRequired'
import { WorkingAndProtectiveEquipment } from './components/WorkingAndProtectiveEquipment'
import { WPSRequired } from './components/WPSRequired'
import { WPSUpdate } from './components/WPSUpdate'

interface Props {
  certificateTypes: string[]
  viewWPSUrl: string
  addWPSUrl: string
  editWPSUrl: string
}

export const FormContent: FC<Props> = ({
  certificateTypes,
  viewWPSUrl,
  addWPSUrl,
  editWPSUrl
}) => {
  const position = usePosition()

  return (
    <>
      <StudentsAllowed />
      <WorkingAndProtectiveEquipment />
      <VCACertificationRequired />
      <WPSRequired />
      {position ? (
        <WPSUpdate
          viewWPSUrl={viewWPSUrl}
          addWPSUrl={addWPSUrl}
          editWPSUrl={editWPSUrl}
        />
      ) : null}
      <Divider sectionSeparator />
      <CertificateTypes certificateTypes={certificateTypes} />
    </>
  )
}
