import { useFormikContext } from 'formik'
import { FC, PropsWithChildren, useEffect } from 'react'
import { Form as AntdForm } from '../../Form'
import type {
  FormProps as AntdFormProps,
  FormInstance as AntdFormInstance
} from '../../Form'
import { ErrorList } from './ErrorList'
import { FormItem } from './FormItem'
import { getAntdFormNamePathsFromFormikErrors } from './utils'

type FormValues = any

export type FormInstance = AntdFormInstance
export type FormProps = AntdFormProps

export const FormComponent: FC<PropsWithChildren<FormProps>> = ({
  layout = 'vertical',
  size = 'large',
  scrollToFirstError = true,
  ...restProps
}) => {
  const {
    handleReset,
    handleSubmit,
    initialValues,
    values,
    isValidating,
    isSubmitting,
    errors
  } = useFormikContext<FormValues>()
  const [form] = AntdForm.useForm<FormValues>()

  // TODO: use fields={fields} to keep values -> https://ant.design/components/form/#components-form-demo-global-state
  useEffect(() => form.setFieldsValue(values), [form, values])

  // scroll to first error on form submit
  useEffect(() => {
    if (scrollToFirstError && isSubmitting) {
      const fieldsWithError = getAntdFormNamePathsFromFormikErrors(errors)

      if (fieldsWithError.length) {
        // Note: antd form keep fields in the same order as they are on the screen, so we can relay on this order to find first inout with errors
        const firstAntdFormFieldWithError = form
          .getFieldsError()
          .find(antdFormField => {
            return fieldsWithError.includes(antdFormField.name?.[0])
          })

        if (firstAntdFormFieldWithError) {
          form.scrollToField(firstAntdFormFieldWithError.name, {
            block: 'center'
          })
        }
      }
    }
  }, [errors, form, isSubmitting, isValidating, scrollToFirstError])

  return (
    <AntdForm
      form={form}
      onReset={handleReset}
      onFinish={handleSubmit}
      initialValues={initialValues}
      noValidate
      action={restProps.action ?? '#'}
      layout={layout}
      size={size}
      {...restProps}
    />
  )
}

// should we do it with hoist-non-react-statics ?
export const Form = Object.assign(FormComponent, {
  ...AntdForm,
  Item: FormItem,
  ErrorList: ErrorList
})
