import styled from '@emotion/styled'
import { FC, forwardRef, PropsWithChildren } from 'react'
import { simpleText, text } from '../../font'
import { margin } from '../../styles/margin'
import { TextProps } from '../typings/TextProps'
import { Color } from '../../colors/Color'

const TextBase = styled('span')<TextProps & { ref?: any; as?: any }>`
  ${props => text(props)};
  ${props => (props.color ? `color: ${props.color};` : '')};
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')};
  ${props => margin(props)};
  text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
  ${props =>
    props.textClip !== 'none'
      ? `
      white-space: nowrap;
      overflow: hidden;
      ${props.textClip ? `text-overflow: ${props.textClip};` : ''}
    `
      : ''};
`

export const Text: FC<
  PropsWithChildren<
    TextProps & {
      ref?: any
    }
  >
> = forwardRef(
  (
    {
      Component = 'span',
      textClip = 'none',
      color = Color.Text.Default,
      ...props
    },
    ref
  ) => (
    <TextBase
      ref={ref as any}
      as={Component}
      textClip={textClip as 'none'}
      color={color}
      {...props}
    />
  )
)

const SimpleTextBase = styled('span')<TextProps & { ref?: any; as?: any }>`
  ${props => simpleText(props)};
  ${props => (props.color ? `color: ${props.color};` : '')};
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')};
  ${props => margin(props)};
  text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
  ${props =>
    props.textClip !== 'none'
      ? `
      white-space: nowrap;
      overflow: hidden;
      ${props.textClip ? `text-overflow: ${props.textClip};` : ''}
    `
      : ''};
`

export const SimpleText: FC<
  TextProps & {
    ref?: any
  }
> = forwardRef(({ Component = 'span', ...props }, ref) => (
  <SimpleTextBase ref={ref as any} as={Component} {...props} />
))
