import {
  FC,
  createContext,
  useContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  PropsWithChildren
} from 'react'

export enum PositionFormStep {
  Step1GeneralInformation,
  Step2Safety,
  Step3Premiums
}

export interface StepsContextValue {
  step: PositionFormStep
  setStep: Dispatch<SetStateAction<PositionFormStep>>
}

export const PositionFormStepContext = createContext<StepsContextValue>({
  step: PositionFormStep.Step1GeneralInformation,
  setStep: () => {}
})

export function useStep() {
  return useContext(PositionFormStepContext)
}

export const StepProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<PositionFormStep>(
    PositionFormStep.Step1GeneralInformation
  )

  const context = useMemo(
    (): StepsContextValue => ({ step: currentStep, setStep: setCurrentStep }),
    [currentStep]
  )

  return (
    <PositionFormStepContext.Provider value={context}>
      {children}
    </PositionFormStepContext.Provider>
  )
}
