import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Radio } from '@vivaldis/antd-ui'
import { useIsApprovedPosition } from '../../../../../providers/PositionProvider'

export const StudentsAllowed: FC = () => {
  const { t } = useTranslation('web_ui')

  const isApprovedPosition = useIsApprovedPosition()

  return (
    <Form.Item
      name="studentsAllowed"
      required
      label={t('position_form.step2.students_allowed.label')}
      tooltip={t('position_form.step2.students_allowed.tooltip')}
    >
      <Radio.Group name="studentsAllowed" disabled={isApprovedPosition}>
        <Radio name="studentsAllowed" value={true}>
          {t('position_form.step2.students_allowed.options.yes')}
        </Radio>
        <Radio name="studentsAllowed" value={false}>
          {t('position_form.step2.students_allowed.options.no')}
        </Radio>
      </Radio.Group>
    </Form.Item>
  )
}
