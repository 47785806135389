export interface EmployeeUser {
  id?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  birthdate?: string | null
  city?: string | null
  country?: string | null
}

export interface UserUser {
  id?: string | null
  name?: string | null
  email?: string | null
  companyId?: string | null
  companyName?: string | null
}

export type User = EmployeeUser | UserUser

type UserHandler = (user: User, language: string) => void

export const userHandlers: UserHandler[] = []

export const addOnUserHandler = (handler: UserHandler) => {
  userHandlers.push(handler)
}
