import { useMemo } from 'react'
import { useT } from '../../../i18n/useT'

const STUDENTS_TAX_CODE = '01'

export const useTaxCodeOptions = (
  taxCodes: string[],
  // When true      -> we show student only tax options
  // When false     -> we show none-student only tax options
  // When undefined -> we show all tax options
  isStudent?: undefined | true | false
) => {
  const t = useT('tax_code')

  return useMemo(
    () =>
      taxCodes
        .filter(taxCode => {
          if (isStudent === true) {
            return taxCode === STUDENTS_TAX_CODE
          }
          if (isStudent === false) {
            return taxCode !== STUDENTS_TAX_CODE
          }
          return true
        })
        .map(taxCode => ({
          label: t(`options.${taxCode}`, `${taxCode}`),
          value: taxCode
        })),
    [taxCodes, isStudent, t]
  )
}
