import { FC, PropsWithChildren, useMemo } from 'react'
import { createProgressBar } from './createProgressBar'
import { ProgressBarContext } from './ProgressBarContext'

export const ProgressBarProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const progressBar = createProgressBar()

  const progressBarContextValue = useMemo(() => progressBar, [progressBar])

  return (
    <ProgressBarContext.Provider value={progressBarContextValue}>
      {children}
    </ProgressBarContext.Provider>
  )
}
