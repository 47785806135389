import { gql } from '@apollo/client'
import { committeeFragment } from './CommitteeFragment'
import { functionCodeFragment } from './FunctionCodeFragment'

export const offerFragment = gql`
  ${committeeFragment}
  ${functionCodeFragment}

  fragment OfferFragment on Offer {
    committees {
      ...CommitteeFragment
    }
    connexysId
    dayContractsAllowed
    dimonaCoefficient
    dimonaEnabled
    functionCodes {
      ...FunctionCodeFragment
    }
    id
    name
    pdfUrl
    validUntil
    vivaldisOffice
  }
`
