enum Primary {
  Default = '#00B287',
  Shade1 = '#007D5F',
  Shade2 = '#003528',
  Tint1 = '#4DC9AB',
  Tint2 = '#CCF0E7'
}

enum Accent {
  Default = '#FFC857',
  Shade1 = '#B38C3D',
  Shade2 = '#4C3C1A',
  Tint1 = '#FFD989',
  Tint2 = '#FFEFCD'
}

enum Gray {
  Default = '#C1CBD5',
  Shade1 = '#8EA1B4',
  Shade2 = '#5A6875',
  Tint1 = '#E0E4EA',
  Tint2 = '#F5F5F8'
}

enum Green {
  Default = '#09C15F',
  Shade1 = '#068743',
  Shade2 = '#003528',
  Tint1 = '#53D48F',
  Tint2 = '#B5ECCF'
}

enum Red {
  Default = '#FF5A5F',
  Shade1 = '#B33F43',
  Shade2 = '#4C1B1C',
  Tint1 = '#FF8C8F',
  Tint2 = '#FFCECF'
}

enum White {
  Default = '#FFFFFF'
}

enum Black {
  Default = '#000000'
}

enum Text {
  Default = '#0F201B'
}

export type ColorTypes =
  | Accent
  | Black
  | Gray
  | Green
  | Primary
  | Red
  | Text
  | White

export const Color = {
  Accent,
  Black,
  Gray,
  Green,
  Primary,
  Red,
  Text,
  White
}
