import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import {
  SetSafetyAnswerMutation,
  SetSafetyAnswerMutationVariables
} from './__generated__/SetSafetyAnswerMutation'

const mutation = gql`
  mutation SetSafetyAnswerMutation($input: SetSafetyAnswerInput!) {
    setSafetyAnswer(input: $input) {
      clientMutationId
    }
  }
`

export function setSafetyAnswerMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<SetSafetyAnswerMutation, SetSafetyAnswerMutationVariables>,
    'mutation'
  >
) {
  return apolloClient.mutate<
    SetSafetyAnswerMutation,
    SetSafetyAnswerMutationVariables
  >({
    mutation,
    ...options
  })
}
