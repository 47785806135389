import {
  RevokeAccessToCompanyMutation,
  RevokeAccessToCompanyMutationVariables
} from './__generated__/RevokeAccessToCompanyMutation'
import { ApolloClient, MutationOptions, gql } from '@apollo/client'

const mutation = gql`
  mutation RevokeAccessToCompanyMutation($input: RevokeAccessToCompanyInput!) {
    revokeAccessToCompany(input: $input) {
      status
    }
  }
`

export function revokeAccessToCompanyMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<
      RevokeAccessToCompanyMutation,
      RevokeAccessToCompanyMutationVariables
    >,
    'mutation'
  >
) {
  return apolloClient.mutate<
    RevokeAccessToCompanyMutation,
    RevokeAccessToCompanyMutationVariables
  >({
    mutation,
    ...options
  })
}
