import { useField } from 'formik'
import { ChangeEventHandler, FC, useCallback, useMemo, useState } from 'react'
import { Input, type SearchProps } from '../../Input'
import { Select } from '../../Select'
import type { FormikFieldProps } from '../typings/FieldProps'

export type VATNumberInputProps = FormikFieldProps & SearchProps

// BE0999999999 | BE1999999999
// NL999999999B99

// Validation can be checked here: https://ec.europa.eu/taxation_customs/vies/#/vat-validation
//   - List of valid VAT numbers for Belgium: https://www.unizo.be/tools/ondernemingsnummer-opzoeken
//   - List of valid VAT numbers for Netherlands: https://nl.kompass.com/c/koninklijke-ahold-delhaize-n-v/nl111968/

export const VATNumberInput: FC<VATNumberInputProps> = ({
  name,
  onChange: _onChangeProp,
  onBlur: onBlurProp,
  value: _valueProp,
  ...restProps
}) => {
  const [field, , helpers] = useField(name)
  const { value, onBlur } = field
  const { setValue } = helpers

  const [countryPrefix, setCountryPrefix] = useState<'BE' | 'NL'>('BE')

  const handleBlur = useCallback(
    (event: any) => {
      onBlur(event)
      onBlurProp?.(event)
    },
    [onBlur, onBlurProp]
  )
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      const inputValue = String(event.target.value || '')
        .trim()
        .toUpperCase()
        .replace(/[^a-z0-9]/gi, '')

      // BE0999999999
      // NL999999999B99

      let newCountryPrefix = countryPrefix

      if (inputValue.startsWith('B')) {
        newCountryPrefix = 'BE'
      }
      if (inputValue.startsWith('N')) {
        newCountryPrefix = 'NL'
      }

      // remove BE, B, NL, N prefixes
      let inputValueWithoutPrefix = inputValue.replace(/^[a-z]{1,2}/gi, '')

      // Belgian VAT numbers have 12 characters [BE + 10 digits]. The last 2 digits constitute a control number.
      if (newCountryPrefix === 'BE') {
        inputValueWithoutPrefix = inputValueWithoutPrefix
          .replace(/[^0-9]/gi, '')
          .slice(0, 10)
      }

      // Dutch VAT number. The number consists of the country code “NL” which is followed by 12 character. These can be digits and letters. The symbols + and * can be parts of the VAT number as well. This new structure has been introduced in January 2020.
      if (newCountryPrefix === 'NL') {
        inputValueWithoutPrefix = inputValueWithoutPrefix.slice(0, 12)
      }

      setValue(`${newCountryPrefix}${inputValueWithoutPrefix}`)
      setCountryPrefix(newCountryPrefix)
    },
    [countryPrefix, setValue]
  )

  const handleCountyPrefixChange = useCallback(
    (selectedCountryPrefix: 'BE' | 'NL') => {
      const currentValue = String(value || '')
        .trim()
        .toUpperCase()
        .replace('BE', selectedCountryPrefix)
        .replace('NL', selectedCountryPrefix)

      const nextValue = currentValue.includes(selectedCountryPrefix)
        ? currentValue
        : `${selectedCountryPrefix}${currentValue}`

      setValue(nextValue)
      setCountryPrefix(selectedCountryPrefix)
    },
    [setValue, value]
  )

  const textValue = useMemo(() => {
    return String(value || '')
      .trim()
      .toUpperCase()
      .replace('BE', '')
      .replace('NL', '')
  }, [value])

  const addonBefore = (
    <Select
      size="large"
      value={countryPrefix}
      onChange={handleCountyPrefixChange}
    >
      <Select.Option value="BE">BE</Select.Option>
      <Select.Option value="NL">NL</Select.Option>
    </Select>
  )

  const placeholder = useMemo(() => {
    if (countryPrefix === 'BE') {
      return '0000.000.000'
    }
    return '0000.000.00B.00'
  }, [countryPrefix])

  return (
    <Input.Search
      addonBefore={addonBefore}
      value={textValue}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      {...restProps}
    />
  )
}
