import type { SelectProps } from '../index'

type FilterFunc = SelectProps['filterOption']

export const filterOption: FilterFunc = (inputValue, option) => {
  if (!inputValue) {
    return false
  }

  if (typeof option?.label === 'string') {
    return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  } else if (typeof option?.labelText === 'string') {
    return option.labelText.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  }
  return false
}
