import {
  UpdatePositionMutation,
  UpdatePositionMutationVariables
} from './__generated__/UpdatePositionMutation'
import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import { positionFragment } from '../fragments/PositionFragment'
import { PositionFragment } from '../fragments/__generated__/PositionFragment'

const mutation = gql`
  ${positionFragment}

  mutation UpdatePositionMutation(
    $input: UpdatePositionInput!
    $locale: String
  ) {
    updatePosition(input: $input) {
      position {
        ...PositionFragment
      }
    }
  }
`

export function updatePositionMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<UpdatePositionMutation, UpdatePositionMutationVariables>,
    'mutation'
  >
) {
  const input = options.variables?.input
  const fragment = apolloClient.readFragment<PositionFragment>({
    fragment: positionFragment,
    variables: {
      locale: options.variables?.locale
    },
    id: `Position:${options.variables?.input.id}`,
    fragmentName: 'PositionFragment'
  })

  return apolloClient.mutate<
    UpdatePositionMutation,
    UpdatePositionMutationVariables
  >({
    mutation,
    ...(fragment && input
      ? {
          optimisticResponse: {
            updatePosition: {
              __typename: 'UpdatePositionPayload',
              position: {
                ...fragment,
                __typename: 'Position',
                city: input.position.city ?? null,
                country: {
                  ...fragment.country!,
                  __typename: 'Country',
                  id: input.position.countryId!
                },
                description: input.position.description ?? null,
                id: input.id,
                latitude: input.position.latitude ?? null,
                longitude: input.position.longitude ?? null,
                name: input.position.name ?? null,
                street: input.position.street ?? null,
                streetNumber: input.position.streetNumber ?? null,
                zip: input.position.zip ?? null
              }
            }
          }
        }
      : {}),
    ...options
  })
}
