import styled from '@emotion/styled'
import {
  Children,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef
} from 'react'
import { ZIndex } from '../../styles/zIndex'
import { useSideSheetsNavigator } from '../hooks/useSideSheetsNavigator'
import { SideSheetsHistoryContext } from '../context/SideSheetsHistoryContext'

export const Overlay: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const scrollCapture = useRef<HTMLDivElement>()
  const { back } = useSideSheetsNavigator()
  const { index } = useContext(SideSheetsHistoryContext)!

  const handleScroll = useCallback(() => {
    if (scrollCapture.current) {
      scrollCapture.current.scrollTop = window.outerHeight * 5
    }
  }, [])

  useLayoutEffect(() => {
    if (scrollCapture.current) {
      scrollCapture.current.scrollTop = window.outerHeight * 5
    }
  }, [])

  const hasSideSheets = index > 0

  const handleBackdropClick = useCallback(() => {
    back()
  }, [back])

  return (
    <Container active={Children.count(children) > 1 || hasSideSheets}>
      <ScrollCapture onScroll={handleScroll} ref={scrollCapture as any}>
        <ScrollContent>
          <SideSheetsContainer>
            <Backdrop
              active={Children.count(children) > 1 || hasSideSheets}
              onClick={handleBackdropClick}
            />
            {children}
          </SideSheetsContainer>
        </ScrollContent>
      </ScrollCapture>
    </Container>
  )
}

const Container = styled('div')<{ active: boolean }>`
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: ${ZIndex.SideSheet};
  overflow: hidden;
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
`

const ScrollCapture = styled('div')`
  height: 100%;
  width: calc(100% + 100px);
  overflow-y: scroll;
`

const ScrollContent = styled('div')`
  position: relative;
  width: 100%;
  // for old Edge browser (version < 107) that doesn't support "dvh". We can remove it in 2024
  height: calc(10 * 100vh);
  height: calc(10 * 100dvh);
`

const SideSheetsContainer = styled('div')`
  position: sticky;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh; // for old Edge browser (version < 107) that doesn't support "dvh". We can remove it in 2024
  height: 100dvh;
`

const Backdrop = styled('div')<{ active: boolean }>`
  width: 100%;
  height: 100vh; // for old Edge browser (version < 107) that doesn't support "dvh". We can remove it in 2024
  height: 100dvh;
  background-color: ${({ active }) =>
    active ? 'rgba(245, 245, 248, 0.7)' : 'rgba(245, 245, 248, 0)'};
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  transition: background-color 0.3s;
`
