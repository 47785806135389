import { enGB, fr, nl, type Locale } from 'date-fns/locale'

const dateFnsLocales: Record<string, Locale> = {
  en: enGB,
  fr: fr,
  nl: nl
}

export const getDateFnsLocale = (shortLocaleName: string): Locale => {
  return dateFnsLocales[shortLocaleName] || dateFnsLocales.en
}
