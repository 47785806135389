import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const IconSquareSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.664 3.5C5.135 3.5 3.5 5.233 3.5 7.916V16.084C3.5 18.767 5.135 20.5 7.664 20.5H16.332C18.864 20.5 20.5 18.767 20.5 16.084V7.916C20.5 5.233 18.864 3.5 16.334 3.5H7.664ZM16.332 22H7.664C4.276 22 2 19.622 2 16.084V7.916C2 4.378 4.276 2 7.664 2H16.334C19.723 2 22 4.378 22 7.916V16.084C22 19.622 19.723 22 16.332 22ZM11.994 16.75C11.58 16.75 11.244 16.414 11.244 16V12C11.244 11.586 11.58 11.25 11.994 11.25C12.408 11.25 12.744 11.586 12.744 12V16C12.744 16.414 12.408 16.75 11.994 16.75ZM10.9939 8.2041C10.9939 8.7571 11.4459 9.2041 11.9989 9.2041C12.5519 9.2041 12.9989 8.7571 12.9989 8.2041C12.9989 7.6511 12.5519 7.2041 11.9989 7.2041H11.9889C11.4369 7.2041 10.9939 7.6511 10.9939 8.2041Z"
    />
  </svg>
)

export const IconSquare = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={IconSquareSvg} {...props} ref={ref} />
)
