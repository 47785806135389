import { FC } from 'react'
import { Form } from '@vivaldis/antd-ui'
import { PositionFormStep, useStep } from '../../providers/StepProvider'
import { Premium } from '../../typings/Premium'
import { FormContent } from './components/FormContent'
import { ActionBar } from './components/ActionBar'
import {
  FormProvider,
  PositionFormProviderProps
} from './providers/FormProvider'
import type { PositionFormStep3Values } from './typings/PositionFormStep3Values'

export interface Step3Props {
  // FormProvider props
  onSubmit: PositionFormProviderProps['onSubmit']
  onPrevStep?: (values: PositionFormStep3Values) => void
  initialValues: PositionFormProviderProps['initialValues']
  // form props
  premiums: Premium[]
  customSubmitButtonLabel?: string
  showEcoChequeFields: boolean
}

export const Step3: FC<Step3Props> = ({
  onSubmit,
  onPrevStep,
  initialValues,
  premiums,
  customSubmitButtonLabel,
  showEcoChequeFields
}) => {
  const { step } = useStep()

  if (step !== PositionFormStep.Step3Premiums) {
    return null
  }

  return (
    <FormProvider
      onSubmit={onSubmit}
      initialValues={initialValues}
      premiums={premiums}
      showEcoChequeFields={showEcoChequeFields}
    >
      <Form name="position-form-step-3" id="position-form-step-3">
        <FormContent
          premiums={premiums}
          showEcoChequeFields={showEcoChequeFields}
        />
        <ActionBar
          customSubmitButtonLabel={customSubmitButtonLabel}
          onPrevStep={onPrevStep}
        />
      </Form>
    </FormProvider>
  )
}
