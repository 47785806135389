import {
  UpdateContractsMutation,
  UpdateContractsMutationVariables
} from './__generated__/UpdateContractsMutation'
import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import { contractFragment } from '../fragments/ContractFragment'

const mutation = gql`
  ${contractFragment}

  mutation UpdateContractsMutation($input: UpdateContractsInput!) {
    updateContracts(input: $input) {
      contracts {
        ...ContractFragment
      }
    }
  }
`

export function updateContractsMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<UpdateContractsMutation, UpdateContractsMutationVariables>,
    'mutation'
  >
) {
  return apolloClient.mutate<
    UpdateContractsMutation,
    UpdateContractsMutationVariables
  >({
    mutation,
    ...options
  })
}
