import { FC, ReactNode } from 'react'
import { Typography } from '@vivaldis/ui'
import styled from '@emotion/styled'

interface Props {
  title: ReactNode
  status: ReactNode
  description: ReactNode
  actions: ReactNode
  minHeight?: number
}

export const MobileListItem: FC<Props> = ({
  title,
  status,
  description,
  actions,
  minHeight = 96
}) => {
  return (
    <Base minHeight={minHeight}>
      <TitleAndStatusBase>
        <Typography.BodyAlt textClip="ellipsis" letterSpacing={0.2}>
          {title}
        </Typography.BodyAlt>
        {status}
      </TitleAndStatusBase>
      <DescriptionBase>{description}</DescriptionBase>
      <ActionsContainer>{actions}</ActionsContainer>
    </Base>
  )
}

const Base = styled('li')<{ minHeight: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  min-height: ${props => props.minHeight}px;
  overflow: hidden;
  margin-bottom: 12px;
  padding: 8px 12px;
  background-color: ${props => props.theme.white};
  border: 2px solid ${props => props.theme.gray1};
  border-radius: 6px;
`

const TitleAndStatusBase = styled('div')`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`

const DescriptionBase = styled('div')`
  display: flex;
  overflow: hidden;
`

const ActionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
