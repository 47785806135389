import { Form } from '@vivaldis/antd-ui'
import type { EmployeeInput } from '@vivaldis/graphql'
import { FormikProps } from 'formik'
import { FC } from 'react'
import { ActionBar } from './components/ActionBar'
import { FormContent } from './components/FormContent'
import {
  EditEmployeeProfileFormValues,
  FormProvider
} from './provider/FormProvider'
import { CountryType } from './types/CountryType'
import { Employee } from './types/Employee'

export interface EditEmployeeProfileFormProps {
  employee: Employee
  isEmployeeContractReady: boolean
  onSubmit: (employeeInput: EmployeeInput) => Promise<any>
  onPartialEmployeeUpdate: (employeeInput: EmployeeInput) => Promise<any>
  initialValues?: Partial<
    FormikProps<EditEmployeeProfileFormValues>['initialValues']
  >
  countries: CountryType[]
  taxCodes: string[]
}

export const EditEmployeeProfileForm: FC<EditEmployeeProfileFormProps> = ({
  employee,
  isEmployeeContractReady,
  onSubmit,
  onPartialEmployeeUpdate,
  initialValues,
  countries,
  taxCodes
}) => {
  return (
    <FormProvider
      countries={countries}
      initialValues={initialValues}
      isEmployeeContractReady={isEmployeeContractReady}
      onSubmit={onSubmit}
    >
      <Form
        id="edit-employee-form"
        name="edit-employee-form"
        autoComplete="off"
      >
        <FormContent
          employee={employee}
          countries={countries}
          onPartialEmployeeUpdate={onPartialEmployeeUpdate}
          taxCodes={taxCodes}
        />
        <ActionBar />
      </Form>
    </FormProvider>
  )
}
