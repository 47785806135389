import { CloseSquare } from '@vivaldis/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from './ActionButton'

export interface NavigationBarCloseButtonProps {
  onClick?: () => void
}

export const CloseButton: FC<NavigationBarCloseButtonProps> = ({ onClick }) => {
  const { t } = useTranslation('web_ui')
  return (
    <ActionButton
      title={t('navigation_bar.close')}
      onClick={onClick}
      icon={<CloseSquare />}
    />
  )
}
