import { InfiniteTable, InfiniteTableProps } from '@vivaldis/antd-ui'
import { CSSProperties, FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ExpandedRow } from './components/ExpandedRow'
import type { Offer } from './typings/Offer'
import type { VivaldisOffices } from './typings/VivaldisOffices'

export interface OffersTableViewProps {
  type: 'selection' | 'payroll'
  offers: Offer[]
  error?: Error
  showLoading: boolean
  vivaldisOffices: VivaldisOffices[]
  style?: CSSProperties
}

export const OffersTableView: FC<OffersTableViewProps> = ({
  type,
  offers,
  showLoading,
  error,
  vivaldisOffices,
  style
}) => {
  const { t } = useTranslation('web_ui')

  const columns: InfiniteTableProps<Offer>['columns'] = useMemo(() => {
    const commonColumns: InfiniteTableProps<Offer>['columns'] = [
      {
        title: t('offers_table_view.name.title'),
        render: (_value, offer) => {
          return `${offer.name || '-'}`
        }
      },
      {
        title: t('offers_table_view.vivaldis_office.title'),
        key: 'vivaldisOffice',
        render: (_value, offer) => {
          const vivaldisOffice = vivaldisOffices.find(
            office => office.code === offer.vivaldisOffice
          )
          return `${vivaldisOffice?.name || offer.vivaldisOffice || '-'}`
        }
      },
      ...(type === 'selection'
        ? ([
            {
              title: t('offers_table_view.valid_until.title'),
              key: 'validUntil',
              render: (_value, offer) => {
                return `${offer.validUntil}`
              }
            }
          ] satisfies InfiniteTableProps<Offer>['columns'])
        : []),
      {
        title: t('offers_table_view.day_contracts_allowed.title'),
        key: 'dayContractsAllowed',
        render: (_value, offer) => {
          return offer.dayContractsAllowed ? t('yes') : t('no')
        }
      }
      // {
      //   title: t('offers_table_view.actions.title'),
      //   key: 'actions',
      //   render: (_value, offer) => {
      //     if (!offer.pdfUrl) {
      //       return null
      //     }
      //
      //     return (
      //       <Typography.Link
      //         href={offer.pdfUrl}
      //         target={'_blank'}
      //         rel="noopener noreferrer"
      //         strong
      //         download
      //       >
      //         {t('offers_table_view.actions.open_pdf')}
      //       </Typography.Link>
      //     )
      //   }
      // }
    ]

    return commonColumns
  }, [t, type, vivaldisOffices])

  const expandedRowRender = useCallback(
    (offer: Offer) => {
      return <ExpandedRow offer={offer} type={type} />
    },
    [type]
  )

  return (
    <InfiniteTable<Offer>
      dataSource={offers}
      columns={columns}
      rowKey="id"
      expandable={{
        expandedRowRender
      }}
      loading={showLoading}
      error={error}
      isLoadingMore={false}
      onLoadMore={() => {}}
      hasNextPage={false}
      style={style}
    />
  )
}
