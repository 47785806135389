import { FontWeight } from './typings/FontWeight'
import { fontWeight } from './platform/fontWeight'

export const lineHeight = (lineHeight: number): any =>
  `line-height: ${lineHeight}px;`

export const letterSpacing = (letterSpacing: number) =>
  `letter-spacing: ${letterSpacing}px;`

export const fontSize = (size?: number, lineHeightValue?: number) => `
${size ? `font-size: ${size}px;` : ''}
${
  lineHeightValue
    ? lineHeight(lineHeightValue)
    : size
      ? lineHeight(Math.round(1.3666666667 * size))
      : ''
};
${size ? `letter-spacing: ${size * 0.016 * -1}px;` : ''}
`

export const textTransform = (
  transformation: 'uppercase' | 'lowercase' | 'capitalize'
) => `text-transform: ${transformation};`

export interface TextOptions {
  fontSize?: number
  fontWeight?: FontWeight
  lineHeight?: number
  letterSpacing?: number
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | undefined
}

export const text = ({
  fontSize: fontSizeValue = 16,
  fontWeight: fontWeightValue = FontWeight.Regular,
  lineHeight: lineHeightValue,
  letterSpacing: letterSpacingValue,
  textTransform: textTransformValue
}: TextOptions) => `
${fontSize(fontSizeValue, lineHeightValue)}
${fontWeight(fontWeightValue)}
${letterSpacingValue ? letterSpacing(letterSpacingValue) : ''}
${textTransformValue ? textTransform(textTransformValue) : ''}
`

export const simpleText = ({
  fontSize: fontSizeValue,
  fontWeight: fontWeightValue,
  lineHeight: lineHeightValue,
  letterSpacing: letterSpacingValue,
  textTransform: textTransformValue
}: TextOptions) => `
${fontSize(fontSizeValue, lineHeightValue)}
${fontWeight(fontWeightValue)}
${letterSpacingValue ? letterSpacing(letterSpacingValue) : ''}
${textTransformValue ? textTransform(textTransformValue) : ''}
`
