import AsyncStorage from 'localforage'
import { createApolloClient as createApolloClientBase } from '@vivaldis/common'
import { typePolicies } from '@vivaldis/graphql'
import { sessionSelector, invalidate, UserType } from '@vivaldis/session'
import { tracking } from '@vivaldis/tracking'

export function createApolloClient() {
  return createApolloClientBase({
    storage: AsyncStorage,
    sessionSelector: sessionSelector,
    tracking: tracking,
    userType: UserType.User,
    apiUrl: process.env.REACT_APP_API_URL as string,
    appVersion: `${process.env.REACT_APP_VERSION}`,
    invalidate: invalidate,
    apolloClientOptions: {
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network'
        }
      }
    },
    apolloInMemoryCacheConfig: {
      typePolicies
    }
  })
}
