// We are using date-fns, so we need to replace default component
// https://ant.design/docs/react/use-custom-date-library#use-date-fns
import { DatePicker as AntdDatePicker, GetProps, GetRef } from 'antd'
import dateFnsGenerateConfig from './config/dateFnsGenerateConfig'

export const DatePicker = AntdDatePicker.generatePicker<Date>(
  dateFnsGenerateConfig
)

export type DatePickerProps = GetProps<typeof DatePicker>
export type DatePickerRef = GetRef<typeof DatePicker>

export type RangePickerProps = GetProps<typeof DatePicker.RangePicker>
