import { Form, RichTextEditor } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const Description: FC = () => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name="description"
      label={t('position_form.step1.description.label', { ns: 'web_ui' })}
      required
      hasFeedback={false}
    >
      <RichTextEditor name="description" placeholder={t('form.required')} />
    </Form.Item>
  )
}
