import { gql } from '@apollo/client'
import { userFragment } from '@vivaldis/graphql'

export const userQuery = gql`
  ${userFragment}

  query UserQuery($locale: String) {
    user {
      ...UserFragment
    }
  }
`
