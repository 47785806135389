import storage from 'localforage'
import {
  createReduxStore as createReduxStoreBase,
  compose
} from '@vivaldis/common'
import { combineReducers, ReducersMapObject } from 'redux'
import { Saga } from 'redux-saga'
import { combineSessionSaga, createSessionReducer } from '@vivaldis/session'
import {
  createAppReducer,
  combineAppSaga,
  reduxPersistRehydrated
} from '@vivaldis/app'
import { Action } from '../typings/store'

export function createReduxStore(
  reducers: ReducersMapObject<any, Action>,
  saga: Saga
) {
  return createReduxStoreBase({
    reducer: combineReducers(
      compose(createAppReducer, createSessionReducer(storage))(reducers)
    ),
    saga: compose(combineAppSaga, combineSessionSaga)(saga),
    debug: process.env.NODE_ENV !== 'production',
    middlewares: [],
    persistorCallback: store => {
      store.dispatch(reduxPersistRehydrated())
    }
  })
}
