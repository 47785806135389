import { FC } from 'react'
import { Divider } from '../../../Divider'
import type { EditEmployeeProfileFormProps } from '../../index'
import { CountryType } from '../../types/CountryType'
import { Employee } from '../../types/Employee'
import { ContactInformation } from './components/ContactInformation'
import { GeneralInformation } from './components/GeneralInformation'
import { DocumentsInformation } from './components/DocumentsInformation'
import { WorkProfileInformation } from './components/WorkProfileInformation'

interface Props {
  employee: Employee
  countries: CountryType[]
  onPartialEmployeeUpdate: EditEmployeeProfileFormProps['onPartialEmployeeUpdate']
  taxCodes: string[]
}

export const FormContent: FC<Props> = ({
  employee,
  countries,
  onPartialEmployeeUpdate,
  taxCodes
}) => {
  return (
    <>
      <GeneralInformation countries={countries} />
      <Divider sectionSeparator />
      <ContactInformation countries={countries} />
      <Divider sectionSeparator />
      <DocumentsInformation
        countries={countries}
        employee={employee}
        onPartialEmployeeUpdate={onPartialEmployeeUpdate}
      />
      <Divider sectionSeparator />
      <WorkProfileInformation taxCodes={taxCodes} />
    </>
  )
}
