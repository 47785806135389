import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OvertimePayingStatusesEnum } from '@vivaldis/graphql'

interface Props {
  overtimePayingStatus: OvertimePayingStatusesEnum
}

export const OvertimePayingStatusLabel = ({ overtimePayingStatus }: Props) => {
  const { t } = useTranslation('web_ui')

  const label = useMemo(() => {
    switch (overtimePayingStatus) {
      case OvertimePayingStatusesEnum.PAID: {
        return t(
          'offers_table_view.committees.fields.overtime_paying_status.options.paid'
        )
      }
      case OvertimePayingStatusesEnum.NOT_APPLICABLE: {
        return t(
          'offers_table_view.committees.fields.overtime_paying_status.options.not_applicable'
        )
      }
      case OvertimePayingStatusesEnum.NOT_PAID: {
        return t(
          'offers_table_view.committees.fields.overtime_paying_status.options.not_paid'
        )
      }
    }
    return null
  }, [overtimePayingStatus, t])

  return <>{label}</>
}
