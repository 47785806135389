import styled from '@emotion/styled'
import { FC, PropsWithChildren } from 'react'
import { animated, useSpring } from 'react-spring'
import useMeasure from 'react-use-measure'

interface CollapseProps {
  isOpen: boolean
  style?: { overflow?: string }
  className?: string
}

export const Collapse: FC<PropsWithChildren<CollapseProps>> = ({
  isOpen,
  style = {},
  className,
  children,
  ...other
}) => {
  const [ref, { height }] = useMeasure()

  const [animatesStyles] = useSpring(
    {
      from: { height: 0 },
      to: { height: isOpen ? height : 0 }
    },
    [isOpen, height]
  )

  return (
    <Container
      style={{
        height: animatesStyles.height,
        ...style
      }}
      className={className}
      {...other}
    >
      <div ref={ref} style={{ overflow: 'auto' }}>
        {children}
      </div>
    </Container>
  )
}

const Container = styled(animated.div)`
  will-change: height;
  overflow: hidden;
`
