import { Typography, TextStylePreset } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { List } from 'antd'
import { Divider } from '../../../Divider'
import {
  EmployeeProfileQuery_employee,
  EmployeeProfileQuery_employee_educations as Education
} from '../../typings/EmployeeProfileQuery'
import { EducationListItem } from './components/EducationListItem'

export interface EducationsProps {
  employee: EmployeeProfileQuery_employee
}

export const Educations: FC<EducationsProps> = ({ employee }) => {
  const [t] = useTranslation('web_ui')

  const { educations } = employee

  if (!educations.length) {
    return null
  }

  return (
    <>
      <Typography.Paragraph
        preset={TextStylePreset.Semibold16}
        marginBottom={16}
      >
        {t('employee_profile.education', { count: educations.length })}
      </Typography.Paragraph>
      <List<Education>
        itemLayout="horizontal"
        dataSource={educations}
        renderItem={education => (
          <EducationListItem key={education.id} education={education} />
        )}
      />
      <Divider sectionSeparator />
    </>
  )
}
