import { createSelector } from 'reselect'
import { ActionCableState, ActionCableChannelState } from '../reducers'
import { ACTION_CABLE_STATE_KEY } from '..'

interface State {
  [ACTION_CABLE_STATE_KEY]: ActionCableState
}

export const actionCableStateSelector = <S extends State = State>(
  state: S
): ActionCableState => state[ACTION_CABLE_STATE_KEY]

export const actionCableChannelsSelector: (
  state: State
) => ActionCableChannelState[] = createSelector(
  actionCableStateSelector,
  app => app.channels
)

export const isActionCableOpenSelector: (state: State) => boolean =
  createSelector(actionCableStateSelector, app => app.open)

export const shouldActionCableReconnectSelector: (state: State) => boolean =
  createSelector(actionCableStateSelector, app => app.shouldReconnect)
