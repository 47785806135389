import { format } from 'date-fns'
import { FC, useMemo } from 'react'
import { Contract_workdays as Workday } from '../../../typings/Contract'

interface Props {
  startsAt: Workday['startsAt']
}

export const StartTime: FC<Props> = ({ startsAt }) => {
  const startsTime = useMemo(() => {
    return format(new Date(startsAt), 'HH:mm')
  }, [startsAt])

  return <>{startsTime}</>
}
