import styled from '@emotion/styled'
import {
  Ref,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
  useCallback,
  PropsWithChildren
} from 'react'
import { padding, PaddingProps } from '@vivaldis/ui'
import { ZIndex } from '../../styles/zIndex'
import type { MasterProps } from './typings/MasterProps'

export const Master = forwardRef<
  HTMLDivElement,
  PropsWithChildren<MasterProps>
>(
  (
    {
      children,
      paddingBottom,
      paddingHorizontal,
      paddingLeft,
      paddingRight,
      paddingVertical,
      paddingTop
    },
    ref
  ) => {
    const [top, setTop] = useState(0)
    const wrapper = useRef<HTMLDivElement>()
    const topRef = useRef(top)
    topRef.current = top

    const measure = useCallback(() => {
      if (wrapper.current && wrapper.current.offsetTop !== topRef.current) {
        setTop(wrapper.current?.offsetTop)
      }
    }, [])

    useLayoutEffect(() => {
      measure()
      // Fixes strange iPad bug from time to time
      setTimeout(() => measure(), 0)
      setTimeout(() => measure(), 100)
      setTimeout(() => measure(), 500)
    })

    useEffect(() => {
      const handler = () => {
        measure()
        // Fixes strange iPad bug from time to time
        setTimeout(() => measure(), 0)
        setTimeout(() => measure(), 100)
        setTimeout(() => measure(), 500)
      }
      window.addEventListener('resize', handler)
      return () => {
        window.removeEventListener('resize', handler)
      }
    }, [measure])

    return (
      <Wrapper ref={wrapper as any}>
        <Container
          top={top}
          paddingBottom={paddingBottom}
          paddingHorizontal={paddingHorizontal}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          paddingVertical={paddingVertical}
          paddingTop={paddingTop}
          ref={ref}
        >
          {children}
        </Container>
      </Wrapper>
    )
  }
)

interface ContainerProps extends PaddingProps {
  ref?: Ref<any>
  top: number
}

const Wrapper = styled('div')`
  width: 320px;
`

const Container = styled('div')<ContainerProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  ${props => padding(props)};
  position: fixed;
  top: ${({ top }) => `${top}px`};
  left: 0;
  right: 0;
  bottom: 0;
  width: 320px;
  overflow-y: auto;
  z-index: ${ZIndex.Master};
`
