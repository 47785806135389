import { useMemo } from 'react'
import { useT } from '../../../i18n/useT'

interface MaritalStatesEnum {
  [key: string]: string
}

export const useMaritalStateOptions = (
  maritalStatesEnum: MaritalStatesEnum
) => {
  const t = useT('marital_state')

  return useMemo(
    () =>
      Object.values(maritalStatesEnum).map(value => ({
        label: t(`options.${value}` as any),
        value: value
      })),
    [maritalStatesEnum, t]
  )
}
