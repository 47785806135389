import { OperationVariables } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { useEffect } from 'react'
import { usePaginatedQuery } from '../paginatedQuery/usePaginatedQuery'
import { DataWithPropertyData, PaginatedQueryVariables } from '../typings'
import { EagerPaginatedQueryOptions, EagerPaginatedQueryResult } from './index'

export function useEagerPaginatedQuery<
  TProperty extends string = string,
  TData extends DataWithPropertyData<TProperty> = any,
  TVariables extends PaginatedQueryVariables = OperationVariables
>(
  query: DocumentNode,
  options: EagerPaginatedQueryOptions<TProperty, TData, TVariables>
): EagerPaginatedQueryResult<TData, TVariables> {
  const paginatedQueryResult = usePaginatedQuery<TProperty, TData, TVariables>(
    query,
    options
  )

  const { hasNextPage, loading, isLoadingMore, loadMore } = paginatedQueryResult

  useEffect(() => {
    if (hasNextPage && !loading && !isLoadingMore) {
      loadMore().catch(() => null)
    }
  }, [hasNextPage, isLoadingMore, loadMore, loading])

  return paginatedQueryResult
}
