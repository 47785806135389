import { ThunderboltOutlined } from '@ant-design/icons'
import { Popconfirm, Tooltip } from '@vivaldis/antd-ui'
import { ShieldDone } from '@vivaldis/icons'
import { useTheme } from '@vivaldis/ui'
import {
  isAfter,
  isMonday,
  isToday,
  isYesterday,
  startOfDay,
  subDays
} from 'date-fns'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Contract_workdays as Workday } from '../../../typings/Contract'

interface Props {
  workday: Workday
  onReSendWorkObstruction?: (workObstructionId: string) => any
}

export const StatusAndWorkObstructionIcon: FC<Props> = ({
  workday,
  onReSendWorkObstruction
}) => {
  const theme = useTheme()

  const { workObstruction } = workday

  const { t } = useTranslation('web_ui')

  const handleOnReSendWorkObstructionConfirm = useCallback(async () => {
    if (onReSendWorkObstruction && workObstruction?.id) {
      return await onReSendWorkObstruction(workObstruction?.id)
    }
  }, [onReSendWorkObstruction, workObstruction?.id])

  const canReSendWorkObstruction = useMemo(() => {
    if (!onReSendWorkObstruction) {
      return false
    }

    if (!workObstruction || !workObstruction.id || workObstruction.processed) {
      return false
    }

    // user can create work obstruction for today
    if (isToday(new Date(workday.startsAt))) {
      return true
    }

    // user can create work obstruction for yesterday
    if (isYesterday(new Date(workday.startsAt))) {
      return true
    }

    // user can create work obstruction for last Friday, Saturday, Sunday, if today is Monday
    if (isMonday(new Date())) {
      // If today is Monday, we allow to create workdays for 3 previous days (Friday, Saturday, Sunday).
      const startOfTheLastFriday = startOfDay(subDays(new Date(), 3))
      return isAfter(new Date(workday.startsAt), startOfTheLastFriday)
    }

    return false
  }, [onReSendWorkObstruction, workObstruction, workday.startsAt])

  if (!workObstruction) {
    return <ShieldDone style={{ color: theme.primaryColor, fontSize: 24 }} />
  }

  // Green lightning = work obstruction was successfully created in 4D
  if (workObstruction.processed) {
    return (
      <Tooltip
        title={t('contract_schedule.work_obstruction.tooltips.created_in_4D')}
      >
        <ThunderboltOutlined
          style={{ color: theme.primaryColor, fontSize: 24 }}
        />
      </Tooltip>
    )
  }

  // we show this confirmation only for SU (when this function is provided)
  if (canReSendWorkObstruction) {
    return (
      <Popconfirm
        title={t(
          'contract_schedule.work_obstruction.tooltips.not_created_in_4D'
        )}
        onConfirm={handleOnReSendWorkObstructionConfirm}
        trigger="hover"
        okText={t(
          'contract_schedule.work_obstruction.push_work_obstruction_button'
        )}
        showCancel={false}
      >
        <ThunderboltOutlined style={{ color: theme.red6, fontSize: 24 }} />
      </Popconfirm>
    )
  }

  // Red lightning = creation failed
  return (
    <Tooltip
      title={t('contract_schedule.work_obstruction.tooltips.not_created_in_4D')}
    >
      <ThunderboltOutlined style={{ color: theme.red6, fontSize: 24 }} />
    </Tooltip>
  )
}
