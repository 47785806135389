export interface MarginProps {
  marginBottom?: number
  marginTop?: number
  marginLeft?: number
  marginRight?: number
  marginVertical?: number
  marginHorizontal?: number
}

export function margin(props: MarginProps & { [key: string]: any }) {
  return `
    ${
      props.marginBottom !== undefined
        ? `margin-bottom: ${props.marginBottom}px;`
        : ''
    }
    ${props.marginTop !== undefined ? `margin-top: ${props.marginTop}px;` : ''};
    ${
      props.marginLeft !== undefined
        ? `margin-left: ${props.marginLeft}px;`
        : ''
    }
    ${
      props.marginRight !== undefined
        ? `margin-right: ${props.marginRight}px;`
        : ''
    }
    ${
      props.marginVertical !== undefined
        ? `
      margin-bottom: ${props.marginVertical}px;
      margin-top: ${props.marginVertical}px;`
        : ''
    }
    ${
      props.marginHorizontal !== undefined
        ? `
      margin-left: ${props.marginHorizontal}px;
      margin-right: ${props.marginHorizontal}px;
      `
        : ''
    }
  `
}
