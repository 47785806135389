import type { EmployeeInput } from '@vivaldis/graphql'
import { format } from 'date-fns'
import { EditEmployeeProfileFormValues } from '../provider/FormProvider'

export const employeeFormValuesToEmployeeInput = (
  values: EditEmployeeProfileFormValues
): EmployeeInput => {
  return {
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    ssn: values.ssn,
    gender: values.gender,
    mobile: values.mobile,
    isStudent: values.isStudent,
    profile: {
      birthdate: values.birthdate
        ? format(values.birthdate, 'yyyy-MM-dd')
        : null,
      placeOfBirth: values.placeOfBirth,
      street: values.street,
      streetNumber: values.streetNumber,
      buildingNumber: values.buildingNumber,
      zip: values.zip,
      city: values.city,
      countryId: values.countryId,
      countryOfBirthId: values.countryOfBirthId,
      nationalityCountryId: values.nationalityCountryId,
      idCardNumber: values.idCardNumber,
      idCardExpiresOn: values.idCardExpiresOn
        ? format(values.idCardExpiresOn, 'yyyy-MM-dd')
        : null
    },
    workProfile: {
      bankAccount: values.bankAccount,
      bic: values.bic || null,
      workPermitExpiresOn: values.workPermitExpiresOn
        ? format(values.workPermitExpiresOn, 'yyyy-MM-dd')
        : null,
      residencePermitExpiresOn: values.residencePermitExpiresOn
        ? format(values.residencePermitExpiresOn, 'yyyy-MM-dd')
        : null,
      residencePermitCode: values.residencePermitCode,
      taxCode: values.taxCode || undefined,
      maritalState: values.maritalState || undefined,
      maritalDate: values.maritalDate
        ? format(values.maritalDate, 'yyyy-MM-dd')
        : undefined,
      childrenNumber: values.childrenNumber,
      childrenWithDisabilitiesNumber: values.childrenWithDisabilitiesNumber,
      dependentPersonsNumber: values.dependentPersonsNumber,
      dependentPersonsWithDisabilitiesNumber:
        values.dependentPersonsWithDisabilitiesNumber,
      involuntaryPartTime: values.involuntaryPartTime
    }
  }
}
