import styled from '@emotion/styled'
import { Typography } from '@vivaldis/antd-ui'
import { FC } from 'react'

interface Props {
  question: string
}

export const SafetyQuestion: FC<Props> = ({ question }) => {
  return (
    <Base>
      <Typography.Paragraph
        ellipsis={{
          rows: 2,
          expandable: true
        }}
        title={question}
        style={{ marginBottom: 0 }}
      >
        {question}
      </Typography.Paragraph>
    </Base>
  )
}

const Base = styled('div')`
  max-width: 40ch;
  word-break: break-all;
  white-space: pre-wrap;
`
