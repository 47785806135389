import { gql } from '@apollo/client'
import { educationDegreeFragment } from './EducationDegreeFragment'

export const educationSubjectFragment = gql`
  ${educationDegreeFragment}

  fragment EducationSubjectFragment on EducationSubject {
    educationDegree {
      ...EducationDegreeFragment
    }
    id
    name(locale: $locale)
  }
`
