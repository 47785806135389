import hoistNonReactStatics from 'hoist-non-react-statics'
import { Input as AntdInput } from '../../Input'
import { Input as BaseInput } from './Input'
import { Password } from './Password'
import { Search } from './Search'
import { TextArea } from './TextArea'

export type { InputProps } from './Input'

// should we do it with hoist-non-react-statics ?
export const Input = Object.assign(hoistNonReactStatics(BaseInput, AntdInput), {
  Password,
  Search,
  TextArea
})
