import { UserAvatar } from '@vivaldis/antd-ui'
import { FC } from 'react'
import styled from '@emotion/styled'
import { Color, Text, FontWeight } from '@vivaldis/ui'
import { DrawerFooterBarCompanyProps } from '../../typings/DrawerFooterBarCompanyProps'

export const Company: FC<DrawerFooterBarCompanyProps> = ({ name, source }) => {
  return (
    <Container>
      <UserAvatar
        name={name || ''}
        src={source}
        shape="square"
        style={{ marginRight: 16 }}
      />
      <Text
        fontSize={15}
        letterSpacing={0.33}
        lineHeight={20}
        fontWeight={FontWeight.Bold}
        color={Color.Text.Default}
        textClip="ellipsis"
      >
        {name}
      </Text>
    </Container>
  )
}

const Container = styled('div')`
  height: 32px;
  display: flex;
  align-items: center;
  overflow: hidden;
`
