import { MaritalStatesEnum } from '@vivaldis/graphql'
import { useMaritalStateOptions, useTaxCodeOptions } from '@vivaldis/ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DatePicker,
  Form,
  InputNumber,
  Select,
  Typography
} from '@vivaldis/antd-ui'
import { Grid } from '../../../../../Grid'
import { useForm } from '../../../../provider/FormProvider'

interface Props {
  taxCodes: string[]
}

export const WorkProfileInformation: FC<Props> = ({ taxCodes }) => {
  const [t] = useTranslation('web_ui')

  const { values } = useForm()

  const maritalStateOptions = useMaritalStateOptions(MaritalStatesEnum)
  const taxCodeOptions = useTaxCodeOptions(taxCodes, values.isStudent)

  const involuntaryPartTimeOptions: any[] = useMemo(
    () => [
      {
        label: t(
          'edit_employee_profile_form.involuntary_part_time.options.yes'
        ),
        value: true
      },
      {
        label: t('edit_employee_profile_form.involuntary_part_time.options.no'),
        value: false
      }
    ],
    [t]
  )

  const showMaritalDate = useMemo(
    () => values.maritalState === MaritalStatesEnum.MARRIED,
    [values.maritalState]
  )

  const showChildrenWithDisabilitiesNumber = useMemo(
    () => !!values.childrenNumber,
    [values.childrenNumber]
  )

  const showDependentPersonsWithDisabilitiesNumber = useMemo(
    () => !!values.dependentPersonsNumber,
    [values.dependentPersonsNumber]
  )

  return (
    <>
      <Typography.Title level={5} marginBottom={16}>
        {t('edit_employee_profile_form.work_profile_section_header')}
      </Typography.Title>

      <Form.Item
        name="maritalState"
        label={t('edit_employee_profile_form.marital_state.label')}
      >
        <Select
          name="maritalState"
          placeholder={t('form.required')}
          options={maritalStateOptions}
          showSearch
        />
      </Form.Item>

      {showMaritalDate ? (
        <Form.Item
          name="maritalDate"
          label={t('edit_employee_profile_form.marital_date.label')}
        >
          <DatePicker
            name="maritalDate"
            format="dd-MM-yyyy"
            placeholder={t('form.required')}
            style={{ width: '100%' }}
            autoComplete="off"
          />
        </Form.Item>
      ) : null}

      <Form.Item
        name="childrenNumber"
        label={t('edit_employee_profile_form.children_number.label')}
      >
        <InputNumber
          name="childrenNumber"
          placeholder={t('form.optional')}
          type="number"
          style={{ width: '100%' }}
          autoComplete="off"
        />
      </Form.Item>

      {showChildrenWithDisabilitiesNumber ? (
        <Form.Item
          name="childrenWithDisabilitiesNumber"
          label={t(
            'edit_employee_profile_form.children_with_disabilities_number.label'
          )}
        >
          <InputNumber
            name="childrenWithDisabilitiesNumber"
            type="number"
            placeholder={t('form.optional')}
            style={{ width: '100%' }}
            autoComplete="off"
          />
        </Form.Item>
      ) : null}

      <Form.Item
        name="dependentPersonsNumber"
        label={t('edit_employee_profile_form.dependent_persons_number.label')}
      >
        <InputNumber
          name="dependentPersonsNumber"
          type="number"
          placeholder={t('form.optional')}
          style={{ width: '100%' }}
          autoComplete="off"
        />
      </Form.Item>

      {showDependentPersonsWithDisabilitiesNumber ? (
        <Form.Item
          name="dependentPersonsWithDisabilitiesNumber"
          label={t(
            'edit_employee_profile_form.dependent_persons_with_disabilities_number.label'
          )}
        >
          <InputNumber
            name="dependentPersonsWithDisabilitiesNumber"
            type="number"
            placeholder={t('form.optional')}
            style={{ width: '100%' }}
            autoComplete="off"
          />
        </Form.Item>
      ) : null}

      <Grid columns={2} gap={0} columnGap={16} marginTop={6}>
        <Form.Item
          name="taxCode"
          label={t('edit_employee_profile_form.tax_code.label')}
        >
          <Select
            name="taxCode"
            placeholder={t('form.required')}
            options={taxCodeOptions}
            showSearch
          />
        </Form.Item>
        <Form.Item
          name="involuntaryPartTime"
          label={t('edit_employee_profile_form.involuntary_part_time.label')}
        >
          <Select
            name="involuntaryPartTime"
            placeholder={t('form.required')}
            options={involuntaryPartTimeOptions}
            showSearch
          />
        </Form.Item>
      </Grid>
    </>
  )
}
