import { useField } from 'formik'
import * as React from 'react'
import { useCallback } from 'react'
import { Input as AntdInput, InputRef as AntdInputRef } from '../../Input'
import { InputProps as AntdInputProps } from '../../Input'
import { FormikFieldProps } from '../typings/FieldProps'

export type InputProps = FormikFieldProps & AntdInputProps

export const Input = React.forwardRef(
  (
    {
      name,
      onChange: onChangeProp,
      onBlur: onBlurProp,
      value: _valueProp,
      ...restProps
    }: InputProps,
    ref: React.Ref<AntdInputRef>
  ) => {
    const [field] = useField(name)
    const { value, onChange, onBlur } = field

    const handleChange = useCallback(
      (event: any) => {
        onChange(event)
        onChangeProp?.(event)
      },
      [onChange, onChangeProp]
    )

    const handleBlur = useCallback(
      (event: any) => {
        onBlur(event)
        onBlurProp?.(event)
      },
      [onBlur, onBlurProp]
    )

    return (
      <AntdInput
        ref={ref}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...restProps}
      />
    )
  }
)
