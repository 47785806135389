import { Tag, TextStylePreset, Typography } from '@vivaldis/antd-ui'
import { DangerCircle } from '@vivaldis/icons'
import { useTheme } from '@vivaldis/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ExpiredInformationTag: FC = () => {
  const [t] = useTranslation('web_ui')

  const theme = useTheme()
  return (
    <Tag
      icon={<DangerCircle />}
      style={{
        background: theme.red1,
        borderColor: theme.red4,
        marginLeft: 8
      }}
    >
      <Typography.Text preset={TextStylePreset.Regular12}>
        {t('employee_profile.expired')}
      </Typography.Text>
    </Tag>
  )
}
