import styled from '@emotion/styled'
import { FC, PropsWithChildren, ReactNode } from 'react'
import { Link } from '@vivaldis/web-ui'
import { AppLogo } from '../../AppLogo'
import {
  NAVIGATION_BAR_ITEM_ACTIVE_LINK_CLASS,
  NAVIGATION_BAR_ITEM_LINK_BOTTOM_INDICATOR_CLASS,
  NAVIGATION_BAR_ITEM_LINK_CLASS,
  NavigationBarItem
} from '../NavigationBarItem'

interface NavigationBarProps {
  navigationItems: ReactNode
}

interface UserNavigationBarInterface
  extends FC<PropsWithChildren<NavigationBarProps>> {
  Item: typeof NavigationBarItem
}

export const UserNavigationBar: UserNavigationBarInterface = ({
  children,
  navigationItems
}) => {
  const prefixCls = 'user-navigation-bar'

  return (
    <Base className={`${prefixCls}`}>
      <Container id="cy-navbar">
        <Link to="/" id="cy-navigate-on-seal-jobs-logo">
          <AppLogo />
        </Link>
        <NavigationItemsBase>{navigationItems}</NavigationItemsBase>
        {children}
      </Container>
    </Base>
  )
}

UserNavigationBar.Item = NavigationBarItem

const Base = styled('div')`
  height: 72px;
`

const Container = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;

  background-color: ${props => props.theme.white};
  border-bottom: 2px solid ${props => props.theme.extraBackground1};
  z-index: 80;
`

const NavigationItemsBase = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;

  & .${NAVIGATION_BAR_ITEM_LINK_CLASS} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    text-decoration: none;
    padding: 0 12px;
    height: 48px;
    border-radius: 8px;
    color: ${props => props.theme.gray9};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    &:hover {
      color: ${props => props.theme.gray9};
      background-color: ${props => props.theme.gray3};
      @media (hover: none) and (pointer: coarse) {
        background: transparent;
      }
    }

    &:active {
      color: ${props => props.theme.gray9};
    }

    &:focus-visible {
      background-color: ${props => props.theme.orange1};
      color: ${props => props.theme.orange6};
    }

    & > .${NAVIGATION_BAR_ITEM_LINK_BOTTOM_INDICATOR_CLASS} {
      position: absolute;
      left: 12px;
      right: 12px;
      bottom: 4px;
      border-radius: 6px;
      height: 2px;
      background-color: transparent;
    }
  }

  & .${NAVIGATION_BAR_ITEM_ACTIVE_LINK_CLASS} {
    background-color: ${props => props.theme.extraBackground1};
    & .${NAVIGATION_BAR_ITEM_LINK_BOTTOM_INDICATOR_CLASS} {
      background-color: ${props => props.theme.orange6};
    }
  }
`
