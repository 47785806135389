import type { Location } from 'react-router-dom'
import { MenuProps } from '@vivaldis/antd-ui'
import { isActiveLink } from './isActiveLink'

type MenuItem = Required<MenuProps>['items'][number]

export const getActiveMenuItemKeys = (
  menuItem: MenuItem,
  location: Location
): string[] => {
  if (!menuItem) {
    return []
  }

  // current item is active key
  if (
    menuItem.key &&
    typeof menuItem.key === 'string' &&
    !('children' in menuItem) &&
    isActiveLink({ location, to: menuItem.key })
  ) {
    return [String(menuItem.key)]
  }

  if ('children' in menuItem && Array.isArray(menuItem.children)) {
    return menuItem.children
      .map(children => getActiveMenuItemKeys(children, location))
      .flat()
      .filter(Boolean)
  }

  return []
}
