import { Navigate, Route, Routes } from 'react-router-dom'
import { Suspense, FC } from 'react'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'
import { lazyOrReloadPage } from '../../utils/lazyOrReloadPage'

const ForgotPassword = lazyOrReloadPage(
  () =>
    import(/* webpackChunkName: "ForgotPassword" */ './screens/ForgotPassword')
)
const Login = lazyOrReloadPage(
  () => import(/* webpackChunkName: "Login" */ './screens/Login')
)
const Registration = lazyOrReloadPage(
  () => import(/* webpackChunkName: "Registration" */ './screens/Registration')
)
const ResetPassword = lazyOrReloadPage(
  () =>
    import(/* webpackChunkName: "ResetPassword" */ './screens/ResetPassword')
)
const SetYourPassword = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "SetYourPassword" */ './screens/SetYourPassword'
    )
)

export const AuthRoutes: FC = () => (
  <Suspense fallback={ProgressBarSuspenseFallback}>
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="set-your-password" element={<SetYourPassword />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  </Suspense>
)
