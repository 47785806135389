import { useTranslation } from 'react-i18next'

export function useLocale(): string {
  const {
    i18n: { language }
  } = useTranslation()
  const locale = language.substring(0, 2).toLowerCase()
  if (!['en', 'nl', 'fr'].includes(locale)) {
    return 'nl'
  }
  return locale
}
