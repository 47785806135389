import { LinkProps as LinkPropsBase } from 'react-router-dom'
import { AnchorHTMLAttributes, forwardRef, useCallback } from 'react'
import { useNavigateOnPress } from '../hooks/useNavigateOnPress'

interface LinkProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  replace?: LinkPropsBase['replace']
  state?: LinkPropsBase['state']
  to: LinkPropsBase['to']
  openInSideSheet?: boolean
  goBackTo?: string | boolean
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      onClick,
      onContextMenu,
      to,
      replace,
      state,
      openInSideSheet,
      goBackTo,
      ...props
    },
    ref
  ) => {
    const handleContextMenu = useCallback(
      (e: any) => {
        e.currentTarget.blur()
        if (onContextMenu) onContextMenu(e)
      },
      [onContextMenu]
    )

    const onPress = useNavigateOnPress({
      to,
      replace,
      state,
      onPress: onClick,
      navigate: true,
      openInSideSheet,
      goBackTo
    })

    const isTelephone = typeof to === 'string' && to.startsWith('tel:')
    if (isTelephone || props.target === '_blank') {
      return (
        <a
          href={to}
          {...(props as any)}
          ref={ref}
          onClick={onClick}
          onContextMenu={handleContextMenu}
        >
          {props.children}
        </a>
      )
    }

    return (
      <a
        href={to}
        {...(props as any)}
        ref={ref}
        onClick={onPress}
        onContextMenu={handleContextMenu}
      >
        {props.children}
      </a>
    )
  }
)
