import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Select, Typography } from '@vivaldis/antd-ui'
import { useCertificateTypeOptions, VCA_CERTIFICATE_TYPE } from '@vivaldis/ui'
import { useForm } from '../../../providers/FormProvider'

interface Props {
  certificateTypes: string[]
}

export const CertificateTypes: FC<Props> = ({ certificateTypes }) => {
  const { t } = useTranslation()

  const certificateTypesOptions = useCertificateTypeOptions(certificateTypes)

  const { setFieldValue } = useForm()

  const handleCertificateTypesChange = useCallback(
    (certificateTypes: any) => {
      // update 'vcaCertificationRequired" field based on select/de-select of VCA certificate
      setFieldValue(
        'vcaCertificationRequired',
        certificateTypes.includes(VCA_CERTIFICATE_TYPE)
      )
      // Note: we don't need to update "certificateTypes" field because Select do it automatically
    },
    [setFieldValue]
  )

  return (
    <>
      <Typography.Title level={5} marginBottom={16}>
        {t('position_form.step2.certificate_types.header', { ns: 'web_ui' })}
      </Typography.Title>
      <Form.Item
        name="certificateTypes"
        label={t('position_form.step2.certificate_types.label', {
          ns: 'web_ui'
        })}
        tooltip={t('position_form.step2.certificate_types.tooltip', {
          ns: 'web_ui'
        })}
      >
        <Select
          name="certificateTypes"
          mode="multiple"
          placeholder={t('form.optional')}
          options={certificateTypesOptions}
          onChange={handleCertificateTypesChange}
          showSearch
        />
      </Form.Item>
    </>
  )
}
