import { Button } from '@vivaldis/antd-ui'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { usePosition } from '../../../providers/PositionProvider'
import { useStep } from '../../../providers/StepProvider'
import { useForm } from '../providers/FormProvider'
import { ActionBar as ActionBarBase } from '../../../../ActionBar'
import { SubmitButton } from '../../../../SubmitButton'
import type { PositionFormStep2Values } from '../typings/PositionFormStep2Values'

interface Props {
  onPrevStep?: (values: PositionFormStep2Values) => void
}
export const ActionBar: FC<Props> = ({ onPrevStep }) => {
  const { t } = useTranslation('web_ui')
  const { isSubmitting, values } = useForm()
  const { setStep } = useStep()

  const position = usePosition()

  const handleGoToPreviousStep = useCallback(() => {
    onPrevStep?.(values)
    setStep(step => step - 1)
  }, [onPrevStep, setStep, values])

  const submitButtonLabel = useMemo(() => {
    return position
      ? t('position_form.step2.submit_button.update')
      : t('position_form.step2.submit_button.create')
  }, [position, t])

  return (
    <ActionBarBase
      leftContent={
        <Button
          type="default"
          shape="round"
          size="large"
          onClick={handleGoToPreviousStep}
        >
          {t('position_form.step2.previous_step_button')}
        </Button>
      }
      rightContent={
        <SubmitButton isSubmitting={isSubmitting} label={submitButtonLabel} />
      }
    />
  )
}
