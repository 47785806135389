import { ComponentType, lazy } from 'react'

const SESSION_STORAGE_KEY = 'LAST_PAGE_RELOADING_TIME_ON_CHUNK_ERROR'

// we try to fetch a chunk (module or component), and when we can not do it (mostly because new release was deployed), we reload a page. We have a protection from infinite reloading a page if multiple modules was lazy loaded in the same time.
export function lazyOrReloadPage<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
) {
  const factoryWrapper = () => {
    return new Promise<{ default: T }>((resolve, reject) => {
      return factory()
        .then(module => {
          return resolve(module)
        })
        .catch(error => {
          const previousReloadingTime =
            window.sessionStorage.getItem(SESSION_STORAGE_KEY)

          if (previousReloadingTime) {
            // if we already reloaded a page on error, and it was less than 60 seconds ago, we should not reload a page again. This is to prevent an infinite loop of reloading a page.
            // We propagate an error further in this case.
            if (
              new Date().getTime() - new Date(previousReloadingTime).getTime() <
              60 * 1000
            ) {
              return reject(error)
            }
          }

          window.sessionStorage.setItem(
            SESSION_STORAGE_KEY,
            new Date().toString()
          )
          return window.location.reload()
        })
    })
  }
  return lazy(factoryWrapper)
}
