import { FC, createContext, useContext, PropsWithChildren } from 'react'

export const DetailContext = createContext<boolean>(false)

export function useIsInsideDetail(): boolean {
  return useContext(DetailContext)!
}

export const DetailProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  return (
    <DetailContext.Provider value={true}>{children}</DetailContext.Provider>
  )
}
