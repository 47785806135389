import styled from '@emotion/styled'
import { FC, ReactNode } from 'react'

const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.gray7 : 'transparent'};
  border: none;
  cursor: pointer;
  height: 30px;
  outline: 0;
  width: 30px;
  vertical-align: bottom;
  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.gray7 : theme.gray6};
  }
`

interface ButtonProps {
  selected: boolean
  onClick: () => any
}

type Props = {
  icon: ReactNode
  title: string
} & ButtonProps

export const Control: FC<Props> = ({ icon, title, onClick, selected }) => {
  return (
    <Button type="button" onClick={onClick} title={title} selected={selected}>
      {icon}
    </Button>
  )
}
