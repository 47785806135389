import {
  useEffect,
  useState,
  useMemo,
  createContext,
  FC,
  PropsWithChildren
} from 'react'

export const VERSION_STORAGE_KEY = '@@version'

export interface VersionContextValue {
  version: string
  previousVersion?: string | null
}

export const VersionContext = createContext<VersionContextValue>({
  version: '0.0.0'
})

interface Props {
  version: string
  storage: any
}

interface State {
  ready: boolean
  previousVersion?: string | null
}

export const Version: FC<PropsWithChildren<Props>> = ({
  version,
  storage,
  children
}) => {
  const [state, setState] = useState<State>({
    ready: false
  })

  useEffect(() => {
    storage
      .getItem(VERSION_STORAGE_KEY)
      .then((value: any) => {
        setState({
          previousVersion: value,
          ready: true
        })
      })
      .catch(() => {
        setState({
          ready: true
        })
      })
  }, [storage])

  useEffect(() => {
    if (state.ready) {
      storage.setItem(VERSION_STORAGE_KEY, version)
    }
  }, [state.ready, storage, version])

  const context = useMemo(
    (): VersionContextValue => ({
      version,
      previousVersion: state.previousVersion
    }),
    [state.previousVersion, version]
  )

  return (
    <VersionContext.Provider value={context}>
      {state.ready ? children : null}
    </VersionContext.Provider>
  )
}
