import {
  SendInvitationReminderMutation,
  SendInvitationReminderMutationVariables
} from './__generated__/SendInvitationReminderMutation'
import { ApolloClient, MutationOptions, gql } from '@apollo/client'

const mutation = gql`
  mutation SendInvitationReminderMutation(
    $input: SendInvitationReminderInput!
  ) {
    sendInvitationReminder(input: $input) {
      status
    }
  }
`

export function sendInvitationReminderMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<
      SendInvitationReminderMutation,
      SendInvitationReminderMutationVariables
    >,
    'mutation'
  >
) {
  return apolloClient.mutate<
    SendInvitationReminderMutation,
    SendInvitationReminderMutationVariables
  >({
    mutation,
    ...options
  })
}
