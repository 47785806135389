import { gql } from '@apollo/client'

export const workObstructionFragment = gql`
  fragment WorkObstructionFragment on WorkObstruction {
    city
    comment
    country {
      id
      nameEn
      nameFr
      nameNl
    }
    id
    processed
    risk
    street
    streetNumber
    weather
    workAndReason
    zip
  }
`
