import {
  applyMiddleware,
  createStore as baseCreateStore,
  Store,
  Reducer,
  Action,
  AnyAction
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import { Persistor, persistStore } from 'redux-persist'
import createSagaMiddleware, { Saga } from 'redux-saga'

let isLoggerEnabled = false

export function enableReduxLogger() {
  isLoggerEnabled = true
}

export function disableReduxLogger() {
  isLoggerEnabled = false
}

function createReduxLoggerMiddleware() {
  const SAGA_LOGGING_BLACKLIST = [
    'EFFECT_TRIGGERED',
    'EFFECT_RESOLVED',
    'EFFECT_REJECTED',
    'persist/REHYDRATE'
  ]
  const logger = createLogger({
    collapsed: true,
    logger: {
      groupCollapsed: (message: string) => {
        const styles = ['color: #A700E4; font-weight: bold;']

        if (message.indexOf('%c[SAGA]') !== -1) {
          styles.push('color: #7200ff; font-weight: bold;')
        }

        if (message.indexOf('%c[REDUX] %cREHYDRATED') !== -1) {
          styles.push('color: #0083ff; font-weight: bold;')
        } else if (message.indexOf('_SUCCESS') !== -1) {
          styles.push('color: #00ff94; font-weight: bold;')
        } else if (message.indexOf('_FAILURE') !== -1) {
          styles.push('color: #ff5000; font-weight: bold;')
        } else {
          styles.push('font-weight: normal;')
        }
        return console.groupCollapsed(message, ...styles)
      },
      groupEnd: console.groupEnd,
      log: console.log
    },
    predicate: (_getState, { type }) =>
      isLoggerEnabled && !SAGA_LOGGING_BLACKLIST.includes(type),
    titleFormatter: (action: any) => {
      if (action['@@redux-saga/SAGA_ACTION']) {
        return `%c[REDUX]%c[SAGA] %c${action.type}`
      }
      return `%c[REDUX] %c${action.type}`
    }
  })
  return logger
}

interface Context {
  store: Store<any, any> | undefined
  persistor: Persistor | undefined
}

const context: Context = {
  persistor: undefined,
  store: undefined
}

export interface CreateReduxStoreOptions<
  S = any,
  A extends Action = AnyAction
> {
  reducer: Reducer<S, A>
  saga: Saga
  middlewares?: any[]
  debug?: boolean
  persistorCallback?: (store: Store<S, A>) => any
}

export function createReduxStore<S = any, A extends Action = AnyAction>({
  reducer,
  saga,
  middlewares,
  debug,
  persistorCallback
}: CreateReduxStoreOptions): {
  store: Store<S, A>
  persistor: Persistor
} {
  const finalMiddlewares: any[] = []
  const enhancers: any[] = []

  const sagaMiddleware = createSagaMiddleware()
  finalMiddlewares.push(sagaMiddleware)
  if (middlewares) finalMiddlewares.push(...middlewares)

  if (debug) {
    finalMiddlewares.push(createReduxLoggerMiddleware())
  }

  enhancers.push(applyMiddleware(...finalMiddlewares))

  const _composeWithDevTools = composeWithDevTools
  const store = baseCreateStore<S, A, any, any>(
    reducer,
    _composeWithDevTools(...enhancers)
  )

  const persistor = persistStore(store, undefined, () => {
    if (persistorCallback) persistorCallback(store)
  })

  sagaMiddleware.run(saga)

  context.store = store
  context.persistor = persistor

  return { store, persistor }
}

export function getReduxStore<S = any, A extends Action = AnyAction>(): Store<
  S,
  A
> {
  return context.store!
}

export function getReduxPersistor(): Persistor {
  return context.persistor!
}
