import { TextStylePreset, Typography } from '@vivaldis/antd-ui'
import { useTheme } from '@vivaldis/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Base } from '../Base'
import { Item } from '../Item'

interface ContractScheduleProps {
  weekNumber: string | null
}

export const TableHeader: FC<ContractScheduleProps> = ({ weekNumber }) => {
  const { t } = useTranslation('web_ui')
  const theme = useTheme()

  return (
    <Base disabled>
      <Item />
      <Item>
        <Typography.Text preset={TextStylePreset.Semibold14} lineHeight={22}>
          {t('contract_schedule.week')} {weekNumber}
        </Typography.Text>
      </Item>
      <Item>
        <Typography.Text
          preset={TextStylePreset.Semibold14}
          lineHeight={22}
          color={theme.gray7}
        >
          {t('contract_schedule.starts_at')}
        </Typography.Text>
      </Item>
      <Item>
        <Typography.Text
          preset={TextStylePreset.Semibold14}
          lineHeight={22}
          color={theme.gray7}
        >
          {t('contract_schedule.ends_at')}
        </Typography.Text>
      </Item>
      <Item>
        <Typography.Text
          preset={TextStylePreset.Semibold14}
          lineHeight={22}
          color={theme.gray7}
        >
          {t('contract_schedule.break_in_minutes')}
        </Typography.Text>
      </Item>
      <Item>
        <Typography.Text
          preset={TextStylePreset.Semibold14}
          lineHeight={22}
          color={theme.gray7}
        >
          {t('contract_schedule.total')}
        </Typography.Text>
      </Item>
    </Base>
  )
}
