export type UnregisterCallback = () => void

export type EventHandler = (progressBar: ProgressBar) => void

export interface ProgressBar {
  addLoadingState: () => void
  removeLoadingState: () => void
  loadingStatesCount: number
  listen: (handler: EventHandler) => UnregisterCallback
}

export function createProgressBar(): ProgressBar {
  function addLoadingState() {
    progressBar.loadingStatesCount++
    notifyListeners()
  }

  function removeLoadingState() {
    progressBar.loadingStatesCount--
    notifyListeners()
  }

  let listeners: EventHandler[] = []

  function notifyListeners() {
    listeners.forEach(handler => {
      handler(progressBar)
    })
  }

  function listen(handler: EventHandler): UnregisterCallback {
    listeners.push(handler)
    return () => {
      const index = listeners.findIndex(a => a === handler)
      if (index !== -1) {
        listeners = [
          ...listeners.slice(0, index),
          ...listeners.slice(index + 1)
        ]
      }
    }
  }

  const progressBar: ProgressBar = {
    addLoadingState,
    removeLoadingState,
    loadingStatesCount: 0,
    listen
  }

  return progressBar
}
