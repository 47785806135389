// similar solution is here: https://blog.devgenius.io/ant-design-component-customization-and-bundle-optimization-a1fa3253a175

// we need manually copy all variables here from LESS
import { AntdTheme } from '../typing/AntdTheme'

const colors = {
  // Dust Red / red color
  red1: '#FFEFEF',
  red2: '#FFCECF',
  red3: '#FF9C9F',
  red4: '#FF7B7F',
  red5: '#FF5A5F',
  red6: '#E65156',
  red7: '#B33F43',
  red8: '#802D30',
  red9: '#4C1B1C',
  red10: '#190909',
  // orange color
  orange1: '#FFF6E8',
  orange2: '#FEE4BB',
  orange3: '#FDDBA4',
  orange4: '#FDC876',
  orange5: '#FCB649',
  orange6: '#FBA41B',
  orange7: '#C98316',
  orange8: '#7E520E',
  orange9: '#4B3108',
  orange10: '#322105',
  // volcano
  volcano1: '#FFFAEE',
  volcano2: '#FFF4DD',
  volcano3: '#FFE4AB',
  volcano4: '#FFD989',
  volcano5: '#FFCE68',
  volcano6: '#FFC857',
  volcano7: '#CCA046',
  volcano8: '#997834',
  volcano9: '#4C3A20',
  volcano10: '#332811',
  // Gray color
  gray1: '#FFFFFF',
  gray2: '#FAFAFA',
  gray3: '#F5F5F8',
  gray4: '#E7E9EC',
  gray5: '#D7DBE0',
  gray6: '#C1CBD5',
  gray7: '#9DA5AD',
  gray8: '#575C60',
  gray9: '#34373A',
  gray10: '#111213',
  // Vivaldis Yellow / yellow color
  VivaldisYellow_yellow1: '#FFFAEE',
  VivaldisYellow_yellow2: '#FFF4DD',
  VivaldisYellow_yellow3: '#FFE4AB',
  VivaldisYellow_yellow4: '#FFD989',
  VivaldisYellow_yellow5: '#FFCE68',
  VivaldisYellow_yellow6: '#FFC857',
  VivaldisYellow_yellow7: '#CCA046',
  VivaldisYellow_yellow8: '#997834',
  VivaldisYellow_yellow9: '#4C3A20',
  VivaldisYellow_yellow10: '#332811',
  // Vivaldis Orange / orange color
  VivaldisOrange_orange1: '#FFF6E8',
  VivaldisOrange_orange2: '#FEE4BB',
  VivaldisOrange_orange3: '#FDDBA4',
  VivaldisOrange_orange4: '#FDC876',
  VivaldisOrange_orange5: '#FCB649',
  VivaldisOrange_orange6: '#FBA41B',
  VivaldisOrange_orange7: '#C98316',
  VivaldisOrange_orange8: '#7E520E',
  VivaldisOrange_orange9: '#4B3108',
  VivaldisOrange_orange10: '#322105',
  // Status Green / green color
  StatusGreen_green1: '#F5FFFA',
  StatusGreen_green2: '#CEF3DF',
  StatusGreen_green3: '#84E0AF',
  StatusGreen_green4: '#6BDA9F',
  StatusGreen_green5: '#3ACD7F',
  StatusGreen_green6: '#09C15F',
  StatusGreen_green7: '#079A4C',
  StatusGreen_green8: '#057439',
  StatusGreen_green9: '#044D26',
  StatusGreen_green10: '#022713',
  // Seal Blue color
  blue1: '#F3FBFF',
  blue2: '#CCE9FF',
  blue3: '#B3DEFF',
  blue4: '#99D3FF',
  blue5: '#66BCFF',
  blue6: '#33A6FF',
  blue7: '#0090FF',
  blue8: '#0073CC',
  blue9: '#004880',
  blue10: '#001D33'
}

export const VivaldisTheme: AntdTheme = {
  // colors
  primaryColor: '#00B287',
  white: '#FFFFFF',
  extraBackground1: '#F0F2F5',
  // base colors
  ...colors,

  // R navigation bar variables - we have different colors for Seal and Vivaldis
  navigationBarActiveLinkColor: colors.orange6,
  navigationBarFocusedBackgroundColor: colors.orange1,
  // tokens from ANTD
  screenXS: 480,
  screenSM: 576,
  screenMD: 768,
  screenLG: 992,
  screenXL: 1200,
  screenXXL: 1600
}
