import { FC } from 'react'
import { useSelector } from 'react-redux'
import { hasSessionSelector } from '@vivaldis/session'
import { Routes } from '../../screens/routes'
import { Drawer } from './components/Drawer'

export const MobileLayout: FC = () => {
  const hasSession = useSelector(hasSessionSelector)

  return (
    <>
      {hasSession ? <Drawer /> : null}
      <Routes />
    </>
  )
}
