import { useUser } from '@vivaldis/session'
import { useMemo } from 'react'

export const useUserInterimFourDCompanyId = () => {
  const user = useUser()
  return useMemo(() => {
    const permission = user?.permissions.find(
      permission => permission.companyId === user?.company?.id
    )

    return permission?.interim4dPermissions?.id
  }, [user?.company?.id, user?.permissions])
}
