import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import {
  CreateContractsMutation,
  CreateContractsMutationVariables
} from './__generated__/CreateContractsMutation'
import { contractFragment } from '../fragments/ContractFragment'

const mutation = gql`
  ${contractFragment}

  mutation CreateContractsMutation($input: CreateContractsInput!) {
    createContracts(input: $input) {
      contracts {
        ...ContractFragment
      }
    }
  }
`

export function createContractsMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<CreateContractsMutation, CreateContractsMutationVariables>,
    'mutation'
  >
) {
  return apolloClient.mutate<
    CreateContractsMutation,
    CreateContractsMutationVariables
  >({
    mutation,
    ...options
  })
}
