import { createSelector } from 'reselect'
import {
  SESSION_STATE_KEY,
  SessionSignIn,
  SessionSignUp,
  SessionState,
  SessionVerifyOTP
} from '../reducer'
import { SessionData } from '../typings/SessionData'
import { isEmployeeReady } from '../utils/isEmployeeReady'
import { EmployeeFragment } from '@vivaldis/graphql'

interface State {
  [SESSION_STATE_KEY]: SessionState
}

export const sessionStoreSelector = (state: State): SessionState =>
  state[SESSION_STATE_KEY]

export const sessionSelector: (state: State) => SessionData = createSelector(
  sessionStoreSelector,
  session => session.data
)

export const isSessionReadySelector: (state: State) => boolean = createSelector(
  sessionStoreSelector,
  session => !!session.ready
)

export const hasSessionSelector: (state: State) => boolean = createSelector(
  sessionStoreSelector,
  session => !!session.data.authentication_token
)

export const hasSessionOnInitSelector: (state: State) => boolean =
  createSelector(sessionStoreSelector, session => !!session.has_session_on_init)

export const isProcessingInvalidSessionSelector: (state: State) => boolean =
  createSelector(
    sessionStoreSelector,
    session => session.processing_invalid_session
  )

export const employeeSelector: (state: State) => EmployeeFragment | undefined =
  createSelector(sessionStoreSelector, ({ employee }) => employee)

export const isEmployeeReadySelector: (state: State) => boolean =
  createSelector(sessionStoreSelector, ({ employee }) =>
    isEmployeeReady(employee)
  )

export const signInSelector: (state: State) => SessionSignIn = createSelector(
  sessionStoreSelector,
  session => session.sign_in
)

export const signUpSelector: (state: State) => SessionSignUp = createSelector(
  sessionStoreSelector,
  session => session.sign_up
)

export const verifyOTPSelector: (state: State) => SessionVerifyOTP =
  createSelector(sessionStoreSelector, session => session.verify_otp)
