import { FC, Fragment, useCallback } from 'react'
import { Premium } from '../../../../../../typings/Premium'
import { useForm } from '../../../../providers/FormProvider'
import { LateShiftBonus } from './components/LateShiftBonus'
import { MealVouchers } from './components/MealVouchers'
import { MorningShiftBonus } from './components/MorningShiftBonus'
import { NightShiftBonus } from './components/NightShiftBonus'

// description is here: https://sealjobs.atlassian.net/wiki/spaces/SJ/pages/2079784991/Premies

interface Props {
  premiums: Premium[]
}

export const PremiumItems: FC<Props> = ({ premiums }) => {
  const { values } = useForm()

  const getPremiumComponentByPremiumId = useCallback(
    (premiumId: string, index: number) => {
      const premium = premiums.find(premium => premium.id === premiumId)

      switch (premium?.name) {
        case 'late_shift_bonus':
          return <LateShiftBonus index={index} />
        case 'night_shift_bonus':
          return <NightShiftBonus index={index} />
        case 'morning_shift_bonus':
          return <MorningShiftBonus index={index} />
        case 'meal_vouchers':
          return <MealVouchers index={index} />
        case 'mobility':
          // NOTE: on position level we don't need field for this premium, because it doesn't have any value
          return null
        case 'bike_bonus':
          // NOTE: on position level this field should be empty (it's only required on contract level)
          return null
        case 'social_kilometer_compensation':
          // NOTE: on position level this field should be empty (it's only required on contract level)
          return null
        default:
          return <div>no inputs premium with type: "{premium?.name}"</div>
      }
    },
    [premiums]
  )

  return (
    <>
      {values.premiumValuesInput.map((premiumValueInput, index) => (
        <Fragment key={premiumValueInput.premiumId}>
          {getPremiumComponentByPremiumId(premiumValueInput.premiumId, index)}
        </Fragment>
      ))}
    </>
  )
}
