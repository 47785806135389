import { gql } from '@apollo/client'

export const committeeFragment = gql`
  fragment CommitteeFragment on Committee {
    averageWeeklyHours
    averageWeeklyHoursByYear
    code
    id
    isConstructAllowed
    isFlexiAllowed
    isWorker
    name
    overtimePayingStatus
  }
`
