import { useBreakpoint } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { NavigationMenu } from '../../../../components/NavigationMenu'

interface Props {
  isSiderCollapsed: boolean
}

export const LeftNavigationMenu: FC<Props> = ({ isSiderCollapsed }) => {
  const breakpoint = useBreakpoint()

  // no navigation bar on mobile
  if (!breakpoint.md) {
    return null
  }

  return <NavigationMenu isSiderCollapsed={isSiderCollapsed} />
}
