import { FC } from 'react'
import {
  ThemeProvider as EmotionThemeProvider,
  ThemeProviderProps as EmotionThemeProviderProps
} from '@emotion/react'
import { VivaldisTheme } from '../themes/VivaldisTheme'

interface ThemeProviderProps extends Omit<EmotionThemeProviderProps, 'theme'> {
  theme?: EmotionThemeProviderProps['theme']
}

export const ThemeProvider: FC<ThemeProviderProps> = props => {
  return <EmotionThemeProvider theme={VivaldisTheme} {...props} />
}
