import { Divider } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { ContractInformation } from './components/GeneralInformation'
import { PremiumsInformation } from './components/PremiumsInformation'
import { SafetyInformation } from './components/SafetyInformation'
import { SuVivaldisOffices } from './typings/SuVivaldisOffices'
import type { Position } from './typings/Position'

export interface ViewPositionScreenContentProps {
  position: Position
  vivaldisOffices: SuVivaldisOffices[]
  suView: boolean
  viewWPSUrl: string
}

export const ViewPositionScreenContent: FC<ViewPositionScreenContentProps> = ({
  position,
  vivaldisOffices,
  suView,
  viewWPSUrl
}) => {
  return (
    <>
      <ContractInformation
        position={position}
        vivaldisOffices={vivaldisOffices}
        suView={suView}
      />
      <Divider />
      <SafetyInformation
        position={position}
        viewWPSUrl={viewWPSUrl}
        suView={suView}
      />
      <Divider />
      <PremiumsInformation position={position} />
    </>
  )
}
