import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, InputNumber, Row } from '@vivaldis/antd-ui'

interface Props {
  index: number
}

export const MealVouchers: FC<Props> = ({ index }) => {
  const { t } = useTranslation('web_ui')

  const valueFieldName = `premiumValuesInput[${index}].value`
  const contributionValueFieldName = `premiumValuesInput[${index}].contributionValue`

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name={valueFieldName}
          required
          label={t('position_form.step3.meal_vouchers.value.label')}
          tooltip={t('position_form.step3.meal_vouchers.value.tooltip')}
        >
          <InputNumber
            name={valueFieldName}
            addonBefore={t(
              'position_form.step3.meal_vouchers.value.addon_before'
            )}
            addonAfter={t(
              'position_form.step3.meal_vouchers.value.addon_after'
            )}
            autoComplete="off"
            min={2}
            max={8}
            placeholder={t(
              'position_form.step3.meal_vouchers.value.placeholder'
            )}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={contributionValueFieldName}
          required
          label={t(
            'position_form.step3.meal_vouchers.contribution_value.label'
          )}
          tooltip={t(
            'position_form.step3.meal_vouchers.contribution_value.tooltip'
          )}
        >
          <InputNumber
            name={contributionValueFieldName}
            addonBefore={t(
              'position_form.step3.meal_vouchers.contribution_value.addon_before'
            )}
            addonAfter={t(
              'position_form.step3.meal_vouchers.contribution_value.addon_after'
            )}
            autoComplete="off"
            placeholder={t(
              'position_form.step3.meal_vouchers.contribution_value.placeholder'
            )}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}
