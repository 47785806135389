import { useMemo } from 'react'
import { useT } from '../../../i18n/useT'

// see translations here: https://sealjobs.atlassian.net/wiki/spaces/SJ/pages/2070773773/Certificates

type CertificateType = string

export const useCertificateTypeOptions = (
  certificateTypes: CertificateType[]
) => {
  const t = useT('certificate_type')

  return useMemo(
    () =>
      certificateTypes.map(premiumType => ({
        label: t(`options.${premiumType}`, `${premiumType}`),
        value: premiumType
      })),
    [certificateTypes, t]
  )
}
