export const fileToBase64 = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB+U
      // we need to remove "Meta" from this string (data:image/png;base64,)
      const [, base64Value] = (reader.result as string).split(',')
      resolve(base64Value)
    }
    reader.onerror = error => reject(error)
  })
}
