import { gql } from '@apollo/client'

export const employeeWorkProfileFragment = gql`
  fragment EmployeeWorkProfileFragment on EmployeeWorkProfile {
    bankAccount
    bic
    childrenNumber
    childrenWithDisabilitiesNumber
    dependentPersonsNumber
    dependentPersonsWithDisabilitiesNumber
    flexiNotAllowedUntil
    hoursContingent
    involuntaryPartTime
    maritalDate
    maritalState
    remainingHours
    remainingHoursScrapedAt
    residencePermitBackPicture
    residencePermitCode
    residencePermitExpiresOn
    residencePermitFrontPicture
    taxCode
    workPermitBackPicture
    workPermitExpiresOn
    workPermitFrontPicture
  }
`
