import styled from '@emotion/styled'
import { Typography, TextStylePreset, Button } from '@vivaldis/antd-ui'
import { Plus } from '@vivaldis/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { List } from 'antd'
import { Divider } from '../../../Divider'
import {
  EmployeeProfileQuery_employee,
  EmployeeProfileQuery_employee_employeeCertificates as EmployeeCertificate
} from '../../typings/EmployeeProfileQuery'
import { EmployeeCertificateListItem } from './components/EmployeeCertificateListItem'

export interface Props {
  employee: EmployeeProfileQuery_employee
  onDeleteEmployeeCertificate?: (
    certificate: EmployeeCertificate
  ) => Promise<void>
}

export const EmployeeCertificates: FC<Props> = ({
  employee,
  onDeleteEmployeeCertificate
}) => {
  const [t] = useTranslation('web_ui')

  const { employeeCertificates } = employee

  return (
    <>
      <Base>
        <Typography.Paragraph
          preset={TextStylePreset.Semibold16}
          marginBottom={16}
        >
          {t('employee_profile.certificates')}
        </Typography.Paragraph>
        <Button
          to={`/employee/${employee.id}/add-employee-certificate`}
          openInSideSheet
          type="link"
          size="small"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          icon={<Plus />}
        >
          {t('employee_profile.add_certificate_button')}
        </Button>
      </Base>

      {employeeCertificates.length ? (
        <List<EmployeeCertificate>
          itemLayout="horizontal"
          dataSource={employeeCertificates}
          renderItem={employeeCertificate => (
            <EmployeeCertificateListItem
              key={employeeCertificate.id}
              employeeId={employee.id}
              employeeCertificate={employeeCertificate}
              onDeleteEmployeeCertificate={onDeleteEmployeeCertificate}
            />
          )}
        />
      ) : (
        t('employee_profile.no_certificates')
      )}
      <Divider sectionSeparator />
    </>
  )
}

const Base = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
