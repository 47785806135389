import { useBreakpoint } from '@vivaldis/antd-ui'
import { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { ErrorState as ErrorStateBase } from '../../ErrorState'
import { SIDE_SHEET_DEFAULT_WIDTH } from '../../Layout/components/SideSheet'
import { useIsInsideDetail } from '../../MasterDetail/MasterDetail/providers/DetailProvider'
import { useIsInSideSheet } from '../../SideSheets/hooks/useIsInSideSheet'
import { ScreenErrorStateProps } from '../typings/ScreenErrorStateProps'
import { Screen } from '../index'

export const ErrorState: FC<ScreenErrorStateProps> = ({
  navigationBar,
  title,
  message,
  onTryAgain
}) => {
  return (
    <Screen navigationBar={navigationBar} title={title} Component={Container}>
      <ErrorStateBase message={message} onTryAgain={onTryAgain} />
    </Screen>
  )
}

const Container: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const isInsideDetail = useIsInsideDetail()
  const breakpoint = useBreakpoint()

  const isInSideSheet = useIsInSideSheet()

  return (
    <ContainerBase
      masterOffset={isInsideDetail && breakpoint.lg}
      isInSideSheet={isInSideSheet}
    >
      {children}
    </ContainerBase>
  )
}

interface CP {
  masterOffset: boolean
  isInSideSheet: boolean
}

const ContainerBase = styled('div')<CP>`
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;
  height: 100%;
  @media only screen and (min-width: ${({ theme }) => theme.screenXL - 1}px) {
    margin-left: auto;
    margin-right: auto;
    ${({ isInSideSheet }) =>
      isInSideSheet ? `max-width: ${SIDE_SHEET_DEFAULT_WIDTH}px;` : ''};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ masterOffset }) => (masterOffset ? `left: 336px;` : '')};
`
