import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, TextStylePreset, Typography } from '@vivaldis/antd-ui'
import type { CompanySurvey_questions } from '../../../../types/CompanySurvey'

interface Props {
  question: CompanySurvey_questions
}

export const Text: FC<Props> = ({ question }) => {
  const [t] = useTranslation('web_ui')

  const textFiledName = `${question.id}.textValue`

  return (
    <Form.Item
      name={textFiledName}
      required={question.required}
      label={
        <Typography.Text preset={TextStylePreset.Semibold16} marginBottom={4}>
          {question.title}
        </Typography.Text>
      }
    >
      <Input
        name={textFiledName}
        placeholder={
          question.required
            ? t('survey_form.text.required_placeholder')
            : t('survey_form.text.optional_placeholder')
        }
        size="large"
        autoComplete="off"
      />
    </Form.Item>
  )
}
