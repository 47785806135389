import { useBreakpoint } from '@vivaldis/antd-ui'
import { FC, PropsWithChildren, useContext } from 'react'
import { Overlay } from './components/Overlay'
import { SideSheet } from './components/SideSheet'
import { SideSheetsElementsContext } from './providers/SideSheetsElementsProvider'

export const SideSheets: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const breakpoint = useBreakpoint()
  const sideSheets = useContext(SideSheetsElementsContext)
  return breakpoint.lg ? (
    <Overlay>
      {sideSheets.map(sideSheet => {
        return (
          <SideSheet
            key={sideSheet.key}
            layer={sideSheet.layer}
            index={sideSheet.index}
            location={sideSheet.location}
            onLeave={sideSheet.onLeave}
          >
            {children}
          </SideSheet>
        )
      })}
    </Overlay>
  ) : null
}
