import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const PlusSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.39539 7.89302C3.39539 5.16 5.06236 3.39535 7.64097 3.39535H16.3591C18.9386 3.39535 20.6047 5.16 20.6047 7.89302V16.107C20.6047 18.84 18.9386 20.6047 16.3591 20.6047H7.64097C5.06236 20.6047 3.39539 18.84 3.39539 16.107V7.89302ZM7.64093 22H16.3591C19.733 22 22 19.6316 22 16.107V7.89302C22 4.36837 19.733 2 16.3591 2H7.64093C4.26698 2 2 4.36837 2 7.89302V16.107C2 19.6316 4.26698 22 7.64093 22ZM12 16.097C11.6149 16.097 11.3024 15.7845 11.3024 15.3994V12.6886H8.58928C8.20323 12.6886 7.8916 12.376 7.8916 11.9909C7.8916 11.6058 8.20323 11.2932 8.58928 11.2932H11.3024V8.58354C11.3024 8.19842 11.6149 7.88586 12 7.88586C12.3852 7.88586 12.6977 8.19842 12.6977 8.58354V11.2932H15.4107C15.7958 11.2932 16.1083 11.6058 16.1083 11.9909C16.1083 12.376 15.7958 12.6886 15.4107 12.6886H12.6977V15.3994C12.6977 15.7845 12.3852 16.097 12 16.097Z"
    />
  </svg>
)

export const Plus = forwardRef<HTMLSpanElement, AntdIconProps>((props, ref) => (
  <Icon component={PlusSvg} {...props} ref={ref} />
))
