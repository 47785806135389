import {
  createContext,
  useContext,
  useMemo,
  useLayoutEffect,
  useRef,
  FC,
  PropsWithChildren
} from 'react'
import { QueryHookOptions, useQuery, QueryResult } from '@apollo/client'
import { useHasSession } from '@vivaldis/session'
import { userDashboardQuery } from '../../graphql/queries/userDashboardQuery'
import { UserDashboardQuery } from '../../graphql/queries/__generated__/UserDashboardQuery'

export interface UserDashboardQueryContextValue
  extends QueryResult<UserDashboardQuery> {}

export const UserDashboardQueryContext = createContext<
  UserDashboardQueryContextValue | undefined
>(undefined)

export function useUserDashboardQuery(): UserDashboardQueryContextValue {
  const context = useContext(UserDashboardQueryContext)
  if (!context) {
    throw new Error(
      'useUserDashboardQuery must be used within a UserDashboardQueryProvider'
    )
  }
  return context
}

export function useUserDashboard(): UserDashboardQuery['userDashboard'] {
  const result = useUserDashboardQuery()
  return (
    result.data?.userDashboard || {
      __typename: 'UserDashboardData',
      extensionCandidatesCount: 0,
      openedSurveysCount: 0,
      unpaidInvoicesCount: 0
    }
  )
}

export const UserDashboardQueryProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const hasSession = useHasSession()
  const hadSession = useRef(hasSession)
  const options = useMemo(
    (): QueryHookOptions<UserDashboardQuery> => ({
      skip: !hasSession
    }),
    [hasSession]
  )

  const result = useQuery<UserDashboardQuery>(userDashboardQuery, options)

  const refetch = useRef(result.refetch)
  refetch.current = result.refetch
  useLayoutEffect(() => {
    if (hasSession && !hadSession.current) {
      refetch.current()
    }
    hadSession.current = hasSession
  }, [hasSession])

  return (
    <UserDashboardQueryContext.Provider value={result}>
      {children}
    </UserDashboardQueryContext.Provider>
  )
}
