import { action, ActionType } from 'typesafe-actions'
import { ActionCableCredentials } from '../actionCable/createActionCable'

export enum ActionCableChannel {
  Dashboard = 'DashboardChannel',
  Chat = 'ChatChannel',
  SuperUserDashboard = 'SuperUserCompanyChannel'
}

export enum ActionCableActionType {
  Init = '@@ActionCable/INIT',
  End = '@@ActionCable/END',
  Close = '@@ActionCable/CLOSE',
  Reinitialize = '@@ActionCable/REINITIALIZE',
  SubscribeToChannel = '@@ActionCable/SUBSCRIBE_TO_CHANNEL',
  UnsubscribeFromChannel = '@@ActionCable/UNSUBSCRIBE_FROM_CHANNEL',
  OnOpen = '@@ActionCable/ON_OPEN',
  OnWelcome = '@@ActionCable/ON_WELCOME',
  OnSubscribedToChannel = '@@ActionCable/ON_SUBSCRIBED_TO_CHANNEL',
  OnClose = '@@ActionCable/ON_CLOSE',
  OnError = '@@ActionCable/ON_ERROR',
  OnMessage = '@@ActionCable/ON_MESSAGE'
}

export type ChannelOptions =
  | {
      room_id: string
    }
  | { company_id: string }

export const init = (url: string, credentials: ActionCableCredentials) =>
  action(ActionCableActionType.Init, { url, credentials })

export const end = () => action(ActionCableActionType.End)

export const reinitialize = () => action(ActionCableActionType.Reinitialize)

export const close = () => action(ActionCableActionType.Close)

export const subscribeToChannel = <Channel = ActionCableChannel>(
  channel: Channel,
  options?: ChannelOptions
) =>
  action(ActionCableActionType.SubscribeToChannel, {
    channel,
    options
  })

export const unsubscribeFromChannel = <Channel = ActionCableChannel>(
  channel: Channel,
  options?: ChannelOptions
) =>
  action(ActionCableActionType.UnsubscribeFromChannel, {
    channel,
    options
  })

export const onOpen = (actionCableKey: string) =>
  action(ActionCableActionType.OnOpen, { actionCableKey })

export const onWelcome = () => action(ActionCableActionType.OnWelcome)

export const onSubscribedToChannel = (options: any) =>
  action(ActionCableActionType.OnSubscribedToChannel, { options })

export const onClose = () => action(ActionCableActionType.OnClose)

export const onError = (error?: any) =>
  action(ActionCableActionType.OnError, { error })

export type OnMessage<Message> = (data: Message) => {
  type: ActionCableActionType.OnMessage
  payload: Message
}

export const onMessage = <Message = {}>(data: Message) =>
  action(ActionCableActionType.OnMessage, data)

export type ActionCableAction = ActionType<{
  init: typeof init
  end: typeof end
  reinitialize: typeof reinitialize
  close: typeof close
  subscribeToChannel: typeof subscribeToChannel
  unsubscribeFromChannel: typeof unsubscribeFromChannel
  onOpen: typeof onOpen
  onWelcome: typeof onWelcome
  onSubscribedToChannel: typeof onSubscribedToChannel
  onClose: typeof onClose
  onError: typeof onError
  onMessage: typeof onMessage
}>

export const actionCableActions = {
  close,
  end,
  init,
  onClose,
  onError,
  onMessage,
  onOpen,
  onSubscribedToChannel,
  onWelcome,
  reinitialize,
  subscribeToChannel,
  unsubscribeFromChannel
}
