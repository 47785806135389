import { FC, useCallback, useEffect, useState } from 'react'
import { FontWeight, Text } from '@vivaldis/ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { UserFragment } from '@vivaldis/graphql'
import { useIntercom } from 'react-use-intercom'
import { getUnixTime, parseISO } from 'date-fns'
import IntercomGlyph from './IntercomGlyph'

const INTERCOM_LAUNCHER_CLASS = 'intercom_launcher'

interface Props {
  user: UserFragment
}

export const OpenIntercomButton: FC<Props> = ({ user }) => {
  const [t, { language }] = useTranslation('intercom_messenger')
  const { boot, hide, show, update } = useIntercom()
  const [, setIsIntercomOpen] = useState(false)

  const {
    id: userId,
    email,
    firstName,
    lastName,
    telephone,
    mobile,
    createdAt,
    company
  } = user

  const { id: companyId, name: companyName, logo: companyLogo } = company || {}

  useEffect(() => {
    // see available params here
    // https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
    boot({
      // MessengerAttributes
      hideDefaultLauncher: true,
      // DataAttributes
      email: email || undefined,
      userId: userId || undefined,
      createdAt: createdAt
        ? String(getUnixTime(parseISO(createdAt)))
        : undefined,
      name: `${firstName} ${lastName}`,
      phone: telephone || mobile || undefined,
      languageOverride: language || undefined,
      avatar: {
        type: 'avatar',
        imageUrl: companyLogo || undefined
      },
      company: {
        companyId: String(companyId),
        name: companyName || undefined
      }
    })
  })

  useEffect(() => {
    update({
      languageOverride: language || undefined
    })
  }, [language, update])

  const handleOnClick = useCallback(() => {
    setIsIntercomOpen(isIntercomOpen => {
      if (isIntercomOpen) {
        hide()
      } else {
        show()
      }
      return !isIntercomOpen
    })
  }, [hide, show])

  return (
    <ContentContainer
      onClick={handleOnClick}
      className={INTERCOM_LAUNCHER_CLASS}
      language={language}
    >
      <Wrapper>
        <IntercomGlyph />
        <Text
          fontSize={14}
          fontWeight={FontWeight.Semibold}
          letterSpacing={0.18}
          lineHeight={0.19}
          marginLeft={6}
        >
          {t('need_help')}
        </Text>
      </Wrapper>
    </ContentContainer>
  )
}

interface ContentContainerProps {
  language: string
}

const ContentContainer = styled('div')<ContentContainerProps>`
  min-height: 44px;
  width: ${props => (props.language === 'fr' ? 145 : 130)}px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  background: ${({ theme }) => theme.white};
  border-top-left-radius: 8px;
  box-shadow: 0 2px 15px 0px rgba(0, 0, 0, 0.1);
  pointer-events: all;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.gray4};
    @media (hover: none) and (pointer: coarse) {
      background: ${({ theme }) => theme.white};
    }
  }

  &:active {
    background: ${({ theme }) => theme.gray4};
  }
`

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 44px;
  padding: 12px 10px;
  box-sizing: border-box;
`
