import type { FillCompanySurveyInput } from '@vivaldis/graphql'
import type { SurveyFormValues } from '../provider/FormProvider'

export const surveyFormValuesToFillCompanySurveyInput = (
  values: SurveyFormValues,
  surveyId: string
): FillCompanySurveyInput => {
  return {
    surveyId: surveyId,
    answers: Object.entries(values).map(
      ([questionId, { boolValue, textValue }]) => {
        return {
          questionId,
          boolValue,
          textValue
        }
      }
    )
  }
}
