import { differenceInMinutes, parseISO } from 'date-fns'

interface Workday {
  startsAt?: string | Date | null
  endsAt?: string | Date | null
  breakInMinutes?: number | null
}

export const getWorkingTimeFromWorkday = (
  workday: Workday
): { workingTimeInMinutes: number; label: string } => {
  const { startsAt, endsAt, breakInMinutes = 0 } = workday
  if (!startsAt || !endsAt) {
    return {
      workingTimeInMinutes: 0,
      label: ''
    }
  }

  const workdayStartsAt =
    typeof startsAt === 'string' ? parseISO(startsAt) : startsAt
  const workdayEndsAt = typeof endsAt === 'string' ? parseISO(endsAt) : endsAt

  // 2h20min - 40min = 1h20min or 01:20
  const diffInMin =
    differenceInMinutes(workdayEndsAt, workdayStartsAt, {
      roundingMethod: 'round'
    }) - (breakInMinutes || 0)

  const hours = Math.floor(diffInMin / 60)
  const minutes = diffInMin % 60

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')

  //	"1 hr 10 min" = "01:10"

  return {
    workingTimeInMinutes: diffInMin,
    label: `${formattedHours}:${formattedMinutes}`
  }
}
