import { gql } from '@apollo/client'
import { companyFragment } from './CompanyFragment'
import { contractDatumFragment } from './ContractDatumFragment'
import { countryFragment } from './CountryFragment'
import { workPostSheetFragment } from './WorkPostSheetFragment'

export const positionFragment = gql`
  ${companyFragment}
  ${contractDatumFragment}
  ${countryFragment}
  ${workPostSheetFragment}

  fragment PositionFragment on Position {
    city
    company {
      ...CompanyFragment
    }
    contractDatum {
      ...ContractDatumFragment
    }
    country {
      ...CountryFragment
    }
    description
    id
    latitude
    longitude
    minimumWage
    name
    nettoPremiumComment
    state
    street
    streetNumber
    studentsAllowed
    workPostSheet {
      ...WorkPostSheetFragment
    }
    wpsRequired
    zip
  }
`
