import styled from '@emotion/styled'
import {
  ComponentType,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback
} from 'react'
import { ArrowDownCircle } from '@vivaldis/icons'
import { GlyphProps, useTheme } from '@vivaldis/ui'
import { css } from '@emotion/css'
import { createElement } from '../../utils/createElement'
import { useAccordionContext } from '../AccordionContext'

interface GlyphPropsWithState extends GlyphProps {
  expanded: boolean
}

interface AccordionSummaryProps {
  BaseComponent?: ReactNode | ComponentType
  IconComponent?: ComponentType<GlyphPropsWithState> | ReactNode
  glyphProps?: GlyphProps
  onClick?: MouseEventHandler<HTMLDivElement>
}

export const AccordionSummary: FC<PropsWithChildren<AccordionSummaryProps>> = ({
  BaseComponent = Base,
  IconComponent = ArrowDownCircle,
  glyphProps,
  onClick,
  children
}) => {
  const { disabled = false, expanded, toggle } = useAccordionContext()
  const theme = useTheme()

  const handleChange = useCallback(
    (event: any) => {
      if (disabled) {
        return
      }
      if (toggle) {
        toggle(event)
      }
      if (onClick) {
        onClick(event)
      }
    },
    [disabled, onClick, toggle]
  )

  const glyphComponent = createElement(IconComponent, {
    style: {
      color: theme.gray6,
      fontSize: 20
    },
    expanded,
    className: expanded ? expandedGlyphStyles : collapsedGlyphStyles,
    ...glyphProps
  })

  return createElement(
    BaseComponent,
    { onClick: handleChange },
    <>
      {' '}
      <ChildrenBase>{children}</ChildrenBase>
      {glyphComponent}
    </>
  )
}

const expandedGlyphStyles = css`
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: rotate(180deg);
`

const collapsedGlyphStyles = css`
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: rotate(0deg);
`

const Base = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
`

const ChildrenBase = styled.div`
  display: flex;
  flex-grow: 1;
`
