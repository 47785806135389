import { Persistor as ReduxPersistor } from 'redux-persist'
import { Store } from 'redux'
import { ApolloClient } from '@apollo/client'
import { Version } from './Version'
import type { CachePersistor } from 'apollo3-cache-persist'
import { Migration } from './Migration'
import { Data } from './Data'
import { UserType } from '@vivaldis/session'
import { FC, PropsWithChildren, ReactElement, ReactNode } from 'react'

interface Props {
  Providers: (children: ReactNode) => ReactElement | null
  apolloClient: ApolloClient<any>
  apolloCachePersistor: CachePersistor<any>
  reduxStore: Store
  reduxPersistor: ReduxPersistor
  version: string
  migrateBefore: (
    version: string,
    previousVersion?: string | null
  ) => Promise<any>
  migrateAfter: (
    version: string,
    previousVersion?: string | null
  ) => Promise<any>
  storage: any
  userType: UserType
  actionCableURL: string
  sideSheets?: ReactNode
}

export const App: FC<PropsWithChildren<Props>> = ({
  Providers,
  children,
  reduxPersistor,
  reduxStore,
  apolloClient,
  apolloCachePersistor,
  version,
  migrateBefore,
  migrateAfter,
  storage,
  userType,
  actionCableURL,
  sideSheets
}) => {
  return (
    <Version version={version} storage={storage}>
      <Migration migration={migrateBefore}>
        <Data
          Providers={Providers}
          reduxPersistor={reduxPersistor}
          reduxStore={reduxStore}
          apolloClient={apolloClient}
          apolloCachePersistor={apolloCachePersistor}
          userType={userType}
          actionCableURL={actionCableURL}
          sideSheets={sideSheets}
        >
          <Migration migration={migrateAfter}>{children}</Migration>
        </Data>
      </Migration>
    </Version>
  )
}
