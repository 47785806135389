import { useUserQuery } from './useUserQuery'
import { useMemo, useRef } from 'react'
import { UserQuery_user } from '../queries/__generated__/UserQuery'
import { isEqual } from 'lodash'
import { UserFragment } from '@vivaldis/graphql'

export function useUser(): UserFragment | undefined {
  const result = useUserQuery()

  const prevUser = useRef<UserQuery_user | null>(result?.data?.user ?? null)
  const user = useMemo((): UserQuery_user | null => {
    if (result) {
      if (isEqual(result.data?.user, prevUser.current)) {
        return prevUser.current
      }
      return result.data?.user ?? null
    }
    return null
  }, [result])
  prevUser.current = user

  return result?.data?.user ?? undefined
}
