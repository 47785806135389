import { useContext } from 'react'
import { SIDE_SHEET_DEFAULT_WIDTH } from '../../Layout/components/SideSheet'
import { SideSheetsElementsContext } from '../providers/SideSheetsElementsProvider'

export function useMaxActiveSideSheetsWidth(): number {
  const sideSheets = useContext(SideSheetsElementsContext)

  // get the biggest width of side panel if any was specified view state={{sideSheetWidth: 680}} (or default one)
  return sideSheets.reduce((previousValue, sideSheet) => {
    if (
      sideSheet?.location?.state &&
      typeof sideSheet?.location?.state === 'object' &&
      'sideSheetWidth' in sideSheet.location.state &&
      typeof sideSheet?.location?.state.sideSheetWidth === 'number'
    ) {
      return Math.max(sideSheet?.location?.state.sideSheetWidth, previousValue)
    }

    return previousValue
  }, SIDE_SHEET_DEFAULT_WIDTH)
}
