import type { NamePath } from 'antd/lib/form/interface'
import type { FormikErrors } from 'formik/dist/types'

export const getAntdFormNamePathsFromFormikErrors = (
  errors: FormikErrors<unknown>
): NamePath[] => {
  const antdFormNamePathErrors: NamePath[] = []

  if (Object.keys(errors).length) {
    Object.entries(errors).forEach(([fieldName, errorMessageOrErrors]) => {
      if (typeof errorMessageOrErrors === 'string') {
        antdFormNamePathErrors.push(fieldName)
      } else {
        // when we have nested object
        if (Array.isArray(errorMessageOrErrors)) {
          errorMessageOrErrors
            .filter(value => !!value)
            .forEach((item, index) => {
              const nestedErrors = getAntdFormNamePathsFromFormikErrors(item)
              nestedErrors.forEach(nestedError => {
                antdFormNamePathErrors.push(
                  `${fieldName}[${index}].${nestedError}`
                )
              })
            })
        }
      }
    })
  }

  return antdFormNamePathErrors
}
