import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input } from '@vivaldis/antd-ui'

export const Name: FC = () => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name="name"
      required
      label={t('position_form.step1.name.label', { ns: 'web_ui' })}
    >
      <Input name="name" autoComplete="off" placeholder={t('form.required')} />
    </Form.Item>
  )
}
