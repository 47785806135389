import { css } from '@emotion/react'

export interface FlexProps {
  alignItems?:
    | string
    | 'normal'
    | 'stretch'
    | 'center'
    | 'safe center'
    | 'unsafe center'
    | 'start'
    | 'end'
    | 'self-start'
    | 'self-end'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'initial'
    | 'inherit'
    | 'unset'
  alignContent?:
    | string
    | 'normal'
    | 'stretch'
    | 'center'
    | 'safe center'
    | 'unsafe center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
    | 'inherit'
    | 'unset'
  justifySelf?:
    | string
    | 'auto'
    | 'normal'
    | 'stretch'
    | 'center'
    | 'safe center'
    | 'unsafe center'
    | 'left'
    | 'right'
    | 'start'
    | 'end'
    | 'self-start'
    | 'self-end'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'initial'
    | 'inherit'
    | 'unset'
  alignSelf?:
    | string
    | 'auto'
    | 'normal'
    | 'stretch'
    | 'center'
    | 'safe center'
    | 'unsafe center'
    | 'start'
    | 'end'
    | 'self-start'
    | 'self-end'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'initial'
    | 'inherit'
    | 'unset'
  justifyItems?:
    | string
    | 'auto'
    | 'normal'
    | 'stretch'
    | 'center'
    | 'safe center'
    | 'unsafe center'
    | 'legacy center'
    | 'legacy left'
    | 'legacy right'
    | 'left'
    | 'right'
    | 'start'
    | 'end'
    | 'self-start'
    | 'self-end'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'initial'
    | 'inherit'
    | 'unset'
  justifyContent?:
    | string
    | 'normal'
    | 'stretch'
    | 'center'
    | 'safe center'
    | 'unsafe center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
    | 'inherit'
    | 'unset'
  flexWrap?:
    | 'nowrap'
    | 'wrap'
    | 'wrap-reverse'
    | 'initial'
    | 'inherit'
    | 'unset'
  flexDirection?:
    | 'row'
    | 'row-reverse'
    | 'column'
    | 'column-reverse'
    | 'initial'
    | 'inherit'
    | 'unset'
  // item
  flex?: string
  flexGrow?: number | 'inherit' | 'initial' | 'unset'
  flexShrink?: number | 'inherit' | 'initial' | 'unset'
  flexBasis?:
    | number
    | 'auto'
    | 'fill'
    | 'fit-content'
    | 'min-content'
    | 'max-content'
    | 'content'
    | 'inherit'
    | 'initial'
    | 'unset'
  order?: number | 'inherit' | 'initial' | 'unset'
}

export function flex(props: FlexProps & { [key: string]: any }) {
  return css`
    ${props.flex !== undefined ? `flex: ${props.flex}` : null};
    ${props.flexBasis !== undefined ? `flex-basis: ${props.flexBasis}` : null};
    ${props.flexDirection !== undefined
      ? `flex-direction: ${props.flexDirection}`
      : null};
    ${props.flexGrow !== undefined ? `flex-grow: ${props.flexGrow}` : null};
    ${props.flexShrink !== undefined
      ? `flex-shrink: ${props.flexShrink}`
      : null};
    ${props.flexWrap !== undefined ? `flex-wrap: ${props.flexWrap}` : null};
    ${props.order !== undefined ? `order: ${props.order}` : null};
    ${props.alignItems !== undefined
      ? `align-items: ${props.alignItems}`
      : null};
    ${props.alignContent !== undefined
      ? `align-content: ${props.alignContent}`
      : null};
    ${props.alignSelf !== undefined ? `align-self: ${props.alignSelf}` : null};
    ${props.justifyContent !== undefined
      ? `justify-content: ${props.justifyContent}`
      : null};
    ${props.justifyItems !== undefined
      ? `justify-items: ${props.justifyItems}`
      : null};
    ${props.justifySelf !== undefined
      ? `justify-self: ${props.justifySelf}`
      : null};
  `
}
