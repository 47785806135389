import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const TwoUserSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.95581 7.135C5.95581 5.131 7.58681 3.5 9.59181 3.5C11.5958 3.5 13.2268 5.131 13.2268 7.138C13.2308 8.104 12.8578 9.015 12.1758 9.7C11.4948 10.387 10.5868 10.766 9.62281 10.77H9.59181C7.58681 10.77 5.95581 9.139 5.95581 7.135ZM4.45581 7.135C4.45581 9.966 6.75981 12.27 9.59181 12.27H9.62581C10.9928 12.265 12.2778 11.728 13.2398 10.757C14.2038 9.787 14.7328 8.499 14.7268 7.135C14.7268 4.304 12.4228 2 9.59181 2C6.75981 2 4.45581 4.304 4.45581 7.135ZM3.49976 17.519C3.49976 15.814 6.81276 15.457 9.59176 15.457C13.6338 15.457 15.6838 16.144 15.6838 17.499C15.6838 18.864 13.6338 19.557 9.59176 19.557C5.54976 19.557 3.49976 18.871 3.49976 17.519ZM1.99976 17.519C1.99976 21.057 7.51576 21.057 9.59176 21.057C11.4698 21.057 17.1838 21.057 17.1838 17.499C17.1838 14.324 12.8508 13.957 9.59176 13.957C7.71376 13.957 1.99976 13.957 1.99976 17.519ZM16.4824 11.1318C16.1144 11.1318 15.7934 10.8608 15.7404 10.4858C15.6834 10.0758 15.9684 9.69577 16.3784 9.63877C17.6264 9.46377 18.5684 8.38077 18.5704 7.11877C18.5704 5.86477 17.6724 4.80677 16.4374 4.60477C16.0284 4.53677 15.7514 4.15177 15.8184 3.74277C15.8854 3.33377 16.2724 3.05877 16.6794 3.12377C18.6444 3.44577 20.0704 5.12677 20.0704 7.11977C20.0664 9.12477 18.5694 10.8468 16.5874 11.1248C16.5524 11.1298 16.5174 11.1318 16.4824 11.1318ZM19.1945 17.9769C19.3075 18.2759 19.5925 18.4609 19.8955 18.4609C19.9845 18.4609 20.0745 18.4449 20.1615 18.4119C21.6645 17.8429 21.9795 16.8559 21.9795 16.1269C21.9795 15.1479 21.4125 13.8949 18.7065 13.4899C18.3045 13.4369 17.9155 13.7119 17.8535 14.1219C17.7925 14.5309 18.0755 14.9129 18.4855 14.9749C19.8085 15.1719 20.4795 15.5599 20.4795 16.1269C20.4795 16.2949 20.4795 16.6879 19.6295 17.0099C19.2425 17.1559 19.0475 17.5899 19.1945 17.9769Z"
    />
  </svg>
)

export const TwoUser = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={TwoUserSvg} {...props} ref={ref} />
)
