import styled from '@emotion/styled'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getWorkingTimeFromWorkday } from '../../../utils/getWorkingTimeFromWorkday'

interface Props {
  startsAt?: string | Date | null
  endsAt?: string | Date | null
  breakInMinutes?: number | null
}

export const TotalHours: FC<Props> = ({ startsAt, endsAt, breakInMinutes }) => {
  const { t } = useTranslation('web_ui')

  const { label, workingTimeInMinutes } = useMemo(() => {
    return getWorkingTimeFromWorkday({ startsAt, endsAt, breakInMinutes })
  }, [breakInMinutes, endsAt, startsAt])

  return (
    <Base>
      <DayName>{label}</DayName>
      <Hours>
        {t('contract_schedule.hours_short', {
          count: workingTimeInMinutes ? workingTimeInMinutes / 60 : 0
        })}
      </Hours>
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const DayName = styled('div')`
  margin-right: 2px;
`

const Hours = styled('div')`
  color: ${props => props.theme.gray7};
`
