import { TextStylePreset, Typography, TypographyProps } from '@vivaldis/antd-ui'
import { FC } from 'react'

export const Title: FC<TypographyProps<'span'>> = ({
  textAlign = 'center',
  marginBottom = 20,
  ...props
}) => (
  <Typography.Text
    preset={TextStylePreset.Semibold18}
    marginBottom={marginBottom}
    textAlign={textAlign}
    style={{ whiteSpace: 'pre-line' }}
    {...props}
  />
)
