export enum ZIndex {
  Master = 50,
  sidebar = 80,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  navigationBar = 80,
  SideSheet = 90,
  BottomChat = 200,
  DrawerMenu = 300,
  Modal = 500,
  Snackbar = 1000,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ProgressBar = 1000
}
