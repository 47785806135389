import { gql } from '@apollo/client'

export const userDashboardQuery = gql`
  query UserDashboardQuery {
    userDashboard {
      extensionCandidatesCount
      openedSurveysCount
      unpaidInvoicesCount
    }
  }
`
