import { useNavigateOnPress } from '@vivaldis/web-ui'
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd'
import { forwardRef, useMemo } from 'react'
import { LinkProps as LinkPropsBase } from 'react-router-dom'

export interface ButtonProps extends AntdButtonProps {
  to?: string
  replace?: LinkPropsBase['replace']
  state?: LinkPropsBase['state']
  openInSideSheet?: boolean
  goBackTo?: string | boolean
}

const ButtonWithNavigation = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    { to, replace, state, openInSideSheet, goBackTo, onClick, ...props },
    ref
  ) => {
    const onPress = useNavigateOnPress({
      to,
      replace,
      state,
      onPress: onClick,
      navigate: true,
      openInSideSheet,
      goBackTo
    })

    const navigationProps = useMemo(() => {
      if (to) {
        return {
          href: to,
          onClick: onPress
        }
      }
      return {
        onClick
      }
    }, [onClick, onPress, to])

    return <AntdButton {...props} {...navigationProps} ref={ref} />
  }
)

// should we do it with hoist-non-react-statics ?
export const Button = Object.assign({ ...AntdButton }, ButtonWithNavigation)
