import { Button, Space } from '@vivaldis/antd-ui'
import { useUser } from '@vivaldis/session'
import { useCallback, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { updateUserMutation } from '@vivaldis/graphql'
import styled from '@emotion/styled'
import { useApolloClient } from '@apollo/client'

export const LanguagePicker: FC = () => {
  const [, i18n] = useTranslation('navigation_bar')
  const apolloClient = useApolloClient()
  const user = useUser()
  const handleLanguageClick = useCallback(
    (language: string) => {
      // NL -> EN -> FR
      i18n.changeLanguage(language)
      if (user?.email && user?.firstName && user?.lastName) {
        updateUserMutation(apolloClient, {
          variables: {
            input: {
              user: {
                email: user?.email,
                firstName: user?.firstName,
                lastName: user?.lastName,
                preferredLanguage: language
              }
            }
          }
        })
      }
    },
    [i18n, user?.email, user?.firstName, user?.lastName, apolloClient]
  )

  const handleEnLanguageClick = useCallback(() => {
    handleLanguageClick('en')
  }, [handleLanguageClick])

  const handleNlLanguageClick = useCallback(() => {
    handleLanguageClick('nl')
  }, [handleLanguageClick])

  const handleFrLanguageClick = useCallback(() => {
    handleLanguageClick('fr')
  }, [handleLanguageClick])

  return (
    <Base>
      <Space>
        <Button
          onClick={handleEnLanguageClick}
          type={i18n.language === 'en' ? 'primary' : 'default'}
        >
          EN
        </Button>
        <Button
          onClick={handleNlLanguageClick}
          type={i18n.language === 'nl' ? 'primary' : 'default'}
        >
          NL
        </Button>
        <Button
          onClick={handleFrLanguageClick}
          type={i18n.language === 'fr' ? 'primary' : 'default'}
        >
          FR
        </Button>
      </Space>
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 16px;
`
