import { Navigate, Outlet } from 'react-router-dom'

interface Props {
  isAllowed: boolean
  redirectPath?: string
  children: JSX.Element
}

export const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/',
  children
}: Props) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}
