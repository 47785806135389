import { addOnEventHandler } from '../../tracking/trackEvent'
import { addOnUserHandler, UserUser } from '../../tracking/trackUser'

declare let window: any

const pushEventToDataLayer = (event: any) => {
  const dataLayer = window?.dataLayer || []
  return dataLayer.push(event)
}

export function initGoogleTagManagerTracking() {
  addOnEventHandler((key, _name, data) => {
    pushEventToDataLayer({
      event: key,
      ...data
    })

    // User successful registration - Custom Event
    // window.dataLayer.push({
    //   event: 'user_successful_registration',
    //   userId: 'Z2lkOi8vc2VhbHYyL1JlY3J1aXRlci8yMDA4',
    //   brandName: 'Seal'
    // })

    // window.dataLayer.push({
    //   userId: 'xx2233alex555',
    //   userName: 'Alex User',
    //   companyName: 'Alex Inc.',
    //   companyId: 'yyy2233alexInc555',
    // })

    // Set "paidUser" to "Yes" in data layer
    // window.dataLayer.push({
    //   event: 'set_paid_user_data_layer_variable_to_yes',
    //   paidUser: 'Yes',
    //   brandName: 'Seal'
    // })

    // Links with campaign to test
    // https://www.seal.jobs/?utm_source=alex-test-mailer&utm_medium=alex-test-email&utm_campaign=alex-test-sale
    // https://klant.seal.jobs/?utm_source=alex-test-mailer&utm_medium=alex-test-email&utm_campaign=alex-test-sale
  })

  addOnUserHandler((user: UserUser) => {
    if (user.id) {
      pushEventToDataLayer({
        userId: user.id,
        userName: user.name,
        companyName: user.companyName,
        companyId: user.companyId
      })
    }
  })
}
