import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const HomeSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7168 15.2913C14.9208 15.2913 15.9008 16.2643 15.9008 17.4603V20.5362C15.9008 20.7932 16.1068 20.9992 16.3708 21.0052H18.2768C19.7788 21.0052 20.9998 19.7993 20.9998 18.3173V9.59325C20.9928 9.08325 20.7498 8.60325 20.3328 8.28425L13.7398 3.02625C12.8548 2.32525 11.6168 2.32525 10.7288 3.02825L4.18076 8.28225C3.74776 8.61125 3.50476 9.09125 3.49976 9.61025V18.3173C3.49976 19.7993 4.72076 21.0052 6.22276 21.0052H8.14676C8.41776 21.0052 8.63776 20.7902 8.63776 20.5262C8.63776 20.4682 8.64476 20.4102 8.65676 20.3552V17.4603C8.65676 16.2713 9.63076 15.2993 10.8258 15.2913H13.7168ZM18.2768 22.5053H16.3528C15.2508 22.4793 14.4008 21.6143 14.4008 20.5363V17.4603C14.4008 17.0913 14.0938 16.7913 13.7168 16.7913H10.8308C10.4618 16.7933 10.1568 17.0943 10.1568 17.4603V20.5263C10.1568 20.6013 10.1468 20.6733 10.1258 20.7413C10.0178 21.7313 9.17176 22.5053 8.14676 22.5053H6.22276C3.89376 22.5053 1.99976 20.6262 1.99976 18.3172V9.60325C2.00976 8.60925 2.46776 7.69925 3.25876 7.10025L9.79376 1.85525C11.2328 0.71525 13.2378 0.71525 14.6738 1.85325L21.2558 7.10325C22.0288 7.69225 22.4868 8.60025 22.4998 9.58225V18.3172C22.4998 20.6262 20.6058 22.5053 18.2768 22.5053V22.5053Z"
    />
  </svg>
)

export const Home = forwardRef<HTMLSpanElement, AntdIconProps>((props, ref) => (
  <Icon component={HomeSvg} {...props} ref={ref} />
))
