import { ApolloCache, DocumentNode } from '@apollo/client'
import {
  resultKeyNameFromField,
  getQueryDefinition,
  valueToObjectRepresentation
} from '@apollo/client/utilities'
import { uniq } from 'lodash'
import type { SelectionNode } from 'graphql/language/ast'

export function getVariablesFromCache(
  apolloCache: ApolloCache<any>,
  query: DocumentNode
) {
  const serializedCache = apolloCache.extract()
  const ROOT_QUERY = { ...serializedCache.ROOT_QUERY }

  let queryNames: string[] = []
  const queryDefinition = getQueryDefinition(query)

  queryDefinition.selectionSet.selections.forEach(selection => {
    if (selection.kind === 'Field') {
      const connectionKey: string | undefined = getConnectionKey(selection)
      const originalQueryName: string = resultKeyNameFromField(selection)

      // this is to handle @connection directive.
      // Example 1: `companies(name: $name, fromTime: $fromTime, first: $count, after: $cursor) @connection(key: "companyList")`  - key in ROOT_QUERY  = companies:companyList
      // Example 2: `team(companyId: $companyId, first: $count, after: $cursor) @connection(key: "TeamMemberListListPaginatedQuery_team" filter: ["companyId"])` - key in ROOT_QUERY  = team:TeamMemberListListPaginatedQuery_team
      // Example 3: `companies(name: $name, fromTime: $fromTime)`  - key in ROOT_QUERY  = companies
      if (connectionKey) {
        queryNames.push(`${originalQueryName}:${connectionKey}`)
      } else {
        queryNames.push(originalQueryName)
      }
    }
  })
  queryNames = uniq(queryNames)

  const matches: { [key: string]: string[] } = {}
  queryNames.forEach(queryName => (matches[queryName] = []))

  // NOTE: we need only variables that are part of ROOT_QUERY key because we need update this key in apollo store
  const len = queryNames.length
  for (const prop in ROOT_QUERY) {
    if (Object.hasOwn(ROOT_QUERY, prop)) {
      for (let i = 0; i <= len; i++) {
        if (prop.startsWith(`${queryNames[i]}(`)) {
          matches[queryNames[i]].push(prop)
        }
      }
    }
  }

  const variables: any[] = []
  for (const queryName in matches) {
    if (Object.hasOwn(matches, queryName)) {
      matches[queryName].forEach(i => {
        variables.push(JSON.parse((i.match(/{.*}/) as string[])[0]))
      })
    }
  }

  return variables
}

const getConnectionKey = (selection: SelectionNode) => {
  if (selection.directives) {
    const connectionDirective = selection.directives
      .find(directive => directive.name.value === 'connection')
      ?.arguments?.find(argument => argument.name.value === 'key')

    if (connectionDirective) {
      const connectionDirectiveObject: { key?: string } = {}
      valueToObjectRepresentation(
        connectionDirectiveObject,
        connectionDirective.name,
        connectionDirective.value
      )
      return connectionDirectiveObject?.key
    }
  }
}
