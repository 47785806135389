import styled from '@emotion/styled'

export const Container = styled.div`
  height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #00b287;
`
