import { DangerTriangle, Edit, Show } from '@vivaldis/icons'
import { FC } from 'react'
import styled from '@emotion/styled'
import { Button, Space } from '@vivaldis/antd-ui'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@vivaldis/ui'
import { BodyAlt } from '../../../../../../Typography'

interface WPSCheckboxProps {
  positionId?: string
  viewWPSUrl: string
  editWPSUrl: string
}

export const WPSItem: FC<WPSCheckboxProps> = ({
  positionId,
  viewWPSUrl,
  editWPSUrl
}) => {
  const [t] = useTranslation('web_ui')
  const theme = useTheme()

  return (
    <Container>
      <DangerTriangle
        style={{
          color: theme.VivaldisOrange_orange7,
          fontSize: 26
        }}
      />
      <MessageContainer>
        <BodyAlt letterSpacing={0}>
          {t('position_form.step2.wps.has_wps')}
        </BodyAlt>
      </MessageContainer>
      <ActionsContainer>
        <Button
          shape="circle"
          size="large"
          to={viewWPSUrl}
          openInSideSheet
          goBackTo
          icon={<Show />}
        />
        <Button
          shape="circle"
          size="large"
          to={editWPSUrl}
          openInSideSheet
          goBackTo
          icon={<Edit />}
        />
      </ActionsContainer>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 100%;
  background: ${({ theme }) => theme.white};
  border: 2px solid ${({ theme }) => theme.gray4};
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 16px;
`

const MessageContainer = styled('div')`
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
  overflow: hidden;
`

const ActionsContainer = styled(Space)`
  display: flex;
  flex: 0 0 80px;
  justify-content: space-between;

  @media only screen and (max-width: 350px) {
    flex: 0;
    flex-direction: column;
  }
`
