import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const LocationSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.49951 10.313C5.49951 6.557 8.52751 3.5 12.2495 3.5C15.9715 3.5 18.9995 6.557 18.9995 10.313C18.9995 15.091 13.3755 19.747 12.2495 19.996C11.1235 19.748 5.49951 15.092 5.49951 10.313ZM3.99951 10.313C3.99951 15.948 10.4555 21.5 12.2495 21.5C14.0435 21.5 20.4995 15.948 20.4995 10.313C20.4995 5.729 16.7985 2 12.2495 2C7.70051 2 3.99951 5.729 3.99951 10.313ZM10.5005 10.251C10.5005 9.285 11.2855 8.5 12.2505 8.5C13.2155 8.5 14.0005 9.285 14.0005 10.251C14.0005 11.216 13.2155 12 12.2505 12C11.2855 12 10.5005 11.216 10.5005 10.251ZM9.00049 10.251C9.00049 12.043 10.4585 13.5 12.2505 13.5C14.0425 13.5 15.5005 12.043 15.5005 10.251C15.5005 8.458 14.0425 7 12.2505 7C10.4585 7 9.00049 8.458 9.00049 10.251Z"
    />
  </svg>
)

export const Location = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={LocationSvg} {...props} ref={ref} />
)
