import { Button, TextStylePreset, Typography } from '@vivaldis/antd-ui'
import { Plus } from '@vivaldis/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { usePosition } from '../../../../../providers/PositionProvider'
import { useForm } from '../../../providers/FormProvider'
import { WPSItem } from './WPSItem'

interface Props {
  viewWPSUrl: string
  addWPSUrl: string
  editWPSUrl: string
}

export const WPSUpdate: FC<Props> = ({ viewWPSUrl, addWPSUrl, editWPSUrl }) => {
  const { t } = useTranslation('web_ui')
  const { values } = useForm()

  const position = usePosition()

  if (!position) {
    return null
  }

  const positionId = position.id
  const hasWps = !!position.workPostSheet

  if (!hasWps && !values.wpsRequired) {
    return null
  }

  return (
    <>
      {hasWps ? (
        <>
          <Typography.Paragraph
            preset={TextStylePreset.Semibold16}
            marginBottom={12}
          >
            {t('position_form.step2.wps.label')}
          </Typography.Paragraph>
          <WPSItem
            positionId={positionId}
            viewWPSUrl={viewWPSUrl}
            editWPSUrl={editWPSUrl}
          />
        </>
      ) : (
        <>
          <Container>
            <Typography.Text
              preset={TextStylePreset.Semibold16}
              marginRight={16}
            >
              {t('position_form.step2.wps.label')}
            </Typography.Text>
            <ButtonContainer>
              <Button
                to={addWPSUrl}
                openInSideSheet
                goBackTo
                type="link"
                icon={<Plus />}
              >
                {t('position_form.step2.wps.add_button')}
              </Button>
            </ButtonContainer>
          </Container>
          <Typography.Paragraph
            preset={TextStylePreset.Regular16}
            marginBottom={16}
          >
            {t('position_form.step2.wps.no_wps')}
          </Typography.Paragraph>
        </>
      )}
    </>
  )
}

const Container = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @media only screen and (max-width: 500px) {
    display: flex;
    grid-gap: 0px;
    flex-direction: column;
    align-items: flex-start;
  }
`

const ButtonContainer = styled('div')`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 500px) {
    margin-top: 16px;
    justify-content: flex-start;
  }
`
