import { FC, Fragment } from 'react'
import { createElement } from '@vivaldis/common'
import { Space } from '@vivaldis/antd-ui'
import { ComponentType, ReactElement } from 'react'

export interface NavigationBarActionButtonsProps {
  actions: (ComponentType | ReactElement | null)[]
}

export const ActionButtons: FC<NavigationBarActionButtonsProps> = ({
  actions
}) => {
  return (
    <Space size="small">
      {actions.map((action, index) => (
        <Fragment key={index}>{createElement(action)}</Fragment>
      ))}
    </Space>
  )
}
