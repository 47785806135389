import { ApolloLink } from '@apollo/client'
import { getReduxStore } from '../../redux'
import { UserType } from '../../typings/UserType'

export const authLink = (
  sessionSelector: (state: any) => any,
  userType: UserType
) =>
  new ApolloLink((operation, forward) => {
    const session = sessionSelector(getReduxStore().getState())

    let sessionHeaders = {}
    switch (userType) {
      case UserType.Employee:
        sessionHeaders = {
          'X-Employee-Email': session.email,
          'X-Employee-Token': session.authentication_token
        }
        break
      case UserType.User:
      case UserType.SuperUser:
        sessionHeaders = {
          'X-User-Email': session.email,
          'X-User-Token': session.authentication_token
        }
        break
    }

    operation.setContext(({ headers = {} }: any) => ({
      headers: {
        ...headers,
        ...sessionHeaders
      }
    }))
    return forward ? forward(operation) : null
  })
