import styled from '@emotion/styled'
import {
  ComponentType,
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactNode
} from 'react'
import { createElement } from '../../utils/createElement'

export interface AccordionDetailsProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  BaseComponent?: ReactNode | ComponentType
}

export const AccordionDetails: FC<AccordionDetailsProps> = ({
  BaseComponent = Base,
  children,
  ...props
}) => {
  return createElement(BaseComponent, props, children)
}

const Base = styled.div`
  display: flex;
  padding: 8px 16px 16px;
`
