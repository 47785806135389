import { PremiumUnitsEnum } from '../../../../../../../typings/PremiumUnitsEnum'

export const getDefaultUnitTypeForPremium = (premiumName: string) => {
  switch (premiumName) {
    case 'cold_weather_bonus':
      return PremiumUnitsEnum.PERCENT
    case 'late_shift_bonus':
      return PremiumUnitsEnum.PERCENT
    case 'night_shift_bonus':
      return PremiumUnitsEnum.PERCENT
    case 'morning_shift_bonus':
      return PremiumUnitsEnum.PERCENT
    case 'meal_vouchers':
      return PremiumUnitsEnum.PER_DAY
    case 'bike_bonus':
      return PremiumUnitsEnum.PER_DAY
    case 'kilometer_compensation':
      return PremiumUnitsEnum.PERCENT
    case 'social_kilometer_compensation':
      return PremiumUnitsEnum.PER_KILOMETER
    case 'eco_cheque':
      return PremiumUnitsEnum.PERCENT
    case 'dimona':
      return PremiumUnitsEnum.PERCENT
    case 'mobility':
      return PremiumUnitsEnum.PER_KILOMETER
    case 'other':
      return PremiumUnitsEnum.PERCENT
    default:
      return PremiumUnitsEnum.PERCENT
  }
}
