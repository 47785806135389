import { setLocale, type LocaleObject } from 'yup'

// please check source file to see all possible cases -> https://github.com/jquense/yup/blob/master/src/locale.ts
const yupLocales: Record<string, LocaleObject> = {
  en: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is a required field',
      defined: '${path} must be defined',
      notNull: '${path} is a required field',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}'
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      uuid: '${path} must be a valid UUID',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string'
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer'
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}'
    },
    boolean: {
      isValue: '${path} field must be ${value}'
    },
    object: {
      noUnknown: '${path} field has unspecified keys: ${unknown}'
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
      length: '${path} must have ${length} items'
    }
  },
  fr: {
    mixed: {
      default: "${path} n'est pas valable",
      required: '${path} est un champ obligatoire',
      defined: '${path} doit être défini',
      notNull: '${path} est un champ obligatoire',
      oneOf: '${path} doit être une des valeurs suivantes: ${values}',
      notOneOf: '${path} ne peut pas être une des valeurs suivantes: ${values}'
    },
    string: {
      length: '${path} doit contenir exactement ${length} caractères',
      min: '${path} doit contenir au minimum ${min} caractères',
      max: '${path} doit contenir au maximum ${max} caractères',
      matches: '${path} doit correspondre avec ceci: "${regex}"',
      email: '${path} doit être une adresse mail valable',
      url: '${path} doit être un url valable',
      uuid: '${path} doit être un UUID valable',
      trim: "${path} ne peut pas contenir d'espace",
      lowercase: '${path} ne peut pas contenir de lettres majuscules',
      uppercase: '${path} peut contenir que des lettres majuscules'
    },
    number: {
      min: '${path} doit être plus grand ou égal à ${min}',
      max: '${path} doit être plus petit ou égal à aa ${max}',
      lessThan: '${path} doit être inférieur à ${less}',
      moreThan: '${path} doit être supérieur à ${more}',
      positive: '${path} doit être un numéro positif',
      negative: '${path} doit être un numéro négatif',
      integer: '${path} doit être un nombre entier'
    },
    date: {
      min: '${path} doit être plus tard que ${min}',
      max: '${path} doit être avant que ${max}'
    },
    boolean: {
      isValue: '${path} doit être ${value}'
    },
    object: {
      noUnknown:
        '${path} Le champ ne contient pas de caractères connus: ${unknown}'
    },
    array: {
      min: '${path} Le champ doit contenir au minimum ${min} caractères',
      max: '${path} le champ peut contenir au maximum ${max} caractères',
      length: '${path} doit faire ${length} caractères'
    }
  },
  nl: {
    mixed: {
      default: '${path} is ongeldig',
      required: '${path} is een verplicht veld',
      defined: '${path} moet worden gedefinieerd',
      notNull: '${path} is een verplicht veld',
      oneOf: '${path} moet een van de volgende waarden zijn: ${values}',
      notOneOf: '${path} mag niet een van de volgende waarden zijn: ${values}'
    },
    string: {
      length: '${path} moet exact ${length} tekens bevatten',
      min: '${path} moet minimaal ${min} tekens bevatten',
      max: '${path} mag maximaal ${max} tekens bevatten',
      matches: '${path} moet overeenkomen met het volgende: "${regex}"',
      email: '${path} moet een geldig e-mailadres zijn',
      url: '${path} moet een geldige URL zijn',
      uuid: '${path}moet een geldige UUID zijn',
      trim: '${path} mag geen spaties bevatten',
      lowercase: '${path} mag geen hoofdletters bevatten',
      uppercase: '${path} mag enkel hoofdletters bevatten'
    },
    number: {
      min: '${path} moet groter of gelijk zijn aan ${min}',
      max: '${path} moet kleiner of gelijk zijn aa ${max}',
      lessThan: '${path} moet minder zijn dan ${less}',
      moreThan: '${path} moet meer zijn dan ${more}',
      positive: '${path} moet een positief nummer zijn',
      negative: '${path} moet een negatief nummer zijn',
      integer: '${path} moet een geheel getal zijn'
    },
    date: {
      min: '${path} moet later zijn dan ${min}',
      max: '${path} moet vroeger zijn dan ${max}'
    },
    boolean: {
      isValue: '${path} moet ${value} zijn'
    },
    object: {
      noUnknown: '${path} veld heeft niet gekende tekens: ${unknown}'
    },
    array: {
      min: '${path} veld moet minimaal ${min} items bevatten',
      max: '${path} veld mag maximaal ${max} items bevatten',
      length: '${path} moet ${length} items bevatten'
    }
  }
}

// documentation with usage is locates here -> https://github.com/jquense/yup#using-a-custom-locale-dictionary
export const setYupLocale = (locale: string) => {
  const yupLocale = yupLocales[locale] || yupLocales.en
  setLocale(yupLocale)
}
