import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { SessionSignUp } from '../reducer'
import { signUp } from '../actions'
import { SignUpRequestData } from '../api/signUp'
import { signUpSelector } from '../selectors'

export interface SignUpResult extends SessionSignUp {
  signUp: (data: SignUpRequestData) => void
}

export function useSignUp(): SignUpResult {
  const dispatch = useDispatch()
  const state = useSelector(signUpSelector)

  const signUpCallback = useCallback(
    (data: SignUpRequestData) => {
      dispatch(signUp(data))
    },
    [dispatch]
  )

  const result = useMemo(
    (): SignUpResult => ({
      signUp: signUpCallback,
      ...state
    }),
    [signUpCallback, state]
  )

  return result
}
