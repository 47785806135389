import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { SignInRequestData } from '../api/signIn'
import { signInSelector } from '../selectors'
import { SessionSignIn } from '../reducer'
import { signIn } from '../actions'

export interface SignInResult extends SessionSignIn {
  signIn: (
    data: SignInRequestData,
    onSuccessSignIn?: () => void,
    onFailedSignIn?: (error: unknown) => void
  ) => void
}

export function useSignIn(): SignInResult {
  const dispatch = useDispatch()
  const state = useSelector(signInSelector)

  const signInCallback = useCallback(
    (
      data: SignInRequestData,
      onSuccessSignIn?: () => void,
      onFailedSignIn?: (error: unknown) => void
    ) => {
      dispatch(signIn(data, { onSuccessSignIn, onFailedSignIn }))
    },
    [dispatch]
  )

  const result = useMemo(
    (): SignInResult => ({
      ...state,
      signIn: signInCallback
    }),
    [signInCallback, state]
  )

  return result
}
