import styled from '@emotion/styled'
import { Row } from '@vivaldis/antd-ui'
import { ON_THE_MOVE, useCountryName } from '@vivaldis/common'
import { ContractReasonsEnum } from '@vivaldis/graphql'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useContractReasonOptions } from '../../../ContractReason'
import { useContractTypeLabel } from '../../../ContractType'
import { Information } from '../../../Information'
import { useStatuteLabel } from '../../../Statute'
import { Contract } from '../../typings/Contract'
import { VCAExamDateInformationField } from './components/VCAExamDateInformationField'
import { Certificates } from './components/Certificates'

interface Props {
  contract: Contract
  showContractType: boolean
  showCommittee: boolean
}

export const ContractInformation: FC<Props> = ({
  contract,
  showContractType,
  showCommittee
}) => {
  const [t] = useTranslation('web_ui')

  const employeeName = useMemo(
    () => `${contract.employee.firstName} ${contract.employee.lastName}`,
    [contract.employee.firstName, contract.employee.lastName]
  )

  const contractReasonOptions = useContractReasonOptions([
    contract.contractReason
  ])

  const contractReasonLabel = useMemo(() => {
    return (
      contractReasonOptions.find(
        option => option.value === contract.contractReason
      )?.label || contract.contractReason
    )
  }, [contract.contractReason, contractReasonOptions])

  const countryName = useCountryName(contract.country)

  const statuteLabel = useStatuteLabel(contract.position.contractDatum)

  const contractTypeLabel = useContractTypeLabel(
    contract.position.contractDatum
  )

  // we show this field only for SU
  const showBackOfficeId = useMemo(() => {
    // this field is available only for SU
    return Object.hasOwn(contract, 'backOfficeId')
  }, [contract])

  // we show this field only for SU
  const showCompany = useMemo(() => {
    // this field is available only for SU
    return Object.hasOwn(contract.position, 'company')
  }, [contract])

  const showCertificates = useMemo(() => {
    // this field is available only for SU
    return Object.hasOwn(contract.position, 'certificateTypes')
  }, [contract])

  const committeeLabel = useMemo(() => {
    const committee = contract.position.contractDatum?.committee

    if (!committee) {
      return null
    }
    const workerType = committee.isWorker
      ? t('view_contract_screen_content.committee.options.worker')
      : t('view_contract_screen_content.committee.options.clerk')
    return `${committee.code} (${workerType}) - ${committee.name}`
  }, [contract.position.contractDatum?.committee, t])

  return (
    <Base>
      <Information>
        {showBackOfficeId ? (
          <Information.InformationField
            label={t('view_contract_screen_content.back_office_id')}
          >
            {contract.backOfficeId}
          </Information.InformationField>
        ) : null}

        <Information.InformationField
          label={t('view_contract_screen_content.employee')}
        >
          {employeeName}
        </Information.InformationField>

        {showCompany ? (
          <Information.InformationField
            label={t('view_contract_screen_content.company')}
          >
            {contract.position.company?.name}
          </Information.InformationField>
        ) : null}

        <Information.InformationField
          label={t('view_contract_screen_content.position')}
        >
          {contract.position.name}
        </Information.InformationField>

        {showCommittee ? (
          <Information.InformationField
            label={t('view_contract_screen_content.committee.label')}
          >
            {committeeLabel || t('not_set')}
          </Information.InformationField>
        ) : null}

        <Information.InformationField
          label={t('view_contract_screen_content.location.label')}
        >
          {contract.street === ON_THE_MOVE ? (
            <>{t('view_contract_screen_content.location.on_the_move')}</>
          ) : (
            <>
              <Row justify="end">
                {`${contract.street} ${contract.streetNumber} ${contract.buildingNumber}`}
              </Row>
              <Row justify="end">
                {contract.city}, {countryName}
              </Row>
            </>
          )}
        </Information.InformationField>

        <Information.InformationField
          label={t('view_contract_screen_content.statute')}
        >
          {statuteLabel}
        </Information.InformationField>

        {showContractType ? (
          <Information.InformationField
            label={t('view_contract_screen_content.contract_type')}
          >
            {contractTypeLabel}
          </Information.InformationField>
        ) : null}

        <Information.InformationField
          label={t('view_contract_screen_content.contract_reason')}
        >
          {contractReasonLabel}
        </Information.InformationField>

        {contract.contractReason === ContractReasonsEnum.INSTROOM ? (
          <Information.InformationField
            label={t('view_contract_screen_content.inflow_attempts_count')}
          >
            {contract.attemptsCount}
          </Information.InformationField>
        ) : null}

        <Information.InformationField
          label={t('view_contract_screen_content.wage')}
        >
          {t('view_contract_screen_content.wage_value', {
            count: contract.wage
          })}
        </Information.InformationField>

        <VCAExamDateInformationField contract={contract} />

        {showCertificates ? (
          <Information.InformationField
            label={t('view_contract_screen_content.certificates')}
          >
            <Certificates contract={contract} />
          </Information.InformationField>
        ) : null}
      </Information>
    </Base>
  )
}

const Base = styled('div')``
