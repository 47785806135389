// VPASS format is: '0 km', '99 km', '9999 km'
export const numberValueToVpassKMValue = (value: string | number | null) => {
  if (value === null) {
    return '0 km'
  }
  if (typeof value === 'number') {
    return value.toFixed(0).toString().concat(' ', 'km')
  }
  return '0 km'
}
