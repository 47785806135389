import { Body } from './components/Body'
import { BodyAlt } from './components/BodyAlt'
import { Button } from './components/Button'
import { Caption } from './components/Caption'
import { CaptionAlt } from './components/CaptionAlt'
import { Header } from './components/Header'
import { LargeHeader } from './components/LargeHeader'
import { SectionHeader } from './components/SectionHeader'
import { Subheader } from './components/Subheader'

export const Typography = {
  Body,
  BodyAlt,
  Button,
  Caption,
  CaptionAlt,
  Header,
  LargeHeader,
  SectionHeader,
  Subheader
}
