import { CloseSquare } from '@vivaldis/icons'
import { useTheme } from '@vivaldis/ui'
import { FC } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Button } from '@vivaldis/antd-ui'
import { useDrawer } from '../../providers/DrawerProvider'
import { Logo } from '../../../Logo'

interface Props {}

export const HeaderBar: FC<Props> = () => {
  const { t } = useTranslation('web_ui')
  const theme = useTheme()
  const { close } = useDrawer()
  return (
    <Container>
      <Wrapper>
        <Button to="/" type="link" size="small" style={{ paddingLeft: 0 }}>
          <Logo />
        </Button>
        <Button
          icon={
            <CloseSquare style={{ fontSize: 26, color: theme.primaryColor }} />
          }
          type="text"
          onClick={close}
          title={t('drawer.close')}
        />
      </Wrapper>
    </Container>
  )
}

const Container = styled('div')`
  min-height: 90px;
  padding-top: 34px;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  box-sizing: border-box;
`

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`
