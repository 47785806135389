import { i18n } from '.'
import { useTranslation, UseTranslationOptions } from 'react-i18next'
import { KeyPrefix, Namespace } from 'i18next'

export function useT<
  N extends Namespace,
  TKPrefix extends KeyPrefix<N> = undefined
>(ns: N | Readonly<N>, options?: UseTranslationOptions<TKPrefix>) {
  const { t } = useTranslation<N, TKPrefix>(ns, { i18n: i18n, ...options })
  return t
}
