import { useField } from 'formik'
import { forwardRef, useCallback } from 'react'
import { Checkbox as AntdCheckbox } from '../../Checkbox'
import type { CheckboxGroupProps as AntdCheckboxGroupProps } from '../../Checkbox'
import type { FormikFieldProps } from '../typings/FieldProps'

export type CheckboxGroupProps = FormikFieldProps & AntdCheckboxGroupProps

export const Group = forwardRef<HTMLDivElement, CheckboxGroupProps>(
  ({ name, onChange: onChangeProp, ...restProps }, ref) => {
    const [field, , helpers] = useField(name)
    const { value } = field
    const { setValue, setTouched } = helpers

    const handleChange = useCallback(
      (value: any) => {
        setValue(value)
        setTouched(true)
        onChangeProp?.(value)
      },
      [onChangeProp, setTouched, setValue]
    )

    return (
      <AntdCheckbox.Group
        ref={ref}
        name={name}
        value={value}
        onChange={handleChange}
        {...restProps}
      />
    )
  }
)
