import { useField } from 'formik'
import { FC, useCallback } from 'react'
import type { FormikFieldProps } from '../typings/FieldProps'
import { PellEditor } from './components/PellEditor'

export type RichTextEditorProps = FormikFieldProps & {
  disabled?: boolean
  placeholder?: string
}

export const RichTextEditor: FC<RichTextEditorProps> = ({
  name,
  disabled,
  placeholder = ''
}) => {
  const [field, , helpers] = useField(name)
  const { value } = field
  const { setValue, setTouched } = helpers

  const handleChange = useCallback(
    (value: string) => {
      setValue(value)
    },
    [setValue]
  )

  const handleBlur = useCallback(() => {
    setTouched(true)
  }, [setTouched])

  return (
    <PellEditor
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      placeholder={placeholder}
    />
  )
}
