import { useNavigateOnPress } from '@vivaldis/web-ui'
import { Dropdown as AntdDropdown } from 'antd'
import type {
  DropdownButtonProps as AntdDropdownButtonProps,
  DropdownButtonType as AntdDropdownButtonType
} from 'antd/lib/dropdown/dropdown-button'
import { FC, useMemo } from 'react'
import { LinkProps as LinkPropsBase } from 'react-router-dom'

export type DropdownButtonType = AntdDropdownButtonType

export interface DropdownButtonProps extends AntdDropdownButtonProps {
  to?: string
  replace?: LinkPropsBase['replace']
  state?: LinkPropsBase['state']
  openInSideSheet?: boolean
  goBackTo?: string | boolean
}

const DropdownButtonWithNavigation: FC<DropdownButtonProps> = ({
  to,
  replace,
  state,
  openInSideSheet,
  goBackTo,
  onClick,
  ...props
}) => {
  const onPress = useNavigateOnPress({
    to,
    replace,
    state,
    onPress: onClick,
    navigate: true,
    openInSideSheet,
    goBackTo
  })

  const navigationProps = useMemo(() => {
    if (to) {
      return {
        href: to,
        onClick: onPress
      }
    }
    return {
      onClick
    }
  }, [onClick, onPress, to])

  return <AntdDropdown.Button {...props} {...navigationProps} />
}

export const DropdownButton = DropdownButtonWithNavigation
