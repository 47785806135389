// employee need to have BIC if his bank account number is not from Belgium
export function doesEmployeeNeedBIC(
  bankAccount: string | null | undefined
): boolean {
  if (!bankAccount) {
    return false
  }

  return !bankAccount.toUpperCase().startsWith('BE')
}
