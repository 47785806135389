import i18next from 'i18next'
import { resources } from './resources'

export const i18n = i18next.createInstance({
  resources,
  fallbackLng: 'en',
  lng: 'en',

  interpolation: {
    escapeValue: false,
    format: function (value, format) {
      if (format === 'rounded') return Math.round(value)
      return value
    }
  },
  returnNull: false
})

i18n.init()
