import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, InputNumber, Select } from '@vivaldis/antd-ui'
import { PremiumUnitsEnum } from '../../../../../../../typings/PremiumUnitsEnum'

interface Props {
  index: number
}

export const NightShiftBonus: FC<Props> = ({ index }) => {
  const { t } = useTranslation('web_ui')

  const valueFieldName = `premiumValuesInput[${index}].value`
  const unitFieldName = `premiumValuesInput[${index}].unit`

  const unitOptions = useMemo(() => {
    return [
      {
        label: t('position_form.step3.night_shift_bonus.units.percent'),
        value: PremiumUnitsEnum.PERCENT
      },
      {
        label: t('position_form.step3.night_shift_bonus.units.per_hour'),
        value: PremiumUnitsEnum.PER_HOUR
      }
    ]
  }, [t])

  const addonBefore = <Select name={unitFieldName} options={unitOptions} />

  return (
    <Form.Item
      name={valueFieldName}
      required
      label={t('position_form.step3.night_shift_bonus.value.label')}
      tooltip={t('position_form.step3.night_shift_bonus.value.tooltip')}
    >
      <InputNumber
        name={valueFieldName}
        addonBefore={addonBefore}
        addonAfter={t(
          'position_form.step3.night_shift_bonus.value.addon_after'
        )}
        autoComplete="off"
        min={0}
        placeholder={t(
          'position_form.step3.night_shift_bonus.value.placeholder'
        )}
        style={{ width: '100%' }}
      />
    </Form.Item>
  )
}
