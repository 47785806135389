import { FillCompanySurveyInput } from '@vivaldis/graphql'
import {
  Formik,
  FormikContextType,
  FormikHelpers,
  useFormikContext
} from 'formik'
import { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { CompanySurvey } from '../types/CompanySurvey'
import { getQuestionMetadata } from '../utils/getQuestionMetadata'
import { surveyFormValuesToFillCompanySurveyInput } from '../utils/surveyFormValuesToFillCompanySurveyInput'

export interface SurveyFormValues {
  [questionId: string]: {
    boolValue: boolean | null
    textValue: string | null
  }
}

export function useForm(): FormikContextType<SurveyFormValues> {
  return useFormikContext()
}

export interface EmployeeFormProviderProps {
  companySurvey: CompanySurvey
  onSubmit: (
    fillCompanySurveyInput: FillCompanySurveyInput,
    formikHelpers: FormikHelpers<SurveyFormValues>
  ) => Promise<any>
}

export const FormProvider: FC<PropsWithChildren<EmployeeFormProviderProps>> = ({
  companySurvey,
  onSubmit,
  children
}) => {
  const [t] = useTranslation('web_ui')

  const formInitialValues: SurveyFormValues = useMemo(() => {
    return companySurvey.questions.reduce<SurveyFormValues>(
      (values, question) => {
        values[question.id] = {
          boolValue: null,
          textValue: null
        }
        return values
      },
      {}
    )
  }, [companySurvey.questions])

  const ValidationSchema = useMemo(() => {
    const validationFields = companySurvey.questions.reduce<
      Record<string, any>
    >((validationRules, question) => {
      validationRules[question.id] = Yup.object().shape({
        boolValue: Yup.boolean()
          .label(t('survey_form.text.label'))
          .nullable()
          .when({
            is: (boolValue?: boolean | null) =>
              getQuestionMetadata(question, boolValue).boolValueRequired,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          }),
        textValue: Yup.string()
          .label(t('survey_form.bool.label'))
          .nullable()
          .when('boolValue', {
            is: (boolValue?: boolean | null) =>
              getQuestionMetadata(question, boolValue).textValueRequired,
            then: schema => schema.required(),
            otherwise: schema => schema.notRequired()
          })
      })

      return validationRules
    }, {})

    return Yup.object().shape({
      ...validationFields
    })
  }, [companySurvey.questions, t])

  const handleSubmit = useCallback(
    async (
      values: SurveyFormValues,
      formikHelpers: FormikHelpers<SurveyFormValues>
    ) => {
      const fillCompanySurveyInput = surveyFormValuesToFillCompanySurveyInput(
        values,
        companySurvey.id
      )
      await onSubmit(fillCompanySurveyInput, formikHelpers)
      formikHelpers.setSubmitting(false)
    },
    [companySurvey.id, onSubmit]
  )

  return (
    <Formik<SurveyFormValues>
      initialValues={formInitialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  )
}
