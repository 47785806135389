import { DatePicker as BaseDatePicker } from './DatePicker'
import { RangePicker } from './RangePicker'
export type { DatePickerProps } from './DatePicker'
export type { RangePickerProps } from './RangePicker'

// should we do it with hoist-non-react-statics ?
export const DatePicker = Object.assign(BaseDatePicker, {
  ...BaseDatePicker,
  RangePicker
})
