import { useEmployeeQuery } from './useEmployeeQuery'
import { useMemo, useRef } from 'react'
import { EmployeeQuery_employee } from '../queries/__generated__/EmployeeQuery'
import { isEqual } from 'lodash'
import { EmployeeFragment } from '@vivaldis/graphql'

export function useEmployee(): EmployeeFragment | undefined {
  const result = useEmployeeQuery()

  const prevEmployee = useRef<EmployeeQuery_employee | null>(
    result?.data?.employee ?? null
  )
  const employee = useMemo((): EmployeeQuery_employee | null => {
    if (result) {
      if (isEqual(result.data?.employee, prevEmployee.current)) {
        return prevEmployee.current
      }
      return result.data?.employee ?? null
    }
    return null
  }, [result])
  prevEmployee.current = employee

  return result?.data?.employee || undefined
}
