import infinite_listEN from '../InfiniteList/i18n/en.json'
import infinite_listFR from '../InfiniteList/i18n/fr.json'
import infinite_listNL from '../InfiniteList/i18n/nl.json'

import infinite_tableEN from '../InfiniteTable/i18n/en.json'
import infinite_tableFR from '../InfiniteTable/i18n/fr.json'
import infinite_tableNL from '../InfiniteTable/i18n/nl.json'

import location_pickerEN from '../LocationPicker/i18n/en.json'
import location_pickerFR from '../LocationPicker/i18n/fr.json'
import location_pickerNL from '../LocationPicker/i18n/nl.json'

import wage_inputEN from '../FormikAntd/WageInput/i18n/en.json'
import wage_inputFR from '../FormikAntd/WageInput/i18n/fr.json'
import wage_inputNL from '../FormikAntd/WageInput/i18n/nl.json'

export const resources = {
  en: {
    infinite_list: infinite_listEN,
    infinite_table: infinite_tableEN,
    location_picker: location_pickerEN,
    wage_input: wage_inputEN
  },
  fr: {
    infinite_list: infinite_listFR,
    infinite_table: infinite_tableFR,
    location_picker: location_pickerFR,
    wage_input: wage_inputFR
  },
  nl: {
    infinite_list: infinite_listNL,
    infinite_table: infinite_tableNL,
    location_picker: location_pickerNL,
    wage_input: wage_inputNL
  }
} as const
