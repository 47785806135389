import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const DeleteSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2186 5.48926C15.1634 5.35308 15.1202 5.21035 15.0905 5.06224L14.8475 3.84624C14.7965 3.66124 14.5855 3.50024 14.3455 3.50024H10.1125C9.87246 3.50024 9.66146 3.66124 9.60046 3.89224L9.36746 5.06224C9.33786 5.21035 9.29478 5.35308 9.23964 5.48926H15.2186ZM7.0129 5.48926C7.01442 5.48925 7.01594 5.48924 7.01746 5.48924C7.44346 5.48924 7.81346 5.18524 7.89746 4.76724L8.14046 3.55124C8.38746 2.61924 9.19446 2.00024 10.1125 2.00024H14.3455C15.2635 2.00024 16.0705 2.61924 16.3075 3.50624L16.5615 4.76724C16.6445 5.18524 17.0145 5.48924 17.4405 5.48924C17.442 5.48924 17.4435 5.48925 17.445 5.48926H20.708C21.122 5.48926 21.458 5.82526 21.458 6.23926C21.458 6.65326 21.122 6.98926 20.708 6.98926H3.75C3.336 6.98926 3 6.65326 3 6.23926C3 5.82526 3.336 5.48926 3.75 5.48926H7.0129ZM12.2466 22C10.8916 22 9.5706 21.985 8.2636 21.958C6.5916 21.925 5.4346 20.841 5.2456 19.129C4.9306 16.289 4.3916 9.59503 4.3866 9.52803C4.3526 9.11503 4.6606 8.75303 5.0736 8.72003C5.4806 8.70903 5.8486 8.99503 5.8816 9.40703C5.8866 9.47503 6.4246 16.146 6.7366 18.964C6.8436 19.937 7.3686 20.439 8.2946 20.458C10.7946 20.511 13.3456 20.514 16.0956 20.464C17.0796 20.445 17.6116 19.953 17.7216 18.957C18.0103 16.3554 18.4983 10.3774 18.569 9.51177L18.5776 9.40703C18.6106 8.99503 18.9756 8.70703 19.3846 8.72003C19.7976 8.75403 20.1056 9.11503 20.0726 9.52803L20.064 9.6324L20.064 9.6325C19.9933 10.4989 19.5032 16.5002 19.2126 19.122C19.0186 20.869 17.8646 21.932 16.1226 21.964C14.7896 21.987 13.5036 22 12.2466 22Z"
    />
  </svg>
)

export const Delete = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={DeleteSvg} {...props} ref={ref} />
)
