import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const AddUserSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.05997 7.318C6.05997 5.213 7.77297 3.5 9.87697 3.5C11.982 3.5 13.695 5.213 13.695 7.318C13.695 9.423 11.982 11.136 9.87697 11.136V11.886L9.84897 11.136C8.83297 11.132 7.87897 10.733 7.16297 10.013C6.44797 9.292 6.05597 8.337 6.05997 7.318ZM9.84597 12.636H9.87697C12.81 12.636 15.195 10.25 15.195 7.318C15.195 4.386 12.81 2 9.87697 2C6.94497 2 4.55997 4.386 4.55997 7.315C4.55497 8.731 5.10197 10.065 6.09997 11.07C7.09697 12.074 8.42697 12.631 9.84597 12.636ZM3.5 18.1151C3.5 16.6831 5.646 15.9561 9.877 15.9561C14.108 15.9561 16.254 16.6891 16.254 18.1371C16.254 19.5681 14.108 20.2951 9.877 20.2951C5.646 20.2951 3.5 19.5611 3.5 18.1151ZM2 18.1151C2 21.7951 7.929 21.7951 9.877 21.7951C13.259 21.7951 17.754 21.4161 17.754 18.1371C17.754 14.4561 11.825 14.4561 9.877 14.4561C6.495 14.4561 2 14.8351 2 18.1151ZM19.2041 13.4297C18.7901 13.4297 18.4541 13.0937 18.4541 12.6797V11.4238H17.16C16.746 11.4238 16.41 11.0878 16.41 10.6738C16.41 10.2598 16.746 9.92383 17.16 9.92383H18.4541V8.6687C18.4541 8.2547 18.7901 7.9187 19.2041 7.9187C19.6181 7.9187 19.9541 8.2547 19.9541 8.6687V9.92383H21.25C21.664 9.92383 22 10.2598 22 10.6738C22 11.0878 21.664 11.4238 21.25 11.4238H19.9541V12.6797C19.9541 13.0937 19.6181 13.4297 19.2041 13.4297Z"
    />
  </svg>
)

export const AddUser = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={AddUserSvg} {...props} ref={ref} />
)
