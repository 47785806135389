import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Input, Row, Select, Typography } from '@vivaldis/antd-ui'
import { localizedCountryNameSelector, useLocale } from '@vivaldis/common'
import { CountryType } from '../../../../types/CountryType'

interface Props {
  countries: CountryType[]
}

export const ContactInformation: FC<Props> = ({ countries }) => {
  const [t] = useTranslation('web_ui')

  const locale = useLocale()

  const countryOptions = useMemo(
    () =>
      countries
        .map(country => ({
          label: localizedCountryNameSelector(locale, country),
          value: country.id
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [countries, locale]
  )

  return (
    <>
      <Typography.Title level={5} marginBottom={16}>
        {t('edit_employee_profile_form.contact_section_header')}
      </Typography.Title>
      <Form.Item
        name="mobile"
        required
        label={t('edit_employee_profile_form.mobile.label')}
      >
        <Input
          name="mobile"
          placeholder={t('form.required')}
          autoComplete="off"
        />
      </Form.Item>

      <Form.Item
        name="email"
        required
        label={t('edit_employee_profile_form.email.label')}
      >
        <Input
          name="email"
          placeholder={t('form.required')}
          autoComplete="off"
        />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="street"
            required
            label={t('edit_employee_profile_form.street.label')}
          >
            <Input
              name="street"
              placeholder={t('form.required')}
              autoComplete="chrome-off-employee-street"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="streetNumber"
            required
            label={t('edit_employee_profile_form.street_number.label')}
          >
            <Input
              name="streetNumber"
              placeholder={t('form.required')}
              autoComplete="chrome-off-employee-street-number"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="buildingNumber"
            label={t('edit_employee_profile_form.building_number.label')}
          >
            <Input
              name="buildingNumber"
              placeholder={t('form.optional')}
              autoComplete="off"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={9}>
          <Form.Item
            name="countryId"
            required
            label={t('edit_employee_profile_form.country_id.label')}
          >
            <Select
              name="countryId"
              placeholder={t('form.required')}
              options={countryOptions}
              showSearch
              autoComplete="chrome-off-employee-country"
            />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            name="city"
            label={t('edit_employee_profile_form.city.label')}
            required
          >
            <Input
              name="city"
              placeholder={t('form.required')}
              autoComplete="chrome-off-employee-city"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="zip"
            label={t('edit_employee_profile_form.zip.label')}
            required
          >
            <Input
              name="zip"
              placeholder={t('form.required')}
              autoComplete="chrome-off-employee-zip"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
