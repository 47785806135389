import { FC, useRef, Suspense, lazy, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'
import { createElement } from '@vivaldis/common'
import { ZIndex } from '../styles/zIndex'
import { useIsDrawerOpen, useDrawer } from './providers/DrawerProvider'
import { HeaderBar } from './components/HeaderBar'
import { FooterBar } from './components/FooterBar'
import { DrawerProps } from './typings/DrawerProps'

const MobileGestureHandler = lazy(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () =>
    import(
      /* webpackChunkName: "MobileGestureHandler" */ './components/MobileGestureHandler'
    )
)

const DrawerBase: FC<PropsWithChildren<DrawerProps>> = ({
  header = HeaderBar,
  footer,
  children
}) => {
  const open = useIsDrawerOpen()
  const { close } = useDrawer()

  const [props] = useSpring(
    () => ({
      transform: open ? 'translateX(0%)' : 'translateX(-100%)',
      opacity: open ? 1 : 0,
      config: {
        mass: 1,
        friction: 100,
        tension: 1800
      }
    }),
    [open]
  )

  const ref = useRef()

  return (
    <Container style={{ transform: props.transform }} ref={ref as any}>
      <Shadow style={{ opacity: props.opacity }} />
      <Wrapper>
        {createElement(header)}
        <ContentContainer>{children}</ContentContainer>
        {createElement(footer)}
      </Wrapper>
      <Suspense fallback={null}>
        <MobileGestureHandler containerRef={ref} close={close} />
      </Suspense>
    </Container>
  )
}

export const Drawer = Object.assign(DrawerBase, {
  HeaderBar: HeaderBar,
  FooterBar: FooterBar
})

const Container = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.white};
  transform: translateX(-50%);
  z-index: ${ZIndex.DrawerMenu};
  display: flex;
  flex-direction: column;

  &:before {
    content: '';
    position: absolute;
    top: -1000px;
    height: 1000px;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.white};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -1000px;
    height: 1000px;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.white};
  }
`

const Wrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
`

const ContentContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Shadow = styled(animated.div)`
  background: ${({ theme }) => theme.white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow:
    0 0px 60px 0 rgba(0, 0, 0, 0.8),
    0 0px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: -1;
`
