import { gql } from '@apollo/client'

export const countryFragment = gql`
  fragment CountryFragment on Country {
    eeaMember
    id
    isoCode
    nameEn
    nameFr
    nameNl
    nationality(locale: $locale)
  }
`
