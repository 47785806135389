import { gql } from '@apollo/client'
import { educationSubjectFragment } from './EducationSubjectFragment'

export const employeeEducationFragment = gql`
  ${educationSubjectFragment}

  fragment EmployeeEducationFragment on EmployeeEducation {
    educationSubject {
      ...EducationSubjectFragment
    }
    graduationYear
    id
    institute
  }
`
