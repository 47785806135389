import { HttpLink } from '@apollo/client'
import { UserType } from '../../typings/UserType'

export const httpLink = (
  apiUrl: string,
  appVersion: string,
  userType: UserType
) => {
  let appVersionHeaders = {}
  switch (userType) {
    case UserType.Employee:
      appVersionHeaders = {
        'SJ-Mobile-Version': appVersion
      }
      break
    case UserType.User:
      appVersionHeaders = {
        'SJ-User-Version': appVersion
      }
      break
    case UserType.SuperUser:
      appVersionHeaders = {
        'SJ-User-User-Version': appVersion
      }
      break
  }

  return new HttpLink({
    uri: `${apiUrl}graphql`,
    headers: {
      ...appVersionHeaders
    }
  })
}
