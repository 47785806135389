import { EditEmployeeProfileFormValues } from '../provider/FormProvider'
import { Employee } from '../types/Employee'

export const employeeToEmployeeFormValues = (
  employee: Employee
): EditEmployeeProfileFormValues => {
  return {
    email: employee.email,
    firstName: employee.firstName || '',
    lastName: employee.lastName || '',
    ssn: employee.ssn || '',
    gender: employee.gender,
    mobile: employee.mobile,
    isStudent: employee.isStudent,

    // -> profile fields
    birthdate: employee.profile?.birthdate
      ? new Date(employee.profile?.birthdate)
      : null,
    placeOfBirth: employee.profile?.placeOfBirth || null,
    street: employee.profile?.street || null,
    streetNumber: employee.profile?.streetNumber || null,
    buildingNumber: employee.profile?.buildingNumber || null,
    zip: employee.profile?.zip || null,
    city: employee.profile?.city || null,
    countryId: employee.profile?.country?.id || null,
    countryOfBirthId: employee.profile?.countryOfBirth?.id || null,
    nationalityCountryId: employee.profile?.nationalityCountry?.id || null,
    idCardNumber: employee.profile?.idCardNumber || null,
    idCardExpiresOn: employee.profile?.idCardExpiresOn
      ? new Date(employee.profile?.idCardExpiresOn)
      : null,
    idCardFrontPicture: employee.profile?.idCardFrontPicture || null,
    idCardBackPicture: employee.profile?.idCardBackPicture || null,

    // -> workProfile fields

    bankAccount: employee.workProfile?.bankAccount || null,
    bic: employee.workProfile?.bic || null,
    workPermitExpiresOn: employee.workProfile?.workPermitExpiresOn
      ? new Date(employee.workProfile?.workPermitExpiresOn)
      : null,
    residencePermitExpiresOn: employee.workProfile?.residencePermitExpiresOn
      ? new Date(employee.workProfile?.residencePermitExpiresOn)
      : null,
    residencePermitCode: employee.workProfile?.residencePermitCode || null,
    taxCode: employee.workProfile?.taxCode || null,
    maritalState: employee.workProfile?.maritalState || null,
    maritalDate: employee.workProfile?.maritalDate
      ? new Date(employee.workProfile?.maritalDate)
      : null,
    childrenNumber: employee.workProfile?.childrenNumber || null,
    childrenWithDisabilitiesNumber:
      employee.workProfile?.childrenWithDisabilitiesNumber || null,
    dependentPersonsNumber:
      employee.workProfile?.dependentPersonsNumber || null,
    dependentPersonsWithDisabilitiesNumber:
      employee.workProfile?.dependentPersonsWithDisabilitiesNumber || null,
    involuntaryPartTime: employee.workProfile?.involuntaryPartTime || null,
    workPermitFrontPicture:
      employee.workProfile?.workPermitFrontPicture || null,
    workPermitBackPicture: employee.workProfile?.workPermitBackPicture || null,
    residencePermitFrontPicture:
      employee.workProfile?.residencePermitFrontPicture || null,
    residencePermitBackPicture:
      employee.workProfile?.residencePermitBackPicture || null
  }
}
