import { useField } from 'formik'
import { ConfigProvider } from 'antd'
import { FC, useCallback, useMemo } from 'react'
import { InputNumber, InputNumberProps } from '../../InputNumber'
import type { FormikFieldProps } from '../typings/FieldProps'
import { numberValueToVpassPercentageValue } from './utils/numberValueToVpassPercentageValue'

// We want to have next:
// min = 0
// max = 9999
// only integers allowed

// VPASS format is: '100,00 %', '0,12 %', '0,00 %', '299,99 %', '040,00 %'

export type VPASSPercentagePickerProps = FormikFieldProps & InputNumberProps

export const VPASSPercentagePicker: FC<VPASSPercentagePickerProps> = ({
  name,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  value: _valueProp,
  ...restProps
}) => {
  const [field, , helpers] = useField(name)
  const { value, onBlur } = field
  const { setValue } = helpers

  const handleBlur = useCallback(
    (event: any) => {
      onBlur(event)
      onBlurProp?.(event)
    },
    [onBlur, onBlurProp]
  )

  const handleChange = useCallback(
    (value: string | number | null) => {
      const stringValue = numberValueToVpassPercentageValue(value)
      setValue(stringValue)
    },
    [setValue]
  )

  const numberValue = useMemo(() => {
    return value
      ? Number(String(value).replace(',', '.').replace('%', '').trim())
      : undefined
  }, [value])

  return (
    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            paddingSM: 8 // to make addonAfter (%) look smaller to fit good on mobile
          }
        }
      }}
    >
      <InputNumber
        name={name}
        value={numberValue}
        min={0}
        max={9999}
        step={1}
        onBlur={handleBlur}
        onChange={handleChange}
        controls={false}
        type="number"
        inputMode="numeric"
        addonAfter="%"
        placeholder="100 %"
        {...restProps}
      />
    </ConfigProvider>
  )
}
