import {
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  PropsWithChildren
} from 'react'

export const IsDrawerOpenContext = createContext<boolean | undefined>(false)

export interface DrawerContextValue {
  open: () => void
  close: () => void
}

export const DrawerContext = createContext<DrawerContextValue | undefined>(
  undefined
)

export function useIsDrawerOpen(): boolean {
  return useContext(IsDrawerOpenContext)!
}

export function useDrawer(): DrawerContextValue {
  return useContext(DrawerContext)!
}

export const DrawerProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  const context = useMemo(() => ({ open, close }), [open, close])

  return (
    <DrawerContext.Provider value={context}>
      <IsDrawerOpenContext.Provider value={isOpen}>
        {children}
      </IsDrawerOpenContext.Provider>
    </DrawerContext.Provider>
  )
}
