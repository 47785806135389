import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const EditSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.895 7.78775L3.69564 16.7918C3.52464 17.0058 3.46164 17.2818 3.52464 17.5468L4.20564 20.4318L7.24464 20.3938C7.53364 20.3908 7.80064 20.2618 7.97764 20.0418C9.9553 17.5674 13.035 13.714 15.1548 11.0599L10.895 7.78775ZM11.8319 6.61609L16.0915 9.88681C16.912 8.85915 17.4518 8.18228 17.5016 8.11776C17.6656 7.85176 17.7296 7.47576 17.6436 7.11376C17.5556 6.74276 17.3246 6.42776 16.9916 6.22676C16.9206 6.17776 15.2356 4.86976 15.1836 4.82876C14.5496 4.32076 13.6246 4.40876 13.1106 5.01676L11.8319 6.61609ZM16.869 11.3173C18.7003 9.02289 18.7168 8.99699 18.7346 8.96876C19.1516 8.30776 19.2816 7.52576 19.1026 6.76776C18.9226 6.00176 18.4476 5.35476 17.8396 4.99276C17.8396 4.99276 16.1666 3.69276 16.1166 3.65376C14.8566 2.64276 12.9886 2.82476 11.9556 4.05976C11.9536 4.06176 11.9519 4.06401 11.9501 4.06626C11.9484 4.06851 11.9466 4.07076 11.9446 4.07276L2.52464 15.8548C2.06364 16.4308 1.89564 17.1728 2.06464 17.8918L2.88364 21.3628C2.96464 21.7018 3.26664 21.9398 3.61364 21.9398H3.62364L7.26264 21.8938C8.00164 21.8848 8.68964 21.5518 9.14864 20.9798C13.0622 16.0837 15.3728 13.1916 16.7392 11.4798C16.7675 11.453 16.794 11.4236 16.8184 11.3917C16.837 11.3676 16.8538 11.3428 16.869 11.3173ZM20.7508 21.9397H13.4978C13.0838 21.9397 12.7478 21.6037 12.7478 21.1897C12.7478 20.7757 13.0838 20.4397 13.4978 20.4397H20.7508C21.1648 20.4397 21.5008 20.7757 21.5008 21.1897C21.5008 21.6037 21.1648 21.9397 20.7508 21.9397Z"
    />
  </svg>
)

export const Edit = forwardRef<HTMLSpanElement, AntdIconProps>((props, ref) => (
  <Icon component={EditSvg} {...props} ref={ref} />
))
