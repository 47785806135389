import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const PaperUploadSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7312 7.48424L14.726 4.35317V5.65924C14.726 6.66324 15.542 7.48124 16.546 7.48424H17.7312ZM13.8446 2.01172H7.58831H7.57031C5.10831 2.01172 3.06031 4.00972 3.00131 6.49072V17.2117C2.94731 19.7237 4.94731 21.8117 7.45931 21.8657H7.58531H15.5993C18.1123 21.7757 20.0683 19.7367 20.0523 17.2277V8.03772C20.0523 7.84472 19.9773 7.65772 19.8443 7.51872L14.7783 2.24172C14.6363 2.09472 14.4413 2.01172 14.2373 2.01172H14.1073C14.0646 2.00418 14.0207 2.00024 13.976 2.00024C13.9312 2.00024 13.8873 2.00418 13.8446 2.01172ZM13.226 3.51172V5.65924C13.226 7.48724 14.713 8.97924 16.544 8.98424H18.5523V17.2327C18.5633 18.9287 17.2423 20.3057 15.5733 20.3657H7.49031C5.80531 20.3287 4.46531 18.9287 4.50131 17.2277V6.50872C4.54031 4.85372 5.91631 3.51172 7.57331 3.51172H7.58531H13.226ZM11.1417 16.7002C10.7277 16.7002 10.3917 16.3642 10.3917 15.9502V11.7252L9.32799 12.7936C9.18199 12.9416 8.98899 13.0146 8.79699 13.0146C8.60599 13.0146 8.41399 12.9416 8.26799 12.7956C7.97499 12.5036 7.97299 12.0296 8.26599 11.7356L10.61 9.37961C10.6304 9.35914 10.6519 9.34016 10.6744 9.32266C10.8026 9.22035 10.9651 9.15918 11.1417 9.15918C11.3242 9.15918 11.4916 9.22447 11.6216 9.33295C11.6394 9.34754 11.6566 9.36309 11.673 9.37961L14.019 11.7356C14.311 12.0296 14.31 12.5036 14.017 12.7956C13.723 13.0876 13.248 13.0876 12.956 12.7936L11.8917 11.7246V15.9502C11.8917 16.3642 11.5557 16.7002 11.1417 16.7002Z"
    />
  </svg>
)

export const PaperUpload = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={PaperUploadSvg} {...props} ref={ref} />
)
