import { useUser } from '@vivaldis/session'
import { useMemo } from 'react'

export const useUserHasInterimWebAccess = (): boolean => {
  const user = useUser()
  return useMemo(() => {
    const permission = user?.permissions.find(
      permission => permission.companyId === user?.company?.id
    )

    const userHasHoursValidationPermissionForInterim =
      permission?.interim4dPermissions?.permissions?.some(
        permission => permission === 'hours_validation'
      ) || false

    return userHasHoursValidationPermissionForInterim
  }, [user?.company?.id, user?.permissions])
}
