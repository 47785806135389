import styled from '@emotion/styled'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Result } from '@vivaldis/antd-ui'

export interface ErrorStateProps {
  message: string
  onTryAgain?: () => void
}

export const ErrorState: FC<ErrorStateProps> = ({ message, onTryAgain }) => {
  const { t } = useTranslation('web_ui')

  const [refreshing, setRefreshing] = useState(false)

  const handleOnTryAgainButtonPress = useCallback(async () => {
    try {
      setRefreshing(true)
      await onTryAgain?.()
    } catch (e) {
    } finally {
      setRefreshing(false)
    }
  }, [onTryAgain])

  return (
    <Container>
      <Result
        status="error"
        subTitle={message}
        extra={
          onTryAgain ? (
            <Button
              onClick={handleOnTryAgainButtonPress}
              type="primary"
              loading={refreshing}
            >
              {t('screen.try_again')}
            </Button>
          ) : null
        }
      />
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
