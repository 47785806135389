import { Form } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { ActionBar } from './components/ActionBar'
import { FormContent } from './components/FormContent'
import { FormProvider, WorkPostSheetFormValues } from './providers/FormProvider'
import { PositionWithWPS, ViewWorkPostSheetQuery_workPostSheet } from './types'

interface WorkPostSheetFormProps {
  onSubmit: (values: WorkPostSheetFormValues) => Promise<any>
  workPostSheet?: ViewWorkPostSheetQuery_workPostSheet | null
  positionsWithWPS?: PositionWithWPS[]
  buttonText: string
  disabledEditing?: boolean
}

export const WorkPostSheetForm: FC<WorkPostSheetFormProps> = ({
  onSubmit,
  workPostSheet,
  positionsWithWPS,
  buttonText,
  disabledEditing = false
}) => {
  return (
    <FormProvider workPostSheet={workPostSheet} onSubmit={onSubmit}>
      <Form name="wps-form">
        <FormContent
          disabledEditing={disabledEditing}
          positionsWithWPS={positionsWithWPS}
        />
        {disabledEditing ? null : <ActionBar buttonText={buttonText} />}
      </Form>
    </FormProvider>
  )
}
