import { addOnErrorHandler } from '../../tracking/trackError'
import { addOnEventHandler } from '../../tracking/trackEvent'
import { addOnInfoHandler } from '../../tracking/trackInfo'
import { addOnScreenHandler } from '../../tracking/trackScreen'
import { addOnUserHandler, UserUser } from '../../tracking/trackUser'

import type { BugsnagClient } from '../types/BugsnagClient'

export function initBugsnagTracking(bugsnagClient: BugsnagClient) {
  addOnErrorHandler((error, params) => {
    bugsnagClient.notify(error, params?.onBugsnagErrorCallback)
  })
  addOnEventHandler((_key, name, data) => {
    bugsnagClient.leaveBreadcrumb(name.substring(0, 30), data)
  })
  addOnInfoHandler((name, data, extraData) => {
    bugsnagClient.leaveBreadcrumb(name.substring(0, 30), data, extraData)
  })
  addOnScreenHandler((name, data) => {
    bugsnagClient.leaveBreadcrumb(name, data, 'navigation')
  })
  addOnUserHandler((user: UserUser) => {
    bugsnagClient.setUser(
      user.id || undefined,
      user.email || undefined,
      user.name || undefined
    )
    if (user.companyId) {
      bugsnagClient.addMetadata('user', 'companyId', user.companyId)
    }
    if (user.companyName) {
      bugsnagClient.addMetadata('user', 'companyName', user.companyName)
    }
  })
}
