import {
  SendMissingContractInformationPushNotificationMutation,
  SendMissingContractInformationPushNotificationMutationVariables
} from './__generated__/SendMissingContractInformationPushNotificationMutation'
import { ApolloClient, MutationOptions, gql } from '@apollo/client'

const mutation = gql`
  mutation SendMissingContractInformationPushNotificationMutation(
    $input: SendMissingContractInformationPushNotificationInput!
  ) {
    sendMissingContractInformationPushNotification(input: $input) {
      status
    }
  }
`

export function sendMissingContractInformationPushNotificationMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<
      SendMissingContractInformationPushNotificationMutation,
      SendMissingContractInformationPushNotificationMutationVariables
    >,
    'mutation'
  >
) {
  return apolloClient.mutate<
    SendMissingContractInformationPushNotificationMutation,
    SendMissingContractInformationPushNotificationMutationVariables
  >({
    mutation,
    ...options
  })
}
