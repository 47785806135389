import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import SearchOutlined from '@ant-design/icons/SearchOutlined'
import { FC } from 'react'

export interface SelectSuffixIconProps {
  loading: boolean
}

export const SelectSuffixIcon: FC<SelectSuffixIconProps> = ({ loading }) => {
  if (loading) {
    return <LoadingOutlined spin />
  }
  return <SearchOutlined />
}
