import { AntdConfigProvider, Steps } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useOnboardingStep } from '../../../../../../providers/OnboardingStepProvider'

export const OnboardingNavigationSteps: FC = () => {
  const { t } = useTranslation('navigation_bar')

  const { currentStep } = useOnboardingStep()

  return (
    <AntdConfigProvider
      theme={{
        components: {
          Steps: {
            descriptionMaxWidth: 180
          }
        }
      }}
    >
      <Steps
        current={currentStep}
        progressDot
        items={[
          {
            title: t('onboarding_items.company')
          },
          {
            title: t('onboarding_items.worker_contract_type')
          },
          {
            title: t('onboarding_items.clerk_contract_type')
          },
          {
            title: t('onboarding_items.overview')
          }
        ]}
      />
    </AntdConfigProvider>
  )
}
