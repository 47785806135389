let enabled = false

export function enableApolloLogger() {
  enabled = true
}

export function disableApolloLogger() {
  enabled = false
}

export function isApolloLoggerEnabled() {
  return enabled
}
