import { FC } from 'react'
import { EmployeeCertificates } from './components/EmployeeCertificates'
import { Contact } from './components/Contact'
import { Documents } from './components/Documents'
import { Educations } from './components/Educations'
import { GeneralInformation } from './components/GeneralInformation'
import { WageProfile } from './components/WageProfile'
import {
  EmployeeProfileQuery_employee,
  EmployeeProfileQuery_employee_employeeCertificates as EmployeeCertificate
} from './typings/EmployeeProfileQuery'

export interface EmployeeProfileProps {
  employee: EmployeeProfileQuery_employee
  onDeleteEmployeeCertificate?: (
    certificate: EmployeeCertificate
  ) => Promise<void>
  onRefreshRemainingHours?: () => Promise<void>
}

export const EmployeeProfile: FC<EmployeeProfileProps> = ({
  employee,
  onDeleteEmployeeCertificate,
  onRefreshRemainingHours
}) => {
  return (
    <>
      <GeneralInformation
        employee={employee}
        onRefreshRemainingHours={onRefreshRemainingHours}
      />
      <Contact employee={employee} />
      <Documents employee={employee} />
      <WageProfile employee={employee} />
      <Educations employee={employee} />
      <EmployeeCertificates
        employee={employee}
        onDeleteEmployeeCertificate={onDeleteEmployeeCertificate}
      />
    </>
  )
}
