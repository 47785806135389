import { ReducersMapObject } from 'redux'
import { AppAction, AppActionType } from '../actions'
import { AppState as RNAppState } from 'react-native'
import { State } from '../typings/State'

export const APP_STATE_KEY = '@app'

export interface AppState {
  ready: boolean
  dataReady: boolean
  uiReady: boolean
  navigationReady: boolean
  state: State
}

const initialState: AppState = {
  ready: false,
  dataReady: false,
  uiReady: false,
  navigationReady: false,
  state: RNAppState.currentState as State
}

export function createAppReducer(
  reducers: ReducersMapObject<any, any>
): ReducersMapObject<any, any> {
  return {
    ...reducers,
    [APP_STATE_KEY]: (state: AppState = initialState, action: AppAction) => {
      switch (action.type) {
        case AppActionType.ReduxPersistRehydrated:
          return {
            ...state,
            dataReady: true
          }

        case AppActionType.InitDataComplete:
          return {
            ...state,
            dataReady: true
          }

        case AppActionType.InitUIComplete:
          return {
            ...state,
            uiReady: true
          }

        case AppActionType.InitNavigationComplete:
          return {
            ...state,
            navigationReady: true
          }

        case AppActionType.InitComplete:
          return {
            ...state,
            ready: true
          }

        case AppActionType.StateChange:
          return {
            ...state,
            state: action.payload.state
          }

        default:
          return state
      }
    }
  }
}
