import { relayStylePagination } from './relayStylePagination'
import type { TypedTypePolicies } from '../typings/apollo-type-policies'

export const typePolicies: TypedTypePolicies = {
  Committee: {
    // in response of `selectionOffers` query we have "-1" as ID for each record
    keyFields: false
  },
  Employee: {
    fields: {
      // profile doesn't have uniq ID, but it always the same for employee
      profile: {
        // Short for options.mergeObjects(existing, incoming).
        merge: true
      },
      // workProfile doesn't have uniq ID, but it always the same for employee
      workProfile: {
        // Short for options.mergeObjects(existing, incoming).
        merge: true
      }
    }
  },
  FourDPermission: {
    // FourDPermission has an ID field, but it's not uniq - it's actually a 4D company ID, and it's not uniq.
    // example: multiple users can have permission with the same ID, witch is actually 4D company ID
    keyFields: false
  },
  FunctionCode: {
    // in response of `selectionOffers` query we have "-1" as ID for each record
    keyFields: false
  },

  Query: {
    fields: {
      companySurveys: relayStylePagination({}),
      suCompanies: relayStylePagination({}),
      suEmployees: relayStylePagination({}),
      users: relayStylePagination({}),
      contracts: relayStylePagination({}),
      employeeSurveys: relayStylePagination({}),
      positions: relayStylePagination({}),
      roomsOverview: relayStylePagination({}),
      team: relayStylePagination({
        uniqByValueIteratee: (edge, readField) => {
          //node.employee.id
          return readField('id', readField('employee', readField('node', edge)))
        }
      })
    }
  }
}
