import styled from '@emotion/styled'
import { Menu as AntdMenu } from 'antd'
import hoistNonReactStatics from 'hoist-non-react-statics'
export type { MenuProps, MenuTheme, SubMenuProps, MenuItemProps } from 'antd'

const StyledAntdMenu = styled(AntdMenu)`
  & .ant-menu-title-content {
    font-weight: 600;
  }

  & .ant-menu-item,
  .ant-menu-submenu {
    .ant-badge-count {
      line-height: 20px;
    }
  }
`

export const Menu = hoistNonReactStatics(StyledAntdMenu, AntdMenu)
