import {
  ComponentType,
  createElement as baseCreateElement,
  isValidElement,
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode
} from 'react'

export function createElement<C extends ComponentType<any> | ReactNode>(
  Component?: C,
  props?: C extends JSXElementConstructor<infer P>
    ? P & { key?: Key | null; ref?: any }
    : any,
  ...children: ReactNode[]
): JSX.Element {
  if (Component && isValidElement(Component)) {
    return Component as ReactElement<any>
  } else if (Component) {
    return baseCreateElement(
      Component as ComponentType<typeof props> | any,
      props,
      ...children
    )
  }
  return null as any
}
