import { Routes, Route, Navigate } from 'react-router-dom'
import { Suspense, FC, useMemo } from 'react'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'
import { ProtectedRoute } from '../../components/ProtectedRoute'
import { useCompanyHasPayrollAccess } from '../../hooks/useCompanyHasPayrollAccess'
import { useCompanyHasSelectionAccess } from '../../hooks/useCompanyHasSelectionAccess'
import { useUserConstructFourDCompanyId } from '../../hooks/useUserConstructFourDCompanyId'
import { useUserHasConstructionExtendContractAccess } from '../../hooks/useUserHasConstructionExtendContractAccess'
import { useUserHasConstructionWebAccess } from '../../hooks/useUserHasConstructionWebAccess'
import { useUserHasInterimExtendContractAccess } from '../../hooks/useUserHasInterimExtendContractAccess'
import { useUserHasInterimWebAccess } from '../../hooks/useUserHasInterimWebAccess'
import { useUserHasWebAccess } from '../../hooks/useUserHasWebAccess'
import { useUserInterimFourDCompanyId } from '../../hooks/useUserInterimFourDCompanyId'
import { lazyOrReloadPage } from '../../utils/lazyOrReloadPage'

const CreateContract = lazyOrReloadPage(
  () =>
    import(/* webpackChunkName: "CreateContract" */ './screens/CreateContract')
)
const CreateWorkObstruction = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "CreateWorkObstruction" */ './screens/CreateWorkObstruction'
    )
)
const PayrollContractList = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "PayrollContractList" */ './screens/PayrollContractList'
    )
)
const RequestContractExtension = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "RequestContractExtension" */ './screens/RequestSelectionContractExtension'
    )
)
const RequestWorkdayCancellation = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "RequestWorkdayCancellation" */ './screens/RequestWorkdayCancellation'
    )
)
const SelectionContractList = lazyOrReloadPage(
  () =>
    import(
      /* webpackChunkName: "SelectionContractList" */ './screens/SelectionContractList'
    )
)
const ViewContract = lazyOrReloadPage(
  () => import(/* webpackChunkName: "ViewContract" */ './screens/ViewContract')
)

export const ContractsRoutes: FC = () => {
  const companyHasPayrollAccess = useCompanyHasPayrollAccess()
  const companyHasSelectionAccess = useCompanyHasSelectionAccess()
  const userHasWebAccess = useUserHasWebAccess()
  const userHasConstructionWebAccess = useUserHasConstructionWebAccess()
  const userHasInterimWebAccess = useUserHasInterimWebAccess()
  const userHasConstructionExtendContractAccess =
    useUserHasConstructionExtendContractAccess()
  const userHasInterimExtendContractAccess =
    useUserHasInterimExtendContractAccess()

  const construct4dCompanyId = useUserConstructFourDCompanyId()
  const interim4dCompanyId = useUserInterimFourDCompanyId()

  const userHasSelectionContractsAccess = useMemo(() => {
    return companyHasSelectionAccess && userHasWebAccess
  }, [companyHasSelectionAccess, userHasWebAccess])

  const userHasConstructAndInterimAccess = useMemo(
    () => userHasConstructionWebAccess && userHasInterimWebAccess,
    [userHasConstructionWebAccess, userHasInterimWebAccess]
  )

  return (
    <Suspense fallback={ProgressBarSuspenseFallback}>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={
                companyHasPayrollAccess
                  ? 'payroll-contracts'
                  : 'selection-contracts'
              }
              replace
            />
          }
        />

        <Route
          path="/payroll-contracts"
          element={
            <ProtectedRoute isAllowed={companyHasPayrollAccess}>
              <PayrollContractList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/selection-contracts/*"
          element={
            <ProtectedRoute isAllowed={userHasSelectionContractsAccess}>
              <Routes>
                {userHasConstructAndInterimAccess ? (
                  <Route
                    path="/"
                    element={
                      <Navigate
                        to={userHasInterimWebAccess ? 'interim' : 'construct'}
                        replace
                      />
                    }
                  />
                ) : (
                  <Route
                    path="/"
                    element={
                      <SelectionContractList
                        fourDCompanyId={
                          construct4dCompanyId || interim4dCompanyId
                        }
                        isContractsExtendingAllowed={
                          userHasConstructionExtendContractAccess ||
                          userHasInterimExtendContractAccess
                        }
                      />
                    }
                  />
                )}

                <Route
                  path="/interim"
                  element={
                    <ProtectedRoute isAllowed={userHasInterimWebAccess}>
                      <SelectionContractList
                        fourDCompanyId={interim4dCompanyId}
                        isContractsExtendingAllowed={
                          userHasInterimExtendContractAccess
                        }
                      />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/construct"
                  element={
                    <ProtectedRoute isAllowed={userHasConstructionWebAccess}>
                      <SelectionContractList
                        fourDCompanyId={construct4dCompanyId}
                        isContractsExtendingAllowed={
                          userHasConstructionExtendContractAccess
                        }
                      />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </ProtectedRoute>
          }
        />

        <Route path="/create-contract" element={<CreateContract />} />
        <Route path="/view-contract/:id" element={<ViewContract />} />
        <Route
          path="/request-workday-cancellation/:id"
          element={<RequestWorkdayCancellation />}
        />
        <Route
          path="/request-contract-extension"
          element={<RequestContractExtension />}
        />
        <Route
          path="/create-work-obstruction/:id"
          element={<CreateWorkObstruction />}
        />
      </Routes>
    </Suspense>
  )
}
