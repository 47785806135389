import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  FC,
  PropsWithChildren
} from 'react'
import { IntercomProvider } from 'react-use-intercom'

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || ''

export const SetIsIntercomMessengerVisibleContext = createContext<
  Dispatch<SetStateAction<boolean>> | undefined
>(undefined)

export function useSetIsIntercomMessengerVisible(): Dispatch<
  SetStateAction<boolean>
> {
  return useContext(SetIsIntercomMessengerVisibleContext)!
}

export const IsIntercomMessengerVisibleContext = createContext<boolean>(false)

export function useIsIntercomMessengerVisible(): boolean {
  return useContext(IsIntercomMessengerVisibleContext)!
}

export const IntercomMessengerProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const [visible, setVisible] = useState(true)

  return (
    <SetIsIntercomMessengerVisibleContext.Provider value={setVisible}>
      <IsIntercomMessengerVisibleContext.Provider value={visible}>
        <IntercomProvider appId={INTERCOM_APP_ID}>{children}</IntercomProvider>
      </IsIntercomMessengerVisibleContext.Provider>
    </SetIsIntercomMessengerVisibleContext.Provider>
  )
}
