import { eventChannel } from 'redux-saga'
import { AppState, AppStateStatus } from 'react-native'
import { stateChange } from '../actions'

export function appStateChannel() {
  return eventChannel(emit => {
    const handler = (state: AppStateStatus) => emit(stateChange(state as any))
    const appStateChangeSubscription = AppState.addEventListener(
      'change',
      handler
    )

    return () => {
      appStateChangeSubscription.remove()
    }
  })
}
