import { Button } from '@vivaldis/antd-ui'
import { useTheme } from '@vivaldis/ui'
import { FC, PropsWithChildren, useCallback } from 'react'
import styled from '@emotion/styled'
import { Logout, Settings } from '@vivaldis/icons'
import { useTranslation } from 'react-i18next'
import { useDrawer } from '../../providers/DrawerProvider'
import { DrawerFooterBarProps } from '../../typings/DrawerFooterBarProps'
import { Divider } from '../../../Divider'
import { Company } from './Company'

export const FooterBarBase: FC<PropsWithChildren<DrawerFooterBarProps>> = ({
  children,
  onLogoutPress
}) => {
  const { t } = useTranslation('web_ui')
  const theme = useTheme()
  const { close } = useDrawer()

  const onPress = useCallback(() => {
    onLogoutPress()
    setTimeout(() => close(), 0)
  }, [close, onLogoutPress])

  return (
    <Container>
      <Wrapper>
        <ContentContainer>{children}</ContentContainer>
        <Button
          icon={<Logout style={{ fontSize: 26, color: theme.primaryColor }} />}
          type="text"
          onClick={onPress}
          title={t('drawer.logout')}
        />
        <Divider type="vertical" />
        <Button
          icon={
            <Settings style={{ fontSize: 26, color: theme.primaryColor }} />
          }
          type="text"
          to="/settings"
          onClick={close}
          title={t('drawer.settings')}
        />
      </Wrapper>
    </Container>
  )
}

const Container = styled('div')`
  margin-left: 20px;
  margin-right: 20px;
  min-height: 72px;
  flex-shrink: 0;
  border-top: 1px solid rgba(0, 178, 135, 0.5);
`

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
`

const ContentContainer = styled('div')`
  flex: 1;
  overflow: hidden;
  margin-right: 16px;
`

export const FooterBar = Object.assign(FooterBarBase, { Company: Company })
