import { FontWeight } from '../typings/FontWeight'

export const fontWeight = (fontWeight?: FontWeight) => {
  switch (fontWeight) {
    case FontWeight.Light:
      return `
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
      `
    case FontWeight.Regular:
      return `
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
      `
    case FontWeight.Semibold:
      return `
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
      `
    case FontWeight.Bold:
      return `
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
      `
  }
  return ''
}
