import { forwardRef } from 'react'
import { Typography as AntdTypography } from 'antd'
import type { LinkProps as AntdLinkProps } from 'antd/lib/typography/Link'
import { makeBaseTextComponent } from './BaseText'
import type { BaseTextProps } from './BaseText'

export interface LinkProps extends AntdLinkProps, BaseTextProps {}

export const Link = forwardRef<HTMLElement, LinkProps>(({ ...props }, ref) => {
  return <AntdTypographyLink {...props} ref={ref} />
})

const AntdTypographyLink = makeBaseTextComponent(AntdTypography.Link)
