import { Form } from '@vivaldis/antd-ui'
import type { FillCompanySurveyInput } from '@vivaldis/graphql'
import { FC } from 'react'
import { ActionBar } from './components/ActionBar'
import { FormContent } from './components/FormContent'
import { FormProvider } from './provider/FormProvider'
import type { CompanySurvey } from './types/CompanySurvey'

export interface EditEmployeeProfileFormProps {
  companySurvey: CompanySurvey
  onSubmit: (fillCompanySurveyInput: FillCompanySurveyInput) => Promise<any>
}

export const SurveyForm: FC<EditEmployeeProfileFormProps> = ({
  companySurvey,
  onSubmit
}) => {
  return (
    <FormProvider companySurvey={companySurvey} onSubmit={onSubmit}>
      <Form
        id="fill-survey-form"
        name="fill-survey-form"
        autoComplete="off"
        size="middle"
      >
        <FormContent companySurvey={companySurvey} />
        <ActionBar />
      </Form>
    </FormProvider>
  )
}
