import styled from '@emotion/styled'
import { FontWeight, MarginProps } from '@vivaldis/ui'
import { ComponentType, CSSProperties } from 'react'
import { text, TextOptions } from '../utils/font'
import { margin } from '../utils/styles/margin'
import { TextStylePreset } from './presets'

export interface BaseTextProps extends MarginProps {
  preset?: TextStylePreset
  //
  Component?: keyof JSX.IntrinsicElements | ComponentType<any>
  className?: string
  color?: string
  fontSize?: number
  fontWeight?: FontWeight
  letterSpacing?: number
  lineHeight?: number
  textAlign?: TextOptions['textAlign']
  textTransform?: TextOptions['textTransform']
  textDecoration?:
    | 'underline'
    | 'line-through'
    | 'underline line-through'
    | 'none'
  style?: CSSProperties
  as?: string
}

const IGNORED_BASE_TEXT_PROPS = [
  'preset',
  'Component',
  'color',
  'fontSize',
  'fontWeight',
  'letterSpacing',
  'lineHeight',
  'textAlign',
  'textTransform',
  'textDecoration',
  'as',
  //  MarginProps
  'marginBottom',
  'marginTop',
  'marginLeft',
  'marginRight',
  'marginVertical',
  'marginHorizontal'
]

// TODO: add typings <ComponentType extends any> to have proper typing and auto-complete
export const makeBaseTextComponent = (Component: any) => {
  return styled(Component, {
    shouldForwardProp: prop => {
      return !IGNORED_BASE_TEXT_PROPS.includes(String(prop))
    }
  })`
    .ant-typography&,
    & {
      ${props => text(props)};
      ${props => (props.color ? `color: ${props.color};` : '')};
      ${props => margin(props)};
    }
  `
}

export const BaseText = makeBaseTextComponent('span')
