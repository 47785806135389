import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const DangerTriangleSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0128 4.5C11.5368 4.5 11.1128 4.746 10.8748 5.159L3.67677 17.724C3.44277 18.134 3.44477 18.623 3.68177 19.032C3.91877 19.441 4.34277 19.686 4.81577 19.686H19.1988C19.6708 19.686 20.0948 19.441 20.3318 19.032C20.5698 18.623 20.5718 18.134 20.3358 17.724L13.1508 5.159C12.9138 4.746 12.4898 4.5 12.0128 4.5ZM19.1988 21.186H4.81577C3.80177 21.186 2.89277 20.662 2.38377 19.784C1.87477 18.907 1.87177 17.858 2.37477 16.979L9.57477 4.413C10.0808 3.528 10.9918 3 12.0128 3H12.0138C13.0338 3 13.9468 3.529 14.4528 4.415L21.6388 16.979C22.1418 17.858 22.1388 18.907 21.6298 19.784C21.1208 20.662 20.2118 21.186 19.1988 21.186ZM12.0039 14.164C11.5899 14.164 11.2539 13.828 11.2539 13.414V10.314C11.2539 9.89996 11.5899 9.56396 12.0039 9.56396C12.4179 9.56396 12.7539 9.89996 12.7539 10.314V13.414C12.7539 13.828 12.4179 14.164 12.0039 14.164ZM11.001 16.499C11.001 17.052 11.453 17.499 12.006 17.499C12.559 17.499 13.006 17.052 13.006 16.499C13.006 15.946 12.559 15.499 12.006 15.499H11.996C11.444 15.499 11.001 15.946 11.001 16.499Z"
    />
  </svg>
)

export const DangerTriangle = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={DangerTriangleSvg} {...props} ref={ref} />
)
