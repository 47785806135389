export const CERTIFICATE_TYPES_WITH_REQUIRED_EXPIRATION_DATE = [
  'vca',
  'code_95',
  'forklift',
  'working_in_heights',
  'ba4_electricity_alerted',
  'ba5_electricity_expert',
  'heating_technology_cedicol',
  'gas_cerga',
  'overhead_crane'
]
