import { gql } from '@apollo/client'
import { contractDatumFragment } from './ContractDatumFragment'
import { workdayFragment } from './WorkdayFragment'

export const contractFragment = gql`
  ${contractDatumFragment}
  ${workdayFragment}

  fragment ContractFragment on Contract {
    attemptsCount
    backOfficeId
    buildingNumber
    cancellationRequest {
      isApproved
      reason
      requester {
        email
        firstName
        lastName
      }
    }
    city
    contractPremiums {
      contributionValue
      id
      premium {
        id
        name
      }
      unit
      value
    }
    contractReason
    #    country: Country!
    #    employee: Employee!
    id
    latitude
    longitude
    parentContractId
    position {
      contractDatum {
        ...ContractDatumFragment
      }
      id
    }
    street
    streetNumber
    wage
    #    workPostSheet: WorkPostSheet
    workdays {
      ...WorkdayFragment
    }
    zip
  }
`
