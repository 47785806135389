import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import {
  CreatePositionMutation,
  CreatePositionMutationVariables
} from './__generated__/CreatePositionMutation'
import { positionFragment } from '../fragments/PositionFragment'

const mutation = gql`
  ${positionFragment}

  mutation CreatePositionMutation(
    $input: CreatePositionInput!
    $locale: String
  ) {
    createPosition(input: $input) {
      position {
        ...PositionFragment
      }
    }
  }
`

export function createPositionMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<CreatePositionMutation, CreatePositionMutationVariables>,
    'mutation'
  >
) {
  return apolloClient.mutate<
    CreatePositionMutation,
    CreatePositionMutationVariables
  >({
    mutation,
    ...options
  })
}
