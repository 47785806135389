import { WorkPostSheetItemTypesEnum } from '../types'

const MATERNITY_PROTECTION_MEASURES_WPS_ITEMS = [
  WorkPostSheetItemTypesEnum.PREGNANCY_ADJUSTMENTS,
  WorkPostSheetItemTypesEnum.PREGNANCY_ADJUSTMENTS_DATE,
  WorkPostSheetItemTypesEnum.BREASTFEEDING_ADJUSTMENTS,
  WorkPostSheetItemTypesEnum.BREASTFEEDING_ADJUSTEMENTS_DATE
]

export const isMaternityProtectionMeasuresWPSItem = (
  type: WorkPostSheetItemTypesEnum
): boolean => {
  return MATERNITY_PROTECTION_MEASURES_WPS_ITEMS.includes(type)
}
