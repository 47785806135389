import { BezierEasing } from './BezierEasing'

let requestAnimationFrame: typeof window.requestAnimationFrame
if (typeof (window as any) !== 'undefined') {
  requestAnimationFrame =
    window.requestAnimationFrame ||
    (window as any).mozRequestAnimationFrame ||
    (window as any).webkitRequestAnimationFrame ||
    (window as any).msRequestAnimationFrame
}

const TOTAL_ITERATIONS = 95
const CIRCLES_INTERVAL = 10
const EASING = BezierEasing(0.2, 0.48, 0.79, 0.55)
const BOUNDING = 16

interface IAnimation {
  iteration: number
  id: number
}

const ids: any[] = []
const animations: {
  [key: string]: number
} = {}

function rotateCircle(this: IAnimation, circles: SVGCircleElement[]) {
  const len = circles.length
  for (let i = 0; i < len; ++i) {
    if (this.iteration >= CIRCLES_INTERVAL * i) {
      let iteration = this.iteration - CIRCLES_INTERVAL * i
      const revolution = Math.floor(iteration / TOTAL_ITERATIONS)
      iteration = iteration - revolution * TOTAL_ITERATIONS
      if (iteration < 0) {
        iteration = TOTAL_ITERATIONS - iteration
      } else if (iteration > TOTAL_ITERATIONS) {
        iteration = iteration - TOTAL_ITERATIONS
      }
      const eased = EASING.get((1 / TOTAL_ITERATIONS) * iteration)
      const position = eased * 2 * Math.PI * -1
      circles[i].setAttributeNS('', 'fill-opacity', '1')
      circles[i].setAttributeNS(
        '',
        'cx',
        20 + BOUNDING * Math.sin(position) + ''
      )
      circles[i].setAttributeNS(
        '',
        'cy',
        20 + BOUNDING * Math.cos(position) + ''
      )
      let size: number
      if (eased === 0.5) size = 3
      else if (eased > 0.5) size = 3 - (eased - 0.5) * 4
      else size = 3 - (0.5 - eased) * 4
      circles[i].setAttributeNS('', 'r', size.toString())
    }
  }

  this.iteration++
  animations[this.id] = requestAnimationFrame(rotateCircle.bind(this, circles))
}

export function startAnimation(...elements: SVGCircleElement[]): number {
  let id = 0
  if (ids.length) id = ids[ids.length - 1] + 1
  ids.push(id)
  rotateCircle.apply({ iteration: 0, id }, [elements])
  return id
}

export function stopAnimation(animation: number) {
  window.cancelAnimationFrame(animations[animation])
}
