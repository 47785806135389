import {
  useEffect,
  useState,
  useRef,
  useCallback,
  Dispatch,
  SetStateAction
} from 'react'

export function useStorage<S = any>(
  key: string,
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>, boolean] {
  const mounted = useRef(true)

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  const [data, setData] = useState<S>(() => {
    let initialData: any = null
    try {
      const string = localStorage.getItem(key)
      if (string) {
        const d = JSON.parse(string)
        initialData = d
      }
    } catch (err) {}
    if (initialData) return initialData
    if (initialState && typeof initialState === 'function') {
      return (initialState as () => S)()
    } else {
      return initialState
    }
  })

  const setDataCallback: Dispatch<SetStateAction<S>> = useCallback(
    (setStateAction: ((state: S) => S) | any) => {
      setData(prev => {
        const next =
          typeof setStateAction === 'function'
            ? setStateAction(prev)
            : setStateAction
        try {
          const serialized = JSON.stringify(next)
          localStorage.setItem(key, serialized)
        } catch (err) {}
        return next
      })
    },
    [key]
  )

  return [data, setDataCallback, true]
}
