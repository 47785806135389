import { FC, PropsWithChildren, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { GoBackToContext } from '../context/GoBackToContext'
import { GoBackToEntriesContext } from '../context/GoBackToEntriesContext'

interface Props {
  value: string
}

export const GoBackToProvider: FC<PropsWithChildren<Props>> = ({
  children,
  value
}) => {
  const location = useLocation()
  const [entries] = useContext(GoBackToEntriesContext)!
  const entry = entries.find(entry => entry.key === location.key)
  return (
    <GoBackToContext.Provider value={entry ? entry.pathname : value}>
      {children}
    </GoBackToContext.Provider>
  )
}
