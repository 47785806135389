import '@vivaldis/web-ui/src/components/Polyfills'
import { FC, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ErrorBoundary, bugsnagClient } from '@vivaldis/web-bugsnag'
import {
  initBugsnagTracking,
  initGoogleTagManagerTracking
} from '@vivaldis/tracking'
import {
  DrawerProvider,
  initFocusHandler,
  UnknownErrorPage
} from '@vivaldis/web-ui'
import './i18n'
import 'antd/dist/reset.css'
import './index.css'
import './config/axios'
import { App as AntdApp, ConfigProvider } from '@vivaldis/antd-ui'
import { App as AppContainer } from '@vivaldis/app'
import storage from 'localforage'
import { UserType } from '@vivaldis/session'
import {
  ProgressBarProvider,
  SideSheets,
  ScreenTitleProvider
} from '@vivaldis/web-ui'
import { OnboardingStepProvider } from './providers/OnboardingStepProvider'
import reportWebVitals from './reportWebVitals'
import { createReduxStore } from './config/redux'
import { reducers } from './reducers'
import { sagas } from './sagas'
import { createApolloClient } from './config/apolloClient'
import { IntercomMessengerProvider } from './app/IntercomMessenger/providers/IntercomMessengerProvider'
import { UserDashboardQueryProvider } from './providers/UserDashboardQueryProvider'
import { CompanyInformationQueryProvider } from './providers/CompanyInformationQueryProvider'
import { App as AppContent } from './app'
import { Routes } from './screens/routes'
import { ThemeProvider } from './providers/ThemeProvider'
import { TimesheetBadgesProvider } from './providers/TimesheetBadgesProvider'

initBugsnagTracking(bugsnagClient)
initGoogleTagManagerTracking()
initFocusHandler()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const App: FC = () => {
  const { apolloClient, apolloCachePersistor, store, storePersistor } =
    useMemo(() => {
      const { apolloClient, apolloCachePersistor } = createApolloClient()
      const { store, persistor: storePersistor } = createReduxStore(
        reducers,
        sagas
      )
      return {
        apolloClient,
        apolloCachePersistor,
        store,
        storePersistor
      }
    }, [])

  return (
    <ThemeProvider>
      <ConfigProvider>
        <AntdApp>
          <ProgressBarProvider>
            <ScreenTitleProvider>
              <AppContainer
                apolloClient={apolloClient}
                apolloCachePersistor={apolloCachePersistor}
                reduxStore={store}
                reduxPersistor={storePersistor}
                storage={storage}
                version={`${process.env.REACT_APP_VERSION}`}
                migrateBefore={async () => {}}
                migrateAfter={async () => {}}
                userType={UserType.User}
                actionCableURL={process.env.REACT_APP_WEB_SOCKET_URL!}
                sideSheets={
                  <SideSheets>
                    <Routes />
                  </SideSheets>
                }
                Providers={children => (
                  <QueryClientProvider client={queryClient}>
                    <OnboardingStepProvider>
                      <DrawerProvider>
                        <IntercomMessengerProvider>
                          <UserDashboardQueryProvider>
                            <CompanyInformationQueryProvider>
                              <TimesheetBadgesProvider>
                                {children}
                              </TimesheetBadgesProvider>
                            </CompanyInformationQueryProvider>
                          </UserDashboardQueryProvider>
                        </IntercomMessengerProvider>
                      </DrawerProvider>
                    </OnboardingStepProvider>
                  </QueryClientProvider>
                )}
              >
                <AppContent />
              </AppContainer>
            </ScreenTitleProvider>
          </ProgressBarProvider>
        </AntdApp>
      </ConfigProvider>
    </ThemeProvider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <ErrorBoundary FallbackComponent={UnknownErrorPage}>
    <App />
  </ErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
