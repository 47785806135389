import AsyncStorage from 'localforage'
import { useEffect, useMemo, useState } from 'react'
import { getI18n } from 'react-i18next'
import { disableTrackingLogger, enableTrackingLogger } from '@vivaldis/tracking'
import {
  disableReduxLogger,
  enableReduxLogger,
  enableApolloLogger,
  disableApolloLogger
} from '@vivaldis/common'

const ASYNC_KEY = '@@DevSettings'

export interface DevSettingsValue {
  reduxLoggerEnabled: boolean
  playgroundEnabled: boolean
  apolloLoggerEnabled: boolean
  trackingLoggerEnabled: boolean
  networkConnectivityDisabled: boolean
  networkDelay: number
  locale: string
}

export interface DevSettingsEvent {
  value?: DevSettingsValue
}

export type DevSettingsEventType = 'change' | 'ready'

export type DevSettingsEventHandler = (event: DevSettingsEvent) => any

export interface DevSettingsListener {
  type: DevSettingsEventType
  handler: DevSettingsEventHandler
}

export default class DevSettings {
  public static addEventListener(
    type: DevSettingsEventType,
    handler: DevSettingsEventHandler
  ) {
    DevSettings.listeners.push({ handler, type })
  }

  public static removeEventListener(
    type: DevSettingsEventType,
    handler: DevSettingsEventHandler
  ) {
    DevSettings.listeners = DevSettings.listeners.filter(
      listener => !(listener.type === type && listener.handler === handler)
    )
  }

  public static triggerEvent(t: DevSettingsEventType, event: DevSettingsEvent) {
    DevSettings.listeners
      .filter(({ type }) => type === t)
      .forEach(({ handler }) => handler(event))
  }

  public static setValue(value: Partial<DevSettingsValue>) {
    const prevSettings = { ...DevSettings.value }
    DevSettings.value = {
      ...DevSettings.value,
      ...value
    }
    DevSettings.settingsDidChange(prevSettings)
    DevSettings.triggerEvent('change', { value: { ...DevSettings.value } })
    try {
      AsyncStorage.setItem(ASYNC_KEY, JSON.stringify(DevSettings.value)).catch(
        () => {}
      )
    } catch (err) {}
  }

  public static getValue(): DevSettingsValue {
    return { ...DevSettings.value }
  }

  public static getInitialValue(): DevSettingsValue {
    return { ...DevSettings.initialValue }
  }

  public static isReady(): boolean {
    return DevSettings.ready
  }

  public static settingsDidChange(prevSettings: DevSettingsValue) {
    if (
      prevSettings.reduxLoggerEnabled !== DevSettings.value.reduxLoggerEnabled
    ) {
      if (DevSettings.value.reduxLoggerEnabled) enableReduxLogger()
      else disableReduxLogger()
    }
    if (
      prevSettings.apolloLoggerEnabled !== DevSettings.value.apolloLoggerEnabled
    ) {
      if (DevSettings.value.apolloLoggerEnabled) enableApolloLogger()
      else disableApolloLogger()
    }
    if (
      prevSettings.trackingLoggerEnabled !==
      DevSettings.value.trackingLoggerEnabled
    ) {
      if (DevSettings.value.trackingLoggerEnabled) enableTrackingLogger()
      else disableTrackingLogger()
    }
    if (
      prevSettings.networkConnectivityDisabled !==
      DevSettings.value.networkConnectivityDisabled
    ) {
      if (DevSettings.value.networkConnectivityDisabled) {
        // Network.simulateNetworkConnectivityDisabled()
      } else {
        // Network.simulateNetworkConnectivityEnabled()
      }
    }
    if (prevSettings.locale !== DevSettings.value.locale) {
      getI18n().changeLanguage(DevSettings.value.locale)
    }
  }

  public static loadValue() {
    AsyncStorage.getItem(ASYNC_KEY, (err, result) => {
      if (!err && result) {
        try {
          const data = JSON.parse(result as any)
          const prevSettings = { ...DevSettings.value }
          DevSettings.value = {
            ...DevSettings.value,
            ...data
          }
          DevSettings.settingsDidChange(prevSettings)
          DevSettings.triggerEvent('change', {
            value: { ...DevSettings.value }
          })
          DevSettings.ready = true
          setImmediate(() => {
            DevSettings.triggerEvent('ready', {})
          })
        } catch (err) {
          DevSettings.ready = true
          setImmediate(() => {
            DevSettings.triggerEvent('ready', {})
          })
        }
      } else {
        DevSettings.ready = true
        setImmediate(() => {
          DevSettings.triggerEvent('ready', {})
        })
      }
    }).catch(() => {})
  }

  private static ready = false

  private static initialValue: DevSettingsValue = {
    apolloLoggerEnabled: false,
    locale: getI18n().language,
    networkConnectivityDisabled: false,
    networkDelay: 0,
    playgroundEnabled: false,
    reduxLoggerEnabled: false,
    trackingLoggerEnabled: false
  }

  private static value: DevSettingsValue = { ...DevSettings.initialValue }

  private static listeners: DevSettingsListener[] = []
}

export const useDevSetting = (key: keyof DevSettingsValue) => {
  const [state, setState] = useState(() => {
    const devSettings = DevSettings.getValue()
    return devSettings[key]
  })

  const handler = useMemo(() => {
    return ({ value }: DevSettingsEvent) => {
      if (value) {
        setState(value[key])
      }
    }
  }, [key])

  useEffect(() => {
    DevSettings.addEventListener('change', handler)
    return () => {
      DevSettings.removeEventListener('change', handler)
    }
  }, [key, handler])
  return state
}
