import { VCA_CERTIFICATE_TYPE } from '@vivaldis/ui'
import { isAfter, startOfDay } from 'date-fns'
import { useMemo } from 'react'

export const useIsVcaExamDateVisible = (
  position: Position | undefined,
  employee: Employee | undefined
) => {
  return useMemo(() => {
    // visible when position.committee is 124.000 and the employee doesn't have an active VCA attestation as a certificate
    if (
      position?.contractDatum?.committee?.code === '124.000' &&
      !employee?.employeeCertificates?.some(employeeCertificate => {
        if (employeeCertificate.certificateType !== VCA_CERTIFICATE_TYPE) {
          return false
        }

        if (!employeeCertificate.expiresOn) {
          return false
        }

        const expiresOn = new Date(employeeCertificate.expiresOn)
        return isAfter(expiresOn, startOfDay(new Date()))
      })
    ) {
      return true
    }
    return false
  }, [employee?.employeeCertificates, position?.contractDatum?.committee?.code])
}

interface EmployeeCertificates {
  __typename: 'EmployeeCertificate'
  certificateType: string
  expiresOn: string | null
}

interface Employee {
  __typename: 'Employee'
  employeeCertificates: EmployeeCertificates[]
}

interface Committee {
  __typename: 'Committee'
  code: string
}

interface ContractDatum {
  __typename: 'ContractDatum'
  committee: Committee | null
}

interface Position {
  __typename: 'Position'
  contractDatum: ContractDatum | null
}
