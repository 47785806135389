import styled from '@emotion/styled'
import { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { Body } from '../../Typography/components/Body'

const Container = styled('div')`
  padding: 24px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`

type Props = {
  text?: string
} & HTMLAttributes<HTMLDivElement>

export const SearchEmptyState: FC<Props> = ({ text, ...rest }) => {
  const { t } = useTranslation('web_ui')
  return (
    <Container {...rest}>
      <Body>{text || t('search_empty_state.label')}</Body>
    </Container>
  )
}
