import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const ClockSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.32 2.024c-2.005.179-3.758.826-5.249 1.937C3.91 5.57 2.526 7.872 2.113 10.54c-.123.797-.123 2.123 0 2.92a10.002 10.002 0 0 0 3.958 6.58 10.061 10.061 0 0 0 4.469 1.847c.45.07.736.086 1.46.086s1.01-.016 1.46-.086a10.03 10.03 0 0 0 6.626-4.018c.918-1.248 1.552-2.8 1.801-4.409.07-.45.086-.736.086-1.46s-.016-1.01-.086-1.46a10 10 0 0 0-8.415-8.422c-.51-.076-1.746-.13-2.152-.094m1.88 1.563a8.442 8.442 0 0 1 3.911 1.619c.509.378 1.353 1.229 1.725 1.74 2.221 3.043 2.221 7.062.001 10.108-.38.521-1.262 1.403-1.783 1.783-3.046 2.22-7.062 2.22-10.108 0-.521-.38-1.403-1.262-1.783-1.783-1.203-1.651-1.777-3.609-1.642-5.604.143-2.114.98-3.954 2.477-5.452C7.919 4.077 10.477 3.22 13.2 3.587M11.598 6.85a.536.536 0 0 0-.258.25c-.077.154-.08.253-.092 2.495-.008 1.622.002 2.404.034 2.56l.045.225 1.666 1.663c1.837 1.833 1.817 1.817 2.173 1.71.22-.065.522-.367.587-.587.106-.351.104-.354-1.518-1.986l-1.471-1.48-.012-2.22c-.012-2.15-.015-2.225-.094-2.38-.11-.214-.355-.34-.658-.34a.868.868 0 0 0-.402.09"
      fillRule="evenodd"
    />
  </svg>
)

export const Clock = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={ClockSvg} {...props} ref={ref} />
)
