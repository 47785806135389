import { axios, type AxiosResponse, UserType } from '@vivaldis/common'
import { Prompt } from '@vivaldis/ui'

export interface VerifyOTPRequestData {
  otp_verification_code: string
  otp_verification_token: string
  email: string
  type: UserType
}

export interface VerifyOTPResponse {
  authentication_token: string
}

export const verifyOTP = async (
  data: VerifyOTPRequestData
): Promise<AxiosResponse<VerifyOTPResponse> | undefined> => {
  try {
    return await axios().get<VerifyOTPResponse>('/users/otp_verify', {
      params: {
        token: data.otp_verification_token,
        otp: data.otp_verification_code
      }
    })
  } catch (error: any) {
    if (
      error &&
      error.response &&
      error.response.status.toString().match(/^5.*/)
    ) {
      setTimeout(() => Prompt.serviceUnavailablePrompt(), 0)
      return undefined
    } else if (error.response) {
      throw error
    } else if (error.request) {
      setTimeout(() => Prompt.serviceUnavailablePrompt(), 0)
      return undefined
    } else {
      setTimeout(() => Prompt.unknownErrorPrompt(), 0)
      return undefined
    }
  }
}
