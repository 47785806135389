import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const ArrowLeftSquareSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.084 3.5C18.767 3.5 20.5 5.135 20.5 7.665V16.334C20.5 18.864 18.767 20.5 16.084 20.5H7.916C5.233 20.5 3.5 18.864 3.5 16.334V7.665C3.5 5.135 5.233 3.5 7.916 3.5H16.084ZM7.916 22H16.084C19.622 22 22 19.723 22 16.334V7.665C22 4.276 19.622 2 16.084 2H7.916C4.378 2 2 4.276 2 7.665V16.334C2 19.723 4.378 22 7.916 22ZM9.72971 12.75H16.0862C16.5002 12.75 16.8362 12.414 16.8362 12C16.8362 11.586 16.5002 11.25 16.0862 11.25H9.7296L12.2066 8.78295C12.4996 8.49095 12.5006 8.01695 12.2086 7.72295C11.9166 7.42895 11.4426 7.42895 11.1486 7.72095L7.38463 11.4689C7.24263 11.6089 7.16363 11.8009 7.16363 11.9999C7.16363 12.1989 7.24263 12.3909 7.38463 12.5309L11.1486 16.2789C11.2946 16.4249 11.4866 16.4979 11.6776 16.4979C11.8696 16.4979 12.0626 16.4249 12.2086 16.2769C12.5006 15.9829 12.4996 15.5089 12.2066 15.2169L9.72971 12.75Z"
    />
  </svg>
)

export const ArrowLeftSquare = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={ArrowLeftSquareSvg} {...props} ref={ref} />
)
