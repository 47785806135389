import { localizedCountryNameSelector, ON_THE_MOVE } from '@vivaldis/common'
import type { PositionFormStep1Values } from '../steps/Step1/typings/PositionFormStep1Values'
import type { Position } from '../typings/Position'

export const positionToStep1FormValues = (
  position: Position,
  locale: string
): PositionFormStep1Values => {
  const onTheMove = position.street === ON_THE_MOVE

  return {
    name: position.name || '',
    functionCodeId: position.contractDatum?.functionCode.id || '',
    noVatApplicable: position.contractDatum?.noVatApplicable || false,
    description: position.description || '',
    // location
    onTheMove: onTheMove,
    city: onTheMove ? '' : position.city || '',
    country: onTheMove
      ? ''
      : localizedCountryNameSelector(locale, position.country || undefined),
    countryId: onTheMove ? '' : position.country?.id || '',
    latitude: onTheMove ? 0 : position.latitude || 0,
    longitude: onTheMove ? 0 : position.longitude || 0,
    street: onTheMove ? '' : position.street || '',
    streetNumber: onTheMove ? '' : position.streetNumber || '',
    zip: onTheMove ? '' : position.zip || '',
    // SU values for approved position
    minimumWage: position.minimumWage || undefined,
    vivaldisOfficeCode: position.contractDatum?.vivaldisOffice || undefined
  }
}
