import { gql } from '@apollo/client'

export const functionCodeFragment = gql`
  fragment FunctionCodeFragment on FunctionCode {
    actualCoefficient
    code
    id
    name
    payrollCoefficient
  }
`
