export function compose<T = any>(...funcs: T[]): T {
  if (funcs.length === 0) {
    return ((arg: any) => arg) as any
  }

  if (funcs.length === 1) {
    return funcs[0]
  }

  return funcs.reduce(
    (a: any, b: any) => ((...args: any[]) => a(b(...args))) as any
  )
}
