import { ApolloClient, MutationOptions, gql } from '@apollo/client'
import {
  SetPositionPremiums,
  SetPositionPremiumsVariables
} from './__generated__/SetPositionPremiums'

const mutation = gql`
  mutation SetPositionPremiums($input: SetPositionPremiumsInput!) {
    setPositionPremiums(input: $input) {
      status
    }
  }
`

export function setPositionPremiumsMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<SetPositionPremiums, SetPositionPremiumsVariables>,
    'mutation'
  >
) {
  return apolloClient.mutate<SetPositionPremiums, SetPositionPremiumsVariables>(
    {
      mutation,
      ...options
    }
  )
}
