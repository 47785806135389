import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { Location } from 'history'
import { SideSheetsHistoryContext } from '../context/SideSheetsHistoryContext'

interface SideSheet {
  key: string
  location: Location
  animation: 'leaving' | 'entering'
  layer: number
  index: number
  onLeave: () => void
}

export const SideSheetsElementsContext = createContext<SideSheet[]>([])

export const SideSheetsElementsProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const history = useContext(SideSheetsHistoryContext)!

  const [sideSheets, setSideSheets] = useState<
    Omit<SideSheet, 'layer' | 'index' | 'onLeave'>[]
  >(
    history.entries
      .slice(1)
      .map(location => ({ key: location.key, location, animation: 'entering' }))
  )

  useEffect(() => {
    return history.listen(() => {
      setSideSheets(prevSideSheets => {
        const keys: { [key: string]: boolean } = {}
        const nextSideSheets: Omit<SideSheet, 'layer' | 'index' | 'onLeave'>[] =
          history.entries.slice(1).map(location => {
            keys[location.key] = true
            const entry = prevSideSheets.find(e => e.key === location.key)
            if (entry) {
              return entry
            }
            return {
              key: location.key,
              location,
              animation: 'entering' as const
            }
          })

        return [
          ...nextSideSheets,
          ...prevSideSheets
            .filter(i => !keys[i.key])
            .map(prevEntry => ({
              ...prevEntry,
              animation: 'leaving' as const
            }))
        ]
      })
    })
  }, [history])

  const onLeave = useCallback((location: Location) => {
    setSideSheets(sideSheets => sideSheets.filter(e => e.key !== location.key))
  }, [])

  let length = sideSheets.length
  if (sideSheets.find(s => s.animation === 'leaving')) {
    length = length - 1
  }

  const finalSideSheets: SideSheet[] = sideSheets.map((sideSheet, index) => ({
    ...sideSheet,
    layer: length - index,
    index: index,
    location: sideSheet.location,
    onLeave: () => onLeave(sideSheet.location)
  }))

  return (
    <SideSheetsElementsContext.Provider value={finalSideSheets}>
      {children}
    </SideSheetsElementsContext.Provider>
  )
}
