import { margin, MarginProps } from '@vivaldis/ui'
import styled from '@emotion/styled'

interface ContainerProps extends MarginProps {
  borderColor: string
  disabled?: boolean
}

export const Container = styled.div<ContainerProps>`
  background: ${({ theme }) => theme.white};
  border: 2px solid ${props => props.borderColor};
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  ${props => margin(props)};
`
