import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Information } from '../../../Information'
import type { CompanySurvey_questions } from '../../types/CompanySurvey'
import { EmptyTextValue } from '../EmptyTextValue'

interface Props {
  question: CompanySurvey_questions
}

export const BoolWithText: FC<Props> = ({ question }) => {
  const [t] = useTranslation('web_ui')

  const boolValue = question.answer?.boolValue || false
  const textValue = question.answer?.textValue || ''

  return (
    <>
      <Information.InformationField label={question.title}>
        {boolValue
          ? t('yes', { ns: 'translation' })
          : t('no', { ns: 'translation' })}
      </Information.InformationField>
      <Information.InformationField
        label={t('view_survey_screen_content.comment')}
      >
        {textValue || <EmptyTextValue />}
      </Information.InformationField>
    </>
  )
}
