import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Radio, TextStylePreset, Typography } from '@vivaldis/antd-ui'
import type { CompanySurvey_questions } from '../../../../types/CompanySurvey'

interface Props {
  question: CompanySurvey_questions
}

export const Bool: FC<Props> = ({ question }) => {
  const [t] = useTranslation('web_ui')

  const boolFiledName = `${question.id}.boolValue`

  return (
    <>
      <Form.Item
        name={boolFiledName}
        required={question.required}
        label={
          <Typography.Text preset={TextStylePreset.Semibold16} marginBottom={4}>
            {question.title}
          </Typography.Text>
        }
      >
        <Radio.Group name={boolFiledName} size="large">
          <Radio name={question.id} value={true}>
            {t('survey_form.bool.options.yes')}
          </Radio>
          <Radio name={question.id} value={false}>
            {t('survey_form.bool.options.no')}
          </Radio>
        </Radio.Group>
      </Form.Item>
    </>
  )
}
