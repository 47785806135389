import { FC, useEffect } from 'react'
import { useProgressBar } from './useProgressBar'

const Fallback: FC = () => {
  const progressBar = useProgressBar()
  useEffect(() => {
    progressBar.addLoadingState()
    return () => progressBar.removeLoadingState()
  }, [progressBar])
  return null
}

export const ProgressBarSuspenseFallback = <Fallback />
