import styled from '@emotion/styled'
import { Divider, Layout, useBreakpoint } from '@vivaldis/antd-ui'
import { useStorage } from '@vivaldis/common'
import { hasSessionSelector, useUser } from '@vivaldis/session'
import { useTheme } from '@vivaldis/ui'
import { createElement, Link, ZIndex } from '@vivaldis/web-ui'
import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { animated, useSpring } from 'react-spring'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Routes } from '../../screens/routes'
import { CompaniesDropdown } from './components/CompaniesDropdown'
import { NavigationBarForOnboarding } from './components/NavigationBar'
import { LeftNavigationMenu } from './components/LeftNavigationMenu'
import { AnimatedLogo } from './components/AnimatedLogo'
import { Header } from './components/Header'

const { Content, Sider } = Layout

const SIDER_STATE_KEY = 'SIDER_STATE_VALUE'

const SIDER_OPEN_WIDTH = 256
const SIDER_COLLAPSED_WIDTH = 64

export const DesktopLayout: FC = () => {
  const theme = useTheme()
  const user = useUser()
  const hasSession = useSelector(hasSessionSelector)

  const breakpoint = useBreakpoint()

  // we force user to finish onboarding when company doesn't have VAT (this mean that onboarding was not passed yet)
  const showOnboarding = useMemo(
    () => !user?.company?.vat,
    [user?.company?.vat]
  )

  // it should be open on xl+ screens and closed on smaller screens
  const [collapsed, setCollapsed] = useStorage<boolean>(
    SIDER_STATE_KEY,
    !breakpoint.xl
  )

  const [animatedStyles] = useSpring(
    () => ({
      width: collapsed ? SIDER_COLLAPSED_WIDTH : SIDER_OPEN_WIDTH
    }),
    [collapsed]
  )

  // guest user doesn't need this layout
  if (!hasSession) {
    return <Routes />
  }

  if (showOnboarding) {
    return (
      <>
        <NavigationBarForOnboarding />
        <Routes />
      </>
    )
  }

  return (
    <Layout hasSider>
      <Sider
        width={SIDER_OPEN_WIDTH}
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        style={{
          overflow: 'auto',
          // height: '100vh',
          minHeight: '100dvh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: ZIndex.navigationBar
        }}
        theme="light"
        collapsedWidth={SIDER_COLLAPSED_WIDTH}
      >
        <SiderContent>
          <div>
            <Link to="/" id="cy-navigate-on-vivaldis-logo">
              <AnimatedBaseLogo style={{ width: animatedStyles.width }}>
                <AnimatedLogo isSiderCollapsed={collapsed} />
              </AnimatedBaseLogo>
            </Link>
            <CompaniesDropdown
              isSiderCollapsed={collapsed}
              animatedStyles={animatedStyles}
            />
            <LeftNavigationMenu isSiderCollapsed={collapsed} />
          </div>
          <SiderFooter>
            <Divider />
            {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              style: {
                fontSize: 24,
                color: theme.primaryColor,
                marginRight: 20
              },
              className: 'trigger',
              onClick: () => setCollapsed(!collapsed)
            })}
          </SiderFooter>
        </SiderContent>
      </Sider>
      <AnimatedLayout style={{ marginLeft: animatedStyles.width }}>
        <Header animatedStyles={animatedStyles} />
        <Content style={{ overflow: 'initial', marginTop: 72 }}>
          <Routes />
        </Content>
      </AnimatedLayout>
    </Layout>
  )
}

const AnimatedBaseLogo = styled(animated.div)`
  width: 100%;
  padding: 16px;
  height: 72px;
  border-bottom: 1px solid ${props => props.theme.extraBackground1};
  overflow: hidden;
  will-change: height;
`

const AnimatedLayout = styled(animated(Layout))``

const SiderContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  height: 100%;
  border-right: 1px solid ${props => props.theme.extraBackground1};
`

const SiderFooter = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 16px;
`
