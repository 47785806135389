import { createSelector } from 'reselect'
import { APP_STATE_KEY, AppState } from '../reducer'

interface State {
  [APP_STATE_KEY]: AppState
}

export const appStateSelector = (state: State): AppState => state[APP_STATE_KEY]

export const isAppDataReadySelector: (state: State) => boolean = createSelector(
  appStateSelector,
  app => app.dataReady
)

export const isAppUIReadySelector: (state: State) => boolean = createSelector(
  appStateSelector,
  app => app.uiReady
)

export const isAppNavigationReadySelector: (state: State) => boolean =
  createSelector(appStateSelector, app => app.navigationReady)

export const isAppReadySelector: (state: State) => boolean = createSelector(
  appStateSelector,
  app => app.ready
)
