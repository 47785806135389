import { FC } from 'react'
import { Divider } from '../../../../../Divider'
import { CompanyFunctionCode } from '../../../../typings/CompanyFunctionCode'
import { CountryType } from '../../../../typings/CountryType'
import { VivaldisOffice } from '../../../../typings/VivaldiOffice'
import { Description } from './components/Description'
import { FunctionCode } from './components/FunctionCode'
import { Location } from './components/Location'
import { MinimumWage } from './components/MinimumWage'
import { Name } from './components/Name'
import { NoVatApplicableCheckbox } from './components/NoVatApplicableCheckbox'
import { VivaldisOfficeCode } from './components/VivaldisOfficeCode'

interface Props {
  countries: CountryType[]
  initiallySelectedCompanyCode?: CompanyFunctionCode | undefined
  companyFunctionCodes: CompanyFunctionCode[]
  showNoVatApplicableCheckbox: boolean
  showMinimumWageInput: boolean
  vivaldisOffices: VivaldisOffice[]
  showVivaldisOfficeCodeInput: boolean
}

export const FormContent: FC<Props> = ({
  countries,
  initiallySelectedCompanyCode,
  companyFunctionCodes,
  showNoVatApplicableCheckbox,
  showMinimumWageInput,
  vivaldisOffices,
  showVivaldisOfficeCodeInput
}) => {
  return (
    <>
      <Name />
      <FunctionCode
        initiallySelectedCompanyCode={initiallySelectedCompanyCode}
        companyFunctionCodes={companyFunctionCodes}
      />
      {showNoVatApplicableCheckbox ? <NoVatApplicableCheckbox /> : null}
      <Description />
      <Divider sectionSeparator />
      <Location countries={countries} />
      {showMinimumWageInput ? <MinimumWage /> : null}
      {showVivaldisOfficeCodeInput ? (
        <VivaldisOfficeCode vivaldisOffices={vivaldisOffices} />
      ) : null}
    </>
  )
}
