import type { AvatarProps } from 'antd'
import { Avatar } from 'antd'
import { FC } from 'react'
import { getInitials } from './utils'

export interface UserAvatarProps extends AvatarProps {
  name: string | null
}

export const UserAvatar: FC<UserAvatarProps> = ({ name, ...props }) => {
  const initials = getInitials(name)
  return (
    <Avatar alt={name || initials} {...props}>
      {initials}
    </Avatar>
  )
}
