import { Typography } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const Title: FC = () => {
  const { t } = useTranslation('web_ui')

  return (
    <Typography.Title level={5} marginBottom={16}>
      {t('contract_schedule.schedule')}
    </Typography.Title>
  )
}
