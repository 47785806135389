import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const ShowSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 18.6055C8.56287 18.6045 5.42987 16.1615 3.56987 12.0525C5.42987 7.94451 8.56387 5.50151 11.9999 5.50051C15.4369 5.50151 18.5699 7.94451 20.4299 12.0525C18.5699 16.1615 15.4369 18.6045 11.9999 18.6055ZM11.9979 20.1055H12.0019H12.0029C16.1389 20.1025 19.8539 17.2035 21.9389 12.3485C22.0209 12.1595 22.0209 11.9455 21.9389 11.7565C19.8529 6.90251 16.1389 4.00351 12.0029 4.00051C12.0025 4.00009 12.0022 3.99985 12.0019 3.99978C12.0015 3.99968 12.001 3.99992 11.9999 4.00051C11.9995 4.00009 11.9992 3.99985 11.9989 3.99978C11.9985 3.99968 11.998 3.99992 11.9969 4.00051C7.86187 4.00351 4.14687 6.90251 2.06087 11.7565C1.97987 11.9455 1.97987 12.1595 2.06087 12.3485C4.14687 17.2035 7.86087 20.1025 11.9969 20.1055H11.9979ZM9.58862 12.0534C9.58862 10.7234 10.6696 9.64136 11.9996 9.64136C13.3296 9.64136 14.4116 10.7234 14.4116 12.0534C14.4116 13.3824 13.3296 14.4634 11.9996 14.4634C10.6696 14.4634 9.58862 13.3824 9.58862 12.0534ZM8.08862 12.0534C8.08862 14.2094 9.84262 15.9634 11.9996 15.9634C14.1566 15.9634 15.9116 14.2094 15.9116 12.0534C15.9116 9.89636 14.1566 8.14136 11.9996 8.14136C9.84262 8.14136 8.08862 9.89636 8.08862 12.0534Z"
    />
  </svg>
)

export const Show = forwardRef<HTMLSpanElement, AntdIconProps>((props, ref) => (
  <Icon component={ShowSvg} {...props} ref={ref} />
))
