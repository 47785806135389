import { PageInfo, DataWithPropertyData } from '../typings'

export const getPageInfo = <
  TPaginationProperty extends string = string,
  TData extends DataWithPropertyData<TPaginationProperty> = any
>(
  data: TData | null | undefined,
  propertyName: TPaginationProperty
): PageInfo => {
  if (data && data[propertyName] && data[propertyName]!.pageInfo) {
    return data[propertyName]!.pageInfo
  }
  return {
    endCursor: null,
    hasNextPage: false
  }
}
