import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

export const useSurveyTypeLabel = (surveyType: string) => {
  const [t] = useTranslation('web_ui')

  return useMemo(() => {
    return t(`survey_type.options.${surveyType}`, `${surveyType}`)
  }, [surveyType, t])
}
