export enum LogType {
  Error = 'error',
  Event = 'event',
  Screen = 'screen',
  Info = 'info',
  User = 'user'
}

const initialColors: { [key in LogType]: string } = {
  error: '#FF0000',
  event: '#00FF0A',
  screen: '#F900FF',
  info: '#FF8840',
  user: '#00FFE9'
}

export interface LoggingOptions {
  colors?: { [key in LogType]: string }
}

const context: { isLoggerEnabled: boolean; options: LoggingOptions } = {
  isLoggerEnabled: false,
  options: {
    colors: initialColors
  }
}

export function enableTrackingLogger(options?: LoggingOptions) {
  if (options) {
    context.options = {
      ...options
    }
  }
  context.isLoggerEnabled = true
}

export function disableTrackingLogger() {
  context.isLoggerEnabled = false
}

export function isTrackingLoggerEnabled(): boolean {
  return context.isLoggerEnabled
}

export const log = (type: LogType, data?: {}, info?: string | null) => {
  if (context.isLoggerEnabled) {
    const colors = context.options.colors!
    const message = `%c[TRACKING]%c[${type.toUpperCase()}] %c${info || ''}`
    const styles = [
      'color: #0085FF; font-weight: bold;',
      `${colors[type] ? `color: ${colors[type]}; ` : ''}font-weight: bold;`,
      'font-weight: normal;'
    ]
    /* tslint:disable:no-console */
    if (data) {
      console.groupCollapsed(message, ...styles)
      for (const prop in data) {
        if (Object.hasOwn(data, prop)) {
          console.log(
            `%c${prop}`,
            `color: gray; font-weight: bold;`,
            (data as any)[prop]
          )
        }
      }
      console.groupEnd()
    } else {
      console.log(message, ...styles)
    }
    /* tslint:enable:no-console */
  }
}
