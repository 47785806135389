// VPASS format is: '00,00 u', '02,75 u', '03,00 u', '06,00 u', '23,99 u'
export const numberValueToVpassDecimalHoursValue = (
  value: string | number | null
) => {
  if (value === null) {
    return '00.00 u'
  }
  if (typeof value === 'number') {
    return value.toFixed(2).toString().replace('.', ',').concat(' ', 'u')
  }
  return '00.00 u'
}
