import { isEmployeeContractReady } from '@vivaldis/common'
import { EmployeeFragment } from '@vivaldis/graphql'

export function isEmployeeReady(employee?: EmployeeFragment) {
  if (!employee || !employee.profile) return false
  if (!isEmployeeContractReady(employee)) return false

  const {
    firstName,
    intentAgreementUrl,
    lastName,
    mobile,
    isStudent,
    profile: {
      city,
      street,
      streetNumber,
      zip,
      country,
      countryOfBirth,
      nationalityCountry,
      placeOfBirth
    },
    studentAgreementUrl
  } = employee

  // intent agreement is required for all employees.
  // student agreement is only required for students
  const acceptedAgreement = isStudent
    ? intentAgreementUrl && studentAgreementUrl
    : intentAgreementUrl

  return !!(
    mobile &&
    //
    firstName &&
    lastName &&
    //
    placeOfBirth &&
    countryOfBirth &&
    nationalityCountry &&
    //
    city &&
    street &&
    streetNumber &&
    zip &&
    country &&
    //
    acceptedAgreement
  )
}
