import type { PositionFormStep3Values } from '../typings/PositionFormStep3Values'
import type { PositionPremium } from '../../../typings/Position'

export const positionPremiumsToPremiumsFormValues = (
  positionPremiums: PositionPremium[]
): PositionFormStep3Values => {
  return {
    premiumIds:
      positionPremiums.map(positionPremium => positionPremium.premium.id) || [],
    premiumValuesInput:
      positionPremiums.map(positionPremium => ({
        premiumId: positionPremium.premium.id,
        value: positionPremium.value,
        contributionValue: positionPremium.contributionValue,
        unit: positionPremium.unit
      })) || [],
    //
    useNettoPremiumComment: false,
    nettoPremiumComment: null,
    //
    useEcoChequeComment: null,
    ecoChequeComment: null
  }
}
