import styled from '@emotion/styled'
import { ButtonHTMLAttributes, FC, ReactNode, useMemo } from 'react'
import { LinkProps as LinkPropsBase } from 'react-router-dom'
import { useNavigateOnPress } from '../../hooks/useNavigateOnPress'

export interface NavigationBarActionButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactNode
  // navigation props
  to?: string
  replace?: LinkPropsBase['replace']
  state?: LinkPropsBase['state']
  openInSideSheet?: boolean
  goBackTo?: string | boolean
}

export const ActionButton: FC<NavigationBarActionButtonProps> = ({
  icon,
  // navigation props
  to,
  replace,
  state,
  openInSideSheet,
  goBackTo,
  onClick,
  // rest
  ...props
}) => {
  const onPress = useNavigateOnPress({
    to,
    replace,
    state,
    onPress: onClick,
    navigate: true,
    openInSideSheet,
    goBackTo
  })

  const navigationProps = useMemo(() => {
    if (to) {
      return {
        href: to,
        onClick: onPress
      }
    }
    return {
      onClick
    }
  }, [onClick, onPress, to])

  return <Button children={icon} {...props} {...navigationProps} />
}

const Button = styled('button')`
  appearance: none;
  border: none;
  display: flex;
  padding: 0;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: ${props => props.theme.primaryColor};
  font-size: 24px;
  background-color: transparent;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
  &:hover {
    transform: scale(1.03);
    filter: brightness(0.91);
  }
`
