import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const LogoutSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3192 22H6.43324C3.98924 22 2.00024 20.011 2.00024 17.565V6.436C2.00024 3.99 3.98924 2 6.43324 2H11.3082C13.7542 2 15.7442 3.99 15.7442 6.436V7.368C15.7442 7.782 15.4082 8.118 14.9942 8.118C14.5802 8.118 14.2442 7.782 14.2442 7.368V6.436C14.2442 4.816 12.9272 3.5 11.3082 3.5H6.43324C4.81624 3.5 3.50024 4.816 3.50024 6.436V17.565C3.50024 19.184 4.81624 20.5 6.43324 20.5H11.3192C12.9312 20.5 14.2442 19.188 14.2442 17.576V16.633C14.2442 16.219 14.5802 15.883 14.9942 15.883C15.4082 15.883 15.7442 16.219 15.7442 16.633V17.576C15.7442 20.016 13.7582 22 11.3192 22ZM19.9715 12.75H9.74609C9.33209 12.75 8.99609 12.414 8.99609 12C8.99609 11.586 9.33209 11.25 9.74609 11.25H19.9713L18.3304 9.61596C18.0374 9.32396 18.0354 8.84996 18.3284 8.55596C18.6204 8.26196 19.0944 8.26196 19.3884 8.55396L22.3063 11.459C22.3158 11.468 22.325 11.4774 22.334 11.4869C22.4649 11.6251 22.5374 11.8093 22.5374 12C22.5374 12.1859 22.4684 12.3657 22.3435 12.5028C22.3309 12.5167 22.3178 12.5301 22.3042 12.5431L19.3884 15.447C19.2424 15.593 19.0504 15.666 18.8594 15.666C18.6674 15.666 18.4744 15.593 18.3284 15.445C18.0364 15.151 18.0374 14.677 18.3304 14.385L19.9715 12.75Z"
    />
  </svg>
)

export const Logout = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={LogoutSvg} {...props} ref={ref} />
)
