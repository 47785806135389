import styled from '@emotion/styled'
import { Tabs as AntdTabs, TabsProps as AntdTabsProps } from 'antd'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { FC } from 'react'

export interface TabsProps extends AntdTabsProps {
  fullWidth?: boolean
}

const TabsComponent: FC<TabsProps> = ({ fullWidth, ...props }) => {
  return <StyledAntdTabs {...props} fullWidth={fullWidth} />
}

const StyledAntdTabs = styled(AntdTabs, {
  shouldForwardProp: props => !['fullWidth'].includes(String(props))
})<TabsProps>`
  // apply styles only for fullWidth === true
  ${props => (props.fullWidth ? '&' : '.not_full_width')} .ant-tabs-nav-list {
    display: flex;
    flex: 1;
  }
  ${props => (props.fullWidth ? '&' : '.not_full_width')} .ant-tabs-tab {
    display: flex;
    flex: 1;
    justify-content: center;
  }
`

export const Tabs = hoistNonReactStatics(TabsComponent, AntdTabs)
