import {
  createContext,
  useContext,
  useMemo,
  FC,
  PropsWithChildren
} from 'react'
import { useQuery, QueryResult } from '@apollo/client'
import { useUser } from '@vivaldis/session'
import { companyInformationQuery } from '../../graphql/queries/companyInformationQuery'
import {
  CompanyInformationQuery,
  CompanyInformationQuery_user_company_vivaldisOffice,
  CompanyInformationQueryVariables
} from '../../graphql/queries/__generated__/CompanyInformationQuery'

export interface CompanyInformationQueryContextValue
  extends QueryResult<
    CompanyInformationQuery,
    CompanyInformationQueryVariables
  > {}

export const CompanyInformationQueryContext = createContext<
  CompanyInformationQueryContextValue | undefined
>(undefined)

export function useCompanyInformationQuery(): CompanyInformationQueryContextValue {
  return useContext(CompanyInformationQueryContext)!
}

export function useCompanyHasTeam(): boolean {
  const result = useCompanyInformationQuery()

  return !!result?.data?.team?.edges?.length
}

export function useCompanyHasContracts(): boolean {
  const result = useCompanyInformationQuery()

  return !!result?.data?.lastContractDate
}

export function useCompanyHasSurveys(): boolean {
  const result = useCompanyInformationQuery()

  return !!result?.data?.companySurveys?.edges?.length
}

export function useCompanyHasPositions(): boolean {
  const result = useCompanyInformationQuery()

  return !!result?.data?.positions?.edges?.length
}

export function useVivaldisOffice():
  | CompanyInformationQuery_user_company_vivaldisOffice
  | undefined
  | null {
  const result = useCompanyInformationQuery()

  return result?.data?.user?.company?.vivaldisOffice
}

export const CompanyInformationQueryProvider: FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const user = useUser()
  const currentCompanyId = useMemo(() => user?.company?.id, [user?.company?.id])

  const result = useQuery<
    CompanyInformationQuery,
    CompanyInformationQueryVariables
  >(companyInformationQuery, {
    skip: !currentCompanyId, // TODO: use skipToken
    variables: {
      companyId: String(currentCompanyId)
    }
  })

  return (
    <CompanyInformationQueryContext.Provider value={result}>
      {children}
    </CompanyInformationQueryContext.Provider>
  )
}
