import { type Breakpoint, Grid, theme } from 'antd'
import { useMemo } from 'react'

const useAntdGridBreakpoint = Grid.useBreakpoint

export const useBreakpoint = (): Record<Breakpoint, boolean> => {
  const breakpoint = useAntdGridBreakpoint()
  const token = theme.useToken()

  const defaultBreakpoint = {
    xs: true,
    sm: window.innerWidth >= token.token.screenSM,
    md: window.innerWidth >= token.token.screenMD,
    lg: window.innerWidth >= token.token.screenLG,
    xl: window.innerWidth >= token.token.screenXL,
    xxl: window.innerWidth >= token.token.screenXXL
  }

  // of first render it is empty (all values = undefined) -> issue https://github.com/ant-design/ant-design/issues/32533
  return useMemo(
    () => ({
      xs: breakpoint.xs ?? defaultBreakpoint.xs,
      sm: breakpoint.sm ?? defaultBreakpoint.sm,
      md: breakpoint.md ?? defaultBreakpoint.md,
      lg: breakpoint.lg ?? defaultBreakpoint.lg,
      xl: breakpoint.xl ?? defaultBreakpoint.xl,
      xxl: breakpoint.xxl ?? defaultBreakpoint.xxl
    }),
    [
      breakpoint.lg,
      breakpoint.md,
      breakpoint.sm,
      breakpoint.xl,
      breakpoint.xs,
      breakpoint.xxl,
      defaultBreakpoint.lg,
      defaultBreakpoint.md,
      defaultBreakpoint.sm,
      defaultBreakpoint.xl,
      defaultBreakpoint.xs,
      defaultBreakpoint.xxl
    ]
  )
}
