import styled from '@emotion/styled'
import { FC } from 'react'
import { Divider } from '../../../Divider'
import { Offer } from '../../typings/Offer'
import { Committees } from './components/Committees'
import { FunctionCodes } from './components/FunctionCodes'

interface Props {
  offer: Offer
  type: 'selection' | 'payroll'
}

export const ExpandedRow: FC<Props> = ({ offer, type }) => {
  return (
    <Base>
      <Committees offer={offer} />
      <Divider
        type="vertical"
        style={{ height: 'auto', marginLeft: 32, marginRight: 32 }}
      />
      <FunctionCodes offer={offer} type={type} />
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${({ theme }) => theme.screenLG - 1}px) {
    flex-direction: column;
  }
`
