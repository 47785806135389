import { CSSProperties } from 'react'
import { TextStylePreset } from '../../typography/presets'
import { FontWeight } from './typings/FontWeight'

export const lineHeight = (lineHeight: number): any =>
  `line-height: ${lineHeight}px;`

export const letterSpacing = (letterSpacing: number) =>
  `letter-spacing: ${letterSpacing}px;`

export const fontSize = (fontSize: number) => `font-size: ${fontSize}px;`

export const fontWeight = (fontWeight?: FontWeight) => {
  switch (fontWeight) {
    case FontWeight.Regular:
      return `font-weight: 400;`
    case FontWeight.Semibold:
      return `font-weight: 600;`
  }
  return ''
}

export const textStylePreset = (preset?: TextStylePreset) => {
  switch (preset) {
    case TextStylePreset.Regular12:
      return text({
        fontSize: 12,
        fontWeight: FontWeight.Regular,
        lineHeight: 18
      })
    case TextStylePreset.Regular14:
      return text({
        fontSize: 14,
        fontWeight: FontWeight.Regular,
        lineHeight: 20
      })
    case TextStylePreset.Regular16:
      return text({
        fontSize: 16,
        fontWeight: FontWeight.Regular,
        lineHeight: 24
      })
    case TextStylePreset.Regular18:
      return text({
        fontSize: 18,
        fontWeight: FontWeight.Regular,
        lineHeight: 26
      })
    case TextStylePreset.Regular20:
      return text({
        fontSize: 20,
        fontWeight: FontWeight.Regular,
        lineHeight: 28
      })
    case TextStylePreset.Regular24:
      return text({
        fontSize: 24,
        fontWeight: FontWeight.Regular,
        lineHeight: 32
      })
    case TextStylePreset.Regular30:
      return text({
        fontSize: 30,
        fontWeight: FontWeight.Regular,
        lineHeight: 38
      })
    case TextStylePreset.Regular38:
      return text({
        fontSize: 38,
        fontWeight: FontWeight.Regular,
        lineHeight: 46
      })
    case TextStylePreset.Regular46:
      return text({
        fontSize: 46,
        fontWeight: FontWeight.Regular,
        lineHeight: 54
      })
    case TextStylePreset.Regular56:
      return text({
        fontSize: 56,
        fontWeight: FontWeight.Regular,
        lineHeight: 64
      })
    case TextStylePreset.Semibold12:
      return text({
        fontSize: 12,
        fontWeight: FontWeight.Semibold,
        lineHeight: 18
      })
    case TextStylePreset.Semibold14:
      return text({
        fontSize: 14,
        fontWeight: FontWeight.Semibold,
        lineHeight: 20
      })
    case TextStylePreset.Semibold16:
      return text({
        fontSize: 16,
        fontWeight: FontWeight.Semibold,
        lineHeight: 24
      })
    case TextStylePreset.Semibold18:
      return text({
        fontSize: 18,
        fontWeight: FontWeight.Semibold,
        lineHeight: 26
      })
    case TextStylePreset.Semibold20:
      return text({
        fontSize: 20,
        fontWeight: FontWeight.Semibold,
        lineHeight: 28
      })
    case TextStylePreset.Semibold24:
      return text({
        fontSize: 24,
        fontWeight: FontWeight.Semibold,
        lineHeight: 32
      })
    case TextStylePreset.Semibold30:
      return text({
        fontSize: 30,
        fontWeight: FontWeight.Semibold,
        lineHeight: 38
      })
    case TextStylePreset.Semibold38:
      return text({
        fontSize: 38,
        fontWeight: FontWeight.Semibold,
        lineHeight: 46
      })
    case TextStylePreset.Semibold46:
      return text({
        fontSize: 46,
        fontWeight: FontWeight.Semibold,
        lineHeight: 54
      })
    case TextStylePreset.Semibold56:
      return text({
        fontSize: 56,
        fontWeight: FontWeight.Semibold,
        lineHeight: 64
      })
  }
  return ''
}

export const textTransform = (transformation: TextOptions['textTransform']) =>
  `text-transform: ${transformation};`

export const textAlign = (textAlign: TextOptions['textAlign']) =>
  `text-align: ${textAlign};`

export const textDecoration = (textDecoration: TextOptions['textDecoration']) =>
  `text-decoration: ${textDecoration};`

export interface TextOptions {
  preset?: TextStylePreset
  //
  fontSize?: number
  fontWeight?: FontWeight
  lineHeight?: number
  letterSpacing?: number
  textTransform?: CSSProperties['textTransform']
  textAlign?: CSSProperties['textAlign']
  textDecoration?:
    | 'underline'
    | 'line-through'
    | 'underline line-through'
    | 'none'
}

export const text = ({
  preset,
  fontSize: fontSizeValue,
  fontWeight: fontWeightValue,
  lineHeight: lineHeightValue,
  letterSpacing: letterSpacingValue,
  textTransform: textTransformValue,
  textAlign: textAlignValue,
  textDecoration: textDecorationValue
}: TextOptions): string => `
${preset ? textStylePreset(preset) : ''}
${fontSizeValue ? fontSize(fontSizeValue) : ''}
${lineHeightValue ? lineHeight(lineHeightValue) : ''}
${fontWeightValue ? fontWeight(fontWeightValue) : ''}
${letterSpacingValue ? letterSpacing(letterSpacingValue) : ''}
${textTransformValue ? textTransform(textTransformValue) : ''}
${textAlignValue ? textAlign(textAlignValue) : ''}
${textDecorationValue ? textDecoration(textDecorationValue) : ''}
`
