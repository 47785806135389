import backend_error_messagesEN from '../BackendErrorMessages/i18n/en.json'
import backend_error_messagesFR from '../BackendErrorMessages/i18n/fr.json'
import backend_error_messagesNL from '../BackendErrorMessages/i18n/nl.json'

import promptEN from '../prompt/i18n/en.json'
import promptFR from '../prompt/i18n/fr.json'
import promptNL from '../prompt/i18n/nl.json'

export const resources = {
  en: {
    backend_error_messages: backend_error_messagesEN,
    brand_name: 'VivaConnect',
    prompt: promptEN
  },
  fr: {
    backend_error_messages: backend_error_messagesFR,
    brand_name: 'VivaConnect',
    prompt: promptFR
  },
  nl: {
    backend_error_messages: backend_error_messagesNL,
    brand_name: 'VivaConnect',
    prompt: promptNL
  }
} as const
