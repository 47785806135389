import * as actions from '@vivaldis/app'
import { UserType } from '@vivaldis/common'
import {
  init as initSession,
  SessionData,
  sessionSelector,
  update as updateSession
} from '@vivaldis/session'
import { put, select, takeEvery } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'

function* appRehydrated(
  action: ActionType<typeof actions.reduxPersistRehydrated>
) {
  const session: SessionData = yield select(sessionSelector)
  if (session && session.authentication_token) {
    yield put(
      updateSession({
        authentication_token: session.authentication_token,
        email: session.email,
        type: UserType.User
      })
    )
    yield put(initSession())
  }
}

export function* app() {
  yield takeEvery(actions.AppActionType.ReduxPersistRehydrated, appRehydrated)
}
