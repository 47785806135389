import { resources as commonTranslations } from '@vivaldis/common/src/i18n/resources'
import { resources as antdUITranslations } from '@vivaldis/antd-ui/src/i18n/resources'
import { resources as webUITranslations } from '@vivaldis/web-ui/src/i18n/resources'

import accept_terms_and_conditions_modalEN from '../components/AcceptTermsAndConditionsModal/i18n/en.json'
import accept_terms_and_conditions_modalFR from '../components/AcceptTermsAndConditionsModal/i18n/fr.json'
import accept_terms_and_conditions_modalNL from '../components/AcceptTermsAndConditionsModal/i18n/nl.json'

import company_status_warning_or_blockerEN from '../components/CompanyStatusWarningOrBlocker/i18n/en.json'
import company_status_warning_or_blockerFR from '../components/CompanyStatusWarningOrBlocker/i18n/fr.json'
import company_status_warning_or_blockerNL from '../components/CompanyStatusWarningOrBlocker/i18n/nl.json'

import employee_profile_alertEN from '../components/EmployeeProfileAlert/i18n/en.json'
import employee_profile_alertFR from '../components/EmployeeProfileAlert/i18n/fr.json'
import employee_profile_alertNL from '../components/EmployeeProfileAlert/i18n/nl.json'

import navigation_menuEN from '../components/NavigationMenu/i18n/en.json'
import navigation_menuFR from '../components/NavigationMenu/i18n/fr.json'
import navigation_menuNL from '../components/NavigationMenu/i18n/nl.json'

import no_rate_agreement_empty_stateTranslationsEN from '../components/NoRateAgreementEmptyState/i18n/en.json'
import no_rate_agreement_empty_stateTranslationsFR from '../components/NoRateAgreementEmptyState/i18n/fr.json'
import no_rate_agreement_empty_stateTranslationsNL from '../components/NoRateAgreementEmptyState/i18n/nl.json'

import add_userTranslationsEN from '../screens/Settings/screens/AddCompanyUser/i18n/en.json'
import add_userTranslationsFR from '../screens/Settings/screens/AddCompanyUser/i18n/fr.json'
import add_userTranslationsNL from '../screens/Settings/screens/AddCompanyUser/i18n/nl.json'

import companyTranslationsEN from '../screens/Settings/screens/Company/i18n/en.json'
import companyTranslationsFR from '../screens/Settings/screens/Company/i18n/fr.json'
import companyTranslationsNL from '../screens/Settings/screens/Company/i18n/nl.json'

import payroll_contract_listTranslationsEN from '../screens/Contracts/screens/PayrollContractList/i18n/en.json'
import payroll_contract_listTranslationsFR from '../screens/Contracts/screens/PayrollContractList/i18n/fr.json'
import payroll_contract_listTranslationsNL from '../screens/Contracts/screens/PayrollContractList/i18n/nl.json'

import selection_contract_listTranslationsEN from '../screens/Contracts/screens/SelectionContractList/i18n/en.json'
import selection_contract_listTranslationsFR from '../screens/Contracts/screens/SelectionContractList/i18n/fr.json'
import selection_contract_listTranslationsNL from '../screens/Contracts/screens/SelectionContractList/i18n/nl.json'

import create_contractTranslationsEN from '../screens/Contracts/screens/CreateContract/i18n/en.json'
import create_contractTranslationsFR from '../screens/Contracts/screens/CreateContract/i18n/fr.json'
import create_contractTranslationsNL from '../screens/Contracts/screens/CreateContract/i18n/nl.json'

import intercom_messengerTranslationsEN from '../app/IntercomMessenger/i18n/en.json'
import intercom_messengerTranslationsFR from '../app/IntercomMessenger/i18n/fr.json'
import intercom_messengerTranslationsNL from '../app/IntercomMessenger/i18n/nl.json'

import navigation_barTranslationsEN from '../app/DesktopLayout/i18n/en.json'
import navigation_barTranslationsFR from '../app/DesktopLayout/i18n/fr.json'
import navigation_barTranslationsNL from '../app/DesktopLayout/i18n/nl.json'

import create_work_obstructionTranslationsEN from '../screens/Contracts/screens/CreateWorkObstruction/i18n/en.json'
import create_work_obstructionTranslationsFR from '../screens/Contracts/screens/CreateWorkObstruction/i18n/fr.json'
import create_work_obstructionTranslationsNL from '../screens/Contracts/screens/CreateWorkObstruction/i18n/nl.json'

import request_selection_contract_extensionTranslationsEN from '../screens/Contracts/screens/RequestSelectionContractExtension/i18n/en.json'
import request_selection_contract_extensionTranslationsFR from '../screens/Contracts/screens/RequestSelectionContractExtension/i18n/fr.json'
import request_selection_contract_extensionTranslationsNL from '../screens/Contracts/screens/RequestSelectionContractExtension/i18n/nl.json'

import request_workday_cancellationTranslationsEN from '../screens/Contracts/screens/RequestWorkdayCancellation/i18n/en.json'
import request_workday_cancellationTranslationsFR from '../screens/Contracts/screens/RequestWorkdayCancellation/i18n/fr.json'
import request_workday_cancellationTranslationsNL from '../screens/Contracts/screens/RequestWorkdayCancellation/i18n/nl.json'

import view_contractTranslationsEN from '../screens/Contracts/screens/ViewContract/i18n/en.json'
import view_contractTranslationsFR from '../screens/Contracts/screens/ViewContract/i18n/fr.json'
import view_contractTranslationsNL from '../screens/Contracts/screens/ViewContract/i18n/nl.json'

import add_employee_certificateTranslationsEN from '../screens/Employee/screens/AddEmployeeCertificate/i18n/en.json'
import add_employee_certificateTranslationsFR from '../screens/Employee/screens/AddEmployeeCertificate/i18n/fr.json'
import add_employee_certificateTranslationsNL from '../screens/Employee/screens/AddEmployeeCertificate/i18n/nl.json'

import edit_employee_profileTranslationsEN from '../screens/Employee/screens/EditEmployeeProfile/i18n/en.json'
import edit_employee_profileTranslationsFR from '../screens/Employee/screens/EditEmployeeProfile/i18n/fr.json'
import edit_employee_profileTranslationsNL from '../screens/Employee/screens/EditEmployeeProfile/i18n/nl.json'

import edit_employee_certificateTranslationsEN from '../screens/Employee/screens/EditEmployeeCertificate/i18n/en.json'
import edit_employee_certificateTranslationsFR from '../screens/Employee/screens/EditEmployeeCertificate/i18n/fr.json'
import edit_employee_certificateTranslationsNL from '../screens/Employee/screens/EditEmployeeCertificate/i18n/nl.json'

import view_employee_profileTranslationsEN from '../screens/Employee/screens/ViewEmployeeProfile/i18n/en.json'
import view_employee_profileTranslationsFR from '../screens/Employee/screens/ViewEmployeeProfile/i18n/fr.json'
import view_employee_profileTranslationsNL from '../screens/Employee/screens/ViewEmployeeProfile/i18n/nl.json'

import faqTranslationsEN from '../screens/FAQ/i18n/en.json'
import faqTranslationsFR from '../screens/FAQ/i18n/fr.json'
import faqTranslationsNL from '../screens/FAQ/i18n/nl.json'

import contract_and_timesheet_detailsTranslationsEN from '../screens/HoursValidation/screens/ContractAndTimesheetDetails/i18n/en.json'
import contract_and_timesheet_detailsTranslationsFR from '../screens/HoursValidation/screens/ContractAndTimesheetDetails/i18n/fr.json'
import contract_and_timesheet_detailsTranslationsNL from '../screens/HoursValidation/screens/ContractAndTimesheetDetails/i18n/nl.json'

import download_timesheetsTranslationsEN from '../screens/HoursValidation/screens/DownloadTimesheets/i18n/en.json'
import download_timesheetsTranslationsFR from '../screens/HoursValidation/screens/DownloadTimesheets/i18n/fr.json'
import download_timesheetsTranslationsNL from '../screens/HoursValidation/screens/DownloadTimesheets/i18n/nl.json'

import timesheet_listTranslationsEN from '../screens/HoursValidation/screens/TimesheetList/i18n/en.json'
import timesheet_listTranslationsFR from '../screens/HoursValidation/screens/TimesheetList/i18n/fr.json'
import timesheet_listTranslationsNL from '../screens/HoursValidation/screens/TimesheetList/i18n/nl.json'

import invoice_listTranslationsEN from '../screens/Invoices/screens/InvoiceList/i18n/en.json'
import invoice_listTranslationsFR from '../screens/Invoices/screens/InvoiceList/i18n/fr.json'
import invoice_listTranslationsNL from '../screens/Invoices/screens/InvoiceList/i18n/nl.json'

import invite_to_payroll_teamTranslationsEN from '../screens/Team/screens/InviteToPayrollTeam/i18n/en.json'
import invite_to_payroll_teamTranslationsFR from '../screens/Team/screens/InviteToPayrollTeam/i18n/fr.json'
import invite_to_payroll_teamTranslationsNL from '../screens/Team/screens/InviteToPayrollTeam/i18n/nl.json'

import forgot_passwordTranslationsEN from '../screens/Auth/screens/ForgotPassword/i18n/en.json'
import forgot_passwordTranslationsFR from '../screens/Auth/screens/ForgotPassword/i18n/fr.json'
import forgot_passwordTranslationsNL from '../screens/Auth/screens/ForgotPassword/i18n/nl.json'

import loginTranslationsEN from '../screens/Auth/screens/Login/i18n/en.json'
import loginTranslationsFR from '../screens/Auth/screens/Login/i18n/fr.json'
import loginTranslationsNL from '../screens/Auth/screens/Login/i18n/nl.json'

import no_company_selectedTranslationsEN from '../screens/NoCompanySelected/i18n/en.json'
import no_company_selectedTranslationsFR from '../screens/NoCompanySelected/i18n/fr.json'
import no_company_selectedTranslationsNL from '../screens/NoCompanySelected/i18n/nl.json'

import not_foundTranslationsEN from '../screens/NotFound/i18n/en.json'
import not_foundTranslationsFR from '../screens/NotFound/i18n/fr.json'
import not_foundTranslationsNL from '../screens/NotFound/i18n/nl.json'

import onboardingTranslationsEN from '../screens/Onboarding/i18n/en.json'
import onboardingTranslationsFR from '../screens/Onboarding/i18n/fr.json'
import onboardingTranslationsNL from '../screens/Onboarding/i18n/nl.json'

import add_positionTranslationsEN from '../screens/Positions/screens/AddPosition/i18n/en.json'
import add_positionTranslationsFR from '../screens/Positions/screens/AddPosition/i18n/fr.json'
import add_positionTranslationsNL from '../screens/Positions/screens/AddPosition/i18n/nl.json'

import position_listTranslationsEN from '../screens/Positions/screens/PositionList/i18n/en.json'
import position_listTranslationsFR from '../screens/Positions/screens/PositionList/i18n/fr.json'
import position_listTranslationsNL from '../screens/Positions/screens/PositionList/i18n/nl.json'

import add_work_post_sheetTranslationsEN from '../screens/Positions/screens/AddWorkPostSheet/i18n/en.json'
import add_work_post_sheetTranslationsFR from '../screens/Positions/screens/AddWorkPostSheet/i18n/fr.json'
import add_work_post_sheetTranslationsNL from '../screens/Positions/screens/AddWorkPostSheet/i18n/nl.json'

import edit_positionTranslationsEN from '../screens/Positions/screens/EditPosition/i18n/en.json'
import edit_positionTranslationsFR from '../screens/Positions/screens/EditPosition/i18n/fr.json'
import edit_positionTranslationsNL from '../screens/Positions/screens/EditPosition/i18n/nl.json'

import edit_work_post_sheetTranslationsEN from '../screens/Positions/screens/EditWorkPostSheet/i18n/en.json'
import edit_work_post_sheetTranslationsFR from '../screens/Positions/screens/EditWorkPostSheet/i18n/fr.json'
import edit_work_post_sheetTranslationsNL from '../screens/Positions/screens/EditWorkPostSheet/i18n/nl.json'

import view_positionTranslationsEN from '../screens/Positions/screens/ViewPosition/i18n/en.json'
import view_positionTranslationsFR from '../screens/Positions/screens/ViewPosition/i18n/fr.json'
import view_positionTranslationsNL from '../screens/Positions/screens/ViewPosition/i18n/nl.json'

import view_work_post_sheetTranslationsEN from '../screens/Positions/screens/ViewWorkPostSheet/i18n/en.json'
import view_work_post_sheetTranslationsFR from '../screens/Positions/screens/ViewWorkPostSheet/i18n/fr.json'
import view_work_post_sheetTranslationsNL from '../screens/Positions/screens/ViewWorkPostSheet/i18n/nl.json'

import profileTranslationsEN from '../screens/Settings/screens/Profile/i18n/en.json'
import profileTranslationsFR from '../screens/Settings/screens/Profile/i18n/fr.json'
import profileTranslationsNL from '../screens/Settings/screens/Profile/i18n/nl.json'

import registrationTranslationsEN from '../screens/Auth/screens/Registration/i18n/en.json'
import registrationTranslationsFR from '../screens/Auth/screens/Registration/i18n/fr.json'
import registrationTranslationsNL from '../screens/Auth/screens/Registration/i18n/nl.json'

import reset_passwordTranslationsEN from '../screens/Auth/screens/ResetPassword/i18n/en.json'
import reset_passwordTranslationsFR from '../screens/Auth/screens/ResetPassword/i18n/fr.json'
import reset_passwordTranslationsNL from '../screens/Auth/screens/ResetPassword/i18n/nl.json'

import set_your_passwordTranslationsEN from '../screens/Auth/screens/SetYourPassword/i18n/en.json'
import set_your_passwordTranslationsFR from '../screens/Auth/screens/SetYourPassword/i18n/fr.json'
import set_your_passwordTranslationsNL from '../screens/Auth/screens/SetYourPassword/i18n/nl.json'

import settingsTranslationsEN from '../screens/Settings/screens/Settings/i18n/en.json'
import settingsTranslationsFR from '../screens/Settings/screens/Settings/i18n/fr.json'
import settingsTranslationsNL from '../screens/Settings/screens/Settings/i18n/nl.json'

import payroll_team_listTranslationsEN from '../screens/Team/screens/PayrollTeamList/i18n/en.json'
import payroll_team_listTranslationsFR from '../screens/Team/screens/PayrollTeamList/i18n/fr.json'
import payroll_team_listTranslationsNL from '../screens/Team/screens/PayrollTeamList/i18n/nl.json'

import selection_team_listTranslationsEN from '../screens/Team/screens/SelectionTeamList/i18n/en.json'
import selection_team_listTranslationsFR from '../screens/Team/screens/SelectionTeamList/i18n/fr.json'
import selection_team_listTranslationsNL from '../screens/Team/screens/SelectionTeamList/i18n/nl.json'

import view_company_userTranslationsEN from '../screens/Settings/screens/ViewCompanyUser/i18n/en.json'
import view_company_userTranslationsFR from '../screens/Settings/screens/ViewCompanyUser/i18n/fr.json'
import view_company_userTranslationsNL from '../screens/Settings/screens/ViewCompanyUser/i18n/nl.json'

import company_usersTranslationsEN from '../screens/Settings/screens/CompanyUsers/i18n/en.json'
import company_usersTranslationsFR from '../screens/Settings/screens/CompanyUsers/i18n/fr.json'
import company_usersTranslationsNL from '../screens/Settings/screens/CompanyUsers/i18n/nl.json'

import email_settingsTranslationsEN from '../screens/Settings/screens/EmailSettings/i18n/en.json'
import email_settingsTranslationsFR from '../screens/Settings/screens/EmailSettings/i18n/fr.json'
import email_settingsTranslationsNL from '../screens/Settings/screens/EmailSettings/i18n/nl.json'

import offersTranslationsEN from '../screens/Settings/screens/Offers/i18n/en.json'
import offersTranslationsFR from '../screens/Settings/screens/Offers/i18n/fr.json'
import offersTranslationsNL from '../screens/Settings/screens/Offers/i18n/nl.json'

import fill_surveyTranslationsEN from '../screens/Surveys/screens/FillSurvey/i18n/en.json'
import fill_surveyTranslationsFR from '../screens/Surveys/screens/FillSurvey/i18n/fr.json'
import fill_surveyTranslationsNL from '../screens/Surveys/screens/FillSurvey/i18n/nl.json'

import survey_listTranslationsEN from '../screens/Surveys/screens/SurveyList/i18n/en.json'
import survey_listTranslationsFR from '../screens/Surveys/screens/SurveyList/i18n/fr.json'
import survey_listTranslationsNL from '../screens/Surveys/screens/SurveyList/i18n/nl.json'

import view_surveyTranslationsEN from '../screens/Surveys/screens/ViewSurvey/i18n/en.json'
import view_surveyTranslationsFR from '../screens/Surveys/screens/ViewSurvey/i18n/fr.json'
import view_surveyTranslationsNL from '../screens/Surveys/screens/ViewSurvey/i18n/nl.json'

import vivaldis_payroll_promoTranslationsEN from '../screens/VivaldisPayrollPromo/i18n/en.json'
import vivaldis_payroll_promoTranslationsFR from '../screens/VivaldisPayrollPromo/i18n/fr.json'
import vivaldis_payroll_promoTranslationsNL from '../screens/VivaldisPayrollPromo/i18n/nl.json'

import vivaldis_selection_promoTranslationsEN from '../screens/VivaldisSelectionPromo/i18n/en.json'
import vivaldis_selection_promoTranslationsFR from '../screens/VivaldisSelectionPromo/i18n/fr.json'
import vivaldis_selection_promoTranslationsNL from '../screens/VivaldisSelectionPromo/i18n/nl.json'

import generalTranslationsEN from './en.json'
import generalTranslationsFR from './fr.json'
import generalTranslationsNL from './nl.json'

export const resources = {
  en: {
    // @vivaldis/antd-ui
    antd_ui: antdUITranslations.en,
    // @vivaldis/common
    common: commonTranslations.en,
    components: {
      accept_terms_and_conditions_modal: accept_terms_and_conditions_modalEN,
      company_status_warning_or_blocker: company_status_warning_or_blockerEN,
      employee_profile_alert: employee_profile_alertEN,
      navigation_menu: navigation_menuEN,
      no_rate_agreement_empty_state: no_rate_agreement_empty_stateTranslationsEN
    },
    intercom_messenger: intercom_messengerTranslationsEN,
    navigation_bar: navigation_barTranslationsEN,
    translation: {
      ...generalTranslationsEN,
      screens: {
        add_user: add_userTranslationsEN,
        company: companyTranslationsEN,
        payroll_contract_list: payroll_contract_listTranslationsEN,
        selection_contract_list: selection_contract_listTranslationsEN,
        create_contract: create_contractTranslationsEN,
        create_work_obstruction: create_work_obstructionTranslationsEN,
        view_contract: view_contractTranslationsEN,
        add_employee_certificate: add_employee_certificateTranslationsEN,
        edit_employee_profile: edit_employee_profileTranslationsEN,
        edit_employee_certificate: edit_employee_certificateTranslationsEN,
        view_employee_profile: view_employee_profileTranslationsEN,
        faq: faqTranslationsEN,
        hours_validation: {
          contract_and_timesheet_details:
            contract_and_timesheet_detailsTranslationsEN,
          download_timesheets: download_timesheetsTranslationsEN,
          timesheet_list: timesheet_listTranslationsEN
        },
        invoice_list: invoice_listTranslationsEN,
        invite_to_payroll_team: invite_to_payroll_teamTranslationsEN,
        forgot_password: forgot_passwordTranslationsEN,
        login: loginTranslationsEN,
        no_company_selected: no_company_selectedTranslationsEN,
        not_found: not_foundTranslationsEN,
        offers: offersTranslationsEN,
        onboarding: onboardingTranslationsEN,
        positions: {
          add_position: add_positionTranslationsEN,
          position_list: position_listTranslationsEN,
          add_work_post_sheet: add_work_post_sheetTranslationsEN,
          edit_position: edit_positionTranslationsEN,
          edit_work_post_sheet: edit_work_post_sheetTranslationsEN,
          view_position: view_positionTranslationsEN,
          view_work_post_sheet: view_work_post_sheetTranslationsEN
        },
        profile: profileTranslationsEN,
        registration: registrationTranslationsEN,
        request_selection_contract_extension:
          request_selection_contract_extensionTranslationsEN,
        request_workday_cancellation:
          request_workday_cancellationTranslationsEN,
        reset_password: reset_passwordTranslationsEN,
        set_your_password: set_your_passwordTranslationsEN,
        settings: settingsTranslationsEN,
        payroll_team_list: payroll_team_listTranslationsEN,
        selection_team_list: selection_team_listTranslationsEN,
        view_company_user: view_company_userTranslationsEN,
        company_users: company_usersTranslationsEN,
        email_settings: email_settingsTranslationsEN,
        fill_survey: fill_surveyTranslationsEN,
        survey_list: survey_listTranslationsEN,
        view_survey: view_surveyTranslationsEN,
        vivaldis_payroll_promo: vivaldis_payroll_promoTranslationsEN,
        vivaldis_selection_promo: vivaldis_selection_promoTranslationsEN
      }
    },
    // @vivaldis/web-ui
    web_ui: webUITranslations.en
  },
  fr: {
    // @vivaldis/antd-ui
    antd_ui: antdUITranslations.fr,
    // @vivaldis/common
    common: commonTranslations.fr,
    components: {
      accept_terms_and_conditions_modal: accept_terms_and_conditions_modalFR,
      company_status_warning_or_blocker: company_status_warning_or_blockerFR,
      employee_profile_alert: employee_profile_alertFR,
      navigation_menu: navigation_menuFR,
      no_rate_agreement_empty_state: no_rate_agreement_empty_stateTranslationsFR
    },
    intercom_messenger: intercom_messengerTranslationsFR,
    navigation_bar: navigation_barTranslationsFR,
    translation: {
      ...generalTranslationsFR,
      screens: {
        add_user: add_userTranslationsFR,
        company: companyTranslationsFR,
        payroll_contract_list: payroll_contract_listTranslationsFR,
        selection_contract_list: selection_contract_listTranslationsFR,
        create_contract: create_contractTranslationsFR,
        create_work_obstruction: create_work_obstructionTranslationsFR,
        view_contract: view_contractTranslationsFR,
        add_employee_certificate: add_employee_certificateTranslationsFR,
        edit_employee_profile: edit_employee_profileTranslationsFR,
        edit_employee_certificate: edit_employee_certificateTranslationsFR,
        view_employee_profile: view_employee_profileTranslationsFR,
        faq: faqTranslationsFR,
        hours_validation: {
          contract_and_timesheet_details:
            contract_and_timesheet_detailsTranslationsFR,
          download_timesheets: download_timesheetsTranslationsFR,
          timesheet_list: timesheet_listTranslationsFR
        },
        invoice_list: invoice_listTranslationsFR,
        invite_to_payroll_team: invite_to_payroll_teamTranslationsFR,
        forgot_password: forgot_passwordTranslationsFR,
        login: loginTranslationsFR,
        no_company_selected: no_company_selectedTranslationsFR,
        not_found: not_foundTranslationsFR,
        offers: offersTranslationsFR,
        onboarding: onboardingTranslationsFR,
        positions: {
          add_position: add_positionTranslationsFR,
          position_list: position_listTranslationsFR,
          add_work_post_sheet: add_work_post_sheetTranslationsFR,
          edit_position: edit_positionTranslationsFR,
          edit_work_post_sheet: edit_work_post_sheetTranslationsFR,
          view_position: view_positionTranslationsFR,
          view_work_post_sheet: view_work_post_sheetTranslationsFR
        },
        profile: profileTranslationsFR,
        registration: registrationTranslationsFR,
        request_selection_contract_extension:
          request_selection_contract_extensionTranslationsFR,
        request_workday_cancellation:
          request_workday_cancellationTranslationsFR,
        reset_password: reset_passwordTranslationsFR,
        set_your_password: set_your_passwordTranslationsFR,
        settings: settingsTranslationsFR,
        payroll_team_list: payroll_team_listTranslationsFR,
        selection_team_list: selection_team_listTranslationsFR,
        view_company_user: view_company_userTranslationsFR,
        company_users: company_usersTranslationsFR,
        email_settings: email_settingsTranslationsFR,
        fill_survey: fill_surveyTranslationsFR,
        survey_list: survey_listTranslationsFR,
        view_survey: view_surveyTranslationsFR,
        vivaldis_payroll_promo: vivaldis_payroll_promoTranslationsFR,
        vivaldis_selection_promo: vivaldis_selection_promoTranslationsFR
      }
    },
    // @vivaldis/web-ui
    web_ui: webUITranslations.fr
  },
  nl: {
    // @vivaldis/antd-ui
    antd_ui: antdUITranslations.nl,
    // @vivaldis/common
    common: commonTranslations.nl,
    components: {
      accept_terms_and_conditions_modal: accept_terms_and_conditions_modalNL,
      company_status_warning_or_blocker: company_status_warning_or_blockerNL,
      employee_profile_alert: employee_profile_alertNL,
      navigation_menu: navigation_menuNL,
      no_rate_agreement_empty_state: no_rate_agreement_empty_stateTranslationsNL
    },
    intercom_messenger: intercom_messengerTranslationsNL,
    navigation_bar: navigation_barTranslationsNL,
    translation: {
      ...generalTranslationsNL,
      screens: {
        add_user: add_userTranslationsNL,
        company: companyTranslationsNL,
        payroll_contract_list: payroll_contract_listTranslationsNL,
        selection_contract_list: selection_contract_listTranslationsNL,
        create_contract: create_contractTranslationsNL,
        create_work_obstruction: create_work_obstructionTranslationsNL,
        view_contract: view_contractTranslationsNL,
        add_employee_certificate: add_employee_certificateTranslationsNL,
        edit_employee_profile: edit_employee_profileTranslationsNL,
        edit_employee_certificate: edit_employee_certificateTranslationsNL,
        view_employee_profile: view_employee_profileTranslationsNL,
        faq: faqTranslationsNL,
        hours_validation: {
          contract_and_timesheet_details:
            contract_and_timesheet_detailsTranslationsNL,
          download_timesheets: download_timesheetsTranslationsNL,
          timesheet_list: timesheet_listTranslationsNL
        },
        invoice_list: invoice_listTranslationsNL,
        invite_to_payroll_team: invite_to_payroll_teamTranslationsNL,
        forgot_password: forgot_passwordTranslationsNL,
        login: loginTranslationsNL,
        no_company_selected: no_company_selectedTranslationsNL,
        not_found: not_foundTranslationsNL,
        offers: offersTranslationsNL,
        onboarding: onboardingTranslationsNL,
        positions: {
          add_position: add_positionTranslationsNL,
          position_list: position_listTranslationsNL,
          add_work_post_sheet: add_work_post_sheetTranslationsNL,
          edit_position: edit_positionTranslationsNL,
          edit_work_post_sheet: edit_work_post_sheetTranslationsNL,
          view_position: view_positionTranslationsNL,
          view_work_post_sheet: view_work_post_sheetTranslationsNL
        },
        profile: profileTranslationsNL,
        registration: registrationTranslationsNL,
        request_selection_contract_extension:
          request_selection_contract_extensionTranslationsNL,
        request_workday_cancellation:
          request_workday_cancellationTranslationsNL,
        reset_password: reset_passwordTranslationsNL,
        set_your_password: set_your_passwordTranslationsNL,
        settings: settingsTranslationsNL,
        payroll_team_list: payroll_team_listTranslationsNL,
        selection_team_list: selection_team_listTranslationsNL,
        view_company_user: view_company_userTranslationsNL,
        company_users: company_usersTranslationsNL,
        email_settings: email_settingsTranslationsNL,
        fill_survey: fill_surveyTranslationsNL,
        survey_list: survey_listTranslationsNL,
        view_survey: view_surveyTranslationsNL,
        vivaldis_payroll_promo: vivaldis_payroll_promoTranslationsNL,
        vivaldis_selection_promo: vivaldis_selection_promoTranslationsNL
      }
    },
    // @vivaldis/web-ui
    web_ui: webUITranslations.nl
  }
} as const
