import { FC } from 'react'
import { useTheme } from '@vivaldis/ui'

interface IntercomGlyphProps {
  color?: string
  extraColor?: string
  size?: number
}
const IntercomGlyph: FC<IntercomGlyphProps> = ({
  color,
  extraColor,
  size = 20
}) => {
  const theme = useTheme()
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-10 -12)">
          <g transform="translate(10 12)">
            <rect width={size} height={size} fill={extraColor || theme.white} />
            <path
              d="m16.6 10.896c-0.0035598 0.32884-0.27115 0.59355-0.60001 0.59355s-0.59645-0.26471-0.60001-0.59355v-5.3963c0.0035599-0.32884 0.27115-0.59355 0.60001-0.59355s0.59645 0.26471 0.60001 0.59355v5.3963zm-0.20939 3.7556c-0.092742 0.079383-2.3154 1.9445-6.3906 1.9445-4.0752 0-6.2978-1.8652-6.3905-1.9446-0.25155-0.2157-0.28066-0.59446-0.065039-0.84607 0.21514-0.25118 0.593-0.28081 0.84466-0.066234 0.035227 0.029531 2.02 1.6568 5.6109 1.6568 3.636 0 5.5901-1.639 5.6094-1.6555 0.25109-0.21565 0.63056-0.18668 0.84607 0.065039 0.2157 0.25156 0.18664 0.63035-0.064898 0.84607zm-12.991-9.1519c0.0035599-0.32884 0.27115-0.59355 0.60001-0.59355s0.59645 0.26471 0.60001 0.59355v5.3963c-0.0035598 0.32884-0.27115 0.59355-0.60001 0.59355s-0.59645-0.26471-0.60001-0.59355v-5.3963zm3.0001-1.2c0.0050183-0.32778 0.27216-0.59086 0.59998-0.59086 0.32782 0 0.59496 0.26308 0.59998 0.59086v8.0157c-0.0050183 0.32778-0.27216 0.59086-0.59998 0.59086-0.32782 0-0.59496-0.26308-0.59998-0.59086v-8.0156-7.031e-5zm3-0.30361c-1.864e-5 -0.15913 0.063187-0.31175 0.17571-0.42427 0.11252-0.11252 0.26514-0.17571 0.42427-0.17571 0.15914 0 0.31178 0.06316 0.42431 0.17568 0.11254 0.11252 0.17575 0.26515 0.17573 0.42429v8.7c-0.0035599 0.32884-0.27115 0.59355-0.60001 0.59355-0.32886 0-0.59645-0.26471-0.60001-0.59355v-8.7zm3 0.30361c0.0050183-0.32778 0.27216-0.59086 0.59998-0.59086 0.32782 0 0.59496 0.26308 0.59998 0.59086v8.0157c-0.0050183 0.32778-0.27216 0.59086-0.59998 0.59086-0.32782 0-0.59496-0.26308-0.59998-0.59086v-8.0156-7.031e-5zm4.35-3.3h-13.5c-1.2426 0-2.25 1.0074-2.25 2.25v13.5c0 1.2426 1.0074 2.25 2.25 2.25h13.5c1.2426 0 2.25-1.0074 2.25-2.25v-13.5c0-1.2426-1.0074-2.25-2.25-2.25z"
              fill={color || theme.primaryColor}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IntercomGlyph
