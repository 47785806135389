import { App as AntdApp } from 'antd'
import { useMemo } from 'react'

export type { AppProps } from 'antd'

const useAntdApp = AntdApp.useApp

const DEFAULT_NOTIFICATION_DURATION = 6
const DEFAULT_ERROR_NOTIFICATION_DURATION = 12

export const App = Object.assign(AntdApp, {
  useApp: () => {
    const res = useAntdApp()
    return useMemo(
      () => ({
        ...res,
        notification: {
          ...res.notification,
          success: (config: Parameters<typeof res.notification.success>[0]) => {
            return res.notification.success({
              duration: DEFAULT_NOTIFICATION_DURATION,
              ...config
            })
          },
          error: (config: Parameters<typeof res.notification.error>[0]) => {
            return res.notification.error({
              duration: DEFAULT_ERROR_NOTIFICATION_DURATION,
              ...config
            })
          },
          info: (config: Parameters<typeof res.notification.info>[0]) => {
            return res.notification.info({
              duration: DEFAULT_NOTIFICATION_DURATION,
              ...config
            })
          },
          warning: (config: Parameters<typeof res.notification.warning>[0]) => {
            return res.notification.warning({
              duration: DEFAULT_NOTIFICATION_DURATION,
              ...config
            })
          },
          open: (config: Parameters<typeof res.notification.open>[0]) => {
            return res.notification.open({
              duration:
                config?.type === 'error'
                  ? DEFAULT_ERROR_NOTIFICATION_DURATION
                  : DEFAULT_NOTIFICATION_DURATION,
              ...config
            })
          }
        }
      }),
      [res]
    )
  }
})
