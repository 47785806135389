import { doesEmployeeNeedBIC } from './doesEmployeeNeedBIC'
import { doesEmployeeNeedResidencePermit } from './doesEmployeeNeedResidencePermit'
import { doesEmployeeNeedWorkPermit } from './doesEmployeeNeedWorkPermit'
import { doesEmployeeNeedIdCard } from './doesEmployeeNeedIdCard'
import type { Date as GraphqlDateType } from '@vivaldis/graphql'
import { parseISO, isValid, isBefore, endOfDay, startOfDay } from 'date-fns'

interface Country {
  eeaMember: boolean
}

export interface EmployeeWorkProfile {
  bankAccount: string | null
  bic: string | null
  // residence permit
  residencePermitBackPicture: string | null
  residencePermitCode: string | null
  residencePermitExpiresOn: GraphqlDateType | null
  residencePermitFrontPicture: string | null
  // work permit
  workPermitBackPicture: string | null
  workPermitExpiresOn: GraphqlDateType | null
  workPermitFrontPicture: string | null
}

export interface EmployeeProfile {
  birthdate: string | null
  buildingNumber: string | null
  city: string | null
  country: Country | null
  countryOfBirth: Country | null
  idCardBackPicture: string | null
  idCardExpiresOn: GraphqlDateType | null
  idCardFrontPicture: string | null
  idCardNumber: string | null
  nationalityCountry: Country | null
  street: string | null
  streetNumber: string | null
  zip: string | null
}

interface Employee {
  gender: GraphqlDateType | null
  profile: EmployeeProfile | null
  ssn: string | null
  workProfile: EmployeeWorkProfile | null
}

export function isEmployeeContractReady(employee: Employee): boolean {
  if (!employee.ssn) return false
  if (!employee.gender) return false
  if (!employee.profile) return false
  if (!employee.profile.birthdate) return false
  if (!employee.profile.countryOfBirth) return false
  if (!employee.profile.nationalityCountry) return false
  if (!employee.profile.street) return false
  if (!employee.profile.streetNumber) return false
  if (!employee.profile.zip) return false
  if (!employee.profile.city) return false
  if (!employee.profile.country) return false
  if (!employee.profile) return false

  if (!employee.workProfile) return false
  if (!employee.workProfile.bankAccount) return false
  if (doesEmployeeNeedBIC(employee.workProfile.bankAccount)) {
    if (!employee.workProfile.bic) return false
  }

  if (doesEmployeeNeedIdCard(employee.profile)) {
    if (!employee.profile.idCardNumber) return false
    if (!employee.profile.idCardBackPicture) return false
    if (!employee.profile.idCardFrontPicture) return false
    // should be a valid date in a future
    if (isExpired(employee.profile.idCardExpiresOn)) return false
  }

  if (doesEmployeeNeedResidencePermit(employee.profile)) {
    if (!employee.workProfile.residencePermitCode) return false
    if (!employee.workProfile.residencePermitBackPicture) return false
    if (!employee.workProfile.residencePermitFrontPicture) return false
    // should be a valid date in a future
    if (isExpired(employee.workProfile.residencePermitExpiresOn)) return false
  }

  if (doesEmployeeNeedWorkPermit(employee.profile)) {
    if (!employee.workProfile.workPermitBackPicture) return false
    if (!employee.workProfile.workPermitFrontPicture) return false
    // should be a valid date in a future
    if (isExpired(employee.workProfile.workPermitExpiresOn)) return false
  }

  return true
}

export function doesEmployeeHaveExpiredContractReadyInformation(
  employee: Employee
): boolean {
  if (!employee.profile) return false
  if (isEmployeeContractReady(employee)) return false

  if (doesEmployeeNeedIdCard(employee.profile)) {
    if (!employee.profile.idCardExpiresOn) return false
    if (isExpired(employee.profile.idCardExpiresOn)) return true
  }

  if (!employee.workProfile) return false

  if (doesEmployeeNeedResidencePermit(employee.profile)) {
    if (!employee.workProfile.residencePermitExpiresOn) return false
    if (isExpired(employee.workProfile.residencePermitExpiresOn)) return true
  }

  if (doesEmployeeNeedWorkPermit(employee.profile)) {
    if (!employee.workProfile.workPermitExpiresOn) return false
    if (isExpired(employee.workProfile.workPermitExpiresOn)) return true
  }

  return false
}

const isExpired = (expiresAt: GraphqlDateType | null) => {
  if (!expiresAt) {
    return true
  }

  const expiresAtDate = parseISO(expiresAt)
  if (!isValid(expiresAtDate)) {
    return true
  }

  return isBefore(endOfDay(expiresAtDate), startOfDay(new Date()))
}
