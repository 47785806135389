import {
  getDay,
  getYear,
  getMonth,
  getDate,
  endOfMonth,
  getHours,
  getMinutes,
  getSeconds,
  addYears,
  addMonths,
  addDays,
  setYear,
  setMonth,
  setDate,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  isAfter,
  isValid,
  getWeek,
  startOfWeek,
  format as formatDate,
  parse as parseDate,
  Locale as LocaleType
} from 'date-fns'
import type { GenerateConfig } from 'rc-picker/lib/generate'
import { getDateFnsLocale } from '@vivaldis/common'

const getDateFnsLocaleFromLocaleName = (localeName: string): LocaleType => {
  // transform "en_US" to the "enUS" and then to the "en"
  const shortLocaleName = localeName.replace(/_/g, '').slice(0, 2)
  return getDateFnsLocale(shortLocaleName)
}

const localeParse = (format: string) => {
  return format
    .replace(/Y/g, 'y')
    .replace(/D/g, 'd')
    .replace(/gggg/, 'yyyy')
    .replace(/g/g, 'G')
    .replace(/([Ww])o/g, 'wo')
}

// based on this example: https://github.com/react-component/picker/blob/master/src/generate/dateFns.ts
const generateConfig: GenerateConfig<Date> = {
  // get
  getNow: () => new Date(),
  getFixedDate: string => new Date(string),
  getEndDate: date => endOfMonth(date),
  getWeekDay: date => getDay(date),
  getYear: date => getYear(date),
  getMonth: date => getMonth(date),
  getDate: date => getDate(date),
  getHour: date => getHours(date),
  getMinute: date => getMinutes(date),
  getSecond: date => getSeconds(date),
  getMillisecond: date => date.getMilliseconds(),

  // set
  addYear: (date, diff) => addYears(date, diff),
  addMonth: (date, diff) => addMonths(date, diff),
  addDate: (date, diff) => addDays(date, diff),
  setYear: (date, year) => setYear(date, year),
  setMonth: (date, month) => setMonth(date, month),
  setDate: (date, num) => setDate(date, num),
  setHour: (date, hour) => setHours(date, hour),
  setMinute: (date, minute) => setMinutes(date, minute),
  setSecond: (date, second) => setSeconds(date, second),
  setMillisecond: (date, millisecond) => setMilliseconds(date, millisecond),

  // Compare
  isAfter: (date1, date2) => isAfter(date1, date2),
  isValidate: date => isValid(date),

  locale: {
    getWeekFirstDay: locale => {
      const dateFnsLocale = getDateFnsLocaleFromLocaleName(locale)
      return dateFnsLocale.options?.weekStartsOn || 0
    },
    getWeekFirstDate: (locale, date) => {
      return startOfWeek(date, {
        locale: getDateFnsLocaleFromLocaleName(locale)
      })
    },
    getWeek: (locale, date) => {
      return getWeek(date, { locale: getDateFnsLocaleFromLocaleName(locale) })
    },
    getShortWeekDays: locale => {
      const clone = getDateFnsLocaleFromLocaleName(locale)
      return Array.from({ length: 7 }).map((_, i) =>
        clone.localize?.day(i as any, { width: 'short' })
      )
    },
    getShortMonths: locale => {
      const clone = getDateFnsLocaleFromLocaleName(locale)
      return Array.from({ length: 12 }).map((_, i) =>
        clone.localize?.month(i as any, { width: 'abbreviated' })
      )
    },
    format: (locale, date, format) => {
      if (!isValid(date)) {
        // by some reason typings in "rc-picker" describe it as string type, but in some cases use `null`
        return null as unknown as string
      }
      return formatDate(date, localeParse(format), {
        locale: getDateFnsLocaleFromLocaleName(locale)
      })
    },
    parse: (locale, text, formats) => {
      for (let i = 0; i < formats.length; i += 1) {
        const format = localeParse(formats[i])

        // it's needed to improve parsing of timepicker manual text input (when user enters only 3 numbers our of 4)
        if (format === 'HH:mm' && text.length !== 'HH:mm'.length) {
          return null
        }

        // when user type 20011991 instead of 20-01-1991 we want to parse it and handle properly
        if (
          format === 'dd-MM-yyyy' &&
          !text.includes('-') &&
          text.length === 'ddMMyyyy'.length
        ) {
          const [d1, d2, M1, M2, y1, y2, y3, y4] = text.split('')
          text = `${d1}${d2}-${M1}${M2}-${y1}${y2}${y3}${y4}`
        }
        // it's needed to improve parsing of date pickers manual text input (when user enters value using keyboard)
        if (format === 'dd-MM-yyyy' && text.length !== 'dd-MM-yyyy'.length) {
          return null
        }

        const date = parseDate(text, format, new Date(), {
          locale: getDateFnsLocaleFromLocaleName(locale)
        })
        if (isValid(date)) {
          return date
        }
      }
      return null
    }
  }
}

export default generateConfig
