import { getI18n } from 'react-i18next'

function getTranslationKeyForBackendMessage(errorMessage: string): string {
  return `backend_error_messages.${errorMessage}`
}

export const tBackendErrorMessage = (
  error: Error | unknown,
  customFallbackMessage?: string
) => {
  const defaultFallbackMessage = getI18n().t(
    'backend_error_messages.unknown_error',
    { ns: 'common' }
  )
  const fallbackMessage: string =
    customFallbackMessage || defaultFallbackMessage

  if (error instanceof Error) {
    return getI18n().t(
      // translated error message (if exist)
      getTranslationKeyForBackendMessage(error.message),
      // fallback for missing error message
      fallbackMessage,
      {
        ns: 'common',
        nsSeparator: '☈' // We want to allow the use of the ":" symbol in translation keys, so we use rare UTF char instead to prevent conflicts
      }
    )
  }
  return fallbackMessage
}
