import styled from '@emotion/styled'
import { Badge as AntdBadge } from 'antd'
import hoistNonReactStatics from 'hoist-non-react-statics'
export type { BadgeProps } from 'antd'

const StyledAntdBadge = styled(AntdBadge)`
  & .ant-badge-count {
    font-weight: 600;
  }
`

export const Badge = hoistNonReactStatics(StyledAntdBadge, AntdBadge)
