import { ChangeEvent, createContext, useContext } from 'react'

export interface AccordionContextValue {
  expanded: boolean
  disabled: boolean
  toggle: (event: ChangeEvent<unknown>) => void
}

export const AccordionContext = createContext<AccordionContextValue>({
  expanded: false,
  disabled: false,
  toggle: event => {}
})

export const useAccordionContext = () => {
  return useContext(AccordionContext)
}

if (process.env.NODE_ENV !== 'production') {
  AccordionContext.displayName = 'AccordionContext'
}
