import contract_reasonTranslationsEN from '../components/ContractReason/i18n/en.json'
import contract_reasonTranslationsFR from '../components/ContractReason/i18n/fr.json'
import contract_reasonTranslationsNL from '../components/ContractReason/i18n/nl.json'

import contract_scheduleTranslationsEN from '../components/ContractSchedule/i18n/en.json'
import contract_scheduleTranslationsFR from '../components/ContractSchedule/i18n/fr.json'
import contract_scheduleTranslationsNL from '../components/ContractSchedule/i18n/nl.json'

import contract_typeTranslationsEN from '../components/ContractType/i18n/en.json'
import contract_typeTranslationsFR from '../components/ContractType/i18n/fr.json'
import contract_typeTranslationsNL from '../components/ContractType/i18n/nl.json'

import drawerTranslationsEN from '../components/Drawer/i18n/en.json'
import drawerTranslationsFR from '../components/Drawer/i18n/fr.json'
import drawerTranslationsNL from '../components/Drawer/i18n/nl.json'

import edit_employee_profile_formTranslationsEN from '../components/EditEmployeeProfileForm/i18n/en.json'
import edit_employee_profile_formTranslationsFR from '../components/EditEmployeeProfileForm/i18n/fr.json'
import edit_employee_profile_formTranslationsNL from '../components/EditEmployeeProfileForm/i18n/nl.json'

import employee_certificate_formTranslationsEN from '../components/EmployeeCertificateForm/i18n/en.json'
import employee_certificate_formTranslationsFR from '../components/EmployeeCertificateForm/i18n/fr.json'
import employee_certificate_formTranslationsNL from '../components/EmployeeCertificateForm/i18n/nl.json'

import employee_profileTranslationsEN from '../components/EmployeeProfile/i18n/en.json'
import employee_profileTranslationsFR from '../components/EmployeeProfile/i18n/fr.json'
import employee_profileTranslationsNL from '../components/EmployeeProfile/i18n/nl.json'

import navigation_barTranslationsEN from '../components/NavigationBar/i18n/en.json'
import navigation_barTranslationsFR from '../components/NavigationBar/i18n/fr.json'
import navigation_barTranslationsNL from '../components/NavigationBar/i18n/nl.json'

import offers_table_viewTranslationsEN from '../components/OffersTableView/i18n/en.json'
import offers_table_viewTranslationsFR from '../components/OffersTableView/i18n/fr.json'
import offers_table_viewTranslationsNL from '../components/OffersTableView/i18n/nl.json'

import premium_typeTranslationsEN from '../components/PremiumType/i18n/en.json'
import premium_typeTranslationsFR from '../components/PremiumType/i18n/fr.json'
import premium_typeTranslationsNL from '../components/PremiumType/i18n/nl.json'

import prevention_servicesTranslationsEN from '../components/PreventionService/i18n/en.json'
import prevention_servicesTranslationsFR from '../components/PreventionService/i18n/fr.json'
import prevention_servicesTranslationsNL from '../components/PreventionService/i18n/nl.json'

import position_formTranslationsEN from '../components/PositionForm/i18n/en.json'
import position_formTranslationsFR from '../components/PositionForm/i18n/fr.json'
import position_formTranslationsNL from '../components/PositionForm/i18n/nl.json'

import screenTranslationsEN from '../components/Screen/i18n/en.json'
import screenTranslationsFR from '../components/Screen/i18n/fr.json'
import screenTranslationsNL from '../components/Screen/i18n/nl.json'

import search_empty_stateTranslationsEN from '../components/Search/SearchEmptyState/i18n/en.json'
import search_empty_stateTranslationsFR from '../components/Search/SearchEmptyState/i18n/fr.json'
import search_empty_stateTranslationsNL from '../components/Search/SearchEmptyState/i18n/nl.json'

import statuteTranslationsEN from '../components/Statute/i18n/en.json'
import statuteTranslationsFR from '../components/Statute/i18n/fr.json'
import statuteTranslationsNL from '../components/Statute/i18n/nl.json'

import survey_formTranslationsEN from '../components/SurveyForm/i18n/en.json'
import survey_formTranslationsFR from '../components/SurveyForm/i18n/fr.json'
import survey_formTranslationsNL from '../components/SurveyForm/i18n/nl.json'

import survey_typeTranslationsEN from '../components/SurveyType/i18n/en.json'
import survey_typeTranslationsFR from '../components/SurveyType/i18n/fr.json'
import survey_typeTranslationsNL from '../components/SurveyType/i18n/nl.json'

import unknown_error_pageTranslationsEN from '../components/UnknownErrorPage/i18n/en.json'
import unknown_error_pageTranslationsFR from '../components/UnknownErrorPage/i18n/fr.json'
import unknown_error_pageTranslationsNL from '../components/UnknownErrorPage/i18n/nl.json'

import view_contract_screen_contentTranslationsEN from '../components/ViewContractScreenContent/i18n/en.json'
import view_contract_screen_contentTranslationsFR from '../components/ViewContractScreenContent/i18n/fr.json'
import view_contract_screen_contentTranslationsNL from '../components/ViewContractScreenContent/i18n/nl.json'

import view_position_screen_contentTranslationsEN from '../components/ViewPositionScreenContent/i18n/en.json'
import view_position_screen_contentTranslationsFR from '../components/ViewPositionScreenContent/i18n/fr.json'
import view_position_screen_contentTranslationsNL from '../components/ViewPositionScreenContent/i18n/nl.json'

import view_survey_screen_contentTranslationsEN from '../components/ViewSurveyScreenContent/i18n/en.json'
import view_survey_screen_contentTranslationsFR from '../components/ViewSurveyScreenContent/i18n/fr.json'
import view_survey_screen_contentTranslationsNL from '../components/ViewSurveyScreenContent/i18n/nl.json'

import work_post_sheet_formTranslationsEN from '../components/WorkPostSheetForm/i18n/en.json'
import work_post_sheet_formTranslationsFR from '../components/WorkPostSheetForm/i18n/fr.json'
import work_post_sheet_formTranslationsNL from '../components/WorkPostSheetForm/i18n/nl.json'

export const resources = {
  en: {
    boCommercialProfileOptions: {
      disableForStudent: 'Candidate is not a student',
      isNotActive: 'Not active'
    },
    contract_reason: contract_reasonTranslationsEN,
    contract_schedule: contract_scheduleTranslationsEN,
    contract_type: contract_typeTranslationsEN,
    drawer: drawerTranslationsEN,
    edit_employee_profile_form: edit_employee_profile_formTranslationsEN,
    employee_certificate_form: employee_certificate_formTranslationsEN,
    employee_profile: employee_profileTranslationsEN,
    form: {
      required: 'Required',
      optional: 'Optional'
    },
    navigation_bar: navigation_barTranslationsEN,
    offers_table_view: offers_table_viewTranslationsEN,
    premium_type: premium_typeTranslationsEN,
    prevention_services: prevention_servicesTranslationsEN,
    position_form: position_formTranslationsEN,
    screen: screenTranslationsEN,
    search_empty_state: search_empty_stateTranslationsEN,
    statute: statuteTranslationsEN,
    survey_form: survey_formTranslationsEN,
    survey_type: survey_typeTranslationsEN,
    unknown_error_page: unknown_error_pageTranslationsEN,
    view_contract_screen_content: view_contract_screen_contentTranslationsEN,
    view_position_screen_content: view_position_screen_contentTranslationsEN,
    view_survey_screen_content: view_survey_screen_contentTranslationsEN,
    work_post_sheet_form: work_post_sheet_formTranslationsEN,
    yes: 'Yes',
    no: 'No'
  },
  fr: {
    boCommercialProfileOptions: {
      disableForStudent: "Le candidat n'est pas étudiant",
      isNotActive: 'Pas actif'
    },
    contract_reason: contract_reasonTranslationsFR,
    contract_schedule: contract_scheduleTranslationsFR,
    contract_type: contract_typeTranslationsFR,
    drawer: drawerTranslationsFR,
    edit_employee_profile_form: edit_employee_profile_formTranslationsFR,
    employee_certificate_form: employee_certificate_formTranslationsFR,
    employee_profile: employee_profileTranslationsFR,
    form: {
      required: 'Obligatoire',
      optional: 'Optionnel'
    },
    navigation_bar: navigation_barTranslationsFR,
    offers_table_view: offers_table_viewTranslationsFR,
    premium_type: premium_typeTranslationsFR,
    prevention_services: prevention_servicesTranslationsFR,
    position_form: position_formTranslationsFR,
    screen: screenTranslationsFR,
    search_empty_state: search_empty_stateTranslationsFR,
    statute: statuteTranslationsFR,
    survey_form: survey_formTranslationsFR,
    survey_type: survey_typeTranslationsFR,
    unknown_error_page: unknown_error_pageTranslationsFR,
    view_contract_screen_content: view_contract_screen_contentTranslationsFR,
    view_position_screen_content: view_position_screen_contentTranslationsFR,
    view_survey_screen_content: view_survey_screen_contentTranslationsFR,
    work_post_sheet_form: work_post_sheet_formTranslationsFR,
    yes: 'Oui',
    no: 'Non'
  },
  nl: {
    boCommercialProfileOptions: {
      disableForStudent: 'Kandidaat is geen student',
      isNotActive: 'Niet actief'
    },
    contract_reason: contract_reasonTranslationsNL,
    contract_schedule: contract_scheduleTranslationsNL,
    contract_type: contract_typeTranslationsNL,
    drawer: drawerTranslationsNL,
    edit_employee_profile_form: edit_employee_profile_formTranslationsNL,
    employee_certificate_form: employee_certificate_formTranslationsNL,
    employee_profile: employee_profileTranslationsNL,
    form: {
      required: 'Vereist',
      optional: 'Optioneel'
    },
    navigation_bar: navigation_barTranslationsNL,
    offers_table_view: offers_table_viewTranslationsNL,
    premium_type: premium_typeTranslationsNL,
    prevention_services: prevention_servicesTranslationsNL,
    position_form: position_formTranslationsNL,
    screen: screenTranslationsNL,
    search_empty_state: search_empty_stateTranslationsNL,
    statute: statuteTranslationsNL,
    survey_form: survey_formTranslationsNL,
    survey_type: survey_typeTranslationsNL,
    unknown_error_page: unknown_error_pageTranslationsNL,
    view_contract_screen_content: view_contract_screen_contentTranslationsNL,
    view_position_screen_content: view_position_screen_contentTranslationsNL,
    view_survey_screen_content: view_survey_screen_contentTranslationsNL,
    work_post_sheet_form: work_post_sheet_formTranslationsNL,
    yes: 'Ja',
    no: 'Nee'
  }
} as const
