import styled from '@emotion/styled'
import { ComponentType, FC, PropsWithChildren } from 'react'
import { CaptionAlt } from '../../../Typography'
import { MarginProps, margin } from '../../../styles/margin'
import { padding, PaddingProps } from '../../../styles/padding'

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 22px;
  padding: 1px 12px 2px 12px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  background-color: ${(props: ContainerProps) => props.containerFillColor};
  border-color: ${(props: ContainerProps) => props.borderColor};
  ${props => margin(props)}
  ${props => padding(props)}
`

type ContainerProps = {
  containerFillColor: string
  borderColor: string
} & MarginProps &
  PaddingProps

interface LabelProps {
  LabelComponent?: ComponentType<any>
}

export type InformativeTagProps = LabelProps & ContainerProps

export const InformativeTag: FC<PropsWithChildren<InformativeTagProps>> = ({
  children,
  LabelComponent = CaptionAlt,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <LabelComponent>{children}</LabelComponent>
    </Container>
  )
}
