import { ComponentType, FC, ReactNode, useContext } from 'react'
import { GlyphProps, useTheme } from '@vivaldis/ui'
import styled from '@emotion/styled'
import { ListViewItemContext } from '../ListViewItemContext'
import { BodyAlt, Caption, TextProps } from '../../../Typography'
import { createElement } from '../../../utils/createElement'

interface Props {
  firstRowText: string
  FirstRowTextComponent?: ComponentType<TextProps>
  FirstRowBaseComponent?: ComponentType | ReactNode
  FirstRowGlyphComponent?: ComponentType<GlyphProps>
  firstRowGlyphProps?: GlyphProps
  // second row
  secondRowText: string | ReactNode
  SecondRowTextComponent?: ComponentType<TextProps>
  SecondRowBaseComponent?: ComponentType | ReactNode
  SecondRowGlyphComponent?: ComponentType<GlyphProps> | ReactNode
  secondRowGlyphProps?: GlyphProps
}

export const TwoRowsItem: FC<Props> = ({
  firstRowText,
  FirstRowTextComponent = BodyAlt,
  FirstRowBaseComponent = FirstRowBase,
  FirstRowGlyphComponent,
  firstRowGlyphProps,
  // second row
  secondRowText,
  SecondRowTextComponent = Caption,
  SecondRowBaseComponent = SecondRowBase,
  SecondRowGlyphComponent,
  secondRowGlyphProps
}) => {
  const context = useContext(ListViewItemContext)
  const theme = useTheme()
  return (
    <Base>
      {createElement(
        FirstRowBaseComponent,
        {},
        <>
          {createElement(FirstRowGlyphComponent, {
            size: 18,
            ...firstRowGlyphProps
          })}
          {createElement(
            FirstRowTextComponent,
            {
              color: context.selected ? theme.primaryColor : undefined
            },
            firstRowText
          )}
        </>
      )}
      {createElement(
        SecondRowBaseComponent,
        {},
        <>
          {createElement(SecondRowGlyphComponent, {
            size: 18,
            color: theme.gray7,
            ...secondRowGlyphProps
          })}
          {createElement(
            SecondRowTextComponent,
            {
              color: theme.gray7
            },
            secondRowText
          )}
        </>
      )}
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 38px;
`

const FirstRowBase = styled('div')`
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  margin-bottom: 4px;
`

const SecondRowBase = styled('div')`
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
`
