import { FC, useMemo } from 'react'
import { TextStylePreset, Typography } from '@vivaldis/antd-ui'
import type { Employee } from './typings/Employee'

interface Props {
  employee: Employee
}

export const EmployeeNameTableColumn: FC<Props> = ({ employee }) => {
  const employeeName = useMemo(
    () => `${employee.firstName} ${employee.lastName}`,
    [employee.firstName, employee.lastName]
  )

  return (
    <Typography.Text preset={TextStylePreset.Semibold14}>
      {employeeName}
    </Typography.Text>
  )
}
