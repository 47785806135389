import styled from '@emotion/styled'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, Radio, Space } from '@vivaldis/antd-ui'
import { Link } from '../../../../../../../Link'
import { useIsApprovedPosition } from '../../../../../../providers/PositionProvider'
import { useForm } from '../../../../providers/FormProvider'

export const NettoPremiumComment: FC = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation()

  const isApprovedPosition = useIsApprovedPosition()

  const { values, setFieldValue } = useForm()

  const handleSetNotUseNettoPremiumComment = useCallback(() => {
    setFieldValue('nettoPremiumComment', null)
  }, [setFieldValue])

  const nettoPremiumCommentExplanationURL = useMemo(() => {
    switch (language) {
      case 'nl': {
        return 'https://www.socialsecurity.be/employer/instructions/dmfa/nl/latest/instructions/salary/particularcases/expensesreimbursement.html'
      }
      case 'fr': {
        return 'https://www.socialsecurity.be/employer/instructions/dmfa/fr/latest/instructions/salary/particularcases/expensesreimbursement.html'
      }
      default:
        return 'https://www.socialsecurity.be/employer/instructions/dmfa/nl/latest/instructions/salary/particularcases/expensesreimbursement.html'
    }
  }, [language])

  return (
    <>
      <Space
        direction="horizontal"
        style={{ width: '100%', justifyContent: 'space-between' }}
      >
        <Form.Item
          name="useNettoPremiumComment"
          required
          label={t('position_form.step3.netto_premium_comment.label', {
            ns: 'web_ui'
          })}
          style={{
            marginBottom: values.useNettoPremiumComment ? 6 : undefined
          }}
        >
          <Radio.Group
            name="useNettoPremiumComment"
            disabled={isApprovedPosition}
          >
            <Radio value={true} name="useNettoPremiumComment">
              {t('position_form.step3.netto_premium_comment.options.yes', {
                ns: 'web_ui'
              })}
            </Radio>
            <Radio
              value={false}
              name="useNettoPremiumComment"
              onChange={handleSetNotUseNettoPremiumComment}
            >
              {t('position_form.step3.netto_premium_comment.options.no', {
                ns: 'web_ui'
              })}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <LinkToNettoPremiumCommentExplanation
          to={nettoPremiumCommentExplanationURL}
          target="_blank"
        >
          {t('position_form.step3.netto_premium_comment.link_to_explanation', {
            ns: 'web_ui'
          })}
        </LinkToNettoPremiumCommentExplanation>
      </Space>
      {values.useNettoPremiumComment ? (
        <Form.Item required name="nettoPremiumComment">
          <Input.TextArea
            name="nettoPremiumComment"
            placeholder={t('form.required')}
            rows={2}
            disabled={isApprovedPosition}
          />
        </Form.Item>
      ) : null}
    </>
  )
}

const LinkToNettoPremiumCommentExplanation = styled(Link)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
`
