import IBAN from 'iban'
import { parseISO, isValid, isAfter, endOfDay, startOfToday } from 'date-fns'
import { isValidSSN } from './getSsnInfo'

const isDateInTheFuture = (date: string | Date): boolean => {
  if (!date) {
    return false
  }

  const dateValue = date instanceof Date ? date : parseISO(date)
  if (!isValid(dateValue)) {
    return false
  }
  return isAfter(endOfDay(dateValue), startOfToday())
}

const isValidBelgiumIdCardNumber = (value: string) => {
  // 12 digits in the form xxx-xxxxxxx-yy
  const onlyNumbers = value.replace(/\D+/g, '')
  if (onlyNumbers.length !== 12) {
    return false
  }

  // we want to allow use '000-0000000-00' as a valid ID card number to bypass the card number validation
  if (onlyNumbers === '000-0000000-00'.replace(/\D+/g, '')) {
    return true
  }

  // The check-number yy is the remainder of the division of xxxxxxxxxx by 97.
  const xxxxxxxxxx = Number(onlyNumbers.slice(0, 10))
  const yy = Number(onlyNumbers.slice(-2))

  const remainder = xxxxxxxxxx % 97
  // Note that if the calculated remainder is equal to 0, the check-number yy is reset to 97.
  if (remainder === 0) {
    return yy === 97
  }

  return remainder === yy
}

const isValidEmail = (value: string): boolean => {
  // use validation from register
  const RegexEmail = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/
  return !!value.match(RegexEmail)
}

const REQUIRED_PASSWORD_LENGTH = 8

const isValidPassword = (value: string): boolean => {
  return value.trim().length >= REQUIRED_PASSWORD_LENGTH
}

export default {
  REQUIRED_PASSWORD_LENGTH,
  isDateInTheFuture,
  isValidBelgiumIdCardNumber,
  isValidEmail,
  isValidIBANNumber: IBAN.isValid,
  isValidPassword,
  isValidSSN
}
