import { Dropdown } from '@vivaldis/antd-ui'
import { ArrowDown2 } from '@vivaldis/icons'
import { useUser } from '@vivaldis/session'
import { tracking } from '@vivaldis/tracking'
import { useCallback, FC, useMemo } from 'react'
import { Drawer as DrawerBase } from '@vivaldis/web-ui'
import styled from '@emotion/styled'
import { selectCompanyMutation } from '../../../../../graphql/mutations/selectCompanyMutation'

export const DrawerFooterWithCompanySelection: FC = () => {
  const user = useUser()

  // we show this only when user has multiple companies
  const permissions = useMemo(
    () => user?.permissions || [],
    [user?.permissions]
  )

  const handleMenuItemClick = useCallback(
    async (item: any) => {
      const { key } = item

      if (key !== user?.company?.id) {
        try {
          await selectCompanyMutation({
            companyId: String(key)
          })
        } catch (error) {
          tracking.error(error)
        }
      }
    },
    [user?.company?.id]
  )

  const menuItems = useMemo(
    () =>
      permissions.map(permission => ({
        key: permission.companyId,
        label: permission.companyName
      })),
    [permissions]
  )

  return (
    <Dropdown
      menu={{
        onClick: handleMenuItemClick,
        items: menuItems
      }}
      placement="top"
      trigger={['click']}
    >
      <Base>
        <DrawerBase.FooterBar.Company
          name={String(user?.company?.name)}
          source={user?.company?.logo}
        />
        <ArrowDown2 style={{ color: 'gray', fontSize: 24 }} />
      </Base>
    </Dropdown>
  )
}

const Base = styled('div')`
  display: flex;
  justify-content: space-between;
`
