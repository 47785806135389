import {
  ComponentType,
  CSSProperties,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode
} from 'react'
import { LinkProps } from 'react-router-dom'
import { PaddingProps } from '../../styles/padding'
import { FlexProps } from '../../styles/flex'
import { createElement } from '../../utils/createElement'
import { Container, ContainerProps } from './components/Container'
import {
  ActionableContentContainer,
  ContentContainer,
  ContentContainerProps
} from './components/ContentContainer'
import { Link } from './components/Link'
import { ListViewItemContext } from './ListViewItemContext'
import { TwoRowsItem } from './LeftContent/TwoRowsItem'

export type ListViewItemProps = {
  ContainerComponent?: ReactNode | ComponentType<ContainerProps>
  BeforeContent?: ReactNode | ComponentType<any>
  AfterContent?: ReactNode | ComponentType<any>
  ContentContainerComponent?: ReactNode | ComponentType<ContentContainerProps>
  LeftContentComponent?: ReactNode | ComponentType<any>
  RightContentComponent?: ReactNode | ComponentType<any>
  selected?: boolean
  onClick?: MouseEventHandler<any>
  contentContainerStyle?: CSSProperties
  containerProps?: any
  to?: LinkProps['to']
  replace?: LinkProps['replace']
  state?: LinkProps['state']
  openInSideSheet?: boolean
  goBackTo?: string | boolean
} & PaddingProps &
  FlexProps

const BaseListViewItem: FC<PropsWithChildren<ListViewItemProps>> = ({
  selected,
  BeforeContent,
  AfterContent,
  ContainerComponent = Container,
  ContentContainerComponent = '__DEFAULT__',
  LeftContentComponent,
  RightContentComponent,
  to,
  replace,
  state,
  openInSideSheet,
  goBackTo,
  onClick,
  containerProps,
  contentContainerStyle,
  ...styleProps
}) => {
  let content = null
  if (to || onClick) {
    if (ContentContainerComponent === '__DEFAULT__') {
      ContentContainerComponent = ActionableContentContainer
    }
    content = createElement(
      ContentContainerComponent,
      {
        as: to ? Link : 'button',
        onClick,
        selected,
        to,
        replace,
        state,
        openInSideSheet,
        goBackTo,
        style: contentContainerStyle,
        ...styleProps
      },
      createElement(LeftContentComponent),
      createElement(RightContentComponent)
    )
  } else {
    if (ContentContainerComponent === '__DEFAULT__') {
      ContentContainerComponent = ContentContainer
    }
    content = createElement(
      ContentContainerComponent,
      { as: 'div', selected, style: contentContainerStyle, ...styleProps },
      createElement(LeftContentComponent),
      createElement(RightContentComponent)
    )
  }

  return createElement(
    ContainerComponent,
    containerProps,
    <ListViewItemContext.Provider value={{ selected: !!selected }}>
      {createElement(BeforeContent)}
      {content}
      {createElement(AfterContent)}
    </ListViewItemContext.Provider>
  )
}

export const ListViewItem = Object.assign(BaseListViewItem, {
  LeftContent: {
    TwoRowsItem
  }
})
