import { ServerParseError } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getReduxStore } from '../../redux'

export const logoutLink = (invalidate: () => any) =>
  onError(({ networkError }) => {
    if (networkError && (networkError as ServerParseError).statusCode === 401) {
      getReduxStore().dispatch(invalidate())
    }
  })
