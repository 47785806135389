import { Typography } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '../../../../../Divider'
import { Information } from '../../../../../Information'
import { Offer } from '../../../../typings/Offer'
import { ActualCoefficient } from './components/ActualCoefficient'
import { PayrollCoefficient } from './components/PayrollCoefficient'

interface Props {
  offer: Offer
  type: 'selection' | 'payroll'
}

export const FunctionCodes: FC<Props> = ({ offer, type }) => {
  const { t } = useTranslation('web_ui')

  return (
    <div style={{ flex: 1 }}>
      <Typography.Title level={5} style={{ marginBottom: 24 }}>
        {t('offers_table_view.function_codes.title')}
      </Typography.Title>
      {offer.functionCodes.map(functionCode => (
        <div
          key={`${functionCode.id} - ${functionCode.code}`}
          style={{ marginBottom: 32 }}
        >
          <Information>
            <Information.InformationField
              label={t(
                'offers_table_view.function_codes.fields.code_and_name.label'
              )}
              smallDivider
            >
              {functionCode.code} - {functionCode.name}
            </Information.InformationField>

            {type === 'payroll' ? (
              <PayrollCoefficient functionCode={functionCode} />
            ) : null}

            {type === 'selection' ? (
              <ActualCoefficient functionCode={functionCode} />
            ) : null}
          </Information>
          <Divider sectionSeparator />
        </div>
      ))}
    </div>
  )
}
