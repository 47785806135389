import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

interface ContractDatum {
  isConstruct: boolean
}

export const useContractTypeLabel = (
  contractDatum: ContractDatum | null | undefined
) => {
  const [t] = useTranslation('web_ui')

  return useMemo(() => {
    if (!contractDatum) {
      return null
    }

    return contractDatum.isConstruct
      ? t('contract_type.construction')
      : t('contract_type.interim')
  }, [contractDatum, t])
}
