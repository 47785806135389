import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsInSideSheet } from '../../SideSheets/hooks/useIsInSideSheet'
import { useSideSheetsNavigator } from '../../SideSheets/hooks/useSideSheetsNavigator'
import { useMainNavigate } from '../../MainNavigate/hooks/useMainNavigate'
import { useGoBackTo } from './useGoBackTo'

export function useGoBack(): (path?: any) => void {
  const goBackTo = useGoBackTo()
  const isInSideSheet = useIsInSideSheet()
  const { back } = useSideSheetsNavigator()
  const navigate = useNavigate()
  const mainNavigate = useMainNavigate()

  return useCallback(
    (path?: string) => {
      const finalPath = typeof path === 'string' ? path : goBackTo
      if (isInSideSheet) {
        back()
        // in case when we want to close side sheet panel and navigate to some specific url in main navigation
        if (path) mainNavigate(path)
      } else if (finalPath) {
        navigate(finalPath)
      }
    },
    [back, goBackTo, isInSideSheet, mainNavigate, navigate]
  )
}
