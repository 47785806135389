import { CachePersistor } from 'apollo3-cache-persist'
import {
  ApolloClient,
  ApolloClientOptions,
  InMemoryCache,
  ApolloLink
} from '@apollo/client'
import type { InMemoryCacheConfig } from '@apollo/client'
import { errorLink } from './links/errorLink'
import { loggerLink } from './links/loggerLink'
import { authLink } from './links/authLink'
import { httpLink } from './links/httpLink'
import { logoutLink } from './links/logoutLink'
import { Tracking } from '../typings/Tracking'
import { UserType } from '../typings/UserType'

export const ASYNC_STORE_KEY = '@@ApolloCache_v3'

const context: {
  apolloClient: ApolloClient<any> | undefined
  apolloCachePersistor: CachePersistor<any> | undefined
} = {
  apolloClient: undefined,
  apolloCachePersistor: undefined
}

export interface CreateApolloClientOptions {
  storage: any
  sessionSelector: (state: any) => any
  tracking: Tracking
  userType: UserType
  apiUrl: string
  appVersion: string
  invalidate: () => any
  apolloClientOptions?: Partial<ApolloClientOptions<any>>
  apolloInMemoryCacheConfig?: Partial<InMemoryCacheConfig>
}

export function createApolloClient<TCacheShape = any>({
  storage,
  sessionSelector,
  tracking,
  apiUrl,
  appVersion,
  userType,
  invalidate,
  apolloClientOptions,
  apolloInMemoryCacheConfig
}: CreateApolloClientOptions): {
  apolloClient: ApolloClient<TCacheShape>
  apolloCachePersistor: CachePersistor<any>
} {
  const cache = new InMemoryCache({
    ...apolloInMemoryCacheConfig
  })

  const apolloCachePersistor = new CachePersistor({
    cache,
    key: ASYNC_STORE_KEY,
    storage
  })

  const apolloClient = new ApolloClient<TCacheShape>({
    ...apolloClientOptions,
    link: ApolloLink.from([
      errorLink(tracking),
      logoutLink(invalidate),
      loggerLink,
      authLink(sessionSelector, userType),
      httpLink(apiUrl, appVersion, userType)
    ]),
    cache: cache as any
  })

  context.apolloCachePersistor = apolloCachePersistor
  context.apolloClient = apolloClient

  return {
    apolloCachePersistor,
    apolloClient
  }
}

export function getApolloClient<
  TCacheShape = any
>(): ApolloClient<TCacheShape> {
  return context.apolloClient!
}

export function getApolloCachePersistor(): CachePersistor<any> {
  return context.apolloCachePersistor!
}

export const clearApolloCache = (): Promise<void> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (context.apolloClient && context.apolloCachePersistor) {
      try {
        const unlisten = context.apolloClient.onClearStore(async () => {
          unlisten()
          await context.apolloCachePersistor!.purge()
          resolve()
        })
        await context.apolloClient.clearStore()
        await context.apolloClient.resetStore()
      } catch (err) {
        reject(err)
      }
    } else {
      reject()
    }
  })
}
