import { getI18n } from 'react-i18next'
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
const mapboxBaseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places'

// by default search only by address, to force user enter accurate place
// proximity=4.4,51.2 -> Antwerp
// Search only by countries where we provide service:
//  - Belgium (99,9%) - BE
//  - The netherlands (0,08%) - NL
//  - France (0.01%) - FR
//  - Germany  (0.01%) - DE
const buildUrl = (
  query: string,
  extraQuery = '&proximity=4.4,51.2&types=address&limit=5&country=BE,NL,FR,DE'
) => {
  const language = getI18n().language
  const strippedSemicolonsFromQuery = query.replace(/;/g, '') || ' ' // we use space instead of empty string
  const encodedQuery = encodeURIComponent(strippedSemicolonsFromQuery)
  // NOTE: There is a bug in the mapbox api: it filters periods ('.') by itself, so if you're searching for, say, "Mechelsesteenweg 271 Bus 1.1" it will actually search on their servers for "Mechelsesteenweg 271 Bus 11" ¯\_(ツ)_/¯

  return `${mapboxBaseUrl}/${encodedQuery}.json?access_token=${MAPBOX_TOKEN}&language=${language}${extraQuery}`
}

export interface MapboxPlace {
  id: string
  name: string // full text (label)
  latitude: number
  longitude: number
  street: string
  streetNumber: string
  zip: string
  city: string
  country: string
}

const cache: { [key: string]: MapboxPlace[] } = {}

export const findPlaces = async (query: string): Promise<MapboxPlace[]> => {
  const url = buildUrl(query)
  if (cache[url]) {
    return cache[url]
  }
  const res = await fetch(url, {
    headers: { 'Content-Type': 'application/json' }
  })
  if (!res.ok) throw Error(res.statusText)
  const json = await res.json()

  const places: MapboxPlace[] = json.features.map(formatMapboxFeature)
  cache[url] = places
  return places
}

const formatMapboxFeature = (feature: any): MapboxPlace => {
  const { id, center, place_name: placeName, text, context } = feature
  const [longitude, latitude] = center
  const street = text
  const streetNumber = getStreetNumber(placeName, street)
  const zip = getZip(context)
  const city = getCity(context)
  const country = getCountryName(context)

  return {
    city,
    country,
    id,
    latitude,
    longitude,
    name: placeName,
    street,
    streetNumber,
    zip
  }
}

const getCountryName = (context: any[]): string => {
  const country = context.find(el => findElementByPathOfId(el, 'country'))
  if (country && country.text) {
    return country.text
  }
  return ''
}

const getZip = (context: any[]): string => {
  const postcodeObj = context.find(el => findElementByPathOfId(el, 'postcode'))
  if (postcodeObj && postcodeObj.text) {
    return postcodeObj.text
  }
  return ''
}

const getCity = (context: any[]): string => {
  const placeObj = context.find(el => findElementByPathOfId(el, 'place'))
  const regionObj = context.find(el => findElementByPathOfId(el, 'region'))
  const localityObj = context.find(el => findElementByPathOfId(el, 'locality'))
  if (placeObj && placeObj.text) {
    return placeObj.text
  }
  if (regionObj && regionObj.text) {
    return regionObj.text
  }
  if (localityObj && localityObj.text) {
    return localityObj.text
  }

  return ''
}

const getStreetNumber = (placeName: string, streetName: string): string => {
  if (placeName === streetName) {
    return ''
  }

  const streetNameWithNumber = placeName
    .split(',')
    .find(name => name.concat(streetName))

  if (!streetNameWithNumber || streetNameWithNumber === streetName) {
    return ''
  }

  const streetNumber = streetNameWithNumber.split(' ').pop()
  return streetNumber || ''
}

const findElementByPathOfId = (element: any, path: string) => {
  return element && element.id && element.id.startsWith(path)
}
