import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import {
  ActionCableChannel,
  subscribeToChannel,
  unsubscribeFromChannel
} from '../actions'

export const useSuperUserDashboardSubscription = (companyId: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      subscribeToChannel(ActionCableChannel.SuperUserDashboard, {
        company_id: companyId
      })
    )
    return () => {
      dispatch(
        unsubscribeFromChannel(ActionCableChannel.SuperUserDashboard, {
          company_id: companyId
        })
      )
    }
  }, [companyId, dispatch])
}
