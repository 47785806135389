import { OptGroup } from './OptGroup'
import { Option } from './Option'
import { Select as BaseSelect } from './Select'
export type { RefSelectProps, SelectProps } from './Select'

// should we do it with hoist-non-react-statics ?
export const Select = Object.assign(BaseSelect, {
  OptGroup,
  Option
})
