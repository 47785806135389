import { useBreakpoint } from '@vivaldis/antd-ui'
import { FC } from 'react'
import {
  useMaxActiveSideSheetsWidth,
  useHasSideSheets,
  ZIndex
} from '@vivaldis/web-ui'
import { animated, useSpring } from 'react-spring'
import styled from '@emotion/styled'
import { useUser } from '@vivaldis/session'
import { useIsIntercomMessengerVisible } from './providers/IntercomMessengerProvider'
import { OpenIntercomButton } from './components/OpenIntercomButton'

export const IntercomMessenger: FC = () => {
  const hasSideSheets = useHasSideSheets()
  const breakpoint = useBreakpoint()
  const visible = useIsIntercomMessengerVisible()

  const activeSideSheetsWidth = useMaxActiveSideSheetsWidth()

  const user = useUser()

  const contentInsetRight =
    hasSideSheets && breakpoint.lg ? activeSideSheetsWidth + 32 : 0

  const [animatedStyles] = useSpring(
    () => ({
      opacity: visible ? 1 : 0,
      xy: [contentInsetRight, visible ? 0 : 50]
    }),
    [visible, contentInsetRight]
  )

  if (!user?.company) {
    return null
  }

  return (
    <Container
      style={{
        opacity: animatedStyles.opacity,
        transform: animatedStyles.xy.to(
          (x, y) => `translateX(${x * -1}px) translateY(${y}px)`
        )
      }}
    >
      <OpenIntercomButton user={user} />
    </Container>
  )
}

const Container = styled(animated.div)`
  position: fixed;
  z-index: ${ZIndex.BottomChat};
  bottom: 0;
  right: 0;
  pointer-events: none;
`
