import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const MenuSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4 6.9C4 6.40294 4.40294 6 4.9 6H19.1C19.5971 6 20 6.40294 20 6.9C20 7.39706 19.5971 7.8 19.1 7.8H4.9C4.40294 7.8 4 7.39706 4 6.9Z" />
    <path d="M4 11.9C4 11.4029 4.40294 11 4.9 11H19.1C19.5971 11 20 11.4029 20 11.9C20 12.3971 19.5971 12.8 19.1 12.8H4.9C4.40294 12.8 4 12.3971 4 11.9Z" />
    <path d="M4 16.9C4 16.4029 4.40294 16 4.9 16H19.1C19.5971 16 20 16.4029 20 16.9C20 17.3971 19.5971 17.8 19.1 17.8H4.9C4.40294 17.8 4 17.3971 4 16.9Z" />
  </svg>
)

export const Menu = forwardRef<HTMLSpanElement, AntdIconProps>((props, ref) => (
  <Icon component={MenuSvg} {...props} ref={ref} />
))
