import styled from '@emotion/styled'
import { Typography } from '@vivaldis/antd-ui'
import { FC, useMemo } from 'react'

interface Props {
  description: string | null
}

export const Description: FC<Props> = ({ description }) => {
  const text = useMemo(() => {
    if (!description) {
      return description
    }

    const parser = new DOMParser()
    const doc = parser.parseFromString(description, 'text/html')
    return doc.body.textContent
  }, [description])

  return (
    <Base>
      {text ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            expandable: true
          }}
          title={text}
          style={{ marginBottom: 0 }}
        >
          {text}
        </Typography.Paragraph>
      ) : null}
    </Base>
  )
}

const Base = styled('div')`
  max-width: 40ch;
  word-break: break-all;
  white-space: pre-wrap;
`
