import styled from '@emotion/styled'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  breakInMinutes: number
}

export const Break: FC<Props> = ({ breakInMinutes }) => {
  const { t } = useTranslation('web_ui')

  return (
    <Base>
      {breakInMinutes}
      <MinutesBase>{t('contract_schedule.min')}</MinutesBase>
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const MinutesBase = styled('div')`
  color: ${props => props.theme.gray7};
  margin-right: 4px;
`
