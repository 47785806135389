import { css } from '@emotion/react'

export enum FontWeight {
  light = 'light',
  regular = 'regular',
  semibold = 'semibold',
  bold = 'bold',
  italic = 'italic'
}

export const fontFamily = css`
  font-family: 'Open Sans', sans-serif;
`

export const fontWeight = (fontWeight: FontWeight = FontWeight.regular) => {
  switch (fontWeight) {
    case 'light':
      return css`
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
      `
    case 'regular':
      return css`
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
      `
    case 'semibold':
      return css`
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
      `
    case 'bold':
      return css`
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
      `
    case 'italic':
      return css`
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
      `
  }
}

export const lineHeight = (lineHeight: number): any => css`
  line-height: ${lineHeight}px;
`

export const letterSpacing = (letterSpacing: number) => css`
  letter-spacing: ${letterSpacing}px;
`

export const fontSize = (size = 16, lineHeightValue?: number) => css`
  font-size: ${size}px;
  ${lineHeightValue
    ? lineHeight(lineHeightValue)
    : lineHeight(1.3666666667 * size)};
`

export const textTransform = (
  transformation: 'uppercase' | 'lowercase' | 'capitalize' | 'none'
) => css`
  text-transform: ${transformation};
`

export function font(
  fontSizeValue = 16,
  fontWeightValue: FontWeight = FontWeight.regular,
  lineHeightValue?: number,
  letterSpacingValue?: number,
  textTransformValue?:
    | 'uppercase'
    | 'lowercase'
    | 'capitalize'
    | 'none'
    | undefined
) {
  return css`
    ${fontSize(fontSizeValue, lineHeightValue)};
    ${fontWeight(fontWeightValue)};
    ${letterSpacingValue ? letterSpacing(letterSpacingValue) : null};
    ${textTransformValue ? textTransform(textTransformValue) : null};
  `
}

export default font
