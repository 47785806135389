import { call, select, takeEvery } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import {
  initComplete as initCompleteSessionAction,
  SessionActionType,
  SessionData,
  sessionSelector
} from '@vivaldis/session'
import { MainNavigateGlobalProvider } from '@vivaldis/web-ui'
import { getApolloClient } from '@vivaldis/common'
import { ApolloQueryResult } from '@apollo/client'
import { tracking } from '@vivaldis/tracking'
import { sessionSagaUserQuery } from './graphql/sessionSagaUserQuery'
import { SessionSagaUserQuery } from './graphql/sessionSagaUserQuery/__generated__/SessionSagaUserQuery'

function* initCompleteSession(
  action: ActionType<typeof initCompleteSessionAction>
) {
  const session: SessionData = yield select(sessionSelector)
  if (
    session.authentication_token &&
    session.email &&
    action.payload.user?.company?.id
  ) {
    try {
      // we mark current user as "Paid customer" in Google Analytics if company of this user has at least one commercial profiles (this mean that our backoffice contacted with this company and signed in agreement)
      // TODO: define conditions for "paid customer" in a new app
      if (action.payload.user?.company?.offers.length > 1_000_00) {
        yield call(async () => {
          tracking.event(
            'set_paid_user_data_layer_variable_to_yes',
            'Send "paidUser" variable to Google Tag Manager data layer',
            {
              paidUser: 'Yes',
              brandName: 'Vivaldis'
            }
          )
        })
      }
    } catch (err) {}
  }
}

function* signOutComplete() {
  yield call(async () => {
    const navigate = await MainNavigateGlobalProvider.getNavigate()
    navigate('/', { replace: true })
  })
}

function* signInComplete() {
  yield call(async () => {
    const navigate = await MainNavigateGlobalProvider.getNavigate()
    navigate('/', { replace: true })
  })
}

function* trackEventAfterSignUpComplete() {
  const session: SessionData = yield select(sessionSelector)

  if (session.authentication_token && session.email) {
    try {
      const apolloClient = getApolloClient()
      const apolloData: ApolloQueryResult<SessionSagaUserQuery> = yield call(
        apolloClient.query,
        {
          query: sessionSagaUserQuery
        }
      )
      const data = apolloData.data

      if (data && data.user?.id) {
        const userId = data.user?.id

        yield call(async () => {
          tracking.event(
            'user_successful_registration',
            'Successful user registration',
            {
              brandName: 'Vivaldis',
              userId
            }
          )
        })
      }
    } catch (err) {}
  }
}

function* signUpComplete() {
  yield call(async () => {
    const navigate = await MainNavigateGlobalProvider.getNavigate()
    navigate('/', { replace: true })
  })
}

export function* session() {
  yield takeEvery(SessionActionType.SignOutComplete, signOutComplete)
  yield takeEvery(SessionActionType.SignInSuccess, signInComplete)
  yield takeEvery(
    SessionActionType.SignUpSuccess,
    trackEventAfterSignUpComplete
  )
  yield takeEvery(SessionActionType.SignUpSuccess, signUpComplete)
  yield takeEvery(SessionActionType.InitComplete, initCompleteSession)
}
