export class Prompt {
  public static confirmationPrompt(
    _message: string,
    _onYesPress: () => any,
    _onNoPress?: () => any
  ) {}

  public static settingsPrompt(_message: string, _onSettingsPress: () => any) {}

  public static errorPrompt(_title: string, _message?: string) {}

  public static unknownErrorPrompt() {}

  public static serviceUnavailablePrompt() {}
}
