import type { EmployeeCertificateInput } from '@vivaldis/graphql'
import { format } from 'date-fns'
import type { EmployeeCertificateFormValues } from '../provider/FormProvider'

export const employeeCertificateFormValuesToEmployeeCertificateInput = (
  values: EmployeeCertificateFormValues
): EmployeeCertificateInput => {
  return {
    certificateType: values.certificateType,
    // we send file only if it was uploaded (for update we don't send file if it wasn't changed)
    ...(values.scannedDocumentUrlAttachment.fileName
      ? {
          scannedDocumentUrlAttachment: {
            fileName: values.scannedDocumentUrlAttachment.fileName,
            base64: values.scannedDocumentUrlAttachment.base64
          }
        }
      : {}),
    ...(values.scannedDocumentBackUrlAttachment.fileName
      ? {
          scannedDocumentBackUrlAttachment: {
            fileName: values.scannedDocumentBackUrlAttachment.fileName,
            base64: values.scannedDocumentBackUrlAttachment.base64
          }
        }
      : {}),
    expiresOn: values.expiresOn
      ? format(values.expiresOn, 'yyyy-MM-dd')
      : values.expiresOn
  }
}
