import styled from '@emotion/styled'
import { TextStylePreset, Typography } from '@vivaldis/antd-ui'
import { format } from 'date-fns'
import { FC, useMemo } from 'react'
import { Contract_workdays as Workday } from '../../../typings/Contract'

interface Props {
  startsAt: Workday['startsAt']
}

export const Day: FC<Props> = ({ startsAt }) => {
  const date = useMemo(() => {
    return new Date(startsAt)
  }, [startsAt])

  const dayName = useMemo(() => {
    // 'Tue'
    return format(date, 'E')
  }, [date])

  const dayDetails = useMemo(() => {
    // '16/11'
    return format(date, 'dd/MM')
  }, [date])

  // 'Tue 16/11'
  return (
    <Base>
      <DayName>{dayName}</DayName>
      <Typography.Text preset={TextStylePreset.Semibold14}>
        {dayDetails}
      </Typography.Text>
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
`

const DayName = styled('div')`
  color: ${props => props.theme.primaryColor};
  margin-right: 4px;
`
