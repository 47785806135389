import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState
} from 'react'

interface ScreenTitleContextValue {
  title: string | null
  setTitle: (title: string | null) => void
}

export const ScreenTitleContext = createContext<ScreenTitleContextValue>({
  title: null,
  setTitle: () => {}
})

export const useScreenTitle = () => {
  return useContext(ScreenTitleContext)
}

export const ScreenTitleProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const [title, setTitle] = useState<string | null>(null)

  const contextValue = useMemo(() => ({ title, setTitle }), [title])

  return (
    <ScreenTitleContext.Provider value={contextValue}>
      {children}
    </ScreenTitleContext.Provider>
  )
}
