import { Radio as BaseRadio } from './Radio'
import { RadioButton as Button } from './RadioButton'
import { RadioGroup as Group } from './RadioGroup'

export type { RadioProps, RadioChangeEvent, RadioRef } from './Radio'
export type { RadioGroupProps } from './RadioGroup'

// should we do it with hoist-non-react-statics ?
export const Radio = Object.assign(BaseRadio, {
  ...BaseRadio,
  Button,
  Group
})
