import { isEqual } from 'lodash'
import { ReducersMapObject } from 'redux'
import {
  ActionCableAction,
  ActionCableActionType,
  ActionCableChannel,
  ChannelOptions
} from '../actions'

export const ACTION_CABLE_STATE_KEY = '@@actionCable'

export interface ActionCableChannelState<
  Channel = ActionCableChannel,
  Options = ChannelOptions
> {
  channel: Channel
  options?: Options
}

export interface ActionCableState {
  channels: ActionCableChannelState[]
  open: boolean
  shouldReconnect: boolean
}

const initialState: ActionCableState = {
  channels: [],
  open: false,
  shouldReconnect: false
}

export function createActionCableReducer(
  reducers: ReducersMapObject<any, any>
): ReducersMapObject<any, any> {
  return {
    ...reducers,
    [ACTION_CABLE_STATE_KEY]: (
      state: ActionCableState = initialState,
      action: ActionCableAction
    ) => {
      switch (action.type) {
        case ActionCableActionType.Init:
          return {
            ...state,
            open: true,
            shouldReconnect: true
          }

        case ActionCableActionType.OnOpen:
          return {
            ...state,
            open: true
          }

        case ActionCableActionType.OnClose:
          return {
            ...state,
            open: false
          }

        case ActionCableActionType.End:
          return initialState

        case ActionCableActionType.SubscribeToChannel: {
          const { channel, options } = action.payload
          const channels = [
            ...state.channels,
            {
              channel: channel,
              ...(options ? { options } : {})
            }
          ]

          // store only unique subscription because application not always can unsubscribe if it has crashed and was terminated
          const uniqChannels = channels.filter(
            (currentChannel, currentIndex, allChannels) => {
              const alreadyExist = allChannels.find((item, index) => {
                if (index > currentIndex) {
                  return isEqual(currentChannel, item)
                }
                return false
              })
              return !alreadyExist
            }
          )
          return {
            ...state,
            channels: uniqChannels
          }
        }

        case ActionCableActionType.UnsubscribeFromChannel: {
          const channelIndex = state.channels.findIndex(
            channel =>
              channel.channel === action.payload.channel &&
              isEqual(channel.options, action.payload.options)
          )
          if (channelIndex !== -1) {
            return {
              ...state,
              channels: [
                ...state.channels.slice(0, channelIndex),
                ...state.channels.slice(channelIndex + 1)
              ]
            }
          }
          return state
        }

        default:
          return state
      }
    }
  }
}
